import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "_redux/hooks";
import { userPageSizeSelector } from "_redux/selectors/user";

export const QUERY_STRING_PARAM_PAGE = "page";
export const QUERY_STRING_PARAM_PAGE_SIZE = "pageSize";

const usePagination = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userPageSize = useAppSelector(userPageSizeSelector);

  const urlSearchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const setPageWithHistoryUpdate = useCallback(
    (newPage: number) => {
      urlSearchParams.set(QUERY_STRING_PARAM_PAGE, newPage.toString());

      navigate({
        pathname: location.pathname,
        search: urlSearchParams.toString(),
        hash: location.hash,
      });

      window.scrollTo(0, 0);
    },
    [location.hash, location.pathname, navigate, urlSearchParams]
  );

  const setPageSizeWithHistoryUpdate = useCallback(
    (newPageSize: string) => {
      urlSearchParams.set(QUERY_STRING_PARAM_PAGE_SIZE, newPageSize);

      navigate({
        pathname: location.pathname,
        search: urlSearchParams.toString(),
        hash: location.hash,
      });
    },
    [location.hash, location.pathname, urlSearchParams, navigate]
  );

  return useMemo(() => {
    const pageQueryStringValue =
      urlSearchParams.get(QUERY_STRING_PARAM_PAGE) || "1";
    const page = parseInt(pageQueryStringValue, 10);
    const pageSize =
      urlSearchParams.get(QUERY_STRING_PARAM_PAGE_SIZE) || userPageSize;

    return {
      pageSize,
      setPageSize: setPageSizeWithHistoryUpdate,
      page,
      setPage: setPageWithHistoryUpdate,
    };
  }, [
    setPageSizeWithHistoryUpdate,
    setPageWithHistoryUpdate,
    urlSearchParams,
    userPageSize,
  ]);
};

export default usePagination;
