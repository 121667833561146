const PING_RESOURCE = "/wcs/resources/health/ping";
const DEFAULT_TIMEOUT_TIME_MS = 1600;

const fetchWithTimeout = async (
  input: RequestInfo | URL,
  init: RequestInit & { timeout?: number } = {}
): Promise<Response> => {
  const { timeout = DEFAULT_TIMEOUT_TIME_MS, signal: originalSignal } = init;

  const timeoutController = new AbortController();
  const { signal } = timeoutController;
  const abortTimeout = () => {
    timeoutController.abort();
  };
  const id = setTimeout(abortTimeout, timeout);

  if (originalSignal) {
    originalSignal.onabort = abortTimeout;
  }

  const response = await fetch(input, {
    ...init,
    signal,
  });
  clearTimeout(id);

  return response;
};

const checkOnlineStatus = async (
  timeout?: number,
  cache: RequestCache = "no-cache"
): Promise<boolean> => {
  // shortcut, if the browser think we're offline, assume we're offline
  if (!navigator.onLine) return navigator.onLine;

  try {
    const pingResponse = await fetchWithTimeout(PING_RESOURCE, {
      method: "GET",
      cache,
      mode: "no-cors",
      timeout,
    });

    return pingResponse.status === 200;
  } catch (error) {
    return false;
  }
};

export default checkOnlineStatus;
