import React, { FC, useState } from "react";

import { StatusCodes } from "http-status-codes";

import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { useSite } from "_foundation/hooks/useSite";
import personService from "_foundationExt/apis/transaction/person.service";

import { useForm } from "react-hook-form";

import { PERSON_HAS_NO_ROLES_IN_STORE } from "constants/errors";

import { Typography, Box, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";

import {
  StyledNotification,
  StyledFormInput,
  StyledButton,
  StyledLink,
  StyledDivider,
  StyledContainer,
  StyledProgress,
} from "components/StyledUI";

import { ServerError } from "types/Error";

import { ROUTES } from "constants/routes";
import { mapRefToInputRef } from "tools/convertUtils";
import { useNavigate } from "react-router-dom";
import { useEFoodConfig } from "_foundationExt/hooks";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  actions: {
    flexDirection: "row-reverse",
    "& .grid-item:first-of-type": {
      textAlign: "right",
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(2),
      },
    },
  },
}));

const ForgotPassword: FC = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const breakpointDownXs = useMediaQuery(theme.breakpoints.down("sm"));

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentSite } = useSite();
  const { registrationtype } = useEFoodConfig();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<ServerError | null>(null);

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, isDirty, isValid, touchedFields },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = handleSubmit((form) => {
    setLoading(true);
    setSuccess(false);
    setError(null);

    const parameters = {
      responseFormat: "json",
      storeId: currentSite?.storeID,
      body: {
        ...form,
        resetPassword: "true",
      },
    };

    personService
      .updatePerson(parameters)
      .then((response) => {
        if (response.status === StatusCodes.OK) {
          setSuccess(true);
        }
      })
      .catch((e) => {
        setError(e.response.data.errors[0]);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return (
    <>
      <Helmet>
        <title>{t("password.lostPassword")}</title>
      </Helmet>

      <StyledContainer size="small">
        <Box mb={3}>
          <Typography variant="h1">{t("password.lostPassword")}?</Typography>
        </Box>

        {loading && <StyledProgress />}

        {success && (
          <Box mb={3}>
            <StyledNotification severity="success">
              {t("password.passwordSentSuccesful")}
            </StyledNotification>
          </Box>
        )}

        {error && error.errorMessage && (
          <>
            {error.errorKey === PERSON_HAS_NO_ROLES_IN_STORE ? (
              <Box mb={3}>
                <StyledNotification severity="info">
                  {t("logon.wrongShop")}
                  <br />
                  <StyledLink to={`${error.errorParameters}/forgotPassword`}>
                    {t("logon.wrongShopLink")}
                  </StyledLink>
                </StyledNotification>
              </Box>
            ) : (
              <Box mb={3}>
                <StyledNotification severity="error">
                  {error.errorMessage}
                </StyledNotification>
              </Box>
            )}
          </>
        )}

        <Typography paragraph align="right">
          * {t("form.mandatory")}
        </Typography>

        <form
          name="ResetPasswordForm"
          id="ResetPasswordForm"
          noValidate
          onSubmit={onSubmit}
          autoComplete="off">
          <Typography paragraph variant="h3">
            {t("password.username")}
          </Typography>

          <StyledFormInput
            id="logonId"
            label={t("password.username")}
            placeholder={`${t("password.username")}*`}
            fullWidth
            autoFocus
            {...mapRefToInputRef(
              register("logonId", {
                required: `${t("form.error.required")}`,
                onChange: () => touchedFields.logonId && trigger(["logonId"]),
                onBlur: () => trigger(["logonId"]),
              })
            )}
            error={!!error}
            helperText={errors?.logonId?.message?.toString()}
          />

          <Box mt={5}>
            <Typography variant="body1">
              * {t("password.formHint")}
              {registrationtype !== "NONE" && (
                <>
                  {" "}
                  {t("password.formHintPleaseRegister")}{" "}
                  {registrationtype === "ALL" && (
                    <StyledLink to={ROUTES.REGISTER_TYPE}>
                      {t("password.register")}
                    </StyledLink>
                  )}
                  {registrationtype === "NEW" && (
                    <StyledLink to={ROUTES.REGISTER_NEW_CUSTOMER}>
                      {t("password.register")}
                    </StyledLink>
                  )}
                </>
              )}
            </Typography>
          </Box>

          <StyledDivider light py={3} />

          <Grid container className={classes.actions}>
            <Grid xs={12} sm={6} className="grid-item">
              <StyledButton
                fullWidth={breakpointDownXs}
                type="submit"
                disabled={(touchedFields && !isDirty) || !isValid}>
                {t("action.sendNewPassword")}
              </StyledButton>
            </Grid>
            <Grid xs={12} sm={6} className="grid-item">
              <StyledButton
                fullWidth={breakpointDownXs}
                variant="outlined"
                onClick={() => navigate(-1)}>
                {t("action.back")}
              </StyledButton>
            </Grid>
          </Grid>
        </form>
      </StyledContainer>
    </>
  );
};

export default React.memo(ForgotPassword);
