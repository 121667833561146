/**
 * -1 = unlimit categories
 *  1,2,3  1=first level 2=second level 3=third level cateogries ...
 */
export const TOP_CATEGORIES_DEPTH_LIMIT = "-1,-1,-1";

export const PRODUCT_LIST_FIELDS = "facetView";

export const KEYWORD_LIMIT = 4;

export const EXCLUDED_FILTER_FACETS = [
  "parentCatgroup_id_search",
  "articlegroup",
  "mfName_ntk_cs",
];
