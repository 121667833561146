export const CUSTOMER_PRODUCTLIST_TYPE_ORDERSET = "XOS"; // Ordersatz
export const CUSTOMER_PRODUCTLIST_TYPE_LABELLIST = "XLL"; // Etikettenliste
export const CUSTOMER_PRODUCTLIST_TYPE_REQUISITION_LIST = "XRL"; // Einkaufsliste
export const CUSTOMER_PRODUCTLIST_TYPE_STORAGE_LIST = "XSL"; // Lagerliste
export const CUSTOMER_PRODUCTLIST_TYPE_CONTRACT = "XCO"; // Kontrakte
export const CUSTOMER_PRODUCTLIST_TYPE_OFFER = "XOF"; // Angebote

export type ProductListType =
  | typeof CUSTOMER_PRODUCTLIST_TYPE_ORDERSET
  | typeof CUSTOMER_PRODUCTLIST_TYPE_LABELLIST
  | typeof CUSTOMER_PRODUCTLIST_TYPE_REQUISITION_LIST
  | typeof CUSTOMER_PRODUCTLIST_TYPE_STORAGE_LIST
  | typeof CUSTOMER_PRODUCTLIST_TYPE_CONTRACT
  | typeof CUSTOMER_PRODUCTLIST_TYPE_OFFER;

export interface CustomerProductlist {
  id: string;
  type: ProductListType;
  name: string;
  description?: string;
  productListItemId?: string;
  sortOrder?: 0 | 1;
}

export interface CustomerProductlistCreateUpdatePayload {
  customerProductListId?: string;
  body: {
    name: string;
    description: string;
    type: string;
    sortOrder?: number;
    addProductsFromCart?: boolean;
  };
}
