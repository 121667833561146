import React, { FC } from "react";

import { StyledDivider } from "components/StyledUI";
import { CustomerProductlist } from "types/CustomerProductlist";
import { Grid, Box } from "@mui/material";
import { tss } from "tss-react/mui";
import { ProductListItem } from "../productListItem";
import { ProductGalleryItem } from "../productGallery/productGalleryItem";
import { Product } from "../../types/Product";

const useStyles = tss.create(({ theme }) => ({
  wrapper: {
    margin: theme.spacing(-1.5),
    overflow: "hidden",
  },
}));

interface ProductListGroupProps {
  products: Product[];
  refetch?: () => void;
  selectedCustomerProductList?: CustomerProductlist | null;
  isAccountListView?: boolean;
  title: string;
  isDark: boolean;
  isGalleryView?: boolean;
  editMode?: boolean;
}

const ProductListTopGroup: FC<ProductListGroupProps> = ({
  products,
  refetch,
  selectedCustomerProductList,
  isAccountListView,
  title,
  isDark,
  isGalleryView,
  editMode,
}) => {
  const { classes } = useStyles();
  return (
    <>
      {products && products.length > 0 && isDark && (
        <>
          <StyledDivider text={title} dark />
          {!isGalleryView &&
            products.map((product) => (
              <ProductListItem
                key={product.uniqueID}
                product={product}
                refetch={refetch}
                selectedCustomerProductList={selectedCustomerProductList}
                isAccountListView={isAccountListView}
                editMode={editMode}
              />
            ))}
          <>
            {isGalleryView && (
              <Box mb={3}>
                <Grid container spacing={3} className={classes.wrapper}>
                  <>
                    {products.map((product) => (
                      <ProductGalleryItem
                        key={product.uniqueID}
                        product={product}
                        refetch={refetch}
                      />
                    ))}
                  </>
                </Grid>
              </Box>
            )}
          </>
        </>
      )}

      {products && products.length > 0 && !isDark && (
        <>
          <StyledDivider text={title} light />
          {!isGalleryView &&
            products.map((product) => (
              <ProductListItem
                key={product.uniqueID}
                product={product}
                refetch={refetch}
                selectedCustomerProductList={selectedCustomerProductList}
                isAccountListView={isAccountListView}
              />
            ))}
          {isGalleryView && (
            <Box mb={5}>
              <Grid container spacing={3} className={classes.wrapper}>
                <>
                  {products.map((product) => (
                    <ProductGalleryItem
                      key={product.uniqueID}
                      product={product}
                      refetch={refetch}
                    />
                  ))}
                </>
              </Grid>
            </Box>
          )}
        </>
      )}
    </>
  );
};
export default ProductListTopGroup;
