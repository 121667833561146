import React, { FC } from "react";
import HTMLReactParser from "html-react-parser";
import { useAppSelector } from "_redux/hooks";
import { loginStatusSelector } from "_redux/selectors/user";
import { Typography } from "@mui/material";
import { ROUTES } from "constants/routes";
import { Product } from "types/Product";
import UnstyledLink from "components/StyledUI/UnstyledLink";
import { useEFoodConfig } from "_foundationExt/hooks";

interface ProductNameSectionProps {
  product: Product;
}

const ProductNameSection: FC<ProductNameSectionProps> = ({ product }) => {
  const efood = useEFoodConfig();
  const loggedIn = useAppSelector(loginStatusSelector);
  let enableLink = false;

  if (loggedIn) {
    enableLink = efood.productdetailCustomer || false;
  } else {
    enableLink = efood.productdetailGuest || false;
  }

  const productNameSectionContent = (
    <>
      <Typography variant="h5">{product.name}</Typography>
      {product.shortDescription && (
        <Typography variant="body2">
          {HTMLReactParser(product.shortDescription)}
        </Typography>
      )}
      <Typography variant="subtitle1">
        {`Art-Nr. ${product.eFoodPartnumber}`}
      </Typography>
    </>
  );

  if (enableLink) {
    return (
      <UnstyledLink to={`${ROUTES.PRODUCT_DETAIL}/${product.eFoodPartnumber}`}>
        {productNameSectionContent}
      </UnstyledLink>
    );
  }

  return productNameSectionContent;
};

export default ProductNameSection;
