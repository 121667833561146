import { useMemo } from "react";
import { useSelector } from "react-redux";
import { shipInfosSelector } from "_redux/selectors/order";

const useShipInfosSelector = () => {
  const shipInfos = useSelector(shipInfosSelector);
  const hasShipInfos = !!shipInfos;
  const {
    address1,
    address2,
    address3,
    city,
    email,
    firstname,
    lastname,
    zipcode,
  } = shipInfos ?? {};

  return useMemo(() => {
    if (!hasShipInfos) {
      return null;
    }
    return {
      address1,
      address2,
      address3,
      city,
      email,
      firstname,
      lastname,
      zipcode,
    };
  }, [
    hasShipInfos,
    address1,
    address2,
    address3,
    city,
    email,
    firstname,
    lastname,
    zipcode,
  ]);
};
export default useShipInfosSelector;
