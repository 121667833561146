import React, { FC, useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import { siteSelector } from "_redux/selectors/site";
import { SET_STORAGE_LOCATION_CHANGED } from "_redux/actions/user";

import { StyledFormInput } from "components/StyledUI";

import customerProductlistService from "_foundationExt/apis/transaction/customerProductlist.service";

import { Product } from "types/Product";

import { tss } from "tss-react/mui";
import { Typography } from "@mui/material";

interface ProductStorageProps {
  product: Product;
  editMode?: boolean;
}

const useStyles = tss.create(({ theme }) => ({
  productStoragePlace: {
    color: theme.palette.grey[700],
    display: "inline-block",
    paddingRight: "20px",
    fontSize: "14px",
  },
  productStorageEdit: {
    width: "50px",
    marginRight: "4px",
  },
}));

const ProductStoragePlace: FC<ProductStorageProps> = ({
  product,
  editMode = true,
}) => {
  const site = useAppSelector(siteSelector);
  const dispatch = useAppDispatch();
  const { classes } = useStyles();

  const [locationValue, setLocationValue] = useState(product?.location ?? "");

  const handleLocationInputChange = (event) => {
    if (event.target.value.length < 5) {
      setLocationValue(event.target.value);
    }
  };

  const handleLocationInputBlur = useCallback(
    (event) => {
      customerProductlistService
        .saveStorageLocation({
          storeId: site ? site.storeID : "",
          stockListItemId: product?.productListItemId,
          body: {
            location: event.target.value,
          },
        })
        .then(() => {
          dispatch(SET_STORAGE_LOCATION_CHANGED());
        });
    },
    [site, product, dispatch]
  );

  return editMode ? (
    <StyledFormInput
      size="small"
      className={classes.productStorageEdit}
      onBlur={(event) => handleLocationInputBlur(event)}
      onChange={(event) => handleLocationInputChange(event)}
      value={locationValue}
    />
  ) : (
    <Typography className={classes.productStoragePlace}>
      {locationValue}
    </Typography>
  );
};

export default ProductStoragePlace;
