import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  StyledDialog,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDivider,
} from "components/StyledUI";
import { useSite } from "_foundationExt/hooks";
import productServiceExt from "_foundationExt/apis/search/product.service";

import { format } from "date-fns";
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import { ORDER_SPLIT_HINT_VIEWED_ACTION } from "_redux/actions/user";
import { orderItemsWithOrderSplitSelector } from "_redux/selectors/user";
import { FETCHING_CART_ACTION } from "_redux/actions/order";
import { tss } from "tss-react/mui";

interface SplitCartRowData {
  catEntryId: string;
  partNumber: string;
  articleName: string;
  replenishmentDate: string;
}

const useStyles = tss.create(({ theme }) => ({
  dialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("sm")]: {
        maxWidth: "unset",
        margin: theme.spacing(0, 1),
      },
    },
  },
  header: {
    backgroundColor: "#F2F3F1",
    hyphens: "auto",
  },
  content: {
    backgroundColor: "#F9F9F8",
    "& > div:nth-of-type(odd)": {
      backgroundColor: "#FFFFFF",
    },
  },
  button: {
    "& button": {
      width: "100%",
    },
  },
}));

const SplitCartDialog: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { currentSite } = useSite();
  const dispatch = useAppDispatch();
  const [articlesToAdd, setArticlesToAdd] = useState<SplitCartRowData[]>([]);

  const orderItemsWithOrderSplit = useAppSelector(
    orderItemsWithOrderSplitSelector
  );

  const closeDialog = () => {
    dispatch(ORDER_SPLIT_HINT_VIEWED_ACTION());
    dispatch(FETCHING_CART_ACTION({}));
  };

  React.useEffect(() => {
    if (
      currentSite?.storeID &&
      orderItemsWithOrderSplit &&
      orderItemsWithOrderSplit.length > 0
    ) {
      const newArticlesToAdd: SplitCartRowData[] = [];

      orderItemsWithOrderSplit.forEach((orderItemWithOrderSplit) => {
        newArticlesToAdd.push({
          catEntryId: orderItemWithOrderSplit.catEntryId,
          partNumber: "",
          articleName: "",
          /* eslint-disable indent */
          replenishmentDate: orderItemWithOrderSplit.availability
            .replenishmentDate
            ? format(
                new Date(
                  orderItemWithOrderSplit.availability.replenishmentDate
                ),
                "dd.MM.yyyy"
              )
            : "",
          /* eslint-enable indent */
        });
      });

      const parameters = {
        storeId: currentSite.storeID,
        $queryParameters: {
          id: newArticlesToAdd.map(
            (newArticleToAdd) => newArticleToAdd.catEntryId
          ),
        },
      };

      productServiceExt.findByIds(parameters).then((productResponse) => {
        if (
          productResponse.status === 200 &&
          productResponse.data &&
          productResponse.data.catalogEntryView &&
          productResponse.data.catalogEntryView.length > 0
        ) {
          productResponse.data.catalogEntryView.forEach((catalogEntry) => {
            const articleWithoutArticleName = newArticlesToAdd.find(
              (newArticleToAdd) =>
                newArticleToAdd.catEntryId === catalogEntry.uniqueID
            );
            if (articleWithoutArticleName) {
              articleWithoutArticleName.partNumber =
                catalogEntry.eFoodPartnumber;
              articleWithoutArticleName.articleName = catalogEntry.name;
            }
          });
          setArticlesToAdd(newArticlesToAdd);
        }
      });
    }
  }, [orderItemsWithOrderSplit, currentSite?.storeID]);

  if (!orderItemsWithOrderSplit?.length) {
    return null;
  }

  return (
    <StyledDialog open onClose={closeDialog} className={classes.dialog}>
      <StyledDialogTitle id="dialog-title" onClose={closeDialog}>
        <Typography variant="h3">{t("cart.split.dialog.title")}</Typography>
      </StyledDialogTitle>

      <StyledDialogContent>
        <Box>{t("cart.split.dialog.description")}</Box>
        <StyledDivider light />
        <Grid container className={classes.header}>
          <Grid xs={2}>
            <Box p={1}>
              <Typography align="left" variant="h4">
                {t("cart.split.dialog.article.partNumber")}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={6}>
            <Box p={1}>
              <Typography align="left" variant="h4">
                {t("cart.split.dialog.article.name")}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={4}>
            <Box p={1}>
              <Typography align="left" variant="h4">
                {t("cart.split.dialog.article.deliveryFrom")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid className={classes.content}>
          <Grid container>
            {articlesToAdd.map((articleToAdd) => (
              <>
                <Grid xs={2}>
                  <Box p={1}>
                    <Typography align="left" variant="body2">
                      {articleToAdd.partNumber}
                    </Typography>
                  </Box>
                </Grid>
                <Grid xs={6}>
                  <Box p={1}>
                    <Typography align="left" variant="body2">
                      {articleToAdd.articleName}
                    </Typography>
                  </Box>
                </Grid>
                <Grid xs={4}>
                  <Box p={1}>
                    <Typography align="left" variant="body2">
                      {articleToAdd.replenishmentDate}
                    </Typography>
                  </Box>
                </Grid>
              </>
            ))}
          </Grid>
        </Grid>
        <StyledDivider light />
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default SplitCartDialog;
