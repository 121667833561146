import React, { PropsWithChildren, useCallback } from "react";
import { Alert, AlertProps, IconButton } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { RESET_ERROR_ACTION } from "_redux/actions/error";
import { useAppDispatch } from "_redux/hooks";
import { ReactComponent as CheckIcon } from "assets/icons/check-circle-1.svg";
import { ReactComponent as InfoIcon } from "assets/icons/messages-bubble-information.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/messages-bubble-warning.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  root: {
    minHeight: theme.spacing(6),
    padding: theme.spacing(0),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(0.5),
    "& .MuiAlert-icon": {
      padding: theme.spacing(1.5),
      borderTopLeftRadius: theme.spacing(0.5),
      borderBottomLeftRadius: theme.spacing(0.5),
      display: "flex",
      alignItems: "center",
      opacity: 1,
      "& svg": {
        height: theme.spacing(3),
        width: theme.spacing(3),
        pointerEvents: "none",
      },
    },
    "& .MuiAlert-message": {
      alignSelf: "center",
      fontSize: theme.typography.fontSize,
      color: theme.palette.text.primary,
      padding: theme.spacing(1.5, 2, 1.5, 0),
    },
    // standart colors
    "&.MuiAlert-standardError .MuiAlert-icon": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.background.paper,
    },
    "&.MuiAlert-standardWarning .MuiAlert-icon": {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.background.paper,
    },
    "&.MuiAlert-standardInfo .MuiAlert-icon": {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.background.paper,
    },
    "&.MuiAlert-standardSuccess .MuiAlert-icon": {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.background.paper,
    },
    // filled colors MuiAlert-filledSuccess
    "&.MuiAlert-filledError": {
      backgroundColor: theme.palette.error.main,
    },
    "&.MuiAlert-filledWarning": {
      backgroundColor: theme.palette.warning.main,
    },
    "&.MuiAlert-filledInfo": {
      backgroundColor: theme.palette.info.main,
    },
    "&.MuiAlert-filledSuccess": {
      backgroundColor: theme.palette.success.main,
    },
    "& .MuiAlert-action": {
      color: theme.palette.grey[800],
      marginRight: theme.spacing(2),
      paddingLeft: theme.spacing(0),
      alignItems: "center",
      "& svg": {
        cursor: "pointer",
        width: theme.spacing(2),
        height: theme.spacing(2),
        pointerEvents: "none",
      },
    },
  },
}));

const StyledNotification = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<Omit<AlertProps, "action" | "iconMapping">> & {
    close?: boolean;
  }
>((props, ref) => {
  const { classes, cx } = useStyles();
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(true);
  const { className, close, children, ...rest } = props;

  const closeFunction = useCallback(() => {
    setOpen(false);
    dispatch(RESET_ERROR_ACTION());
  }, [dispatch]);

  const alert = (
    <Alert
      {...rest}
      ref={ref}
      action={
        close ? (
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={closeFunction}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        ) : undefined
      }
      className={cx(classes.root, className)}
      iconMapping={{
        success: <CheckIcon />,
        info: <InfoIcon />,
        warning: <ErrorIcon />,
        error: <ErrorIcon />,
      }}>
      {children}
    </Alert>
  );

  if (close) {
    return <Collapse in={open}>{alert}</Collapse>;
  }

  return alert;
});

export { StyledNotification };
