import React, { useEffect } from "react";
import { useAppDispatch } from "_redux/hooks";
import { useLocation } from "react-router-dom";
import { UPDATE_FORCE_STANDALONE_MODE_ACTION } from "_redux/actions/site";

const ForceStandaloneModeWatcher: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const hashParams = new URLSearchParams(location.hash.substring(1));
      const forceStandaloneMode = hashParams.get("forceStandaloneMode");
      if (forceStandaloneMode != null) {
        dispatch(
          UPDATE_FORCE_STANDALONE_MODE_ACTION(forceStandaloneMode === "true")
        );
      }
    }
  }, [location.hash, dispatch]);

  return null;
};

export default ForceStandaloneModeWatcher;
