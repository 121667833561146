import React, {
  CSSProperties,
  FC,
  PropsWithChildren,
  useLayoutEffect,
  useState,
} from "react";

import { Box } from "@mui/material";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  root: {
    position: "relative",
    borderRadius: "8px",
    overflow: "hidden",
    height: "160px",
    "& img": {
      position: "absolute",
      bottom: "0",
      right: "0",
      display: "block",
      width: "auto",
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        transform: "translateX(10%)",
      },
    },
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",

    [theme.breakpoints.up("sm")]: {
      height: "180px",
    },
    [theme.breakpoints.up("md")]: {
      height: "240px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "267px",
    },
  },
  overlay: {
    position: "absolute",
    top: "50%",
    left: 24,
    transform: "translateY(-50%)",
    margin: "auto",
    whiteSpace: "pre-line",
    color: theme.palette.common.white,
    [theme.breakpoints.up("sm")]: {
      left: 118,
    },
    [theme.breakpoints.up("md")]: {
      left: 64,
    },
    [theme.breakpoints.up("lg")]: {
      left: 118,
    },
    "& p": {
      fontFamily: "ShadowsIntoLight",
      fontSize: 32,
      lineHeight: "40px",
      letterSpacing: "-0.5px",
      [theme.breakpoints.up("sm")]: {
        fontSize: 40,
        lineHeight: "48px",
      },
    },
  },
}));

type StyledKeyVisualProps = {
  imageSrc: string;
  imageAlt: string;
  useBackground?: boolean;
};

// ensure to load image with cross-origin modifier set, so
// if it is cached, it will not be cached as "opaque" response
// which adds additional 7MiB to the storage quota on Chrome
// this is required, as css property background-image url-param
// does not support url-modifier like crossOrigin
const imageUrlToDataUrl = async (imageUrl: string) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.onerror = reject;
    img.src = imageUrl;
  });

const StyledKeyVisual: FC<PropsWithChildren<StyledKeyVisualProps>> = ({
  imageSrc,
  imageAlt,
  children,
  useBackground = false,
  ...rest
}) => {
  const { classes } = useStyles();
  const [style, setStyle] = useState<CSSProperties>();

  useLayoutEffect(() => {
    if (useBackground) {
      const createBackgroundStyle = async () => {
        try {
          const dataUrl = await imageUrlToDataUrl(imageSrc);
          setStyle({ backgroundImage: `url('${dataUrl}')` });
        } catch {
          setStyle({ backgroundImage: `url('${imageSrc}')` });
        }
      };

      createBackgroundStyle();
    }
  }, [imageSrc, useBackground]);

  return (
    <Box className={classes.root} mb={3} {...rest} style={style}>
      {!useBackground && (
        <img src={imageSrc} alt={imageAlt} crossOrigin="anonymous" />
      )}
      <div className={classes.overlay}>{children}</div>
    </Box>
  );
};

export default React.memo(StyledKeyVisual);
