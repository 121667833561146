import { useEffect } from "react";

export const useScrollRestoration: () => void = () => {
  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
  }, []);
};

export const useScrollToTop: () => void = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};
