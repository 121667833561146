const handleInstallPrompt = () => {
  // let deferredInstallPrompt;
  window.addEventListener("beforeinstallprompt", (e) => {
    // Prevents the default mini-infobar or install dialog from appearing on mobile
    e.preventDefault();
    // Save the event because you’ll need to trigger it later.
    // deferredInstallPrompt = e;
  });
};

export default handleInstallPrompt;
