import { executeRequest } from "_foundationExt/axios/axiosConfig";
import isInstanaActive from "tools/isInstanaActive";
import DEFAULT_SYNC_HEADERS from "../common/headers";
import { FetchSyncDataFunction } from "../common/types";
import productsSyncTimestamp from "./productsSyncTimestamp";
import saveProductDetails from "./saveProductDetails";

const doSyncProducts: FetchSyncDataFunction = async ({
  storeId,
  transactionContext,
  signal,
  onDownloadProgress,
  paginated,
  syncTimestamp,
  force = false,
}) => {
  try {
    const lastSync = force ? 0 : (await productsSyncTimestamp.get()) ?? 0;

    const params: Record<string, number | string> = {
      responseFormat: "json",
      lastSync,
    };
    if (paginated) {
      params.pageNumber = paginated.pageNumber;
      params.pageSize = paginated.pageSizeProducts;
    }

    if (isInstanaActive()) {
      ineum("reportEvent", "sync products", {
        timestamp: Date.now(),
        meta: {
          productsSyncTimestamp: lastSync,
          pageNumber: paginated?.pageNumber ?? -1,
          pageSize: paginated?.pageSizeProducts ?? -1,
        },
      });
    }

    const {
      data: {
        recordSetTotal,
        recordSetStartNumber,
        recordSetCount,
        timestamp: requestTimestamp,
        catalogEntryView,
      },
    } = await executeRequest({
      method: "GET",
      url: `${transactionContext}/store/${storeId}/efood/productview/@sync`,
      headers: DEFAULT_SYNC_HEADERS,
      params,
      signal,
      onDownloadProgress,
    });
    const products = Array.isArray(catalogEntryView)
      ? catalogEntryView
      : [catalogEntryView];
    const timestamp = syncTimestamp ?? requestTimestamp;
    await saveProductDetails({
      paginated: paginated != null,
      timestamp,
      products,
    });

    return Promise.resolve({
      recordSetTotal: recordSetTotal
        ? Number(recordSetTotal)
        : products?.length ?? 0,
      recordSetStartNumber: recordSetStartNumber
        ? Number(recordSetStartNumber)
        : 0,
      recordSetCount: recordSetCount
        ? Number(recordSetCount)
        : products?.length ?? 0,
      timestamp,
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

export default doSyncProducts;
