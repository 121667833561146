/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from "react";

import CardMedia, { CardMediaProps } from "@mui/material/CardMedia";
import { tss } from "tss-react/mui";
import CardMediaVideo from "./CardMediaVideo";

const useStyles = tss.create(({ theme }) => ({
  root: {
    margin: "-26px -26px 24px",
    backgroundSize: "100%",
    transition: theme.transitions.create(["background-size"]),
  },
}));

const getMediaComponent = (mediaSrc?: string): React.ElementType => {
  if (mediaSrc) {
    try {
      const url = new URL(mediaSrc);
      if (url.searchParams.get("type") === "video") return "video";
    } catch {
      // do nothing
    }
  }
  return "div";
};

const StyledCardMedia: FC<CardMediaProps> = ({ children, image, ...rest }) => {
  const { classes } = useStyles();
  const mediaComponent = getMediaComponent(image);
  const isVideo = mediaComponent === "video";

  if (isVideo && image) {
    return (
      <CardMedia classes={classes} component={CardMediaVideo} videoSrc={image}>
        {children}
      </CardMedia>
    );
  }

  return (
    <CardMedia
      classes={classes}
      component={mediaComponent}
      image={image}
      {...rest}>
      {children}
    </CardMedia>
  );
};

export { StyledCardMedia, getMediaComponent };
