import { isObject, isString } from "lodash-es";
import { FC } from "react";

const isFunctionComponent = (candidate: unknown): candidate is FC<unknown> =>
  isObject(candidate) && ("displayName" in candidate || "name" in candidate);

export default function getDisplayName(component?: unknown) {
  return (
    (isFunctionComponent(component) &&
      (component.displayName || component.name)) ||
    (isString(component) && component.length > 0 && component) ||
    "Unknown"
  );
}
