import { TrackShowType } from ".";

const getType = (emsName: string | undefined): TrackShowType | undefined => {
  if (!emsName) return undefined;
  if (emsName.includes("basket")) return "cart" as TrackShowType;
  if (emsName.includes("Product")) return "pdp" as TrackShowType;
  if (emsName.includes("Category")) return "side" as TrackShowType;
  return undefined;
};

export default getType;
