// Standard libraries
import React, { FC, useCallback, useMemo, useState } from "react";
import { useAppDispatch } from "_redux/hooks";
// Redux
import { useSite } from "_foundationExt/hooks/useSite";
import * as userActions from "_redux/actions/user";
import { DropShipConfig } from "_redux/reducers";
// mui
import { StyledDatePicker } from "components/StyledUI";
// Components
import { BasketIdentifier } from "types/Order";
import { isEqual } from "lodash-es";

interface DeliveryDateBoxParams {
  dropShipConfig: DropShipConfig;
  orderId: string;
  deliveryDate: string | Date | null;
  articleType?: string;
  dropShipType?: string;
}

const toMilliseconds = (hour: number) => hour * 60 * 60 * 1000;

const DeliveryDateBoxTPD: FC<DeliveryDateBoxParams> = ({
  dropShipConfig,
  orderId,
  deliveryDate,
  articleType,
  dropShipType,
}) => {
  const dispatch = useAppDispatch();
  const { currentSite } = useSite();

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    deliveryDate ? new Date(deliveryDate) : null
  );

  const basketIdentifier = useMemo(() => {
    if (!articleType) {
      return null;
    }

    const result: BasketIdentifier = {
      type: articleType,
      dropShipType: dropShipType ?? null,
      deliveryDate: deliveryDate ? { date: new Date(deliveryDate) } : null,
    };

    return result;
  }, [articleType, dropShipType, deliveryDate]);

  const setDeliveryDate = useCallback(
    (date: Date | null) => {
      const payload = {
        storeId: currentSite?.storeID,
        date,
        orderId,
        basketIdentifier,
        refreshAvailabilities: false,
      };
      dispatch(userActions.UPDATE_DELIVERY_DATE_REQUEST_ACTION(payload));
      setSelectedDate(date);
    },
    [currentSite?.storeID, orderId, basketIdentifier, dispatch]
  );

  const filterDeliveryDates = useCallback(
    (date: Date) => {
      const dateToValidate = new Date(date.getTime());
      dateToValidate.setHours(0, 0, 0, 0);
      const nowWithLeadTime =
        Date.now() + toMilliseconds(dropShipConfig.leadTime ?? 0);

      if (dateToValidate.getTime() < nowWithLeadTime) {
        return true;
      }

      const day = dateToValidate.getDay();
      if (day === 0) return !dropShipConfig.deliveryDays.sunday;
      if (day === 1) return !dropShipConfig.deliveryDays.monday;
      if (day === 2) return !dropShipConfig.deliveryDays.tuesday;
      if (day === 3) return !dropShipConfig.deliveryDays.wednesday;
      if (day === 4) return !dropShipConfig.deliveryDays.thursday;
      if (day === 5) return !dropShipConfig.deliveryDays.friday;
      if (day === 6) return !dropShipConfig.deliveryDays.saturday;

      return false;
    },
    [dropShipConfig]
  );

  return (
    <StyledDatePicker
      shouldDisableDate={filterDeliveryDates}
      onAccept={setDeliveryDate}
      selectedDate={selectedDate}
      error={!deliveryDate}
      key={`deliverydatepicker-${articleType}-${
        basketIdentifier?.dropShipType
      }-${deliveryDate || 0}`}
    />
  );
};

export default React.memo(DeliveryDateBoxTPD, isEqual);
