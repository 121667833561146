import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { StyledButton } from "components/StyledUI";
import { tss } from "tss-react/mui";
import type { ContentProps } from "../types";

const useStyles = tss.create(({ theme }) => ({
  wrapper: {
    marginTop: theme.spacing(2),
  },
}));

export type MessageParams = {
  messageKey?: string;
  titleKey?: string;
  closeButton?: boolean;
};

const Message: FC<ContentProps<MessageParams>> = ({ close, params }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  if (!params) {
    return null;
  }

  const { messageKey = "", closeButton = false } = params;

  return (
    <>
      <Typography variant="body2" className={classes.wrapper}>
        {t(messageKey)}
      </Typography>
      {closeButton && (
        <div className={classes.wrapper}>
          <StyledButton variant="outlined" onClick={close}>
            {t("action.close")}
          </StyledButton>
        </div>
      )}
    </>
  );
};

export default Message;
