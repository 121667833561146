import React, { FC, PropsWithChildren } from "react";
import { StyledTooltip } from "components/StyledUI";
import { tss } from "tss-react/mui";

const handleSize = (value: unknown) =>
  Number.isNaN(value) ? `${value}` : `${value}px`;

const useStyles = tss
  .withParams<Pick<StyledIconProps, "color" | "sizeX" | "sizeY">>()
  .create(({ color, sizeX, sizeY }) => ({
    root: {
      color: color || "inherit",
      display: "flex",
      "& svg": {
        height: sizeX ? handleSize(sizeX) : "16px",
        width: sizeY ? handleSize(sizeY) : "16px",
        pointerEvents: "none",
      },
    },
  }));

type StyledIconProps = {
  size?: string;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  tooltip?: string;
  delay?: number;
  sizeX?: string;
  sizeY?: string;
};

const StyledIcon: FC<PropsWithChildren<StyledIconProps>> = ({
  children,
  className,
  color,
  size,
  tooltip,
  delay,
  sizeX,
  sizeY,
  ...rest
}) => {
  const { classes, cx } = useStyles({
    color,
    sizeX: sizeX || size,
    sizeY: sizeY || size,
  });

  if (tooltip) {
    return (
      <StyledTooltip
        title={tooltip}
        enterDelay={delay || 2500}
        enterNextDelay={delay || 2500}>
        <div className={cx(classes.root, className)} {...rest}>
          {children}
        </div>
      </StyledTooltip>
    );
  }

  return (
    <div className={cx(classes.root, className)} {...rest}>
      {children}
    </div>
  );
};

export default StyledIcon;
