import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { ReactComponent as CloseIcon } from "assets/icons/close_thicker.svg";
import { ReactComponent as FlashOnIcon } from "assets/icons/flash-on.svg";
import { ReactComponent as FlashOffIcon } from "assets/icons/flash-off.svg";
import { ReactComponent as HelpQuestionIcon } from "assets/icons/help-question.svg";
import { IconButton } from "@mui/material";

import {
  Html5QrcodeSupportedFormats,
  type QrcodeSuccessCallback,
} from "@efood-commerce-sdk/html5-qrcode";
import { tss } from "tss-react/mui";
import { BcScannerPlugin } from "./BcScannerPlugin";

const useStyles = tss.create(() => ({
  tile: {
    position: "relative",
    "z-index": "500",
    width: "calc(100% - 3px)",
    height: "100%",
    left: "1px",
  },
  closeIcon: {
    width: "20px;",
    "z-index": "505",
    color: "#ffffff",
  },
  closeIconButton: {
    "z-index": "505",
    position: "absolute",
    right: "10px",
  },
  torchIconButton: {
    "z-index": "505",
    position: "absolute",
    left: "38px",
  },
  torchIcon: {
    width: "28px;",
    "z-index": "505",
    color: "#ffffff",
  },
  helpIconButton: {
    "z-index": "505",
    position: "absolute",
    left: "0px",
  },
  helpIcon: {
    width: "28px;",
    "z-index": "505",
    color: "#ffffff",
  },
}));

export interface CameraViewDimension {
  width?: number;
  height?: number;
}

export type BCSannerProps = {
  onResult: (searchTerm: string) => void;
  cameraViewDimension: CameraViewDimension;
  onClose: () => void;
  showHelpDialog: () => void;
};

const formatsToSupport: Html5QrcodeSupportedFormats[] = [
  Html5QrcodeSupportedFormats.CODE_128,
  Html5QrcodeSupportedFormats.EAN_8,
  Html5QrcodeSupportedFormats.EAN_13,
];

const BcScanner: FC<BCSannerProps> = ({
  onResult,
  cameraViewDimension,
  onClose,
  showHelpDialog,
}) => {
  const { classes } = useStyles();
  const [scanner, setScanner] = useState<BcScannerPlugin>();

  const flashEnabled = scanner?.isTorchEnabled() ?? false;
  const flashSupported = useMemo(
    () => scanner?.isTorchFeatureSupported() ?? false,
    [scanner]
  );

  const switchFlash = useCallback(() => {
    if (
      scanner != null &&
      scanner.isStarted() &&
      scanner.isTorchFeatureSupported()
    ) {
      scanner.switchTorch();
    }
  }, [scanner]);

  const scannerConfig = useMemo(() => {
    if (cameraViewDimension?.width == null) {
      return undefined;
    }
    let qrBoxHeight = cameraViewDimension.width / 2;
    if (qrBoxHeight < 136) qrBoxHeight = 136;
    if (qrBoxHeight > 250) qrBoxHeight = 250;

    return {
      fps: 10,
      qrbox: {
        width: qrBoxHeight,
        height: qrBoxHeight,
      },
      showTorchButtonIfSupported: true,
      formatsToSupport,
      useBarCodeDetectorIfSupported: true,
    };
  }, [cameraViewDimension?.width]);

  useEffect(() => {
    let newScanner: BcScannerPlugin;
    if (scannerConfig != null) {
      newScanner = new BcScannerPlugin("bc_scanner", scannerConfig, false);
      setScanner(newScanner);
    }

    return () => {
      if (newScanner) {
        const stopScanner = async () => {
          try {
            newScanner.disableTorch();
            await newScanner.clear();
          } catch {
            // ignore error
          }
        };
        stopScanner();
      }
    };
  }, [scannerConfig]);

  const qrCodeSuccessCallback: QrcodeSuccessCallback = useCallback(
    (decodedText, decodedResult) => {
      const { format } = decodedResult.result;

      if (format?.format && formatsToSupport.indexOf(format.format) !== -1) {
        onResult(decodedText);
      }
    },
    [onResult]
  );

  // start scanner async, to avoid starting it, while the component is directly unmounted
  useEffect(() => {
    if (scanner) {
      scanner.render(qrCodeSuccessCallback).catch(() => {
        // ignore error;
      });
    }
  }, [scanner, qrCodeSuccessCallback]);

  return (
    <div className={classes.tile}>
      <IconButton
        className={classes.helpIconButton}
        onClick={showHelpDialog}
        size="large">
        <HelpQuestionIcon className={classes.helpIcon} />
      </IconButton>
      {flashSupported && (
        <IconButton
          className={classes.torchIconButton}
          onClick={switchFlash}
          size="large">
          {flashEnabled && <FlashOnIcon className={classes.torchIcon} />}
          {!flashEnabled && <FlashOffIcon className={classes.torchIcon} />}
        </IconButton>
      )}
      <IconButton
        edge="end"
        onClick={onClose}
        className={classes.closeIconButton}
        size="large">
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
      <div id="bc_scanner" />
    </div>
  );
};
export default BcScanner;
