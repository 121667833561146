/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosPromise } from "axios";
import { site } from "_foundationExt/constants/site";
import { PredictionApi } from "@efood-commerce-sdk/typescript-axios-transaction-efood";
import { getSite } from "_foundation/hooks/useSite";

const predictionApiInstance = new PredictionApi(
  undefined,
  site.transactionContext
);

const predictivBasketService = {
  getPredictionForCustomer(): AxiosPromise<any> {
    const siteInfo = getSite();
    if (!siteInfo) {
      return Promise.reject();
    }

    return predictionApiInstance.getPredictionForCurrentUser(siteInfo?.storeID);
  },
};

export default predictivBasketService;
