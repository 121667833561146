// react
import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
// components
import { Cart } from "components/cart";

const Checkout: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("cart.mycart")}</title>
      </Helmet>

      <Cart />
    </>
  );
};

export default Checkout;
