export const footerConfig = {
  page: {
    name: "register",
  },
  espotFooterLinks: {
    eSpotName: "content_spot_footer_links",
  },
  espotFooter: {
    eSpotName: "content_spot_footer",
  },
};
