// react
import React, { FC, useMemo } from "react";
import { isOrderItem, OrderItem } from "types/Order";
import { BaseProduct } from "types/Product";
import { StyledShow } from "components/StyledUI";
import { TrackShowType } from "components/matomo";
import QuantitySelect from "./QuantitySelect";
import RemoveOrderItem from "./RemoveOrderItem";

interface QuantityProps {
  data: OrderItem | BaseProduct;
  size?: "small" | "medium";
  fullWidth?: boolean;
  type?: string;
  trackType?: TrackShowType;
  additionalQuantityInformationClass?: string;
  galleryView?: boolean;
  formattedPrice?: string;
  showCartHint?: boolean;
  addProductToCart?: boolean;
  multipleScanCounter?: number;
}

const getDontDisplaySelect = (product: BaseProduct) => {
  if (!product) {
    return false;
  }

  const { matrix, prices } = product;
  const matrixEmpty = !matrix?.length;
  const pricesEmptyOrNoPriceExists =
    !prices?.length || !prices.some((p) => p.price > 0);

  /**
   * dont display select when:
   * - not buyable
   * - matrix empty
   * - price empty
   */
  return (
    product.buyable !== "true" || matrixEmpty || pricesEmptyOrNoPriceExists
  );
};

const Quantity: FC<QuantityProps> = ({
  data,
  size,
  fullWidth,
  type = "",
  trackType,
  additionalQuantityInformationClass,
  galleryView = false,
  formattedPrice,
  showCartHint,
  addProductToCart,
  multipleScanCounter,
}) => {
  const isAnOrderItem = isOrderItem(data);
  const product = isAnOrderItem ? data.article : data;
  const orderItemId = isAnOrderItem ? data.orderitemId : undefined;
  const isFreeGift = isAnOrderItem ? data.isFreeGift : false;

  const dontDisplaySelect = useMemo(
    () => getDontDisplaySelect(product),
    [product]
  );

  /**
   * display nothing when empty orderItemId and product not buyable
   */
  if (product && dontDisplaySelect && !orderItemId) return null;

  return (
    <StyledShow loggedIn>
      {!dontDisplaySelect && (
        <QuantitySelect
          key={`quantity_select_${product.partNumber}`}
          product={product}
          size={size}
          fullWidth={fullWidth}
          isFreeGift={isFreeGift}
          type={type}
          trackType={trackType}
          additionalQuantityInformationClass={
            additionalQuantityInformationClass
          }
          galleryView={galleryView}
          formattedPrice={formattedPrice}
          showCartHint={showCartHint}
          addProductToCart={addProductToCart}
          multipleScanCounter={multipleScanCounter}
        />
      )}
      {orderItemId && dontDisplaySelect && (
        <RemoveOrderItem orderItemId={orderItemId} />
      )}
    </StyledShow>
  );
};

export default Quantity;
