import React, { FC } from "react";
import { FormGroup } from "@mui/material";
import { StyledFilterDropdown } from "components/StyledUI";
import { Filter } from "types/Search";
import FilterOption from "./FilterOption";

interface FilterDropdownSimpleProps {
  filter: Filter;
}

const FilterDropdownSimple: FC<FilterDropdownSimpleProps> = ({ filter }) => (
  <StyledFilterDropdown buttonLabel={filter.name}>
    {filter.entry.map((filterEntry) => (
      <FilterOption
        filterKey={filter.value}
        filterEntry={filterEntry}
        key={filterEntry.value}
      />
    ))}
    <FormGroup />
  </StyledFilterDropdown>
);

export default FilterDropdownSimple;
