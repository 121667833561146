/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useEffect, useState, SetStateAction, Dispatch } from "react";
import { useAppSelector } from "_redux/hooks";
import { basketPredictionSelector } from "_redux/selectors/user";
import { BasketPrediction } from "types/Order";

import productServiceExt from "_foundationExt/apis/search/product.service";
import { useSite } from "_foundation/hooks/useSite";
import { Product } from "types/Product";
import { SiteInfo } from "_redux/reducers";

/**
 * Takes these prediction lists:
 *
 * clusterPrediction: {115940: 0.111, 131358: 0.09}
 * individualPrediction: {049127: 1, 061406: 1}
 *
 * and returns a list with all the ids; for example [115940, 131358, 049127, 061406]
 */
const determineBasketPrediction = (
  basketPrediction: BasketPrediction | null
) => {
  if (!basketPrediction) {
    return null;
  }

  let basketPredictionIds: string[] = [];

  if (basketPrediction.cluster) {
    const clusterPredictionKeys = Object.keys(basketPrediction.cluster);

    basketPredictionIds = [...clusterPredictionKeys];
  }

  if (basketPrediction.individual) {
    const individualPredictionKeys = Object.keys(basketPrediction.individual);

    basketPredictionIds = [...basketPredictionIds, ...individualPredictionKeys];
  }

  return basketPredictionIds;
};

const useBasketPredictionIds = (): string[] | null => {
  const basketPrediction = useAppSelector(basketPredictionSelector);

  const memorizedBasketPredictionIds = useMemo(
    () => determineBasketPrediction(basketPrediction),
    [basketPrediction]
  );

  return memorizedBasketPredictionIds;
};

/**
 * Takes a list of ids as input and concatenates the ids with "OR".
 * Result looks like this for example:
 * 115940%20OR%20131358%20OR%20062969
 */
const buildURIEncodedSearchTerm = (basketPredictionIds: string[]) => {
  const searchTerm = basketPredictionIds.reduce((concateString, id, index) => {
    if (index === 0) {
      return id;
    }

    return `${concateString} OR ${id}`;
  }, "");

  return encodeURI(searchTerm);
};

const fetchProduct = async (
  site: SiteInfo,
  basketPredictionIds: string[],
  setPredictionProducts: Dispatch<SetStateAction<Product[] | null>>,
  setError: Dispatch<SetStateAction<boolean>>,
  setPredictionProductsTotal: Dispatch<SetStateAction<number>>,
  pageSize?: string | null,
  pageNumber?: number | null
) => {
  const searchTerm = buildURIEncodedSearchTerm(basketPredictionIds);

  const parameters: any = {
    storeId: site.storeID,
    term: searchTerm,
    profileName: "EFood_findProductsByPartNumbers",
  };

  if (pageSize && pageNumber) {
    parameters.$queryParameters = {
      pageSize,
      pageNumber,
    };
  }

  try {
    const response = await productServiceExt.bySearchTerm({
      ...parameters,
    });

    setPredictionProducts(response.data.catalogEntryView);
    setPredictionProductsTotal(response.data.recordSetTotal);
  } catch (error) {
    setError(true);
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useFetchProducts = (
  basketPredictionIds: string[] | null,
  pageSize?: string | null,
  pageNumber?: number | null
) => {
  const { currentSite } = useSite();

  const [predictionProducts, setPredictionProducts] = useState<
    Product[] | null
  >(null);
  const [predictionProductsTotal, setPredictionProductsTotal] = useState(0);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (
      currentSite != null &&
      basketPredictionIds &&
      basketPredictionIds.length > 0
    ) {
      fetchProduct(
        currentSite,
        basketPredictionIds,
        setPredictionProducts,
        setError,
        setPredictionProductsTotal,
        pageSize,
        pageNumber
      );
    }
  }, [currentSite, basketPredictionIds, pageSize, pageNumber]);

  return {
    predictionProducts,
    error,
    predictionProductsTotal,
  };
};

export { useBasketPredictionIds, useFetchProducts };
