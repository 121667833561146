/* eslint-disable react/jsx-props-no-spreading */

import React, { FC } from "react";
import DialogContent from "@mui/material/DialogContent";
import { DialogContentProps } from "@mui/material/";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  root: {
    padding: `0 ${theme.spacing(3)} ${theme.spacing(4)}`,
  },
}));

const StyledDialogContent: FC<DialogContentProps> = (props) => {
  const { classes } = useStyles();

  return <DialogContent classes={classes} {...props} />;
};

export default StyledDialogContent;
