import { all } from "redux-saga/effects";
import * as USER from "./watchers/user";
import * as ERROR from "./watchers/error";
import * as ORDER from "./watchers/order";
import * as CATALOG from "./watchers/catalog";
import * as MARKETING from "./watchers/marketingEvent";

export default function* rootSaga() {
  yield all([
    ERROR.watchSaga(),
    USER.watchSaga(),
    ORDER.watchSaga(),
    CATALOG.watchSaga(),
    MARKETING.watchSaga(),
  ]);
}
