export const INIT_SITE_SUCCESS = "INIT_SITE_SUCCESS";
export const SET_TRACKING_PARAMS = "SET_TRACKING_PARAMS";
export const SCROLL_TO_TOP = "SCROLL_TO_TOP";
export const SHOW_DIALOG = "SHOW_DIALOG";
export const CLOSE_DIALOG = "CLOSE_DIALOG";
export const UPDATE_ONLINE_STATUS = "UPDATE_ONLINE_STATUS";
export const UPDATE_FORCE_STANDALONE_MODE = "UPDATE_FORCE_STANDALONE_MODE";
export const TRIGGER_SYNC_MANUALLY = "TRIGGER_SYNC_MANUALLY";
export const RESET_SYNC_MANUALLY = "RESET_SYNC_MANUALLY";
export const UPDATE_SYNC_RUNNING = "UPDATE_SYNC_RUNNING";
