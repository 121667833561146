/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Dispatch, FC, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { StyledIcon } from "components/StyledUI";
import { ReactComponent as MenuIcon } from "assets/icons/navigation-menu.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import type { ZIndexWithExtension } from "theme/zIndexExtension";
import { styled } from "@mui/material";

interface SideBarButtonProps {
  isSideBarOpen: boolean;
  setSideBarOpenStatus: Dispatch<SetStateAction<boolean>>;
}

export const SideBarButtonRoot = styled("div", {
  name: "Sidebar",
  slot: "button",
})(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.background.default,
  display: "inline-block",
  lineHeight: 0,
  padding: "12px",
  zIndex: (theme.zIndex as ZIndexWithExtension).extension.hamburgerButton,

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(3),
  },

  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(4, 3, 3, 3),
  },

  "&:hover": {
    background: theme.palette.primary.light,
  },

  "&:active": {
    background: theme.palette.primary.dark,
  },
}));

const SideBarButton: FC<SideBarButtonProps> = ({
  isSideBarOpen,
  setSideBarOpenStatus,
}) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    setSideBarOpenStatus((prevState) => !prevState);
  }, [setSideBarOpenStatus]);

  return (
    <SideBarButtonRoot onClick={handleClick}>
      <StyledIcon size="40" tooltip={t("iconTooltips.sideBarButton")}>
        {isSideBarOpen ? <CloseIcon /> : <MenuIcon />}
      </StyledIcon>
    </SideBarButtonRoot>
  );
};

export default SideBarButton;
