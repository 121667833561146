// react
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
// redux
import useShipInfosSelector from "_redux/hooks/useShipInfosSelector";
// mui
import { Typography, Box } from "@mui/material";

const ShippingAddress: FC = () => {
  const { t } = useTranslation();
  const shippingInfos = useShipInfosSelector();

  if (shippingInfos) {
    return (
      <Box>
        <Typography variant="h3" mb={1}>
          {t("cart.shippingInformations")}
        </Typography>
        <Typography variant="body1">{shippingInfos.address1}</Typography>
        <Typography variant="body1">{shippingInfos.address2}</Typography>
        <Typography variant="body1">{shippingInfos.address3}</Typography>
        <Typography variant="body1" mb={1}>
          {`${shippingInfos.zipcode} ${shippingInfos.city}`}
        </Typography>
        {shippingInfos.email &&
          shippingInfos.email.split(",").map((item, index, arr) => (
            <Typography
              key={item}
              variant="body1"
              mb={index + 1 === arr.length ? 1 : undefined}>
              {item}
            </Typography>
          ))}
      </Box>
    );
  }
  return null;
};

export default ShippingAddress;
