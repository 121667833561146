import React, { FC, PropsWithChildren, Suspense } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { StyledProgress } from "components/StyledUI";

const Lazy: FC<PropsWithChildren> = ({ children }) => (
  <Suspense
    fallback={
      <Grid container justifyContent="center">
        <StyledProgress />
      </Grid>
    }>
    {children}
  </Suspense>
);

export default Lazy;
