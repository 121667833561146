import React, { FC } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import { ReactComponent as QuestionIcon } from "assets/icons/question-circle.svg";
import { ReactComponent as BinIcon } from "assets/icons/bin-1.svg";
import { StyledButton, StyledTooltip } from "components/StyledUI";
import { useOnlineStatus } from "_foundationExt/hooks/useOnlineStatus";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  wrapper: {
    marginBottom: theme.spacing(1),

    [theme.breakpoints.only("xs")]: {
      display: "flex",
      width: "100%",

      "& > span:first-of-type": {
        flex: 1,
      },
    },

    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2),
      display: "inline-block",
    },
  },
}));

export interface PromotionCode {
  codeName: string;
  tooltip: string;
}

interface CartPromotionCodeButtoProps {
  promotionCode: PromotionCode;
  deletePromotion: (promotionCode: string) => Promise<void>;
}

const CartPromotionCodeButton: FC<CartPromotionCodeButtoProps> = ({
  promotionCode,
  deletePromotion,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const xsViewport = useMediaQuery(theme.breakpoints.only("xs"));
  const { isOffline } = useOnlineStatus();

  return (
    <div className={classes.wrapper} key={promotionCode.codeName}>
      <StyledTooltip title={promotionCode.tooltip}>
        <StyledButton
          size="small"
          color="inherit"
          fullWidth={!!xsViewport}
          endIcon={<QuestionIcon />}>
          {promotionCode.codeName}
        </StyledButton>
      </StyledTooltip>
      <Box ml={1} component="span">
        <StyledButton
          color="secondary"
          startIcon={<BinIcon />}
          size="small"
          onClick={() => deletePromotion(promotionCode.codeName)}
          disabled={isOffline}
        />
      </Box>
    </div>
  );
};

export default CartPromotionCodeButton;
