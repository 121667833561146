import { useCallback, useEffect, useMemo, useState } from "react";

import { BreadcrumbData } from "components/StyledUI/StyledBreadcrumb/StyledBreadcrumb";
import productServiceExt from "_foundationExt/apis/search/product.service";
import { CustomerProductlist } from "types/CustomerProductlist";
import { PROFILE_NAME } from "constants/search";
import { useAbortControllers, useEFoodConfig } from "_foundationExt/hooks";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "_redux/hooks";
import { userStockDeliveryDateDate } from "_redux/selectors/user";
import { isCanceledError } from "_foundationExt/axios/axiosConfig";
import { CategoriesLookupEntry, SearchResult } from "../../types/Search";
import { useSite } from "../../_foundation/hooks/useSite";
import {
  determineCategoriesLookup,
  determineFilterData,
  determineSubCategories,
} from "./utils";

export type LoadingState =
  | "initial"
  | "reload"
  | "loading"
  | "error"
  | "completed";

const useProductList = (
  categoryId: string,
  pageSize: string,
  page: number,
  selectedProductlistFilter: CustomerProductlist[]
) => {
  const { currentSite } = useSite();
  const deliveryDate = useAppSelector(userStockDeliveryDateDate);
  const eFoodConfig = useEFoodConfig();
  const location = useLocation();
  const abortControllers = useAbortControllers();

  const [searchResult, setSearchResult] = useState<SearchResult>({
    searchTerm: null,
    originalNumberOfProducts: 0,
    totalNumberOfProducts: 0,
    products: [],
    topProducts: [],
    categoryFacet: { categories: [] },
    productList: null,
    filterFacets: [],
    spellCheckSuggestions: null,
    categoryNote: null,
    searchOrderset: false,
    categoriesLookup: new Map<string, CategoriesLookupEntry>(),
  });
  const [breadcrumbData, setBreadcrumbData] = useState<BreadcrumbData[] | null>(
    null
  );
  const [loadingState, setLoadingState] = useState<LoadingState>("initial");
  const [reloadToggle, setReloadToggle] = useState(false);

  const refetch = useCallback(() => {
    setReloadToggle((oldValue) => !oldValue);
  }, []);

  const selectedFilterOptions = useMemo(() => {
    const urlParam = new URLSearchParams(location.hash.substring(1));
    const result: string[] = [];
    if (urlParam?.size) {
      urlParam.forEach((value, key) => {
        result.push(encodeURIComponent(`${key}:"${value}"`));
      });
    }
    return result;
  }, [location.hash]);

  useEffect(() => {
    if (currentSite?.storeID) {
      setLoadingState("loading");

      const { signal } = abortControllers.create();
      const topProductCount = eFoodConfig.topProductCount || 0;

      const parameters = {
        storeId: currentSite.storeID,
        categoryId,
        profileName: PROFILE_NAME.FIND_WITHOUT_TOP_RANKED_PRODUCTS_BY_CATEGORY,
        $queryParameters: {
          orderBy: eFoodConfig.internTxt ? "6" : "5",
          pageSize,
          pageNumber: page,
        } as {
          orderBy: string;
          pageSize: string;
          pageNumber: number;
          productList?: string;
          facet?: string[];
          deliveryDate?: Date;
        },
        signal,
      };

      if (selectedProductlistFilter?.length > 0) {
        const ids = selectedProductlistFilter.map((list) => list.id).join(",");
        parameters.$queryParameters.productList = ids;
      }

      if (deliveryDate) {
        parameters.$queryParameters.deliveryDate = deliveryDate;
      }

      if (selectedFilterOptions.length) {
        parameters.$queryParameters.facet = selectedFilterOptions;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const executeSearch = (pagingShift: number, topProducts: any[]): void => {
        const excludedCatalogEntries = topProducts
          .map((p) => p.uniqueID)
          .join(",");

        const visTopProducts = page > 1 ? [] : topProducts;

        productServiceExt
          .byCategory({
            ...parameters,
            $queryParameters: {
              ...parameters.$queryParameters,
              pagingShift,
              excludedCatalogEntries,
            },
          })
          .then((response) => {
            const products = response?.data?.catalogEntryView || [];
            const subCategoriesData = determineSubCategories(response);
            const filterList = determineFilterData(response);
            const categoriesLookup = determineCategoriesLookup(
              response,
              parameters.categoryId
            );

            setSearchResult({
              searchTerm: null,
              originalNumberOfProducts: response.data.recordSetTotal,
              totalNumberOfProducts: response.data.recordSetTotal,
              products,
              topProducts: visTopProducts,
              categoryFacet: { categories: subCategoriesData },
              filterFacets: filterList,
              productList: null,
              spellCheckSuggestions: null,
              categoryNote: null,
              searchOrderset: false,
              categoriesLookup,
            });
            setBreadcrumbData(response.data.breadCrumbTrailEntryView);
          })
          .catch((e) => {
            if (!isCanceledError(e)) {
              setLoadingState("error");
            }
          })
          .finally(() => {
            setLoadingState("completed");
          });
      };

      if (topProductCount > 0) {
        productServiceExt
          .byCategory({
            ...parameters,
            profileName: PROFILE_NAME.FIND_TOP_RANKED_PRODUCTS_BY_CATEGORY,
            $queryParameters: {
              ...parameters.$queryParameters,
              pageSize: topProductCount,
              orderBy: 1,
              pageNumber: 1,
            },
          })
          .then((response) => {
            const topProducts = response?.data?.catalogEntryView || [];
            const pagingShift = topProducts?.length || 0;
            executeSearch(pagingShift, topProducts);
          })
          .catch((e) => {
            if (!isCanceledError(e)) {
              setLoadingState("error");
            }
          });
      } else {
        executeSearch(0, []);
      }
    }
  }, [
    categoryId,
    deliveryDate,
    eFoodConfig.internTxt,
    eFoodConfig.topProductCount,
    currentSite?.storeID,
    page,
    pageSize,
    reloadToggle,
    selectedFilterOptions,
    selectedProductlistFilter,
    abortControllers,
  ]);

  return useMemo(
    () => ({
      searchResult,
      breadcrumbData,
      loadingState,
      error: loadingState === "error",
      refetch,
    }),
    [searchResult, breadcrumbData, loadingState, refetch]
  );
};

export { useProductList };
