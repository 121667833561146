import React, { FC } from "react";
import { Divider, DividerProps, Box, BoxProps } from "@mui/material";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  root: {
    backgroundColor: "unset",
    borderBottom: "0px",
    borderTop: `1px dashed ${theme.palette.grey[800]}`,
  },
  light: {
    borderTop: `1px dashed ${theme.palette.grey[300]}`,
  },
  textDivider: {
    padding: `0 ${theme.spacing(1.5)}`,
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.spacing(0.25),
    color: "#55841e",
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(2.5),
  },
  dark: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
}));

interface Props {
  text?: string;
  light?: boolean;
  py?: number;
  dark?: boolean;
}

type StyledDividerProps = Props & DividerProps & BoxProps;

const StyledDivider: FC<StyledDividerProps> = (props) => {
  const { classes, cx } = useStyles();

  const { text, py, light, dark, ...boxProps } = props;

  return (
    <Box py={py || 2} {...boxProps}>
      {text == null && (
        <Divider
          classes={{ root: classes.root, light: classes.light }}
          light={light}
        />
      )}
      {text != null && (
        <div className={cx(classes.textDivider, { [classes.dark]: dark })}>
          {text}
        </div>
      )}
    </Box>
  );
};

export { StyledDivider };
