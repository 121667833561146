import { Workbox, WorkboxLifecycleWaitingEvent } from "workbox-window";
import { AppStore } from "_redux/store";
import { SHOW_DIALOG_ACTION } from "_redux/actions/site";
import { syncReduxStoreWithSW } from "./context";

type Config = {
  store?: AppStore;
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export function unregister(): void {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch(() => {
        // do nothing
      });
  }
}

export function registerWithWorkbox(config?: Config) {
  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    const wb = new Workbox(`${process.env.PUBLIC_URL}/service-worker.js`);

    const showSkipWaitingPrompt = async (
      _event: WorkboxLifecycleWaitingEvent
    ) => {
      wb.addEventListener("controlling", () => {
        window.location.reload();
      });
      const dialogCallback = (updateAccepted: boolean) => {
        if (updateAccepted) {
          wb.messageSkipWaiting();
        }
      };

      config?.store?.dispatch(
        SHOW_DIALOG_ACTION({
          type: "ServiceWorkerUpdateAvailable",
          closeable: true,
          params: {
            callback: dialogCallback,
          },
        })
      );
    };

    wb.addEventListener("waiting", (event) => {
      showSkipWaitingPrompt(event);
    });

    if (config?.store) {
      syncReduxStoreWithSW(config.store, wb);
    }

    wb.register();

    return wb;
  }

  return undefined;
}
