import { DeliveryDate as ResponseDeliveryDate } from "@efood-commerce-sdk/typescript-axios-transaction-efood";
import { isObject } from "lodash-es";
import { DeliveryDate } from "types/Order";

export const toDate = (date: string | Date) => new Date(date);

export const fromResponseDeliveryDate = (source: ResponseDeliveryDate) => {
  const target: DeliveryDate = { date: toDate(source.date) };

  if (source.validUntil) {
    target.validUntil = toDate(source.validUntil);
  }

  return target;
};

export const fromResponseDeliveryDates = (sources: ResponseDeliveryDate[]) =>
  sources.map(fromResponseDeliveryDate);

export const toResponseDeliveryDate = (source: DeliveryDate) => {
  const target: ResponseDeliveryDate = {
    date: source.date.toISOString(),
  };

  if (source.validUntil) {
    target.validUntil = source.validUntil.toISOString();
  }

  return target;
};

export const toResponseDeliveryDates = (sources: DeliveryDate[]) =>
  sources.map(toResponseDeliveryDate);

export const isDeliveryDate = (
  deliveryDate?: unknown
): deliveryDate is DeliveryDate =>
  isObject(deliveryDate) && "date" in deliveryDate;

export const getDeliveryDateDate = (deliveryDate?: DeliveryDate | null) =>
  isDeliveryDate(deliveryDate) ? toDate(deliveryDate.date) : null;

export const isEqual = (date1?: Date | string, date2?: Date | string) => {
  if (date1 === date2) return true;
  if (date1 != null && date2 == null) return false;
  if (date1 == null && date2 != null) return false;

  return toDate(date1!).getTime() === toDate(date2!).getTime();
};
