import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";

import { StyledButton, StyledProgress } from "components/StyledUI";
import { tss } from "tss-react/mui";
import offlineSyncEnabled from "pwa/offline/common/OfflineSyncEnabled";
import { ContentProps } from "components/dialog/types";
import { eFoodDB } from "pwa/db/efood.db";
import AllowSyncDialogContent from "./AlloSyncDialogContent";
import lastRunSyncTimestamp from "../lastRun/lastRunSyncTimestamp";

const useStyles = tss.create(({ theme }) => ({
  buttonStack: {
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
    flexDirection: "column-reverse",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  button: {
    minWidth: 100,
  },
}));

export type AllowSyncDialogParams = Record<string, unknown>;

type Props = ContentProps<AllowSyncDialogParams>;

const AllowSyncDialog: FC<Props> = ({ close }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [disagreed, setDisagreed] = useState(false);
  const [agreed, setAgreed] = useState(false);

  return (
    <>
      <AllowSyncDialogContent />
      <Stack className={classes.buttonStack}>
        <StyledButton
          className={classes.button}
          variant="outlined"
          onClick={async () => {
            setDisagreed(true);
            setAgreed(false);
            try {
              await eFoodDB.clearOfflineData();
              await offlineSyncEnabled.put(false);
            } catch {
              // ignore error
            }

            close();
          }}
          disabled={disagreed || agreed}>
          {disagreed ? (
            <StyledProgress size={24} color="inherit" />
          ) : (
            t("offline.allowSyncDialog.disagree")
          )}
        </StyledButton>
        <StyledButton
          className={classes.button}
          onClick={async () => {
            setDisagreed(false);
            setAgreed(true);
            try {
              await lastRunSyncTimestamp.clear();
              await offlineSyncEnabled.put(true);
            } catch {
              // ignore error
            }

            close();
          }}
          disabled={disagreed || agreed}>
          {agreed ? (
            <StyledProgress size={24} color="inherit" />
          ) : (
            t("offline.allowSyncDialog.agree")
          )}
        </StyledButton>
      </Stack>
    </>
  );
};

export default AllowSyncDialog;
