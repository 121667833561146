import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { styled } from "@mui/material";
import {
  HeaderContent,
  HeaderLogoWrapper,
  HeaderRoot,
} from "components/header/Header";
import { SideBarButtonRoot } from "components/header/SideBarButton";
import WaveDivider from "components/footer/WaveDivider";
import { FooterBottom, FooterTop } from "components/footer/Footer";
import { StyledLink } from "components/StyledUI";
import { ROUTES } from "constants/routes";
import { tss } from "tss-react/mui";

const Icon = styled("div")(() => ({
  width: "40px",
  height: "40px",
}));

const LogoWrapper = styled(StyledLink)(({ theme }) => ({
  height: "100%",
  background: theme.palette.background.paper,
  borderBottomRightRadius: "20px",
  paddingRight: theme.spacing(1),

  [theme.breakpoints.up("md")]: {
    paddingRight: theme.spacing(2),
  },

  "& img": {
    objectFit: "contain",
    maxWidth: "100%",
  },
}));

const useStyles = tss.create(() => ({
  logoWrapper: {
    display: "flex",
    alignItems: "center",
  },
}));

const ErrorLayout: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <HeaderRoot id="header">
        <HeaderContent container alwaysMarginBottom>
          <HeaderLogoWrapper xs={6} sm={3}>
            <SideBarButtonRoot>
              <Icon />
            </SideBarButtonRoot>
            <LogoWrapper to={ROUTES.HOME} className={classes.logoWrapper}>
              <img src="images/logo.png" alt="Service-Bund Logo" />
            </LogoWrapper>
          </HeaderLogoWrapper>
        </HeaderContent>
      </HeaderRoot>
      <main>
        <Helmet>
          <title>{t("error.error")}</title>
        </Helmet>

        <Outlet />
      </main>
      <footer>
        <FooterTop />
        <WaveDivider />
        <FooterBottom />
      </footer>
    </>
  );
};

export default ErrorLayout;
