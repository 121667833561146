/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosPromise } from "axios";

import { ContractApi } from "@hcl-commerce-store-sdk/typescript-axios-transaction";
import { ContractApi as EFoodContractApi } from "@efood-commerce-sdk/typescript-axios-transaction-efood";
import { getSite } from "_foundation/hooks/useSite";
import { site } from "_foundationExt/constants/site";

const contractApiInstance = new ContractApi(undefined, site.transactionContext);
const efoodContractApiInstance = new EFoodContractApi(
  undefined,
  site.transactionContext
);

const contractService = {
  getContracts(): AxiosPromise<any> {
    const siteInfo = getSite();
    if (!siteInfo) {
      return Promise.reject();
    }

    return contractApiInstance.storeStoreIdContractGet(
      siteInfo.storeID,
      "eligible"
    );
  },

  getEFoodContracts(): AxiosPromise<any> {
    const siteInfo = getSite();
    if (!siteInfo) {
      return Promise.reject();
    }

    return efoodContractApiInstance.getContracts(siteInfo.storeID);
  },
};

export default contractService;
