import { Dispatch, useCallback, useEffect, useMemo, useReducer } from "react";
import isInstanaActive from "tools/isInstanaActive";
import { useSite } from "_foundationExt/hooks";
import syncStateReducer from "./syncStateReducer";
import {
  FetchSyncDataFunction,
  INITIAL_SYNC_STATE,
  SyncState,
  SyncStateAction,
} from "./types";

export type UseFetchSyncDataFunctionProps = {
  fetchSyncData: FetchSyncDataFunction;
  cleanup?: () => Promise<Array<unknown>>;
  callback?: (timestamp: unknown) => void;
  run?: boolean;
  force?: boolean;
  paginated?: boolean;
};

export type UseFetchSyncDataFunction = (
  props: UseFetchSyncDataFunctionProps
) => { syncState: SyncState; resetSyncState: () => void };

export type UseFetchSyncDataFunctionReturnType =
  ReturnType<UseFetchSyncDataFunction>;

type FetchAllProps = {
  fetchFn: FetchSyncDataFunction;
  storeId: string;
  transactionContext: string;
  signal: AbortSignal;
  dispatch: Dispatch<SyncStateAction>;
  force: boolean;
};

const fetchAll = async ({
  fetchFn,
  storeId,
  transactionContext,
  signal,
  dispatch,
  force,
}: FetchAllProps) => {
  const { recordSetTotal, recordSetStartNumber, recordSetCount, timestamp } =
    await fetchFn({
      storeId,
      transactionContext,
      signal,
      onDownloadProgress(progressEvent) {
        dispatch({
          type: "SYNC_PROGRESS_UPDATE",
          payload: progressEvent.loaded,
        });
      },
      force,
    });

  return { recordSetTotal, recordSetStartNumber, recordSetCount, timestamp };
};

const fetchAllPaginated = async ({
  fetchFn,
  storeId,
  transactionContext,
  signal,
  dispatch,
  force,
}: FetchAllProps) => {
  let timestamp: unknown;
  let pageNumber = 1;
  const PAGE_SIZE_FOR_PRICES = 200;
  const PAGE_SIZE_FOR_PRODUCTS = 400;
  let hasMorePages = false;
  let recordSetTotal = 0;
  let recordSetCount = 0;
  const recordSetStartNumber = 0;
  do {
    const {
      recordSetTotal: pageRecordSetTotal,
      recordSetCount: pageRecordSetCount,
      recordSetStartNumber: pageRecordSetStartNumber,
      timestamp: pageTimestamp,
      // eslint-disable-next-line no-await-in-loop
    } = await fetchFn({
      storeId,
      transactionContext,
      signal,
      onDownloadProgress(progressEvent) {
        dispatch({
          type: "SYNC_PROGRESS_UPDATE",
          payload: {
            progress: progressEvent.loaded,
          },
        });
      },
      paginated: {
        pageNumber,
        pageSizePrices: PAGE_SIZE_FOR_PRICES,
        pageSizeProducts: PAGE_SIZE_FOR_PRODUCTS,
      },
      force,
      syncTimestamp: timestamp,
    });
    if (pageNumber === 1) {
      timestamp = pageTimestamp;
    }
    pageNumber += 1;
    hasMorePages =
      pageRecordSetCount > 0 &&
      pageRecordSetStartNumber + pageRecordSetCount < pageRecordSetTotal;
    recordSetTotal = pageRecordSetTotal;
    recordSetCount += pageRecordSetCount;

    dispatch({
      type: "SYNC_PROGRESS_UPDATE",
      payload: {
        progress: undefined,
        recordSetTotal,
        recordSetCount,
        recordSetStartNumber,
      },
    });
  } while (hasMorePages);

  return { recordSetTotal, recordSetCount, recordSetStartNumber, timestamp };
};

const useFetchSyncData: UseFetchSyncDataFunction = ({
  fetchSyncData,
  cleanup,
  callback,
  run = false,
  force = false,
  paginated = false,
}) => {
  const { currentSite } = useSite();
  const [syncState, dispatchSyncState] = useReducer(syncStateReducer, {
    ...INITIAL_SYNC_STATE,
  });

  const resetSyncState = useCallback(
    () => dispatchSyncState({ type: "SYNC_RESET" }),
    [dispatchSyncState]
  );

  const useFetchSyncDataResult = useMemo(
    () => ({ syncState, resetSyncState }),
    [syncState, resetSyncState]
  );

  const doFetch = useCallback(
    async (
      signal: AbortSignal,
      storeId: string,
      transactionContext: string
    ) => {
      const fetchProps = {
        fetchFn: fetchSyncData,
        dispatch: dispatchSyncState,
        force,
        signal,
        storeId,
        transactionContext,
      };

      try {
        if (force && cleanup) {
          await cleanup();
        }

        const {
          recordSetTotal,
          recordSetStartNumber,
          recordSetCount,
          timestamp,
        } = paginated
          ? await fetchAllPaginated(fetchProps)
          : await fetchAll(fetchProps);

        if (callback) {
          callback(timestamp);
        }

        dispatchSyncState({
          type: "SYNC_SUCCESS",
          payload: { recordSetTotal, recordSetStartNumber, recordSetCount },
        });
      } catch (e) {
        if (e instanceof Error && isInstanaActive()) {
          ineum("reportEvent", "sync error", {
            timestamp: Date.now(),
            error: e,
            componentStack: e.stack,
          });
        }
        dispatchSyncState({
          type: "SYNC_ERROR",
          payload: { error: e },
        });
      }
    },
    [callback, cleanup, fetchSyncData, force, paginated]
  );

  useEffect(() => {
    const ac = new AbortController();

    if (run && currentSite?.storeID && currentSite?.transactionContext) {
      dispatchSyncState({
        type: "SYNC_START",
      });
      doFetch(ac.signal, currentSite.storeID, currentSite.transactionContext);
    }

    return () => {
      if (!ac.signal.aborted) {
        ac.abort();
      }
    };
  }, [run, currentSite?.storeID, currentSite?.transactionContext, doFetch]);

  return useFetchSyncDataResult;
};

export default useFetchSyncData;
