/**
 * list of external scripts per store
 *
 * storeName: [script-urls]
 */
export const externalScripts = {
  SGK: [
    /**
     * userlike import for sgk
     */
    "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/2aea469462de4411bf6b73e54420a26bb04899a00dec43318610327affd73740.js",
  ],
  BRO: [
    /**
     * userlike import for bro
     */
    "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/72511857f75647beab94c9aeccfc3fe4dbd49b7c8bc14b0da1db4eac8bc040b4.js",
  ],
  VNK: [
    /**
     * userlike import for vnk
     */
    "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/6f0e224fc1544678958ba9339b1e621157f5e2d9411e4acb9e63771a8b22e648.js",
  ],
  OSA: [
    /**
     * userlike import for osa
     */
    "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/5ffca60b56a14e8c91de0f7cccb59543df6d77be9580417fab5548a9134d5cc2.js",
  ],
  OSW: [
    /**
     * userlike import for osa
     */
    "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/da19405661c34e80b1a367b7cadac60f3c161c0226424f098b9d57ee19b4a79f.js",
  ],
  RFS: [
    /**
     * userlike import for osa
     */
    "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/f2f8e2a93cfd4d7bb302e3ea720808e6e1c2ed6b26d74908ae240d8144b0c216.js",
  ],
  DEMO: [
    "https://cloud.ccm19.de/app.js?apiKey=e6b394063f2d32efe5a5b1e3ee5bcf6c4860f1915b5d26dc&domain=627d00a6c2a7d22c4d2abdce",
  ],
  /**
   * userlike import for fbr
   */
  FBB: [
    "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/48b07488a39c4c718bda268ea591300f57843acfb5c846318a0c85c06161ce50.js",
  ],
  /**
   * userlike import for fri
   */
  FRI: [
    "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/2850a4815ce54e6a929ba4a26f521fc2dc264c751ce84b459ac422c635d483e3.js",
  ],
  /**
   * userlike import for osn
   */
  OSN: [
    "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/f387be846b01443dbfcc0abc761c084c5376d088c2df4eeb8987f1d17f0387fe.js",
  ],
};
