import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "_redux/hooks";
import { genericErrorSelector } from "_redux/selectors/error";

/**
 * overwrite error message by key and params
 * @returns
 */
const useGenericError = () => {
  const { t } = useTranslation();
  const genericError = useAppSelector(genericErrorSelector);

  const error = useMemo(() => {
    if (genericError?.errorKey) {
      try {
        if (
          genericError.errorKey === "_ERR_THRESHOLD_SHOPPING_CART_QUANTITY" &&
          genericError.errorParameters
        ) {
          const parameters = genericError.errorParameters.split(",");
          const partnumber = parameters[2].substring(
            0,
            parameters[2].length - 3
          );
          const message = t("error._ERR_THRESHOLD_SHOPPING_CART_QUANTITY", {
            param1: parseFloat(parameters[0]).toLocaleString(),
            param2: parseFloat(parameters[1]).toLocaleString(),
            param3: partnumber,
          });
          return { ...genericError, errorMessage: message };
        }
        if (genericError.errorKey === "ERR_BAD_RESPONSE") {
          const message = `${t("error.generic")}. ${t("error.reload")}.`;
          return { ...genericError, errorMessage: message };
        }
        return genericError;
      } catch {
        return genericError;
      }
    }
    return undefined;
  }, [genericError, t]);

  return error;
};

export { useGenericError };
