import React, { forwardRef } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";

import { styled } from "@mui/material/styles";

interface StyledProps {
  dark?: boolean;
  error?: boolean;
  showHelperTextDirectly?: boolean;
}

const EFoodTextField = styled(TextField, {
  shouldForwardProp: (prop) =>
    prop !== "dark" && prop !== "showHelperTextDirectly",
})<StyledProps>(({ theme, dark, error, showHelperTextDirectly }) => ({
  "& label": {
    visibility: "hidden",
  },
  "& .MuiButtonBase-root:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiInputBase-root": {
    marginTop: "0 !important",
    borderRadius: 4,
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: dark ? theme.palette.grey[800] : theme.palette.grey[50],
    backgroundColor: dark ? theme.palette.grey[800] : theme.palette.grey[50],
    transition: theme.transitions.create(["border-color", "background-color"]),
    "& .MuiInputAdornment-root": {
      color: dark ? theme.palette.grey[50] : theme.palette.grey[800],
      "& svg": {
        color: dark ? theme.palette.grey[50] : theme.palette.grey[800],
        height: 24,
        width: 24,
        pointerEvents: "none",
      },
    },
    "&:hover": {
      borderColor: dark
        ? theme.palette.primary.light
        : theme.palette.primary.main,
    },
    "&.Mui-focused": {
      borderColor: dark
        ? theme.palette.primary.light
        : theme.palette.primary.main,
      backgroundColor: dark ? "transparent" : theme.palette.common.white,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    display: "none",
    border: 0,
  },
  "& .MuiInputBase-input": {
    padding: "8px 14px",
    height: "auto",
    lineHeight: "24px",
    color: dark ? theme.palette.grey[50] : undefined,
    "&::placeholder": {
      color: dark ? theme.palette.grey[50] : theme.palette.grey["700"],
      opacity: 1,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
    },
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
    },
    "&[type=number]": {
      MozAppearance: "textfield",
    },
  },
  "& .MuiInputBase-multiline": {
    padding: 0,
  },
  "& .MuiInputBase-sizeSmall": {
    "& .MuiInputAdornment-root": {
      "& svg": {
        height: 16,
        width: 16,
        pointerEvents: "none",
      },
    },
  },
  "& .MuiInputBase-inputSizeSmall": {
    padding: 6,
    fontSize: 14,
    lineHeight: "20px",
  },
  "& .MuiInputBase-inputMultiline": {
    fontSize: 16,
    height: "auto",
    lineHeight: "24px",
  },
  "& .MuiInputBase-adornedStart": {
    paddingLeft: 8,
  },
  "& .MuiInputBase-adornedEnd": {
    paddingRight: 8,
    "& .MuiButtonBase-root": {},
  },
  "& .MuiInputBase-inputAdornedStart": {
    paddingLeft: 0,
  },
  "& .MuiInputBase-inputAdornedEnd": {
    paddingRight: 0,
  },
  "& .Mui-disabled": {
    "&::placeholder": {
      // needs to be function, to overwrite default placeholder color
      color: () => theme.palette.grey["600"],
    },
  },
  "& .Mui-error": {
    borderColor: () => theme.palette.error.main,
    "&:hover": {
      borderColor: theme.palette.error.main,
    },
  },
  "& .MuiFormHelperText-root": {
    margin: 0,
    position: "absolute",
    top: "calc(100% + 12px)",
    left: 0,
    padding: "11px 8px",
    backgroundColor: theme.palette.common.white,
    opacity: showHelperTextDirectly && error ? 1 : 0,
    visibility: showHelperTextDirectly && error ? "visible" : "hidden",
    lineHeight: "14px",
    fontSize: 12,
    width: "100%",
    transition: theme.transitions.create(["top", "opacity"]),
    boxShadow: "0px 4px 32px rgba(32,35,28,0.15)",
    borderRadius: 4,
    color: `${theme.palette.grey.A200} !important`,
    zIndex: theme.zIndex.extension.formInput,

    "&.Mui-focused": {
      opacity: 1,
      visibility: "visible",
      top: "calc(100% + 12px)",
    },
  },
}));

interface Props {
  dark?: boolean;
  showHelperTextDirectly?: boolean;
  disabled?: boolean;
}

type StyledFormInputProps = Props & TextFieldProps;

const StyledFormInput = forwardRef<HTMLDivElement, StyledFormInputProps>(
  (props, ref) => {
    const {
      InputProps,
      dark,
      showHelperTextDirectly,
      error,
      disabled,
      ...rest
    } = props;

    const inputLapelProps = {
      shrink: true,
    };

    return (
      <EFoodTextField
        ref={ref}
        variant="outlined"
        InputLabelProps={inputLapelProps}
        InputProps={InputProps}
        dark={dark}
        showHelperTextDirectly={showHelperTextDirectly}
        error={error}
        disabled={disabled}
        {...rest}
      />
    );
  }
);

export default StyledFormInput;
