/* eslint-disable indent */
import React, { FC } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import StyledCategoriesAccordion from "components/StyledUI/StyledCategoriesAccordion";
import StyledCategoriesLink from "components/StyledUI/StyledCategoriesLink";
import { tss } from "tss-react/mui";
import { CategoryTree } from "./CategoriesAccordionWrapper";
import { buildLinkRoute } from "./utils";

const useStyles = tss.create(({ theme }) => ({
  wrapper: {
    background: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius * 2,
    padding: `${theme.spacing(0.25)} ${theme.spacing(3)}`,
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },

    "& > .MuiAccordion-root > .MuiAccordionSummary-root": {
      height: "48px",
    },
  },
  linkWrapper: {
    marginTop: "2px",
    marginBottom: "2px",
  },
  categoriesWrapper: {
    paddingLeft: "8px",
    width: "100%",
  },
}));

interface CategoriesAccordionProps {
  categories: CategoryTree[];
  searchTerm?: string | null;
  isAccountListView?: boolean;
}

const CategoriesAccordion: FC<CategoriesAccordionProps> = ({
  categories,
  searchTerm,
  isAccountListView,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const matchesXsAndSmViewport = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  return (
    <>
      {categories.map((category: CategoryTree) =>
        category.subCategories.length ? (
          <div key={category.categoryId}>
            <StyledCategoriesAccordion
              bold={category.bold}
              underline={category.underline}
              headline={category.name}
              expanded={!matchesXsAndSmViewport && category.expand}
              to={buildLinkRoute(
                location,
                category.categoryId,
                searchTerm,
                isAccountListView
              )}>
              <div className={classes.categoriesWrapper}>
                <CategoriesAccordion
                  categories={category.subCategories}
                  searchTerm={searchTerm}
                  isAccountListView={isAccountListView}
                />
              </div>
            </StyledCategoriesAccordion>
          </div>
        ) : (
          <div key={category.categoryId} className={classes.linkWrapper}>
            <StyledCategoriesLink
              bold={category.bold}
              underline={category.underline}
              to={buildLinkRoute(
                location,
                category.categoryId,
                searchTerm,
                isAccountListView
              )}>
              {category.name}
            </StyledCategoriesLink>
          </div>
        )
      )}
    </>
  );
};

export default CategoriesAccordion;
