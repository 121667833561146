/* eslint-disable camelcase */
/* eslint-disable indent */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import {
  basketPredictionSelector,
  userInitStatusSelector,
} from "_redux/selectors/user";
import { useAppSelector } from "_redux/hooks";
import { selectPartNumbersOfAllOrderItems } from "_redux/selectors/order";
import { useSite } from "_foundationExt/hooks";
import { localStorageUtil } from "_foundationExt/utils/storageUtil";

const usePredictivBasket = (cluster: boolean, reload?: boolean): any[] => {
  const { currentSite } = useSite();
  const [loading, setLoading] = useState(true);
  const prediction = useAppSelector(basketPredictionSelector);
  const initSelector = useAppSelector(userInitStatusSelector);
  const [value, setValue] = useState<string[] | undefined>();
  const orderItems = useAppSelector(selectPartNumbersOfAllOrderItems);
  const reloadPredictiveBasket = reload !== undefined ? reload : false;

  useEffect(() => {
    if (
      currentSite &&
      prediction &&
      initSelector &&
      (loading || reloadPredictiveBasket)
    ) {
      const predictedBasketCount =
        currentSite?.storeCfg?.efood?.predictivBasketCount ?? 20;

      /**
       * load individual prediction and format to partnumber object with weight
       */
      const individualPrediction = Object.entries(prediction?.individual || [])
        .map((item) => {
          if (!orderItems || !orderItems.includes(item[0]))
            return {
              partnumber: item[0],
              weight: item[1],
            };
          return null;
        })
        .filter(Boolean);

      /**
       * get cluster prediction from storage or set to storage if empty
       */
      const storageCluster =
        localStorageUtil.get(`PB-${prediction.customerNumber}`) ||
        prediction?.cluster;

      /**
       * load cluster prediction and format to partnumber object with weight, sorted by weight
       */
      const clusterPrediction = Object.entries(storageCluster)
        .map((item) => {
          if (item[0] && item[1]) {
            if (!orderItems || !orderItems.includes(item[0]))
              return {
                partnumber: item[0] as string,
                weight: item[1] as string,
              };
          }
          return null;
        })
        .filter(Boolean)
        .sort((a, b) => {
          if (!!a && !!b) {
            if (a.weight > b.weight) return -1;
            if (a.weight < b.weight) return 1;
          }
          return 0;
        })
        .slice(0, individualPrediction.length);

      /**
       * mix cluster and individual prediction
       */
      const mixedPrediction = () => {
        const individualOverallLength = Object.keys(
          prediction?.individual || []
        ).length;
        const individualCurrentLength = individualPrediction.length;
        const individualInCartLength =
          individualOverallLength - individualCurrentLength;
        const clusterItemsLength =
          Math.round((individualOverallLength / 100) * predictedBasketCount) +
          individualInCartLength;
        const clusterNextPosition = Math.round(
          individualCurrentLength / clusterItemsLength
        );
        const individualNextPosition = Math.round(
          clusterItemsLength / individualCurrentLength
        );
        const switchToClusterAsmMain =
          individualCurrentLength / clusterItemsLength <= 0.5;
        const result: any[] = switchToClusterAsmMain
          ? clusterPrediction
          : individualPrediction;
        let y = 0;

        if (switchToClusterAsmMain) {
          for (let i = 0; i < individualCurrentLength; i++) {
            if (individualPrediction[y]) {
              result.splice(
                i * individualNextPosition,
                0,
                individualPrediction[y]
              );
              y++;
            }
          }
        } else {
          for (let i = 1; i <= clusterItemsLength; i++) {
            if (clusterPrediction[y]) {
              result.splice(
                i * clusterNextPosition + y,
                0,
                clusterPrediction[y]
              );
              y++;
            }
          }
        }

        return result;
      };

      /**
       * show mixed or cluster only
       */
      const tmpPrediction = cluster ? clusterPrediction : mixedPrediction();

      /**
       * reduce result to partnumbers
       */
      const predictedPartnumbers: string[] =
        tmpPrediction.map((item) => item.partnumber) || [];

      setValue(predictedPartnumbers);
      setLoading(false);
    }
  }, [
    loading,
    prediction,
    initSelector,
    currentSite,
    cluster,
    orderItems,
    reloadPredictiveBasket,
  ]);

  return [value, loading];
};

export { usePredictivBasket };
