import { createTheme } from "@mui/material/styles";

// Basic Theme
import { palette } from "./palette";
import { breakpoints } from "./breakpoints";
import { components } from "./components";
import { typography } from "./typography";
import { shape } from "./shape";
import { zIndex } from "./zIndexExtension";

const theme = createTheme({
  components: {
    ...components,
  },
  breakpoints,
  palette,
  shape,
  typography,
  zIndex,
});

export { theme };
