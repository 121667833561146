import { useState, useEffect, SetStateAction, Dispatch } from "react";
import { useAppDispatch } from "_redux/hooks";
import Axios from "axios";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import * as orderActions from "_redux/actions/order";
import { promotionServiceExt } from "_foundationExt/apis/transaction/promotion.service";
import { useMatomo } from "components/matomo";
import MatomoTracker from "@jonkoops/matomo-tracker";
import { PromotionCode } from "./CartPromotionCodeButton";

const fetchPromotions = (
  orderId: string,
  setPromotionCodes: Dispatch<SetStateAction<PromotionCode[] | null>>
) =>
  fetchPromotionsAndTrackEvent(
    orderId,
    setPromotionCodes,
    undefined,
    undefined
  );

const fetchPromotionsAndTrackEvent = async (
  orderId: string,
  setPromotionCodes: Dispatch<SetStateAction<PromotionCode[] | null>>,
  promotionCodeInput: string | undefined,
  matomo: MatomoTracker | undefined
) => {
  const getPromotionResponse = await promotionServiceExt.getPromotions({
    body: {
      orderId,
    },
  });

  if (
    getPromotionResponse.status === 200 &&
    getPromotionResponse.data.promotionCode?.length > 0
  ) {
    const promotionCodeData = getPromotionResponse.data.promotionCode.map(
      (promotionCodeItem) => ({
        codeName: promotionCodeItem.code,
        tooltip:
          promotionCodeItem.associatedPromotion !== undefined &&
          promotionCodeItem.associatedPromotion.length > 0
            ? promotionCodeItem.associatedPromotion[0]?.description
            : "",
      })
    );

    setPromotionCodes(promotionCodeData);

    if (matomo && promotionCodeInput) {
      const promotionCode = getPromotionResponse.data.promotionCode.find(
        (ele) => ele.code === promotionCodeInput
      );

      if (promotionCode?.associatedPromotion) {
        promotionCode.associatedPromotion.map((code) =>
          matomo.trackEvent({
            category: "Promotion Code Eingabe",
            action: `${code.name || code.promotionId}`,
            name: `${promotionCodeInput}`,
          })
        );
      }
    }
  }

  if (
    // promotion codes list is empty
    getPromotionResponse.status === 200 &&
    getPromotionResponse.data.promotionCode === undefined
  ) {
    setPromotionCodes([]);
  }
};

const handleCreateNewPromotionErrors = (
  error: unknown,
  setPromotionCodeError: Dispatch<SetStateAction<string>>,
  t: TFunction
) => {
  if (Axios.isAxiosError(error)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data = error.response?.data as any;
    if (
      error.response?.status === 400 &&
      data?.errors?.[0]?.errorKey === "ERR_PROMOTION_CODE_DUPLICATED"
    ) {
      const errorText = t("cart.promotion.error.duplicatePromotionCode");
      setPromotionCodeError(errorText);
    }

    if (
      error.response?.status === 400 &&
      data?.errors?.[0]?.errorKey === "ERR_PROMOTION_CODE_INVALID"
    ) {
      const errorText = t("cart.promotion.error.unknownPromotionCode");
      setPromotionCodeError(errorText);
    }

    if (
      error.response?.status === 400 &&
      data?.errors?.[0]?.errorKey === "ERR_PROMOTION_NOT_AVAILABLE_AT_THIS_TIME"
    ) {
      const errorText = t("cart.promotion.error.expiredPromotionCode");
      setPromotionCodeError(errorText);
    }

    if (
      error.response?.status === 400 &&
      data?.errors?.[0]?.errorKey === "ERR_PROMOTION_OVERALL_LIMIT_EXCEEDED"
    ) {
      const errorText = t("cart.promotion.error.limitedPromotionCode");
      setPromotionCodeError(errorText);
    }

    if (
      error.response?.status === 400 &&
      data?.errors?.[0]?.errorKey === "ERR_PROMOTION_PER_SHOPPER_LIMIT_EXCEEDED"
    ) {
      const errorText = t("cart.promotion.error.limitedPromotionCode");
      setPromotionCodeError(errorText);
    }
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useFetchPromotions = (orderId: string) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const matomo = useMatomo();

  const [promotionCodeInput, setPromotionCodeInput] = useState("");
  const [promotionCodeError, setPromotionCodeError] = useState<string>("");
  const [promotionCodes, setPromotionCodes] = useState<PromotionCode[] | null>(
    null
  );

  const createNewPromotion = async () => {
    try {
      const setPromotionResponse = await promotionServiceExt.setPromotion({
        body: {
          orderId,
          promoCode: promotionCodeInput,
        },
      });

      if (setPromotionResponse.status === 201) {
        dispatch(orderActions.FETCHING_CART_ACTION({}));
        fetchPromotionsAndTrackEvent(
          orderId,
          setPromotionCodes,
          promotionCodeInput,
          matomo
        );
        setPromotionCodeInput("");
        setPromotionCodeError("");
      }
    } catch (error: unknown) {
      handleCreateNewPromotionErrors(error, setPromotionCodeError, t);
    }
  };

  const deletePromotion = async (promotionCode: string) => {
    const response = await promotionServiceExt.deletePromotion({
      body: {
        orderId,
        promotionCode,
        resourceName: "cart",
      },
    });

    if (response.status === 200) {
      dispatch(orderActions.FETCHING_CART_ACTION({}));
      fetchPromotions(orderId, setPromotionCodes);
      setPromotionCodeError("");
    }
  };

  useEffect(() => {
    fetchPromotions(orderId, setPromotionCodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    promotionCodeInput,
    setPromotionCodeInput,
    promotionCodeError,
    setPromotionCodeError,
    promotionCodes,
    createNewPromotion,
    deletePromotion,
  };
};

export { useFetchPromotions };
