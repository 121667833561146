import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import type { ContentProps } from "components/dialog/types";
import type { SyncOfflineDataParams } from "./SyncOfflineData";

const SyncOfflineDataTitle: FC<ContentProps<SyncOfflineDataParams>> = () => {
  const { t } = useTranslation();

  return (
    <Typography variant="h3">{t("offline.sync.headline.catalog")}</Typography>
  );
};

export default SyncOfflineDataTitle;
