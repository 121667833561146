/**
 * rounds a quantity by cutting the decimal places after the requested decimal places
 *
 * @param quantity the quantity to round
 * @param decimals the number of decimal places
 * @returns the number rounded down to the provided quantity with
 */
const roundQuantity = (quantity: number, decimals = 0) => {
  if (quantity === 0) {
    return Math.abs(quantity); // to avoid -0
  }
  const multiple = 10 ** decimals; // equivalent to Math.pow(10, decimals)
  const quotient = quantity * multiple;
  // account for floating point errors by subtracting the built in machine epsilon, which is
  // the smallest positive number which, added to 1, will produce a value different from 1
  const roundedQuantity =
    quotient < 0
      ? -Math.round(-(quotient + Number.EPSILON)) / multiple
      : Math.round(quotient - Number.EPSILON) / multiple;

  return Math.abs(roundedQuantity); // to avoid -0
};

export default roundQuantity;
