import React, { FC } from "react";
import {
  defaultParams,
  type OnlyOnlineAvailableParams,
} from "../contents/OnlyOnlineAvailable";
import type { ContentProps } from "../types";
import MessageTitle from "./MessageTitle";

const OnlyOnlineAvailableTitle: FC<ContentProps<OnlyOnlineAvailableParams>> = ({
  close,
}) => <MessageTitle close={close} params={defaultParams} />;

export default OnlyOnlineAvailableTitle;
