import { MarketingSpotData, NVPProperties } from "types/Marketing";

const getMarketingSpotNVPData = (
  marketingSpotData?: MarketingSpotData
): NVPProperties => {
  const result: NVPProperties = {};
  if (marketingSpotData?.baseMarketingSpotActivityData) {
    marketingSpotData.baseMarketingSpotActivityData
      .filter((e) => e.baseMarketingSpotDataType === "DM_NVP")
      .forEach((e) => {
        e.properties?.forEach((item) => {
          if (item.baseMarketingKey && item.baseMarketingValue) {
            result[item.baseMarketingKey] = item.baseMarketingValue;
          }
        });
      });
  }
  return result;
};

export default getMarketingSpotNVPData;
