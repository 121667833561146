import { ZIndex } from "@mui/material";

// The declaration is necessary for the typescript support
declare module "@mui/material/styles/zIndex" {
  interface ZIndex {
    extension: {
      offCanvas: number;
      hamburgerButton: number;
      offCanvasShadow: number;
      badge: number;
      formInput: number;
      formSelect: number;
      scrollUpButton: number;
      header: number;
      headerLogoWrapper: number;
      headerMarketingSpot: number;
      minicartPopper: number;
      profileFlyoutPopper: number;
      searchPopper: number;
      loginFlyoutPopper: number;
      productOptions: number;
    };
  }
}

export type ZIndexWithExtension = ZIndex;

const zIndex: Partial<ZIndex> = {
  extension: {
    offCanvas: 2,
    header: 20,
    headerLogoWrapper: 3,
    headerMarketingSpot: 21,
    hamburgerButton: 2,
    offCanvasShadow: 1,
    badge: 0,
    formInput: 10,
    formSelect: 10,
    scrollUpButton: 12,
    minicartPopper: 30,
    profileFlyoutPopper: 30,
    searchPopper: 30,
    loginFlyoutPopper: 30,
    productOptions: 11,
  },
} as const;

export { zIndex };
