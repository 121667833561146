import React, { FC, useMemo } from "react";
import { Link, LinkProps, To, createPath } from "react-router-dom";
import { StyledTooltip } from "components/StyledUI";
import { tss } from "tss-react/mui";

const useStyles = tss
  .withParams<
    Pick<IStyledLink, "navigation" | "selected" | "small" | "whiteText">
  >()
  .create(({ theme, navigation, selected, small, whiteText }) => ({
    root: {
      display: "inline-block",
      fontSize: small ? "14px" : undefined,
      lineHeight: small ? "20px" : undefined,
      fontWeight: selected ? "bold" : undefined,
      color: whiteText
        ? theme.palette.background.default
        : theme.palette.text.primary,
      letterSpacing: "0.5px",
      textDecorationLine: navigation && !selected ? "none" : "underline",
      textUnderlineOffset: "4px",
      textDecorationColor: whiteText
        ? theme.palette.grey[800]
        : theme.palette.grey[600],

      "&:hover": {
        textDecorationColor: theme.palette.primary.light,
        textDecorationLine: "underline",
      },

      "&:active": {
        textDecorationColor: theme.palette.primary.dark,
        textDecorationLine: "underline",
      },
    },
  }));

type IStyledLink = {
  small?: boolean;
  navigation?: boolean;
  selected?: boolean;
  whiteText?: boolean;
  className?: string;
  tooltip?: string;
};

type StyledLinkProps = IStyledLink & LinkProps;

const pathSearchOrHashStartChar = new Set(["/", "?", "#"]);
const createHref = (to: To) => (typeof to === "string" ? to : createPath(to));
const isAbsoluteLink = (href: string) =>
  href &&
  !pathSearchOrHashStartChar.has(href.charAt(0)) &&
  (/^http[s]?:\/\//.test(href) ||
    /^mailto:([^?]*)/.test(href) ||
    /^tel:([^?]*)/.test(href));

const StyledLink: FC<StyledLinkProps> = (props) => {
  const {
    children,
    className,
    navigation,
    small,
    selected,
    whiteText,
    to,
    tooltip,
    ...rest
  } = props;
  const { classes, cx } = useStyles({ navigation, small, selected, whiteText });

  const link = useMemo(() => {
    if (to == null) {
      return null;
    }

    const href = createHref(to);
    if (isAbsoluteLink(href)) {
      return (
        <a
          className={cx(classes.root, className)}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          {...rest}>
          {children}
        </a>
      );
    }

    return (
      <Link className={cx(classes.root, className)} to={to} {...rest}>
        {children}
      </Link>
    );
  }, [children, className, rest, to, classes.root, cx]);

  if (!link) {
    return null;
  }

  if (tooltip) {
    return <StyledTooltip title={tooltip}>{link}</StyledTooltip>;
  }

  return link;
};

export { StyledLink };
