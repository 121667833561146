/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosPromise } from "axios";
import { getSite } from "_foundation/hooks/useSite";
import { ProductProximitiesApi } from "@efood-commerce-sdk/typescript-axios-transaction-efood";
import { site as constantsSite } from "_foundationExt/constants/site";
import ensureObject from "tools/ensureObject";

const productProximitiesApiInstance = new ProductProximitiesApi(
  undefined,
  constantsSite.transactionContext
);

/**
 * Get Product Proximities with the specified eFoodPartnumber
 * @param parameters {storeId: string, eFoodPartnumber: string}
 */

const productProximitiesService = {
  getProximitiesForProduct(parameters: any): AxiosPromise<any> {
    const site = getSite();
    const {
      storeId = site?.storeID,
      eFoodPartnumber,
      ...options
    } = ensureObject(parameters);

    return productProximitiesApiInstance.getProductProximities(
      storeId,
      eFoodPartnumber,
      options
    );
  },
};

export default productProximitiesService;
