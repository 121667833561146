import React, { ChangeEventHandler, FC, useCallback, useMemo } from "react";

import { StyledFormCheckbox } from "components/StyledUI";
import { FilterEntry } from "types/Search";
import { truncate } from "tools/stringUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { QUERY_STRING_PARAM_PAGE } from "_foundationExt/hooks/usePagination";

interface FilterParams {
  filterKey: string;
  filterEntry: FilterEntry;
}

const FilterOption: FC<FilterParams> = ({ filterKey, filterEntry }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const checked = useMemo(() => {
    if (location.hash) {
      const urlParam = new URLSearchParams(location.hash.substring(1));
      const urlValue = urlParam.getAll(filterKey);

      if (urlValue.includes(filterEntry.label)) {
        return true;
      }
    }

    return false;
  }, [location.hash, filterKey, filterEntry.label]);

  const setParamToUrl: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const newValue = event.target.name;
      const hashParam = new URLSearchParams(
        location.hash ? location.hash.substring(1) : undefined
      );
      const keyExists = hashParam.has(filterKey);
      if (event.target.checked) {
        if (keyExists) {
          hashParam.append(filterKey, newValue);
        } else {
          hashParam.set(filterKey, newValue);
        }
      } else {
        hashParam.delete(filterKey);
      }

      const searchParam = new URLSearchParams(
        location.search ? location.search.substring(1) : undefined
      );
      searchParam.delete(QUERY_STRING_PARAM_PAGE);

      navigate({
        pathname: location.pathname,
        search: searchParam.size === 0 ? "" : `?${searchParam.toString()}`,
        hash: hashParam.size === 0 ? "" : `#${hashParam.toString()}`,
      });
    },
    [filterKey, location.pathname, location.search, location.hash, navigate]
  );

  return (
    <StyledFormCheckbox
      key={`dropdown_${filterEntry.label}`}
      size="small"
      checkboxProps={{
        checked,
        onChange: setParamToUrl,
        name: filterEntry.label,
      }}
      label={`${truncate(filterEntry.label, 31)} (${filterEntry.count})`}
    />
  );
};

export default FilterOption;
