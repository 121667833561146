import {
  loginStatusSelector,
  userInitStatusSelector,
} from "_redux/selectors/user";
import { ROUTES } from "constants/routes";
import { useNavigate } from "react-router-dom";
import { useEFoodConfig } from "_foundationExt/hooks";
import { useAppSelector } from "_redux/hooks";

/**
 * Checks if the store has the property "onlyHomepage".
 * If this is the case the user can only continue if he/she is logged in.
 * If the user is not logged in he/she will be redirected to login page.
 */
export const useOnlyHomepageChecker: () => void = () => {
  const { onlyHomepage } = useEFoodConfig();
  const loginStatus = useAppSelector(loginStatusSelector);
  const userInitFromStorageStatus = useAppSelector(userInitStatusSelector);
  const navigate = useNavigate();

  // if the user is logged in he/she can visit the site so that no redirect is necessary
  if (loginStatus) {
    return;
  }

  // Wait until all the data is present from the user initialization process
  // so that this logic is able to decide if a route change to the logon page is necessary or not
  if (!userInitFromStorageStatus) {
    return;
  }

  if (!onlyHomepage) {
    return;
  }

  navigate(ROUTES.LOGON);
};
