import React, { FC, useState } from "react";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import * as userAction from "_redux/actions/user";
import * as orderAction from "_redux/actions/order";
import { selectSortedCustomerProductLists } from "_redux/selectors/user";
import {
  StyledButton,
  StyledDialog,
  StyledDialogTitle,
  StyledDialogContent,
  StyledFormInput,
  StyledNotification,
} from "components/StyledUI";
import customerProductlistService from "_foundationExt/apis/transaction/customerProductlist.service";
import {
  CustomerProductlist,
  CustomerProductlistCreateUpdatePayload,
} from "types/CustomerProductlist";

const isCustomerProductListNameUnique = (
  listname: string,
  customerProducts: CustomerProductlist[]
) => {
  // Does already a productlist exist with the same listname
  const equalProductList = customerProducts.find(
    (customerProduct) => customerProduct.name === listname
  );

  if (equalProductList) {
    return false;
  }

  return true;
};

const validateInput = (
  listname: string,
  customerProducts: CustomerProductlist[],
  t: TFunction
) => {
  if (listname.length < 3) {
    return t("myAccount.listManagement.modalMinListnameInputError");
  }

  if (listname.length > 30) {
    return t("myAccount.listManagement.modalMaxListnameInputError");
  }

  if (!isCustomerProductListNameUnique(listname, customerProducts)) {
    return t("myAccount.listManagement.modalEqualListnameError");
  }

  return null;
};

interface Props {
  closeDialog: () => void;
  showDialog: boolean;
}

const CreateSaveDialog: FC<Props> = ({ closeDialog, showDialog }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const customerProductLists = useAppSelector(selectSortedCustomerProductLists);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState<string | null>(null);

  const handleClose = () => {
    setError(null);
    setName("");
    setDescription("");

    closeDialog();
  };

  const saveInputs = async () => {
    const inputError = validateInput(name, customerProductLists, t);

    if (inputError) {
      setError(inputError);
      return;
    }

    const parameters: CustomerProductlistCreateUpdatePayload = {
      body: {
        name,
        description,
        type: "XSL",
        addProductsFromCart: true,
      },
    };

    try {
      await customerProductlistService.createList(parameters);
      dispatch(userAction.RE_FETCH_CUSTOMER_PRODUCTLISTS_ACTION());
      dispatch(orderAction.FETCHING_CART_ACTION({}));

      handleClose();
    } catch (err) {
      setError(
        t("myAccount.listManagement.modalGeneralError") ?? "general error"
      );
    }
  };

  return (
    <StyledDialog open={showDialog} onClose={handleClose}>
      <StyledDialogTitle id="dialog-title" onClose={handleClose}>
        {t("myAccount.listManagement.modalCreateList")}
      </StyledDialogTitle>

      <StyledDialogContent>
        {error && (
          <Box mb={2}>
            <StyledNotification severity="error">{error}</StyledNotification>
          </Box>
        )}

        <Box mb={1}>{t("myAccount.listManagement.modalListnameLabel")}</Box>

        <StyledFormInput
          value={name}
          onChange={(event) => setName(event.target.value)}
          placeholder={t("myAccount.listManagement.modalListnamePlaceholder")}
          autoFocus
          fullWidth
        />

        <Box mt={2} mb={1}>
          {t("myAccount.listManagement.modalDescriptionLabel")}
        </Box>

        <StyledFormInput
          rows="3"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          InputProps={{
            multiline: true,
          }}
          placeholder={
            t("myAccount.listManagement.modalDescriptionPlaceholder") ??
            undefined
          }
          fullWidth
        />

        <Box mt={2}>
          <StyledButton fullWidth onClick={saveInputs}>
            {t("myAccount.listManagement.modalAcceptButton")}
          </StyledButton>
        </Box>

        <Box mt={2}>
          <StyledButton fullWidth variant="outlined" onClick={handleClose}>
            {t("myAccount.listManagement.modalCancelButton")}
          </StyledButton>
        </Box>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default CreateSaveDialog;
