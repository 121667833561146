import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProductListContainer } from "components/productList";
import { useOnlyHomepageChecker } from "tools/useOnlyHomepageChecker";
import { useScrollToTop } from "tools/scrollRestoration";
import { useAppDispatch } from "_redux/hooks";
import { RESET_PRODUCTLIST_FILTER } from "_redux/actions/user";

const ProductList: FC = () => {
  const { categoryId } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useOnlyHomepageChecker();
  useScrollToTop();

  useEffect(() => {
    dispatch(RESET_PRODUCTLIST_FILTER());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{t("productCatalog.productCatalog")}</title>
      </Helmet>

      {categoryId && <ProductListContainer categoryId={categoryId} />}
    </>
  );
};

export default ProductList;
