import { Category, CategoryWithIndices } from "pwa/db/types";

const extractCategoryId = (childCatalogGroupId: string) => {
  const splittedId = childCatalogGroupId.split("_");
  return splittedId.length > 1 ? splittedId[1] : splittedId[0];
};

const createCategoryWithIndices = (category: Category) => {
  const result = { ...category } as CategoryWithIndices;
  delete result.childCatalogGroupID;
  delete result.catalogGroupView;

  if (category.childCatalogGroupID) {
    if (Array.isArray(category.childCatalogGroupID)) {
      result.childCatalogGroupIDs = category.childCatalogGroupID.map(
        (childCatalogGroupId) => extractCategoryId(childCatalogGroupId)
      );
    } else {
      result.childCatalogGroupIDs = [
        extractCategoryId(category.childCatalogGroupID),
      ];
    }
  }

  if (Array.isArray(category.childCatalogGroupID)) {
    result.childCatalogGroupIDs = category.childCatalogGroupID.map(
      (childCatalogGroupId) => extractCategoryId(childCatalogGroupId)
    );
  }

  return result;
};

const flattenCatalogGroupView = (catalogGroupView: Category[]) =>
  catalogGroupView.reduce(
    (previousValue, currentCatalogGroup) => {
      let result = previousValue;
      if (currentCatalogGroup.delete) {
        result.deletedCategories.push(currentCatalogGroup.identifier);
        return result;
      }

      const category = createCategoryWithIndices(currentCatalogGroup);
      result.categoriesWithIndices.push(category);

      if (currentCatalogGroup.catalogGroupView?.length) {
        const recursiveResult = flattenCatalogGroupView(
          currentCatalogGroup.catalogGroupView
        );
        result = {
          categoriesWithIndices: result.categoriesWithIndices.concat(
            recursiveResult.categoriesWithIndices
          ),
          deletedCategories: result.deletedCategories.concat(
            recursiveResult.deletedCategories
          ),
        };
      }
      return result;
    },
    {
      categoriesWithIndices: [] as CategoryWithIndices[],
      deletedCategories: [] as string[],
    }
  );

const handleCategories = (categories: Category[]) =>
  flattenCatalogGroupView(categories);

export default handleCategories;
