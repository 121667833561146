// Standard libraries
import React, { FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "_redux/hooks";
import {
  selectCustomerProductListsWithContractsSorted,
  userConfigSelector,
} from "_redux/selectors/user";

import Box from "@mui/material/Box";

import { useEFoodConfig, useOnlineStatus } from "_foundationExt/hooks";

import { StyledLink, StyledShow } from "components/StyledUI";

import { ROUTES } from "constants/routes";

import { useEnvironmentContext } from "components/helpers/environment-context";
import { tss } from "tss-react/mui";
import ActionBarDropdown from "./ActionBarDropdown";

const useStyles = tss.create(() => ({
  link: {
    display: "block",
  },
}));

interface Props {
  setSideBarOpenStatus?: React.Dispatch<SetStateAction<boolean>>;
}

const ActionBar: FC<Props> = ({ setSideBarOpenStatus }) => {
  const { classes } = useStyles();

  const { t } = useTranslation();
  const { envType } = useEnvironmentContext();
  const storeConfiguration = useEFoodConfig();
  const { isOffline } = useOnlineStatus();

  const userConfig = useAppSelector(userConfigSelector);
  const sortedProductLists = useAppSelector(
    selectCustomerProductListsWithContractsSorted
  );

  const closeSideBar = () => {
    if (setSideBarOpenStatus) {
      setSideBarOpenStatus(false);
    }
  };

  return (
    <>
      {!storeConfiguration.onlyHomepage && !isOffline && (
        <Box component="span" mr={4}>
          <StyledLink whiteText to={ROUTES.OFFERS} onClick={closeSideBar}>
            {t("sites.offers")}
          </StyledLink>
        </Box>
      )}

      {process.env.REACT_APP_ENVIRONMENT === "development" && (
        <Box component="span" mr={4}>
          <StyledLink
            whiteText
            to={ROUTES.ESPOT_SHOWCASE_SITE}
            onClick={closeSideBar}>
            Showcase
          </StyledLink>
        </Box>
      )}
      {envType !== "prod" && (
        <StyledShow loggedIn>
          <Box component="span" mr={4}>
            <StyledLink
              whiteText
              to={ROUTES.OFFLINE_SHOWCASE_SITE}
              onClick={closeSideBar}>
              Offline Testseite
            </StyledLink>
          </Box>
        </StyledShow>
      )}

      <StyledShow loggedIn>
        <Box component="span" mr={4}>
          <ActionBarDropdown label={t("sites.myLists")}>
            {sortedProductLists.map((productList) => (
              <StyledLink
                key={productList.id}
                className={classes.link}
                whiteText
                small
                onClick={closeSideBar}
                to={
                  productList.type === "XCO"
                    ? `${ROUTES.PRODUCT_DETAIL}${ROUTES.SEARCH}?productListId=${productList.id}`
                    : `${ROUTES.ACCOUNT_LISTS}?productListId=${productList.id}`
                }>
                {productList.name}
              </StyledLink>
            ))}
          </ActionBarDropdown>
        </Box>

        {!storeConfiguration.onlyHomepage &&
          (storeConfiguration.recipes ||
            (storeConfiguration.sander && userConfig?.sander)) && (
            <Box component="span" mr={4}>
              <ActionBarDropdown label={t("sites.tools")}>
                {/** TODO: action is disabled for R1 
                {storeConfiguration?.recipes && (
                  <StyledLink
                    className={classes.link}
                    whiteText
                    small
                    onClick={closeSideBar}
                    to={ROUTES.RECIPES}>
                    {t("header.actionBar.recipes")}
                  </StyledLink>
                )}
                */}

                {storeConfiguration.sander && userConfig?.sander && (
                  <>
                    <StyledLink
                      className={classes.link}
                      whiteText
                      small
                      onClick={closeSideBar}
                      to={`${ROUTES.SANDER}/buffet#/buffet/template`}>
                      {t("header.actionBar.sanderBuffet")}
                    </StyledLink>

                    <StyledLink
                      className={classes.link}
                      whiteText
                      small
                      onClick={closeSideBar}
                      to={`${ROUTES.SANDER}/menu`}>
                      {t("header.actionBar.sanderMenuCard")}
                    </StyledLink>
                  </>
                )}
              </ActionBarDropdown>
            </Box>
          )}
      </StyledShow>
    </>
  );
};

export default ActionBar;
