//Login
export const RENEW_LOGIN_REQUESTED = "RENEW_LOGIN_REQUESTED";
export const REDIRECTED_LOGIN_REQUESTED = "REDIRECTED_LOGIN_REQUESTED";
export const LOGIN_REQUESTED = "LOGIN_REQUESTED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_REQUESTED = "LOGOUT_REQUESTED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const SESSION_ERROR_LOGIN_REQUESTED = "SESSION_ERROR_LOGIN_REQUESTED";
export const SESSION_ERROR_LOGIN_SUCCESS = "SESSION_ERROR_LOGIN_SUCCESS";
export const SESSION_ERROR_LOGIN_ERROR = "SESSION_ERROR_LOGIN_ERROR";
export const REGISTRATION_REQUESTED = "REGISTRATION_REQUESTED";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_ERROR = "REGISTRATION_ERROR";
export const NEWSLETTER_ERROR = "NEWSLETTER_ERROR";
export const NEWSLETTER_SUCCESS = "NEWSLETTER_SUCCESS";

//storage
export const INIT_STATE_FROM_STORAGE = "INIT_STATE_FROM_STORAGE";
export const INIT_USER_FROM_STORAGE_SUCCESS =
  "INIT_USER_STATE_FROM_STORAGE_SUCCESS";

// user details
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";

// delivery dates
export const FETCH_DELIVERY_DATES_REQUESTED = "FETCH_DELIVERY_DATES_REQUESTED";
export const FETCH_DELIVERY_DATES_SUCCESS = "FETCH_DELIVERY_DATES_SUCCESS";
export const FETCH_DELIVERY_DATES_ERROR = "FETCH_DELIVERY_DATES_ERROR";
export const FETCH_DELIVERY_DATE_SUCCESS = "FETCH_DELIVERY_DATE_SUCCESS";
export const FETCH_DELIVERY_DATE_ERROR = "FETCH_DELIVERY_DATE_ERROR";
export const UPDATE_DELIVERY_DATE_REQUESTED = "UPDATE_DELIVERY_DATE_REQUESTED";
export const UPDATE_DELIVERY_DATE_SUCCESS = "UPDATE_DELIVERY_DATE_SUCCESS";
export const UPDATE_DELIVERY_DATE_ERROR = "UPDATE_DELIVERY_DATE_ERROR";

// customer productlists
export const FETCH_CUSTOMER_PRODUCTLISTS_REQUESTED =
  "FETCH_CUSTOMER_PRODUCTLISTS_REQUESTED";
export const FETCH_CUSTOMER_PRODUCTLISTS_ERROR =
  "FETCH_CUSTOMER_PRODUCTLISTS_ERROR";
export const FETCH_CUSTOMER_PRODUCTLISTS = "FETCH_CUSTOMER_PRODUCTLISTS";

// customer organizations
export const RELOAD_CUSTOMER_DATA = "RELOAD_CUSTOMER_DATA";
export const FETCH_ENTITLED_ORGANIZATIONS = "FETCH_ENTITLED_ORGANIZATIONS";
export const RELOAD_PERSON_DATA = "RELOAD_PERSON_DATA";

// offers
export const FETCH_OFFERS = "FETCH_OFFERS";
export const FETCH_OFFERS_ERROR = "FETCH_OFFERS_ERROR";

// contracts
export const FETCH_CONTRACTS = "FETCH_CONTRACTS";
export const FETCH_EFOOD_CONTRACTS = "FETCH_EFOOD_CONTRACTS";

// history
export const FETCH_ORDER_HISTORY = "FETCH_ORDER_HISTORY";
export const FETCH_ORDER_HISTORY_ERROR = "FETCH_ORDER_HISTORY_ERROR";
export const RELOAD_ORDER_HISTORY = "RELOAD_ORDER_HISTORY";

export const PUT_SELECTED_PRODUCTLIST = "PUT_SELECTED_PRODUCTLIST";

export const UPDATE_PRODUCTLIST_FILTER = "UPDATE_PRODUCTLIST_FILTER";
export const RESET_PRODUCTLIST_FILTER = "RESET_PRODUCTLIST_FILTER";

export const SET_TRACKING_CHOICE = "SET_TRACKING_CHOICE";
export const ACCEPT_PRICE_CHANGES = "ACCEPT_PRICE_CHANGES";

export const SET_STORAGE_LOCATION_CHANGED = "SET_STORAGE_LOCATION_CHANGED";

// Rewards
export const REWARDS_HANDLED = "REWARDS_HANDLED";

// order split
export const SET_ORDER_SPLIT_ITEMS = "SET_ORDER_SPLIT_ITEMS";
export const ORDER_SPLIT_HINT_VIEWED = "ORDER_SPLIT_HINT_VIEWED";

// Predictive Basket
export const PREDICTIVE_BASKET_REQUESTED = "PREDICTIVE_BASKET_REQUESTED";
export const PREDICTIVE_BASKET_FOUND = "PREDICTIVE_BASKET_FOUND";
export const PREDICTIVE_BASKET_NOT_FOUND = "PREDICTIVE_BASKET_NOT_FOUND";
