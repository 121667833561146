// Standard libraries
import React, {
  FC,
  useState,
  useCallback,
  useReducer,
  useEffect,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { isAxiosError, isCancel } from "axios";
import {
  Box,
  Typography,
  InputAdornment,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import {
  StyledButton,
  StyledFormInput,
  StyledLink,
  StyledProgress,
  StyledNotification,
  StyledLinkButton,
  StyledPopper,
} from "components/StyledUI";

import { useSite } from "_foundation/hooks/useSite";
import productServiceExt from "_foundationExt/apis/search/product.service";
import siteContentServiceExt from "_foundationExt/apis/search/siteContent.service";

import { ServerError } from "types/Error";
import { Product } from "types/Product";
import { SearchKeywordSuggestion } from "types/Search";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as BarcodeScanIcon } from "assets/icons/barcode_scan.svg";

import { ROUTES } from "constants/routes";

import SpellCheckSuggestions from "components/searchSpellCheckSuggestions";
import { userStockDeliveryDate } from "_redux/selectors/user";
import { useOrderset } from "_redux/hooks/useOrderset";
import { useEFoodConfig } from "_foundationExt/hooks/useEFoodConfig";
import { RESET_PRODUCTLIST_FILTER } from "_redux/actions/user";
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import { useMatomo } from "components/matomo";
import { useNavigate } from "react-router-dom";
import { TRIGGER_MARKETING_ACTION } from "_redux/actions/marketingEvent";
import BcScannerDialog from "components/barcodeScanner/BcScannerDialog";

import { useDebouncedCallback } from "use-debounce";
import { tss } from "tss-react/mui";

import ProductSearchItem from "./ProductSearchItem";
import KeywordSuggestionLink from "./KeywordSuggestionLink";

// time the frontend waits before sending search query
const SEARCH_DEBOUNCE_TIME = 750;
// search should start after min length is reached
const SEARCH_MIN_LENGTH = 2;
// from servicebund certain entries for which the search starts with less characters
const SEARCH_REGISTER = new Set(["ei", "öl", "tk"]);

const useStyles = tss.create(({ theme }) => ({
  bcScannerIcon: {
    paddingTop: "5px",
    paddingRight: "5px",
    paddingBottom: "5px",
    paddingLeft: "5px",
    "& svg": {
      width: "38px !important",
      height: "38px !important",
    },
  },
  popover: {
    zIndex: theme.zIndex.extension.searchPopper,
  },
  content: {
    maxHeight: "732px",
    [theme.breakpoints.up("sm")]: {
      width: "480px",
      maxHeight: "756px",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "756px",
    },
  },
  headerPopover: {
    display: "flex",
    justifyContent: "space-between",
  },
  overflow: {
    overflow: "auto",
    maxHeight: "360px",

    scrollbarColor: `${theme.palette.grey[300]} ${theme.palette.background.paper}`,
    scrollbarWidth: "thin",

    "&::-webkit-scrollbar": {
      width: theme.spacing(0.5),
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[300],
      borderRadius: "2px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: theme.palette.grey[700],
    },
  },
  formInput: {
    "& .MuiInputAdornment-root": {
      gap: theme.spacing(1),
      marginLeft: 0,
      "& .MuiIconButton-root span": {
        minWidth: "38px",
      },
    },
  },
}));

const searchUri = `${ROUTES.PRODUCT_DETAIL}${ROUTES.SEARCH}`;

export interface QuickSearchStateParam {
  searchOrderset: boolean;
  term: string;
  keywordSuggestions: SearchKeywordSuggestion[];
  products: Product[];
  recordTotal: number;
  spellCheckSuggestions: string[];
  loading: boolean;
  error: ServerError | null;
  excludeNoteSearch: boolean;
}

const QuickSearch: FC = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const breakpointUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const isLargeScreen = useMediaQuery("(min-width:1024px)");
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentSite } = useSite();
  const matomoInstance = useMatomo();
  const abortController = useRef<AbortController>();

  const orderset = useOrderset();
  const ordersetExists = orderset != null;
  const { searchIn, internTxt } = useEFoodConfig();
  const orderBy = internTxt ? "2" : "1";

  const currentDeliveryDate = useAppSelector(userStockDeliveryDate);

  const [params, setParams] = useReducer(
    (
      state: QuickSearchStateParam,
      newState: Partial<QuickSearchStateParam>
    ) => ({
      ...state,
      ...newState,
    }),
    {
      searchOrderset: false,
      term: "",
      keywordSuggestions: [],
      products: [],
      recordTotal: 0,
      spellCheckSuggestions: [],
      loading: false,
      error: null,
      excludeNoteSearch: false,
    }
  );

  // used to open or close the popover
  const [open, setOpen] = useState(false);

  // set initial value for searchOrderset async, as orderset is resolved async
  useEffect(() => {
    setParams({
      searchOrderset: ordersetExists,
    });
  }, [ordersetExists]);

  const fetchSearchResults = useCallback(
    (searchTerm: string, openPopover: boolean, signal?: AbortSignal) => {
      const fetch = async () => {
        if (
          // check if mySite not null and if terms length is > 2 or if term is something like 'öl'
          !currentSite?.storeID ||
          (searchTerm.length <= SEARCH_MIN_LENGTH &&
            !SEARCH_REGISTER.has(searchTerm.toLocaleLowerCase()))
        ) {
          setOpen(false);
          return;
        }

        setParams({
          loading: true,
        });
        if (openPopover) {
          setOpen(true);
        }
        const urlEncodedTerm = encodeURIComponent(searchTerm);
        const parameters = {
          storeId: currentSite.storeID,
          term: urlEncodedTerm,
          orderSetId: orderset?.id,
          pageSize: 10,
          signal,
          $queryParameters: {
            deliveryDate: currentDeliveryDate?.date,
            excludeNoteSearch: params.excludeNoteSearch,
            orderBy,
          } as {
            excludeNoteSearch: boolean;
            orderBy: string;
            productList?: string;
          },
        };

        if (searchIn === "orderset" && params.searchOrderset) {
          parameters.$queryParameters.productList = orderset?.id;
          parameters.$queryParameters.excludeNoteSearch = true;
        }
        try {
          const response = await productServiceExt.bySearchTermCategorized(
            true,
            {
              ...parameters,
              $queryParameters: {
                ...parameters.$queryParameters,
              },
            }
          );

          setParams({
            recordTotal: response.data?.recordSetTotal,
            products: response.data.result?.catalogEntryView,
            spellCheckSuggestions: response.data?.result?.metaData?.spellcheck,
            searchOrderset:
              response.data.result?.catalogEntryView?.length <= 0 &&
              ordersetExists
                ? false
                : params.searchOrderset,
          });

          if (matomoInstance) {
            const category = t("prediction.event.quickSearch");
            matomoInstance.trackSiteSearch({
              keyword: searchTerm,
              documentTitle: document.title,
              href: window.location.pathname,
              category,
              count: response.data?.recordSetTotal,
            });
          }

          dispatch(
            TRIGGER_MARKETING_ACTION({
              searchTerm,
              DM_ReqCmd: "SearchDisplay",
            })
          );
        } catch (error) {
          if (isCancel(error)) {
            return;
          }
          if (isAxiosError(error)) {
            setParams({
              error: error.response?.data?.result?.errors[0],
            });
          }
        } finally {
          setParams({
            loading: false,
          });
        }

        siteContentServiceExt
          .findKeywordSuggestions(parameters)
          .then((response) => {
            setParams({
              keywordSuggestions: response.data.suggestionView[0].entry,
            });
          })
          .catch((e) => {
            if (!isCancel(e)) {
              throw e;
            }
          });
      };

      fetch();
    },
    [
      dispatch,
      matomoInstance,
      currentSite?.storeID,
      currentDeliveryDate,
      orderBy,
      orderset?.id,
      ordersetExists,
      params.excludeNoteSearch,
      params.searchOrderset,
      searchIn,
      t,
    ]
  );
  const fetchSearchResultsWithoutDebounce = useCallback(
    (searchTerm: string, signal?: AbortSignal, openPopover = true) => {
      setParams({
        loading: false,
        error: null,
        keywordSuggestions: [],
        spellCheckSuggestions: [],
        searchOrderset: ordersetExists,
        recordTotal: 0,
        products: [],
        excludeNoteSearch: false,
      });

      fetchSearchResults(searchTerm, openPopover, signal);
    },
    [fetchSearchResults, ordersetExists]
  );

  const debouncedFetchSearchResults = useDebouncedCallback(
    (searchTerm: string, signal?: AbortSignal) => {
      fetchSearchResultsWithoutDebounce(searchTerm, signal);
    },
    SEARCH_DEBOUNCE_TIME
  );

  const cancelDebouncedFetchSearchResults = useCallback(() => {
    abortController.current?.abort();
    debouncedFetchSearchResults.cancel();
  }, [debouncedFetchSearchResults]);

  const getSearchResults = useCallback(
    (searchTerm: string) => {
      abortController.current?.abort();
      abortController.current = new AbortController();
      debouncedFetchSearchResults(searchTerm, abortController.current.signal);
    },
    [debouncedFetchSearchResults]
  );

  const disableSearchOrderset = () => {
    setParams({ searchOrderset: false });
    getSearchResults(params.term);
  };

  const formInputRef = useRef<HTMLDivElement>(null);
  const [isBcScannerSearch, setIsBcScannerSearch] = useState(false);
  const [showBarcodeScanIcon, setShowBarcodeScanIcon] = useState(true);
  const showSearchIcon = isLargeScreen || !showBarcodeScanIcon;

  const handlePopoverClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handlePopoverShow = (event) => {
    const { value } = event.target;
    const searchTerm = value.replace(/\*+/g, "");

    setParams({
      term: searchTerm,
    });
    getSearchResults(searchTerm);
  };

  const handlePopoverClick = useCallback(() => {
    dispatch(RESET_PRODUCTLIST_FILTER());
    setParams({
      term: "",
    });
    handlePopoverClose();
  }, [dispatch, handlePopoverClose]);

  const routeToSearchPage = useCallback(() => {
    if (params.term.length) {
      dispatch(RESET_PRODUCTLIST_FILTER());
      navigate(`${searchUri}?searchTerm=${params.term}`);
      setParams({
        term: "",
      });
      handlePopoverClose();
    }
  }, [handlePopoverClose, dispatch, navigate, params.term]);

  const [showScannerDialog, setShowScannerDialog] = useState(false);

  const openScannerDialog = () => {
    setShowScannerDialog(true);
    // hide-userlike class to not display userlike
    document.body.classList.add("hide-userlike");
  };

  const closeScannerDialog = useCallback(() => {
    setParams({
      term: "",
    });
    setShowScannerDialog(false);
    // allow userlike to be visible again
    document.body.classList.remove("hide-userlike");
    setIsBcScannerSearch(false);
  }, []);

  const handleFocusOut = () => {
    setShowBarcodeScanIcon(true);
  };

  const handleOnFocus = () => {
    if (params.term) setOpen(true);
    setShowBarcodeScanIcon(false);
  };

  const doBcScannerSearch = useCallback(
    (bcScannerResult: string) => {
      setIsBcScannerSearch(true);

      const searchTerm = bcScannerResult.replace(/\*+/g, "");
      setParams({
        term: searchTerm,
      });
      abortController.current?.abort();
      abortController.current = new AbortController();
      fetchSearchResultsWithoutDebounce(
        searchTerm,
        abortController.current.signal,
        false
      );
    },
    [fetchSearchResultsWithoutDebounce]
  );

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    cancelDebouncedFetchSearchResults();
    setShowBarcodeScanIcon(false);
    if (event.key === "Enter") {
      routeToSearchPage();
    }
    if (event.key === "ArrowDown" && !!formInputRef.current) {
      const anker = document.getElementsByClassName(`anker_search`);
      if (anker && anker.length > 0) {
        const focusInput = document.getElementById(anker[0].id);
        if (focusInput) focusInput.focus();
      }
    }
  };

  return (
    <>
      <BcScannerDialog
        closeDialog={closeScannerDialog}
        showScannerDialog={showScannerDialog}
        bcScannerResult={doBcScannerSearch}
        params={params}
        handlePopoverClick={handlePopoverClick}
      />
      <StyledFormInput
        className={classes.formInput}
        ref={formInputRef}
        dark
        id="header-search"
        value={params.term}
        placeholder={t("search.inputPlaceholder")}
        fullWidth
        onChange={handlePopoverShow}
        onKeyDown={handleEnterKeyPress}
        onBlur={handleFocusOut}
        onFocus={handleOnFocus}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {showSearchIcon && (
                <IconButton
                  edge="end"
                  onClick={routeToSearchPage}
                  size="large"
                  title={t("iconTooltips.searchIcon")}>
                  <SearchIcon aria-label="start search" />
                </IconButton>
              )}
              {showBarcodeScanIcon && (
                <IconButton
                  className={classes.bcScannerIcon}
                  edge="end"
                  onClick={openScannerDialog}
                  size="large"
                  title={t("iconTooltips.bcSearchIcon")}>
                  <BarcodeScanIcon aria-label="start search via barcode scanning" />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />

      <StyledPopper
        className={classes.popover}
        id="search-id"
        open={!isBcScannerSearch && open}
        anchorEl={formInputRef.current}
        onClose={handlePopoverClose}
        keepMounted>
        <div className={classes.content}>
          <Box className={classes.headerPopover} mb={2}>
            <Typography variant={breakpointUpMd ? "h2" : "h3"} component="p">
              {t("search.suggestions")}
            </Typography>
            <StyledButton
              className="close"
              onClick={handlePopoverClose}
              startIcon={<CloseIcon />}
              size="small"
              color="inherit"
            />
          </Box>

          {params.loading && <StyledProgress />}

          {params.error && (
            <Box mb={3}>
              <StyledNotification severity="error">
                {params.error.errorMessage}
              </StyledNotification>
            </Box>
          )}

          {!params.error && !params.loading && (
            <>
              {searchIn === "orderset" && params.searchOrderset && (
                <Box mb={2}>
                  <Typography variant="body1">
                    {t("search.ordersetSearch")}{" "}
                    <StyledLinkButton onClick={disableSearchOrderset}>
                      {t("search.disableNoteSearch")}
                    </StyledLinkButton>
                  </Typography>
                </Box>
              )}

              {params.keywordSuggestions.length > 0 && (
                <Box mb={2}>
                  {params.keywordSuggestions.map((item) => (
                    <KeywordSuggestionLink
                      key={`SearchSuggestion_${item.term}`}
                      label={item.term}
                      term={params.term}
                      onClick={() => handlePopoverClick()}
                    />
                  ))}
                </Box>
              )}

              {!params.recordTotal && (
                <Box>
                  <SpellCheckSuggestions
                    suggestions={params.spellCheckSuggestions}
                    searchTerm={params.term}
                    onClick={() => handlePopoverClick()}
                  />
                </Box>
              )}

              {params.recordTotal > 0 && (
                <Box mb={2}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid>
                      <Typography variant="h3" component="p">
                        {t("search.articlesFound", {
                          recordTotal: params.recordTotal
                            ? `${params.recordTotal}`
                            : t("numeric.none"),
                        })}
                      </Typography>
                    </Grid>
                    <Grid>
                      <StyledLink
                        to={`${searchUri}?searchTerm=${params.term}`}
                        onClick={handlePopoverClick}>
                        {t("search.showAll")}
                      </StyledLink>
                    </Grid>
                  </Grid>
                </Box>
              )}

              <Box className={classes.overflow}>
                <Box mr={2}>
                  {params.products.map((product) => (
                    <ProductSearchItem
                      key={`SearchItem_${product.uniqueID}`}
                      product={product}
                      onClick={handlePopoverClick}
                    />
                  ))}
                </Box>
              </Box>
            </>
          )}
        </div>
      </StyledPopper>
    </>
  );
};

export default QuickSearch;
