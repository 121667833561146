import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { ROUTES } from "constants/routes";

interface Props {
  restricted: boolean;
  redirectTo?: string;
}

/**
 * Usage:
 *  <Route path={ROUTES.RECIPES} render={
 *    <Restricted restricted={true}>
 *      <Recipes />
 *    </Restricted>
 *  } />
 *
 * redirect to redirectTo when restricted is not true
 */
const Restricted = ({
  children,
  restricted = false,
  redirectTo = ROUTES.HOME,
}: PropsWithChildren<Props>) =>
  restricted ? <Navigate to={redirectTo} replace /> : <>{children}</>;
export default Restricted;
