import React, { FC } from "react";

import { StyledFormSelect, StyledFormSelectItem } from "components/StyledUI";

import { pageSizeOptions } from "constants/site";

interface PageSizeSelectorProps {
  pageSize: string;
  setPageSize: (pageSize: string) => void;
}

const PageSizeSelector: FC<PageSizeSelectorProps> = ({
  pageSize,
  setPageSize,
}) => (
  <StyledFormSelect
    style={{ width: "98px" }}
    value={pageSize}
    onChange={(event) => setPageSize(event.target.value)}>
    {pageSizeOptions.map((option) => (
      <StyledFormSelectItem key={option} value={option}>
        {option}
      </StyledFormSelectItem>
    ))}
  </StyledFormSelect>
);
export default PageSizeSelector;
