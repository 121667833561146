import { call, takeLatest } from "redux-saga/effects";
import { CANCEL_SESSION_ERROR_ACTION } from "_redux/actions/error";
import * as ACTIONS from "_redux/action-types/user";
import * as WORKERS from "_redux/sagas/workers/user";
import * as ORDERWORKERS from "_redux/sagas/workers/order";

import {
  SESSION_ERROR_LOGIN_REQUESTED_ACTION,
  INIT_STATE_FROM_STORAGE_ACTION,
  LOGOUT_REQUESTED_ACTION,
} from "_redux/actions/user";

export function* watchSaga() {
  yield takeLatest(
    INIT_STATE_FROM_STORAGE_ACTION,
    WORKERS.initStateFromStorage
  );
  yield takeLatest(
    ACTIONS.PREDICTIVE_BASKET_REQUESTED,
    WORKERS.getPredictivBasket
  );
  yield takeLatest(
    SESSION_ERROR_LOGIN_REQUESTED_ACTION,
    WORKERS.sessionErrorReLogin
  );
  yield takeLatest(
    [CANCEL_SESSION_ERROR_ACTION, LOGOUT_REQUESTED_ACTION],
    WORKERS.logout
  );
  yield takeLatest(
    ACTIONS.UPDATE_DELIVERY_DATE_REQUESTED,
    updateDeliveryDateAndFetch
  );
  yield takeLatest(ACTIONS.RENEW_LOGIN_REQUESTED, WORKERS.renewLogin);
  yield takeLatest(ACTIONS.REDIRECTED_LOGIN_REQUESTED, WORKERS.redirectedLogin);
  yield takeLatest(ACTIONS.LOGIN_REQUESTED, loginAndFetch);
  yield takeLatest(ACTIONS.REGISTRATION_REQUESTED, WORKERS.registration);
  yield takeLatest(ACTIONS.FETCH_CUSTOMER_PRODUCTLISTS, fetchProductList);
  yield takeLatest(ACTIONS.RELOAD_CUSTOMER_DATA, WORKERS.reload);
  yield takeLatest(ACTIONS.RELOAD_PERSON_DATA, WORKERS.FetchUserDetails);
  yield takeLatest(ACTIONS.RELOAD_ORDER_HISTORY, WORKERS.fetchOrderHistory);
  yield takeLatest(
    ACTIONS.FETCH_DELIVERY_DATES_REQUESTED,
    WORKERS.fetchDeliveryDates
  );
}

function* loginAndFetch(action: any) {
  yield call(WORKERS.login, action);
}

function* fetchProductList(action: any) {
  yield call(WORKERS.fetchProductLists, action);
}

function* updateDeliveryDateAndFetch(action: any) {
  yield call(WORKERS.updateDeliveryDate, action);
  yield call(WORKERS.getCurrentDeliveryDate, action);
  yield call(ORDERWORKERS.fetchCart, action);
}
