import { Dexie, Table } from "dexie";
import resolveEan from "pwa/offline/sync/products/resolve-ean";
import {
  CategoryWithIndices,
  CustomerProductList,
  NamedNumberValue,
  NamedStringValue,
  OfflineOrderItemLogEntry,
  ProductDetailWithIndices,
  Setting,
  SyncTimestamp,
} from "./types";

export class EFoodDB extends Dexie {
  categories!: Table<CategoryWithIndices, string>;

  products!: Table<ProductDetailWithIndices, string>;

  customerProductLists!: Table<CustomerProductList, string>;

  namedValues!: Table<NamedNumberValue | NamedStringValue, string>;

  syncTimestamps!: Table<SyncTimestamp, string>;

  orderItemLog!: Table<OfflineOrderItemLogEntry, number>;

  settings!: Table<Setting, string>;

  constructor() {
    super("EFoodDB");
    this.version(1).stores({
      categories:
        "uniqueID, identifier, parentCatalogGroupID, *childCatalogGroupIDs",
      products:
        "uniqueID, partNumber, eFoodPartnumber, *parentCatgroupsPath, *customerProductlists, *nameTokens, *shortDescTokens, *longDescTokens, *attrTokens",
      customerProductLists: "id",
      namedValues: "name",
      syncTimestamps: "name",
      orderItemLog: "++pk, orderItemId, partNumber",
      settings: "name",
    });
    this.version(2)
      .stores({
        products:
          "uniqueID, partNumber, eFoodPartnumber, *parentCatgroupsPath, *customerProductlists, *nameTokens, *shortDescTokens, *longDescTokens, *attrTokens, lastUpdate",
      })
      .upgrade(async (tx) => {
        const lastProductViewSync = (
          await tx.table<SyncTimestamp>("syncTimestamps").get("productview")
        )?.value;
        return tx
          .table<ProductDetailWithIndices>("products")
          .toCollection()
          .modify((product) => {
            // eslint-disable-next-line no-param-reassign
            product.lastUpdate = lastProductViewSync;
          });
      });
    this.version(3)
      .stores({
        products:
          "uniqueID, partNumber, eFoodPartnumber, *parentCatgroupsPath, *customerProductlists, *nameTokens, *shortDescTokens, *longDescTokens, *attrTokens, lastUpdate, *ean",
      })
      .upgrade(async (tx) =>
        tx
          .table<ProductDetailWithIndices>("products")
          .toCollection()
          .modify((product) => {
            if (product.ean == null || product.ean.length === 0) {
              Object.assign(product, { ean: resolveEan(product) });
            }

            return product;
          })
      );
  }

  public async clearSyncTimestamps() {
    return this.syncTimestamps.clear().catch(() => {
      // ignore error
    });
  }

  public async clearOfflineData() {
    const allPromises = [
      this.categories.clear(),
      this.products.clear(),
      this.customerProductLists.clear(),
      this.clearSyncTimestamps(),
      this.orderItemLog.clear(),
    ];

    return Promise.all(allPromises).catch(() => {
      // ignore error
    });
  }

  public async clearAll() {
    const allPromises = [
      this.clearOfflineData(),
      this.namedValues.clear(),
      this.settings.clear(),
    ];

    return Promise.all(allPromises).catch(() => {
      // ignore error
    });
  }
}

export const eFoodDB = new EFoodDB();
