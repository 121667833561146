import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { StyledButton } from "components/StyledUI";

import { tss } from "tss-react/mui";
import { ContentProps } from "components/dialog/types";

const useStyles = tss.create(({ theme }) => ({
  wrapper: {
    marginTop: theme.spacing(3),
  },
}));

export type ServiceWorkerUpdateAvailableParams = {
  callback?: (updateAccepted: boolean) => void;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const EMPTY_DEFAULT_CALLBACK = () => {};
const FALLBACK_PARAMS: ServiceWorkerUpdateAvailableParams = {};

const ServiceWorkerUpdateAvailable: FC<
  ContentProps<ServiceWorkerUpdateAvailableParams>
> = ({ close, params }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { callback = EMPTY_DEFAULT_CALLBACK } = (params ??
    FALLBACK_PARAMS) as ServiceWorkerUpdateAvailableParams;

  return (
    <>
      <Typography variant="body2" className={classes.wrapper}>
        {t("offline.updateAvailable.message")}
      </Typography>
      <div className={classes.wrapper}>
        <StyledButton
          onClick={() => {
            callback(true);
            close();
          }}
          fullWidth>
          {t("offline.updateAvailable.cta")}
        </StyledButton>
      </div>
    </>
  );
};

export default ServiceWorkerUpdateAvailable;
