/* eslint-disable indent */
import React, { FC, useCallback, useState } from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import { userSelectedProductlistFilterSelector } from "_redux/selectors/user";
import { Offer } from "types/Offer";
import { RESET_PRODUCTLIST_FILTER } from "_redux/actions/user";
import { usePagination } from "_foundationExt/hooks";
import { TrackSearch } from "components/matomo";
import ProductList from "./ProductList";
import { useSearch } from "./useSearch";
import useHeadline from "./useHeadline";
import BreadcrumbSection from "./BreadcrumbSection";
import { useBreadcrumb } from "./useBreadcrumb";

interface ProductSearchContainerProps {
  searchTerm: string | null;
  partNumbers: string | null;
  overrideHeadline: string | null;
  categoryId: string | null;
  productListId: string | null;
  offer?: Offer | null;
}

const ProductSearchContainer: FC<ProductSearchContainerProps> = ({
  searchTerm,
  overrideHeadline,
  partNumbers,
  categoryId,
  productListId,
  offer,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedProductlistFilter = useAppSelector(
    userSelectedProductlistFilterSelector
  );

  const { pageSize, setPageSize, page, setPage } = usePagination();

  const [previousSearchTerm, setPreviousSearchTerm] = useState(searchTerm);

  // search by category and note
  const [excludeNoteSearchState, setExcludeNoteSearchState] = useState(false);

  // disable category search
  const disableNoteSearch = useCallback(
    () => setExcludeNoteSearchState(true),
    []
  );

  // use logic for orderset search
  const [searchInOrdersetState, setSearchInOrdersetState] = useState(true);
  const disableSearchOrderset = useCallback(
    () => setSearchInOrdersetState(false),
    []
  );

  if (searchTerm !== previousSearchTerm) {
    dispatch(RESET_PRODUCTLIST_FILTER());
    setExcludeNoteSearchState(false);
    setSearchInOrdersetState(true);
    setPreviousSearchTerm(searchTerm);
  }

  const [storageSorting, setStorageSorting] = useState("");

  const { error, loadingState, refetch, searchResult } = useSearch(
    searchTerm || "*",
    partNumbers || "",
    pageSize,
    page,
    categoryId,
    excludeNoteSearchState,
    searchInOrdersetState,
    selectedProductlistFilter,
    offer?.id || productListId,
    storageSorting
  );

  const headline = useHeadline(
    searchTerm,
    overrideHeadline,
    productListId,
    null,
    false,
    searchResult.originalNumberOfProducts,
    page,
    pageSize
  );

  const breadcrumb = useBreadcrumb(
    searchTerm,
    partNumbers,
    overrideHeadline,
    offer || null,
    searchResult.breadCrumbTrail || null
  );

  if (error) {
    return (
      <Navigate
        to={{
          pathname: "/error",
        }}
        state={{ errorMessage: t("search.error.generalError") }}
        replace
      />
    );
  }

  return (
    <>
      <BreadcrumbSection breadcrumbData={breadcrumb} />
      <ProductList
        searchResult={searchResult}
        pageSize={pageSize}
        setPageSize={setPageSize}
        page={page}
        setPage={setPage}
        loadingState={loadingState}
        searchTerm={searchTerm}
        headline={loadingState === "loading" ? null : headline}
        filterList={searchResult.filterFacets}
        setStorageSorting={setStorageSorting}
        disableNoteSearch={disableNoteSearch}
        disableSearchOrderset={disableSearchOrderset}
        selectedProductlistFilter={selectedProductlistFilter}
        refetch={refetch}
      />
      {loadingState === "completed" &&
        searchResult &&
        searchResult.searchTerm &&
        searchResult.totalNumberOfProducts != null && (
          <TrackSearch
            searchTerm={searchResult.searchTerm}
            searchResult={searchResult.totalNumberOfProducts}
            searchCategory={t("prediction.event.pageSearch")}
          />
        )}
    </>
  );
};

export default ProductSearchContainer;
