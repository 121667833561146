/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from "react";
import { LinkProps, To } from "react-router-dom";
import CardActionArea, {
  CardActionAreaProps,
} from "@mui/material/CardActionArea";
import { tss } from "tss-react/mui";

const useStyles = tss.create(() => ({
  root: {
    "&:hover": {
      "& .MuiCardMedia-root": {
        backgroundSize: "110%",
      },
    },
  },
  focusHighlight: {
    opacity: "0 !important",
  },
}));

interface StyledCardActionAreaProps {
  href?: string;
  to?: To;
}

const StyledCardActionArea: FC<
  CardActionAreaProps & Omit<LinkProps, "to"> & StyledCardActionAreaProps
> = ({ children, ...rest }) => {
  const { classes } = useStyles();

  return (
    <CardActionArea classes={classes} {...rest}>
      {children}
    </CardActionArea>
  );
};

export { StyledCardActionArea };
