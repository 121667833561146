// react
import React, { FC, useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import HTMLReactParser from "html-react-parser";
// redux
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import { RESET_ERROR_ACTION } from "_redux/actions/error";
import {
  orderApprovalsSelector,
  promotionCodeSelector,
  selectBasketValidations,
} from "_redux/selectors/order";
import { cartErrorSelector } from "_redux/selectors/error";

// mui
import { Typography, Box } from "@mui/material/";

import { StyledNotification } from "components/StyledUI";
// components
import ESpot from "components/widgets/espot/ESpot";
import { useGenericError } from "_foundationExt/hooks/useError";
import { ERR_GENERIC, ERR_PROD_ITEM_NOT_EXIST } from "constants/errors";

import { FETCHING_CART_ACTION } from "_redux/actions/order";
import { BASKET_TYPE } from "constants/order";
import { SCROLL_TO_TOP_ACTION } from "_redux/actions/site";
import { useAbortControllers } from "_foundationExt/hooks";
import { isEqual } from "lodash-es";
import { tss } from "tss-react/mui";
import TriggerCartSyncWatcher from "pwa/offline/cart/TriggerCartSyncWatcher";
import CartActions from "./CartList/CartActions";

const useStyles = tss.create(({ theme }) => ({
  root: {
    marginLeft: theme.spacing(14),
    marginRight: theme.spacing(14),
    maxWidth: `calc(100% - ${theme.spacing(14)})`,
    [theme.breakpoints.down("lg")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      maxWidth: `calc(100% - ${theme.spacing(2)})`,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      maxWidth: `100%`,
    },
    minWidth: "270px",
  },
  bottomRecomContainer: {
    marginTop: theme.spacing(2),
  },
}));

const Cart: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const abortControllers = useAbortControllers();
  const dispatch = useAppDispatch();
  const promotionCodes = useAppSelector(promotionCodeSelector, isEqual);
  const basketValidations = useAppSelector(selectBasketValidations, isEqual);
  const orderApprovals = useAppSelector(orderApprovalsSelector, isEqual);
  const error = useGenericError();
  const cartError = useAppSelector(cartErrorSelector);

  const cartErrorMessages: string[] = [];

  if (error && error.errorKey && error.errorMessage) {
    if (error.errorKey.includes("CART") || error.errorKey.includes("SHOPPING"))
      cartErrorMessages.push(error.errorMessage);
    if (error.errorKey.includes("BAD_RESPONSE"))
      cartErrorMessages.push(error.errorMessage);
  }

  if (cartError && cartError.errorMessage) {
    let { errorMessage } = cartError;

    if (
      cartError.errorKey === ERR_PROD_ITEM_NOT_EXIST &&
      cartError.errorParameters
    ) {
      const articleGroups =
        /(?<name>.*): (?<partNumber>\d{6})\d{3}(\d{4})?.*/.exec(
          cartError.errorParameters
        )?.groups;

      if (articleGroups && articleGroups.name && articleGroups.partNumber) {
        errorMessage = t("error._ERR_PROD_ITEM_NOT_EXIST", {
          products: `${articleGroups.name}: ${articleGroups.partNumber}`,
        });
      }
    } else if (cartError.errorKey === ERR_GENERIC) {
      errorMessage = t("error.generic");
    }

    cartErrorMessages.push(errorMessage);
  }

  const promotionCodeWarnings = useMemo(() => {
    const determinedPromotionCodeWarnings = promotionCodes.filter(
      (promotionCode) => !promotionCode.qualified
    );
    return determinedPromotionCodeWarnings;
  }, [promotionCodes]);

  useEffect(() => {
    const { signal } = abortControllers.create();
    // dont display errors when go to cart
    dispatch(RESET_ERROR_ACTION());
    // refresh orders when go to cart
    const payload = {
      refreshAvailabilities: true,
      calculateOrder: true,
      signal,
    };
    dispatch(FETCHING_CART_ACTION(payload));
  }, [abortControllers, dispatch]);

  useEffect(() => {
    if (promotionCodeWarnings?.length) {
      dispatch(SCROLL_TO_TOP_ACTION(true));
    }
  }, [promotionCodeWarnings, dispatch]);

  return (
    <Box className={classes.root}>
      <TriggerCartSyncWatcher ignoreOnlineStateChange />
      <Box mb={2}>
        <Typography variant="h1">{t("cart.mycart")}</Typography>
      </Box>

      <ESpot emsName="basket_catentry_recommendation_checkout_1" />

      {cartErrorMessages?.length > 0 && (
        <Box mb={1}>
          {cartErrorMessages.map((cartErrorMessage: string) => (
            <StyledNotification severity="error">
              {cartErrorMessage}
            </StyledNotification>
          ))}
        </Box>
      )}
      {promotionCodeWarnings.length > 0 &&
        promotionCodeWarnings.map((promotionCodeWarning) => (
          <Box mb={1} key={promotionCodeWarning.code}>
            <StyledNotification
              severity={promotionCodeWarning.active ? "warning" : "error"}>
              {promotionCodeWarning.active ? (
                <Trans i18nKey="cart.promotion.warning">
                  Ihr Warenkorb erfüllt noch nicht die Bedingungen für die
                  Anwendung des Aktionscodes
                  {{ promotionCode: promotionCodeWarning.code }}
                </Trans>
              ) : (
                <Trans i18nKey="cart.promotion.warning_inactive">
                  Der Aktionscode
                  {{ promotionCode: promotionCodeWarning.code }} ist nicht mehr
                  gültig. Bitte entfernen Sie diesen.
                </Trans>
              )}
              {promotionCodeWarning.conditions && (
                <>
                  &nbsp;
                  {t("cart.promotion.warning_condition")}
                  {HTMLReactParser(promotionCodeWarning.conditions)}
                </>
              )}
            </StyledNotification>
          </Box>
        ))}

      {orderApprovals.map((approval) =>
        approval.basketIdentifier.type === BASKET_TYPE.STOCK_OPTAIN &&
        approval.approvalMessage !== "" ? (
          <Box mb={1}>
            <StyledNotification
              severity={
                approval.approvalStatus === "Denial" ? "error" : "warning"
              }>
              {approval.approvalMessage}
            </StyledNotification>
          </Box>
        ) : null
      )}

      {basketValidations.map(({ key, validation }) => (
        <Box mb={1} key={key}>
          <StyledNotification
            severity={validation.type === "DENIAL" ? "error" : "warning"}>
            {validation.message}
          </StyledNotification>
        </Box>
      ))}

      <Box mb={2} mt={2}>
        <CartActions />
      </Box>

      <ESpot
        emsName="basket_catentry_recommendation_checkout_1_bottom"
        className={classes.bottomRecomContainer}
      />
    </Box>
  );
};

export default Cart;
