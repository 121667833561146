// react
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
// redux
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import { orderIdSelector } from "_redux/selectors/order";
import * as orderActions from "_redux/actions/order";
// mui
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  StyledButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  StyledFormInput,
} from "components/StyledUI";
import { ReactComponent as EditIcon } from "assets/icons/pencil-write-2.svg";
import { Basket } from "types/Order";
import { useOnlineStatus } from "_foundationExt/hooks/useOnlineStatus";

interface OrderCommentType {
  required: boolean;
  text: string;
  basket: Basket;
}

const OrderComment: FC<OrderCommentType> = ({ required, text, basket }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const orderId = useAppSelector(orderIdSelector);
  const { isOffline } = useOnlineStatus();
  const comment = basket.comment || "";
  const [current, setCurrent] = React.useState(comment);
  const [displayed, setDisplayed] = React.useState(comment);
  const [open, setOpen] = React.useState(false);

  const showDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const updateComment = (newComment: string) => {
    const payload = {
      orderId,
      comment: newComment,
      basketIdentifier: basket.identifier,
    };
    dispatch(orderActions.SET_ORDER_COMMENT_ACTION(payload));
  };

  const saveAction = () => {
    setCurrent(displayed);
    updateComment(displayed);
    closeDialog();
  };
  const abortAction = () => {
    setDisplayed(current);
    closeDialog();
  };

  React.useEffect(() => {
    if (comment !== current && current === displayed && !open) {
      setCurrent(comment);
      setDisplayed(comment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, displayed, comment]);

  return (
    <Grid container xs={12}>
      {required ? (
        <StyledFormInput
          placeholder={text + (required ? "*" : "")}
          inputProps={{ required }}
          error={required && displayed === ""}
          onChange={(e) => setDisplayed(e.target.value)}
          value={displayed}
          onBlur={saveAction}
          fullWidth
          style={{ maxWidth: "75%" }}
        />
      ) : (
        <>
          <StyledButton
            variant="outlined"
            startIcon={<EditIcon />}
            size="small"
            onClick={showDialog}
            tooltip={t("iconTooltips.commentIcon")}
            disabled={isOffline}
          />
          <StyledDialog open={open} onClose={closeDialog}>
            <StyledDialogTitle id="dialog-title" onClose={closeDialog}>
              <Box style={{ maxWidth: "275px" }}>{t("cart.comment")}</Box>
            </StyledDialogTitle>

            <StyledDialogContent>
              <Box mt={2}>
                <StyledFormInput
                  placeholder={text}
                  onChange={(e) => setDisplayed(e.target.value)}
                  value={displayed}
                  fullWidth
                  multiline
                  rows="3"
                />
              </Box>

              <Box mt={2}>
                <StyledButton fullWidth onClick={saveAction}>
                  {t("action.save")}
                </StyledButton>
              </Box>

              <Box mt={2}>
                <StyledButton
                  fullWidth
                  variant="outlined"
                  onClick={abortAction}>
                  {t("action.abort")}
                </StyledButton>
              </Box>
            </StyledDialogContent>
          </StyledDialog>
        </>
      )}
    </Grid>
  );
};

export default OrderComment;
