import React, { PropsWithChildren, useMemo } from "react";
import { useEFoodConfig } from "_foundationExt/hooks";
import { ROUTES } from "constants/routes";
import Restricted from "./Restricted";

interface Props {
  registrationType: ReturnType<typeof useEFoodConfig>["registrationtype"];
  compareType: "eq" | "ne";
  redirectTo?: string;
}

/**
 * Usage:
 *  <Route path={ROUTES.REGISTER_TYPE} element={
 *    <RequireRegistrationType registrationType="ALL" compareType="ne">
 *      <Lazy>
 *        <RegisterType />
 *      </Lazy>
 *    </RequireRegistrationType>
 *  } />
 * redirect to site when registrationType does not fulfill comparison
 */
const RequireRegistrationType = ({
  children,
  registrationType,
  compareType,
  redirectTo = ROUTES.LOGON,
}: PropsWithChildren<Props>) => {
  const { registrationtype } = useEFoodConfig();

  const isRestricted = useMemo(() => {
    switch (compareType) {
      case "eq":
        return registrationType === registrationtype;
      case "ne":
        return registrationType !== registrationtype;
      default:
        return true;
    }
  }, [compareType, registrationType, registrationtype]);

  return (
    <Restricted restricted={isRestricted} redirectTo={redirectTo}>
      {children}
    </Restricted>
  );
};

export { RequireRegistrationType };
