import React, { Dispatch, FC, SetStateAction } from "react";
import { Hidden, Box } from "@mui/material";

import ActionBar from "components/header/actionBar";
import { tss } from "tss-react/mui";
import type { ZIndexWithExtension } from "theme/zIndexExtension";
import SideBarCategories from "./SideBarCategories";

const useStyles = tss
  .withParams<{ isSideBarOpen: boolean }>()
  .create(({ theme, isSideBarOpen }) => ({
    offCanvas: {
      background: theme.palette.text.primary,
      color: theme.palette.background.default,
      padding: theme.spacing(3),
      /* Adding as temp fixing for ios menu bug */
      paddingBottom: theme.spacing(7),
      position: "absolute",
      width: "100%",
      height: "calc(100vh - 124px)",
      overflow: "auto",
      zIndex: (theme.zIndex as ZIndexWithExtension).extension.offCanvas,
      display: isSideBarOpen ? "block" : "none",

      [theme.breakpoints.up("sm")]: {
        width: "400px",
      },

      [theme.breakpoints.only("sm")]: {
        height: "calc(100vh - 100px)",
      },
      [theme.breakpoints.only("md")]: {
        height: "calc(100vh - 116px)",
      },
      [theme.breakpoints.only("lg")]: {
        height: "calc(100vh - 120px)",
      },

      // Scrollbar Styling for firefox
      scrollbarColor: `${theme.palette.grey[800]} ${theme.palette.grey.A200}`,
      scrollbarWidth: "thin",

      // Scrollbar Styling for Chrome, Edge, Safari
      "&::-webkit-scrollbar": {
        width: "15px",
      },
      "&::-webkit-scrollbar-track": {},
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.grey[800],
        borderRadius: "10px",
        border: `4px solid ${theme.palette.text.primary}`,
        "&:hover": {
          backgroundColor: theme.palette.primary.light,
        },
      },
    },
    contentShadow: {
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: theme.palette.text.primary,
      zIndex: (theme.zIndex as ZIndexWithExtension).extension.offCanvasShadow,
      opacity: isSideBarOpen ? 0.2 : undefined,
      display: isSideBarOpen ? "block" : "none",
    },
  }));

interface SideBarProps {
  isSideBarOpen: boolean;
  setSideBarOpenStatus: Dispatch<SetStateAction<boolean>>;
}

const SideBar: FC<SideBarProps> = ({ isSideBarOpen, setSideBarOpenStatus }) => {
  const { classes } = useStyles({ isSideBarOpen });

  return (
    <>
      <div
        className={classes.contentShadow}
        onClick={() => setSideBarOpenStatus(false)}
        aria-hidden="true"
      />
      <div className={classes.offCanvas}>
        <Hidden smUp>
          <Box mb={4}>
            <ActionBar setSideBarOpenStatus={setSideBarOpenStatus} />
          </Box>
        </Hidden>
        <SideBarCategories setSideBarOpenStatus={setSideBarOpenStatus} />
      </div>
    </>
  );
};

export default SideBar;
