/* eslint-disable @typescript-eslint/no-explicit-any */

import { getSite } from "_foundation/hooks/useSite";
import { AvailabilityApi } from "@efood-commerce-sdk/typescript-axios-transaction-efood";
import { site } from "_foundationExt/constants/site";
import { AxiosPromise } from "axios";

const availabilityApiInstance = new AvailabilityApi(
  undefined,
  site.transactionContext
);

const availabilityService = {
  getAvailability(orderItemIds: string[]): AxiosPromise<any> {
    const siteInfo = getSite();
    if (!siteInfo) {
      return Promise.reject();
    }

    return availabilityApiInstance.getAvailability(
      siteInfo?.storeID,
      orderItemIds
    );
  },
};

export default availabilityService;
