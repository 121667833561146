import React, { FC } from "react";
import type { ContentProps } from "../types";
import Message from "./Message";

export type OnlyOnlineAvailableParams = Record<string, unknown>;

export const defaultParams = {
  closeButton: true,
  messageKey: "offline.status.defaultMessage",
  titleKey: "offline.status.defaultTitle",
};

const OnlyOnlineAvailable: FC<ContentProps<OnlyOnlineAvailableParams>> = ({
  close,
}) => <Message close={close} params={defaultParams} />;

export default OnlyOnlineAvailable;
