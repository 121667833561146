import React, { FC } from "react";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const Root = styled(Tooltip, {
  name: "StyledTooltip",
  slot: "Root",
  shouldForwardProp: (prop) => prop !== "autoWidth",
})<{ autoWidth: boolean }>(({ theme, autoWidth }) => ({
  "& .MuiTooltip-tooltip": {
    maxWidth: autoWidth ? "initial" : "260px",
  },
  "& .MuiTooltip-tooltipPlacementBottom": {
    margin: `${theme.spacing(1)} 0`,
  },
}));

const Span = styled("span", {
  name: "StyledTooltip",
  slot: "Span",
  shouldForwardProp: (prop) => prop !== "useFlexStyle",
})<{ useFlexStyle?: boolean }>(({ useFlexStyle }) => ({
  display: useFlexStyle ? "flex" : undefined,
}));

interface Props {
  autoWidth?: boolean;
  useflexstyle?: boolean;
}

type StyledTooltipProps = Props & TooltipProps;

const StyledTooltip: FC<StyledTooltipProps> = ({
  autoWidth = false,
  useflexstyle,
  children,
  ...rest
}) => (
  <Root autoWidth={autoWidth} {...rest}>
    <Span useFlexStyle={useflexstyle}>{children}</Span>
  </Root>
);

export { StyledTooltip };
