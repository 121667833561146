import { createSelector } from "@reduxjs/toolkit";
import { BreadcrumbData } from "components/StyledUI/StyledBreadcrumb/StyledBreadcrumb";
import { findObjectPaths } from "find-object-paths";
import { get, isString } from "lodash-es";
import { CatalogGroupView, RootReducerState } from "../reducers";

export const categoryTopSelector = (state: RootReducerState) => {
  return state.catalog.categoryTop;
};

const splitCategoryPath = (
  categoryPath: ReturnType<typeof findObjectPaths>
) => {
  if (isString(categoryPath)) {
    return categoryPath.split(".");
  }
  if (Array.isArray(categoryPath)) {
    return categoryPath[0]?.split(".") ?? [];
  }
  return [];
};

const memoizedSelectTopCategoryByChildCategoryIdCache = new Map<
  string,
  (state: RootReducerState) => CatalogGroupView | undefined
>();
const selectUndefined = () => undefined;
export const makeSelectTopCategoryByChildCategoryId = (
  categoryId?: string | string[]
) => {
  const uniqueID = Array.isArray(categoryId) ? categoryId[0] : categoryId;
  if (!uniqueID) return selectUndefined;

  const cacheEntry =
    memoizedSelectTopCategoryByChildCategoryIdCache.get(uniqueID);
  if (cacheEntry) {
    return cacheEntry;
  }
  const selectTopCategoryByChildCategoryId = createSelector(
    [categoryTopSelector],
    (categoryTop) => {
      const categoryPath = findObjectPaths(categoryTop, {
        key: "uniqueID",
        value: uniqueID,
      });

      const splittedCategoryPath = splitCategoryPath(categoryPath);
      const topCategoryPath = splittedCategoryPath.length
        ? splittedCategoryPath[0]
        : null;

      if (!topCategoryPath) return undefined;

      return get<CatalogGroupView[], string, CatalogGroupView | undefined>(
        categoryTop,
        topCategoryPath,
        undefined
      );
    }
  );
  memoizedSelectTopCategoryByChildCategoryIdCache.set(
    uniqueID,
    selectTopCategoryByChildCategoryId
  );

  return selectTopCategoryByChildCategoryId;
};

export const makeSelectBreadcrumbData = (uniqueIDs?: string[]) => {
  const selectBreadcrumbData = createSelector(
    [categoryTopSelector],
    (topCategories) => {
      if (!uniqueIDs?.length) {
        return [];
      }

      const result: BreadcrumbData[] = [];
      topCategories.forEach((category0) => {
        if (uniqueIDs.includes(category0.uniqueID)) {
          result.push({ label: category0.name, value: category0.uniqueID });
          if (category0.catalogGroupView) {
            category0.catalogGroupView.forEach((category1) => {
              if (uniqueIDs.includes(category1.uniqueID)) {
                result.push({
                  label: category1.name,
                  value: category1.uniqueID,
                });
                if (category1.catalogGroupView) {
                  category1.catalogGroupView.forEach((category2) => {
                    if (uniqueIDs.includes(category2.uniqueID)) {
                      result.push({
                        label: category2.name,
                        value: category2.uniqueID,
                      });
                    }
                  });
                }
              }
            });
          }
        }
      });

      return result;
    }
  );

  return selectBreadcrumbData;
};
