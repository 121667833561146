import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { get } from "lodash-es";

import { Typography } from "@mui/material";
import { StyledKeyVisual } from "components/StyledUI";
import { useFetchESpotDataByFindByName } from "components/widgets/espot/utils";
import cacheLuckyCloudImage from "tools/cacheLuckyCloudImage";
import { useEnvironmentContext } from "components/helpers/environment-context";

interface HomeBannerProps {
  loggedIn: boolean;
  user: {
    firstName: string;
    lastName: string;
  };
}

const HomeBanner: FC<HomeBannerProps> = ({ loggedIn, user }) => {
  const { t } = useTranslation();
  const { envType } = useEnvironmentContext();

  const emsName = loggedIn
    ? "HomePage_Banner_Angemeldet"
    : "HomePage_Banner_Nicht_Angemeldet";
  const { data } = useFetchESpotDataByFindByName({ emsName });

  const imageUrl = useMemo(() => {
    const attachmentUrl = get(data, "[0].contents[0].attachment.url", "");
    if (attachmentUrl) {
      try {
        return cacheLuckyCloudImage(envType, attachmentUrl, true).toString();
      } catch {
        // ignore errors
      }
    }
    return attachmentUrl;
  }, [envType, data]);

  return (
    <StyledKeyVisual
      useBackground
      imageSrc={imageUrl}
      imageAlt="homepage banner">
      {loggedIn ? (
        <Typography>{t("home.welcomeLoggedIn", user)}</Typography>
      ) : (
        <>
          <Typography>{t("home.welcomeLoggedOut")}</Typography>
          <Typography style={{ fontSize: "28px" }}>
            {t("home.welcomeLoggedOutSecondLine")}
          </Typography>
        </>
      )}
    </StyledKeyVisual>
  );
};

export default HomeBanner;
