import React, { PropsWithChildren } from "react";
import { StyledProgress } from "components/StyledUI";

import Grid from "@mui/material/Unstable_Grid2";
import { useLoggedIn } from "_foundationExt/hooks/useLoggedIn";
import { ROUTES } from "constants/routes";
import Restricted from "./Restricted";

interface Props {
  redirectTo?: string;
}

const RequireLoggedOutHome = ({
  children,
  redirectTo = ROUTES.HOME_LOGGEDIN,
}: PropsWithChildren<Props>) => {
  const { loggedIn, loading } = useLoggedIn();

  if (loading) {
    return (
      <Grid container justifyContent="center">
        <StyledProgress />
      </Grid>
    );
  }

  return (
    <Restricted restricted={loggedIn} redirectTo={redirectTo}>
      {children}
    </Restricted>
  );
};

const RequireLoggedInHome = ({
  children,
  redirectTo = ROUTES.HOME,
}: PropsWithChildren<Props>) => {
  const { loggedIn, loading } = useLoggedIn();

  if (loading) {
    return (
      <Grid container justifyContent="center">
        <StyledProgress />
      </Grid>
    );
  }

  return (
    <Restricted restricted={!loggedIn} redirectTo={redirectTo}>
      {children}
    </Restricted>
  );
};

export { RequireLoggedOutHome, RequireLoggedInHome };
