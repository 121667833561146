import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import initStates from "./initStates";
import type {
  ErrorReducerState,
  OrderItemError,
} from "./reducerStateInterface";
import {
  RESET_ERROR_SUCCESS_ACTION,
  HANDLE_SESSION_ERROR_ACTION,
  VALIDATION_ERROR_ACTION,
  RESET_SESSION_POPUP_LOGON_ERROR_ACTION,
} from "_redux/actions/error";
import {
  LOGIN_SUCCESS_ACTION,
  SESSION_ERROR_LOGIN_ERROR_ACTION,
  loginErrorAction,
} from "_redux/actions/user";
import {
  CART_CHECKOUT_ERROR_ACTION,
  CART_PRECHECKOUT_ERROR_ACTION,
  ITEM_MODIFY_ERROR_ACTION,
  ITEM_MODIFY_WARNING_ACTION,
} from "_redux/actions/order";
import {
  CART_FETCHING_REQUESTED,
  ITEM_MODIFY_SUCCESS,
} from "_redux/action-types/order";
import { AnyAction } from "redux-saga";

const errorReducer = createReducer(initStates.error, (builder) => {
  builder.addCase(HANDLE_SESSION_ERROR_ACTION, (state, action) => {
    Object.assign(state, { ...action.payload });
  });
  builder.addCase(SESSION_ERROR_LOGIN_ERROR_ACTION, (state, action) => {
    Object.assign(state, { sessionErrorLoginError: { ...action.payload } });
  });
  builder.addCase(LOGIN_SUCCESS_ACTION, (state, action) => {
    if (state.handled === false) state.handled = true;
  });
  builder.addCase(loginErrorAction, (state, action) => {
    state = action.payload;
  });
  builder.addCase(RESET_ERROR_SUCCESS_ACTION, (state, action) => {
    for (var variableKey in state) {
      if (variableKey !== "cart" && state.hasOwnProperty(variableKey)) {
        delete state[variableKey];
      }
    }
    Object.assign(state, { ...action.payload });
  });
  builder.addCase(
    RESET_SESSION_POPUP_LOGON_ERROR_ACTION,
    (state: ErrorReducerState | any, action) => {
      if (state.hasOwnProperty("sessionErrorLoginError")) {
        delete state.sessionErrorLoginError;
      }
    }
  );
  builder.addCase(
    VALIDATION_ERROR_ACTION,
    (state: ErrorReducerState | any, action) => {
      Object.assign(state, { ...action.payload });
    }
  );
  builder.addCase(CART_FETCHING_REQUESTED, (state, _action) => {
    if (state.cart) {
      delete state.cart;
    }
  });
  builder.addCase(ITEM_MODIFY_SUCCESS, (state, action: AnyAction) => {
    const { payload } = action;

    const { article } = payload;
    const { partNumber } = article;

    if (state.cart?.orderitems) {
      state.cart.orderitems = state.cart.orderitems.filter(
        (e) => e.partNumber !== partNumber
      );
    }
  });
  builder.addCase(ITEM_MODIFY_WARNING_ACTION, (state, action) => {
    const {
      payload: { response, payload },
    } = action;
    const rounded = response?.orderItem?.[0].rounded;

    if (rounded) {
      const orderitemsError = state.cart?.orderitems ?? [];
      if (!state.cart) {
        state.cart = { orderitems: orderitemsError };
      }
      if (!state.cart.orderitems) {
        state.cart.orderitems = orderitemsError;
      }

      const partNumber =
        payload?.article?.partNumber || (action as any).partNumber;

      orderitemsError.push({
        errorKey: "_WARN_QANTITY_ROUNDED_NEXT_VKE",
        errorMessage: "_WARN_QANTITY_ROUNDED_NEXT_VKE",
        partNumber,
        errorParameters: [],
      });
    }
  });
  builder.addCase(ITEM_MODIFY_ERROR_ACTION, (state, action) => {
    const { payload } = action ?? {};
    const { error, article, partNumber } = payload ?? {};
    const errorType = error?.errorType || undefined;
    const errorDataErrors = error?.response?.data?.errors || undefined;
    const orderitemsError = state.cart?.orderitems ?? [];
    if (!state.cart) {
      state.cart = { orderitems: orderitemsError };
    }
    if (!state.cart.orderitems) {
      state.cart.orderitems = orderitemsError;
    }
    const partNumberToUse = article?.partNumber || partNumber;

    if (errorType === "QUANTITY_NOT_UPDATED") {
      orderitemsError.push({
        errorKey: errorType,
        errorMessage: errorType,
        partNumber: partNumberToUse,
      });
    } else if (errorDataErrors?.length) {
      const errorResponse = errorDataErrors[0];
      const errorCode = errorResponse?.errorCode ?? errorResponse?.errorKey;

      if (errorCode === "_ERR_MINQUANTITY_NOTREACHED") {
        const errorMessage: string | undefined = errorResponse?.errorMessage;
        const errorParameters: string | undefined =
          errorResponse?.errorParameters;

        let orderItemError: OrderItemError;
        try {
          const errors = errorParameters ? JSON.parse(errorParameters) : {};
          orderItemError = {
            errorKey: errorCode,
            errorMessage: errorMessage ?? "",
            partNumber: partNumberToUse,
            minQuantityHint: errors[partNumberToUse],
          };
        } catch {
          orderItemError = {
            errorKey: errorCode,
            errorMessage: errorMessage ?? "",
            partNumber: partNumberToUse,
          };
        }
        const oldIndex = orderitemsError.findIndex(
          (orderitem) => orderitem.partNumber === partNumberToUse
        );
        if (oldIndex > -1) {
          orderItemError[oldIndex] = orderItemError;
        } else {
          orderitemsError.push(orderItemError);
        }
      } else if (errorResponse) {
        const errorParameters = errorResponse.errorParameters
          ? errorResponse.errorParameters.split(",")
          : [];
        const orderitemError = orderitemsError.find(
          (orderitem) => orderitem.partNumber === partNumberToUse
        );
        if (orderitemError) {
          orderitemError.errorKey = errorResponse.errorKey;
          orderitemError.errorMessage = errorResponse.errorMessage;
          orderitemError.errorParameters = errorParameters;
        } else if (
          errorResponse.errorKey === "_ERR_THRESHOLD_SHOPPING_CART_QUANTITY"
        ) {
          // TODO: refactor together with useGenericError
          orderitemsError.push({
            errorKey: errorResponse.errorKey,
            errorMessage: errorResponse.errorMessage.replace(
              errorParameters[2],
              errorParameters[2].substring(0, errorParameters[2].length - 3)
            ),
            errorParameters: [],
            partNumber: partNumberToUse,
          });
        } else {
          orderitemsError.push({
            errorKey: errorResponse.errorKey,
            errorMessage: errorResponse.errorMessage,
            errorParameters,
            partNumber: partNumberToUse,
          });
        }
      }
    } else {
      const errorMessage: string | undefined =
        error?.message || error?.errorMessage;
      orderitemsError.push({
        errorKey: "GENERIC_ERROR",
        errorMessage: errorMessage ? errorMessage : "Internal Server Error",
        errorParameters: [],
        partNumber: partNumberToUse,
      });
    }
  });
  builder.addMatcher(
    isAnyOf(CART_PRECHECKOUT_ERROR_ACTION, CART_CHECKOUT_ERROR_ACTION),
    (state, action) => {
      const error = action.payload;
      const errorResponse = error.response?.data.errors[0];
      if (errorResponse) {
        state.cart = {
          ...state.cart,
          errorKey: errorResponse.errorKey,
          errorMessage: errorResponse.errorMessage,
          errorParameters: errorResponse.errorParameters,
          orderitems: [],
        };
      }
    }
  );
});
export default errorReducer;
