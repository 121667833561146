import React, { useCallback, useEffect, useState } from "react";
import { UPDATE_ONLINE_STATUS_ACTION } from "_redux/actions/site";
import { useAppDispatch } from "_redux/hooks";
import { useOnlineStatus } from "_foundationExt/hooks/useOnlineStatus";
import checkOnlineStatus from "./checkOnlineStatus";

const ONLINE_CHECK_TIMEOUT_TIME_MS = 1600;
const MAX_ONLINE_POLLING_INTERVAL = 10000;
const MIN_ONLINE_POLLING_INTERVAL = ONLINE_CHECK_TIMEOUT_TIME_MS + 10;
const OFFLINE_REPORT_THRESHOLD = 3;

const DefaultOnlineStatusChecker: React.FC<{ cache?: RequestCache }> = ({
  cache,
}) => {
  const dispatch = useAppDispatch();
  const { isOnline } = useOnlineStatus();
  const [offlineCount, setOfflineCount] = useState(0);
  const [onlinePollingInterval, setOnlinePollingInterval] = useState(
    MAX_ONLINE_POLLING_INTERVAL
  );

  const checkStatus = useCallback(async () => {
    const online = await checkOnlineStatus(ONLINE_CHECK_TIMEOUT_TIME_MS, cache);
    if (online) {
      setOfflineCount(0);
    } else {
      setOfflineCount((previousCount) => previousCount + 1);
      setOnlinePollingInterval(MIN_ONLINE_POLLING_INTERVAL);
    }
  }, [cache]);

  useEffect(() => {
    // initial check of online state
    checkStatus();
  }, [dispatch, checkStatus]);

  useEffect(() => {
    // repeating check of online state
    const controller = new AbortController();
    const { signal } = controller;
    window.addEventListener(
      "offline",
      () => {
        setOfflineCount(OFFLINE_REPORT_THRESHOLD);
        setOnlinePollingInterval(MIN_ONLINE_POLLING_INTERVAL);
        dispatch(UPDATE_ONLINE_STATUS_ACTION("offline"));
      },
      {
        signal,
      }
    );

    window.addEventListener(
      "online",
      () => {
        setOnlinePollingInterval(MAX_ONLINE_POLLING_INTERVAL);
        checkStatus();
      },
      {
        signal,
      }
    );

    // Add polling incase of slow connection
    const id = setInterval(() => {
      checkStatus();
    }, onlinePollingInterval);

    return () => {
      controller.abort();
      clearInterval(id);
    };
  }, [onlinePollingInterval, dispatch, checkStatus]);

  useEffect(() => {
    if (!isOnline && offlineCount === 0) {
      setOnlinePollingInterval(MAX_ONLINE_POLLING_INTERVAL);
      dispatch(UPDATE_ONLINE_STATUS_ACTION("online"));
    } else if (isOnline && offlineCount === OFFLINE_REPORT_THRESHOLD) {
      // only report offline state once, after OFFLINE_REPORT_THRESHOLD failed requests
      dispatch(UPDATE_ONLINE_STATUS_ACTION("offline"));
    }
  }, [dispatch, isOnline, offlineCount]);

  return null;
};

export default DefaultOnlineStatusChecker;
