// react
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
// mui
import { Box, Grid, Typography } from "@mui/material";
import { UnstyledLink } from "components/StyledUI";
import { format, parse } from "date-fns";
import { ROUTES } from "constants/routes";
import defaultOfferImage from "assets/images/default-offer.jpg";
import { useBreakpoints } from "_foundationExt/hooks/useBreakpoints";
import { tss } from "tss-react/mui";
import useFetchOffers from "./useFetchOffers";

const useStyles = tss.create(({ theme }) => ({
  box: {
    padding: "24px 45px 100px 20px",
  },
  offerContainer: {
    padding: "12px 12px 24px 12px",
  },
  offerImage: {
    height: "290px",
    width: "210px",
    margin: 0,
    boxShadow: "0 0 15px grey",
  },
  offerDescription: {
    textAlign: "center",
  },
  text: {
    maxWidth: "200px",
    height: "40px",
    margin: "0 auto",
  },
  border: {
    borderRight: `1px dashed ${theme.palette.grey[300]}`,
    padding: "12px 12px 24px 12px",
  },
}));

const transformDateFormat = (date) =>
  format(parse(date, "yyyy-MM-dd", new Date()), "dd.MM.yyyy");

const OffersContainer: FC = () => {
  const { classes } = useStyles();

  const { t } = useTranslation();

  const offers = useFetchOffers();

  const { rows } = useBreakpoints();

  return (
    <>
      <Typography variant="h1" paragraph>
        {t("offers.title")}
      </Typography>

      <Box className={classes.box}>
        <Grid container>
          {offers &&
            offers.map((offer, index) => (
              <Grid
                key={offer.id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className={
                  (index + 1) % rows === 0 || offers.length === index + 1
                    ? classes.offerContainer
                    : classes.border
                }>
                <UnstyledLink to={`${ROUTES.OFFERS}/${offer.offerNumber}`}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <img
                      src={offer.imagePath || defaultOfferImage}
                      alt={offer.title}
                      className={classes.offerImage}
                    />
                  </div>
                  <Box pt={3}>
                    <Typography
                      variant="h5"
                      align="center"
                      className={classes.text}>
                      {offer.title}
                    </Typography>
                  </Box>
                  {offer.description && (
                    <div className={classes.offerDescription}>
                      {offer.description}
                    </div>
                  )}
                  <Typography variant="body2" align="center">
                    {t("offers.valid", {
                      validFrom: `${transformDateFormat(offer.validFrom)}`,
                      validTo: `${transformDateFormat(offer.validTo)}`,
                    })}
                  </Typography>
                </UnstyledLink>
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  );
};

export default OffersContainer;
