import React, { FC } from "react";
import { Box } from "@mui/material";
import { StyledProgress } from "components/StyledUI";
import type { ContentProps } from "../types";

export type SessionRenewalParams = Record<string, unknown>;

const SessionRenewal: FC<ContentProps<SessionRenewalParams>> = () => (
  <Box mt={2} textAlign="center">
    <StyledProgress />
  </Box>
);

export default SessionRenewal;
