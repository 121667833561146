/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { ReactComponent as LeftIcon } from "assets/icons/arrow-left-1.svg";
import { ReactComponent as RightIcon } from "assets/icons/arrow-right-1.svg";
import { StyledButton } from "components/StyledUI";
import { ESpotData } from "types/Marketing";
import { categoryTopSelector } from "_redux/selectors/catalog";
import { useAppSelector } from "_redux/hooks";
import { CatalogGroupView } from "_redux/reducers";
import { tss } from "tss-react/mui";
import TitleCard from "./TitleCard";
import CatalogGroupCard from "./CatalogGroupCard";
import getCatalogGroupViewFromTopCategoriesById from "../utils/getCatalogGroupViewFromTopCategoriesById";

const useStyles = tss.create(({ theme }) => ({
  productSlide: {
    paddingTop: theme.spacing(2),

    "& > div": {
      borderRight: `1px dashed ${theme.palette.grey[300]}`,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    "&:last-of-type > div": {
      borderRight: 0,
    },
  },
  arrows: {
    position: "absolute",
    zIndex: 1,
    "&.MuiButton-root:focus": {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[800],
    },
    "&.MuiButton-root:hover": {
      backgroundColor: theme.palette.primary.light,
      color: "#fff",
    },
  },
  left: {
    top: "12px",
    right: "56px",
  },
  right: {
    top: "12px",
    right: "12px",
  },
  leftSidebar: {
    top: "18px",
    right: "85px",
  },
  rigthSidebar: {
    top: "18px",
    right: "41px",
  },
  sidebar: {
    border: `2px solid ${theme.palette.grey[50]}`,
    borderRadius: theme.shape.borderRadius * 2,
    [theme.breakpoints.up("lg")]: {
      maxWidth: `calc(100% - ${theme.spacing(3)})`,
      marginRight: theme.spacing(3),
    },
    "& figure": {
      maxWidth: "100%",
    },
    "& > div > div": {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(3),
    },
  },
  title: {
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
  },
  titleScrollButtonSpacing: {
    marginRight: theme.spacing(14), // buttons should not overlap title
  },
}));

interface CatalogGroupSliderProps {
  id: string;
  data: ESpotData;
  headline?: string;
  sidebar?: boolean;
}

const CatalogGroupSlider: React.FC<CatalogGroupSliderProps> = ({
  id,
  data,
  headline,
  sidebar,
}) => {
  const [index, setIndex] = React.useState(0);
  const ref = useRef<any>(null);
  const { classes, cx } = useStyles();
  const [cols, setCols] = useState(4);

  const { catalogGroupIds } = data;

  const categoryViewTop = useAppSelector(categoryTopSelector);

  const { filteredCatalogGroupIds, filterdCatalogGroupViews } =
    React.useMemo(() => {
      let j = 0;
      const tmpFilteredCatalogGroupIds: string[] = [];
      const tmpFilterdCatalogGroupViews: CatalogGroupView[] = [];

      // Determine the CatalogGroupViews and filter empyty or not defined CatalogGroupViews
      for (let i = 0; i < catalogGroupIds.length; i++) {
        const currentCatalogGroupView =
          getCatalogGroupViewFromTopCategoriesById(
            categoryViewTop,
            catalogGroupIds[i]
          );
        if (currentCatalogGroupView !== null) {
          tmpFilteredCatalogGroupIds[j] = catalogGroupIds[i];
          tmpFilterdCatalogGroupViews[j] = currentCatalogGroupView;
          j += 1;
        }
      }
      return {
        filteredCatalogGroupIds: tmpFilteredCatalogGroupIds,
        filterdCatalogGroupViews: tmpFilterdCatalogGroupViews,
      };
    }, [catalogGroupIds, categoryViewTop]);

  const calculateCols = () => {
    const newWidth = ref.current?.clientWidth;
    let newCols = 1;
    if (newWidth > 0 && newWidth < 499) newCols = 1;
    else if (newWidth > 500 && newWidth < 749) newCols = 2;
    else if (newWidth > 750 && newWidth < 999) newCols = 3;
    else if (newWidth > 1000) newCols = 4;

    setCols(newCols);
  };

  useEffect(() => {
    if (ref.current.clientWidth) {
      setCols(Math.floor(ref.current.clientWidth / 290));
    }
    window.addEventListener("resize", calculateCols);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const forward = () => {
    setIndex((oldIndex) =>
      oldIndex < filteredCatalogGroupIds.length - 1 ? oldIndex + 1 : 0
    );
  };
  const backward = () => {
    setIndex((oldIndex) =>
      oldIndex > 0 ? oldIndex - 1 : filteredCatalogGroupIds.length - 1
    );
  };

  const displayedCatalogGroups: React.ReactElement[] = [];

  const calcIndex = (i: number) => {
    let ci = index + i;
    if (ci > filteredCatalogGroupIds.length - 1)
      ci -= filteredCatalogGroupIds.length;
    return ci;
  };

  const rows = cols < 1 ? 1 : cols;

  for (let i = 0; i < rows && i < filteredCatalogGroupIds.length; i++) {
    if (filterdCatalogGroupViews[i] !== null) {
      displayedCatalogGroups.push(
        <Grid
          xs
          key={`espot_${id}_catalogGroup_${
            filteredCatalogGroupIds[calcIndex(i)]
          }`}
          style={
            filteredCatalogGroupIds.length < rows
              ? { maxWidth: `${100 / rows}%` }
              : {}
          }
          className={classes.productSlide}>
          <CatalogGroupCard
            currentCatalogGroupView={filterdCatalogGroupViews[calcIndex(i)]}
          />
        </Grid>
      );
    }
  }

  const needsScrollButtons =
    filteredCatalogGroupIds &&
    filteredCatalogGroupIds.length > 1 &&
    filteredCatalogGroupIds.length > rows;

  return (
    <Grid
      container
      xs
      ref={ref}
      className={cx({ [classes.sidebar]: sidebar && cols === 1 })}
      data-track-content={data.contentTracking?.content || undefined}
      data-content-name={data.contentTracking?.name || undefined}
      data-content-piece={data.type.join(",") || undefined}>
      {((data.titles && data.titles.length > 0) || headline) && (
        <TitleCard
          headline={headline}
          data={data}
          className={cx(
            { [classes.titleScrollButtonSpacing]: needsScrollButtons },
            classes.title
          )}
        />
      )}

      {needsScrollButtons && (
        <>
          <StyledButton
            startIcon={<LeftIcon />}
            size="small"
            color="inherit"
            className={cx(
              { [classes.leftSidebar]: sidebar, [classes.left]: !sidebar },
              classes.arrows
            )}
            onClick={backward}
          />
          <StyledButton
            startIcon={<RightIcon />}
            size="small"
            color="inherit"
            className={cx(
              { [classes.rigthSidebar]: sidebar, [classes.right]: !sidebar },
              classes.arrows
            )}
            onClick={forward}
          />
        </>
      )}
      {displayedCatalogGroups &&
        displayedCatalogGroups.map((product) => product)}
    </Grid>
  );
};

export default CatalogGroupSlider;
