// react
import React, { FC, useState, useEffect } from "react";
import { Backdrop } from "@mui/material";
import { StyledPopper } from "components/StyledUI";
// components
import { useSite } from "_foundationExt/hooks/useSite";
// assets
import { tss } from "tss-react/mui";
import PostalCodeView from "./PostalCodeView";
import LogonView from "./LogonView";

const useStyles = tss.create(() => ({
  backdrop: {
    zIndex: 21,
    color: "#fff",
  },
}));

const CentralLogonPopup: FC = () => {
  const { classes } = useStyles();
  const { currentSite } = useSite();

  const [view, setView] = useState<number>();

  useEffect(() => {
    if (currentSite?.storeID === "10101" && !view) {
      setView(1);
    }
    if (view === 1 || view === 2) {
      document.body.style.overflow = "hidden";
    }
  }, [currentSite?.storeID, view]);

  if (!view) {
    return null;
  }

  return (
    <>
      <Backdrop open className={classes.backdrop} />
      <StyledPopper
        open
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bottom: "unset",
          right: "unset",
          zIndex: 22,
        }}>
        {view === 1 && <LogonView setView={setView} />}
        {view === 2 && <PostalCodeView setView={setView} />}
      </StyledPopper>
    </>
  );
};
export default CentralLogonPopup;
