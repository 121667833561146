import { useMemo } from "react";
import { useAppSelector } from "_redux/hooks";
import { onlineStatusSelector } from "_redux/selectors/site";

const useOnlineStatus = () => {
  const onlineStatus = useAppSelector(onlineStatusSelector);

  const status = useMemo(
    () => ({
      status: onlineStatus.status,
      isOnline: onlineStatus.status === "online",
      isOffline: onlineStatus.status === "offline",
      isLoading: onlineStatus.status === "initial",
      isChanged: onlineStatus.changed,
    }),
    [onlineStatus.status, onlineStatus.changed]
  );

  return status;
};

export { useOnlineStatus };
