import React, { FC } from "react";

import { Box } from "@mui/material";
import {
  StyledButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "components/StyledUI";
import { Trans, useTranslation } from "react-i18next";

interface Props {
  closeDialog: () => void;
  showDialog: boolean;
}

const BcScannerHelpDialog: FC<Props> = ({ closeDialog, showDialog }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    closeDialog();
  };

  return (
    <StyledDialog open={showDialog} onClose={handleClose}>
      <StyledDialogTitle id="dialog-title" onClose={handleClose}>
        {t("barcodeScanner.helpDialog.dialogHeader")}
      </StyledDialogTitle>

      <StyledDialogContent>
        <Box>
          <Trans i18nKey="barcodeScanner.helpDialog.dialogContent" />
        </Box>

        <Box mt={1}>
          <StyledButton fullWidth variant="contained" onClick={handleClose}>
            {t("barcodeScanner.helpDialog.close")}
          </StyledButton>
        </Box>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default BcScannerHelpDialog;
