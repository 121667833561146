// react
import React, { FC, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
// mui
import { Box, FormGroup } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  StyledFormLabel,
  StyledFormCheckbox,
  StyledFormControl,
  StyledButton,
  StyledDialog,
  StyledDialogTitle,
  StyledDialogContent,
  StyledFilterDropdown,
  StyledProtectable,
  StyledIcon,
} from "components/StyledUI";
// components
import { ReactComponent as StarIcon } from "assets/icons/rating-star.svg";
import { ReactComponent as ContractIcon } from "assets/attributeIcons/icon-kennzeichen-kontrakt.svg";
import customerProductlistService from "_foundationExt/apis/transaction/customerProductlist.service";
import { useSite } from "_foundationExt/hooks/useSite";
import { useOnlineStatus } from "_foundationExt/hooks/useOnlineStatus";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import CreateListDialog from "components/productOptions/CreateListDialog";
import { tss } from "tss-react/mui";
import useProductOptions from "./useProductOptions";

const useStyles = tss.create(({ theme }) => ({
  dialog: {
    minWidth: "350px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
    },
  },
  orderset: {
    "& .MuiFormControlLabel-root": {
      marginTop: "0",
      marginBottom: theme.spacing(1),
    },
  },
  active: {
    color: theme.palette.primary.main,
    "& path": {
      fill: `${theme.palette.primary.main} !important`,
    },
    "&:hover path": {
      fill: `${theme.palette.primary.light} !important`,
    },
  },
  marginBottomOne: {
    marginBottom: theme.spacing(1),
  },
  marginLeftOne: {
    marginLeft: theme.spacing(1),
  },
  checkbox: {
    display: "flex",
    alignItems: "center",
  },
  createListWrapper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    cursor: "pointer",
    gap: theme.spacing(1),

    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
  },
  createListIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.primary.main,
  },
}));

interface Props {
  productId: string;
  size?: "medium" | "large" | "small" | undefined;
  refetch?: () => void;
  customerProductlists?: string[];
  selectedCustomerProductListId?: string;
  inContract?: boolean;
}

const ProductOptions: FC<Props> = ({
  productId,
  size = "small",
  refetch,
  customerProductlists = [],
  selectedCustomerProductListId,
  inContract = false,
}) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const { currentSite } = useSite();
  const { isOffline } = useOnlineStatus();

  const [showDialog, setShowDialog] = useState(false);
  const [checkbox, setCheckBox] = useState({});
  const [loading, setLoading] = useState(true);
  const [inList, setInList] = useState(false);

  const { orderset, labellist, shoppinglist, customerProductLists } =
    useProductOptions();

  const openDialog = () => {
    setLoading(true);
    setShowDialog(true);
  };

  useEffect(() => {
    setInList(customerProductlists.length > 0 || inContract);
  }, [customerProductlists, inContract]);

  const closeDialog = () => {
    const changedIds = Object.keys(checkbox).filter(
      (key) => checkbox[key].changed
    );

    const isInList =
      Object.keys(checkbox).filter((key) => checkbox[key].checked).length > 0;

    let reload = false;
    const requests: Promise<unknown>[] = [];

    changedIds.forEach((key) => {
      if (checkbox[key].checked) {
        requests.push(
          customerProductlistService.addProduct({
            storeId: currentSite ? currentSite.storeID : "",
            customerProductListId: key,
            body: {
              productId,
            },
          })
        );
      } else {
        if (
          !reload &&
          selectedCustomerProductListId != null &&
          selectedCustomerProductListId === key
        ) {
          reload = true;
        }
        requests.push(
          customerProductlistService.deleteProduct({
            storeId: currentSite ? currentSite.storeID : "",
            customerProductListId: key,
            productId,
          })
        );
      }
    });

    Promise.all(requests)
      .then(() => {
        // nothing to do
      })
      .finally(() => {
        setInList(isInList || inContract);
        setShowDialog(false);
        if (refetch && reload) refetch();
      });
  };

  const changeCheckbox = (event) => {
    setCheckBox({
      ...checkbox,
      [event.target.name]: {
        checked: event.target.checked,
        changed: true,
      },
    });
  };

  const loadCheckboxData = useCallback(
    (storeId) => {
      customerProductlistService
        .getListsByProductId({ storeId, productId })
        .then((res) => {
          const productListsResult = res.data.productlists as
            | { id: string }[]
            | undefined;
          const checkboxListsResult = {};

          customerProductLists.forEach((customerProductListsItem) => {
            const foundItem = productListsResult?.some(
              (productListsResultItem) =>
                productListsResultItem.id === customerProductListsItem.id
            );
            checkboxListsResult[customerProductListsItem.id] = {
              checked: foundItem,
              changed: false,
            };
          });

          setCheckBox(checkboxListsResult);
        });
    },
    [customerProductLists, productId]
  );

  const [showCreateListDialog, setShowCreateListDialog] = useState(false);

  const onCreateList = () => {
    setShowCreateListDialog(true);
  };

  useEffect(() => {
    if (currentSite?.storeID && showDialog && loading) {
      loadCheckboxData(currentSite.storeID);
      setLoading(false);
    }
  }, [currentSite?.storeID, showDialog, checkbox, loading, loadCheckboxData]);

  return (
    <StyledProtectable>
      <StyledButton
        className={cx({ [classes.active]: inList })}
        variant="outlined"
        size={size}
        onClick={() => {
          openDialog();
        }}
        startIcon={<StarIcon />}
        tooltip={t("iconTooltips.favoriteIcon")}
        disabled={isOffline}
      />

      <StyledDialog onClose={closeDialog} open={showDialog}>
        <StyledDialogTitle id="dialog-title" onClose={closeDialog}>
          {t("productListing.option")}
        </StyledDialogTitle>

        <StyledDialogContent className={classes.dialog}>
          {inContract && (
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              className={classes.marginBottomOne}>
              <Grid xs={1}>
                <StyledIcon size="30">
                  <ContractIcon />
                </StyledIcon>
              </Grid>
              <Grid xs={11}>
                <span className={classes.marginLeftOne}>
                  {t("productListing.optionContractArticleHint")}
                </span>
              </Grid>
            </Grid>
          )}

          <StyledFormLabel> {t("productListing.addTo")}</StyledFormLabel>

          {orderset?.id && checkbox[orderset.id] && (
            <StyledFormControl className={classes.orderset}>
              <StyledFormCheckbox
                checkboxProps={{
                  name: orderset.id,
                  checked: checkbox[orderset.id]?.checked,
                  onChange: changeCheckbox,
                }}
                label={t("productListing.orderset")}
              />
            </StyledFormControl>
          )}
          {labellist?.id && checkbox[labellist.id] && (
            <StyledFormControl className={classes.orderset}>
              <StyledFormCheckbox
                checkboxProps={{
                  name: labellist.id,
                  checked: checkbox[labellist.id]?.checked,
                  onChange: changeCheckbox,
                }}
                label={t("productListing.labelList")}
              />
            </StyledFormControl>
          )}

          <Grid container spacing={2}>
            <Grid xs={12}>
              <StyledFormLabel>{t("productListing.addToList")}</StyledFormLabel>
              <StyledFilterDropdown
                buttonLabel={t("productListing.myLists")}
                style={{ width: "100%" }}>
                <FormGroup>
                  <Box
                    className={classes.createListWrapper}
                    onClick={onCreateList}>
                    <AddIcon className={classes.createListIcon} />
                    <div>{t("productListing.createList")}</div>
                  </Box>
                  {shoppinglist.length > 0 ? (
                    shoppinglist.map((list) => (
                      <StyledFormCheckbox
                        className={classes.checkbox}
                        key={list.id}
                        size="small"
                        noMarginTop
                        checkboxProps={{
                          checked: checkbox[list.id]
                            ? checkbox[list.id].checked
                            : false,
                          name: list.id,
                          onChange: changeCheckbox,
                        }}
                        label={list.name}
                      />
                    ))
                  ) : (
                    <>{t("myAccount.listManagement.emptyList")}</>
                  )}
                </FormGroup>
              </StyledFilterDropdown>
            </Grid>
          </Grid>

          <Box mt={2}>
            <StyledButton fullWidth onClick={closeDialog}>
              {t("action.close")}
            </StyledButton>
          </Box>
        </StyledDialogContent>
      </StyledDialog>

      <CreateListDialog
        closeDialog={() => setShowCreateListDialog(false)}
        setInList={setInList}
        showDialog={showCreateListDialog}
        productId={productId}
        closeShowDialog={() => setShowDialog(false)}
      />
    </StyledProtectable>
  );
};

export default React.memo(ProductOptions);
