import React, { FC, useMemo } from "react";

import { useAppSelector } from "_redux/hooks";
import { UnstyledLink } from "components/StyledUI";
import { ROUTES } from "constants/routes";

import { CatalogGroupView } from "_redux/reducers";
import { makeSelectTopCategoryByChildCategoryId } from "_redux/selectors/catalog";
import { tss } from "tss-react/mui";

const useStyles = tss
  .withParams<{
    centered: boolean;
    size?: string;
  }>()
  .create(({ centered, size }) => ({
    image: {
      margin: centered ? "0 auto" : "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "start",
      height: `${size}px`,
      width: `${size}px`,
      borderRadius: size && parseInt(size, 10) > 68 ? 8 : 4,
      "& img": {
        maxHeight: "100%",
        maxWidth: "100%",
        textIndent: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
    },
  }));

interface Props {
  catalogGroupView: CatalogGroupView;
  size?: string;
  centered?: boolean;
}

const CategoryImage: FC<Props> = ({
  catalogGroupView,
  size,
  centered = false,
}) => {
  const { classes } = useStyles({
    size,
    centered,
  });

  const selectTopCategoryByChildCategoryId = useMemo(
    () => makeSelectTopCategoryByChildCategoryId(catalogGroupView.uniqueID),
    [catalogGroupView.uniqueID]
  );

  const topCategory = useAppSelector(selectTopCategoryByChildCategoryId);

  const src = topCategory?.fullImage || undefined;

  if (!src) {
    <UnstyledLink to={`${ROUTES.PRODUCT_LIST}/${catalogGroupView.uniqueID}`}>
      {topCategory?.name ?? catalogGroupView.name}
    </UnstyledLink>;
  }

  return (
    <UnstyledLink to={`${ROUTES.PRODUCT_LIST}/${catalogGroupView.uniqueID}`}>
      <figure className={classes.image}>
        <img src={src} alt={topCategory?.name} />
      </figure>
    </UnstyledLink>
  );
};

export default CategoryImage;
