import React, { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { StyledButton } from "components/StyledUI";
import { tss } from "tss-react/mui";
import { ContentProps } from "components/dialog/types";
import {
  RESET_SYNC_MANUALLY_ACTION,
  UPDATE_SYNC_RUNNING_ACTION,
} from "_redux/actions/site";
import { useAppDispatch } from "_redux/hooks";
import ShowSyncState from "./ShowSyncState";
import useSyncCatalogData from "../common/useSyncCatalogData";

const useStyles = tss.create(({ theme }) => ({
  wrapper: {
    marginTop: theme.spacing(2),
  },
}));

export type SyncOfflineDataParams = {
  force?: boolean;
  cancelable?: boolean;
  closeOnSuccess?: boolean;
  onClose?: () => void;
};

const SyncOfflineData: FC<ContentProps<SyncOfflineDataParams>> = ({
  close,
  params,
}) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    force = false,
    cancelable = false,
    closeOnSuccess = false,
    onClose,
  } = params ?? {};
  const {
    categoriesSyncState,
    customerProductListsSyncState,
    productsSyncState,
    pricesSyncState,
    error,
    loading,
    finished,
    startSync,
    stopSync,
  } = useSyncCatalogData({ force });

  const parentClose = useCallback(() => {
    dispatch(UPDATE_SYNC_RUNNING_ACTION(false));
    dispatch(RESET_SYNC_MANUALLY_ACTION());
    if (typeof onClose === "function") {
      onClose();
    }
    close();
  }, [close, onClose, dispatch]);

  const onCloseHandler = useCallback(() => {
    stopSync();
    parentClose();
  }, [parentClose, stopSync]);

  useEffect(() => {
    if (finished && (!cancelable || closeOnSuccess)) {
      parentClose();
    }
  }, [cancelable, parentClose, closeOnSuccess, finished]);

  useEffect(() => {
    if (!error && !loading && !finished) {
      dispatch(UPDATE_SYNC_RUNNING_ACTION(true));
      startSync();
    }
  }, [error, finished, loading, startSync, dispatch]);

  return (
    <>
      <div>
        <ShowSyncState
          syncState={categoriesSyncState}
          labelKey="offline.sync.data.category"
        />
        <ShowSyncState
          syncState={customerProductListsSyncState}
          labelKey="offline.sync.data.customerList"
        />
        <ShowSyncState
          syncState={productsSyncState}
          labelKey="offline.sync.data.product"
        />
        <ShowSyncState
          syncState={pricesSyncState}
          labelKey="offline.sync.data.price"
        />
      </div>
      {(error || cancelable) && (
        <div className={classes.wrapper}>
          <StyledButton onClick={onCloseHandler} variant="outlined">
            {t(closeOnSuccess && !error ? "action.abort" : "action.close")}
          </StyledButton>
        </div>
      )}
    </>
  );
};

export default SyncOfflineData;
