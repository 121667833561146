import { Box } from "@mui/material";
import { StyledNotification } from "components/StyledUI";
import { find, isEmpty } from "lodash-es";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "_redux/hooks";
import { errorSelector } from "_redux/selectors/error";

const ErrorToDisplay = [
  {
    errorKey: "ERR_BAD_RESPONSE",
    errorCode: "500",
    errorTitleKey: "AxiosError",
    errorMessage: "Internal Server Error",
    handled: false,
  },
];

const findError = (error) => {
  if (!isEmpty(error)) {
    return !find(ErrorToDisplay, {
      errorKey: error.errorKey,
      errorCode: error.errorCode,
      errorTitleKey: error.errorTitleKey,
      errorMessage: error.errorMessage,
      handled: error.handled,
    });
  }
  return true;
};

const ErrorAlert: FC = () => {
  const error = useAppSelector(errorSelector);
  const { t } = useTranslation();

  if (findError(error)) return null;

  return (
    <Box pb={3}>
      <StyledNotification
        severity="error"
        close
        onClick={() => window.location.reload()}>
        {t("error.generic")}. {t("error.reload")}!
      </StyledNotification>
    </Box>
  );
};

export default ErrorAlert;
