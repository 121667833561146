import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import type { ContentProps } from "../types";
import type { SessionRenewalParams } from "../contents/SessionRenewal";

const SessionRenewalTitle: FC<ContentProps<SessionRenewalParams>> = () => {
  const { t } = useTranslation();

  return (
    <Typography variant="h3">
      {t("app.dialog.sessionRenewal.headline")}
    </Typography>
  );
};

export default SessionRenewalTitle;
