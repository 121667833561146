import React, { FC, ForwardedRef } from "react";
import { Link, LinkProps } from "react-router-dom";
import { tss } from "tss-react/mui";

const useStyles = tss.create(() => ({
  root: {
    textDecoration: "none",
    color: "inherit",
  },
}));

const UnstyledLink: FC<
  LinkProps & { forwardedRef: ForwardedRef<HTMLAnchorElement> }
> = ({ to, children, className, forwardedRef, ...rest }) => {
  const { classes, cx } = useStyles();

  const isExternalLink = to ? /^http[s]?:\/\//.test(to.toString()) : false;

  if (isExternalLink) {
    return (
      <a
        href={to.toString()}
        target="_blank"
        rel="noopener noreferrer"
        className={cx(classes.root, className)}
        ref={forwardedRef}
        {...rest}>
        {children}
      </a>
    );
  }

  return (
    <Link
      to={to}
      className={cx(classes.root, className)}
      ref={forwardedRef}
      {...rest}>
      {children}
    </Link>
  );
};

const RefForwardingUnstyledLink = React.forwardRef<
  HTMLAnchorElement,
  LinkProps
>((props, ref) => <UnstyledLink {...props} forwardedRef={ref} />);

export { RefForwardingUnstyledLink as UnstyledLink };
