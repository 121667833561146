import { useCallback } from "react";
import useFetchSyncData, {
  UseFetchSyncDataFunctionProps,
  UseFetchSyncDataFunctionReturnType,
} from "../common/useFetchSyncData";
import clearCustomerProductListsData from "./clearCustomerProductListsData";
import doSyncCustomerProductLists from "./doSyncProductLists";
import productListsSyncTimestamp from "./productListsSyncTimestamp";

const useSyncCustomerProductLists: (
  props: Omit<UseFetchSyncDataFunctionProps, "fetchSyncData" | "cleanup">
) => UseFetchSyncDataFunctionReturnType = ({
  callback: externalCallback,
  paginated,
  ...rest
}) => {
  const callback = useCallback(
    (timestamp: unknown) => {
      if (paginated) {
        productListsSyncTimestamp.put(timestamp);
      }
      if (externalCallback) {
        externalCallback(timestamp);
      }
    },
    [externalCallback, paginated]
  );

  return useFetchSyncData({
    fetchSyncData: doSyncCustomerProductLists,
    cleanup: clearCustomerProductListsData,
    callback,
    paginated,
    ...rest,
  });
};

export default useSyncCustomerProductLists;
