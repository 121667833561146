// react
import React, { FC } from "react";

import { useBreakpoints } from "_foundationExt/hooks/useBreakpoints";
// mui
import { Hidden, useTheme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { StyledPriceTable, StyledPriceTableSmall } from "components/StyledUI";
// types
import { Product } from "types/Product";
// components
import { CustomerProductlist } from "types/CustomerProductlist";
import ProductOptions from "components/productOptions";
import ProductImage from "components/productImage";
import ProductAttributes from "components/productAttributes/ProductAttributes";
import { ProductStoragePlace } from "components/productStoragePlace";
import { TrackShowType } from "components/matomo";
import { tss } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import ProductNameSection from "./ProductNameSection";
import QuantitySection from "./QuantitySection";

interface Props {
  product: Product;
  refetch?: () => void;
  selectedCustomerProductList?: CustomerProductlist | null;
  isAccountListView?: boolean;
  trackType?: TrackShowType;
  editMode?: boolean;
}

const useStyles = tss
  .withParams<{ editMode?: boolean }>()
  .create(({ theme, editMode }) => ({
    row: {
      margin: theme.spacing(-0.5),
      "&:hover": {
        background: theme.palette.grey[50],
      },
    },
    boxLarge: {
      marginBottom: editMode ? "49px" : "49px",
    },
    boxSmall: {
      marginBottom: editMode ? "76px" : "40px",
    },
    attributeIcons: {
      [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(1),
        maxWidth: theme.spacing(9),
      },
      [theme.breakpoints.down(376)]: {
        marginLeft: theme.spacing(2),
        maxWidth: theme.spacing(6),
      },
    },
  }));

const ProductListItem: FC<Props> = ({
  product,
  refetch,
  selectedCustomerProductList,
  isAccountListView,
  trackType,
  editMode,
}) => {
  const { classes, cx } = useStyles({
    editMode,
  });
  const { t } = useTranslation();
  const { lg } = useBreakpoints();

  const showStorageInput =
    isAccountListView && selectedCustomerProductList?.type === "XSL";

  // TODO: Remove hack and implement better handling of the missing ProductOptions element in smaller viewport sizes when showStorageInput = false.
  // ==> This results in an empty Grid element at the left.
  // The interim-fix is to change the element sort direction in the Grid container.
  const useReverseRowDirection = () => !lg && !showStorageInput;

  const theme = useTheme();
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("lg"));

  const discountPrice =
    product.discountPrices && product.discountPrices.length > 0
      ? product.discountPrices[0]
      : null;

  return (
    <Grid
      padding={0}
      container
      className={cx(classes.row, {
        [classes.boxSmall]: breakpointDownMD,
        [classes.boxLarge]: !breakpointDownMD,
      })}
      id={`list_row_${product.partNumber}`}>
      <Hidden smDown>
        <Grid
          sm={3}
          md={showStorageInput ? 3 : 2}
          lg={showStorageInput ? 3 : 2}>
          <Grid
            container
            padding={0.5}
            direction={useReverseRowDirection() ? "row-reverse" : "row"}>
            <Grid
              sm={5}
              md={showStorageInput ? 6 : 4}
              lg={showStorageInput ? 6 : 3}>
              {showStorageInput && (
                <ProductStoragePlace product={product} editMode={editMode} />
              )}
              <Hidden lgDown>
                <ProductOptions
                  productId={product.uniqueID}
                  selectedCustomerProductListId={
                    selectedCustomerProductList?.id
                  }
                  customerProductlists={product.customerProductlists}
                  refetch={refetch}
                  inContract={product.inContract}
                />
              </Hidden>
            </Grid>

            <Grid
              container
              justifyContent={lg ? "center" : "flex-start"}
              sm={7}
              md={showStorageInput ? 6 : 8}
              lg={showStorageInput ? 6 : 9}>
              <ProductImage
                product={product}
                size="68"
                enableTooltip
                badgeText={!!discountPrice && t("cart.promotionCode")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <Grid
        container
        xs={12}
        sm={9}
        padding={0.5}
        md={showStorageInput ? 9 : 10}
        lg={showStorageInput ? 9 : 10}>
        <Grid xs={7} md={7} lg={4} marginBottom={1}>
          <ProductNameSection product={product} />
        </Grid>

        <Grid
          container
          justifyContent={lg ? "flex-start" : "flex-end"}
          xs={5}
          md={5}
          lg={3}>
          <Hidden lgDown>
            <StyledPriceTable
              prices={product.prices}
              matrix={product.matrix}
              discountPrices={product.discountPrices}
              fullWidth
              noPaddingTop
              paddingX
            />
          </Hidden>
          <Hidden lgUp>
            <StyledPriceTableSmall
              prices={product.prices}
              matrix={product.matrix}
              discountPrices={product.discountPrices}
            />
          </Hidden>
        </Grid>

        {showStorageInput && (
          <Hidden smUp>
            <Grid xs={2}>
              <ProductStoragePlace product={product} editMode={editMode} />
            </Grid>
          </Hidden>
        )}

        <Hidden lgUp>
          <Grid xs={1}>
            <ProductOptions
              productId={product.uniqueID}
              selectedCustomerProductListId={selectedCustomerProductList?.id}
              customerProductlists={product.customerProductlists || []}
              refetch={refetch}
              inContract={product.inContract}
            />
          </Grid>
        </Hidden>

        <Grid xs={3} lg={1} className={classes.attributeIcons}>
          <ProductAttributes
            attributes={product.attributes}
            inContract={product.inContract}
            smallVariant
            customerProductlists={product.customerProductlists || []}
          />
        </Grid>

        <QuantitySection
          product={product}
          isAccountListView={isAccountListView}
          selectedCustomerProductList={selectedCustomerProductList}
          trackType={trackType}
          editMode={editMode}
        />
      </Grid>
    </Grid>
  );
};

export default ProductListItem;
