import { useCallback } from "react";
import useFetchSyncData, {
  UseFetchSyncDataFunctionReturnType,
  UseFetchSyncDataFunctionProps,
} from "../common/useFetchSyncData";
import categoriesSyncTimestamp from "./categoriesSyncTimestamp";
import clearCategoriesData from "./clearCategoriesData";
import doSyncCategories from "./doSyncCategories";

const useSyncCategories: (
  props: Omit<UseFetchSyncDataFunctionProps, "fetchSyncData" | "cleanup">
) => UseFetchSyncDataFunctionReturnType = ({
  callback: externalCallback,
  paginated,
  ...rest
}) => {
  const callback = useCallback(
    (timestamp: unknown) => {
      if (paginated) {
        categoriesSyncTimestamp.put(timestamp);
      }
      if (externalCallback) {
        externalCallback(timestamp);
      }
    },
    [externalCallback, paginated]
  );

  return useFetchSyncData({
    fetchSyncData: doSyncCategories,
    cleanup: clearCategoriesData,
    callback,
    paginated,
    ...rest,
  });
};

export default useSyncCategories;
