/* eslint-disable indent */
// react
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// redux
import { BaseProduct } from "types/Product";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import { makeSelectOrderItemByPartNumberAndFreeGift } from "_redux/selectors/order";
import { makeSelectOrderItemErrorByPartNumber } from "_redux/selectors/error";
import * as orderActions from "_redux/actions/order";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import { TextFieldProps } from "@mui/material";
import { tss } from "tss-react/mui";
import {
  StyledButton,
  StyledFormInput,
  StyledProtectable,
} from "components/StyledUI";
// assets
import { ReactComponent as DeleteIcon } from "assets/icons/bin-1.svg";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import { ReactComponent as SubIcon } from "assets/icons/subtract.svg";
import { ReactComponent as AddToCart } from "assets/icons/shopping-cart-add.svg";
// foundation
import { RESET_ERROR_ACTION } from "_redux/actions/error";
import { ROUTES } from "constants/routes";
import { isMobile } from "react-device-detect";
import { TrackShowType, useMatomo } from "components/matomo";
import { useBreakpoints } from "_foundationExt/hooks/useBreakpoints";
import { userPersonalizationIdSelector } from "_redux/selectors/user";
import { useEFoodConfig } from "_foundationExt/hooks";
import roundQuantity from "tools/round-quantity";
import { useDebouncedCallback } from "use-debounce";
import AdditionalQuantityInformation from "./AdditionalQuantityInformation";
import PackageSelect from "./PackageSelect";

const MAX_DECIMAL_PLACES_FRONTEND = 3;
const MAX_DECIMAL_PLACES_BACKEND = 10;
const EMPTY_MATRIX_DATA = [];

interface QuantitySelectProps {
  product: BaseProduct;
  size?: TextFieldProps["size"];
  fullWidth?: boolean;
  isFreeGift?: boolean;
  type?: string;
  trackType?: TrackShowType;
  additionalQuantityInformationClass?: string;
  galleryView?: boolean;
  formattedPrice?: string;
  showCartHint?: boolean;
  addProductToCart?: boolean;
  multipleScanCounter?: number;
}

const useStyles = tss.create(({ theme }) => ({
  root: {
    justifyContent: "flex-end",
  },
  box: {
    display: "inline-block",
    verticalAlign: "middle",
  },
  container: {
    display: "flex",
    justifyContent: "flex-end",
  },
  marginRightPointFive: {
    marginRight: theme.spacing(0.5),
  },
  marginTopPointEight: {
    marginTop: theme.spacing(0.8),
  },
  productPrice: {
    float: "right",
    margin: "8px 8px 0 0",
    fontSize: "14px",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
}));

const toVkeQuantity = (
  quantity: number,
  currentConvFactor: number,
  vkeConvFactor: number
) => (quantity * currentConvFactor) / vkeConvFactor;

const fromVkeQuantity = (
  quantity: number,
  currentConvFactor: number,
  vkeConvFactor: number
) => (quantity / currentConvFactor) * vkeConvFactor;

const QuantitySelect: FC<QuantitySelectProps> = ({
  product,
  size = "small",
  fullWidth = false,
  isFreeGift = false,
  type = "",
  trackType,
  additionalQuantityInformationClass,
  galleryView = false,
  formattedPrice,
  showCartHint = false,
  addProductToCart,
  multipleScanCounter,
}) => {
  // hooks
  const { classes, cx } = useStyles();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { lg } = useBreakpoints();
  const matomo = useMatomo();
  const abortController = useRef<AbortController>();

  // selectors
  const selectOrderItemByPartNumberAndFreeGift = useMemo(
    () =>
      makeSelectOrderItemByPartNumberAndFreeGift(
        product.partNumber,
        isFreeGift
      ),
    [product.partNumber, isFreeGift]
  );
  const { packageUnitSelector } = useEFoodConfig();
  const orderitem = useAppSelector(selectOrderItemByPartNumberAndFreeGift);

  const selectOrderItemErrorByPartNumber = makeSelectOrderItemErrorByPartNumber(
    product.partNumber
  );
  const orderItemError = useAppSelector(selectOrderItemErrorByPartNumber);
  const persinalizationID = useAppSelector(userPersonalizationIdSelector);

  // vars
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedProduct = useMemo(() => product, [product.partNumber]);
  const { matrix = EMPTY_MATRIX_DATA } = memoizedProduct;
  const matrixSelectableElements = useMemo(
    () => matrix.filter((item) => parseInt(item.handlingCode, 10) > 0),
    [matrix]
  );
  const vke = useMemo(
    () =>
      matrixSelectableElements.find(
        (item) => parseInt(item.handlingCode, 10) === 1
      ),
    [matrixSelectableElements]
  );
  const vkeConvFactor = vke ? parseFloat(vke.convFactor) : 1;
  const orderItemId = orderitem?.orderitemId ?? "";
  const availability = orderitem?.availability;
  const orderItemQuantity = orderitem?.quantity ?? 0;
  const trackAdd2Basket = orderItemQuantity === 0;
  const roundedBackendQuantity = roundQuantity(
    orderItemQuantity,
    MAX_DECIMAL_PLACES_FRONTEND
  );
  const roundedQuantity = roundQuantity(
    orderItemQuantity,
    MAX_DECIMAL_PLACES_FRONTEND
  );
  const minAmount = product.minAmount / vkeConvFactor;
  const isValidQuantity = useCallback(
    (quantity: number) => minAmount <= quantity || quantity <= 0,
    [minAmount]
  );
  const invalidMinAmountHint = useMemo(
    () =>
      t("cart.minAmountHint", {
        quantity: minAmount.toLocaleString(i18n.resolvedLanguage),
        shortDesc: vke?.shortDesc,
      }),
    [minAmount, t, vke?.shortDesc, i18n.resolvedLanguage]
  );

  const [addToCart, setAddToCart] = useState(false);
  const [prevScanCounter, setPrevScanCounter] = useState(0);

  // state
  const [frontendQuantity, setFrontendQuantity] = useState(orderItemQuantity);
  const [inputQuantity, setInputQuantity] = useState<string | number>(
    roundedQuantity
  );
  const [showInputField, setShowInputField] = useState(orderItemQuantity > 0);
  const [currentConvFactor, setCurrentConvFactor] = useState(vkeConvFactor);
  const [minAmountHint, setMinAmountHint] = useState<string>();
  const [updateRequested, setUpdateRequested] = useState(false);

  // functions
  const concreteMutateServerQuantity = useCallback(
    (newQuantity: number, signal?: AbortSignal) => {
      if (newQuantity > 0 && trackAdd2Basket && matomo && trackType) {
        const event = {
          category: `${t(
            `prediction.event.type.${trackType}: ${t(
              "prediction.event.category"
            )}`
          )}`,
          action: `${t(`prediction.event.type.${trackType}`)}: ${t(
            "prediction.event.action.add"
          )}`,
          name: persinalizationID,
        };
        matomo.trackEvent(event);
      }

      // avoid rounding errors from the 6th decimal place
      const newRoundedQuantity = roundQuantity(
        newQuantity,
        MAX_DECIMAL_PLACES_BACKEND
      );

      setMinAmountHint(undefined);
      dispatch(RESET_ERROR_ACTION());
      const payload = {
        article: memoizedProduct,
        quantity: newRoundedQuantity,
        reloadCart: ROUTES.CART === window.location.pathname,
        trackType,
        signal,
      };
      dispatch(orderActions.MODIFY_ITEM_ACTION(payload));
    },
    [
      trackAdd2Basket,
      dispatch,
      matomo,
      persinalizationID,
      memoizedProduct,
      t,
      trackType,
    ]
  );

  const debouncedMutateServerQuantity = useDebouncedCallback(
    concreteMutateServerQuantity,
    500
  );

  const mutateServerQuantity = useCallback(
    (newQuantity: number) => {
      abortController.current?.abort();
      abortController.current = new AbortController();

      if (isValidQuantity(newQuantity)) {
        setUpdateRequested(true);
        setMinAmountHint(undefined);
        if (
          roundedBackendQuantity !==
          roundQuantity(newQuantity, MAX_DECIMAL_PLACES_BACKEND)
        ) {
          debouncedMutateServerQuantity(
            newQuantity,
            abortController.current.signal
          );
        }
      } else {
        setMinAmountHint(invalidMinAmountHint);
      }
    },
    [
      debouncedMutateServerQuantity,
      invalidMinAmountHint,
      isValidQuantity,
      roundedBackendQuantity,
    ]
  );

  // When the component goes to be unmounted, we will force update the server state.
  useEffect(
    () => () => {
      debouncedMutateServerQuantity.flush();
    },
    [debouncedMutateServerQuantity]
  );

  const addButton = useCallback(
    (force: boolean) => {
      const value = force
        ? frontendQuantity + 1
        : parseFloat(inputQuantity as unknown as string) + 1;

      setFrontendQuantity(value);
      setInputQuantity(roundQuantity(value, MAX_DECIMAL_PLACES_FRONTEND));
      setShowInputField(true);
      mutateServerQuantity(
        toVkeQuantity(value, currentConvFactor, vkeConvFactor)
      );
    },
    [
      frontendQuantity,
      inputQuantity,
      mutateServerQuantity,
      currentConvFactor,
      vkeConvFactor,
    ]
  );

  const removeButton = useCallback(
    (force: boolean) => {
      const inputQuantityAsNumber =
        typeof inputQuantity === "string"
          ? parseFloat(inputQuantity)
          : inputQuantity;
      const value = force ? frontendQuantity - 1 : inputQuantityAsNumber - 1;

      if (
        (force &&
          roundQuantity(frontendQuantity, MAX_DECIMAL_PLACES_FRONTEND) > 1) ||
        roundQuantity(inputQuantityAsNumber, MAX_DECIMAL_PLACES_FRONTEND) > 1
      ) {
        setFrontendQuantity(value);
        setInputQuantity(roundQuantity(value, MAX_DECIMAL_PLACES_FRONTEND));
        setShowInputField(true);
        mutateServerQuantity(
          toVkeQuantity(value, currentConvFactor, vkeConvFactor)
        );
      } else {
        document.getElementById(`anker_${product.partNumber}_${type}`)?.focus();
        setFrontendQuantity(0);
        setInputQuantity(0);
        setShowInputField(false);
        mutateServerQuantity(0);
      }
    },
    [
      currentConvFactor,
      frontendQuantity,
      inputQuantity,
      mutateServerQuantity,
      product.partNumber,
      type,
      vkeConvFactor,
    ]
  );
  const forceAdd = useCallback(() => {
    addButton(true);
  }, [addButton]);

  const matrixSelectChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = useCallback(
    (e) => {
      const { value } = e.target;
      const newConvFactor = parseFloat(value);

      const newQuantity =
        (frontendQuantity * currentConvFactor) / newConvFactor;

      setFrontendQuantity(newQuantity);
      setInputQuantity(roundQuantity(newQuantity, MAX_DECIMAL_PLACES_FRONTEND));
      setCurrentConvFactor(newConvFactor);
      if (
        roundQuantity(
          toVkeQuantity(frontendQuantity, currentConvFactor, vkeConvFactor),
          MAX_DECIMAL_PLACES_BACKEND
        ) !== roundQuantity(orderItemQuantity, MAX_DECIMAL_PLACES_BACKEND)
      ) {
        mutateServerQuantity(newQuantity);
      }
    },
    [
      currentConvFactor,
      frontendQuantity,
      mutateServerQuantity,
      orderItemQuantity,
      vkeConvFactor,
    ]
  );

  const inputQuantityChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = useCallback((e) => {
    const { value } = e.target;

    setInputQuantity(value);
  }, []);

  const quantityInputBlur = useCallback(() => {
    const newQuantity = parseFloat(inputQuantity as unknown as string);

    if (Number.isNaN(newQuantity)) {
      setFrontendQuantity(0);
      setInputQuantity(0);
      setShowInputField(false);
      mutateServerQuantity(0);
    } else if (
      roundQuantity(frontendQuantity, MAX_DECIMAL_PLACES_FRONTEND) !==
      newQuantity
    ) {
      setFrontendQuantity(newQuantity);
      setInputQuantity(newQuantity);
      setShowInputField(newQuantity > 0);
      mutateServerQuantity(
        toVkeQuantity(newQuantity, currentConvFactor, vkeConvFactor)
      );
    }
  }, [
    inputQuantity,
    frontendQuantity,
    mutateServerQuantity,
    currentConvFactor,
    vkeConvFactor,
  ]);

  // XXX: navigation, focus, etc. needs refactore
  const activeRow = useCallback(
    (a) => {
      const parent = document.getElementById(
        `${type}_row_${product.partNumber}`
      );
      if (parent && a) parent.style.backgroundColor = "#f2f3f1";
      else if (parent && !a) parent.removeAttribute("style");
    },
    [product.partNumber, type]
  );
  const navigation = useCallback(
    (e, input) => {
      const { keyCode } = e;
      if ([9, 13, 37, 38, 39, 40].includes(keyCode)) e.preventDefault();

      const active = input
        ? document.getElementById(`anker_${product.partNumber}_${type}`)
        : document.activeElement;
      const anker = document.getElementsByClassName(`anker_${type}`);
      const ankerList = Array.prototype.slice.call(anker);
      const ankerIndex = ankerList.indexOf(active);

      const focus = (i) => {
        if (
          (ankerIndex === 0 && i < 0) ||
          (ankerIndex === ankerList.length - 1 && i > 0 && type === "search")
        ) {
          const focusSearch = document.getElementById("header-search");
          focusSearch?.focus();
        } else if (ankerIndex >= 0) {
          const focusAnker =
            ankerList[ankerIndex + i] ||
            ankerList[i > 0 ? 0 : ankerList.length - 1];

          const focusInput = document.getElementById(
            focusAnker.id.replace("anker", "input")
          );

          if (focusInput) {
            focusInput.focus();
            if (type !== "minicart" && type !== "search")
              focusInput.scrollIntoView({
                block: "center",
                inline: "center",
                behavior: "smooth",
              });
          } else {
            focusAnker.focus();
            if (type !== "minicart" && type !== "search")
              focusAnker.scrollIntoView({
                block: "center",
                inline: "center",
                behavior: "smooth",
              });
          }
        }
      };

      switch (keyCode) {
        case 40: {
          // ArrowDown
          focus(1);
          break;
        }
        case 39: {
          // ArrowRight
          addButton(false);
          break;
        }
        case 38: {
          // ArrowUp
          focus(-1);
          break;
        }
        case 37: {
          // ArrowLeft
          removeButton(false);
          break;
        }
        case 13: {
          // Enter
          addButton(false);
          break;
        }
        case 9: {
          // Tab
          focus(1);
          break;
        }
        default: {
          // nothing
          break;
        }
      }
    },
    [addButton, product.partNumber, removeButton, type]
  );

  // rerender
  React.useEffect(() => {
    if (
      (addProductToCart && !addToCart) ||
      (multipleScanCounter &&
        multipleScanCounter > 0 &&
        multipleScanCounter !== prevScanCounter)
    ) {
      forceAdd();
      setAddToCart(true);
      if (multipleScanCounter) {
        setPrevScanCounter(multipleScanCounter);
      }
    }
  }, [
    addProductToCart,
    multipleScanCounter,
    addToCart,
    forceAdd,
    prevScanCounter,
  ]);

  React.useEffect(() => {
    if (updateRequested) {
      setUpdateRequested(false);
    } else if (orderitem?.quantity != null) {
      const newQuantity = fromVkeQuantity(
        orderitem.quantity,
        currentConvFactor,
        vkeConvFactor
      );

      if (
        roundQuantity(newQuantity, MAX_DECIMAL_PLACES_BACKEND) !==
        roundQuantity(
          toVkeQuantity(frontendQuantity, currentConvFactor, vkeConvFactor),
          MAX_DECIMAL_PLACES_BACKEND
        )
      ) {
        setFrontendQuantity(newQuantity);
        setInputQuantity(
          roundQuantity(newQuantity, MAX_DECIMAL_PLACES_FRONTEND)
        );
        setShowInputField(orderitem.quantity > 0);
        setMinAmountHint(undefined);
      }
    } else {
      // orderitem does not exist > clear quantity
      setFrontendQuantity(0);
      setInputQuantity(0);
      setShowInputField(false);
    }
    // only trigger when orderitem changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderitem?.quantity, currentConvFactor, vkeConvFactor]);

  return (
    <>
      <Grid container className={classes.root} data-min-amount={minAmount}>
        <Grid className={classes.container} xs>
          {((galleryView && lg) || !galleryView) && (
            <PackageSelect
              packageUnitSelector={packageUnitSelector}
              matrixSelectableElements={matrixSelectableElements}
              orderItemId={orderItemId}
              currentConvFactor={currentConvFactor}
              matrixSelectChange={matrixSelectChange}
              product={product}
              fullWidth={fullWidth}
              size={size}
              isFreeGift={isFreeGift}
            />
          )}
          {showInputField && (
            <>
              <div className={cx(classes.box, classes.marginRightPointFive)}>
                <StyledButton
                  color="secondary"
                  tooltip={t("iconTooltips.subtractIcon")}
                  startIcon={
                    roundQuantity(
                      frontendQuantity,
                      MAX_DECIMAL_PLACES_FRONTEND
                    ) <= 1 ? (
                      <DeleteIcon />
                    ) : (
                      <SubIcon />
                    )
                  }
                  size={size}
                  onClick={() => removeButton(true)}
                  disabled={isFreeGift}
                  onKeyDown={(e) => navigation(e, false)}
                  disableFocusListener
                />
              </div>
              <div className={cx(classes.box, classes.marginRightPointFive)}>
                <StyledFormInput
                  type="number"
                  size={size}
                  style={{ width: size === "small" ? "53px" : "71px" }}
                  value={inputQuantity}
                  onChange={inputQuantityChange}
                  onBlur={() => {
                    quantityInputBlur();
                    activeRow(false);
                  }}
                  InputProps={{ inputProps: { tabIndex: 10 } }}
                  disabled={isFreeGift}
                  onKeyDown={(e) => navigation(e, true)}
                  id={`input_${product.partNumber}_${type}`}
                  className={cx(`input_${type}`)}
                  onFocus={(e) => {
                    e.target.select();
                    activeRow(true);
                  }}
                  autoFocus={
                    !isMobile && inputQuantity === 1 && orderItemQuantity === 0
                  }
                />
              </div>
            </>
          )}
          <div className={classes.box}>
            <StyledButton
              tooltip={t("iconTooltips.addIcon")}
              startIcon={frontendQuantity === 0 ? <AddToCart /> : <AddIcon />}
              size={size}
              onClick={() => addButton(true)}
              disabled={isFreeGift}
              onKeyDown={(e) => navigation(e, false)}
              id={`anker_${product.partNumber}_${type}`}
              className={cx(`anker_${type}`)}
              onFocus={() => activeRow(true)}
              onBlur={() => {
                activeRow(false);
              }}
              disableFocusListener
            />
          </div>
        </Grid>
      </Grid>
      {galleryView && !lg && (
        <Grid container className={classes.alignItemsCenter}>
          <Grid xs>
            <div className={classes.productPrice}>
              <StyledProtectable permission="show.price">
                <b>{formattedPrice}</b>
              </StyledProtectable>
            </div>
          </Grid>
          <Grid>
            <PackageSelect
              packageUnitSelector={packageUnitSelector}
              matrixSelectableElements={matrixSelectableElements}
              orderItemId={orderItemId}
              currentConvFactor={currentConvFactor}
              matrixSelectChange={matrixSelectChange}
              product={product}
              fullWidth={fullWidth}
              size={size}
              isFreeGift={isFreeGift}
              smallGalleryView
            />
          </Grid>
        </Grid>
      )}
      <AdditionalQuantityInformation
        availability={availability}
        isFreeGift={isFreeGift}
        orderItemError={orderItemError}
        minAmountHint={minAmountHint}
        additionalQuantityInformationClass={additionalQuantityInformationClass}
        showCartHint={showCartHint}
        isBcScan={multipleScanCounter != null}
      />
    </>
  );
};

export default React.memo(QuantitySelect);
