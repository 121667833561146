import React, { FC } from "react";
import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  variant?: "determinate" | "indeterminate";
  minValue?: number;
  maxValue?: number;
}

const normalise = (value: number, minValue: number, maxValue: number) =>
  ((value - minValue) * 100) / (maxValue - minValue);

const LinearProgressWithLabel: FC<LinearProgressProps & Props> = ({
  value,
  color = "primary",
  variant = "indeterminate",
  minValue = 0,
  maxValue = 100,
  ...rest
}) => {
  const { t } = useTranslation();

  const normalizedValue = value
    ? normalise(value, minValue, maxValue)
    : undefined;

  if (!normalizedValue && variant === "determinate") {
    return null;
  }

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant={variant}
          color={color}
          value={normalizedValue}
          {...rest}
        />
      </Box>
      <Box minWidth={35}>
        {normalizedValue && variant === "determinate" ? (
          <Typography variant="body2" color="textSecondary">
            {t("offline.sync.progress.label", {
              percentage: Math.round(normalizedValue),
            })}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;
