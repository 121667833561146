import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Typography } from "@mui/material";
import { formatCurrency } from "tools/stringUtils";
import { SubBasket } from "types/Order";
import { DropShipConfig } from "_redux/reducers";

type Props = { dropShipConfig?: DropShipConfig } & Omit<
  SubBasket,
  "orderitems"
>;

const CartSubBasketPriceSummary: FC<Props> = ({
  articleType,
  dropShipConfig,
  total,
  totalAdjustment,
  totalProduct,
  totalShipping,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid xs={8} className="grid-item">
        <Box mr={1}>
          <Typography align="right" variant="body2">
            {`${t("cart.subtotal")}:`}
          </Typography>
        </Box>
      </Grid>
      <Grid xs={4} className="grid-item">
        <Typography align="right" variant="body2">
          {formatCurrency(totalProduct)}
        </Typography>
      </Grid>

      {totalAdjustment !== 0 && (
        <>
          <Grid xs={8} className="grid-item">
            <Box mr={1}>
              <Typography align="right" variant="body2">
                {`${t("cart.promotionCode")}:`}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={4} className="grid-item">
            <Typography align="right" variant="body2">
              {formatCurrency(totalAdjustment)}
            </Typography>
          </Grid>
        </>
      )}

      <Grid xs={8} className="grid-item">
        <Box mr={1}>
          <Typography align="right" variant="body2">
            {`${t("cart.shippingCosts")}:`}
          </Typography>
        </Box>
      </Grid>
      <Grid xs={4} className="grid-item">
        <Typography align="right" variant="body2">
          {formatCurrency(totalShipping)}
        </Typography>
      </Grid>

      <Grid xs={8} className="grid-item">
        <Box mr={1}>
          <Typography align="right" variant="body2">
            {`${t("cart.sum")} ${
              dropShipConfig?.title?.trim() ||
              t(`cart.articleType.${articleType.toLowerCase()}`)
            }:`}
          </Typography>
        </Box>
      </Grid>
      <Grid xs={4} className="grid-item">
        <Typography align="right" variant="body2">
          <b>{formatCurrency(total)}</b>
        </Typography>
      </Grid>
    </>
  );
};

export default React.memo(CartSubBasketPriceSummary);
