import type { MarketingSpotData } from "types/Marketing";

const getCatalogEntryIdsFromMarketingSpotData = (
  marketingSpotData?: MarketingSpotData
): string[] => {
  const result: string[] = [];
  if (marketingSpotData?.baseMarketingSpotActivityData) {
    marketingSpotData.baseMarketingSpotActivityData
      .filter(
        (e) =>
          e.baseMarketingSpotDataType === "CatalogEntry" ||
          e.baseMarketingSpotDataType === "CatalogEntryId"
      )
      .forEach((e) => {
        const productId = e.baseMarketingSpotActivityID;
        if (productId) {
          result.push(productId);
        }
      });
  }
  return result;
};

export default getCatalogEntryIdsFromMarketingSpotData;
