/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { StyledButton, StyledPopover } from "components/StyledUI";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow-down-1.svg";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  button: {
    justifyContent: "space-between",
    backgroundColor: theme.palette.grey[50],
    textTransform: "none",
    fontWeight: 400,
    lineHeight: "20px",
    fontSize: 14,
    border: `2px solid ${theme.palette.grey[50]}`,
    padding: "6px !important",
    color: theme.palette.text.primary,
    letterSpacing: ".5px",
    "& svg": {
      color: theme.palette.grey[800],
      height: 16,
      width: 16,
      pointerEvents: "none",
    },
    "& .MuiButton-endIcon": {
      marginLeft: 8,
      marginRight: 0,
    },
    "&:hover": {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.text.primary,
      "& svg": {
        color: theme.palette.grey[800],
      },
    },
    "&:focus": {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      "& svg": {
        color: theme.palette.grey[800],
        pointerEvents: "none",
      },
    },
  },
  open: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    "& svg": {
      transform: "rotateZ(180deg)",
      pointerEvents: "none",
    },
  },
  popover: {
    marginTop: "0px !important",
    "& .MuiPopover-paper": {
      maxHeight: "228px",
    },
    "& .MuiFormControlLabel-root": {
      display: "flex",
      padding: 4,
      "&:hover": {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[50],
      },
    },
    "& .MuiTextField-root": {
      marginBottom: 8,
    },
  },
}));

const StyledFilterDropdown = React.forwardRef((props: any, ref: any) => {
  const { classes, cx } = useStyles();

  const {
    children,
    buttonLabel,
    onEnter,
    style,
    fullWidth,
    disabled,
    ...rest
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-dropdown" : undefined;

  return (
    <>
      <StyledButton
        className={cx(classes.button, { [classes.open]: open })}
        aria-describedby={id}
        variant="outlined"
        size={props.size}
        fullWidth={fullWidth}
        style={style}
        endIcon={<ArrowIcon />}
        onClick={handleClick}
        disabled={disabled}>
        {buttonLabel}
      </StyledButton>

      <StyledPopover
        className={classes.popover}
        fullwidth={fullWidth}
        id={id}
        open={open}
        anchorEl={anchorEl}
        ref={ref}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        TransitionProps={{
          onEnter,
        }}
        {...rest}>
        {children}
      </StyledPopover>
    </>
  );
});

export { StyledFilterDropdown };
