import React, { FC } from "react";
import { useAppSelector } from "_redux/hooks";

import { useAbortControllers } from "_foundationExt/hooks";
import { wcTokenSelector } from "_redux/selectors/user";
import { useSite } from "_foundation/hooks/useSite";
import eSpotService from "_foundation/apis/transaction/eSpot.service";

import { GridSize } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Breakpoint } from "@mui/material/styles";

import { createFindByNameParameters } from "components/widgets/espot/utils/useFetchESpotDataByFindByName";
import { isAuthError, isCanceledError } from "_foundationExt/axios/axiosConfig";
import EmsGridItem, { mapMarketingSpotDataToEmsGridItems } from "./EmsGridItem";
import EmsContentCard from "./EmsContentCard";

interface GridCardRecommendationsProps {
  eSpotName: string;
}

const GridCardRecommendations: FC<GridCardRecommendationsProps> = ({
  eSpotName,
}) => {
  const { currentSite } = useSite();
  const wcToken = useAppSelector(wcTokenSelector);
  const [contents, setContents] = React.useState<EmsGridItem[]>([]);
  const abortControllers = useAbortControllers();

  const getGridItemSizes = (
    content: EmsGridItem
  ): Partial<Record<Breakpoint, boolean | GridSize>> => {
    if (!content) {
      return {
        xs: 12,
        sm: 6,
        md: 4,
      };
    }
    switch (content.gridItemType) {
      case "one-third":
        return {
          xs: 12,
          sm: 6,
          md: 4,
        };

      case "one-half":
        return {
          xs: 12,
          sm: 6,
        };

      case "full":
        return {
          xs: 12,
        };

      default:
        throw new Error("Unknown EmsGridItemType");
    }
  };

  React.useEffect(() => {
    (async () => {
      if (currentSite?.storeID && currentSite?.catalogID) {
        const { signal } = abortControllers.create();
        const parameters = createFindByNameParameters({
          emsName: eSpotName,
          storeId: currentSite.storeID,
          catalogId: currentSite.catalogID,
          signal,
        });
        try {
          const response = await eSpotService.findByName(parameters);

          const eSpotRoot = response.data?.MarketingSpotData?.[0];
          if (eSpotRoot) {
            const items = mapMarketingSpotDataToEmsGridItems(eSpotRoot);
            setContents(items);
          }
        } catch (error) {
          if (!(isCanceledError(error) || isAuthError(error))) {
            throw error;
          }
        }
      }
    })();
  }, [
    eSpotName,
    currentSite?.storeID,
    currentSite?.catalogID,
    wcToken,
    abortControllers,
  ]);

  return (
    <>
      {contents &&
        contents.map((content: EmsGridItem) => (
          <Grid key={content.contentId} {...getGridItemSizes(content)}>
            <EmsContentCard emsContent={content} />
          </Grid>
        ))}
    </>
  );
};

export default GridCardRecommendations;
