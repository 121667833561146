import React, { FC } from "react";

import { StyledFormSelect, StyledFormSelectItem } from "components/StyledUI";
import type { BaseProduct, Matrix } from "types/Product";
import { Theme, type TextFieldProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const getMarginRightForRoot = (
  theme: Theme,
  smallGalleryView: boolean,
  fullWidth: boolean
) => {
  if (fullWidth) {
    return "auto";
  }
  if (!smallGalleryView) {
    return theme.spacing(0.5);
  }

  return undefined;
};

const Root = styled("div", {
  name: "PackageSelect",
  slot: "Root",
  shouldForwardProp: (prop) =>
    prop !== "smallGalleryView" && prop !== "fullWidth",
})<{ smallGalleryView: boolean; fullWidth: boolean }>(
  ({ theme, smallGalleryView, fullWidth }) => ({
    display: "inline-block",
    verticalAlign: "middle",
    marginTop: smallGalleryView ? theme.spacing(0.8) : undefined,
    marginRight: getMarginRightForRoot(theme, smallGalleryView, fullWidth),
  })
);

const Select = styled(StyledFormSelect, {
  name: "PackageSelect",
  slot: "Select",
})<{ size: Required<TextFieldProps["size"]> }>(({ size }) => ({
  "& .MuiInputBase-root": {
    width: size === "small" ? "78px" : "131px",
    textAlign: "left",
    "& .longDesc": {
      display: "none !important",
    },
    "& .shortDesc": {
      display: "block !important",
    },
  },
  "& .Mui-disabled": {
    "& svg": {
      display: "none",
      pointerEvents: "none",
    },
  },
}));

const SelectItem = styled(StyledFormSelectItem, {
  name: "PackageSelect",
  slot: "SelectItem",
})(() => ({
  "& .longDesc": {
    display: "block",
  },
  "& .shortDesc": {
    display: "none",
  },
}));

type PackageSelectProps = {
  packageUnitSelector: boolean;
  matrixSelectableElements: Matrix[];
  orderItemId: string | null;
  currentConvFactor: number;
  matrixSelectChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  product: BaseProduct;
  fullWidth: boolean;
  size: Required<TextFieldProps["size"]>;
  isFreeGift: boolean;
  smallGalleryView?: boolean;
};

const PackageSelect: FC<PackageSelectProps> = ({
  packageUnitSelector,
  matrixSelectableElements,
  orderItemId,
  currentConvFactor,
  matrixSelectChange,
  product,
  fullWidth,
  size,
  isFreeGift,
  smallGalleryView = false,
}) => {
  if (!matrixSelectableElements?.length) {
    return null;
  }

  return (
    <Root smallGalleryView={smallGalleryView} fullWidth={fullWidth}>
      <Select
        size={size}
        disabled={
          !packageUnitSelector ||
          matrixSelectableElements.length <= 1 ||
          isFreeGift
        }
        value={currentConvFactor}
        onChange={matrixSelectChange}>
        {matrixSelectableElements.map((item) => (
          <SelectItem
            key={`${product?.partNumber || "unknown"}_${orderItemId}_${
              item.shortDesc
            }`}
            value={parseFloat(item.convFactor)}>
            <span className="shortDesc">{item.shortDesc}</span>
            <span className="longDesc">{item.longDesc}</span>
          </SelectItem>
        ))}
      </Select>
    </Root>
  );
};

export default PackageSelect;
