import { useCallback } from "react";
import isInstanaActive from "tools/isInstanaActive";
import useFetchSyncData, {
  UseFetchSyncDataFunctionProps,
  UseFetchSyncDataFunctionReturnType,
} from "../common/useFetchSyncData";
import clearPricesData from "./clearPrices";
import doSyncPrices from "./doSyncPrices";
import pricesSyncTimestamp from "./pricesSyncTimestamp";

const useSyncPrices: (
  props: Omit<UseFetchSyncDataFunctionProps, "fetchSyncData" | "cleanup">
) => UseFetchSyncDataFunctionReturnType = ({
  callback: externalCallback,
  paginated,
  ...rest
}) => {
  const callback = useCallback(
    (timestamp: unknown) => {
      if (paginated) {
        pricesSyncTimestamp.put(timestamp);

        if (isInstanaActive()) {
          ineum("reportEvent", "sync prices setTimestamp", {
            timestamp: Date.now(),
            meta: {
              paginated: true,
              pricesSyncTimestamp: timestamp as string | number | boolean,
            },
          });
        }
      }
      if (externalCallback) {
        externalCallback(timestamp);
      }
    },
    [externalCallback, paginated]
  );

  return useFetchSyncData({
    fetchSyncData: doSyncPrices,
    cleanup: clearPricesData,
    callback,
    paginated,
    ...rest,
  });
};

export default useSyncPrices;
