import React, { FC, useState } from "react";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { selectSortedCustomerProductLists } from "_redux/selectors/user";
import * as userAction from "_redux/actions/user";
import {
  StyledButton,
  StyledDialog,
  StyledDialogTitle,
  StyledDialogContent,
  StyledFormInput,
  StyledNotification,
} from "components/StyledUI";
import customerProductlistService from "_foundationExt/apis/transaction/customerProductlist.service";
import {
  CustomerProductlist,
  CustomerProductlistCreateUpdatePayload,
} from "types/CustomerProductlist";
import { useSite } from "_foundationExt/hooks/useSite";

const isCustomerProductListNameUnique = (
  listname: string,
  customerProducts: CustomerProductlist[]
) => {
  // Does already a productlist exist with the same listname
  const equalProductList = customerProducts.find(
    (customerProduct) => customerProduct.name === listname
  );

  if (equalProductList) {
    return false;
  }

  return true;
};

const validateInput = (
  listname: string,
  customerProducts: CustomerProductlist[],
  t: TFunction
) => {
  if (listname.length < 3) {
    return t("myAccount.listManagement.modalMinListnameInputError");
  }

  if (listname.length > 30) {
    return t("myAccount.listManagement.modalMaxListnameInputError");
  }

  if (!isCustomerProductListNameUnique(listname, customerProducts)) {
    return t("myAccount.listManagement.modalEqualListnameError");
  }

  return null;
};

interface CreateListDialogProps {
  closeDialog: () => void;
  closeShowDialog: () => void;
  productId: string;
  setInList: React.Dispatch<React.SetStateAction<boolean>>;
  showDialog: boolean;
}

const CreateUpdateDialog: FC<CreateListDialogProps> = ({
  closeDialog,
  closeShowDialog,
  productId,
  setInList,
  showDialog,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const customerProducts = useAppSelector(selectSortedCustomerProductLists);
  const { currentSite } = useSite();

  const [listname, setListname] = useState("");
  const [description, setDescription] = useState("");
  const listtype = "XSL";
  const [error, setError] = useState("");

  const handleClose = () => {
    setError("");
    setListname("");
    setDescription("");

    closeDialog();
  };

  const saveInputs = async () => {
    const inputError = validateInput(listname, customerProducts, t);

    if (inputError) {
      setError(inputError);
      return;
    }

    const parameters: CustomerProductlistCreateUpdatePayload = {
      body: {
        name: listname,
        description,
        type: listtype,
      },
    };

    try {
      const createdProductList = (
        await customerProductlistService.createList(parameters)
      ).data;

      await customerProductlistService.addProduct({
        storeId: currentSite ? currentSite.storeID : "",
        customerProductListId: createdProductList.productListId,
        body: {
          productId,
        },
      });

      // Entrys for customerproductlists comes from searchserver, which is not fetched again until refresh so set it to true
      setInList(true);

      dispatch(userAction.RE_FETCH_CUSTOMER_PRODUCTLISTS_ACTION());

      closeShowDialog();
      handleClose();
    } catch (err) {
      setError(
        t("myAccount.listManagement.modalGeneralError") ?? "general error"
      );
    }
  };

  return (
    <StyledDialog open={showDialog} onClose={handleClose}>
      <StyledDialogTitle id="dialog-title" onClose={handleClose}>
        {t("myAccount.listManagement.modalCreateList")}
      </StyledDialogTitle>

      <StyledDialogContent>
        {error && (
          <Box my={2}>
            <StyledNotification severity="error">{error}</StyledNotification>
          </Box>
        )}

        <Box>{t("myAccount.listManagement.modalListnameLabel")}</Box>

        <StyledFormInput
          value={listname}
          onChange={(event) => setListname(event.target.value)}
          placeholder={t("myAccount.listManagement.modalListnamePlaceholder")}
          autoFocus
          fullWidth
        />

        <Box mt={2}>{t("myAccount.listManagement.modalDescriptionLabel")}</Box>

        <StyledFormInput
          rows="3"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          InputProps={{
            multiline: true,
          }}
          placeholder={
            t("myAccount.listManagement.modalDescriptionPlaceholder") ??
            undefined
          }
          fullWidth
        />

        <Box mt={2}>
          <StyledButton fullWidth onClick={saveInputs}>
            {t("myAccount.listManagement.modalAcceptButton")}
          </StyledButton>
        </Box>

        <Box mt={2}>
          <StyledButton fullWidth variant="outlined" onClick={handleClose}>
            {t("myAccount.listManagement.modalCancelButton")}
          </StyledButton>
        </Box>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default React.memo(CreateUpdateDialog);
