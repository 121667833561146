/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, Method, AxiosPromise } from "axios";
import { executeRequest } from "_foundation/axios/axiosConfig";
import { getSite } from "_foundation/hooks/useSite";
import organizationService from "_foundation/apis/transaction/organization.service";
import { OrganizationApi } from "@efood-commerce-sdk/typescript-axios-transaction-efood";
import { site as constantsSite } from "_foundationExt/constants/site";
import ensureObject from "tools/ensureObject";

const organizationApiInstance = new OrganizationApi(
  undefined,
  constantsSite.transactionContext
);

const organizationServiceExt = {
  /**
   * loads all organizations for user
   * @param parameters {storeId: string}
   */
  selfEntiledOrgs(parameters: any): AxiosPromise<any> {
    const site = getSite();
    const { storeId = site?.storeID, ...options } = ensureObject(parameters);

    return organizationApiInstance.getEntitledOrganizations(storeId, options);
  },

  /**
   * switch organization
   * @param parameters {storeId: string}
   * @param url
   */
  switchOrganizationTo(parameters: any, url?: string): AxiosPromise<any> {
    const param = parameters;

    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.transactionContext || "";
    }

    const domain = url || siteContext;
    const path = "/store/{storeId}/switchOrganization/switchTo";
    let requestUrl = domain + path;
    const method: Method = "PUT";
    const body = {
      activeOrgId: null,
      changeSelectedUserForZek: "",
    };

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };

    if (param.storeId === undefined && site !== null) {
      param.storeId = site.storeID;
    }
    requestUrl = requestUrl.replace("{storeId}", param.storeId);

    if (param.storeId === undefined) {
      throw new Error(`Request '${path}' missing required parameter storeId`);
    }

    if (param.organizationId === undefined) {
      throw new Error(
        `Request '${path}' missing required parameter organization`
      );
    }

    if (param.organizationId !== undefined) {
      body.activeOrgId = param.organizationId;
    }

    if (param.changeSelectedUserForZek !== undefined) {
      body.changeSelectedUserForZek = param.changeSelectedUserForZek;
    }
    const requestOptions: AxiosRequestConfig = {
      method,
      headers: header,
      url: requestUrl,
      data: body,
      ...parameters,
    };

    return executeRequest(requestOptions);
  },
};

export default { ...organizationService, ...organizationServiceExt };
