import React, {
  FC,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";

import { CatalogGroupView } from "_redux/reducers/reducerStateInterface";
import { useSite } from "_foundation/hooks/useSite";
import { useAppSelector } from "_redux/hooks";
import { categoryTopSelector } from "_redux/selectors/catalog";
import SideBarAccordions from "./SideBarAccordions";

interface SideBarCategoriesProps {
  setSideBarOpenStatus: Dispatch<SetStateAction<boolean>>;
}

const SideBarCategories: FC<SideBarCategoriesProps> = ({
  setSideBarOpenStatus,
}) => {
  const categoryViewTop = useAppSelector(categoryTopSelector);
  const [categories, setCategories] = useState<Array<CatalogGroupView>>();
  const { currentSite } = useSite();

  const memorizedCloseSideBar = useCallback(() => {
    setSideBarOpenStatus(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentSite !== null && categoryViewTop) {
      setCategories(categoryViewTop);
    }
  }, [currentSite, categoryViewTop]);

  if (!categories || !currentSite) {
    return null;
  }

  return (
    <SideBarAccordions
      categories={categories}
      memorizedCloseSideBar={memorizedCloseSideBar}
      navigationLevels={currentSite.storeCfg.efood.navigationLevels}
    />
  );
};

export default SideBarCategories;
