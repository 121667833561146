export const PROFILE_NAME = {
  FETCH_PRODUCT_DETAIL_BY_PARTNUMBER:
    "EFood_findProductBySBECPartnumber_Summary_WithNoEntitlementCheck",
  FIND_PRODUCT_LIST_PRODUCTS_BY_SEARCH_TERM:
    "EFood_findProductListProductsBySearchTerm",
  FIND_PRODUCTS_BY_PARTNUMBERS: "EFood_findProductsByPartNumbers",
  FIND_PRODUCTS_BY_SEARCH_TERM: "EFood_findProductsBySearchTerm",
  FIND_TOP_RANKED_PRODUCTS_BY_CATEGORY: "EFood_findTopRankedProductsByCategory",
  FIND_WITHOUT_TOP_RANKED_PRODUCTS_BY_CATEGORY:
    "EFood_findWithoutTopRankedProductsByCategory",
} as const;
