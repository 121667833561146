// react
import React, { FC, useState } from "react";

import { useTranslation } from "react-i18next";

import { ROUTES } from "constants/routes";

// redux
import { useAppSelector } from "_redux/hooks";
import {
  numItemsSelector,
  selectAllOrderItemsSorted,
} from "_redux/selectors/order";
import useOrderPriceSelector from "_redux/hooks/useOrderPriceSelector";

// mui
import { useMediaQuery, Typography, Box, Hidden } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";

import {
  UnstyledLink,
  StyledProtectable,
  StyledBadge,
  StyledButton,
  StyledIcon,
  StyledPriceTableSmall,
  StyledLinkButton,
  StyledPopover,
} from "components/StyledUI";
// assets
import { ReactComponent as ShoppingCartIcon } from "assets/icons/shopping-cart.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
// components
import { formatCurrency, truncate } from "tools/stringUtils";
import ProductImage from "components/productImage";
import Quantity from "components/quantity/Quantity";
import { MINICART_ORDERITEMS_LIMIT } from "configs/order";
import { useNavigate } from "react-router-dom";
import { tss } from "tss-react/mui";
import TriggerCartSyncWatcher from "pwa/offline/cart/TriggerCartSyncWatcher";

const useStyles = tss.create(({ theme }) => ({
  marginBottomOne: {
    marginBottom: theme.spacing(1),
  },
  marginBottomThree: {
    marginBottom: theme.spacing(3),
  },
  marginRightTwo: {
    marginRight: theme.spacing(2),
  },
  marginTopOne: {
    marginTop: theme.spacing(1),
  },
  textAlignRight: {
    textAlign: "right",
  },
  childVerticalAlignTop: {
    "& > *": {
      verticalAlign: "top",
    },
  },
  button: {
    backgroundColor: "transparent !important",
    padding: "4px 8px",
    fontSize: 14,
    textTransform: "none",
    letterSpacing: 0.5,

    [theme.breakpoints.up("sm")]: {
      padding: "4px 12px",
    },
  },
  headerPopover: {
    display: "flex",
    justifyContent: "space-between",
  },
  hover: {
    color: theme.palette.background.default,
    "&:focus": {
      color: theme.palette.primary.dark,
    },
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
  price: {
    display: "inline-block",
    paddingLeft: "16px",
    verticalAlign: "top",
    textAlign: "left",
    lineHeight: "20px",
    paddingTop: "3px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  icon: {
    width: "24px",
    height: "24px",
    transform: "scale(-1,1)",
  },
  content: {
    maxWidth: "370px",
    maxHeight: "600px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "310px",
    },
  },
  overflow: {
    overflow: "auto",
    maxHeight: "360px",

    scrollbarColor: `${theme.palette.grey[300]} ${theme.palette.background.paper}`,
    scrollbarWidth: "thin",

    "&::-webkit-scrollbar": {
      width: theme.spacing(0.5),
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[300],
      borderRadius: "2px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: theme.palette.grey[700],
    },
  },
  thumbnail: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    width: "68px",
    height: "68px",
    position: "relative",
    marginRight: theme.spacing(2),
  },
  empty: {
    color: theme.palette.grey[300],
    width: "32px",
    height: "32px",
    position: "relative",
    top: "17px",
    left: "17px",
  },
  boxShadow: {
    // boxShadow: `0px -10px 10px -10px ${theme.palette.grey[300]}`,
    background:
      "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 95%, rgba(0,0,0,0.05) 100%)",
  },
  innerTypography: {
    padding: theme.spacing(0),
  },
  gray: {
    color: theme.palette.grey[700],
  },
}));

const MiniCart: FC = () => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const breakpointUpMd = useMediaQuery(theme.breakpoints.up("md"));

  const navigate = useNavigate();
  const { t } = useTranslation();

  const numItems = useAppSelector(numItemsSelector);
  const orderItems = useAppSelector(selectAllOrderItemsSorted);
  const order = useOrderPriceSelector();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl == null ? event.currentTarget : null);
  };

  const reloadCart = () => {
    setAnchorEl(null);
    navigate(ROUTES.CART);
  };

  return (
    <>
      <StyledButton
        className={classes.button}
        aria-describedby="minicart-flyout"
        onClick={handlePopoverToggle}>
        <div className={classes.hover}>
          <StyledIcon
            size="40"
            style={{ display: "inline-block" }}
            tooltip={t("iconTooltips.cartIcon")}>
            <StyledBadge badgeContent={numItems} color="secondary" max={999}>
              <ShoppingCartIcon style={{ transform: "scale(-1,1)" }} />
            </StyledBadge>
          </StyledIcon>
          <StyledProtectable permission="show.price">
            <div className={classes.price}>
              <span style={{ fontWeight: "lighter" }}>{t("header.total")}</span>
              <br />
              {formatCurrency(order.total)}
            </div>
          </StyledProtectable>
        </div>
      </StyledButton>

      <StyledPopover
        id="minicart-flyout"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        transitionDuration={0}
        padding="24px"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        disableAutoFocus
        disableRestoreFocus>
        <TriggerCartSyncWatcher ignoreOnlineStateChange />
        {numItems && orderItems.length ? (
          <Grid container className={classes.content}>
            <Grid xs={12}>
              <Box className={classes.headerPopover} mb={2}>
                <Typography
                  variant={breakpointUpMd ? "h2" : "h3"}
                  component="p">
                  {t("cart.mycart")}
                </Typography>
                <StyledButton
                  className="close"
                  onClick={handlePopoverClose}
                  startIcon={<CloseIcon />}
                  size="small"
                  color="inherit"
                />
              </Box>
            </Grid>
            <div className={classes.marginBottomOne}>
              <div className={cx(classes.boxShadow, classes.overflow)}>
                <div className={classes.marginRightTwo}>
                  <Grid container>
                    {orderItems
                      .filter(Boolean)
                      .slice(
                        0,
                        Math.min(orderItems.length, MINICART_ORDERITEMS_LIMIT)
                      )
                      .map(
                        (orderItem) =>
                          orderItem.article && (
                            <Grid
                              container
                              key={`OrderItem_${orderItem.orderitemId}`}>
                              <Grid xs={9} sm={10}>
                                <UnstyledLink
                                  onClick={handlePopoverClose}
                                  to={`${ROUTES.PRODUCT_DETAIL}/${orderItem.article.eFoodPartnumber}`}>
                                  <Grid
                                    container
                                    wrap="nowrap"
                                    spacing={breakpointUpMd ? 6 : 3}>
                                    <Grid>
                                      <Hidden mdDown>
                                        <ProductImage
                                          product={orderItem.article}
                                          size="68"
                                          isLink={false}
                                        />
                                      </Hidden>
                                    </Grid>
                                    <Grid>
                                      <Typography variant="body2">
                                        <b>
                                          {truncate(orderItem.article.name, 50)}
                                        </b>
                                      </Typography>
                                      <Typography
                                        variant="subtitle1"
                                        className={classes.gray}>
                                        {t("product.partnumber")}{" "}
                                        {orderItem.article.eFoodPartnumber}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </UnstyledLink>
                              </Grid>
                              <Grid xs={3} sm={2}>
                                <StyledPriceTableSmall
                                  shortDesc
                                  matrix={orderItem.article.matrix}
                                  prices={orderItem.article.prices}
                                />
                              </Grid>
                              <Grid xs={12}>
                                <div className={classes.marginBottomOne}>
                                  <Quantity data={orderItem} type="minicart" />
                                </div>
                              </Grid>
                            </Grid>
                          )
                      )}
                  </Grid>
                </div>
              </div>
            </div>
            <Grid container className={classes.content}>
              {orderItems?.length > MINICART_ORDERITEMS_LIMIT && (
                <>
                  <Grid
                    xs={6}
                    className={cx(
                      classes.marginBottomOne,
                      classes.childVerticalAlignTop
                    )}>
                    <Typography variant="subtitle1" component="span">
                      {t("cart.showAllHint", {
                        current: MINICART_ORDERITEMS_LIMIT,
                        total: orderItems.length,
                      })}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={6}
                    className={cx(
                      classes.marginBottomOne,
                      classes.textAlignRight,
                      classes.childVerticalAlignTop
                    )}>
                    <StyledLinkButton onClick={reloadCart}>
                      {t("cart.showAll")}
                    </StyledLinkButton>
                  </Grid>
                </>
              )}
              <StyledProtectable permission="show.price">
                <Grid xs={6}>
                  <Typography variant="body2">{t("cart.products")}:</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography variant="body2" align="right">
                    {formatCurrency(order.totalProduct)}
                  </Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography variant="body2">{t("cart.shipping")}:</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography variant="body2" align="right">
                    {formatCurrency(order.totalShipping)}
                  </Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography variant="body2">
                    <b>{t("cart.total")}:</b>
                  </Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography variant="body2" align="right">
                    <b>{formatCurrency(order.total)}</b>
                  </Typography>
                </Grid>
              </StyledProtectable>
              <Grid xs={12}>
                <div className={classes.marginTopOne}>
                  <StyledButton fullWidth color="primary" onClick={reloadCart}>
                    {t("cart.gotocart")}
                  </StyledButton>
                </div>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid xs={12}>
              <Box mr={6} mt={0.7}>
                <Typography variant="h2">{t("cart.empty")}</Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </StyledPopover>
    </>
  );
};

export default MiniCart;
