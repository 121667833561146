import React, { FC } from "react";

import FormLabel, { FormLabelProps } from "@mui/material/FormLabel";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  root: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    display: "block",
    lineHeight: "24px",
  },
}));

const StyledFormLabel: FC<FormLabelProps> = (props) => {
  const { classes } = useStyles();

  return <FormLabel className={classes.root} {...props} />;
};

export { StyledFormLabel };
