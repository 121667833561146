import orderService from "_foundation/apis/transaction/order.service";
import { OrderApi } from "@efood-commerce-sdk/typescript-axios-transaction-efood";
import { site as constantsSite } from "_foundationExt/constants/site";
import ensureObject from "tools/ensureObject";
import { getSite } from "../../hooks/useSite";

const orderApiInstance = new OrderApi(
  undefined,
  constantsSite.transactionContext
);

const orderServiceExt = {
  /**
   * load order confirmation data
   * @param parameters {storeId?: string, orderId: string}
   */
  getOrderConfirmation(parameters: {
    storeId?: string;
    orderId: string;
    [x: string]: unknown;
  }) {
    const site = getSite();
    const {
      storeId = site?.storeID,
      orderId,
      ...options
    } = ensureObject(parameters);

    return orderApiInstance.findByOrderId(storeId, orderId, options);
  },
};
export default { ...orderService, ...orderServiceExt };
