import React, { FC, PropsWithChildren } from "react";

type HiddenProps = { condition: boolean };

const Hidden: FC<PropsWithChildren<HiddenProps>> = ({
  children,
  condition,
}) => {
  if (condition) {
    return null;
  }
  return <>{children}</>;
};

export default Hidden;
