/* eslint-disable indent */
import React, { FC } from "react";
import { usePredictivBasket } from "components/widgets/espot/utils/usePredictivBasket";
import { ESpotData } from "types/Marketing";
import ProductSlider from "components/widgets/espot/widgets/ProductSlider";
import { TrackShowType } from "components/matomo";
import { useFetchProducts } from "./prediction.hooks";

interface Params {
  data: ESpotData;
  cluster?: boolean;
  trackType?: TrackShowType;
  sidebar?: boolean;
  categoryId?: string;
}

const PredictionSlider: FC<Params> = ({
  data,
  cluster,
  trackType,
  sidebar,
  categoryId,
}) => {
  const [basketPredictionIds, loading] = usePredictivBasket(cluster || false);
  const { predictionProducts, error, predictionProductsTotal } =
    useFetchProducts(basketPredictionIds);

  let sortedPredictionProducts =
    basketPredictionIds && predictionProducts
      ? predictionProducts.sort(
          (a, b) =>
            basketPredictionIds.indexOf(a.eFoodPartnumber) -
            basketPredictionIds.indexOf(b.eFoodPartnumber)
        )
      : null;

  if (loading || error) return null;

  if (sortedPredictionProducts && categoryId) {
    sortedPredictionProducts = sortedPredictionProducts.sort((a, b) => {
      if (a.parentCatgroupsPath && b.parentCatgroupsPath) {
        if (a.parentCatgroupsPath.includes(categoryId)) return -1;
        return 1;
      }
      return 0;
    });
  }

  return (
    <>
      {data &&
        predictionProducts &&
        predictionProductsTotal > 0 &&
        sortedPredictionProducts && (
          <ProductSlider
            data={{ ...data, products: sortedPredictionProducts }}
            id={data.id}
            trackType={trackType}
            sidebar={sidebar}
          />
        )}
    </>
  );
};

export default PredictionSlider;
