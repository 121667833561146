/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from "react";
import { Link } from "react-router-dom";

import Card, { CardProps } from "@mui/material/Card";

import { tss } from "tss-react/mui";
import { StyledCardActionArea } from "./StyledCardActionArea";

const useStyles = tss
  .withParams<Pick<StyledCardProps, "dark" | "small" | "withImage">>()
  .create(({ theme, dark, small, withImage }) => ({
    root: {
      height: "100%",
      border: "none",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      padding: "22px",
      borderRadius: "8px",
      border: dark
        ? `2px solid ${theme.palette.text.primary}`
        : `2px solid ${theme.palette.grey[300]}`,
      backgroundColor: dark ? theme.palette.text.primary : undefined,
      height: "100%",
      color: dark ? theme.palette.background.default : undefined,
      [theme.breakpoints.down("sm")]: {
        padding: small ? "18px 14px" : undefined,
        textAlign: small ? "center" : undefined,
        "& > div": {
          justifyContent: small ? "center" : undefined,
        },
      },

      // case, that whole card is a link
      "&.MuiButtonBase-root": {
        display: "block",
        height: "100%",
        borderColor: theme.palette.grey[50],
        backgroundColor: theme.palette.grey[50],
        transition: theme.transitions.create(["color", "background", "border"]),
        "& svg": {
          transition: theme.transitions.create(["color"]),
          marginBottom: theme.spacing(3.5),
          [theme.breakpoints.down("sm")]: {
            marginBottom: theme.spacing(2),
          },
          pointerEvents: "none",
        },
        "& .MuiTypography-body1": {
          lineHeight: "20px",
        },
        "&:hover": {
          color: withImage ? undefined : theme.palette.common.white,
          borderColor: withImage ? undefined : theme.palette.primary.main,
          backgroundColor: withImage
            ? theme.palette.grey[50]
            : theme.palette.primary.main,
          "& svg": {
            color: theme.palette.common.white,
            pointerEvents: "none",
          },
        },
      },
    },
  }));

interface Props {
  to?: string;
  withImage?: boolean;
  small?: boolean;
  dark?: boolean;
}

type StyledCardProps = Props & CardProps;

const StyledCard: FC<StyledCardProps> = ({
  children,
  to,
  withImage,
  small,
  dark,
  ...rest
}) => {
  const { classes } = useStyles({ withImage, small, dark });

  return (
    <Card className={classes.root} variant="outlined" raised={false} {...rest}>
      {to ? (
        <StyledCardActionArea
          className={classes.content}
          component={Link}
          to={to}>
          {children}
        </StyledCardActionArea>
      ) : (
        <div className={classes.content}>{children}</div>
      )}
    </Card>
  );
};

export { StyledCard };
