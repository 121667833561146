import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as ArrowUpIcon } from "assets/icons/arrow-up-1.svg";

import { scrollToTopSelector } from "_redux/selectors/site";
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import { SCROLL_TO_TOP_ACTION } from "_redux/actions/site";
import { tss } from "tss-react/mui";
import { StyledButton } from "../StyledButton";

const useStyles = tss.create(() => ({
  root: {
    pointerEvents: "none",
  },
  button: {
    pointerEvents: "auto",
  },
}));

const StyledScrollUp: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const scrollToTop = useAppSelector(scrollToTopSelector);

  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      dispatch(SCROLL_TO_TOP_ACTION(false));
    }
  }, [scrollToTop, dispatch]);

  return (
    <div className={classes.root}>
      <StyledButton
        className={classes.button}
        startIcon={<ArrowUpIcon />}
        dark
        onClick={() => dispatch(SCROLL_TO_TOP_ACTION(true))}
        tooltip={t("iconTooltips.scrollUpButton")}
      />
    </div>
  );
};

export default StyledScrollUp;
