import React, { FC } from "react";
import { Box } from "@mui/material";

import { BreadcrumbData } from "components/StyledUI/StyledBreadcrumb/StyledBreadcrumb";
import { StyledBreadcrumb } from "components/StyledUI";

interface BreadcrumbSectionProps {
  breadcrumbData: BreadcrumbData[] | null;
}

const BreadcrumbSection: FC<BreadcrumbSectionProps> = ({ breadcrumbData }) => (
  <Box mb={3}>
    <StyledBreadcrumb breadcrumbData={breadcrumbData} />
  </Box>
);

export default BreadcrumbSection;
