import React, { FC } from "react";
import { Typography } from "@mui/material";
import {
  StyledButton,
  StyledContainer,
  StyledDivider,
} from "components/StyledUI";
import { ROUTES } from "constants/routes";

import { Helmet } from "react-helmet-async";
import { Link, isRouteErrorResponse, useRouteError } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ErrorPage: FC = () => {
  const error = useRouteError();
  const { t } = useTranslation();

  // eslint-disable-next-line no-nested-ternary
  const errorMessage = isRouteErrorResponse(error)
    ? error.statusText
    : error instanceof Error
      ? error.message
      : undefined;

  return (
    <main>
      <Helmet>
        <title>{t("error.error")}</title>
      </Helmet>

      <StyledContainer size="small">
        <Typography variant="h1">{t("error.generic")}</Typography>
        {errorMessage && <div>{errorMessage}</div>}
        <div>{t("error.genericDescription")}</div>
        <StyledDivider light />
        <StyledButton component={Link} to={ROUTES.HOME}>
          {t("home.toHome")}
        </StyledButton>
      </StyledContainer>
    </main>
  );
};

export default ErrorPage;
