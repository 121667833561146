import React, { FC } from "react";

import { tss } from "tss-react/mui";

const useStyles = tss
  .withParams<Pick<StyledImageProps, "cropped">>()
  .create(({ theme, cropped }) => ({
    root: {
      position: cropped ? "absolute" : "relative",
      top: cropped ? "50%" : undefined,
      left: cropped ? "50%" : undefined,
      transform: cropped ? "translate(-50%, -50%)" : undefined,
      display: "block",
      minWidth: cropped ? "100%" : undefined,
      maxWidth: cropped ? "100%" : undefined,
      minHeight: cropped ? "100%" : undefined,
      overflow: "hidden",
      transition: theme.transitions.create(["transform"]),
      transformOrigin: "50% 50%",
    },
  }));

type StyledImageProps = {
  imageSrc?: string;
  imageAlt?: string;
  cropped?: boolean;
  className?: string;
};

const StyledImage: FC<StyledImageProps> = ({
  imageSrc,
  imageAlt,
  cropped,
  className,
}) => {
  const { classes, cx } = useStyles({ cropped });

  return (
    <img
      className={cx(classes.root, className)}
      src={imageSrc}
      alt={imageAlt}
    />
  );
};

export { StyledImage };
