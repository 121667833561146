import React, { FC } from "react";

import { tss } from "tss-react/mui";

const useStyles = tss
  .withParams<{
    variant: VariantType;
  }>()
  .create(({ theme, variant }) => ({
    point: {
      width: theme.spacing(1),
      height: theme.spacing(1),
      backgroundColor: theme.palette[variant].main,
      borderRadius: "50%",
      display: "inline-block",
      margin: theme.spacing(0, 1, 0, 0),
    },
    detail: {
      display: "none",
      visibility: "hidden",
    },
  }));

type VariantType = "warning" | "error";

type QuantityHintProps = {
  message?: string | null;
  messageDetail?: string;
  variant?: VariantType;
};

const QuantityHint: FC<QuantityHintProps> = ({
  message,
  messageDetail,
  variant = "warning",
}) => {
  const { classes } = useStyles({ variant });

  if (!message) return null;

  return (
    <div>
      <div className={classes.point} />
      {message}
      {!!messageDetail && (
        <pre className={classes.detail} aria-hidden="true">
          {messageDetail}
        </pre>
      )}
    </div>
  );
};

export default QuantityHint;
