import {
  Html5Qrcode,
  Html5QrcodeConfigs,
  QrcodeSuccessCallback,
  Html5QrcodeCameraScanConfig,
  Html5QrcodeFullConfig,
  BaseLoggger,
  Html5QrcodeError,
  Html5QrcodeResult,
  Logger,
  QrcodeErrorCallback,
  OnTorchActionFailureCallback,
  Html5QrcodeScannerState,
} from "@efood-commerce-sdk/html5-qrcode";

import { BcTorchController } from "./BcTorchController";

/**
 * Interface for controlling different aspects of {@class Html5QrcodeScanner}.
 */
interface Html5QrcodeScannerConfig
  extends Html5QrcodeCameraScanConfig,
    Html5QrcodeConfigs {
  /**
   * If {@code true} the rendered UI will have button to turn flash on or off
   * based on device + browser support.
   *
   * Note: default value is {@code false}.
   */
  showTorchButtonIfSupported?: boolean | undefined;
}

function toHtml5QrcodeCameraScanConfig(
  config: Html5QrcodeScannerConfig
): Html5QrcodeCameraScanConfig {
  return {
    fps: config.fps,
    qrbox: config.qrbox,
  };
}

function toHtml5QrcodeFullConfig(
  config: Html5QrcodeConfigs,
  verbose: boolean | undefined
): Html5QrcodeFullConfig {
  return {
    formatsToSupport: config.formatsToSupport,
    useBarCodeDetectorIfSupported: config.useBarCodeDetectorIfSupported,
    verbose,
  };
}

// End to end scanner library.
export class BcScannerPlugin {
  private torchController!: BcTorchController;

  // #region private fields
  private elementId: string;

  private config: Html5QrcodeScannerConfig;

  private verbose: boolean;

  private logger: Logger;

  // Initally null fields.
  private html5Qrcode: Html5Qrcode;
  // #endregion

  /**
   * Gets state is torch feature is supported.
   *
   * @returns torch feature supported {boolean}.
   */
  public isTorchFeatureSupported(): boolean {
    if (!this.html5Qrcode || !this.isStarted()) {
      return false;
    }
    const cameraCapabilities =
      this.html5Qrcode.getRunningTrackCameraCapabilities();
    return cameraCapabilities.torchFeature().isSupported();
  }

  public async switchTorch() {
    if (this.torchController && this.isTorchFeatureSupported()) {
      await this.torchController.flipState();
    }
  }

  public isTorchEnabled(): boolean {
    if (this.torchController && this.isTorchFeatureSupported()) {
      return this.torchController.isTorchEnabled();
    }
    return false;
  }

  public disableTorch() {
    if (this.torchController && this.isTorchFeatureSupported()) {
      if (this.torchController.isTorchEnabled()) {
        this.torchController.flipState();
      }
      this.torchController.reset();
    }
  }

  /**
   * Creates instance of this class.
   *
   * @param elementId Id of the HTML element.
   * @param config Extra configurations to tune the code scanner.
   * @param verbose - If true, all logs would be printed to console.
   */
  public constructor(
    elementId: string,
    config: Html5QrcodeScannerConfig,
    verbose: boolean | undefined
  ) {
    this.elementId = elementId;
    this.config = config;
    this.verbose = verbose === true;
    this.logger = new BaseLoggger(this.verbose);

    this.html5Qrcode = new Html5Qrcode(
      this.elementId,
      toHtml5QrcodeFullConfig(this.config, this.verbose)
    );
  }

  /**
   * Renders the User Interface.
   *
   * @param qrCodeSuccessCallback Callback called when an instance of a QR
   * code or any other supported bar code is found.
   * @param qrCodeErrorCallback optional, callback called in cases where no
   * instance of QR code or any other supported bar code is found.
   * @param onTorchActionFailureCallback optional, callback called in cases
   * where the requested torch action fails
   */
  public async render(
    qrCodeSuccessCallback: QrcodeSuccessCallback,
    qrCodeErrorCallback?: QrcodeErrorCallback,
    onTorchActionFailureCallback?: OnTorchActionFailureCallback
  ) {
    // Add wrapper to success callback.
    const innerQrCodeSuccessCallback = (
      decodedText: string,
      result: Html5QrcodeResult
    ) => {
      if (qrCodeSuccessCallback) {
        qrCodeSuccessCallback(decodedText, result);
      }
    };

    // Add wrapper to failure callback
    const innerqrCodeErrorCallback = (
      errorMessage: string,
      error: Html5QrcodeError
    ) => {
      if (qrCodeErrorCallback) {
        qrCodeErrorCallback(errorMessage, error);
      }
    };

    return this.html5Qrcode
      .start(
        { facingMode: "environment" },
        toHtml5QrcodeCameraScanConfig(this.config),
        innerQrCodeSuccessCallback,
        innerqrCodeErrorCallback
      )
      .then(() => {
        this.torchController = new BcTorchController(
          this.html5Qrcode.getRunningTrackCameraCapabilities().torchFeature(),
          onTorchActionFailureCallback
        );
      });
  }

  public stop(): Promise<void> {
    if (!this.html5Qrcode) {
      return Promise.resolve();
    }
    if (this.isStarted()) {
      return this.html5Qrcode.stop();
    }

    return Promise.resolve();
  }

  public isNotStarted() {
    if (!this.html5Qrcode) {
      return false;
    }

    const state = this.html5Qrcode.getState();
    if (state === Html5QrcodeScannerState.NOT_STARTED) {
      return true;
    }

    return false;
  }

  public isStarted() {
    if (!this.html5Qrcode) {
      return false;
    }

    return this.html5Qrcode.isScanning;
  }

  public clear(): Promise<void> {
    const emptyHtmlContainer = () => {
      const mainContainer = document.getElementById(this.elementId);
      if (mainContainer) {
        mainContainer.innerHTML = "";
      }
    };

    if (this.html5Qrcode) {
      return new Promise((resolve, reject) => {
        if (!this.html5Qrcode) {
          resolve();
          return;
        }
        if (this.html5Qrcode.isScanning) {
          this.html5Qrcode
            .stop()
            .then((_) => {
              if (!this.html5Qrcode) {
                resolve();
                return;
              }

              this.html5Qrcode.clear();
              emptyHtmlContainer();
              resolve();
            })
            .catch((error) => {
              if (this.verbose) {
                this.logger.logError("Unable to stop qrcode scanner", error);
              }
              reject(error);
            });
        } else {
          // Assuming file based scan was ongoing.
          this.html5Qrcode.clear();
          emptyHtmlContainer();
          resolve();
        }
      });
    }

    return Promise.resolve();
  }
}
