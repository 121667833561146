import React, { FC } from "react";

import { tss } from "tss-react/mui";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";

import { ReactComponent as CheckIcon } from "assets/icons/check-1.svg";

const useStyles = tss
  .withParams<{ size: StyledCheckboxProps["size"] }>()
  .create(({ theme, size }) => ({
    root: {
      padding: size === "small" ? "0 8px 0 0" : "0 12px 0 0",
    },
    disabled: {
      "& input + span": {
        color: `${theme.palette.grey[600]} !important`,
        backgroundColor: `${theme.palette.grey[50]} !important`,
        borderColor: `${theme.palette.grey[50]} !important`,
      },
    },
  }));

const useIconStyles = tss
  .withParams<{
    size: StyledCheckboxProps["size"];
    error: StyledCheckboxProps["error"];
  }>()
  .create(({ theme, size, error }) => ({
    icon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: size === "small" ? 24 : 32,
      height: size === "small" ? 24 : 32,
      backgroundColor: theme.palette.grey[50],
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: error
        ? theme.palette.secondary.main
        : theme.palette.grey[50],
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.common.white,
      transition: theme.transitions.create([
        "border-color",
        "background-color",
      ]),
      "& svg": {
        height: size === "small" ? 14 : 20,
        width: size === "small" ? 14 : 20,
        pointerEvents: "none",
      },
      "input:hover ~ &": {
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.primary.main,
      },
      "input:focus ~ &": {
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.primary.main,
      },
      "input:active ~ &": {
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.primary.dark,
      },
    },
    checkedIcon: {
      backgroundColor: error
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
      borderColor: error
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
      "input:hover ~ &": {
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
      },
      "input:focus ~ &": {
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
      },
      "input:active ~ &": {
        backgroundColor: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
      },
    },
  }));

type StyledCheckboxProps = CheckboxProps & {
  error?: boolean;
};

const StyledCheckbox: FC<StyledCheckboxProps> = ({
  size,
  error,
  checked,
  ...rest
}) => {
  const { classes } = useStyles({ size });
  const { classes: iconClasses, cx } = useIconStyles({ size, error });

  return (
    <Checkbox
      classes={classes}
      checkedIcon={
        <span className={cx(iconClasses.icon, iconClasses.checkedIcon)}>
          <CheckIcon />
        </span>
      }
      icon={<span className={iconClasses.icon} />}
      checked={checked}
      {...rest}
    />
  );
};

export { StyledCheckbox };
