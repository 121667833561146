import React, { FC } from "react";

import PriceChange from "components/priceChange/PriceChange";
import SplitCartDialog from "components/splitCart/SplitCartDialog";
import { styled } from "@mui/material";
import { useSite } from "../../_foundation/hooks/useSite";
import { footerConfig } from "./footerConstant";
import { ContentRecommendation } from "../widgets/content-recommendation";
import WaveDivider from "./WaveDivider";

export const FooterTop = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.grey.A200,
  padding: `${theme.spacing(6)} ${theme.spacing(7)}`,
}));

export const FooterBottom = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.grey.A100,
  padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
}));

const Footer: FC = () => {
  const { currentSite } = useSite();

  return (
    <footer>
      <FooterTop>
        {currentSite != null ? (
          <ContentRecommendation
            cid="footer"
            eSpot={footerConfig.espotFooter}
            page={footerConfig.page}
          />
        ) : null}
      </FooterTop>

      <WaveDivider />

      <FooterBottom>
        {currentSite != null ? (
          <ContentRecommendation
            cid="footer"
            eSpot={footerConfig.espotFooterLinks}
            page={footerConfig.page}
          />
        ) : null}
      </FooterBottom>

      {currentSite != null ? (
        <>
          <PriceChange />
          <SplitCartDialog />
        </>
      ) : null}
    </footer>
  );
};

export default Footer;
