import React, {
  FC,
  FocusEvent,
  MouseEvent,
  PropsWithChildren,
  useCallback,
} from "react";
import { StyledLink } from "components/StyledUI";

import { ROUTES } from "constants/routes";
import { tss } from "tss-react/mui";

const useStyles = tss.create(() => ({
  categorylink: {
    width: "100%",
  },
}));

interface SideBarLinkProps {
  memorizedCloseSideBar: () => void;
  uniqueID: string;
  small?: boolean;
  onClick?: (event: MouseEvent) => void;
  onFocus?: (event: FocusEvent) => void;
}

const SideBarLink: FC<PropsWithChildren<SideBarLinkProps>> = ({
  memorizedCloseSideBar,
  uniqueID,
  children,
  onClick,
  onFocus,
  small = true,
}) => {
  const { classes } = useStyles();

  const combinedOnClickHandler = useCallback(
    (event: MouseEvent) => {
      if (onClick) onClick.apply(this, [event]);
      if (memorizedCloseSideBar) memorizedCloseSideBar.apply(this);
    },
    [onClick, memorizedCloseSideBar]
  );

  return (
    <StyledLink
      to={`${ROUTES.PRODUCT_LIST}/${uniqueID}`}
      onClick={combinedOnClickHandler}
      navigation
      className={classes.categorylink}
      small={small}
      whiteText
      onFocus={onFocus}>
      {children}
    </StyledLink>
  );
};

export default SideBarLink;
