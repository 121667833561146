import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ReactComponent as DownIcon } from "assets/icons/arrow-down-1.svg";
import { StyledButton } from "components/StyledUI";
import { tss } from "tss-react/mui";

interface StyledAccordionProps {
  headline: string;
  small?: boolean;
  expanded?: boolean;
}

const useStyles = tss.create(({ theme }) => ({
  accordion: {
    boxShadow: "none",
    backgroundColor: "transparent",
    "&:before": {
      height: "0px",
    },
    "& .MuiAccordionDetails-root": {
      marginTop: "0",
    },
    "& .MuiAccordionSummary-root": {
      marginTop: "-58px",
    },
    /* fixed minHeight and margin to prevent jumping headline */
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: `${theme.spacing(6)} !important`,
      marginTop: "-64px",
    },
    "& .MuiAccordionSummary-content": {
      display: "flex",
      flexDirection: "row-reverse",
      "& .Mui-expanded": {
        margin: `${theme.spacing(1.5)} 0 !important`,
      },
    },
    "& .MuiAccordionSummary-expandIcon": {
      transition: "none",
      padding: "0",
    },
    "& .consent-accordion": {
      "& button": {
        backgroundColor: "transparent !important",
        color: theme.palette.grey[800],
        fontSize: "14px",
      },
      "& svg": {
        width: "14px",
        height: "14px",
        pointerEvents: "none",
      },
    },
    "& .consent-accordion h5": {
      fontSize: "14px",
      fontWeight: "normal",
      lineHeight: "20px",
      textDecorationLine: "underline",
      textDecorationColor: theme.palette.grey[600],
      textUnderlineOffset: "4px",
    },
    "& .consent-accordion h5:hover": {
      textDecorationColor: theme.palette.primary.light,
      textDecorationLine: "underline",
    },
  },
  "& .MuiGrid-root MuiGrid-item": {
    padding: 0,
  },
  padding: {
    padding: "8px 0",
    margin: "0",
  },
}));

// TODO: this has to be refactored more and moved to CookieConsentModal folder

const StyledConsentAccordion: FC<PropsWithChildren<StyledAccordionProps>> = ({
  headline,
  small,
  expanded = false,
  children,
}) => {
  const { classes, cx } = useStyles();
  const [open, setOpen] = useState<boolean>(expanded);

  useEffect(() => {
    setOpen(expanded);
  }, [expanded]);

  const handleChange = () => setOpen((oldState) => !oldState);

  let headingLevel: "h2" | "h3" | "h5" | "h5" = "h5";
  if (small) {
    headingLevel = "h5";
  }

  const variant = "consent-accordion";

  return (
    <Accordion
      className={cx(classes.accordion, variant)}
      expanded={open}
      onChange={handleChange}>
      <AccordionSummary
        className={cx(classes.padding, variant)}
        expandIcon={<StyledButton startIcon={<DownIcon />} size="small" />}>
        <Typography variant={headingLevel}>{headline}</Typography>
      </AccordionSummary>
      <AccordionDetails className={cx(classes.padding, variant)}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export { StyledConsentAccordion };
