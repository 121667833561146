import TrackRoute from "components/matomo/TrackRoute";
import TrackProduct from "components/matomo/TrackProduct";
import getType from "components/matomo/getType";
import useTrackPredictionOnCart from "components/matomo/useTrackPredictionOnCart";
import MatomoTracker from "@jonkoops/matomo-tracker";
import trackCart from "components/matomo/trackCart";
import MatomoTagManager from "./MatomoTagManager";
import TrackSearch from "./TrackSearch";
import * as SessionUtils from "./utils";
import MatomoProvider, {
  MatomoContext,
  useTrackingConfig,
  useMatomo,
} from "./MatomoProvider";

export {
  TrackRoute,
  TrackSearch,
  TrackProduct,
  useMatomo,
  useTrackingConfig,
  MatomoContext,
  useTrackPredictionOnCart,
  getType,
  SessionUtils,
  MatomoTagManager,
  trackCart,
};

export interface MatomoInstance {
  tracker: MatomoTracker;
}

export interface ContentParams extends MatomoInstance {
  pathname: string;
}

export type TrackShowType = "side" | "pdp" | "list" | "cart";
export const typeList = ["side", "pdp", "list", "cart"];

export default MatomoProvider;
