import { OrderItem } from "types/Order";
import { OrderReducerState } from "_redux/reducers";
import MatomoTracker from "@jonkoops/matomo-tracker";
import roundQuantity from "tools/round-quantity";

const trackCart = (
  orderitems: OrderItem[],
  order: OrderReducerState,
  orderId: string,
  matomo: MatomoTracker | undefined
) => {
  // track cart items
  if (orderId && order && orderitems && matomo) {
    matomo.clearEcommerceCart();

    orderitems.map((item) =>
      matomo.addEcommerceItem({
        sku: item.article.partNumber,
        productName: item.article?.name,
        productPrice: item.price,
        productQuantity: roundQuantity(item.quantity, 2),
      })
    );

    const { promotionCode, rewards } = order;
    if (promotionCode) {
      promotionCode.map(
        (code) =>
          code.qualified &&
          matomo.trackEvent({
            category: "Rabatt Code Bestellung",
            action: `${code.name || code.id}`,
            name: `${code.code}`,
          })
      );
    }
    if (rewards) {
      rewards.map((reward) =>
        matomo.trackEvent({
          category: "Gratisartikel Bestellung",
          action: `${reward.title}`,
          name: `${reward.numberOfGiftOptions}`,
        })
      );
    }

    matomo.trackEcommerceOrder({
      orderId,
      orderRevenue: order.total,
      orderSubTotal: order.totalProduct,
      shippingAmount: order.totalShipping,
      discountOffered: order.totalAdjustment > 0,
      taxAmount: 0,
    });
  }
};

export default trackCart;
