import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { ROUTES } from "constants/routes";
import { StyledLink, StyledButton } from "components/StyledUI";
import { ReactComponent as HouseIcon } from "assets/icons/house-4.svg";
import { tss } from "tss-react/mui";
import determineBreadcrumbData from "./determineBreadcrumbData";

const useStyles = tss.create(({ theme }) => ({
  li: {
    color: theme.palette.text.primary,
    "&:last-of-type": {
      letterSpacing: "0.5px",
    },
  },
}));

export interface BreadcrumbData {
  label: string;
  value: string;
  type_?: string;
  link?: string;
}

interface Props {
  breadcrumbData: BreadcrumbData[] | null;
}

const StyledBreadcrumb: FC<Props> = ({ breadcrumbData }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const matchesXsViewport = useMediaQuery(theme.breakpoints.only("xs"));

  if (!breadcrumbData) return null;

  return (
    <Breadcrumbs classes={classes} aria-label="breadcrumb">
      <StyledButton
        startIcon={<HouseIcon />}
        size="small"
        color="inherit"
        component={Link}
        to={ROUTES.HOME}
        tooltip={t("iconTooltips.homeIcon")}
      />

      {breadcrumbData.map((breadcrumbItem, index) => {
        const breadcrumbItemData = determineBreadcrumbData(
          breadcrumbItem,
          matchesXsViewport,
          breadcrumbData.length,
          index
        );

        if (!breadcrumbItemData) {
          return null;
        }

        return (
          <StyledLink
            to={breadcrumbItemData.link}
            key={breadcrumbItemData.value}
            navigation
            small>
            {breadcrumbItemData.label}
          </StyledLink>
        );
      })}
    </Breadcrumbs>
  );
};

export default React.memo(StyledBreadcrumb);
