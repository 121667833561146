import {
  StoreStore,
  StoreStoreItem,
} from "@hcl-commerce-store-sdk/typescript-axios-transaction";
import { ShowDialogPayload } from "components/dialog/types";
import { CustomerProductlist } from "types/CustomerProductlist";
import { Offer } from "types/Offer";
import {
  Approval,
  Basket,
  ShipInfos,
  Validation,
  BasketPrediction,
  Reward,
  PromotionCode,
  Availability,
  DeliveryDate,
} from "types/Order";
import { EntitledOrganizations } from "types/Organization";

export interface OrderItemError {
  errorKey: string;
  errorMessage: string;
  errorParameters?: string[];
  partNumber: string;
  minQuantityHint?: {
    quantity: string;
    shortDesc: string;
  };
  [key: string]: unknown;
}
export interface CartError {
  errorKey?: string;
  errorMessage?: string;
  errorParameters?: string;
  orderitems: OrderItemError[];
}

export interface ErrorReducerState {
  errorKey?: string;
  errorCode?: string;
  errorTitleKey?: string;
  errorMsgKey?: string;
  errorMessage?: string;
  errorParameters?: string;
  handled?: boolean;
  cart?: CartError;
  loginError?: boolean;
  [extraProps: string]: any;
}

export interface OrderItemWithOrderSplit {
  orderItemId: string;
  catEntryId: string;
  availability: Availability;
}

export interface UserReducerState {
  initiatedFromStorage: boolean;
  userLoggedIn?: boolean;
  WCToken: string;
  WCTrustedToken: string;
  refreshToken?: string;
  contracts: any;
  rewardOptionIdsHandled: string[];
  orderItemsWithOrderSplit: OrderItemWithOrderSplit[];
  prediction?: BasketPrediction;
  trackingChoice?: string;
  stockDeliveryDate?: DeliveryDate;
  deliveryDates?: DeliveryDate[];
  selectedProductListId?: string;
  customerProductLists?: CustomerProductlist[];
  centralPurchaser?: boolean;
  entitledOrganizations?: EntitledOrganizations[];
  details?: {
    activeOrganizationId?: string;
    organizationId?: string;
    [extraProps: string]: any;
  };
  offers?: Offer[];
  [extraProps: string]: any;
}

export interface MatomoConfig {
  host: string | null;
  siteId: number | null;
  containerId?: string;
}

export interface Tracking {
  matomo: MatomoConfig;
}

export interface DropShipConfig {
  barCustomer: boolean;
  commentRequired: boolean;
  name: string;
  type: string;
  title: string;
  deliveryDays: DeliveryDays;
  leadTime: number;
}

export interface DeliveryDays {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export const KNOWN_SYNC_INFORMATION_TYPES = ["dialog", "notification"] as const;

export type SyncInformationType = (typeof KNOWN_SYNC_INFORMATION_TYPES)[number];

export const isSyncInformationType = (
  candidate?: string
): candidate is SyncInformationType =>
  candidate != null &&
  KNOWN_SYNC_INFORMATION_TYPES.includes(candidate as SyncInformationType);

export interface EFoodConfiguration {
  host: string | null;
  productdetailGuest: boolean;
  productdetailCustomer: boolean;
  optainArticleGuest: boolean;
  packageUnitSelector: boolean;
  contracts: boolean;
  optainarticleCheck: boolean;
  onsalearticleRemainder: boolean;
  recipes: boolean;
  reports: boolean;
  sander: boolean;
  internTxt: boolean;
  useDeliveryDateForPricing: boolean;
  onlyHomepage: boolean;
  partialQuantities: boolean;
  navigationLevels: number;
  checkoutComment: boolean;
  servisaID: string | null;
  registrationtype: "NONE" | "NEW" | "ALL";
  searchIn: "catalog" | "orderset";
  dropShipping: DropShipConfig[];
  tracking: Tracking;
  topProductCount: number;
  predictivBasketCount: number;
  isAutoLoginAllowed: boolean;
  offlineSyncInformationType?: string;
  [key: string]: any;
}

export interface OnlineStoreDescriptionType {
  displayName: string;
  description: string;
  languageId: string;
}
export interface StoreConfig {
  description: OnlineStoreDescriptionType[];
  efood: EFoodConfiguration;
  [key: string]: any;
}

export type ExtendedStoreConfigurationType = StoreStoreItem & {
  efood: EFoodConfiguration;
};

export interface SiteInfo {
  storeName: string;
  storeID: string;
  catalogID: string;
  defaultLanguageID: string;
  defaultCurrencyID: string;
  catalogStoreID: string;
  saStoreID: string;
  searchContext?: string;
  transactionContext?: string;
  legacyContext?: string;
  dxContext?: string;
  storeType: string;
  isB2B: boolean;
  inventorySystem: number;
  storeCfg: ExtendedStoreConfigurationType;
  supportedLanguages: any[];
  [extraPros: string]: any;
}

export interface OnlineStatus {
  status: "online" | "offline" | "initial";
  changed: boolean;
}

export interface SiteReducerState {
  currentSite?: SiteInfo;
  scrollToTop: boolean;
  showDialog: ShowDialogPayload[];
  onlineStatus: OnlineStatus;
  forceStandaloneMode: boolean;
  syncManuallyTriggered: boolean;
  syncRunning: boolean;
}

export interface OrderReducerState {
  baskets: Basket[];
  numItems: number;
  shipInfos: ShipInfos | null;
  total: number;
  totalProduct: number;
  totalAdjustment: number;
  id: string;
  totalShipping: number;
  isFetching?: boolean;
  validation?: Validation | null;
  approvals?: Approval[] | null;
  rewards: Reward[] | null;
  promotionCode: PromotionCode[];
  switchTimeExpired?: string;
}

export interface CatalogReducerState {
  categoryTop: CatalogGroupView[];
}

export interface CatalogGroupView {
  uniqueID: string;
  identifier: string;
  storeID: string;
  name: string;
  parentCatalogGroupID: string;
  sequence?: string;
  thumbnail?: string;
  fullImage?: string;
  resourceId: string;
  catalogGroupView?: CatalogGroupView[];
  childCatalogGroupID?: string[];
}

export interface ApiReducerState {
  apiFlowList: string[]; //Tracks list of API calls for development use only. State will exist in Production but will not be used.
}
