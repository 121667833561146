import React, { FC, useState } from "react";
import { ROUTES } from "constants/routes";
import { useTheme, Breakpoint } from "@mui/material/styles";
import { StyledCard, StyledIcon, StyledButton } from "components/StyledUI";
import {
  Box,
  GridSize,
  Hidden,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ReactComponent as LeftIcon } from "assets/icons/arrow-left-1.svg";
import { ReactComponent as RightIcon } from "assets/icons/arrow-right-1.svg";
import { tss } from "tss-react/mui";
import type { ZIndexWithExtension } from "theme/zIndexExtension";

interface CardData {
  id: string;
  title: string;
  desc: string | undefined;
  sort: string;
}

interface GridCardStaticProps {
  icon: React.ReactNode;
  data: CardData[];
  small?: boolean;
  titleVariant?: "h1" | undefined;
  tooltip?: string;
}

const useStyles = tss.create(({ theme }) => ({
  arrows: {
    zIndex: (theme.zIndex as ZIndexWithExtension).extension.offCanvasShadow,
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    "& > button": {
      margin: theme.spacing(0.5),
    },
    "& .MuiButton-root:focus": {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[800],
    },
    "& .MuiButton-root:hover": {
      backgroundColor: theme.palette.primary.light,
      color: "#fff",
    },
  },
  grid: {
    position: "relative",
  },
}));

const GridCardSlider: FC<
  Partial<Record<Breakpoint, boolean | GridSize>> & GridCardStaticProps
> = ({ data, small, icon, titleVariant, tooltip, ...rest }) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const breakpointDownXs = useMediaQuery(theme.breakpoints.down("sm"));
  const [index, setIndex] = useState(0);

  const forward = () => {
    setIndex(index < data.length - 1 ? index + 1 : 0);
  };
  const backward = () => {
    setIndex(index > 0 ? index - 1 : data.length - 1);
  };

  return (
    <>
      {data[index] && (
        <Grid {...rest} className={classes.grid}>
          <Hidden smDown>
            <div className={classes.arrows}>
              <StyledButton
                startIcon={<LeftIcon />}
                size="small"
                onClick={backward}
                color="inherit"
              />
              <StyledButton
                startIcon={<RightIcon />}
                size="small"
                onClick={forward}
                color="inherit"
              />
            </div>
          </Hidden>

          <StyledCard
            small={small}
            to={`${ROUTES.ACCOUNT_LISTS}?productListId=${data[index].id}`}>
            <StyledIcon
              size={breakpointDownXs ? "40" : "88"}
              color={theme.palette.primary.main}
              tooltip={tooltip}>
              {icon}
            </StyledIcon>
            <Box mb={breakpointDownXs ? 0 : 0.5}>
              <Typography
                variant={titleVariant || (breakpointDownXs ? "body1" : "h2")}
                component="h2"
                style={{ fontWeight: 700 }}>
                {data[index].title}
              </Typography>
            </Box>

            <Hidden smDown={small}>
              <Typography>{data[index].desc}</Typography>
            </Hidden>
          </StyledCard>
        </Grid>
      )}
    </>
  );
};

export default GridCardSlider;
