import React, { FC, useMemo } from "react";
import { Link, LinkProps } from "react-router-dom";
import { tss } from "tss-react/mui";

const useStyles = tss
  .withParams<IStyledCategoriesLink>()
  .create(({ theme, bold, underline }) => ({
    root: {
      display: "inline-block",
      fontWeight: bold ? "bold" : undefined,
      color: theme.palette.text.primary,
      letterSpacing: "0.5px",
      textUnderlineOffset: "4px",
      textDecorationLine: underline ? "underline" : "none",

      "&:hover": {
        textDecorationColor: theme.palette.primary.light,
        textDecorationLine: "underline",
      },

      "&:active": {
        textDecorationColor: theme.palette.primary.dark,
        textDecorationLine: "underline",
      },
    },
  }));

type IStyledCategoriesLink = {
  bold: boolean;
  underline: boolean;
};

type StyledCategoriesLinkProps = IStyledCategoriesLink & LinkProps;

const StyledCategoriesLink: FC<StyledCategoriesLinkProps> = (props) => {
  const { bold, underline, to, children, ...rest } = props;
  const { classes } = useStyles({ bold, underline });

  const href = useMemo(() => to?.toString() ?? "", [to]);

  if (
    to &&
    (/^http[s]?:\/\//.test(href) ||
      /^mailto:([^?]*)/.test(href) ||
      /^tel:([^?]*)/.test(href))
  ) {
    return (
      <a
        className={classes.root}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        {...rest}>
        {children}
      </a>
    );
  }

  return (
    <Link className={classes.root} to={to} {...rest}>
      {children}
    </Link>
  );
};

export { StyledCategoriesLink };
