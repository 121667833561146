// react
import React, { FC, useState } from "react";
import { useAppSelector } from "_redux/hooks";

import { loginStatusSelector } from "_redux/selectors/user";
// mui
import { styled, useMediaQuery, useTheme } from "@mui/material";
import Grid, { Grid2Props } from "@mui/material/Unstable_Grid2";
// component
import { DeliveryDateBox } from "components/widgets/deliveryDateBox";
import { MiniCart } from "components/cart";
import { StyledShow } from "components/StyledUI";

import { tss } from "tss-react/mui";
import type { ZIndexWithExtension } from "theme/zIndexExtension";
import SideBar from "./SideBar";
import SideBarButton from "./SideBarButton";
import HeaderLogo from "./HeaderLogo";
import QuickSearch from "./search/QuickSearch";
import Account from "./account";
import LogonBox from "./logonBox";
import ActionBar from "./actionBar";

export const HeaderRoot = styled("header", {
  name: "Header",
  slot: "root",
})(({ theme }) => ({
  backgroundColor: theme.palette.grey.A200,
  position: "sticky",
  top: 0,
  zIndex: (theme.zIndex as ZIndexWithExtension).extension.header,
}));

export const HeaderContent = styled(Grid, {
  name: "Header",
  slot: "content",
  shouldForwardProp: (prop) => prop !== "alwaysMarginBottom",
})<{ alwaysMarginBottom?: boolean }>(
  ({ theme, alwaysMarginBottom = false }) => ({
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey.A200,
    ...(!alwaysMarginBottom && {
      [theme.breakpoints.only("xs")]: {
        marginBottom: theme.spacing(0),
      },
    }),
  })
);

type HeaderLogoWrapperProps = Grid2Props & {
  loggedIn?: boolean;
};

export const HeaderLogoWrapper = styled(Grid, {
  name: "Header",
  slot: "logoWrapper",
  shouldForwardProp: (prop) => prop !== "loggedIn",
})<HeaderLogoWrapperProps>(({ theme, loggedIn = false }) => ({
  zIndex: (theme.zIndex as ZIndexWithExtension).extension.headerLogoWrapper,
  display: "flex",
  height: "64px",
  [theme.breakpoints.only("xs")]: {
    width: loggedIn ? "45%" : "50%",
  },
  [theme.breakpoints.up("md")]: {
    height: "86px",
  },

  [theme.breakpoints.up("lg")]: {
    height: "104px",
  },
}));

const useStyles = tss.create(({ theme }) => ({
  searchGridItem: {
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(3, 5, 1),
    },
    margin: theme.spacing(3, 3, 1),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(1, 3, 1),
    },
    flex: 1,
    [theme.breakpoints.only("xs")]: {
      margin: theme.spacing(1, 2),
    },
  },
  actionBarWrapper: {
    margin: theme.spacing(0, 0, 0, 3),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(0, 0, 0, 5),
    },
  },
  loginGridItem: {
    minWidth: "max-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: theme.spacing(2, 2, 0, 0),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 2, 0, 0),
    },
    "& button": {
      [theme.breakpoints.up("lg")]: {
        margin: theme.spacing(0, 2, 0, 0),
      },
      margin: theme.spacing(0, 1, 0, 0),
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(0),
      },
    },
  },
}));

const Header: FC = () => {
  const loggedIn = useAppSelector(loginStatusSelector);
  const { classes } = useStyles();
  const [isSideBarOpen, setSideBarOpenStatus] = useState(false);
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <HeaderRoot id="header">
      <HeaderContent container>
        <HeaderLogoWrapper xs={6} sm={3} loggedIn={loggedIn}>
          <SideBarButton
            isSideBarOpen={isSideBarOpen}
            setSideBarOpenStatus={setSideBarOpenStatus}
          />
          <HeaderLogo />
        </HeaderLogoWrapper>
        <Grid container xs={6} sm={9}>
          {isSmDown ? null : (
            <Grid xs={12} sm={11} className={classes.searchGridItem}>
              <QuickSearch />
            </Grid>
          )}

          <Grid xs={12} sm="auto" className={classes.loginGridItem}>
            <StyledShow loggedOut>
              <LogonBox />
            </StyledShow>
            <StyledShow loggedIn>
              <Account />
              <DeliveryDateBox variant="icon" />
              <MiniCart />
            </StyledShow>
          </Grid>

          {isSmDown ? null : (
            <Grid xs={12}>
              <div className={classes.actionBarWrapper}>
                <ActionBar />
              </div>
            </Grid>
          )}
        </Grid>
        {!isSmDown ? null : (
          <Grid xs={12} className={classes.searchGridItem}>
            <QuickSearch />
          </Grid>
        )}
      </HeaderContent>

      <SideBar
        isSideBarOpen={isSideBarOpen}
        setSideBarOpenStatus={setSideBarOpenStatus}
      />
    </HeaderRoot>
  );
};

export default Header;
