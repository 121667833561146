import React, { FC } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";

import { StyledButton, StyledFormInput } from "components/StyledUI";
import { useOnlineStatus } from "_foundationExt/hooks/useOnlineStatus";
import { tss } from "tss-react/mui";
import CartPromotionCodeButtons from "./CartPromotionCodeButtons";

import { useFetchPromotions } from "./CartPromotionCode.helper";

const useStyles = tss.create(({ theme }) => ({
  formWrapper: {
    marginTop: theme.spacing(1),

    [theme.breakpoints.only("xs")]: {
      display: "flex",
    },
  },

  form: {
    [theme.breakpoints.only("xs")]: {
      flex: 1,
    },
  },
}));

interface CartPromotionCodeProps {
  orderId: string;
}

const CartPromotionCode: FC<CartPromotionCodeProps> = ({ orderId }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { isOffline } = useOnlineStatus();

  const {
    promotionCodeInput,
    setPromotionCodeInput,
    promotionCodeError,
    setPromotionCodeError,
    promotionCodes,
    createNewPromotion,
    deletePromotion,
  } = useFetchPromotions(orderId);

  const handleInputChange = (event) => {
    setPromotionCodeInput(event.target.value);
    setPromotionCodeError("");
  };

  return (
    <Grid container>
      <Grid xs={12}>
        <CartPromotionCodeButtons
          promotionCodeData={promotionCodes}
          deletePromotion={deletePromotion}
        />
      </Grid>

      <Grid xs={12}>
        <div className={classes.formWrapper}>
          <StyledFormInput
            placeholder={t("cart.enterPromotionCode")}
            size="small"
            value={promotionCodeInput}
            error={promotionCodeError.length > 0}
            helperText={promotionCodeError}
            showHelperTextDirectly
            className={classes.form}
            onChange={(event) => handleInputChange(event)}
            disabled={isOffline}
          />
          <Box ml={1} display="inline-block">
            <StyledButton
              variant="outlined"
              size="small"
              onClick={() => createNewPromotion()}
              disabled={promotionCodeInput.length === 0 || isOffline}>
              {t("action.apply")}
            </StyledButton>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default CartPromotionCode;
