import React, { FC } from "react";
import { StyledAccordion } from "components/StyledUI";
import { CatalogGroupView } from "_redux/reducers";
import { tss } from "tss-react/mui";
import SideBar2ndLevel from "./SideBar2ndLevel";
import SideBarLink from "./SideBarLink";

interface SideBarAccordionsProps {
  categories?: CatalogGroupView[];
  memorizedCloseSideBar: () => void;
  navigationLevels: number;
}

const useStyles = tss.create(() => ({
  childAccordionWrapper: {
    width: "100%",
  },
}));

const stopEventPropagation = (event) => event.stopPropagation();

const SideBarAccordions: FC<SideBarAccordionsProps> = ({
  categories,
  memorizedCloseSideBar,
  navigationLevels,
}) => {
  const { classes } = useStyles();

  return (
    <>
      {categories &&
        categories.map((topCategory) => (
          <StyledAccordion
            key={topCategory.identifier}
            dark
            thick
            headline={
              <SideBarLink
                memorizedCloseSideBar={memorizedCloseSideBar}
                uniqueID={topCategory.uniqueID}
                small={false}
                onClick={stopEventPropagation}
                onFocus={stopEventPropagation}>
                {topCategory.name}
              </SideBarLink>
            }>
            <div className={classes.childAccordionWrapper}>
              {topCategory.catalogGroupView &&
                topCategory.catalogGroupView.map((childCategory) => (
                  <React.Fragment key={childCategory.identifier}>
                    <SideBar2ndLevel
                      memorizedCloseSideBar={memorizedCloseSideBar}
                      navigationLevels={navigationLevels}
                      category2ndLevel={childCategory}
                    />
                  </React.Fragment>
                ))}
            </div>
          </StyledAccordion>
        ))}
    </>
  );
};

export default React.memo(SideBarAccordions);
