const isExternalUrl = (
  candidate: string
): { isExternal: boolean; url?: URL } => {
  if (candidate == null) {
    return { isExternal: false };
  }

  try {
    const candidateUrl = new URL(candidate, document.baseURI);
    const documentUrl = new URL(document.baseURI);

    return {
      isExternal: candidateUrl.origin !== documentUrl.origin,
      url: candidateUrl,
    };
  } catch {
    return { isExternal: false };
  }
};

export default isExternalUrl;
