import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "_redux/hooks";
import { isEqual } from "lodash-es";
import { selectPartNumbersOfAllOrderItems } from "_redux/selectors/order";
import { ESpotData } from "types/Marketing";
import { Product } from "types/Product";
import productProximitiesService from "_foundationExt/apis/transaction/productProximities.service";
import { useSite } from "_foundationExt/hooks/useSite";
import fetchProductByPartNumbers from "../utils/fetchProductByPartNumbers";
import ProductSlider from "./ProductSlider";

type Props = {
  eFoodPartnumber?: string;
  data: ESpotData | undefined;
  sidebar?: boolean;
};

const ProductProximities: FC<Props> = ({ eFoodPartnumber, data, sidebar }) => {
  const [loading, setLoading] = useState(true);
  const [proximitiesList, setProximitiesList] = useState<string[]>([]);
  const [fetchedProducts, setFetchedProducts] = useState<Product[]>();
  const [recommendedProducts, setFilteredProducts] = useState<Product[] | null>(
    null
  );
  const [error, setError] = useState(false);
  const { currentSite } = useSite();
  const orderItems = useAppSelector(selectPartNumbersOfAllOrderItems, isEqual);

  useEffect(() => {
    if (loading && currentSite?.storeID && eFoodPartnumber) {
      productProximitiesService
        .getProximitiesForProduct({
          storeId: currentSite.storeID,
          eFoodPartnumber,
        })
        .then((res) => {
          const productProximitiesResult: string[] =
            res.data.product_proximities;
          setProximitiesList(productProximitiesResult);
        });
      setLoading(false);
    }
  }, [currentSite?.storeID, loading, eFoodPartnumber]);

  useEffect(() => {
    if (!loading && currentSite?.storeID && proximitiesList?.length) {
      // load products
      fetchProductByPartNumbers(
        proximitiesList,
        currentSite.storeID,
        setError
      ).then((products) => {
        setFetchedProducts(products);
      });
    }
  }, [currentSite?.storeID, loading, proximitiesList]);

  useEffect(() => {
    if (fetchedProducts) {
      const filteredFetchedProducts = fetchedProducts.filter(
        (fetchedProduct) =>
          !orderItems?.includes(fetchedProduct.eFoodPartnumber)
      );

      setFilteredProducts(filteredFetchedProducts);
    }
  }, [fetchedProducts, orderItems]);

  if (!data || error || !recommendedProducts?.length) {
    return null;
  }

  return (
    <ProductSlider
      data={{ ...data, products: recommendedProducts }}
      id={data.id}
      sidebar={sidebar}
    />
  );
};

export default ProductProximities;
