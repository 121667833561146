import { AxiosRequestConfig } from "axios";
import { AvailabilityOfflineAwareRequest } from "./availability/types";
import { CartOfflineAwareRequest } from "./cart/types";
import { CUSTOM_HEADER } from "./common/constants";
import { ProductviewOfflineAwareRequest } from "./productview/types";

export type OfflineAwareRequestMarker =
  | ProductviewOfflineAwareRequest
  | CartOfflineAwareRequest
  | AvailabilityOfflineAwareRequest;

const addOfflineAwareRequestHeaders = (
  offlineRequestMarker: OfflineAwareRequestMarker,
  options?: AxiosRequestConfig
) => {
  const enhancedOptions: AxiosRequestConfig = options ?? {};
  let { headers } = enhancedOptions;
  if (!headers) {
    headers = {};
    // eslint-disable-next-line no-param-reassign
    enhancedOptions.headers = headers;
  }

  // only handle offline request, if they are not offline sync requests
  if (!headers[CUSTOM_HEADER.X_EFOOD_OFFLINE_SYNC_REQUEST]) {
    headers[CUSTOM_HEADER.X_EFOOD_REQUEST_RESOURCE] =
      offlineRequestMarker.resource;
    headers[CUSTOM_HEADER.X_EFOOD_REQUEST_ORIGIN_METHOD] =
      offlineRequestMarker.originMethod;
  }

  return enhancedOptions;
};

export default addOfflineAwareRequestHeaders;
