import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { useTheme, Breakpoint } from "@mui/material/styles";
import { StyledCard, StyledIcon } from "components/StyledUI";
import {
  Box,
  GridSize,
  Hidden,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

interface GridCardStaticProps {
  to?: string;
  small?: boolean;
  title: string;
  titleVariant?: "h1" | undefined;
  desc: string;
  icon: React.ReactNode;
  iconSize?: string;
  tooltip?: string;
}

const GridCardStatic: FC<
  Partial<Record<Breakpoint, boolean | GridSize>> & GridCardStaticProps
> = ({
  to,
  small,
  title,
  titleVariant,
  desc,
  icon,
  iconSize,
  tooltip,
  ...rest
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const breakpointDownXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid {...rest}>
      <StyledCard to={to} small={small}>
        <StyledIcon
          size={iconSize || (breakpointDownXs ? "40" : "88")}
          color={theme.palette.primary.main}
          tooltip={tooltip}>
          {icon}
        </StyledIcon>
        <Box mb={breakpointDownXs ? 0 : 0.5}>
          <Typography
            variant={titleVariant || (breakpointDownXs ? "body1" : "h2")}
            component="h2"
            style={{ fontWeight: 700 }}>
            {t(title)}
          </Typography>
        </Box>

        <Hidden smDown={small}>
          <Typography>{t(desc)}</Typography>
        </Hidden>
      </StyledCard>
    </Grid>
  );
};

export default GridCardStatic;
