/* tslint:disable */
/* eslint-disable */
/**
 * EFood Storefront API
 * This is the EFood Storefront API specification.  Operations are grouped by tags.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { CreateUser201Response } from '../../../../com/telekom-mms/efood/api/model';
// @ts-ignore
import { CreateUserRequest } from '../../../../com/telekom-mms/efood/api/model';
// @ts-ignore
import { GetUserAdmisterList200Response } from '../../../../com/telekom-mms/efood/api/model';
// @ts-ignore
import { GetUserDetails200Response } from '../../../../com/telekom-mms/efood/api/model';
// @ts-ignore
import { UpdateUserRequest } from '../../../../com/telekom-mms/efood/api/model';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new user.
         * @param {string} storeId The store identifier
         * @param {CreateUserRequest} [createUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (storeId: string, createUserRequest?: CreateUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('createUser', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/efood/user`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an existing user.
         * @param {string} storeId The store identifier
         * @param {BigInt} userId The user identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (storeId: string, userId: BigInt, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('deleteUser', 'storeId', storeId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/store/{storeId}/efood/user/{userId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"userId"}}`, String(userId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of user ids which the current can admister.
         * @param {string} storeId The store identifier
         * @param {string} organizationId The id of the organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAdmisterList: async (storeId: string, organizationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getUserAdmisterList', 'storeId', storeId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getUserAdmisterList', 'organizationId', organizationId)
            const localVarPath = `/store/{storeId}/efood/user/user_admister_list/{organizationId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"organizationId"}}`, String(organizationId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get detailed informations about a user.
         * @param {string} storeId The store identifier
         * @param {BigInt} userId The user identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDetails: async (storeId: string, userId: BigInt, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getUserDetails', 'storeId', storeId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserDetails', 'userId', userId)
            const localVarPath = `/store/{storeId}/efood/user/{userId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"userId"}}`, String(userId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates information of an existing user.
         * @param {string} storeId The store identifier
         * @param {BigInt} userId The user identifier.
         * @param {UpdateUserRequest} [updateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (storeId: string, userId: BigInt, updateUserRequest?: UpdateUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('updateUser', 'storeId', storeId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            const localVarPath = `/store/{storeId}/efood/user/{userId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"userId"}}`, String(userId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new user.
         * @param {string} storeId The store identifier
         * @param {CreateUserRequest} [createUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(storeId: string, createUserRequest?: CreateUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUser201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(storeId, createUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes an existing user.
         * @param {string} storeId The store identifier
         * @param {BigInt} userId The user identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(storeId: string, userId: BigInt, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(storeId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of user ids which the current can admister.
         * @param {string} storeId The store identifier
         * @param {string} organizationId The id of the organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAdmisterList(storeId: string, organizationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserAdmisterList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAdmisterList(storeId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get detailed informations about a user.
         * @param {string} storeId The store identifier
         * @param {BigInt} userId The user identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDetails(storeId: string, userId: BigInt, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserDetails200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDetails(storeId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates information of an existing user.
         * @param {string} storeId The store identifier
         * @param {BigInt} userId The user identifier.
         * @param {UpdateUserRequest} [updateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(storeId: string, userId: BigInt, updateUserRequest?: UpdateUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(storeId, userId, updateUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new user.
         * @param {string} storeId The store identifier
         * @param {CreateUserRequest} [createUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(storeId: string, createUserRequest?: CreateUserRequest, options?: any): AxiosPromise<CreateUser201Response> {
            return localVarFp.createUser(storeId, createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an existing user.
         * @param {string} storeId The store identifier
         * @param {BigInt} userId The user identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(storeId: string, userId: BigInt, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(storeId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of user ids which the current can admister.
         * @param {string} storeId The store identifier
         * @param {string} organizationId The id of the organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAdmisterList(storeId: string, organizationId: string, options?: any): AxiosPromise<GetUserAdmisterList200Response> {
            return localVarFp.getUserAdmisterList(storeId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get detailed informations about a user.
         * @param {string} storeId The store identifier
         * @param {BigInt} userId The user identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDetails(storeId: string, userId: BigInt, options?: any): AxiosPromise<GetUserDetails200Response> {
            return localVarFp.getUserDetails(storeId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates information of an existing user.
         * @param {string} storeId The store identifier
         * @param {BigInt} userId The user identifier.
         * @param {UpdateUserRequest} [updateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(storeId: string, userId: BigInt, updateUserRequest?: UpdateUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateUser(storeId, userId, updateUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Create a new user.
     * @param {string} storeId The store identifier
     * @param {CreateUserRequest} [createUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(storeId: string, createUserRequest?: CreateUserRequest, options?: any) {
        return UserApiFp(this.configuration).createUser(storeId, createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes an existing user.
     * @param {string} storeId The store identifier
     * @param {BigInt} userId The user identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(storeId: string, userId: BigInt, options?: any) {
        return UserApiFp(this.configuration).deleteUser(storeId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of user ids which the current can admister.
     * @param {string} storeId The store identifier
     * @param {string} organizationId The id of the organization.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserAdmisterList(storeId: string, organizationId: string, options?: any) {
        return UserApiFp(this.configuration).getUserAdmisterList(storeId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get detailed informations about a user.
     * @param {string} storeId The store identifier
     * @param {BigInt} userId The user identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserDetails(storeId: string, userId: BigInt, options?: any) {
        return UserApiFp(this.configuration).getUserDetails(storeId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates information of an existing user.
     * @param {string} storeId The store identifier
     * @param {BigInt} userId The user identifier.
     * @param {UpdateUserRequest} [updateUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(storeId: string, userId: BigInt, updateUserRequest?: UpdateUserRequest, options?: any) {
        return UserApiFp(this.configuration).updateUser(storeId, userId, updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
