import React, { FC } from "react";
import { ESpotData } from "types/Marketing";
import ProductSlider from "./ProductSlider";

type Props = {
  data: ESpotData | undefined;
  sidebar?: boolean;
  sidebarBottom?: boolean;
};

const ProductRecommendation: FC<Props> = ({ data, sidebar, sidebarBottom }) => {
  if (!data || !data.products || !data.products.length) {
    return null;
  }

  return (
    <ProductSlider
      data={data}
      id={data.id}
      sidebar={sidebar}
      sidebarBottom={sidebarBottom}
    />
  );
};

export default ProductRecommendation;
