import React, { FC, useCallback, useMemo, useState } from "react";
import { Typography, FormGroup, InputAdornment } from "@mui/material";
import { StyledFilterDropdown, StyledFormInput } from "components/StyledUI";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { Filter } from "types/Search";
import { tss } from "tss-react/mui";
import FilterOption from "./FilterOption";

const useStyles = tss.create(({ theme }) => ({
  overflow: {
    maxHeight: 168,
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: theme.spacing(0.5),
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[300],
      borderRadius: "2px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: theme.palette.grey[700],
    },
  },
}));

interface FilterDropdowsWithSearchProps {
  filter: Filter;
}

const FilterDropdowsWithSearch: FC<FilterDropdowsWithSearchProps> = ({
  filter,
}) => {
  const { classes } = useStyles();

  const [searchTerm, setSearchTerm] = useState("");

  const filterOptionsMatchingSearchTerm = useMemo(() => {
    if (!searchTerm) {
      return filter.entry;
    }

    return filter.entry.filter((filterOption) =>
      filterOption.label.toLowerCase().includes(searchTerm)
    );
  }, [filter.entry, searchTerm]);

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const term = event.target.value.toLowerCase();
      setSearchTerm(term);
    },
    []
  );

  return (
    <StyledFilterDropdown buttonLabel={filter.name}>
      <StyledFormInput
        fullWidth
        id="small-outlined-search"
        placeholder="Suche"
        size="small"
        value={searchTerm}
        autoFocus
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <div className={classes.overflow}>
        <FormGroup>
          {filterOptionsMatchingSearchTerm.map((filterEntry) => (
            <FilterOption
              filterKey={filter.value}
              filterEntry={filterEntry}
              key={filterEntry.value}
            />
          ))}
        </FormGroup>

        {filterOptionsMatchingSearchTerm.length === 0 && (
          <Typography variant="h5" component="p" style={{ padding: 4 }}>
            keine Ergebnisse
          </Typography>
        )}
      </div>
    </StyledFilterDropdown>
  );
};

export default FilterDropdowsWithSearch;
