import React, { ElementType, useMemo } from "react";
import { Link, To } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { StyledImage, StyledCardActionArea } from "components/StyledUI";
import { getMediaComponent } from "components/StyledUI/StyledCard/StyledCardMedia";
import CardMediaVideo from "components/StyledUI/StyledCard/CardMediaVideo";
import { tss } from "tss-react/mui";
import EmsGridItem from "./EmsGridItem";

interface Props {
  emsContent: EmsGridItem;
}

type EmsContentCardProps = Props;

const useStyles = tss
  .withParams<Pick<EmsGridItem, "imageSrc">>()
  .create(({ theme, imageSrc }) => ({
    root: {
      color: imageSrc ? undefined : theme.palette.common.white,
      backgroundColor: imageSrc
        ? theme.palette.grey[50]
        : theme.palette.primary.main,
      borderRadius: "8px",
      height: "100%",
    },
    media: {
      transition: theme.transitions.create(["background-size"]),
    },
    oneThirdMedia: {
      height: "232px",

      [theme.breakpoints.down("lg")]: {
        height: "184px",
      },
      [theme.breakpoints.down("md")]: {
        height: "174px",
      },
    },
    oneHalfMedia: {
      height: "320px",

      [theme.breakpoints.down("lg")]: {
        height: "242px",
      },
      [theme.breakpoints.down("md")]: {
        height: "174px",
      },
    },
    fullMedia: {
      height: "360px",
      width: "calc(100% - 330px)",
      float: "right",

      [theme.breakpoints.down("lg")]: {
        height: "336px",
      },
      [theme.breakpoints.down("sm")]: {
        height: "174px",
        float: "none",
        width: "inherit",
      },
    },
    content: {
      padding: theme.spacing(3),
      display: "flex",
    },
    oneThirdContent: {
      height: imageSrc ? "128px" : "360px",

      [theme.breakpoints.down("lg")]: {
        height: imageSrc ? "128px" : "312px",
      },
      [theme.breakpoints.down("md")]: {
        height: "100% !important",
      },
      "& .MuiBox-root": {
        "& p:first-of-type": {
          marginBottom: "4px !important",
          "& strong span": {
            lineHeight: "28px",
          },
        },
      },
    },
    oneHalfContent: {
      height: imageSrc ? "128px" : "448px",

      [theme.breakpoints.down("lg")]: {
        height: imageSrc ? "128px" : "370px",
      },
      [theme.breakpoints.down("md")]: {
        height: "100% !important",
      },
      "& .MuiBox-root": {
        "& p:first-of-type": {
          marginBottom: "4px !important",
          "& strong span": {
            lineHeight: "28px",
          },
        },
      },
    },
    fullContent: {
      height: "360px",
      width: imageSrc ? "330px" : undefined,
      justifyContent: "space-between",
      flexDirection: "column",

      [theme.breakpoints.only("md")]: {
        height: "336px",
      },
      [theme.breakpoints.only("sm")]: {
        height: imageSrc ? "336px" : "100%",
        maxHeight: imageSrc ? undefined : "312px",
      },
      [theme.breakpoints.down("sm")]: {
        height: "100%",
        maxHeight: "inherit",
        width: "inherit !important",
      },

      [theme.breakpoints.down("sm")]: {
        height: "100%",
        maxHeight: "inherit",
        width: "inherit !important",
      },
      "& br": {
        display: "block",
        marginTop: "16px",
        content: "' '",
      },
      "& .MuiBox-root": {
        "& p:first-of-type strong span": {
          lineHeight: "40px",
        },
      },
    },
    innerContent: {
      overflow: "hidden",
      height: "100%",
      width: "100%",
      wordBreak: "break-word",
      position: "relative",
    },
    noCursor: {
      cursor: "inherit",
    },
    icon: {
      maxWidth: "80px",
      maxHeight: "80px",
    },
    iconRight: {
      float: "right",
      marginLeft: theme.spacing(3),
    },
    iconBottomRight: {
      float: "right",
      height: "100%",
      display: "flex",
      alignItems: "flex-end",
      shapeOutside: "inset(calc(100% - 80px) 0 0)",
      marginLeft: theme.spacing(1),
    },
    iconLeftHolder: {
      paddingTop: theme.spacing(1), // to avoid half rendered line with text
      justifyContent: "flex-start",
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(3),
      },
    },
  }));

const EmsContentCard: React.FC<EmsContentCardProps> = ({
  emsContent,
  ...rest
}) => {
  const theme = useTheme();
  const { classes, cx } = useStyles({ imageSrc: emsContent?.imageSrc });
  const isXsBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));
  const isExternalLink = emsContent.url
    ? /^http[s]?:/.test(emsContent?.url.toString())
    : false;
  const isIconTopRight =
    (emsContent.gridItemType !== "full" || isXsBreakpoint) &&
    emsContent.iconSrc != null &&
    emsContent.imageSrc != null;
  const isIconBottomRight =
    !isIconTopRight &&
    emsContent.gridItemType !== "full" &&
    emsContent.iconSrc != null &&
    !emsContent.imageSrc != null &&
    !isXsBreakpoint;
  const isIconBottomLeft =
    emsContent.gridItemType === "full" &&
    emsContent.iconSrc != null &&
    !isIconTopRight &&
    !isIconBottomRight;

  const isVideo = useMemo(
    () => getMediaComponent(emsContent?.imageSrc) === "video",
    [emsContent?.imageSrc]
  );

  if (!emsContent) {
    return null;
  }

  const iconClassName = cx({
    [classes.media]: true,
    [classes.iconRight]: isIconTopRight,
    [classes.iconBottomRight]: !isIconTopRight && isIconBottomRight,
  });
  const cardMediaClassName = cx({
    [classes.media]: true,
    [classes.oneThirdMedia]: emsContent.gridItemType === "one-third",
    [classes.oneHalfMedia]: emsContent.gridItemType === "one-half",
    [classes.fullMedia]: emsContent.gridItemType === "full",
  });
  const cardContentClassName = cx({
    [classes.content]: true,
    [classes.oneThirdContent]: emsContent.gridItemType === "one-third",
    [classes.oneHalfContent]: emsContent.gridItemType === "one-half",
    [classes.fullContent]: emsContent.gridItemType === "full",
  });

  interface IActionAreaLinkProps {
    className?: string;
    component: ElementType;
    to?: To;
    href?: string;
    target?: string;
    rel?: string;
  }
  const actionAreaLinkProps = {} as IActionAreaLinkProps;
  if (emsContent.url) {
    if (isExternalLink) {
      actionAreaLinkProps.component = "a";
      actionAreaLinkProps.href = emsContent.url;
      actionAreaLinkProps.target = "_blank";
      actionAreaLinkProps.rel = "noreferrer";
    } else {
      actionAreaLinkProps.component = Link;
      actionAreaLinkProps.to = emsContent.url;
    }
  } else {
    actionAreaLinkProps.className = classes.noCursor;
    actionAreaLinkProps.component = Box;
  }

  return (
    <Card className={classes.root} elevation={0} {...rest}>
      <StyledCardActionArea {...actionAreaLinkProps}>
        {emsContent.imageSrc &&
          (isVideo ? (
            <CardMedia
              className={cardMediaClassName}
              component={CardMediaVideo}
              videoSrc={emsContent.imageSrc}
            />
          ) : (
            <CardMedia
              className={cardMediaClassName}
              image={emsContent.imageSrc}
              title={emsContent.imageAlt}
            />
          ))}

        <CardContent className={cardContentClassName}>
          <Box className={classes.innerContent}>
            {isIconTopRight && (
              <StyledImage
                className={cx(classes.icon, iconClassName)}
                imageSrc={emsContent.iconSrc}
                imageAlt={emsContent.iconAlt}
              />
            )}
            {isIconBottomRight && (
              <div className={iconClassName}>
                <StyledImage
                  className={classes.icon}
                  imageSrc={emsContent.iconSrc}
                  imageAlt={emsContent.iconAlt}
                />
              </div>
            )}
            {emsContent.content}
          </Box>
          {isIconBottomLeft && (
            <Box className={classes.iconLeftHolder}>
              <StyledImage
                className={classes.icon}
                imageSrc={emsContent.iconSrc}
                imageAlt={emsContent.iconAlt}
              />
            </Box>
          )}
        </CardContent>
      </StyledCardActionArea>
    </Card>
  );
};

export default EmsContentCard;
