import { eFoodDB } from "pwa/db/efood.db";
import { CustomerProductList } from "pwa/db/types";
import productListsSyncTimestamp from "./productListsSyncTimestamp";

const saveCustomerProductLists = async (
  customerProductlists?: Array<CustomerProductList>,
  timestamp?: unknown
) => {
  if (customerProductlists?.length) {
    await eFoodDB.customerProductLists.bulkPut(customerProductlists);
  }

  await productListsSyncTimestamp.put(timestamp);
};

export default saveCustomerProductLists;
