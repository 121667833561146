// react
import React, { FC } from "react";

// mui
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

// types
import { Product } from "types/Product";
// components
import { TrackShowType } from "components/matomo";
import { tss } from "tss-react/mui";
import ProductTile from "../ProductTile";

interface Props {
  product: Product;
  refetch?: () => void;
  trackType?: TrackShowType;
}

const useStyles = tss.create(({ theme }) => ({
  tile: {
    textAlign: "center",
    height: "100%",
    padding: "0 4px",

    "&:hover": {
      background: theme.palette.grey[50],
    },

    "&:hover .productOptions": {
      background: theme.palette.grey[50],
    },
  },
  galleryRowGridItem: {
    "@media (max-width:1440px) and (min-width:959px)": {
      flexBasis: "33%",
      maxWidth: "33%",

      "&:nth-of-type(3n+1),&:nth-of-type(3n+2)": {
        borderRight: "dotted 3px #f2f3f1",
      },
    },
    "@media (min-width:859px) and (max-width:958px )": {
      flexBasis: "25%",
      maxWidth: "25%",

      "&:nth-of-type(4n+1),&:nth-of-type(4n+2),&:nth-of-type(4n+3)": {
        borderRight: "dotted 3px #f2f3f1",
      },
    },
    "@media (max-width:860px) and (min-width:670px)": {
      flexBasis: "33%",
      maxWidth: "33%",

      "&:nth-of-type(3n+1),&:nth-of-type(3n+2)": {
        borderRight: "dotted 3px #f2f3f1",
      },
    },
    "@media (max-width:670px) and (min-width:340px)": {
      maxWidth: "50%",
      flexBasis: "50%",

      "&:nth-of-type(2n+1)": {
        borderRight: "dotted 3px #f2f3f1",
      },
    },
    "@media (max-width:340px)": {
      maxWidth: "100%",
      flexBasis: "100%",
    },
    padding: "0px 8px!Important",
    margin: "12px 0",

    "@media (min-width:1440px)": {
      "&:nth-of-type(4n+1),&:nth-of-type(4n+2),&:nth-of-type(4n+3)": {
        borderRight: "dotted 3px #f2f3f1",
      },
    },
  },
  dottedBorder: {
    height: "2px",
    backgroundImage:
      "linear-gradient(90deg, transparent, transparent 50%, #fff 50%, #fff 100%), linear-gradient(90deg, #f2f3f1, #f2f3f1, #f2f3f1,#f2f3f1, #f2f3f1)",
    backgroundSize: "5px 4px, 100% 4px",
    border: "none",
    margin: "12px 0 0 0",
  },
}));

const ProductGalleryItem: FC<Props> = ({ product, refetch, trackType }) => {
  const { classes } = useStyles();

  return (
    <Grid xs={6} sm={3} className={classes.galleryRowGridItem}>
      <Box className={classes.tile}>
        <ProductTile
          product={product}
          trackType={trackType}
          refetch={refetch}
        />
      </Box>
      <hr className={classes.dottedBorder} />
    </Grid>
  );
};

export default ProductGalleryItem;
