import { AxiosError, AxiosResponse, isAxiosError } from "axios";
import { put, select } from "redux-saga/effects";
import * as ERRORS from "constants/errors";
import {
  HANDLE_SESSION_ERROR_ACTION,
  RESET_ERROR_SUCCESS_ACTION,
  VALIDATION_ERROR_ACTION,
  RESET_SESSION_POPUP_LOGON_ERROR_ACTION,
} from "_redux/actions/error";
import { LOGOUT_SUCCESS_ACTION } from "_redux/actions/user";
import { defaultStates } from "_redux/reducers/initStates";
import { sessionErrorSelector } from "_redux/selectors/error";
import { isCanceledError } from "_foundationExt/axios/axiosConfig";

let handlingError = false;

type ErrorData = {
  errorCode?: string;
  errorMessage?: string;
  code?: string;
  errors: {
    errorCode: string;
    errorKey: string;
  }[];
};

type Error = {
  isAxiosError: boolean;
  config: {
    url: string;
    method: string;
  };
  response: {
    status: number;
    data: ErrorData;
  };
};

const generatePayload = (error: AxiosError<ErrorData, any>) => {
  const errorResponse = error.response ?? <AxiosResponse<ErrorData, any>>{};

  if (errorResponse.data?.errors?.length) {
    const e = errorResponse.data.errors[0];

    // handle expired session
    if (
      e.errorCode === ERRORS.EXPIRED_ACTIVITY_TOKEN_ERROR ||
      e.errorKey === ERRORS.EXPIRED_ACTIVITY_TOKEN_ERROR ||
      e.errorCode === ERRORS.ACTIVITY_TOKEN_ERROR_CODE ||
      e.errorKey === ERRORS.ACTIVITY_TOKEN_ERROR_KEY ||
      e.errorCode === ERRORS.COOKIE_EXPIRED_ERROR_CODE ||
      e.errorKey === ERRORS.COOKIE_EXPIRED_ERROR_KEY
    ) {
      return {
        ...e,
        handled: false,
        errorTitleKey: "SessionError.TimeoutTitle",
        errorMsgKey: "SessionError.TimeoutMsg",
        logout: true,
      };
    }

    // handle invalid session where another user logged in from different location
    if (
      e.errorCode === ERRORS.INVALID_COOKIE_ERROR_CODE &&
      e.errorKey === ERRORS.INVALID_COOKIE_ERROR_KEY
    ) {
      //reset password dialog
      return {
        ...e,
        handled: false,
        errorTitleKey: "SessionError.InvalidTitle",
        errorMsgKey: "SessionError.InvalidMsg",
        logout: true,
      };
    }

    // handle password expired issue.
    if (
      e.errorCode === ERRORS.PASSWORD_EXPIRED_ERR_CODE ||
      e.errorKey === ERRORS.PASSWORD_EXPIRED
    ) {
      //reset password dialog
      return {
        ...e,
        handled: false,
        errorTitleKey: "reset.title",
        errorMsgKey: "reset.errorMsg",
        logout: true,
      };
    }

    if (
      e.errorCode === ERRORS.PARTIAL_AUTHENTICATION_ERROR_CODE ||
      e.errorKey === ERRORS.PARTIAL_AUTHENTICATION_ERROR_KEY
    ) {
      //reset password dialog
      return {
        ...e,
        handled: false,
        errorTitleKey: "SessionError.GenericTitle",
        errorMsgKey: "SessionError.PartialAuthError",
        logout: true,
      };
    }

    return {
      ...e,
      handled: false,
      errorTitleKey: "SessionError.GenericTitle",
      logout: false,
    };
  }

  if (
    errorResponse.status === 401 &&
    errorResponse.data?.code &&
    error?.config?.url?.startsWith("/search/resources")
  ) {
    // when search returns 401 without any error code/key, then handle as invalid session error
    const e = {
      errorKey: ERRORS.INVALID_COOKIE_ERROR_KEY,
      errorParameters: "",
      errorMessage:
        "CMN1039E: An invalid cookie was received for the user, your logonId may be in use by another user.",
      errorCode: ERRORS.INVALID_COOKIE_ERROR_CODE,
    };

    return {
      ...e,
      handled: false,
      errorTitleKey: "SessionError.InvalidTitle",
      errorMsgKey: "SessionError.InvalidMsg",
      logout: true,
    };
  }

  return {
    errorKey: error.code,
    errorCode: error.response?.status
      ? String(error.response?.status)
      : undefined,
    errorTitleKey: error.name,
    errorMessage: error.response?.statusText || error.toLocaleString() || "",
    handled: false,
    logout: false,
  };
};

export function* handleAxiosErrors(action: any) {
  const { payload: error }: { payload: Error } = action;

  if (isCanceledError(error)) {
    return;
  }

  if (isAxiosError(error) && !handlingError) {
    handlingError = true;
    const payload = generatePayload(error);

    yield put(HANDLE_SESSION_ERROR_ACTION(payload));
    if (payload.logout) {
      yield put(LOGOUT_SUCCESS_ACTION());
    }

    setTimeout(() => {
      if (handlingError) {
        handlingError = false;
      }
    }, 1000);
  }
}

export function* handleCMCSessionError() {
  const payload = {
    errorKey: ERRORS.CMC_SESSION_ERROR_KEY,
    handled: false,
    errorTitleKey: "SessionError.InvalidTitle",
    errorMsgKey: "SessionError.InvalidMsg",
  };
  yield put(HANDLE_SESSION_ERROR_ACTION(payload));
}

export function* resetError() {
  const sessionErrorObject = yield select(sessionErrorSelector);
  //do not reset session error
  if (!sessionErrorObject) {
    const sessionError = { ...defaultStates.error };
    yield put(RESET_ERROR_SUCCESS_ACTION(sessionError));
  } else {
    yield put(RESET_SESSION_POPUP_LOGON_ERROR_ACTION());
  }
}

export function* resetSessionError() {
  const sessionErrorObject = yield select(sessionErrorSelector);
  if (sessionErrorObject) {
    //reset session error
    const sessionError = { ...defaultStates.error };
    yield put(RESET_ERROR_SUCCESS_ACTION(sessionError));
  }
}

export function* handleValidationError(action: any) {
  const payload = action.payload;
  yield put(VALIDATION_ERROR_ACTION(payload));
}
