import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "_redux/hooks";
import { siteSelector } from "_redux/selectors/site";

import customerProductlistService from "_foundationExt/apis/transaction/customerProductlist.service";

import { Box } from "@mui/material";
import {
  StyledButton,
  StyledDialog,
  StyledDialogTitle,
  StyledDialogContent,
  StyledNotification,
  StyledProgress,
} from "components/StyledUI";

import { CustomerProductlist } from "types/CustomerProductlist";
import { ServerError } from "types/Error";

interface Props {
  closeDialog: () => void;
  showDialog: boolean;
  selectedCustomerProductList: CustomerProductlist;
  refetch?: () => void;
  setStorageSorting?: (storageSorting: string) => void;
}

const ReorderConfirmDialog: FC<Props> = ({
  closeDialog,
  showDialog,
  selectedCustomerProductList,
  refetch,
  setStorageSorting,
}) => {
  const { t } = useTranslation();
  const site = useAppSelector(siteSelector);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ServerError | null>(null);

  const handleClose = () => {
    setError(null);
    closeDialog();
  };

  const handleConfirm = () => {
    setError(null);
    setLoading(true);

    customerProductlistService
      .reorganizeStockList({
        storeId: site ? site.storeID : "",
        stocklistId: selectedCustomerProductList?.id || "",
      })
      .then(() => {
        if (setStorageSorting) setStorageSorting("ascending");
        if (refetch) refetch();

        closeDialog();
      })
      .catch((e) => {
        setError(e.response?.data.errors[0]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <StyledDialog open={showDialog} onClose={handleClose}>
      <StyledDialogTitle id="dialog-title" onClose={handleClose}>
        {t("myAccount.listManagement.reorder")}
      </StyledDialogTitle>

      <StyledDialogContent>
        {loading && <StyledProgress />}
        {error && error.errorMessage && (
          <Box my={3}>
            <StyledNotification severity="error">
              {error.errorMessage}
            </StyledNotification>
          </Box>
        )}

        <Box>{t("myAccount.listManagement.reorderHint")}</Box>

        <Box mt={2}>
          <StyledButton fullWidth onClick={handleConfirm}>
            {t("action.continue")}
          </StyledButton>
        </Box>

        <Box mt={2}>
          <StyledButton fullWidth variant="outlined" onClick={handleClose}>
            {t("action.abort")}
          </StyledButton>
        </Box>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default React.memo(ReorderConfirmDialog);
