// react
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ROUTES } from "constants/routes";
// redux
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import * as orderActions from "_redux/actions/order";
import * as userActions from "_redux/actions/user";

// foundation
import cartService from "_foundation/apis/transaction/cart.service";
import { useSite } from "_foundationExt/hooks/useSite";
// components
import { StyledButton, StyledTooltip } from "components/StyledUI";
import {
  hasOrderErrorSelector,
  orderSelector,
  selectAllOrderItemsSorted,
} from "_redux/selectors/order";
import ProgressDialog from "components/progressDialog";
import {
  trackCart,
  useMatomo,
  useTrackPredictionOnCart,
} from "components/matomo";
import { useNavigate } from "react-router-dom";
import { selectCurrentOrganizationId } from "_redux/selectors/user";
import { useOnlineStatus } from "_foundationExt/hooks/useOnlineStatus";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  button: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const OrderNowButton: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { currentSite } = useSite();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isOffline } = useOnlineStatus();

  const hasOrderError = useAppSelector(hasOrderErrorSelector);
  const organizationId = useAppSelector(selectCurrentOrganizationId);

  const orderitems = useAppSelector(selectAllOrderItemsSorted);
  const order = useAppSelector(orderSelector);
  const matomo = useMatomo();

  const { trackPrediction } = useTrackPredictionOnCart();

  const [showProgressDialog, setShowProgressDialog] = useState<boolean>(false);

  const reloadCart = useCallback(
    (orderId: string) => {
      const payload = {
        storeId: currentSite?.storeID,
        activeOrganizationId: organizationId,
      };
      dispatch(orderActions.FETCHING_CART_ACTION(payload));
      dispatch(userActions.RELOAD_ORDER_HISTORY_ACTION(payload));
      navigate(`${ROUTES.ORDER_CONFIRMATION}/${orderId}`);
    },
    [currentSite?.storeID, dispatch, navigate, organizationId]
  );

  const preCheckout = useCallback(() => {
    const params1 = {
      body: {
        storeId: currentSite?.storeID,
      },
    };

    const params2 = (currentOrderId) => ({
      body: {
        orderId: currentOrderId,
        notifyMerchant: "1",
        notifyShopper: "1",
        notifyOrderSubmitted: "0",
        storeId: currentSite?.storeID,
        catalogId: currentSite?.catalogID,
        langId: currentSite?.defaultLanguageID,
        purchaseorder_id: "",
      },
    });

    setShowProgressDialog(true);

    cartService.getCart({}).then((cart) => {
      if (cart.data.switchTimeExpired) {
        setShowProgressDialog(false);
        dispatch(orderActions.FETCHING_CART_ACTION({}));
      } else {
        cartService
          .preCheckout(params1)
          .then((res) => {
            const { orderId } = res.data;
            if (orderId != null) {
              cartService
                .checkOut(params2(orderId))
                .then((executedOrderId) => {
                  trackPrediction();
                  trackCart(orderitems, order, orderId, matomo);
                  reloadCart(executedOrderId.data.orderId);
                })
                .catch((checkoutError) => {
                  setShowProgressDialog(false);
                  dispatch(
                    orderActions.CART_CHECKOUT_ERROR_ACTION(checkoutError)
                  );
                });
            } else {
              setShowProgressDialog(false);
            }
          })
          .catch((preCheckoutError) => {
            setShowProgressDialog(false);
            dispatch(
              orderActions.CART_PRECHECKOUT_ERROR_ACTION(preCheckoutError)
            );
          });
      }
    });
  }, [
    currentSite?.catalogID,
    currentSite?.defaultLanguageID,
    currentSite?.storeID,
    dispatch,
    matomo,
    order,
    orderitems,
    reloadCart,
    trackPrediction,
  ]);

  return (
    <>
      <StyledTooltip title={hasOrderError ? t("cart.tooltipText") : ""}>
        <StyledButton
          className={classes.button}
          disabled={isOffline || hasOrderError}
          onClick={preCheckout}>
          {t("cart.orderNow")}
        </StyledButton>
      </StyledTooltip>

      <ProgressDialog
        closeDialog={() => setShowProgressDialog(false)}
        showDialog={showProgressDialog}
        message={t("cart.progressMessage")}
        closable={false}
      />
    </>
  );
};

export default OrderNowButton;
