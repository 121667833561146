import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  StyledButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  StyledDialogActions,
} from "components/StyledUI";
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import { FETCHING_CART_ACTION } from "_redux/actions/order";
import { ACCEPT_PRICE_CHANGES_ACTION } from "_redux/actions/user";
import { userGetPriceChanges } from "_redux/selectors/user";
import { formatCurrency } from "tools/stringUtils";
import { ROUTES } from "constants/routes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  dialog: {
    "& .MuiPaper-root": {
      maxWidth: "900px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        margin: "0",
      },
    },
  },
  table: {
    "& .MuiTableHead-root": {
      backgroundColor: "#F2F3F1",
      hyphens: "auto",
    },
    "& .MuiTableBody-root": {
      backgroundColor: "#F9F9F8",
      "& > .MuiTableRow-root:nth-of-type(odd)": {
        backgroundColor: "#FFFFFF",
      },
    },
    "& .MuiTableCell-root": {
      border: "none",
      padding: theme.spacing(1),
    },
  },
  button: {
    width: "49%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  underXS: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mr: {
    marginRight: "2%",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0",
      marginBottom: theme.spacing(1),
    },
  },
}));

const PriceChange: FC = () => {
  const dispatch = useAppDispatch();
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const priceChanges = useAppSelector(userGetPriceChanges);
  const [showPriceChanges, setShowPriceChanges] = useState(false);
  const closeDialog = () => {
    dispatch(ACCEPT_PRICE_CHANGES_ACTION({}));
    setShowPriceChanges(false);
  };

  const goToCart = () => {
    closeDialog();
    dispatch(FETCHING_CART_ACTION({}));
    navigate(ROUTES.CART);
  };

  useEffect(() => {
    if (priceChanges) setShowPriceChanges(true);
  }, [priceChanges, showPriceChanges]);

  if (priceChanges && Object.keys(priceChanges).length > 0) {
    return (
      <StyledDialog
        open={showPriceChanges}
        onClose={closeDialog}
        className={classes.dialog}>
        <StyledDialogTitle id="price-change-dialog" onClose={closeDialog}>
          <Box>{t("cart.priceChanges.headline")}</Box>
        </StyledDialogTitle>

        <StyledDialogContent>
          <Box mb={3}>{t("cart.priceChanges.desc")}</Box>

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: 75 }} className={classes.underXS}>
                  <Typography variant="h4">
                    {t("product.partnumber")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4">
                    {t("myAccount.listManagement.modalStandardQuantityColumn1")}
                  </Typography>
                </TableCell>
                <TableCell style={{ maxWidth: 100 }}>
                  <Typography variant="h4">
                    {t("cart.priceChanges.oldPrice")}
                  </Typography>
                </TableCell>
                <TableCell style={{ maxWidth: 100 }}>
                  <Typography variant="h4">
                    {t("cart.priceChanges.newPrice")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(priceChanges).map((key) => (
                <TableRow>
                  <TableCell
                    style={{ minWidth: 75 }}
                    className={classes.underXS}>
                    <Typography variant="body2">{key}</Typography>{" "}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {priceChanges[key].name}
                    </Typography>{" "}
                  </TableCell>
                  <TableCell style={{ maxWidth: 120 }}>
                    <Typography variant="body2">
                      {priceChanges[key].unit}
                      <br />
                      {formatCurrency(priceChanges[key].oldPrice)}
                    </Typography>{" "}
                  </TableCell>
                  <TableCell style={{ maxWidth: 120 }}>
                    <Typography variant="body2">
                      {priceChanges[key].unit}
                      <br />
                      {formatCurrency(priceChanges[key].newPrice)}
                    </Typography>{" "}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton
            variant="outlined"
            onClick={goToCart}
            className={cx(classes.button, classes.mr)}>
            {t("cart.gotocart")}
          </StyledButton>
          <StyledButton onClick={closeDialog} className={classes.button}>
            {t("action.close")}
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
    );
  }

  return null;
};

export default PriceChange;
