import React, {
  FC,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "_redux/hooks";
import {
  StyledDialog,
  StyledDialogTitle,
  StyledDialogContent,
  StyledButton,
  StyledProgress,
} from "components/StyledUI";

import { Reward, RewardOption } from "types/Order";
import { FETCHING_CART_ACTION } from "_redux/actions/order";
import { Product } from "types/Product";
import { cloneDeep } from "lodash-es";
import { useSite } from "_foundation/hooks/useSite";
import {
  getCombinedRewardOptions,
  getNumberOfSelectedProducts,
  getProductsFromRewards,
  useFetchProducts,
  useUpdateFreeProductSelection,
} from "./CartFreeProduct.helper";
import CartFreeProductDialogItem from "./CartFreeProductDialogItem";
import CartFreeProductError, { ErrorTypes } from "./CartFreeProductError";

interface RewardOptionError {
  rewardOptionId: string;
  errorType: ErrorTypes;
}

interface CartFreeProductDialogProps {
  showDialog: boolean;
  closeDialog: () => void;
  rewards: Reward[];
  orderId: string;
  rewardOptionIdsNotHandled: string[];
}

const CartFreeProductDialog: FC<CartFreeProductDialogProps> = ({
  showDialog,
  closeDialog,
  rewards,
  orderId,
  rewardOptionIdsNotHandled,
}) => {
  const [applying, setApplying] = useState(false);
  const [rewardOptionsErrors, setRewardOptionsErrors] = useState<
    RewardOptionError[]
  >([]);

  // holds the Ids of the rewards wich was automatically selected on initial dialog call
  const rewardsInitSelectIdList: string[] = useMemo(() => [], []);

  // holds the maximum quantity of gifts wich can be selected per reward
  const freeGiftsMaximumQuantityList: Map<string, number> = useMemo(() => {
    const freeGiftsMaximumQuantityListTmp = new Map<string, number>();
    rewards?.forEach((reward) => {
      const maximumQuantity =
        reward.rewardSpecification?.giftSetSpecification?.maximumQuantity
          ?.value;
      freeGiftsMaximumQuantityListTmp.set(reward.code, maximumQuantity);
    });
    return freeGiftsMaximumQuantityListTmp;
  }, [rewards]);

  // includes all gift products wich are selected in the dialog
  const [selectedFreeProducts, setSelectedFreeProducts] = useState<
    Map<string, string[]>
  >(() => {
    const initalSelectedFreeProducts = new Map<string, string[]>();
    rewards?.forEach((reward) => {
      reward.rewardOptions.forEach((option) =>
        initalSelectedFreeProducts.set(
          option.rewardOptionId,
          option.rewardChoice.giftSet.giftItem.map((item) => item.uniqueID)
        )
      );
    });
    return initalSelectedFreeProducts;
  });

  const { freeProducts } = useFetchProducts(rewards);
  const freeProductsRef = useRef(freeProducts);

  const { updateFreeProductSelection } = useUpdateFreeProductSelection(
    orderId,
    rewards
  );

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { currentSite } = useSite();

  const changeSelection = (
    reward: Reward,
    rewardOptions: RewardOption[],
    freeProductUniqueID: string,
    changeStatus: "CHECKED" | "UNCHECKED"
  ) => {
    const firstRewardOption = rewardOptions[0];
    const selectedFreeProductsClone = new Map<string, string[]>(
      selectedFreeProducts
    );
    if (changeStatus === "CHECKED") {
      const maximumSelections =
        reward.rewardSpecification.giftSetSpecification.maximumQuantity.value;
      const currentSelections =
        selectedFreeProducts.get(firstRewardOption.rewardOptionId)?.length || 0;
      if (currentSelections >= maximumSelections) {
        if (
          rewardOptionsErrors.some(
            (rewardOptionsError) =>
              rewardOptionsError.rewardOptionId ===
              firstRewardOption.rewardOptionId
          )
        ) {
          /* eslint-disable indent */
          setRewardOptionsErrors(
            rewardOptionsErrors.map((rewardOptionsError) =>
              rewardOptionsError.rewardOptionId ===
              firstRewardOption.rewardOptionId
                ? {
                    rewardOptionId: firstRewardOption.rewardOptionId,
                    errorType: "MAX_SELECTION_ERROR",
                  }
                : rewardOptionsError
            )
            /* eslint-enable indent */
          );
        } else {
          setRewardOptionsErrors([
            ...rewardOptionsErrors,
            {
              rewardOptionId: firstRewardOption.rewardOptionId,
              errorType: "MAX_SELECTION_ERROR",
            },
          ]);
        }
      }

      rewardOptions.forEach((rewardOption) => {
        const freeProductsForRewardOption =
          selectedFreeProductsClone.get(rewardOption.rewardOptionId) || [];

        const updatedFreeProductsForRewardOption = [
          ...freeProductsForRewardOption,
          freeProductUniqueID,
        ];

        selectedFreeProductsClone.set(
          rewardOption.rewardOptionId,
          updatedFreeProductsForRewardOption
        );
      });
    } else {
      rewardOptions.forEach((rewardOption) => {
        const filteredFreeProducts =
          selectedFreeProducts
            ?.get(rewardOption.rewardOptionId)
            ?.filter((uniqueId) => uniqueId !== freeProductUniqueID) || [];

        selectedFreeProductsClone.set(
          rewardOption.rewardOptionId,
          filteredFreeProducts
        );
      });

      setRewardOptionsErrors(
        rewardOptionsErrors.filter(
          (rewardOptionsError) =>
            rewardOptionsError.rewardOptionId !==
            firstRewardOption.rewardOptionId
        )
      );
    }

    setSelectedFreeProducts(selectedFreeProductsClone);
  };

  const handleClose = () => {
    closeDialog();
    setRewardOptionsErrors(
      rewardOptionsErrors.filter(
        (rewardOptionsError) => rewardOptionsError.rewardOptionId !== "0"
      )
    );
  };

  const handleApplyClick = async () => {
    setApplying(true);
    updateFreeProductSelection(selectedFreeProducts)
      .then((results) => {
        const resultSuccess = results.reduce(
          (success, result) => success && result,
          true
        );

        if (resultSuccess) {
          handleClose();
          dispatch(FETCHING_CART_ACTION({}));
        } else {
          setRewardOptionsErrors([
            { rewardOptionId: "0", errorType: "FETCH_ERROR" },
          ]);
        }
      })
      .finally(() => setApplying(false));
  };

  /**
   * Checks whether the maximum number of selectable products per reward has already been reached.
   * If it has not, the number of initially selected products per reward is stored and returned
   * so that the product can be selected.
   *
   * @param combinedRewardOption
   * @param freeProductsMap
   * @param rewardOptionIdIsNotHandled
   * @param rewardCode
   * @returns
   */
  const isInitialSelect = useCallback(
    (
      combinedRewardOption: RewardOption[],
      freeProductsMap: Map<string, Product[]>,
      rewardOptionIdIsNotHandled: boolean,
      rewardCode: string
    ): boolean => {
      let returnValue = false;
      const combinedRewardOptionId = combinedRewardOption[0].rewardOptionId;

      let numberOfFreeProductsInitialToSelect: number | undefined;

      numberOfFreeProductsInitialToSelect =
        freeGiftsMaximumQuantityList.get(rewardCode);

      if (
        freeProductsMap &&
        freeProductsMap.size > 0 &&
        combinedRewardOption &&
        combinedRewardOption.length > 0 &&
        combinedRewardOptionId
      ) {
        const products = freeProductsMap.get(combinedRewardOptionId);
        if (products && products.length > 0) {
          if (
            numberOfFreeProductsInitialToSelect &&
            numberOfFreeProductsInitialToSelect > 0 &&
            rewardOptionIdIsNotHandled
          ) {
            numberOfFreeProductsInitialToSelect -= 1;
            freeGiftsMaximumQuantityList.set(
              rewardCode,
              numberOfFreeProductsInitialToSelect
            );
            returnValue = true;
          }
        }
      } else {
        returnValue = false;
      }
      return returnValue;
    },
    [freeGiftsMaximumQuantityList]
  );

  const reloadFreeProducts = useCallback(async () => {
    if (currentSite?.storeID) {
      freeProductsRef.current = await getProductsFromRewards(
        rewards,
        currentSite.storeID
      );
    }
  }, [currentSite?.storeID, rewards]);

  useEffect(() => {
    // check if a reward was removed
    if (freeProducts.size > rewards.length) {
      const selectedFreeProductMap = new Map<string, string[]>();

      // clear rewardsInitSelectIdList array
      rewardsInitSelectIdList.length = 0;
      // build new selectedFreeProducts
      rewards.forEach((reward) => {
        rewardsInitSelectIdList.push(reward.code);
        reward.rewardOptions.forEach((option) =>
          selectedFreeProductMap.set(
            option.rewardOptionId,
            option.rewardChoice.giftSet.giftItem.map((item) => item.uniqueID)
          )
        );
      });

      setSelectedFreeProducts(selectedFreeProductMap);
      reloadFreeProducts();
    }
  }, [freeProducts.size, reloadFreeProducts, rewards, rewardsInitSelectIdList]);

  useEffect(() => {
    // freeProducts contains all gift products per rewardId (Map<string, Product[]>). This map is filled asynchronously and only contains
    // the product_ids later. useEffect(), however, is called earlier. That is why we check
    // here whether freeProducts contains something.
    if (freeProducts.size > 0) {
      // we clone the selectedFreeProducts because direct change of selectedFreeProducts is not possible
      const selectedFreeProductMap = cloneDeep(selectedFreeProducts);
      let selectedFreeProductMapHasChanged = false;

      /* 
        If the number of reward - product maps contained in freeProducts is not equal to the number of rewards, 
        we have to update the freeProducts again. This happens if, for example, you redeem a voucher 
        and already get discounts elsewhere. 
       */
      if (freeProducts.size !== rewards.length) {
        reloadFreeProducts();
        return;
      }

      rewards.forEach((reward) => {
        // We check whether the reward has already been selected initially.
        if (!rewardsInitSelectIdList.includes(reward.code)) {
          getCombinedRewardOptions(reward).forEach(
            async (combinedRewardOption) => {
              const combinedRewardOptionId =
                combinedRewardOption[0].rewardOptionId;
              // rewardOptionIdsNotHandled contains a list of rewardOptions which have not yet been handled.
              // This is filled in CartFreeProduct.
              if (
                combinedRewardOptionId &&
                (rewardOptionIdsNotHandled.length === 0 ||
                  rewardOptionIdsNotHandled.includes(combinedRewardOptionId))
              ) {
                if (freeProducts.has(combinedRewardOptionId)) {
                  const freeProductsList = freeProducts.get(
                    combinedRewardOptionId
                  );
                  freeProductsList?.forEach((freeProduct) => {
                    // we check, for every freeProduct, if it can be selected
                    const toSelect = isInitialSelect(
                      combinedRewardOption,
                      freeProducts,
                      rewardOptionIdsNotHandled.includes(
                        combinedRewardOptionId
                      ),
                      reward.code
                    );
                    // if we can select the product, we save it in selectedFreeProductMap.
                    // The content of selectedFreeProductsMap is later stored in selectedFreeProducts.
                    if (toSelect) {
                      // we check if the product was earlier selected
                      let alwaysSelectedfreeProducts =
                        selectedFreeProductMap.get(combinedRewardOptionId);
                      if (!alwaysSelectedfreeProducts) {
                        alwaysSelectedfreeProducts = [];
                      }
                      if (
                        !alwaysSelectedfreeProducts.includes(
                          freeProduct.uniqueID
                        )
                      ) {
                        alwaysSelectedfreeProducts.push(freeProduct.uniqueID);
                        selectedFreeProductMap.set(
                          combinedRewardOptionId,
                          alwaysSelectedfreeProducts
                        );
                        selectedFreeProductMapHasChanged = true;
                      }
                    }
                  });
                }
                // save that we have always initial handled the reward
                if (!rewardsInitSelectIdList.includes(reward.code)) {
                  rewardsInitSelectIdList.push(reward.code);
                }
              }
            }
          );
        }
      });
      // now we store the initial selected products in SelectedFreeProducts
      if (selectedFreeProductMapHasChanged) {
        setSelectedFreeProducts(selectedFreeProductMap);
      }
    }
  }, [
    freeProducts,
    isInitialSelect,
    reloadFreeProducts,
    rewardOptionIdsNotHandled,
    rewards,
    rewardsInitSelectIdList,
    selectedFreeProducts,
  ]);

  return (
    <StyledDialog onClose={handleClose} open={showDialog}>
      <StyledDialogTitle id="dialog-title" onClose={handleClose}>
        {t("cart.freeProduct.selectFreeProduct")}
      </StyledDialogTitle>

      <StyledDialogContent>
        {showDialog
          ? rewards.map((reward) =>
              getCombinedRewardOptions(reward).map((combinedRewardOption) =>
                rewardOptionIdsNotHandled.length === 0 ||
                rewardOptionIdsNotHandled.includes(
                  combinedRewardOption[0].rewardOptionId
                ) ? (
                  <>
                    <Typography variant="h2">{reward.title}</Typography>
                    <Box mb={2}>
                      {t("cart.freeProduct.pleaseSelectProduct")}
                    </Box>
                    <CartFreeProductError
                      errorType={
                        rewardOptionsErrors.find(
                          (rewardOptionsError) =>
                            rewardOptionsError.rewardOptionId ===
                            combinedRewardOption[0].rewardOptionId
                        )?.errorType
                      }
                    />

                    {freeProducts
                      .get(combinedRewardOption[0].rewardOptionId)
                      ?.map((freeProduct) => (
                        <CartFreeProductDialogItem
                          key={freeProduct.uniqueID}
                          reward={reward}
                          rewardOptions={combinedRewardOption}
                          freeProduct={freeProduct}
                          selectedFreeProducts={
                            selectedFreeProducts.get(
                              combinedRewardOption[0].rewardOptionId
                            ) || []
                          }
                          changeSelection={changeSelection}
                        />
                      ))}

                    <Box mb={2}>
                      {t("cart.freeProduct.amountSelectedFreeProducts", {
                        amount: getNumberOfSelectedProducts(
                          reward,
                          selectedFreeProducts.get(
                            combinedRewardOption[0].rewardOptionId
                          )
                        ),
                      })}
                    </Box>
                  </>
                ) : null
              )
            )
          : null}
        <CartFreeProductError
          errorType={
            rewardOptionsErrors.find(
              (rewardOptionsError) => rewardOptionsError.rewardOptionId === "0"
            )?.errorType
          }
        />
        <Box display="flex">
          <Box m="auto">{applying && <StyledProgress />}</Box>
        </Box>
        <Grid container>
          <Grid xs={12}>
            <Box mt={1} mb={1}>
              <StyledButton
                variant="contained"
                onClick={() => handleApplyClick()}
                fullWidth
                disabled={applying || rewardOptionsErrors.length > 0}>
                {t("action.apply")}
              </StyledButton>
            </Box>
          </Grid>
          <Grid xs={12}>
            <StyledButton
              variant="outlined"
              onClick={() => handleClose()}
              fullWidth>
              {t("action.abort")}
            </StyledButton>
          </Grid>
        </Grid>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default CartFreeProductDialog;
