import { RootReducerState } from "../reducers";
import { Validation } from "types/Order";
import { BASKET_TYPE } from "constants/order";
import { efoodSelector } from "./site";
import { createSelector } from "@reduxjs/toolkit";

const EMPTY_ARRAY = [];

type BasketValidationType = { key: string; validation: Validation };
const isBasketValidationType = (
  item?: BasketValidationType
): item is BasketValidationType => !!item;

export const orderSelector = (state: RootReducerState) => state.order;

export const orderIdSelector = (state: RootReducerState) => state.order.id;

export const orderApprovalsSelector = (state: RootReducerState) =>
  state.order.approvals ?? EMPTY_ARRAY;

export const promotionCodeSelector = (state: RootReducerState) =>
  state.order.promotionCode ?? EMPTY_ARRAY;

export const rewardsSelector = (state: RootReducerState) =>
  state.order.rewards ?? EMPTY_ARRAY;

export const orderTotalSelector = (state: RootReducerState) =>
  state.order.total;

export const orderTotalAdjustmentSelector = (state: RootReducerState) =>
  state.order.totalAdjustment;

export const orderTotalProductSelector = (state: RootReducerState) =>
  state.order.totalProduct;

export const orderTotalShippingSelector = (state: RootReducerState) =>
  state.order.totalShipping;

export const basketSwitchTimeExpiredSelector = (state: RootReducerState) =>
  state.order.switchTimeExpired;

export const numItemsSelector = (state: RootReducerState) =>
  state.order.numItems;

export const shipInfosSelector = (state: RootReducerState) =>
  state.order.shipInfos;

export const isFetchingSelector = (state: RootReducerState) =>
  state.order.isFetching;

export const selectAllBaskets = (state: RootReducerState) =>
  state.order.baskets ?? EMPTY_ARRAY;

export const selectHasBaskets = (state: RootReducerState) =>
  selectAllBaskets(state).length > 0;

export const selectBasketValidations = createSelector(
  [selectAllBaskets],
  (baskets) => {
    return baskets
      .filter(Boolean)
      .map((basket) => {
        const {
          validation,
          identifier: { type, dropShipType, deliveryDate },
        } = basket;

        if (!validation || type === BASKET_TYPE.OPTAIN) {
          return undefined;
        }

        const deliveryDateDate =
          deliveryDate?.date != null
            ? new Date(deliveryDate.date).getTime()
            : 0;

        return {
          key: `validation_${type}_${dropShipType}_${deliveryDateDate}`,
          validation,
        };
      })
      .filter(isBasketValidationType);
  }
);

export const selectAllSubBaskets = createSelector(
  [selectAllBaskets],
  (baskets) =>
    baskets.flatMap((basket) => {
      return basket.subBaskets;
    })
);

export const selectAllOrderItems = createSelector(
  [selectAllSubBaskets],
  (subBaskets) => {
    return subBaskets.flatMap((subBasket) => subBasket.orderitems);
  }
);

export const selectAllOrderItemsSorted = createSelector(
  [selectAllOrderItems],
  (orderItems) => {
    return [...orderItems].sort((orderItem1, orderItem2) => {
      return new Date(orderItem1.createDate).getTime() <
        new Date(orderItem2.createDate).getTime()
        ? 1
        : -1;
    });
  }
);

// create selector to filter order items by partnumber and free gift flag
export const makeSelectOrderItemByPartNumberAndFreeGift = (
  partNumber: string,
  isFreeGift: boolean
) => {
  const selectOrderItemByPartNumberAndFreeGift = createSelector(
    [selectAllOrderItems],
    (orderItems) => {
      return orderItems
        .filter(
          (item) =>
            item?.article?.partNumber &&
            item.article.partNumber === partNumber &&
            item.isFreeGift === isFreeGift
        )
        .find(Boolean);
    }
  );

  return selectOrderItemByPartNumberAndFreeGift;
};

export const selectOrderItemsWithPendingAvailability = createSelector(
  [selectAllOrderItems],
  (orderItems) =>
    orderItems.filter((orderItem) => orderItem?.availability?.pending)
);

export const selectPartNumbersOfAllOrderItems = createSelector(
  [selectAllOrderItems],
  (orderItems) =>
    orderItems.map((orderItem) => orderItem.article.eFoodPartnumber)
);

export const hasOrderErrorSelector = (state: RootReducerState) => {
  const { dropShipping } = efoodSelector(state);
  const baskets = selectAllBaskets(state);
  const approvals = orderApprovalsSelector(state);
  const promotionCode = promotionCodeSelector(state);

  const dropShippingCommentRequired = dropShipping
    .filter((item) => item.commentRequired === true)
    .map((result) => result.type);

  if (
    baskets.some(
      (basket) =>
        (basket.identifier.type === BASKET_TYPE.STOCK_OPTAIN &&
          basket.validation &&
          basket.validation.type === "DENIAL") ||
        !basket.identifier.deliveryDate ||
        (basket.identifier.dropShipType &&
          dropShippingCommentRequired.indexOf(basket.identifier.dropShipType) >
            -1 &&
          (basket.comment === "" || basket.comment === null))
    ) ||
    approvals.some(
      (approval) =>
        approval.basketIdentifier.type === BASKET_TYPE.STOCK_OPTAIN &&
        approval.approvalStatus === "Denial"
    ) ||
    promotionCode.some((promotionCode) => !promotionCode.active)
  ) {
    return true;
  }

  return false;
};
