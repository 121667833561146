import { format } from "date-fns";
import { de } from "date-fns/locale/de";

export function truncate(str: string, length: number): string {
  return str.length > length ? `${str.substring(0, length - 3)}...` : str;
}

export function formatCurrency(str?: number): string {
  return (
    str?.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) || ""
  );
}

export function formatDateTime(date: string): string {
  const dateToConvert = new Date(parseInt(date, 10));

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  } as const;

  return dateToConvert.toLocaleDateString("de-DE", options);
}

function formatDate(date: string, options): string {
  if (!date) {
    return "";
  }

  const dateToConvert = new Date(date);

  return dateToConvert.toLocaleDateString("de-DE", options);
}

export function formatLongDate(date: string): string {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  } as const;

  return formatDate(date, options);
}

export function formatShortDate(date: string): string {
  const options = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  } as const;

  return formatDate(date, options);
}

export function formatDateLocal(
  date: Date,
  pattern?: string | undefined
): string | null {
  const defaultPattern = "EEEE, dd.MM.yyyy";

  if (!date) return "";

  return format(new Date(date), pattern || defaultPattern, {
    locale: de,
  });
}

/**
 * Levenshtein distance > compaire two strings
 * return [0...1] > 1 strings are equal
 */
export function compareTwoStrings(f1, f2) {
  const first = f1.replace(/\s+/g, "");
  const second = f2.replace(/\s+/g, "");
  if (first === second) return 1;
  if (first.length < 2 || second.length < 2) return 0;
  const firstBigrams = new Map();
  for (let i = 0; i < first.length - 1; i++) {
    const bigram = first.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;
    firstBigrams.set(bigram, count);
  }
  let intersectionSize = 0;
  for (let i = 0; i < second.length - 1; i++) {
    const bigram = second.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;
    if (count > 0) {
      firstBigrams.set(bigram, count - 1);
      intersectionSize += 1;
    }
  }
  return (2.0 * intersectionSize) / (first.length + second.length - 2);
}
function areArgsValid(mainString, targetStrings) {
  if (typeof mainString !== "string") return false;
  if (!Array.isArray(targetStrings)) return false;
  if (!targetStrings.length) return false;
  if (targetStrings.find((s) => typeof s !== "string")) return false;
  return true;
}
export function findBestMatch(mainString, targetStrings) {
  if (!areArgsValid(mainString, targetStrings))
    throw new Error(
      "Bad arguments: First argument should be a string, second should be an array of strings"
    );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ratings: any[] = [];
  let bestMatchIndex = 0;
  for (let i = 0; i < targetStrings.length; i++) {
    const currentTargetString = targetStrings[i];
    const currentRating = compareTwoStrings(mainString, currentTargetString);
    ratings.push({ target: currentTargetString, rating: currentRating });
    if (currentRating > ratings[bestMatchIndex].rating) {
      bestMatchIndex = i;
    }
  }
  const bestMatch = ratings[bestMatchIndex];
  return { ratings, bestMatch, bestMatchIndex };
}
