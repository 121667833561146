import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { tss } from "tss-react/mui";

interface Props {
  widgets: React.ReactElement[];
}

const useStyles = tss.create(({ theme }) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "-8px",
    },
    "& .MuiPaper-root": {
      [theme.breakpoints.up("sm")]: {
        height: "246px",
      },
    },
    "& .blackbox": {
      "& .MuiPaper-root": {
        height: "516px",
        border: "none",
      },
    },
  },
  top: {
    // added because there is an error in chromium at a zoom of 110%,
    // so that the last box slides down one line
    [theme.breakpoints.up("lg")]: {
      flexWrap: "nowrap",
    },
    [theme.breakpoints.down("sm")]: {
      order: "1 !important",
    },
  },
  blackbox: {
    [theme.breakpoints.down("sm")]: {
      order: "2 !important",
    },
  },
}));

const WidgetLayout: React.FC<Props> = ({ widgets }) => {
  const { classes } = useStyles();
  const theme = useTheme();

  const xs = useMediaQuery(theme.breakpoints.up("xs"));
  const sm = useMediaQuery(theme.breakpoints.up("sm")); // 2
  const md = useMediaQuery(theme.breakpoints.up("md")); // 4
  const lg = useMediaQuery(theme.breakpoints.up("lg")); // 6

  let count = 1;
  if (xs && sm && !md && !lg) count = 2;
  if (xs && sm && md && !lg) count = 4;
  if (xs && sm && md && lg) count = 6;

  const sectionleft = widgets
    .filter((item, index) => index <= count && count !== 1 && index !== 0)
    .map((item) => (
      <Grid xs={6} sm={12} md={6} lg={4} key={`${item.key}_left_widgets`}>
        {item}
      </Grid>
    ));

  const sectionbottom = widgets
    .filter((item, index) => (index > count || count === 1) && index !== 0)
    .map((item) => (
      <Grid xs={6} sm={6} md={4} lg={4} key={`${item.key}_bottom_widgets`}>
        {item}
      </Grid>
    ));

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.top} spacing={sm ? 3 : 2}>
        {sectionleft && sectionleft.length > 0 && (
          <Grid container xs={12} sm={6} md={8} lg={9}>
            {sectionleft}
          </Grid>
        )}
        <Grid
          className={classes.blackbox}
          container
          xs={12}
          sm={6}
          md={4}
          lg={3}
          spacing={0}>
          {widgets[0]}
        </Grid>
        {sectionbottom && sectionbottom.length > 0 && (
          <Grid container xs={12}>
            {sectionbottom}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default WidgetLayout;
