import React, { FC } from "react";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useAppSelector } from "_redux/hooks";
import { userSelectedProductlistFilterSelector } from "_redux/selectors/user";
import { usePagination } from "_foundationExt/hooks";
import ProductList from "./ProductList";
import { useProductList } from "./useProductList";
import useHeadline from "./useHeadline";
import BreadcrumbSection from "./BreadcrumbSection";

interface ProductListContainerProps {
  categoryId: string;
}

const ProductListContainer: FC<ProductListContainerProps> = ({
  categoryId,
}) => {
  const selectedProductlistFilter = useAppSelector(
    userSelectedProductlistFilterSelector
  );

  const { pageSize, setPageSize, page, setPage } = usePagination();

  const { breadcrumbData, error, loadingState, refetch, searchResult } =
    useProductList(categoryId, pageSize, page, selectedProductlistFilter);

  const headline = useHeadline(
    null,
    null,
    null,
    breadcrumbData,
    false,
    searchResult.totalNumberOfProducts,
    page,
    pageSize
  );

  if (error) {
    return (
      <Navigate
        to={{
          pathname: "/error",
        }}
        state={{ errorMessage: "Couldn't load the products" }}
        replace
      />
    );
  }

  return (
    <>
      {categoryId && breadcrumbData && breadcrumbData.length > 0 && (
        <Helmet>
          <title>{breadcrumbData[breadcrumbData.length - 1].label}</title>
        </Helmet>
      )}
      <BreadcrumbSection breadcrumbData={breadcrumbData} />
      <ProductList
        searchResult={searchResult}
        categoryId={categoryId}
        pageSize={pageSize}
        setPageSize={setPageSize}
        page={page}
        setPage={setPage}
        loadingState={loadingState}
        headline={loadingState === "initial" ? null : headline}
        filterList={searchResult.filterFacets}
        selectedProductlistFilter={selectedProductlistFilter}
        refetch={refetch}
      />
    </>
  );
};

export default ProductListContainer;
