import React, { FC, useState } from "react";
import { SxProps, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CustomerProductlist } from "types/CustomerProductlist";
import { Product } from "types/Product";
import EditProductStandardQuantity from "./EditProductStandardQuantity";

interface ProductStandardQuantityProps {
  product?: Product;
  selectedCustomerProductList?: CustomerProductlist | null;
  editMode?: boolean;
}

const styles: SxProps<Theme> = {
  color: (theme) => theme.palette.grey[700],
  fontSize: "14px",
  float: "right",
  marginBottom: "5px",
};

const ProductStandardQuantity: FC<ProductStandardQuantityProps> = ({
  product,
  selectedCustomerProductList,
  editMode,
}) => {
  const { t } = useTranslation();
  const [standardQuantity, setStandardQuantity] = useState(
    product?.standardQuantity
  );

  if (editMode) {
    return (
      <EditProductStandardQuantity
        standardQuantity={standardQuantity}
        setStandardQuantity={setStandardQuantity}
        productListItemId={product?.productListItemId}
        selectedCustomerProductList={selectedCustomerProductList}
      />
    );
  }

  return (
    <Typography sx={styles}>
      {t("myAccount.listManagement.inputStandardLable")}
      {standardQuantity ?? 0}
    </Typography>
  );
};

export default ProductStandardQuantity;
