/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, Method, AxiosPromise } from "axios";
import { executeRequest } from "_foundation/axios/axiosConfig";
import { getSite } from "_foundation/hooks/useSite";
import cartService from "_foundation/apis/transaction/cart.service";
import { CartApprovalApi } from "@efood-commerce-sdk/typescript-axios-transaction-efood";
import { site as constantsSite } from "_foundationExt/constants/site";
import addOfflineAwareRequestHeaders from "pwa/offline/addOfflineAwareRequestHeaders";
import { Resource } from "pwa/offline";

const cartApprovalApiInstance = new CartApprovalApi(
  undefined,
  constantsSite.transactionContext
);

const cartServiceExt = {
  setOrderComment(parameters: any, url?: string): AxiosPromise<any> {
    const param = parameters;
    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.transactionContext || "";
    }
    const domain = url || siteContext;
    const path = "/store/{storeId}/cart/set_order_comment";
    let requestUrl = domain + path;
    const method: Method = "POST";
    let body = {};
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    const queryParameters = new URLSearchParams();

    if (param.storeId === undefined && site !== null) {
      param.storeId = site.storeID;
    }
    requestUrl = requestUrl.replace("{storeId}", param.storeId);

    if (param.storeId === undefined) {
      throw new Error(`Request '${path}' missing required parameter storeId`);
    }

    if (param.body !== undefined) {
      body = parameters.body;
    }

    if (parameters.body.orderId === undefined) {
      throw new Error(`Request '${path}' missing required parameter orderId`);
    }

    if (param.responseFormat !== undefined) {
      const name = "responseFormat";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.body !== undefined) {
      body = parameters.body;
    }

    if (param.mode !== undefined) {
      const name = "mode";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.$queryParameters) {
      Object.keys(param.$queryParameters).forEach((parameterName) => {
        const parameter = param.$queryParameters[parameterName];
        queryParameters.set(parameterName, parameter);
      });
    }

    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      headers: header,
      data: body,
      url: requestUrl,
      ...parameters,
    };

    return executeRequest(requestOptions);
  },

  createOrder(parameters: any, url?: string): AxiosPromise<any> {
    const param = parameters;
    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.transactionContext || "";
    }
    const domain = url || siteContext;
    const path = "/store/{storeId}/cart/create_order";
    let requestUrl = domain + path;
    const method: Method = "POST";
    let body = {};
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    const queryParameters = new URLSearchParams();

    if (param.storeId === undefined && site !== null) {
      param.storeId = site.storeID;
    }
    requestUrl = requestUrl.replace("{storeId}", param.storeId);

    if (param.storeId === undefined) {
      throw new Error(`Request '${path}' missing required parameter storeId`);
    }

    if (param.body !== undefined) {
      body = parameters.body;
    }

    if (param.description !== undefined) {
      const name = "description";
      const parameter = param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.$queryParameters) {
      Object.keys(param.$queryParameters).forEach((parameterName) => {
        const parameter = param.$queryParameters[parameterName];
        queryParameters.set(parameterName, parameter);
      });
    }

    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      headers: header,
      data: body,
      url: requestUrl,
      ...parameters,
    };

    return executeRequest(requestOptions);
  },

  emptyCart(parameters: any, url?: string): AxiosPromise<any> {
    const param = parameters;
    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.transactionContext || "";
    }
    const domain = url || siteContext;
    const path = "/store/{storeId}/cart/@self";
    let requestUrl = domain + path;
    const method: Method = "DELETE";

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    const queryParameters = new URLSearchParams();

    if (param.storeId === undefined && site !== null) {
      param.storeId = site.storeID;
    }
    requestUrl = requestUrl.replace("{storeId}", param.storeId);

    if (param.storeId === undefined) {
      throw new Error(`Request '${path}' missing required parameter storeId`);
    }

    if (param.responseFormat !== undefined) {
      const name = "responseFormat";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.mode !== undefined) {
      const name = "mode";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.$queryParameters) {
      Object.keys(param.$queryParameters).forEach((parameterName) => {
        const parameter = param.$queryParameters[parameterName];
        queryParameters.set(parameterName, parameter);
      });
    }

    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      headers: header,
      url: requestUrl,
      ...parameters,
    };

    return executeRequest(
      addOfflineAwareRequestHeaders(
        { resource: Resource.Cart, originMethod: "emptyCart" },
        requestOptions
      )
    );
  },

  checkApproval(orderId: string): AxiosPromise<any> {
    const siteInfo = getSite();
    if (!siteInfo) {
      return Promise.reject();
    }

    return cartApprovalApiInstance.checkApproval(
      siteInfo?.storeID,
      orderId,
      addOfflineAwareRequestHeaders({
        resource: Resource.Cart,
        originMethod: "checkApproval",
      })
    );
  },

  updateRewardChoice(parameters: any, url?: string): AxiosPromise<any> {
    const param = parameters;
    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.transactionContext || "";
    }
    const domain = url || siteContext;
    const path = "/store/{storeId}/cart/@self/update_reward_option";
    let requestUrl = domain + path;
    const method: Method = "PUT";
    let body = {};
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    const queryParameters = new URLSearchParams();

    if (param.storeId === undefined && site !== null) {
      param.storeId = site.storeID;
    }
    requestUrl = requestUrl.replace("{storeId}", param.storeId);

    if (param.storeId === undefined) {
      throw new Error(`Request '${path}' missing required parameter storeId`);
    }

    if (param.body !== undefined) {
      body = parameters.body;
    }

    if (param.body.orderId === undefined) {
      throw new Error(`Request '${path}' missing required parameter orderId`);
    }

    if (param.body.rewardOptionId === undefined) {
      throw new Error(
        `Request '${path}' missing required parameter rewardOptionId`
      );
    }

    if (param.responseFormat !== undefined) {
      const name = "responseFormat";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.mode !== undefined) {
      const name = "mode";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      headers: header,
      data: body,
      url: requestUrl,
      ...parameters,
    };

    return executeRequest(requestOptions);
  },
};

export default { ...cartService, ...cartServiceExt };
