import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import {
  StyledButton,
  StyledContainer,
  StyledDivider,
} from "components/StyledUI";

import { ROUTES } from "constants/routes";

interface LocationState {
  errorMessage: string;
}

interface Props {
  resetError?: () => void;
}

const ErrorSite: FC<Props> = ({ resetError }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const errorMessage = (location.state as LocationState)?.errorMessage;

  const resetErrorAndOpenHomepage = () => {
    if (resetError) {
      resetError();
    }
    navigate(ROUTES.HOME);
  };

  return (
    <>
      <Helmet>
        <title>{t("error.error")}</title>
      </Helmet>

      <StyledContainer size="small">
        <Typography variant="h1">{t("error.generic")}</Typography>
        {errorMessage && <div>{errorMessage}</div>}
        {resetError && <div>{t("error.genericDescription")}</div>}
        <StyledDivider light />
        {resetError && (
          <StyledButton variant="outlined" onClick={resetErrorAndOpenHomepage}>
            {t("home.toHome")}
          </StyledButton>
        )}
        {!resetError && (
          <StyledButton component={Link} to={ROUTES.HOME}>
            {t("home.toHome")}
          </StyledButton>
        )}
      </StyledContainer>
    </>
  );
};

export default ErrorSite;
