import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { ContentProps } from "components/dialog/types";
import { ServiceWorkerUpdateAvailableParams } from "./ServiceWorkerUpdateAvailable";

const ServiceWorkerUpdateAvailableTitle: FC<
  ContentProps<ServiceWorkerUpdateAvailableParams>
> = () => {
  const { t } = useTranslation();

  return (
    <Typography variant="h3">{t("offline.updateAvailable.title")}</Typography>
  );
};

export default ServiceWorkerUpdateAvailableTitle;
