import React, { PropsWithChildren } from "react";
import Badge, { type BadgeProps } from "@mui/material/Badge";

import { styled } from "@mui/material/styles";

type RootProps = { large?: boolean };

const Root = styled(Badge, {
  name: "StyledBadge",
  slot: "Root",
  shouldForwardProp: (prop) => prop !== "large",
})<RootProps>(({ theme, large = false }) => ({
  "& .MuiBadge-badge": {
    fontSize: large ? "14px" : "12px",
    fontWeight: "bold",
    color: theme.palette.background.default,
    zIndex: theme.zIndex.extension.badge,
  },
}));

type StyledBadgeProps = RootProps & BadgeProps;

const StyledBadge = React.forwardRef<
  HTMLSpanElement,
  PropsWithChildren<StyledBadgeProps>
>(({ children, ...rest }, ref) => (
  <Root {...rest} ref={ref}>
    {children}
  </Root>
));

export { StyledBadge };
