import React, { useEffect } from "react";
import { SHOW_DIALOG_ACTION } from "_redux/actions/site";
import { loginStatusSelector } from "_redux/selectors/user";
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import { useOnlineStatus } from "_foundationExt/hooks/useOnlineStatus";
import isInstanaActive from "tools/isInstanaActive";
import { useLiveQuery } from "dexie-react-hooks";
import defaultOfflineCartHandler from "./OfflineCartHandler";
import offlineSyncEnabled from "../common/OfflineSyncEnabled";

const TriggerCartSyncWatcher: React.FC<{
  ignoreOnlineStateChange?: boolean;
}> = ({ ignoreOnlineStateChange = false }) => {
  const { isChanged, isOnline } = useOnlineStatus();
  const userLoggedIn = useAppSelector(loginStatusSelector);
  const isOfflineSyncEnabled = useLiveQuery(offlineSyncEnabled.get, [], false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      isOfflineSyncEnabled &&
      userLoggedIn &&
      isOnline &&
      (ignoreOnlineStateChange || isChanged)
    ) {
      const checkSyncNeeded = async () => {
        try {
          const isNeeded = await defaultOfflineCartHandler.isCartSyncNeeded();
          if (isNeeded) {
            dispatch(
              SHOW_DIALOG_ACTION({
                type: "SyncCartData",
                closeable: false,
              })
            );
          }
        } catch (error) {
          if (error instanceof Error && isInstanaActive()) {
            ineum(
              "reportEvent",
              "error while checking if cart sync is needed",
              {
                timestamp: Date.now(),
                error,
                componentStack:
                  error instanceof Error ? error.stack : undefined,
              }
            );
          }
        }
      };
      checkSyncNeeded();
    }
  }, [
    isOfflineSyncEnabled,
    userLoggedIn,
    isOnline,
    ignoreOnlineStateChange,
    isChanged,
    dispatch,
  ]);

  return null;
};

export default TriggerCartSyncWatcher;
