import { eFoodDB } from "pwa/db/efood.db";
import productListsSyncTimestamp from "./productListsSyncTimestamp";

const clearCustomerProductListsData = () =>
  Promise.all([
    productListsSyncTimestamp.clear(),
    eFoodDB.customerProductLists.clear(),
  ]);

export default clearCustomerProductListsData;
