import { useCallback, useEffect, useMemo } from "react";

/**
 * Controls a list of AbortControllers created by this hook, which can be used to cancel API requests.
 * When the component which uses this hook gets unmounted, all requests which are using AbortControllers created by this hook are canceled.
 *
 * @returns an object containing a create function to create a new AbortController for a request which is about to happen and an abortAll function to abort all AbortControllers created via this Hook.
 */
const useAbortControllers = () => {
  const controllers: AbortController[] = useMemo(() => [], []);

  const create = useCallback(() => {
    const ac = new AbortController();
    controllers.push(ac);
    return ac;
  }, [controllers]);

  const abortAll = useCallback(() => {
    while (controllers.length) {
      controllers.pop()?.abort();
    }
  }, [controllers]);

  // abort possible requests when component/hook gets unmounted
  useEffect(
    () => () => {
      abortAll();
    },
    [abortAll]
  );

  const abortControllers = useMemo(
    () => ({
      create,
      abortAll,
    }),
    [create, abortAll]
  );

  return abortControllers;
};

export default useAbortControllers;
