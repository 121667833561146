import { ThemeOptions } from "@mui/material/styles";

const typography: NonNullable<ThemeOptions["typography"]> = {
  fontFamily: ["NunitoSans", "sans-serif"].join(","),
  htmlFontSize: 16,
  fontSize: 16,
  h1: {
    fontSize: 28,
    lineHeight: "36px",
    fontWeight: 700,
    letterSpacing: "-0.5px",
    "@media (min-width:680px)": {
      fontSize: 32,
      lineHeight: 1.25,
    },
  },
  h2: {
    fontSize: 22,
    lineHeight: "28px",
    fontWeight: 700,
    letterSpacing: "-0.5px",
  },
  h3: {
    fontSize: 18,
    lineHeight: "24px",
    fontWeight: 700,
  },
  h4: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: 700,
  },
  h5: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 700,
  },
  h6: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 700,
  },
  body1: {
    fontSize: 16,
    lineHeight: "24px",
  },
  body2: {
    fontSize: 14,
    lineHeight: "20px",
    color: "#20231c",
  },
  subtitle1: {
    fontSize: 12,
    lineHeight: "16px",
    color: "#80837c",
  },
  button: {
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "1.5px",
    fontWeight: 700,
  },
};

export { typography };
