// Standard libs
import React, { FC, useCallback, useEffect } from "react";
// Custom libraries
import Header from "components/header";
import Footer from "components/footer";
import HeaderMarketingSpot from "components/headerMarketingSpot";
// Redux

// UI
import { ErrorAlert } from "components/error";

import CookieConsentModal from "components/cookieConsent/CookieConsentModal";
import { StyledScrollUpContainer } from "components/StyledUI";
import ScrollToTop from "components/helpers/ScrollToTop";
import MatomoProvider, {
  MatomoTagManager,
  TrackRoute,
} from "components/matomo";
import { Dialog } from "components/dialog";
import CentralLogonDialog from "components/CentralLogonDialog";
import OnlineStatusInformation from "pwa/onlineStatus/OnlineStatusInformation";
import OfflineSyncWatcher from "pwa/offline/sync/components/OfflineSyncWatcher";
import TriggerCartSyncWatcher from "pwa/offline/cart/TriggerCartSyncWatcher";
import OnlineStatusChecker from "pwa/onlineStatus/OnlineStatusChecker";
import ForceStandaloneModeWatcher from "pwa/displayMode/ForceStandaloneModeWatcher";
import { Outlet } from "react-router-dom";
import { useAppDispatch } from "_redux/hooks";
import useAxiosMaintenanceInterceptor from "_foundationExt/hooks/useAxiosMaintenanceInterceptor";
import { useAxiosInterceptor } from "_foundation/axios/axiosConfig";
import {
  initSite,
  useAbortControllers,
  useScript,
  useSite,
} from "_foundationExt/hooks";
import { useTranslation } from "react-i18next";
import { localStorageUtil } from "_foundationExt/utils/storageUtil";
import { LOCALE } from "_foundation/constants/common";
import { CommerceEnvironment } from "constants/common";
import { externalScripts } from "constants/scripts";
import { useScrollRestoration } from "tools/scrollRestoration";
import { INIT_STATE_FROM_STORAGE_ACTION } from "_redux/actions/user";
import { site } from "_foundationExt/constants/site";
import Home from "pages/home/Home";
import Lazy from "components/routes/Lazy";

const HeaderIncludes: FC = () => (
  <>
    <ForceStandaloneModeWatcher />
    <OnlineStatusChecker />
    <TriggerCartSyncWatcher />
    <ScrollToTop />
    <HeaderMarketingSpot />
    <MatomoTagManager />
    <Dialog />
    <CentralLogonDialog />
  </>
);

const BodyIncludes: FC = () => (
  <>
    <OnlineStatusInformation />
    <OfflineSyncWatcher />
    <ErrorAlert />
    <CookieConsentModal />
    <StyledScrollUpContainer />
  </>
);

const AppLayout: FC = () => {
  const dispatch = useAppDispatch();
  useAxiosMaintenanceInterceptor();
  useAxiosInterceptor();
  const { currentSite } = useSite();
  const { i18n } = useTranslation();
  const abortControllers = useAbortControllers();

  const setTranslate = useCallback(() => {
    /**
     * language preference priority
     * 1. user context, to be implemented with language toggle
     * 2. localStorage (saved for 30 days).
     * 3. store default language.
     */
    if (currentSite) {
      // check if locale exists in local storage
      const storageLocal = localStorageUtil.get(LOCALE);
      if (storageLocal == null) {
        // locale does not exist in local storage
        // get language from site default. convert from id to string
        const locale = CommerceEnvironment.languageMap[
          currentSite.defaultLanguageID
        ]
          .split("_")
          .join("-");
        // check if language from site default matches the current store language
        if (locale !== i18n.languages[0]) {
          // if not then change language
          i18n.changeLanguage(locale);
        }
        // set locale into local storage
        localStorageUtil.set(LOCALE, locale.split("-").join("_"));
      } else {
        const locale = storageLocal.split("_").join("-");
        i18n.changeLanguage(locale);
      }
    }
  }, [currentSite, i18n]);

  useScript(
    currentSite?.storeName ? externalScripts[currentSite.storeName] : null
  );

  useScrollRestoration();

  useEffect(() => {
    if (currentSite) {
      dispatch(INIT_STATE_FROM_STORAGE_ACTION());
      setTranslate();
    } else {
      const { signal } = abortControllers.create();
      initSite(site, dispatch, signal);
    }
  }, [currentSite, dispatch, setTranslate, abortControllers]);

  return (
    <MatomoProvider>
      <HeaderIncludes />
      <Header />
      <main>
        <BodyIncludes />
        {currentSite?.storeID === "10101" ? (
          <Lazy>
            <Home />
          </Lazy>
        ) : (
          <Outlet />
        )}
        <TrackRoute />
      </main>
      <Footer />
    </MatomoProvider>
  );
};

export default AppLayout;
