import React, { FC } from "react";
import ButtonGroup, { ButtonGroupProps } from "@mui/material/ButtonGroup";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  groupedContainedHorizontal: {
    transition: theme.transitions.create([
      "color",
      "background",
      "borderColor",
    ]),
    "&:not(:last-of-type)": {
      borderRight: 0,
      borderColor: theme.palette.primary.light,
    },
    "&:not(:first-of-type)": {
      borderLeft: 0,
    },
  },
}));

const StyledButtonGroup: FC<ButtonGroupProps> = (props) => {
  const { classes } = useStyles();

  const { color, variant } = props;

  return (
    <ButtonGroup
      classes={classes}
      color={color || "primary"}
      variant={variant || "contained"}
      disableElevation
      disableRipple
      {...props}
    />
  );
};

export default StyledButtonGroup;
