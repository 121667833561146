/* eslint-disable indent */
import React, { FC } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import { ProductListItem } from "components/productListItem";
import { usePredictivBasket } from "components/widgets/espot/utils/usePredictivBasket";
import PaginationSection from "components/productList/PaginationSection";

import PageSizeSelector from "components/productList/PageSizeSelector";
import { TrackProduct } from "components/matomo";
import { usePagination } from "_foundationExt/hooks";
import { useFetchProducts } from "./prediction.hooks";

type Props = {
  reloadPredictiveBasket: boolean;
};

const PredictionList: FC<Props> = ({ reloadPredictiveBasket }) => {
  const { t } = useTranslation();
  const { pageSize, setPageSize, page, setPage } = usePagination();

  const [basketPredictionIds, loading] = usePredictivBasket(
    false,
    reloadPredictiveBasket
  );
  const { predictionProducts, error, predictionProductsTotal } =
    useFetchProducts(basketPredictionIds, pageSize, page);

  const sortedPredictionProducts =
    basketPredictionIds && predictionProducts
      ? predictionProducts.sort(
          (a, b) =>
            basketPredictionIds.indexOf(a.eFoodPartnumber) -
            basketPredictionIds.indexOf(b.eFoodPartnumber)
        )
      : null;

  if (loading) return null;

  return (
    <Grid container>
      <Grid xs={12}>
        <Box mt={5} />
        {error && <div>{t("prediction.predictionErrorMessage")}</div>}
        {basketPredictionIds?.length === 0 && (
          <div>{t("prediction.noPredictions")}</div>
        )}
      </Grid>

      {predictionProductsTotal >= 10 && (
        <Grid container xs={12} justifyContent="flex-end">
          <Box mb={3}>
            <Grid>
              <PaginationSection
                pageSize={pageSize}
                totalAmountOfProducts={predictionProductsTotal}
                page={page}
                setPage={setPage}
              />
            </Grid>
          </Box>
          <Box ml={2}>
            <Grid>
              <PageSizeSelector pageSize={pageSize} setPageSize={setPageSize} />
            </Grid>
          </Box>
        </Grid>
      )}

      <Grid xs={12}>
        {sortedPredictionProducts &&
          sortedPredictionProducts &&
          sortedPredictionProducts.length > 0 && (
            <div>
              {sortedPredictionProducts.map((product) => (
                <TrackProduct
                  key={product.eFoodPartnumber}
                  trackType="list"
                  productId={product.eFoodPartnumber}>
                  <ProductListItem
                    key={product.uniqueID}
                    product={product}
                    trackType="list"
                  />
                </TrackProduct>
              ))}
            </div>
          )}
      </Grid>

      {predictionProductsTotal >= 10 && (
        <Grid container xs={12} justifyContent="flex-end">
          <Box mt={3}>
            <Grid>
              <PaginationSection
                pageSize={pageSize}
                totalAmountOfProducts={predictionProductsTotal}
                page={page}
                setPage={setPage}
              />
            </Grid>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default PredictionList;
