export const ROUTES = {
  ABOUT: "/about",
  ACCOUNT_LISTS: "/account/lists",
  ACCOUNT_ORDERHISTORY_DETAILS: "/account/orderhistory/details",
  ACCOUNT_PROFILE: "/account/profile",
  ACCOUNT_PWA_SETTINGS: "/account/settings",
  ACCOUNT_REPORTS: "/account/reports",
  ACCOUNT_USERS_NEW: "/account/users/new",
  ACCOUNT_USERS: "/account/users",
  ACCOUNT: "/account",
  CART: "/checkout",
  COMPONENTS_SHOWCASE_SITE: "/componentsshowcase",
  CONTACT: "/contact",
  ERROR_SITE: "/error",
  ESPOT_SHOWCASE_SITE: "/espotshowcase",
  EXPIRED_PASSWORD: "/expiredPassword",
  FORGOT_PASSWORD: "/forgotPassword",
  HOME_LOGGEDIN: "/home",
  HOME: "/",
  LANDINGPAGE: "/landing",
  LOGON: "/logon",
  MAINTENANCE: "/maintenance",
  NEWSLETTER_ACTIVATE: "/newsletter/activate",
  OFFERS: "/offers",
  OFFLINE_SHOWCASE_SITE: "/offlineshowcase",
  ORDER_CONFIRMATION: "/order/confirmation",
  PRIVACY: "/privacy",
  PRODUCT_CATALOG: "/catalog",
  PRODUCT_DETAIL: "/product",
  PRODUCT_LIST: "/category",
  PRODUCT_PREDICTION: "/prediction",
  RECIPES: "/recipes",
  REGISTER_EXISTING_CUSTOMER: "/register/existing",
  REGISTER_NEW_CUSTOMER: "/register/new",
  REGISTER_SUCCESS: "/register/success",
  REGISTER_TYPE: "/register",
  SANDER: "/sander",
  SEARCH: "/search",
  TERMS: "/terms",
} as const;

export type AllowedRoutes = keyof typeof ROUTES;
export type AllowedRoutesValues = (typeof ROUTES)[AllowedRoutes];
