import { DEFAULT_TRACKING_CHOICE } from "constants/user";
import checkOnlineStatus from "pwa/onlineStatus/checkOnlineStatus";
import {
  OrderReducerState,
  ErrorReducerState,
  CatalogReducerState,
  UserReducerState,
  SiteReducerState,
  ApiReducerState,
  EFoodConfiguration,
  Tracking,
} from "./reducerStateInterface";

export const trackingDefaultState: Tracking = {
  matomo: { siteId: null, host: null },
};

export const eFoodConfigDefaultState: EFoodConfiguration = {
  checkoutComment: false,
  contracts: false,
  dropShipping: [],
  host: null,
  internTxt: false,
  isAutoLoginAllowed: false,
  navigationLevels: 3,
  onlyHomepage: true,
  onsalearticleRemainder: false,
  optainarticleCheck: false,
  optainArticleGuest: false,
  packageUnitSelector: false,
  partialQuantities: false,
  predictivBasketCount: 50,
  productdetailCustomer: false,
  productdetailGuest: false,
  recipes: false,
  registrationtype: "NONE",
  reports: false,
  sander: false,
  searchIn: "catalog",
  servisaID: null,
  topProductCount: 1,
  tracking: trackingDefaultState,
  useDeliveryDateForPricing: true,
};

const orderDefaultState: OrderReducerState = {
  baskets: [],
  numItems: 0,
  total: 0,
  totalProduct: 0,
  totalAdjustment: 0,
  id: "",
  totalShipping: 0,
  shipInfos: null,
  validation: null,
  approvals: null,
  rewards: null,
  promotionCode: [],
};
const errorDefaultState: ErrorReducerState = {};

const catalogDefaultState: CatalogReducerState = {
  categoryTop: [],
};

const userDefaultState: UserReducerState = {
  initiatedFromStorage: false,
  trackingChoice: DEFAULT_TRACKING_CHOICE,
  rewardOptionIdsHandled: [],
  orderItemsWithOrderSplit: [],
  WCToken: "",
  WCTrustedToken: "",
  contracts: undefined,
};

const siteDefaultState: SiteReducerState = {
  scrollToTop: false,
  showDialog: [],
  onlineStatus: {
    status: "initial",
    changed: false,
  },
  forceStandaloneMode: false,
  syncManuallyTriggered: false,
  syncRunning: false,
};

const apiDefaultState: ApiReducerState = {
  apiFlowList: [],
};

export const defaultStates = {
  api: apiDefaultState,
  order: orderDefaultState,
  user: userDefaultState,
  error: errorDefaultState,
  site: siteDefaultState,
  catalog: catalogDefaultState,
};

export const clearState = (o: any) => {
  for (var variableKey in o) {
    if (o.hasOwnProperty(variableKey)) {
      delete o[variableKey];
    }
  }
};

const initStates = {
  ...defaultStates,
};
export default initStates;
