declare global {
  interface Window {
    instanaActive: boolean;
  }
}

const isInstanaActive = () =>
  typeof document !== "undefined" &&
  window.instanaActive &&
  typeof ineum === "function";

export default isInstanaActive;
