import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import useOrderPriceSelector from "_redux/hooks/useOrderPriceSelector";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import { formatCurrency } from "tools/stringUtils";

const CartPriceSummary: FC = () => {
  const { t } = useTranslation();
  const orderPrice = useOrderPriceSelector();

  return (
    <Grid
      container
      sx={{ flexGrow: 1, alignContent: "flex-start" }}
      xs={12}
      sm={4}
      mt={{ xs: 2, sm: 0 }}>
      <Grid xs={8}>
        <Typography align="right" variant="body1" mr={1}>
          {t("cart.products")}:
        </Typography>
      </Grid>
      <Grid xs={4}>
        <Typography align="right" variant="body1">
          {formatCurrency(orderPrice.totalProduct)}
        </Typography>
      </Grid>
      {orderPrice.totalAdjustment !== 0 && (
        <>
          <Grid xs={8}>
            <Typography align="right" variant="body1" mr={1}>
              {t("cart.promotionCode")}:
            </Typography>
          </Grid>

          <Grid xs={4}>
            <Typography align="right" variant="body1">
              {formatCurrency(orderPrice.totalAdjustment)}
            </Typography>
          </Grid>
        </>
      )}
      <Grid xs={8}>
        <Typography align="right" variant="body1" mr={1}>
          {t("cart.shippingCosts")}:
        </Typography>
      </Grid>
      <Grid xs={4}>
        <Typography align="right" variant="body1">
          {formatCurrency(orderPrice.totalShipping)}
        </Typography>
      </Grid>
      <Grid xs={8}>
        <Typography align="right" variant="body1" mr={1}>
          {t("cart.total")}:
        </Typography>
      </Grid>
      <Grid xs={4}>
        <Typography align="right" variant="h2">
          <b>{formatCurrency(orderPrice.total)}</b>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CartPriceSummary;
