import { get } from "lodash-es";
import type {
  Attachment,
  ESpotContent,
  MarketingSpotDataTitle,
} from "types/Marketing";

const fixDisplayUrl = (url?: string) => {
  if (url) {
    const productId = /\/?ProductDisplay.*productId=(?<productId>[^&]*).*/.exec(
      url
    )?.groups?.productId;
    if (productId) {
      return `/product/${productId}`;
    }
  }
  return url;
};

const handleFile = (
  marketingSpotDataTitle: MarketingSpotDataTitle
): ESpotContent => {
  const result: ESpotContent = {
    id: marketingSpotDataTitle.contentId || "",
    attachment: {
      id: get(
        marketingSpotDataTitle,
        "attachmentAsset[0].attachmentAssetId",
        ""
      ),
      url: get(
        marketingSpotDataTitle,
        "attachmentAsset[0].attachmentAssetPath",
        ""
      ),
    },
    marketingText: get(
      marketingSpotDataTitle,
      "marketingContentDescription[0].marketingText",
      ""
    ),
    url: marketingSpotDataTitle.contentUrl || "",
  };

  return result;
};

const handleText = (
  marketingSpotDataTitle: MarketingSpotDataTitle,
  langId: string
): ESpotContent => {
  const id =
    marketingSpotDataTitle.marketingSpotDataTitleId ||
    marketingSpotDataTitle.contentId ||
    "";
  const marketingText = marketingSpotDataTitle.marketingContentDescription
    ?.filter((e) => e.language === langId)
    .map((e) => e.marketingText)
    .find(Boolean);
  const url = fixDisplayUrl(marketingSpotDataTitle.contentUrl);
  const attachmentPath = marketingSpotDataTitle.attachmentAsset
    ?.filter(
      (e) =>
        e &&
        (!e.attachmentAssetLanguage ||
          e.attachmentAssetLanguage.indexOf(langId) !== -1)
    )
    .map((e) => e.attachmentAssetPath)
    .find(Boolean);
  let attachment: Attachment | undefined;
  if (attachmentPath) {
    attachment = {
      id: marketingSpotDataTitle.attachementId || "",
      url: attachmentPath,
    };
  }
  return { id, marketingText, url, attachment };
};
const mapMarketingContent = (
  langId: string,
  marketingSpotDataTitle?: MarketingSpotDataTitle
): ESpotContent | undefined => {
  if (marketingSpotDataTitle?.contentFormatName === "Text") {
    return handleText(marketingSpotDataTitle, langId);
  }

  if (marketingSpotDataTitle?.contentFormatName === "File") {
    return handleFile(marketingSpotDataTitle);
  }

  return undefined;
};

export default mapMarketingContent;
