import React, { FC } from "react";

import { OrderItemError } from "_redux/reducers";
import { useTranslation } from "react-i18next";
import QuantityHint from "./QuantityHint";

const OrderItemErrorHint: FC<{ orderItemError?: OrderItemError }> = ({
  orderItemError,
}) => {
  const { t } = useTranslation();

  if (!orderItemError) return null;

  if (orderItemError.errorMessage === "_WARN_QANTITY_ROUNDED_NEXT_VKE") {
    return <QuantityHint message={t("cart.quantityRounded")} />;
  }

  if (orderItemError.errorMessage === "QUANTITY_NOT_UPDATED") {
    return (
      <QuantityHint variant="error" message={t("cart.quantityNotUpdated")} />
    );
  }

  return (
    <QuantityHint
      variant="error"
      message={t("cart.quantityUnknownError")}
      messageDetail={orderItemError.errorMessage}
    />
  );
};

export default OrderItemErrorHint;
