// Standard libs
import React, { FC, lazy } from "react";
// UI
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ROUTES } from "constants/routes";
import AppLayout from "components/layout/AppLayout";
import {
  RequireLoggedInHome,
  RequireLoggedOutHome,
} from "components/routes/CheckStatusHome";
import ErrorPage from "components/error/ErrorPage";
import ErrorLayout from "components/layout/ErrorLayout";
import Lazy from "components/routes/Lazy";
import { RequireLoggedIn } from "components/routes/RequireLoggedIn";
import { ErrorSite } from "pages";
import Restricted from "components/routes/Restricted";
import { RequireRegistrationType } from "components/routes/RequireRegistrationType";
import InstanaPageEvent from "components/layout/InstanaPageEvent";

// Lazy loaded routes
const Home = lazy(() => import("pages/home/Home"));
const Logon = lazy(() => import("pages/Logon"));
const RegisterSuccess = lazy(() => import("pages/register/RegisterSuccess"));
const NewsletterActivate = lazy(
  () => import("pages/newsletter/NewsletterActivate")
);
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));
const ExpiredPassword = lazy(() => import("pages/ExpiredPassword"));
const ProductCatalog = lazy(() => import("pages/ProductCatalog"));
const ProductList = lazy(() => import("pages/ProductList"));
const RestrictedProductPredictionPage = lazy(
  () => import("components/routes/RestrictedProductPredictionPage")
);
const Search = lazy(() => import("pages/Search"));
const Account = lazy(() => import("pages/account/Dashboard"));
const AccountProfile = lazy(() => import("pages/account/Profile"));
const AccountLists = lazy(() => import("pages/account/AccountLists"));
const AccountReports = lazy(() => import("pages/account/Reports"));
const AccountUsers = lazy(() => import("pages/account/Users"));
const PwaSettings = lazy(() => import("pages/account/PwaSettings"));
const Maintenance = lazy(() => import("pages/Maintenance"));
const OrderHistoryDetails = lazy(
  () => import("pages/account/OrderHistoryDetails")
);
const OrderConfirmationPage = lazy(() => import("pages/OrderConfirmationPage"));
const Checkout = lazy(() => import("pages/Checkout"));
const Offers = lazy(() => import("pages/Offers"));
const SanderBuffet = lazy(() => import("pages/Sander/Buffet"));
const SanderMenu = lazy(() => import("pages/Sander/Menu"));
const ComponentsShowcase = lazy(() => import("pages/componentsShowcase"));
const ESpotShowcase = lazy(() => import("pages/ESpotShowcase"));
const OfflineShowcase = lazy(() => import("pages/offlineShowcase"));

const LandingPage = lazy(() => import("pages/content/LandingPage"));
const About = lazy(() => import("pages/content/About"));
const Contact = lazy(() => import("pages/content/Contact"));
const Privacy = lazy(() => import("pages/content/Privacy"));
const Terms = lazy(() => import("pages/content/Terms"));
const RegisterType = lazy(() => import("pages/register/RegisterType"));
const Register = lazy(() => import("pages/register/Register"));
const RestrictedProductDetailPage = lazy(
  () => import("components/routes/RestrictedProductDetailPage")
);

const router = createBrowserRouter([
  {
    element: <InstanaPageEvent />,
    children: [
      {
        id: "sbec",
        path: ROUTES.HOME,
        element: <AppLayout />,
        errorElement: <ErrorPage />,
        children: [
          {
            errorElement: <ErrorPage />,
            children: [
              // public routes
              {
                index: true,
                element: (
                  <RequireLoggedOutHome>
                    <Lazy>
                      <Home />
                    </Lazy>
                  </RequireLoggedOutHome>
                ),
              },
              {
                path: ROUTES.HOME_LOGGEDIN,
                element: (
                  <RequireLoggedInHome>
                    <Lazy>
                      <Home />
                    </Lazy>
                  </RequireLoggedInHome>
                ),
              },
              {
                path: ROUTES.LOGON,
                element: (
                  <Lazy>
                    <Logon />
                  </Lazy>
                ),
              },
              {
                path: ROUTES.REGISTER_SUCCESS,
                element: (
                  <Lazy>
                    <RegisterSuccess />
                  </Lazy>
                ),
              },
              {
                path: `${ROUTES.NEWSLETTER_ACTIVATE}/:validationCode`,
                element: (
                  <Lazy>
                    <NewsletterActivate />
                  </Lazy>
                ),
              },
              {
                path: ROUTES.FORGOT_PASSWORD,
                element: (
                  <Lazy>
                    <ForgotPassword />
                  </Lazy>
                ),
              },
              {
                path: ROUTES.EXPIRED_PASSWORD,
                element: (
                  <Lazy>
                    <ExpiredPassword />
                  </Lazy>
                ),
              },
              {
                path: ROUTES.PRODUCT_CATALOG,
                element: (
                  <Lazy>
                    <ProductCatalog />
                  </Lazy>
                ),
              },
              {
                path: `${ROUTES.PRODUCT_LIST}/:categoryId`,
                element: (
                  <Lazy>
                    <ProductList />
                  </Lazy>
                ),
              },
              {
                path: `${ROUTES.PRODUCT_DETAIL}${ROUTES.SEARCH}`,
                element: (
                  <Lazy>
                    <Search />
                  </Lazy>
                ),
              },
              {
                path: ROUTES.OFFERS,
                children: [
                  {
                    index: true,
                    element: (
                      <Lazy>
                        <Offers />
                      </Lazy>
                    ),
                  },
                  {
                    path: ":offerNumber",
                    element: (
                      <Lazy>
                        <Offers />
                      </Lazy>
                    ),
                  },
                ],
              },
              // restricted routes
              {
                path: ROUTES.REGISTER_TYPE,
                element: (
                  <RequireRegistrationType
                    compareType="ne"
                    registrationType="ALL">
                    <Lazy>
                      <RegisterType />
                    </Lazy>
                  </RequireRegistrationType>
                ),
              },
              {
                path: ROUTES.REGISTER_NEW_CUSTOMER,
                element: (
                  <RequireRegistrationType
                    compareType="eq"
                    registrationType="NONE">
                    <Lazy>
                      <Register type="new" />
                    </Lazy>
                  </RequireRegistrationType>
                ),
              },
              {
                path: ROUTES.REGISTER_EXISTING_CUSTOMER,
                element: (
                  <RequireRegistrationType
                    compareType="ne"
                    registrationType="ALL">
                    <Lazy>
                      <Register type="existing" />
                    </Lazy>
                  </RequireRegistrationType>
                ),
              },
              {
                path: `${ROUTES.PRODUCT_DETAIL}/:partNumber`,
                element: (
                  <Lazy>
                    <RestrictedProductDetailPage />
                  </Lazy>
                ),
              },
              {
                path: ROUTES.PRODUCT_PREDICTION,
                element: (
                  <Lazy>
                    <RestrictedProductPredictionPage />
                  </Lazy>
                ),
              },
              // private routes
              {
                path: ROUTES.SANDER,
                children: [
                  {
                    path: "buffet",
                    element: (
                      <RequireLoggedIn>
                        <Lazy>
                          <SanderBuffet />
                        </Lazy>
                      </RequireLoggedIn>
                    ),
                  },
                  {
                    path: "menu",
                    element: (
                      <RequireLoggedIn>
                        <Lazy>
                          <SanderMenu />
                        </Lazy>
                      </RequireLoggedIn>
                    ),
                  },
                ],
              },
              {
                path: ROUTES.ACCOUNT,
                children: [
                  {
                    index: true,
                    element: (
                      <RequireLoggedIn>
                        <Lazy>
                          <Account />
                        </Lazy>
                      </RequireLoggedIn>
                    ),
                  },
                  {
                    path: "profile",
                    element: (
                      <RequireLoggedIn>
                        <Lazy>
                          <AccountProfile />
                        </Lazy>
                      </RequireLoggedIn>
                    ),
                  },
                  {
                    path: "lists",
                    element: (
                      <RequireLoggedIn>
                        <Lazy>
                          <AccountLists />
                        </Lazy>
                      </RequireLoggedIn>
                    ),
                  },
                  {
                    path: "reports",
                    element: (
                      <RequireLoggedIn>
                        <Lazy>
                          <AccountReports />
                        </Lazy>
                      </RequireLoggedIn>
                    ),
                  },
                  {
                    path: "orderhistory/details/:organizationId/:orderHistoryId",
                    element: (
                      <RequireLoggedIn>
                        <Lazy>
                          <OrderHistoryDetails />
                        </Lazy>
                      </RequireLoggedIn>
                    ),
                  },
                  {
                    path: "users",
                    children: [
                      {
                        index: true,
                        element: (
                          <RequireLoggedIn>
                            <Lazy>
                              <AccountUsers />
                            </Lazy>
                          </RequireLoggedIn>
                        ),
                      },
                      {
                        path: ":new",
                        element: (
                          <RequireLoggedIn>
                            <Lazy>
                              <AccountUsers />
                            </Lazy>
                          </RequireLoggedIn>
                        ),
                      },
                    ],
                  },
                  {
                    path: "settings",
                    element: (
                      <RequireLoggedIn>
                        <Lazy>
                          <PwaSettings />
                        </Lazy>
                      </RequireLoggedIn>
                    ),
                  },
                ],
              },
              {
                path: ROUTES.CART,
                element: (
                  <RequireLoggedIn>
                    <Lazy>
                      <Checkout />
                    </Lazy>
                  </RequireLoggedIn>
                ),
              },
              {
                path: `${ROUTES.ORDER_CONFIRMATION}/:orderId`,
                element: (
                  <RequireLoggedIn>
                    <Lazy>
                      <OrderConfirmationPage />
                    </Lazy>
                  </RequireLoggedIn>
                ),
              },
              // content pages
              {
                path: `${ROUTES.LANDINGPAGE}/:pageName`,
                element: (
                  <Lazy>
                    <LandingPage />
                  </Lazy>
                ),
              },
              {
                path: ROUTES.ABOUT,
                element: (
                  <Lazy>
                    <About />
                  </Lazy>
                ),
              },
              {
                path: ROUTES.TERMS,
                element: (
                  <Lazy>
                    <Terms />
                  </Lazy>
                ),
              },
              {
                path: ROUTES.CONTACT,
                element: (
                  <Lazy>
                    <Contact />
                  </Lazy>
                ),
              },
              {
                path: ROUTES.PRIVACY,
                element: (
                  <Lazy>
                    <Privacy />
                  </Lazy>
                ),
              },
              // showcases
              {
                path: ROUTES.COMPONENTS_SHOWCASE_SITE,
                element: (
                  <Restricted
                    restricted={
                      process.env.REACT_APP_SERVER_REQUEST_STAGE === "PROD"
                    }>
                    <Lazy>
                      <ComponentsShowcase />
                    </Lazy>
                  </Restricted>
                ),
              },
              {
                path: ROUTES.ESPOT_SHOWCASE_SITE,
                element: (
                  <Restricted
                    restricted={
                      process.env.REACT_APP_SERVER_REQUEST_STAGE === "PROD"
                    }>
                    <Lazy>
                      <ESpotShowcase />
                    </Lazy>
                  </Restricted>
                ),
              },
              {
                path: ROUTES.OFFLINE_SHOWCASE_SITE,
                element: (
                  <Restricted
                    restricted={
                      process.env.REACT_APP_SERVER_REQUEST_STAGE === "PROD"
                    }>
                    <Lazy>
                      <OfflineShowcase />
                    </Lazy>
                  </Restricted>
                ),
              },
            ],
          },
        ],
      },
      {
        element: <ErrorLayout />,
        children: [
          { path: ROUTES.ERROR_SITE, element: <ErrorSite /> },
          { path: ROUTES.MAINTENANCE, element: <Maintenance /> },
        ],
      },
    ],
  },
]);

const App: FC = () => <RouterProvider router={router} />;

export default App;
