/* eslint-disable no-nested-ternary */
import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import ProductSearchContainer from "components/productList/ProductSearchContainer";
import { useOnlyHomepageChecker } from "tools/useOnlyHomepageChecker";
import { useScrollToTop } from "tools/scrollRestoration";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "_redux/hooks";
import { TRIGGER_MARKETING_ACTION } from "_redux/actions/marketingEvent";
import { SEARCH_DISPLAY } from "constants/marketing";

const Search: FC = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const searchTerm = searchParams.get("searchTerm");
  const categoryId = searchParams.get("categoryId");
  const productListId = searchParams.get("productListId");
  const partNumbers = searchParams.get("partNumbers");
  const overrideHeadline = searchParams.get("headline");

  useOnlyHomepageChecker();
  useScrollToTop();

  let title = t("search.search");

  if (searchTerm && !categoryId && !productListId && !partNumbers) {
    title = t("search.searchBy", { searchTerm });
  }

  if (searchTerm === "*" && productListId) title = t("sites.myLists");

  useEffect(() => {
    if (searchTerm && searchTerm !== "*") {
      dispatch(
        TRIGGER_MARKETING_ACTION({
          searchTerm,
          DM_ReqCmd: SEARCH_DISPLAY,
        })
      );
    }
  }, [dispatch, searchTerm]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {(searchTerm || partNumbers || productListId) && (
        <ProductSearchContainer
          searchTerm={searchTerm}
          categoryId={categoryId}
          productListId={productListId}
          partNumbers={partNumbers}
          overrideHeadline={overrideHeadline}
        />
      )}
    </>
  );
};

export default Search;
