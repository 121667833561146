/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, Method, AxiosPromise } from "axios";
import { executeRequest } from "_foundation/axios/axiosConfig";
import { getSite } from "../../hooks/useSite";

const reportService = {
  getOrderHistory(parameters: any, url?: string): AxiosPromise<any> {
    const param = parameters;

    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.transactionContext || "";
    }
    const domain = url || siteContext;
    const path = "/store/{storeId}/efood/order_history/{organizationId}";
    let requestUrl = domain + path;
    const method: Method = "GET";
    let body = {};
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    const queryParameters = new URLSearchParams();

    if (param.storeId === undefined && site !== null) {
      param.storeId = site.storeID;
    }
    requestUrl = requestUrl.replace("{storeId}", param.storeId);

    if (param.storeId === undefined) {
      throw new Error(`Request '${path}' missing required parameter storeId`);
    }

    requestUrl = requestUrl.replace("{organizationId}", param.organizationId);

    if (param.organizationId === undefined) {
      throw new Error(
        `Request '${path}' missing required parameter organizationId`
      );
    }

    if (param.body !== undefined) {
      body = parameters.body;
    }

    if (param.responseFormat !== undefined) {
      const name = "responseFormat";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.body !== undefined) {
      body = parameters.body;
    }

    if (param.mode !== undefined) {
      const name = "mode";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.$queryParameters) {
      Object.keys(param.$queryParameters).forEach((parameterName) => {
        const parameter = param.$queryParameters[parameterName];
        queryParameters.set(parameterName, parameter);
      });
    }

    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      headers: header,
      data: body,
      url: requestUrl,
      ...parameters,
    };

    return executeRequest(requestOptions);
  },

  getOrderHistoryDetails(parameters: any, url?: string): AxiosPromise<any> {
    const param = parameters;

    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.transactionContext || "";
    }
    const domain = url || siteContext;
    const path =
      "/store/{storeId}/efood/order_history/details/{orderHistoryId}";
    let requestUrl = domain + path;
    const method: Method = "GET";
    let body = {};
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    const queryParameters = new URLSearchParams();

    if (param.storeId === undefined && site !== null) {
      param.storeId = site.storeID;
    }
    requestUrl = requestUrl.replace("{storeId}", param.storeId);

    if (param.storeId === undefined) {
      throw new Error(`Request '${path}' missing required parameter storeId`);
    }

    requestUrl = requestUrl.replace("{orderHistoryId}", param.orderHistoryId);

    if (param.orderHistoryId === undefined) {
      throw new Error(
        `Request '${path}' missing required parameter orderHistoryId`
      );
    }

    if (param.body !== undefined) {
      body = parameters.body;
    }

    if (param.responseFormat !== undefined) {
      const name = "responseFormat";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.body !== undefined) {
      body = parameters.body;
    }

    if (param.mode !== undefined) {
      const name = "mode";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.$queryParameters) {
      Object.keys(param.$queryParameters).forEach((parameterName) => {
        const parameter = param.$queryParameters[parameterName];
        queryParameters.set(parameterName, parameter);
      });
    }

    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      headers: header,
      data: body,
      url: requestUrl,
      ...parameters,
    };

    return executeRequest(requestOptions);
  },
  getOrderHistoryForProduct(parameters: any, url?: string): AxiosPromise<any> {
    const param = parameters;

    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.transactionContext || "";
    }
    const domain = url || siteContext;
    const path = "/store/{storeId}/efood/order_history/item/{partNumber}";
    let requestUrl = domain + path;
    const method: Method = "GET";
    let body = {};
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    const queryParameters = new URLSearchParams();

    if (param.storeId === undefined && site !== null) {
      param.storeId = site.storeID;
    }
    requestUrl = requestUrl.replace("{storeId}", param.storeId);

    if (param.storeId === undefined) {
      throw new Error(`Request '${path}' missing required parameter storeId`);
    }

    requestUrl = requestUrl.replace("{partNumber}", param.partNumber);

    if (param.partNumber === undefined) {
      throw new Error(
        `Request '${path}' missing required parameter partnumber`
      );
    }

    if (param.body !== undefined) {
      body = parameters.body;
    }

    if (param.responseFormat !== undefined) {
      const name = "responseFormat";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.body !== undefined) {
      body = parameters.body;
    }

    if (param.mode !== undefined) {
      const name = "mode";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.$queryParameters) {
      Object.keys(param.$queryParameters).forEach((parameterName) => {
        const parameter = param.$queryParameters[parameterName];
        queryParameters.set(parameterName, parameter);
      });
    }

    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      headers: header,
      data: body,
      url: requestUrl,
      ...parameters,
    };

    return executeRequest(requestOptions);
  },
};
export default reportService;
