/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import { useMemo } from "react";
// Standard libraries

import { useTranslation } from "react-i18next";
// Redux
import { useAppSelector } from "_redux/hooks";
import { siteSelector } from "../../../_redux/selectors/site";
import { CommerceEnvironment } from "../../../constants/common";

const useSite = () => {
  const { i18n } = useTranslation();
  const currentSite = useAppSelector(siteSelector);

  return useMemo(() => {
    let storeDisplayName = "";

    if (currentSite) {
      const lang = i18n.languages[0].split("-").join("_");
      const langId: string | undefined =
        CommerceEnvironment.reverseLanguageMap[lang];

      const description =
        currentSite.storeCfg.description?.find(
          (d) => d.languageId === langId
        ) ??
        currentSite.storeCfg.description?.find(
          (d) => d.languageId === currentSite.defaultLanguageID
        );
      storeDisplayName = description?.displayName || currentSite.storeName;
    }

    return {
      currentSite,
      storeDisplayName,
    };
  }, [i18n.languages, currentSite]);
};

export { useSite };
