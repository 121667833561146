// react
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { StatusCodes } from "http-status-codes";
import { isAxiosError } from "axios";
// mui
import { Typography, InputAdornment, IconButton, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  StyledButton,
  StyledFormInput,
  StyledNotification,
  StyledProgress,
  StyledTooltip,
} from "components/StyledUI";
// components
import loginIdentityServiceExt from "_foundationExt/apis/transaction/loginIdentity.service";
// types
import { ServerError } from "types/Error";
// assets
import { ReactComponent as VisibleIcon } from "assets/icons/view-1.svg";
import { ReactComponent as UnvisibleIcon } from "assets/icons/view-off.svg";
import { mapRefToInputRef } from "tools/convertUtils";

import getDisplayName from "tools/getDisplayName";
import { useAbortControllers } from "_foundationExt/hooks";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  content: {
    maxWidth: "370px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "310px",
      minWidth: "75vw",
    },
  },
  spacing: {
    marginBottom: theme.spacing(2),
  },
}));

interface LogonViewProps {
  setView: (view: number) => void;
}

const LogonView: FC<LogonViewProps> = ({ setView }) => {
  const widgetName = getDisplayName(LogonView);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const abortControllers = useAbortControllers();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ServerError>();
  const [showPassword, setShowPassword] = useState(false);
  const [redirectPath] = useState(
    encodeURIComponent(window.location.pathname + window.location.search)
  );

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, isDirty, isValid, touchedFields },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      logonId: "",
      logonPassword: "",
    },
  });

  const onSubmit = handleSubmit(async (form) => {
    setLoading(true);
    setError(undefined);

    try {
      const { signal } = abortControllers.create();

      const response = await loginIdentityServiceExt.redirectingLogin({
        body: {
          ...form,
        },
        widget: widgetName,
        signal,
      });

      if (response.status === StatusCodes.OK) {
        if (redirectPath && redirectPath !== "/") {
          window.location.href = `${response.data.Location}&redirectPath=${redirectPath}`;
        } else {
          window.location.href = response.data.Location;
        }
      }
    } catch (e) {
      if (isAxiosError(e)) {
        setError(e.response?.data.errors?.[0]);
      }
    } finally {
      setLoading(false);
    }
  });

  const tooltipViewIcon = t("iconTooltips.viewIcon");

  return (
    <Grid container className={classes.content}>
      <Grid xs={12}>
        <Box mb={3}>
          <Typography variant="h1" component="p">
            {t("registration.alreadyCustomer")}
          </Typography>
        </Box>

        {!!error && (
          <Box mb={3}>
            <StyledNotification severity="error">
              {error.errorMessage}
            </StyledNotification>
          </Box>
        )}
      </Grid>

      <Grid xs={12}>
        <form
          name="LoginForm"
          id="LoginForm"
          onSubmit={onSubmit}
          autoComplete="off">
          <StyledFormInput
            className={classes.spacing}
            id="logonId"
            label={t("header.username")}
            placeholder={`${t("header.username")}*`}
            fullWidth
            autoFocus
            {...mapRefToInputRef(
              register("logonId", {
                required: `${t("form.error.required")}`,
                onChange: () =>
                  touchedFields.logonPassword && trigger(["logonId"]),
                onBlur: () => trigger(["logonId"]),
              })
            )}
            error={!!errors.logonId}
            helperText={errors?.logonId?.message?.toString()}
          />

          <StyledFormInput
            className={classes.spacing}
            id="logonPassword"
            label={t("header.password")}
            placeholder={`${t("header.password")}*`}
            type={showPassword ? "text" : "password"}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <StyledTooltip
                    title={tooltipViewIcon}
                    enterDelay={2500}
                    enterNextDelay={2500}>
                    <IconButton
                      tabIndex={-1}
                      edge="end"
                      style={showPassword ? { marginTop: "-2px" } : {}}
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      size="large">
                      {showPassword ? <UnvisibleIcon /> : <VisibleIcon />}
                    </IconButton>
                  </StyledTooltip>
                </InputAdornment>
              ),
            }}
            {...mapRefToInputRef(
              register("logonPassword", {
                required: `${t("form.error.required")}`,
                onChange: () =>
                  touchedFields.logonPassword && trigger(["logonPassword"]),
                onBlur: () => trigger(["logonPassword"]),
              })
            )}
            error={!!errors.logonPassword}
            helperText={errors.logonPassword && errors.logonPassword.message}
          />

          <StyledButton
            fullWidth
            type="submit"
            disabled={loading || (touchedFields && !isDirty) || !isValid}>
            {loading ? (
              <StyledProgress size={24} color="inherit" />
            ) : (
              t("header.logon")
            )}
          </StyledButton>

          <StyledButton
            fullWidth
            variant="outlined"
            disabled={loading}
            onClick={() => setView(2)}>
            {t("registration.goToPlzSearch")}
          </StyledButton>
        </form>
      </Grid>
    </Grid>
  );
};
export default LogonView;
