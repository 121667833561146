import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { StyledButton, StyledProgress } from "components/StyledUI";
import defaultOfflineCartHandler from "pwa/offline/cart/OfflineCartHandler";
import { tss } from "tss-react/mui";
import { ContentProps } from "components/dialog/types";

const useStyles = tss.create(({ theme }) => ({
  wrapper: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
}));

export type SyncCartDataParams = {
  cancelable?: boolean;
};

const SyncCartData: FC<ContentProps<SyncCartDataParams>> = ({
  close,
  params,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [syncing, setSyncing] = useState(true);
  const [error, setError] = useState(false);

  const { cancelable = false } = params ?? {};

  useEffect(() => {
    (async () => {
      if (syncing) {
        try {
          await defaultOfflineCartHandler.syncCart();
          if (!cancelable) {
            close();
          }
        } catch {
          setError(true);
        } finally {
          setSyncing(false);
        }
      }
    })();
  }, [cancelable, syncing, close]);

  return (
    <>
      <div className={classes.wrapper}>
        <StyledProgress />
      </div>
      {error ? (
        <div className={classes.wrapper}>
          <Typography variant="caption" component="div">
            {t("offline.sync.error")}
          </Typography>
        </div>
      ) : null}
      {error || cancelable ? (
        <div className={classes.wrapper}>
          <StyledButton onClick={() => close()}>
            {t("action.close")}
          </StyledButton>
        </div>
      ) : null}
    </>
  );
};

export default SyncCartData;
