import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import customerProductlistService from "_foundationExt/apis/transaction/customerProductlist.service";

import { Menu, MenuItem } from "@mui/material";
import { StyledButton } from "components/StyledUI";

import {
  CustomerProductlist,
  CustomerProductlistCreateUpdatePayload,
} from "types/CustomerProductlist";

import { ReactComponent as ReorderIcon } from "assets/icons/reorder-up.svg";

import { useAppSelector } from "_redux/hooks";
import { userLastLocationChangeSelector } from "_redux/selectors/user";
import ReorderConfirmDialog from "./ReorderConfirmDialog";

interface Props {
  selectedCustomerProductList: CustomerProductlist | null;
  setStorageSorting?: (storageSorting: string) => void;
  refetch?: () => void;
}

const SortReorderStocklistMenu: FC<Props> = ({
  selectedCustomerProductList,
  setStorageSorting,
  refetch,
}) => {
  const { t } = useTranslation();

  const lastLocationChange = useAppSelector(userLastLocationChangeSelector);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showReorderConfirmDialog, setShowReorderConfirmDialog] =
    useState<boolean>(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSort = (direction) => {
    if (setStorageSorting) {
      setStorageSorting(`${direction}${lastLocationChange}`);

      if (selectedCustomerProductList) {
        const parameters: CustomerProductlistCreateUpdatePayload = {
          customerProductListId: selectedCustomerProductList.id,
          body: {
            name: selectedCustomerProductList.name,
            description: selectedCustomerProductList.description || "",
            type: selectedCustomerProductList.type,
            sortOrder: direction === "ascending" ? 0 : 1,
          },
        };
        customerProductlistService.updateList(parameters);
      }
    }
    handleClose();
  };

  return (
    <>
      <StyledButton
        onClick={handleClick}
        variant="outlined"
        startIcon={<ReorderIcon />}
        tooltip={t("iconTooltips.sortIcon")}
      />

      <Menu
        id="accountlist-sort-reorder-menu"
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleSort("ascending");
          }}>
          {t("app.ascending")}
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleSort("descending");
          }}>
          {t("app.descending")}
        </MenuItem>

        <MenuItem
          onClick={() => {
            setShowReorderConfirmDialog(true);
            handleClose();
          }}>
          {t("myAccount.listManagement.reorder")}
        </MenuItem>
      </Menu>

      {selectedCustomerProductList && (
        <ReorderConfirmDialog
          closeDialog={() => setShowReorderConfirmDialog(false)}
          showDialog={showReorderConfirmDialog}
          selectedCustomerProductList={selectedCustomerProductList}
          refetch={refetch}
          setStorageSorting={setStorageSorting}
        />
      )}
    </>
  );
};

export default React.memo(SortReorderStocklistMenu);
