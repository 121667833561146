/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, Method } from "axios";
import { OfflineAwareResponse, Resource } from "pwa/offline";
import { getSite } from "_foundation/hooks/useSite";
import addOfflineAwareRequestHeaders from "pwa/offline/addOfflineAwareRequestHeaders";
import { executeRequest } from "_foundationExt/axios/axiosConfig";

const productServiceExt = {
  byCategory(parameters: any, url?: string): Promise<OfflineAwareResponse> {
    const param = parameters;

    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.searchContext || "";
    }
    const domain = url || siteContext;
    const path = "/store/{storeId}/productview/byCategory/{categoryId}";
    let requestUrl = domain + path;
    const method: Method = "GET";
    const body = {};
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    const queryParameters = new URLSearchParams();

    if (param.storeId === undefined && site !== null) {
      param.storeId = site.storeID;
    }
    if (param.storeId === undefined) {
      throw new Error(`Request '${path}' missing required parameter storeId`);
    }
    requestUrl = requestUrl.replace("{storeId}", param.storeId);

    if (param.categoryId === undefined) {
      throw new Error(
        `Request '${path}' missing required parameter categoryId`
      );
    }
    requestUrl = requestUrl.replace("{categoryId}", param.categoryId);

    if (param.profileName !== undefined) {
      const name = "profileName";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    /**
     * "E" Perform deep search to retrieve all products within the current category and all the subcategories.
     * The default of this parameter in the expression is "N" for shallow search Navigation to return immediate products in the category.
     */
    queryParameters.set("searchSource", "E");

    if (param.$queryParameters) {
      Object.keys(param.$queryParameters).forEach((parameterName) => {
        const parameter = param.$queryParameters[parameterName];

        if (parameter instanceof Array) {
          parameter.forEach((value) => {
            queryParameters.append(parameterName, value);
          });
        } else {
          queryParameters.set(parameterName, parameter);
        }
      });
    }

    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      headers: header,
      data: body,
      url: requestUrl,
      ...parameters,
    };

    return executeRequest(
      addOfflineAwareRequestHeaders(
        {
          resource: Resource.ProductView,
          originMethod: "byCategory",
        },
        requestOptions
      )
    );
  },

  bySearchTerm(parameters: any, url?: string): Promise<OfflineAwareResponse> {
    const param = parameters;

    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.searchContext || "";
    }
    const domain = url || siteContext;
    const path = "/store/{storeId}/productview/bySearchTerm/{term}";
    let requestUrl = domain + path;
    const method: Method = "GET";
    const body = {};
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    const queryParameters = new URLSearchParams();

    if (param.storeId === undefined && site !== null) {
      param.storeId = site.storeID;
    }
    if (param.storeId === undefined) {
      throw new Error(`Request '${path}' missing required parameter storeId`);
    }
    requestUrl = requestUrl.replace("{storeId}", param.storeId);

    if (param.term === undefined) {
      throw new Error(`Request '${path}' missing required parameter term`);
    }
    requestUrl = requestUrl.replace("{term}", param.term);

    if (param.pageSize !== undefined) {
      queryParameters.set("pageSize", param.pageSize);
    }

    if (param.profileName !== undefined) {
      const name = "profileName";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.$queryParameters) {
      Object.keys(param.$queryParameters).forEach((parameterName) => {
        const parameter = param.$queryParameters[parameterName];

        if (parameter instanceof Array) {
          parameter.forEach((value) => {
            queryParameters.append(parameterName, value);
          });
        } else {
          queryParameters.set(parameterName, parameter);
        }
      });
    }

    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      headers: header,
      data: body,
      url: requestUrl,
      ...parameters,
    };

    return executeRequest(
      addOfflineAwareRequestHeaders(
        {
          resource: Resource.ProductView,
          originMethod: "bySearchTerm",
        },
        requestOptions
      )
    );
  },

  bySearchTermCategorized(
    isHeader: boolean,
    parameters: any,
    url?: string
  ): Promise<OfflineAwareResponse> {
    const param = parameters;

    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.searchContext || "";
    }

    const domain =
      url?.replace("search", "wcs") || siteContext.replace("search", "wcs");
    const path = isHeader
      ? "/store/{storeId}/efood/search/bySearchTermHeader/?searchTerm={term}"
      : "/store/{storeId}/efood/search/bySearchTerm/?searchTerm={term}";
    let requestUrl = domain + path;
    const method: Method = "GET";
    const body = {};
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    const queryParameters = new URLSearchParams();

    if (param.storeId === undefined && site !== null) {
      param.storeId = site.storeID;
    }
    if (param.storeId === undefined) {
      throw new Error(`Request '${path}' missing required parameter storeId`);
    }
    requestUrl = requestUrl.replace("{storeId}", param.storeId);

    if (param.term === undefined) {
      throw new Error(`Request '${path}' missing required parameter term`);
    }
    requestUrl = requestUrl.replace("{term}", param.term);

    if (param.pageSize !== undefined) {
      queryParameters.set("pageSize", param.pageSize);
    }

    if (param.profileName !== undefined) {
      const name = "profileName";
      delete param[name];
    }

    if (param.$queryParameters) {
      Object.keys(param.$queryParameters).forEach((parameterName) => {
        const parameter = param.$queryParameters[parameterName];

        if (parameter instanceof Array) {
          parameter.forEach((value) => {
            queryParameters.append(parameterName, value);
          });
        } else {
          queryParameters.set(parameterName, parameter);
        }
      });
    }

    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      headers: header,
      data: body,
      url: requestUrl,
      ...parameters,
      isHeader,
      signal: param.signal,
    };

    return executeRequest(
      addOfflineAwareRequestHeaders(
        {
          resource: Resource.ProductView,
          originMethod: "bySearchTermCategorized",
        },
        requestOptions
      )
    );
  },

  byPartNumbers(parameters: any, url?: string): Promise<OfflineAwareResponse> {
    const param = parameters;
    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.searchContext || "";
    }
    const domain = url || siteContext;
    const path = "/store/{storeId}/productview/bySearchTerm/{partNumbers}";
    let requestUrl = domain + path;
    const method: Method = "GET";
    const body = {};
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    const queryParameters = new URLSearchParams();

    if (param.storeId === undefined && site !== null) {
      param.storeId = site.storeID;
    }
    if (param.storeId === undefined) {
      throw new Error(`Request '${path}' missing required parameter storeId`);
    }
    requestUrl = requestUrl.replace("{storeId}", param.storeId);

    if (param.partNumbers === undefined || param.partNumbers.length <= 0) {
      throw new Error(
        `Request '${path}' missing required parameter partNumbers`
      );
    }
    requestUrl = requestUrl.replace(
      "{partNumbers}",
      param.partNumbers.join(" OR ")
    );

    if (param.pageSize !== undefined) {
      queryParameters.set("pageSize", param.pageSize);
    }

    if (param.profileName !== undefined) {
      const name = "profileName";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    } else {
      queryParameters.set("profileName", "EFood_findProductsByPartNumbers");
    }

    if (param.$queryParameters) {
      Object.keys(param.$queryParameters).forEach((parameterName) => {
        const parameter = param.$queryParameters[parameterName];

        if (parameter instanceof Array) {
          parameter.forEach((value) => {
            queryParameters.append(parameterName, value);
          });
        } else {
          queryParameters.set(parameterName, parameter);
        }
      });
    }

    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      headers: header,
      data: body,
      url: requestUrl,
      ...parameters,
    };

    return executeRequest(
      addOfflineAwareRequestHeaders(
        {
          resource: Resource.ProductView,
          originMethod: "byPartNumbers",
        },
        requestOptions
      )
    );
  },

  findByIds(parameters: any, url?: string): Promise<OfflineAwareResponse> {
    const param = parameters;

    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.transactionContext || "";
    }
    const domain = url || siteContext;
    const path = "/store/{storeId}/efood/productview/byIds";
    let requestUrl = domain + path;
    const method: Method = "GET";
    const body = {};
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    const queryParameters = new URLSearchParams();

    if (param.storeId === undefined && site !== null) {
      param.storeId = site.storeID;
    }
    if (param.storeId === undefined) {
      throw new Error(`Request '${path}' missing required parameter storeId`);
    }
    requestUrl = requestUrl.replace("{storeId}", param.storeId);

    if (param.pageSize !== undefined) {
      queryParameters.set("pageSize", param.pageSize);
    }

    if (param.profileName !== undefined) {
      const name = "profileName";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.filter(Boolean).forEach((value) => {
          queryParameters.append(name, value);
        });
      } else if (parameter) {
        queryParameters.set(name, parameter);
      }
    }

    if (param.$queryParameters) {
      Object.keys(param.$queryParameters).forEach((parameterName) => {
        const parameter = param.$queryParameters[parameterName];

        if (parameter instanceof Array) {
          parameter.filter(Boolean).forEach((value) => {
            queryParameters.append(parameterName, value);
          });
        } else if (parameter) {
          queryParameters.set(parameterName, parameter);
        }
      });
    }

    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      headers: header,
      data: body,
      url: requestUrl,
      ...parameters,
    };

    return executeRequest(
      addOfflineAwareRequestHeaders(
        {
          resource: Resource.ProductView,
          originMethod: "findByIds",
        },
        requestOptions
      )
    );
  },
};

export default { ...productServiceExt };
