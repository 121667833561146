import React, { FC } from "react";

import CardActions, { CardActionsProps } from "@mui/material/CardActions";

import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  root: {
    marginTop: "auto",
    padding: `${theme.spacing(3)} 0 0`,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
}));

const StyledCardActions: FC<CardActionsProps> = ({ children, ...rest }) => {
  const { classes } = useStyles();

  return (
    <CardActions classes={classes} {...rest}>
      {children}
    </CardActions>
  );
};

export { StyledCardActions };
