import { eFoodDB } from "pwa/db/efood.db";
import { Category } from "pwa/db/types";
import categoriesSyncTimestamp from "./categoriesSyncTimestamp";
import handleCategories from "./handleCategories";

const saveCategories = async (
  categories?: Array<Category>,
  timestamp?: unknown
) => {
  if (categories?.length) {
    const { categoriesWithIndices, deletedCategories } =
      handleCategories(categories);

    if (deletedCategories.length) {
      await eFoodDB.categories.bulkDelete(deletedCategories);
    }

    await eFoodDB.categories.bulkPut(categoriesWithIndices);
  }
  await categoriesSyncTimestamp.put(timestamp);
};

export default saveCategories;
