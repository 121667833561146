import { createReducer, AnyAction } from "@reduxjs/toolkit";
import { StatusCodes } from "http-status-codes";
import { get } from "lodash-es";
import * as ACTIONS from "_redux/action-types/order";
import { LOGOUT_SUCCESS_ACTION } from "_redux/actions/user";
import initStates from "./initStates";
import { OrderReducerState } from "./reducerStateInterface";

import {
  Approval,
  Availability,
  Basket,
  DeliveryDate,
  OrderItem,
  SubBasket,
} from "types/Order";
import { Product } from "types/Product";
import { BASKET_TYPE } from "constants/order";
import { ARTICLE_TYPE } from "constants/product";
import { getDeliveryDateDate } from "tools/delivery-date-helper";

const findOrderitemWithBasketAndSubBasket = (
  state: OrderReducerState,
  orderitemId: string
) => {
  for (const basket of state.baskets) {
    for (const subBasket of basket.subBaskets) {
      for (const orderitem of subBasket.orderitems) {
        if (orderitem.orderitemId === orderitemId) {
          return { orderitem, basket, subBasket };
        }
      }
    }
  }

  return { orderitem: null, basket: null, subBasket: null };
};

const findOrderitem = (
  state: OrderReducerState,
  orderitemId: string
): OrderItem | null =>
  state.baskets
    .flatMap((basket) => basket.subBaskets)
    .flatMap((subBasket) => subBasket.orderitems)
    .find((orderitem) => orderitem.orderitemId === orderitemId) || null;

export const createOrderitemFrom = (
  orderitemId: string,
  quantity: number,
  article: Product,
  availability: Availability
): OrderItem => {
  const matrixEntry = article.matrix.find((item) => item.handlingCode === "1");
  const priceEntry = article.prices?.find(
    (item) => item.matrixShortDescription === matrixEntry?.shortDesc
  );

  const price = priceEntry?.price || 0;
  const nowAsUTCString = new Date().toUTCString();

  return {
    orderitemId,
    quantity: quantity,
    total: quantity * price,
    price: price,
    article: article,
    availability: availability,
    adjustment: 0,
    createDate: nowAsUTCString,
    lastUpdate: nowAsUTCString,
    isFreeGift: false,
  };
};

const addOrderItemToBasket = (
  orderitem: OrderItem,
  state: OrderReducerState,
  stockDeliveryDate: DeliveryDate
): SubBasket => {
  const stockDeliveryDateDate = new Date(stockDeliveryDate.date);
  const basketType = determineBasketType(orderitem, stockDeliveryDateDate);
  const articleFlagAttribute =
    basketType === BASKET_TYPE.DROP_SHIP
      ? orderitem.article.attributes.find(
          (attribute) => attribute.identifier === "ARTICLEFLAG"
        )
      : null;
  const dropShipType =
    articleFlagAttribute && articleFlagAttribute.values.length > 0
      ? articleFlagAttribute.values[0].value
      : null;

  let basket = findBasket(state, basketType, dropShipType, orderitem);

  const articleType = orderitem.article.articleType;
  let subBasket = findSubBasket(basket, articleType);
  if (!subBasket) {
    if (!basket) {
      const deliveryDate = orderitem.availability.replenishmentDate
        ? new Date(orderitem.availability.replenishmentDate)
        : stockDeliveryDateDate;
      basket = createBasket(basketType, dropShipType, deliveryDate);
      state.baskets.push(basket);
    }
    subBasket = createSubBasket(articleType);
    basket.subBaskets.push(subBasket);
  }

  subBasket.orderitems.push(orderitem);

  return subBasket;
};

const removeOrderItemFromBasket = (
  orderitem: OrderItem,
  basket: Basket,
  subBasket: SubBasket,
  state: OrderReducerState
) => {
  let index = subBasket.orderitems.indexOf(orderitem);
  subBasket.orderitems.splice(index, 1);

  if (subBasket.orderitems.length === 0) {
    index = basket.subBaskets.indexOf(subBasket);
    basket.subBaskets.splice(index, 1);

    if (basket.subBaskets.length === 0) {
      index = state.baskets.indexOf(basket);
      state.baskets.splice(index, 1);
    }
  }
};

const determineBasketType = (
  orderitem: OrderItem,
  stockDeliveryDateDate: Date
): BASKET_TYPE => {
  // defaults to STOCK_OPTAIN
  let basketType = BASKET_TYPE.STOCK_OPTAIN;

  const articleType = orderitem.article.articleType;
  switch (articleType) {
    case ARTICLE_TYPE.OPTAIN:
      const replenishmentDate = orderitem.availability.replenishmentDate;
      if (replenishmentDate) {
        const replenishmentDateDate = new Date(replenishmentDate);
        if (stockDeliveryDateDate.getTime() < replenishmentDateDate.getTime()) {
          basketType = BASKET_TYPE.OPTAIN;
        }
      }
      break;
    case ARTICLE_TYPE.DROP_SHIP:
      basketType = BASKET_TYPE.DROP_SHIP;
      break;
  }

  return basketType;
};

const findBasket = (
  state: OrderReducerState,
  basketType: BASKET_TYPE,
  articleFlag: string | null,
  orderitem: OrderItem
): Basket | null => {
  let basket: Basket | null = null;

  const stockOptainBasket = state.baskets.find(
    (basket) => basket.identifier.type === BASKET_TYPE.STOCK_OPTAIN
  );

  switch (basketType) {
    case BASKET_TYPE.STOCK_OPTAIN:
      if (stockOptainBasket) {
        basket = stockOptainBasket;
      }
      break;
    case BASKET_TYPE.OPTAIN:
      const replenishmentDate = orderitem.availability.replenishmentDate;
      if (replenishmentDate) {
        const replenishmentDateDate = new Date(replenishmentDate);

        basket =
          state.baskets.find((basket) => {
            const {
              identifier: { type, deliveryDate },
            } = basket;
            const deliveryDateDate = getDeliveryDateDate(deliveryDate);
            return (
              type === BASKET_TYPE.OPTAIN &&
              deliveryDateDate &&
              replenishmentDateDate.getTime() === deliveryDateDate.getTime()
            );
          }) ?? null;
      } else if (stockOptainBasket) {
        basket = stockOptainBasket;
      }
      break;
    case BASKET_TYPE.DROP_SHIP:
      basket =
        state.baskets.find(
          (basket) =>
            basket.identifier.type === BASKET_TYPE.DROP_SHIP &&
            basket.identifier.dropShipType === articleFlag
        ) || null;
      break;
  }

  return basket;
};

const findSubBasket = (
  basket: Basket | null,
  articleType: ARTICLE_TYPE
): SubBasket | null => {
  if (!basket) {
    return null;
  }

  const basketType = basket.identifier.type;
  if (basketType === BASKET_TYPE.STOCK_OPTAIN) {
    if (articleType === ARTICLE_TYPE.STOCK) {
      return (
        basket.subBaskets.find(
          (subBasket) => subBasket.articleType === ARTICLE_TYPE.STOCK
        ) || null
      );
    } else if (articleType === ARTICLE_TYPE.OPTAIN) {
      return (
        basket.subBaskets.find(
          (subBasket) => subBasket.articleType === ARTICLE_TYPE.OPTAIN
        ) || null
      );
    }
  } else if (basketType === BASKET_TYPE.OPTAIN) {
    return basket.subBaskets[0];
  }

  return null;
};

const createBasket = (
  basketType: string,
  dropShipType: string | null,
  stockDeliveryDateDate: Date
): Basket => {
  return {
    identifier: {
      type: basketType,
      dropShipType,
      deliveryDate: {
        date: stockDeliveryDateDate,
      },
    },
    comment: null,
    earliestDeliveryDate: null,
    validation: null,
    subBaskets: [],
  };
};

const createSubBasket = (articleType: string): SubBasket => {
  return {
    articleType: articleType,
    orderitems: [],
    total: 0,
    totalProduct: 0,
    totalAdjustment: 0,
    totalShipping: 0,
  };
};

export const handleItemModifySuccess = ({
  cart,
  orderItemId,
  quantity,
  article,
  availability,
  stockDeliveryDate,
}: {
  cart: OrderReducerState;
  orderItemId: string;
  quantity: number;
  article: Product;
  availability: Availability;
  stockDeliveryDate: DeliveryDate;
}) => {
  let { orderitem, basket, subBasket } = findOrderitemWithBasketAndSubBasket(
    cart,
    orderItemId
  );

  let quantityBefore;
  if (orderitem) {
    quantityBefore = orderitem.quantity;

    if (quantity > 0) {
      orderitem.quantity = quantity;
      orderitem.total = quantity * orderitem.price;
    } else {
      removeOrderItemFromBasket(orderitem, basket!, subBasket!, cart);
    }
  } else {
    quantityBefore = 0;

    orderitem = createOrderitemFrom(
      orderItemId,
      quantity,
      article,
      availability
    );
    if (orderitem) {
      subBasket = addOrderItemToBasket(orderitem, cart, stockDeliveryDate);
    }
  }

  if (orderitem) {
    if (availability) {
      orderitem.availability = availability;
    }

    const quantityDifference = quantity - quantityBefore;
    const priceDifference = quantityDifference * orderitem.price;

    subBasket!.totalProduct += priceDifference;
    subBasket!.total += priceDifference;

    cart.totalProduct += priceDifference;
    cart.total += priceDifference;
    if (quantityBefore === 0) {
      cart.numItems += 1;
    }
    if (quantity === 0) {
      cart.numItems -= 1;
    }
  }
};

/**
 * Order reducer
 * handles states used by order related components
 * @param state State object managed by order reducer
 * @param action The dispatched action
 */

const orderReducer = createReducer(initStates.order, (builder) => {
  builder.addCase(ACTIONS.CART_FETCHING_REQUESTED, (state, _action) => {
    state.isFetching = true;
  });
  builder.addCase(ACTIONS.CART_GET_SUCCESS, (state, action: AnyAction) => {
    const response = action.response;
    if (response) {
      for (var prop in response) {
        if (response[prop] !== undefined) {
          state[prop] = response[prop];
        }
      }

      state.switchTimeExpired = response.switchTimeExpired;
    }
    state.isFetching = false;
  });
  builder.addCase(
    ACTIONS.CART_CHECK_APPROVAL_SUCCESS,
    (state, action: AnyAction) => {
      const approvals: Approval[] = action.response.approvals;
      if (approvals) {
        state.approvals = approvals;
      }
    }
  );
  builder.addCase(ACTIONS.ITEM_MODIFY_SUCCESS, (state, action: AnyAction) => {
    const { payload, response, stockDeliveryDate } = action;

    const orderItemId =
      action.orderitemId || response.orderItem?.[0]?.orderItemId;

    const quantity = Number(
      get(response, "orderItem[0].quantity", payload.quantity)
    );
    const { article } = payload;

    handleItemModifySuccess({
      cart: state,
      orderItemId,
      article,
      availability: response?.orderItem?.[0]?.availability,
      quantity,
      stockDeliveryDate,
    });
  });

  builder.addCase(
    ACTIONS.ITEM_CHECK_AVAILABILITY_SUCCESS,
    (state, action: AnyAction) => {
      const { availabilities } = action;

      availabilities.forEach((availabilityEntry) => {
        const orderItem = findOrderitem(state, availabilityEntry.orderItemId);
        if (orderItem) {
          orderItem.availability.pending = availabilityEntry.pending;
          orderItem.availability = availabilityEntry.availability;
        }
      });
    }
  );

  builder.addCase(
    ACTIONS.SET_ORDER_COMMENT_SUCCESS,
    (state, action: AnyAction) => {
      const { payload } = action;
      const { comment, basketIdentifier } = payload;

      state.baskets
        .filter((basket) =>
          basketIdentifier.dropShipType
            ? basket.identifier.dropShipType === basketIdentifier.dropShipType
            : !!!basket.identifier.dropShipType
        )
        .forEach((basket) => (basket.comment = comment));
    }
  );

  builder.addCase(ACTIONS.CART_GET_ERROR, (state, action: AnyAction) => {
    if (action?.error?.response?.status === StatusCodes.NOT_FOUND) {
      resetCartInfo(state, action);
    }
    state.isFetching = false;
  });

  builder.addCase(LOGOUT_SUCCESS_ACTION, resetCart);
  builder.addCase(ACTIONS.CART_RESET_REQUESTED, resetCart);
});

function resetCart(state: OrderReducerState, action: AnyAction) {
  resetCartInfo(state, action);
}

function resetCartInfo(state: OrderReducerState, action: AnyAction) {
  state.baskets = [];
  state.numItems = 0;
  state.total = 0;
  state.totalProduct = 0;
  state.totalAdjustment = 0;
  state.id = "";
  state.totalShipping = 0;
  state.shipInfos = null;
  state.validation = null;
  state.approvals = null;
  state.rewards = null;
  state.promotionCode = [];
  delete state.switchTimeExpired;
}

export default orderReducer;
