// react
import React, { FC, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { ROUTES } from "constants/routes";
// redux
import { useAppSelector } from "_redux/hooks";
import {
  loginStatusSelector,
  selectCustomerProductListsWithContractsSorted,
} from "_redux/selectors/user";
// mui
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
// comp

import { OrderInformation } from "components/widgets/order-information";
import { WidgetLayout } from "components/layout";
import ContactPerson from "components/contactPerson";

import { ReactComponent as VegetablesPlateIcon } from "assets/icons/vegetables-plate.svg";
import { ReactComponent as PersonIcon } from "assets/icons/single-neutral.svg";
import { ReactComponent as DiscountIcon } from "assets/icons/discount-circle.svg";
import { ReactComponent as OrdersetIcon } from "assets/icons/notes-checklist-flip.svg";

import { useEFoodConfig, useOnlineStatus } from "_foundationExt/hooks";
import Hidden from "components/Hidden";
import GridCardRecommendations from "./GridCardRecommendations";
import GridCardStatic from "./GridCardStatic";
import GridCardSlider from "./GridCartSlider";
import HomeBanner from "./HomeBanner";
import useUserName from "./useUserName";

const Home: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { onlyHomepage } = useEFoodConfig();
  const loggedIn = useAppSelector(loginStatusSelector);
  const { isOffline } = useOnlineStatus();
  const user = useUserName();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const sortedProductLists = useAppSelector(
    selectCustomerProductListsWithContractsSorted
  );

  const preparedProductLists = useMemo(
    () =>
      sortedProductLists
        .filter(Boolean)
        .map((item) => ({
          id: item.id,
          title: item.name,
          desc:
            item.type === "XOS"
              ? t("productListing.ordersetDescription")
              : item.description,
          sort: item.type,
        }))
        .filter((item) => item.sort !== "XLL"),
    [sortedProductLists, t]
  );

  const widgets = useMemo(() => {
    const result: React.ReactElement[] = [
      <OrderInformation />,
      <GridCardSlider
        key="customerProductList"
        small
        data={preparedProductLists}
        icon={<OrdersetIcon />}
      />,
    ];
    if (!onlyHomepage) {
      result.push(
        <GridCardStatic
          key="productCatalog"
          to={ROUTES.PRODUCT_CATALOG}
          small
          title="productCatalog.productCatalog"
          desc="productCatalog.productCatalogDescription"
          icon={<VegetablesPlateIcon />}
        />
      );
      if (!isOffline) {
        result.push(
          <GridCardStatic
            key="offers"
            to={ROUTES.OFFERS}
            small
            title="offers.title"
            desc="offers.description"
            icon={<DiscountIcon />}
          />
        );
      }
    }
    result.push(
      <GridCardStatic
        key="myAccount"
        to={ROUTES.ACCOUNT}
        small
        title="myAccount.myAccount"
        desc="myAccount.myAccountDescription"
        icon={<PersonIcon />}
      />
    );

    if (!smDown && !onlyHomepage) result.push(<ContactPerson />);

    return result;
  }, [isOffline, onlyHomepage, preparedProductLists, smDown]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_STORENAME} - {t("home.homePage")}
        </title>
      </Helmet>

      <HomeBanner loggedIn={loggedIn} user={user} />

      {(loggedIn && (
        <>
          <WidgetLayout widgets={widgets} />

          <Hidden condition={isOffline}>
            <Box mt={3}>
              <Grid container spacing={3}>
                <GridCardRecommendations eSpotName="HomePage_Marketing_Angemeldet" />
              </Grid>
            </Box>
          </Hidden>
        </>
      )) || (
        <Grid container spacing={3}>
          {!onlyHomepage && (
            <GridCardStatic
              xs={12}
              sm={6}
              md={4}
              to={ROUTES.PRODUCT_CATALOG}
              title="productCatalog.productCatalog"
              titleVariant="h1"
              desc="productCatalog.productCatalogDescription"
              icon={<VegetablesPlateIcon />}
              iconSize="120"
            />
          )}
          <Hidden condition={isOffline}>
            <GridCardRecommendations eSpotName="HomePage_Marketing_Nicht_Angemeldet" />
          </Hidden>
        </Grid>
      )}
    </>
  );
};

export default Home;
