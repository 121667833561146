import { takeLatest, takeEvery } from "redux-saga/effects";
import * as WORKERS from "../workers/order";
import {
  FETCHING_CART_ACTION,
  SET_ORDER_COMMENT_ACTION,
  ADD_ITEM_ACTION,
  UPDATE_ITEM_ACTION,
  REMOVE_ITEM_ACTION,
  MODIFY_ITEM_ACTION,
  CHECK_AVAILABILITY_ACTION,
} from "_redux/actions/order";
import {
  LOGIN_SUCCESS_ACTION,
  INIT_USER_FROM_STORAGE_SUCCESS_ACTION,
  RELOAD_CUSTOMER_DATA_ACTION,
} from "_redux/actions/user";

export function* watchSaga() {
  yield takeLatest(
    INIT_USER_FROM_STORAGE_SUCCESS_ACTION,
    WORKERS.initFromStorageFetchCart
  );

  yield takeLatest(ADD_ITEM_ACTION, WORKERS.addItem);
  yield takeLatest(REMOVE_ITEM_ACTION, WORKERS.removeItem);
  yield takeLatest(UPDATE_ITEM_ACTION, WORKERS.updateItem);
  yield takeLatest(MODIFY_ITEM_ACTION, WORKERS.modifyItem);
  yield takeLatest(CHECK_AVAILABILITY_ACTION, WORKERS.checkAvailability);

  yield takeLatest(
    [FETCHING_CART_ACTION, LOGIN_SUCCESS_ACTION, RELOAD_CUSTOMER_DATA_ACTION],
    WORKERS.fetchCart
  );

  yield takeLatest(SET_ORDER_COMMENT_ACTION, WORKERS.setOrderComment);
}
