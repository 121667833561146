import React, { FC } from "react";

import { RadioProps } from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

import { tss } from "tss-react/mui";
import { StyledRadio } from "./StyledRadio";

const useStyles = tss
  .withParams<Pick<StyledFormRadioProps, "size">>()
  .create(({ theme, size }) => ({
    root: {
      margin: 0,
    },
    label: {
      lineHeight: size === "small" ? "20px" : undefined,
      fontSize: size === "small" ? 14 : undefined,
    },
    disabled: {
      color: `${theme.palette.grey[600]} !important`,
    },
  }));

type StyledFormRadioProps = {
  size?: string;
  error?: boolean;
  radioProps: RadioProps;
  label?: string;
  className?: string;
};

const StyledFormRadio: FC<StyledFormRadioProps> = ({
  size,
  error,
  radioProps,
  className,
  label,
}) => {
  const { classes, cx } = useStyles({ size });

  return (
    <FormControlLabel
      classes={classes}
      className={cx(className)}
      label={label}
      control={
        <StyledRadio
          error={error}
          size={size === "small" ? "small" : "medium"}
          {...radioProps}
          inputProps={{
            name: radioProps.name,
          }}
        />
      }
    />
  );
};

export { StyledFormRadio };
