import { eFoodDB } from "pwa/db/efood.db";
import pricesSyncTimestamp from "./pricesSyncTimestamp";

const clearPricesData = () =>
  Promise.all([
    pricesSyncTimestamp.clear(),
    eFoodDB.products.toCollection().modify({ prices: [] }),
  ]);

export default clearPricesData;
