/* tslint:disable */
/* eslint-disable */
/**
 * EFood Storefront API
 * This is the EFood Storefront API specification.  Operations are grouped by tags.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { LookupStoreBy200Response } from '../../../../com/telekom-mms/efood/api/model';
/**
 * StoreLookupApi - axios parameter creator
 * @export
 */
export const StoreLookupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Lookup storeId determined by host given by parameter or determined from request.
         * @param {string} [host] The host for which the storeId should be determined. If empty, the host is retrieved from request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupStoreBy: async (host?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/site/efood/store_lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (host !== undefined) {
                localVarQueryParameter['host'] = host;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreLookupApi - functional programming interface
 * @export
 */
export const StoreLookupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreLookupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Lookup storeId determined by host given by parameter or determined from request.
         * @param {string} [host] The host for which the storeId should be determined. If empty, the host is retrieved from request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupStoreBy(host?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LookupStoreBy200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupStoreBy(host, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoreLookupApi - factory interface
 * @export
 */
export const StoreLookupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreLookupApiFp(configuration)
    return {
        /**
         * 
         * @summary Lookup storeId determined by host given by parameter or determined from request.
         * @param {string} [host] The host for which the storeId should be determined. If empty, the host is retrieved from request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupStoreBy(host?: string, options?: any): AxiosPromise<LookupStoreBy200Response> {
            return localVarFp.lookupStoreBy(host, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreLookupApi - object-oriented interface
 * @export
 * @class StoreLookupApi
 * @extends {BaseAPI}
 */
export class StoreLookupApi extends BaseAPI {
    /**
     * 
     * @summary Lookup storeId determined by host given by parameter or determined from request.
     * @param {string} [host] The host for which the storeId should be determined. If empty, the host is retrieved from request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreLookupApi
     */
    public lookupStoreBy(host?: string, options?: any) {
        return StoreLookupApiFp(this.configuration).lookupStoreBy(host, options).then((request) => request(this.axios, this.basePath));
    }
}
