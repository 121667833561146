import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { StyledButton } from "components/StyledUI";
import { ReactComponent as GiftIcon } from "assets/icons/products-gift-tag.svg";
import { Reward } from "types/Order";
import { rewardOptionIdsHandledSelector } from "_redux/selectors/user";
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import { REWARDS_HANDLED_ACTION } from "_redux/actions/user";
import { isEqual } from "lodash-es";
import { rewardsSelector } from "_redux/selectors/order";
import { tss } from "tss-react/mui";
import CartFreeProductDialog from "./CartFreeProductDialog";

const useStyles = tss.create(() => ({
  buttonText: {
    paddingLeft: 15,
  },
}));

interface CartFreeProductDialogProps {
  orderId: string;
}

const determineRewardOptionIdsNotHandled = (
  rewards: Reward[],
  rewardOptionIdsHandled: string[]
) =>
  rewards.reduce((resultRewards: string[], reward) => {
    let nextResultRewards = resultRewards;
    if (reward.numberOfGiftOptions === 1) {
      if (
        !rewardOptionIdsHandled.includes(reward.rewardOptions[0].rewardOptionId)
      ) {
        nextResultRewards.push(reward.rewardOptions[0].rewardOptionId);
      }
    } else {
      nextResultRewards = resultRewards.concat(
        reward.rewardOptions.reduce((resultOptions: string[], rewardOption) => {
          if (!rewardOptionIdsHandled.includes(rewardOption.rewardOptionId)) {
            resultOptions.push(rewardOption.rewardOptionId);
          }
          return resultOptions;
        }, [])
      );
    }

    return nextResultRewards;
  }, []);

const CartFreeProducts: FC<CartFreeProductDialogProps> = ({ orderId }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const rewards = useAppSelector(rewardsSelector, isEqual);
  const rewardOptionIdsHandled = useAppSelector(
    rewardOptionIdsHandledSelector,
    isEqual
  );
  const [showDialog, setShowDialog] = useState(false);

  const rewardOptionIdsNotHandled = useMemo(
    () => determineRewardOptionIdsNotHandled(rewards, rewardOptionIdsHandled),
    [rewards, rewardOptionIdsHandled]
  );

  const closeDialog = useCallback(() => {
    setShowDialog(false);
    dispatch(REWARDS_HANDLED_ACTION(rewardOptionIdsNotHandled));
  }, [rewardOptionIdsNotHandled, dispatch]);

  useEffect(() => {
    setShowDialog(rewardOptionIdsNotHandled.length > 0);
  }, [rewardOptionIdsNotHandled]);

  return rewards && rewards.length > 0 ? (
    <Box mr={2} display="inline-block">
      <StyledButton
        variant="outlined"
        size="small"
        onClick={() => {
          setShowDialog(true);
        }}
        startIcon={<GiftIcon />}>
        <span className={classes.buttonText}>{t("cart.freeProduct.free")}</span>
      </StyledButton>

      <CartFreeProductDialog
        showDialog={showDialog}
        closeDialog={closeDialog}
        rewards={rewards}
        orderId={orderId}
        rewardOptionIdsNotHandled={rewardOptionIdsNotHandled}
      />
    </Box>
  ) : null;
};

export default CartFreeProducts;
