import { RootReducerState } from "../reducers";
import {
  EXPIRED_ACTIVITY_TOKEN_ERROR,
  INVALID_COOKIE_ERROR_CODE,
  INVALID_COOKIE_ERROR_KEY,
  PASSWORD_EXPIRED_ERR_CODE,
  PASSWORD_EXPIRED,
  PARTIAL_AUTHENTICATION_ERROR_CODE,
  PARTIAL_AUTHENTICATION_ERROR_KEY,
  NOT_AUTHORIZED_FOR_QUERY,
  CMC_SESSION_ERROR_KEY,
  ACTIVITY_TOKEN_ERROR_KEY,
  ACTIVITY_TOKEN_ERROR_CODE,
  COOKIE_EXPIRED_ERROR_CODE,
  COOKIE_EXPIRED_ERROR_KEY,
  AUTHENTICATION_ERROR,
  PERSON_HAS_NO_ROLES_IN_STORE,
} from "../../constants/errors";
import { ErrorReducerState } from "../reducers/reducerStateInterface";
import { EXPIRED_PASSWORD_PAGE_ERROR } from "_foundation/constants/common";
import { createSelector } from "@reduxjs/toolkit";

export const authenticationErrors = [
  AUTHENTICATION_ERROR,
  PERSON_HAS_NO_ROLES_IN_STORE,
];
export const passwordExpiredErrors = [
  PASSWORD_EXPIRED_ERR_CODE,
  PASSWORD_EXPIRED,
];
export const sessionErrors = [
  EXPIRED_ACTIVITY_TOKEN_ERROR,
  INVALID_COOKIE_ERROR_CODE,
  INVALID_COOKIE_ERROR_KEY,
  ACTIVITY_TOKEN_ERROR_CODE,
  ACTIVITY_TOKEN_ERROR_KEY,
  PARTIAL_AUTHENTICATION_ERROR_CODE,
  PARTIAL_AUTHENTICATION_ERROR_KEY,
  NOT_AUTHORIZED_FOR_QUERY,
  CMC_SESSION_ERROR_KEY,
  COOKIE_EXPIRED_ERROR_CODE,
  COOKIE_EXPIRED_ERROR_KEY,
];

export const authenticationErrorSelector = (state: RootReducerState) => {
  const { error } = state;
  const { errorCode, errorKey, loginError } = error;

  if (loginError) {
    return error;
  }

  if (
    (errorCode && authenticationErrors.includes(errorCode)) ||
    (errorKey && authenticationErrors.includes(errorKey))
  ) {
    return error;
  }
};

export const sessionErrorSelector = (state: RootReducerState) => {
  const { error } = state;
  const { errorCode, errorKey } = error;

  if (
    (errorCode && sessionErrors.includes(errorCode)) ||
    (errorKey && sessionErrors.includes(errorKey))
  ) {
    return error;
  }
};

export const passwordExpiredErrorSelector = (state: RootReducerState) => {
  const { error } = state;
  const { errorCode, errorKey } = error;

  if (
    (errorCode && passwordExpiredErrors.includes(errorCode)) ||
    (errorKey && passwordExpiredErrors.includes(errorKey)) ||
    error[EXPIRED_PASSWORD_PAGE_ERROR]
  ) {
    return error;
  }
};

export const genericErrorSelector = (state: RootReducerState) => {
  const { error } = state;
  const { errorCode, errorKey } = error;

  if (
    !error ||
    !Object.keys(error).length ||
    error[EXPIRED_PASSWORD_PAGE_ERROR]
  ) {
    return undefined;
  }
  if (
    !(errorCode && sessionErrors.includes(errorCode)) &&
    !(errorKey && sessionErrors.includes(errorKey)) &&
    !(errorCode && passwordExpiredErrors.includes(errorCode)) &&
    !(errorKey && passwordExpiredErrors.includes(errorKey)) &&
    !(errorCode && authenticationErrors.includes(errorCode)) &&
    !(errorKey && authenticationErrors.includes(errorKey))
  ) {
    return error;
  }
  if (
    ((errorCode && sessionErrors.includes(errorCode)) ||
      (errorKey && sessionErrors.includes(errorKey))) &&
    error.sessionErrorLoginError
  ) {
    return error.sessionErrorLoginError as ErrorReducerState;
  }
};
export const cartErrorSelector = (state: RootReducerState) => state.error.cart;

export const selectAllOrderItemErrors = (state: RootReducerState) =>
  state.error?.cart?.orderitems;

// create selector to filter order item errors by partnumber
export const makeSelectOrderItemErrorByPartNumber = (partNumber: string) => {
  const selectOrderItemErrorByPartNumber = createSelector(
    [selectAllOrderItemErrors],
    (orderItemErrors) => {
      return orderItemErrors?.find(
        (orderitemError) => orderitemError.partNumber === partNumber
      );
    }
  );

  return selectOrderItemErrorByPartNumber;
};

export const errorSelector = (state: RootReducerState) => state.error;
