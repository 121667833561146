import { delay, call, put, select, spawn } from "redux-saga/effects";
import cartService from "_foundation/apis/transaction/cart.service";
import cartServiceExt from "_foundationExt/apis/transaction/cart.service";
import availabilityService from "_foundationExt/apis/transaction/availability.service";
import { ORDER_CONFIGS } from "../../../configs/order";
import * as ACTIONS from "../../action-types/order";
import {
  makeSelectOrderItemByPartNumberAndFreeGift,
  selectOrderItemsWithPendingAvailability,
} from "_redux/selectors/order";
import { OrderItem, RewardOption } from "types/Order";
import {
  loginStatusSelector,
  userStockDeliveryDate,
} from "_redux/selectors/user";
import { SessionUtils } from "components/matomo";
import { SHOW_DIALOG_ACTION } from "_redux/actions/site";

import {
  FETCH_DELIVERY_DATES_REQUESTED_ACTION,
  FETCH_DELIVERY_DATE_SUCCESS_ACTION,
} from "_redux/actions/user";
import { UPDATE_DELIVERY_DATE_SUCCESS } from "_redux/action-types/user";
import {
  ITEM_MODIFY_ERROR_ACTION,
  ITEM_MODIFY_WARNING_ACTION,
} from "_redux/actions/order";
import { onlineStatusSelector } from "_redux/selectors/site";
import { OnlineStatus } from "_redux/reducers";
import defaultOfflineCartHandler from "pwa/offline/cart/OfflineCartHandler";
import { isCanceledError } from "_foundationExt/axios/axiosConfig";

export function* addItem(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(cartService.addOrderItem, payload);
    yield put({ type: ACTIONS.ITEM_ADD_SUCCESS, response, payload });
    // yield put({ type: ACTIONS.CART_FETCHING_REQUESTED, payload });
  } catch (error) {
    yield put({ type: ACTIONS.ITEM_ADD_ERROR, error });
  }
}

export function* removeItem(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(cartService.deleteOrderItem, payload);
    yield put({ type: ACTIONS.ITEM_REMOVE_SUCCESS, response, payload });
    yield put({ type: ACTIONS.CART_FETCHING_REQUESTED, payload });
  } catch (error) {
    yield put({ type: ACTIONS.ITEM_REMOVE_ERROR, error });
  }
}

export function* updateItem(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(cartService.updateOrderItem, payload);
    yield put({ type: ACTIONS.ITEM_UPDATE_SUCCESS, response, payload });
    yield put({ type: ACTIONS.CART_FETCHING_REQUESTED, payload });
  } catch (error) {
    yield put({ type: ACTIONS.ITEM_UPDATE_ERROR, error });
  }
}

export function* modifyItem(action: any) {
  yield spawn(modifyItemSpawned, action);
}

function* modifyItemSpawned(action: any) {
  const { payload } = action;
  const { quantity, article, trackType, signal } = payload;
  const { partNumber, eFoodPartnumber, catEntryId } = article;

  // check if cart sync is needed
  const userIsLoggedIn: boolean = yield select(loginStatusSelector);
  const onlineStatus: OnlineStatus = yield select(onlineStatusSelector);
  const isOnline = onlineStatus.status === "online";
  const isSyncNeeded: boolean =
    userIsLoggedIn &&
    isOnline &&
    (yield call({
      context: defaultOfflineCartHandler,
      fn: defaultOfflineCartHandler.isCartSyncNeeded,
    }));
  if (isSyncNeeded) {
    yield call({
      context: defaultOfflineCartHandler,
      fn: defaultOfflineCartHandler.syncCart,
    });
  }

  try {
    let checkAvailability = false;
    const orderItem = yield select(
      makeSelectOrderItemByPartNumberAndFreeGift(partNumber, false)
    );
    if (orderItem) {
      if (quantity > 0) {
        // update
        const requestPayload = {
          body: {
            orderItem: [
              {
                quantity: quantity.toString(),
                orderItemId: orderItem.orderitemId,
                partNumber,
              },
            ],
          },
          signal,
        };

        const response = yield call(
          cartService.updateOrderItem,
          requestPayload
        );
        const updatedOrderItem = response.data?.orderItem?.[0] || {};
        const { quantity: updatedQuantity, rounded } = updatedOrderItem;

        if (
          (updatedQuantity === quantity || rounded) &&
          (!signal || !signal.aborted)
        ) {
          yield put({
            type: ACTIONS.ITEM_MODIFY_SUCCESS,
            response: response.data,
            payload: payload,
            orderitemId: orderItem.orderitemId,
          });
          yield put(
            ITEM_MODIFY_WARNING_ACTION({
              payload,
              response: response.data,
            })
          );
          if (response.data.orderItem) {
            checkAvailability =
              response.data.orderItem[0]["availability"].pending;
          }
        } else {
          yield put(
            ITEM_MODIFY_ERROR_ACTION({
              ...payload,
              error: {
                errorType: "QUANTITY_NOT_UPDATED",
                response: response.data,
              },
            })
          );
        }
      } else {
        // delete
        const body = {
          body: {
            orderItemId: orderItem.orderitemId,
          },
        };
        const response = yield call(cartService.deleteOrderItem, body);

        yield put({
          type: ACTIONS.ITEM_MODIFY_SUCCESS,
          response: response.data,
          payload: payload,
          orderitemId: orderItem.orderitemId,
        });

        if (eFoodPartnumber) {
          SessionUtils.removeSessionMap("inCart", eFoodPartnumber);
        }
      }
    } else {
      if (quantity > 0) {
        // add
        const body = {
          body: {
            orderItem: [
              {
                quantity: quantity.toString(),
                partNumber,
              },
            ],
          },
          signal,
        };

        const response = yield call(cartService.addOrderItem, body);
        const stockDeliveryDate = yield select(userStockDeliveryDate);
        yield put({
          type: ACTIONS.ITEM_MODIFY_SUCCESS,
          response: response.data,
          payload: payload,
          orderItem: {
            ...response.data.orderItem[0],
            catEntryId: catEntryId ?? article.uniqueID,
          },
          stockDeliveryDate: stockDeliveryDate,
        });
        yield put(
          ITEM_MODIFY_WARNING_ACTION({
            payload,
            response: response.data,
          })
        );
        if (response.data.orderItem) {
          checkAvailability =
            response.data.orderItem[0]["availability"].pending;
        }

        if (trackType && eFoodPartnumber) {
          SessionUtils.setSessionMap("inCart", eFoodPartnumber, trackType);
        }
      }
    }

    if (checkAvailability && (!signal || !signal.aborted)) {
      yield put({ type: ACTIONS.ITEM_CHECK_AVAILABILITY_REQUESTED });
    }

    if (payload.reloadCart && (!signal || !signal.aborted)) {
      yield put({ type: ACTIONS.CART_FETCHING_REQUESTED });
    }
  } catch (error) {
    if (!isCanceledError(error)) {
      yield put(ITEM_MODIFY_ERROR_ACTION({ error, partNumber, article }));
    }
  }
}

export function* checkAvailability(action: any) {
  let orderItems: OrderItem[] = [];
  do {
    orderItems = yield select(selectOrderItemsWithPendingAvailability);
    if (orderItems?.length > 0) {
      const orderItemIds: string[] = orderItems.map(
        (orderItem) => orderItem.orderitemId
      );

      const response = yield call(
        availabilityService.getAvailability,
        orderItemIds
      );

      yield put({
        type: ACTIONS.ITEM_CHECK_AVAILABILITY_SUCCESS,
        availabilities: response.data.availabilities,
      });

      yield delay(3000);
    }
  } while (orderItems?.length > 0);
}

export function* initFromStorageFetchCart(action: any) {
  const { WCToken } = action.payload || {};
  if (!!WCToken) {
    yield* fetchCart(action);
  }
}

export function* fetchCart(action: any) {
  try {
    const payload = action.payload;

    const calculateOrder = payload?.calculateOrder || false;
    if (calculateOrder || action.type === "LOGIN_SUCCESS") {
      yield call(cartService.calculateOrder, {
        body: {
          calculationUsageId: [-1, -5],
        },
      });
    }

    const refreshAvailabilities = payload?.refreshAvailabilities || false;
    const parameters = {
      ...payload,
      sortOrderItemBy: ORDER_CONFIGS.sortOrderItemBy,
      query: {
        refreshAvailabilities: refreshAvailabilities,
      },
    };

    let responseCart = yield call(cartService.getCart, { ...parameters });

    let callCartAgain = false;
    const singleGiftRewards = responseCart.data.rewards.filter(
      (reward) => reward.numberOfGiftOptions === 1
    );
    for (const singleGiftReward of singleGiftRewards) {
      if (singleGiftReward.rewardOptions.length > 1) {
        const firstRewardOption = singleGiftReward.rewardOptions[0];
        const rewardOptionsNeedAction: RewardOption[] = [];

        singleGiftReward.rewardOptions
          .filter(
            (rewardOption) =>
              firstRewardOption.rewardOptionId !== rewardOption.rewardOptionId
          )
          .forEach((rewardOption) => {
            if (
              firstRewardOption.rewardChoice.giftSet.giftItem.length !==
              rewardOption.rewardChoice.giftSet.giftItem.length
            ) {
              rewardOptionsNeedAction.push(rewardOption);
            }
          });

        for (const rewardOptionNeedAction of rewardOptionsNeedAction) {
          const catEntryIds =
            firstRewardOption.rewardChoice.giftSet.giftItem.map(
              (giftItem) => giftItem.uniqueID
            );
          const quantities =
            firstRewardOption.rewardChoice.giftSet.giftItem.map(
              (giftItem) => giftItem.quantity.value
            );
          const parameters = {
            body: {
              orderId: responseCart.data.id,
              rewardOptionId: rewardOptionNeedAction.rewardOptionId,
              catEntryId: catEntryIds,
              quantity: quantities,
            },
          };

          yield call(cartServiceExt.updateRewardChoice, { ...parameters });
          callCartAgain = true;
        }
      }
    }

    if (callCartAgain) {
      responseCart = yield call(cartService.getCart, { ...parameters });
    }

    yield put({
      type: ACTIONS.CART_GET_SUCCESS,
      response: responseCart.data,
    });

    if (refreshAvailabilities) {
      const checkAvailability = responseCart.data.baskets.some((basket) =>
        basket.subBaskets.some((subBasket) =>
          subBasket.orderitems.some(
            (orderitem) => orderitem.availability.pending
          )
        )
      );
      if (checkAvailability) {
        yield put({ type: ACTIONS.ITEM_CHECK_AVAILABILITY_REQUESTED });
      }
    }

    const responseApproval = yield call(
      cartServiceExt.checkApproval,
      responseCart.data.id
    );
    yield put({
      type: ACTIONS.CART_CHECK_APPROVAL_SUCCESS,
      response: responseApproval.data,
    });

    if (responseCart.data.switchTimeExpired) {
      if (responseCart.data.stockDeliveryDate) {
        yield put(
          FETCH_DELIVERY_DATE_SUCCESS_ACTION(
            responseCart.data.stockDeliveryDate
          )
        );
        yield put({
          type: UPDATE_DELIVERY_DATE_SUCCESS,
          response: responseCart,
          payload,
        });
      }
      yield put(FETCH_DELIVERY_DATES_REQUESTED_ACTION({}));
      yield put(
        SHOW_DIALOG_ACTION({
          type: "DeliveryDateExpired",
          params: {
            time: responseCart.data.switchTimeExpired,
          },
        })
      );
    }
  } catch (error: any) {
    yield put({
      type: ACTIONS.CART_GET_ERROR,
      error,
    });
  }
}

export function* setOrderComment(action: any) {
  try {
    const payload = action.payload;
    const orderId = payload.orderId;
    const basketIdentifier = payload.basketIdentifier;
    const comment = payload.comment;
    const body = {
      body: {
        orderId,
        basketIdentifier,
        comment,
      },
    };
    const response = yield call(cartServiceExt.setOrderComment, body);
    yield put({ type: ACTIONS.SET_ORDER_COMMENT_SUCCESS, response, payload });
  } catch (error) {
    yield put({ type: ACTIONS.SET_ORDER_COMMENT_ERROR, error });
  }
}
