/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useCallback } from "react";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDatePickerProps,
  PickersActionBarProps,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { de } from "date-fns/locale/de";
import {
  InputAdornment,
  Typography,
  Box,
  TextFieldVariants,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar-3.svg";
import {
  StyledButton,
  StyledFormInput,
  StyledIcon,
  StyledLink,
} from "components/StyledUI";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as LeftIcon } from "assets/icons/arrow-left-1.svg";
import { ReactComponent as RightIcon } from "assets/icons/arrow-right-1.svg";
import { theme as defaultTheme } from "theme/theme";
import { useTranslation } from "react-i18next";
import { formatDateLocal } from "tools/stringUtils";
import { useOnlineStatus } from "_foundationExt/hooks/useOnlineStatus";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  inline: {
    display: "flex",
    alignItems: "center",
  },
  border: {
    borderBottom: `1px dashed ${theme.palette.grey[300]}`,
  },
  svg: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  button: {
    backgroundColor: "transparent !important",
    padding: "4px 8px",
    fontSize: 14,
    textTransform: "none",
    letterSpacing: 0.5,
    "&:focus": {
      color: theme.palette.primary.dark,
    },
    "&:hover": {
      color: theme.palette.primary.light,
    },

    [theme.breakpoints.up("sm")]: {
      padding: "4px 12px",
    },
  },
  arrow: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
}));

const materialTheme = createTheme(defaultTheme, {
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          minWidth: "304px",
        },
        input: {
          cursor: "pointer",
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginRight: "4px !important",
          "& svg": {
            height: "24px",
            color: defaultTheme.palette.grey[800],
            pointerEvents: "none",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: defaultTheme.palette.grey[800],
          borderRadius: "4px",
          padding: "8px",
          backgroundColor: defaultTheme.palette.grey[50],
          "&:hover": {
            color: defaultTheme.palette.background.default,
            backgroundColor: defaultTheme.palette.primary.light,
            "@media (hover: none)": {
              backgroundColor: defaultTheme.palette.grey[50],
              color: defaultTheme.palette.grey[800],
            },
          },
        },
      },
    },
  },
});

const datePickerStyle = {
  ".MuiPickersLayout-contentWrapper": {
    padding: "0 2px",
    gridColumn: 1,
  },
  ".actionBarButton": {
    gridColumn: 1,
    gridRow: 3,
    color: defaultTheme.palette.background.default,
    width: "100%",
    backgroundColor: defaultTheme.palette.primary.main,
    "&:hover": {
      backgroundColor: defaultTheme.palette.primary.light,
      "@media (hover: none)": {
        backgroundColor: defaultTheme.palette.grey[50],
        color: defaultTheme.palette.grey[800],
      },
    },
  },
  ".MuiPickersCalendarHeader-root": {
    paddingRight: "22px",
    marginBottom: "16px",
    ".MuiPickersCalendarHeader-labelContainer": {
      button: {
        display: "none",
      },
    },
  },
  ".MuiPickersArrowSwitcher-root": {
    gap: "8px",
  },
  ".MuiDayCalendar-header": {
    columnGap: 1,
    borderBottom: `1px dashed ${defaultTheme.palette.grey[300]}`,
    margin: "0 22px 12px 22px",
    ".MuiDayCalendar-weekDayLabel": {
      fontSize: "0.8rem",
      fontWeight: 600,
    },
  },
  ".MuiDayCalendar-monthContainer": {
    display: "grid",
    spacing: 1,
  },
  ".MuiPickersDay-root": {
    borderRadius: "2px",
    "&.Mui-selected, &:focus.Mui-selected": {
      backgroundColor: defaultTheme.palette.primary.main,
    },
    "&.Mui-disabled:not(.Mui-selected)": {
      color: defaultTheme.palette.grey[700],
    },
    "&:not(.Mui-disabled):not(.Mui-selected)": {
      backgroundColor: defaultTheme.palette.grey[200],
      "&:hover": {
        color: defaultTheme.palette.background.default,
        backgroundColor: defaultTheme.palette.primary.main,
      },
    },
    "&.MuiPickersDay-today": {
      border: 0,
      "&:not(.Mui-selected)": {
        color: defaultTheme.palette.primary.light,
        "&:hover": {
          color: defaultTheme.palette.background.default,
        },
      },
    },
  },
} as const;

const StyledDatePickerInput = ({ handleClick, selectedDate, error }) => {
  const { isOffline } = useOnlineStatus();

  return (
    <StyledFormInput
      id="datepicker"
      onClick={
        isOffline
          ? () => {
              /* do nothing, or show popup? */
            }
          : handleClick
      }
      value={formatDateLocal(selectedDate) ?? ""}
      error={error || false}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <CalendarIcon />
          </InputAdornment>
        ),
        readOnly: true,
      }}
      disabled={isOffline}
    />
  );
};

const StyledDatePickerIcon = ({ classes, handleClick }) => {
  const { t } = useTranslation();
  const { isOffline } = useOnlineStatus();

  return (
    <StyledButton
      className={classes.button}
      aria-describedby="date-flyout"
      onClick={handleClick}
      disabled={isOffline}>
      <StyledIcon size="40" tooltip={t("iconTooltips.calendarIcon")}>
        <CalendarIcon />
      </StyledIcon>
    </StyledButton>
  );
};

const StyledDatePickerText = ({ handleClick }) => {
  const { t } = useTranslation();
  const { isOffline } = useOnlineStatus();

  return (
    <StyledLink
      whiteText
      small
      to="#"
      onClick={
        isOffline
          ? () => {
              /* do nothing, or show info popup? */
            }
          : handleClick
      }>
      {t("cart.selectDeliveryDay")}
    </StyledLink>
  );
};

const StyledDatePickerToolbar = ({ classes, handleClick, selectedDate }) => {
  const { t } = useTranslation();

  return (
    <Box p={3} pb={2}>
      <Grid className={classes.border}>
        <Grid container xs={12} justifyContent="space-between">
          <Grid xs={10}>
            <Typography variant="h2">{t("cart.myDeliverDay")}</Typography>
          </Grid>
          <Grid xs={2}>
            <StyledButton
              className="close"
              startIcon={<CloseIcon />}
              size="small"
              color="inherit"
              onClick={handleClick}
              tooltip={t("iconTooltips.closeIcon")}
            />
          </Grid>
        </Grid>
        <Box alignItems="center" mt={2} pb={1.5}>
          <Grid xs={12} className={classes.inline}>
            <div>
              <CalendarIcon className={classes.svg} />
            </div>
            <Typography variant="body2">
              {formatDateLocal(selectedDate, "EEEE, 'den' dd. MMMM yyyy")}
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};

const StyledDatePickerActionBar = ({
  onAccept,
  onCancel,
}: PickersActionBarProps) => {
  const { t } = useTranslation();

  return (
    <Grid padding={3}>
      <StyledButton
        className="actionBarButton"
        fullWidth
        aria-describedby="date-flyout"
        onClick={() => {
          onAccept();
          // needed to close datepicker when date was not changed
          onCancel();
        }}>
        {t("action.accept")}
      </StyledButton>
    </Grid>
  );
};

type StyledDatePickerProps = {
  selectedDate?: Date | null;
  callback?: () => void;
  error?: boolean;
  variant?: "text" | "icon";
  inputVariant?: TextFieldVariants;
};

const referenceDate = new Date();
referenceDate.setUTCHours(0, 0, 0, 0);

const StyledDatePicker: FC<
  StyledDatePickerProps & MobileDatePickerProps<Date>
> = React.forwardRef((props, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { classes } = useStyles();

  const {
    error,
    selectedDate,
    onAccept,
    callback,
    variant,
    inputVariant,
    ...rest
  } = props;

  const [internalDate, setInternalDate] = React.useState(
    selectedDate ? new Date(selectedDate) : null
  );

  const [open, setOpen] = React.useState(false);

  const handleOnAccept = useCallback(
    (date: Date | null) =>
      setOpen((isOpen) => {
        if (isOpen && callback) callback();
        if (isOpen && onAccept) onAccept(date);
        return !isOpen;
      }),
    [callback, onAccept]
  );

  const handleClick = useCallback(
    () =>
      setOpen((isOpen) => {
        if (isOpen) {
          setInternalDate(selectedDate ? new Date(selectedDate) : null);
          if (callback) callback();
        }
        return !isOpen;
      }),
    [callback, setInternalDate, selectedDate]
  );

  const getTextFieldComponent = () => {
    if (variant === "text") return StyledDatePickerText({ handleClick });
    if (variant === "icon")
      return StyledDatePickerIcon({ classes, handleClick });
    return StyledDatePickerInput({ handleClick, selectedDate, error });
  };

  const getToolbarComponent = () =>
    StyledDatePickerToolbar({ classes, handleClick, selectedDate });

  const left = () => <LeftIcon className={classes.arrow} />;
  const right = () => <RightIcon className={classes.arrow} />;

  React.useEffect(() => {
    if (selectedDate) {
      setInternalDate(new Date(selectedDate));
    }
  }, [selectedDate]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={materialTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
          <MobileDatePicker
            value={internalDate}
            onChange={setInternalDate}
            dayOfWeekFormatter={
              // is needed to render days with two letters abbreviation
              (day) => day
            }
            timezone="UTC"
            disablePast
            referenceDate={referenceDate}
            slotProps={{
              textField: { variant: inputVariant ?? "filled" },
              layout: {
                sx: { ...datePickerStyle },
              },
              dialog: {
                onClose: (_event, _reason) => {
                  /* ignore at escapeKeyDown and backDropClick */
                },
              },
            }}
            slots={{
              field: getTextFieldComponent,
              toolbar: getToolbarComponent,
              actionBar: StyledDatePickerActionBar,
              leftArrowIcon: left,
              rightArrowIcon: right,
            }}
            open={open}
            onAccept={handleOnAccept}
            onClose={() => setOpen(false)}
            {...rest}
            ref={ref}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
});

export { StyledDatePicker };
