/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
// react
import React, { FC, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
// redux
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import personService from "_foundationExt/apis/transaction/person.service";
import * as userAction from "_redux/actions/user";
import loginIdentityServiceExt from "_foundationExt/apis/transaction/loginIdentity.service";
import { genericErrorSelector } from "_redux/selectors/error";
import { RESET_ERROR_ACTION } from "_redux/actions/error";
// mui
import {
  Typography,
  Box,
  useMediaQuery,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as VisibleIcon } from "assets/icons/view-1.svg";
import { ReactComponent as UnvisibleIcon } from "assets/icons/view-off.svg";
import {
  StyledNotification,
  StyledFormInput,
  StyledButton,
  StyledDivider,
  StyledContainer,
  StyledProgress,
  StyledTooltip,
} from "components/StyledUI";
// components
import { ROUTES } from "constants/routes";
import { sessionStorageUtil } from "_foundationExt/utils/storageUtil";
import { mapRefToInputRef } from "tools/convertUtils";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  actions: {
    flexDirection: "row-reverse",
    "& .grid-item:first-of-type": {
      textAlign: "right",
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(2),
      },
    },
  },
}));

const ExpiredPassword: FC = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const breakpointDownXs = useMediaQuery(theme.breakpoints.down("sm"));
  const genericError = useAppSelector(genericErrorSelector);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [redirect, setRedirect] = useState(false);
  const [showPassword, setShowPassword] = useState({
    1: false,
    2: false,
    3: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, touchedFields },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [form, setForm] = useState({
    logonId: sessionStorageUtil.get("u") ? sessionStorageUtil.get("u") : "",
    logonPasswordOld: sessionStorageUtil.get("p")
      ? sessionStorageUtil.get("p")
      : "",
    logonPassword: "",
    logonPasswordVerify: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const onSubmit = () => {
    dispatch(RESET_ERROR_ACTION());

    setLoading(true);
    setSuccess(false);
    setError(false);

    const parameters = {
      action: "changePassword",
      body: {
        ...form,
      },
    };

    personService
      .updatePassword(parameters)
      .then(() => {
        setSuccess(true);
        const params = {
          body: {
            ...form,
          },
        };
        loginIdentityServiceExt
          .login(params)
          .then((response2) => {
            dispatch(userAction.LOGIN_SUCCESS_ACTION(response2.data));
            dispatch(
              userAction.LOGIN_REQUESTED_ACTION({
                requestPayload: response2.data,
              })
            );
            setRedirect(true);
          })
          .catch(() => {
            setError(true);
          })
          .finally(() => {
            setLoading(false);
            sessionStorageUtil.remove("u");
            sessionStorageUtil.remove("p");
          });
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tooltipViewIcon = t("iconTooltips.viewIcon");

  return (
    <>
      <Helmet>
        <title>{t("password.expiredPassword")}</title>
      </Helmet>

      <StyledContainer size="small">
        <Box mb={3}>
          <Typography variant="h1">{t("password.expiredPassword")}?</Typography>
        </Box>

        {loading && <StyledProgress />}

        {redirect && <Navigate to={ROUTES.HOME} replace />}

        {success && (
          <Box mb={3}>
            <StyledNotification severity="success">
              {t("password.passwordHasChanged")}
            </StyledNotification>
          </Box>
        )}

        {error && (
          <Box mb={3}>
            <StyledNotification severity="error">
              {t("password.passwordWrong")}
            </StyledNotification>
          </Box>
        )}

        {!!genericError?.errorMessage && (
          <Box mb={1}>
            <StyledNotification severity="error">
              {genericError.errorMessage}
            </StyledNotification>
          </Box>
        )}

        <Typography paragraph align="right">
          * {t("form.mandatory")}
        </Typography>

        <form
          name="ResetPasswordForm"
          id="ResetPasswordForm"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off">
          <Typography paragraph variant="h3">
            {t("password.username")}
          </Typography>

          <StyledFormInput
            id="logonId"
            label={t("password.username")}
            placeholder={`${t("password.username")}*`}
            fullWidth
            autoFocus
            {...mapRefToInputRef(
              register("logonId", {
                required: t("form.error.required") ?? "required",
                onChange: handleInputChange,
              })
            )}
            error={!!errors.logonId}
            helperText={errors?.logonId?.message?.toString()}
          />

          <Box mt={2}>
            <Typography paragraph variant="h3">
              {t("password.oldPassword")}
            </Typography>
          </Box>

          <StyledFormInput
            id="logonPasswordOld"
            label={t("password.oldPassword")}
            type={showPassword[1] ? "text" : "password"}
            placeholder={`${t("password.oldPassword")}*`}
            fullWidth
            autoFocus
            {...mapRefToInputRef(
              register("logonPasswordOld", {
                required: t("form.error.required") ?? "required",
                onChange: handleInputChange,
              })
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <StyledTooltip
                    title={tooltipViewIcon}
                    enterDelay={2500}
                    enterNextDelay={2500}>
                    <IconButton
                      tabIndex={-1}
                      edge="end"
                      style={showPassword[1] ? { marginTop: "-2px" } : {}}
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          1: !showPassword[1],
                        })
                      }
                      size="large">
                      {showPassword[1] ? <UnvisibleIcon /> : <VisibleIcon />}
                    </IconButton>
                  </StyledTooltip>
                </InputAdornment>
              ),
            }}
            error={!!errors.logonPasswordOld}
            helperText={errors?.logonPasswordOld?.message?.toString()}
          />

          <Box mt={2}>
            <Typography paragraph variant="h3">
              {t("password.newPassword")}
            </Typography>
          </Box>

          <StyledFormInput
            id="logonPassword"
            label={t("password.newPassword")}
            type={showPassword[2] ? "text" : "password"}
            placeholder={`${t("password.newPassword")}*`}
            fullWidth
            autoFocus
            {...mapRefToInputRef(
              register("logonPassword", {
                required: t("form.error.required") ?? "required",
                onChange: handleInputChange,
              })
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <StyledTooltip
                    title={tooltipViewIcon}
                    enterDelay={2500}
                    enterNextDelay={2500}>
                    <IconButton
                      tabIndex={-1}
                      edge="end"
                      style={showPassword[2] ? { marginTop: "-2px" } : {}}
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          2: !showPassword[2],
                        })
                      }
                      size="large">
                      {showPassword[2] ? <UnvisibleIcon /> : <VisibleIcon />}
                    </IconButton>
                  </StyledTooltip>
                </InputAdornment>
              ),
            }}
            error={!!errors.logonPassword}
            helperText={errors?.logonPassword?.message?.toString()}
          />

          <Box mt={2}>
            <Typography paragraph variant="h3">
              {t("password.newPasswordRetry")}
            </Typography>
          </Box>

          <StyledFormInput
            id="logonPasswordVerify"
            label={t("password.newPasswordRetry")}
            type={showPassword[3] ? "text" : "password"}
            placeholder={`${t("password.newPasswordRetry")}*`}
            fullWidth
            autoFocus
            {...mapRefToInputRef(
              register("logonPasswordVerify", {
                required: t("form.error.required") ?? "required",
                validate: (value) =>
                  value === form.logonPassword ||
                  (t("password.differentPasswords") ?? "error"),
                onChange: handleInputChange,
              })
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <StyledTooltip
                    title={tooltipViewIcon}
                    enterDelay={2500}
                    enterNextDelay={2500}>
                    <IconButton
                      tabIndex={-1}
                      edge="end"
                      style={showPassword[3] ? { marginTop: "-2px" } : {}}
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          3: !showPassword[3],
                        })
                      }
                      size="large">
                      {showPassword[3] ? <UnvisibleIcon /> : <VisibleIcon />}
                    </IconButton>
                  </StyledTooltip>
                </InputAdornment>
              ),
            }}
            error={!!errors.logonPasswordVerify}
            helperText={errors?.logonPasswordVerify?.message?.toString()}
          />

          <StyledDivider light py={3} />

          <Grid container className={classes.actions}>
            <Grid xs={12} sm={6} className="grid-item">
              <StyledButton
                fullWidth={breakpointDownXs}
                type="submit"
                disabled={(touchedFields && !isDirty) || !isValid}>
                {t("password.passwordChange")}
              </StyledButton>
            </Grid>
            <Grid xs={12} sm={6} className="grid-item">
              <StyledButton
                fullWidth={breakpointDownXs}
                variant="outlined"
                onClick={() => navigate(-1)}>
                {t("action.back")}
              </StyledButton>
            </Grid>
          </Grid>
        </form>
      </StyledContainer>
    </>
  );
};

export default ExpiredPassword;
