import React, { FC } from "react";
import { LinearProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SyncState } from "pwa/offline/sync/common/types";
import LinearProgressWithLabel from "./LinearProgressWithLabel";

const ShowSyncState: FC<{ syncState: SyncState; labelKey: string }> = ({
  syncState: { error, recordSetTotal, recordSetCount, loading },
  labelKey,
}) => {
  const { t } = useTranslation();

  if (error && error.code === "ERR_CANCELED") {
    return (
      <Typography variant="caption" component="div">
        {t("offline.sync.state.canceled", { labelKey })}
      </Typography>
    );
  }

  if (error) {
    return (
      <Typography variant="caption" component="div">
        {t("offline.sync.state.error", { labelKey })}
      </Typography>
    );
  }

  if (loading) {
    return (
      <>
        <Typography variant="caption" component="div">
          {t("offline.sync.state.running", { labelKey })}
        </Typography>

        <div>
          {recordSetTotal == null ? (
            <LinearProgress variant="indeterminate" />
          ) : (
            <LinearProgressWithLabel
              variant="determinate"
              minValue={0}
              maxValue={recordSetTotal}
              value={recordSetCount}
            />
          )}
        </div>
      </>
    );
  }

  if (
    !error &&
    (typeof recordSetCount === "number" || typeof recordSetTotal === "number")
  ) {
    return (
      <Typography variant="caption" component="div">
        {t("offline.sync.state.finished", {
          count: recordSetCount ?? recordSetTotal,
          labelKey,
        })}
      </Typography>
    );
  }

  return null;
};

export default ShowSyncState;
