import React, { FC } from "react";

import Radio, { RadioProps } from "@mui/material/Radio";
import { tss } from "tss-react/mui";

const useStyles = tss
  .withParams<Pick<StyledRadioProps, "size">>()
  .create(({ theme, size }) => ({
    root: {
      padding: size === "small" ? "0 8px 0 0" : "0 12px 0 0",
    },
    disabled: {
      "& input + span": {
        color: `${theme.palette.grey[600]} !important`,
        backgroundColor: `${theme.palette.grey[50]} !important`,
        borderColor: `${theme.palette.grey[50]} !important`,
      },
    },
  }));

const useIconStyles = tss
  .withParams<Pick<StyledRadioProps, "error" | "size">>()
  .create(({ theme, size, error }) => ({
    icon: {
      width: size === "small" ? 24 : 32,
      height: size === "small" ? 24 : 32,
      backgroundColor: theme.palette.grey[50],
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: error
        ? theme.palette.secondary.main
        : theme.palette.grey[50],
      borderRadius: "50%",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
      ]),
      "input:hover ~ &": {
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.primary.main,
      },
      "input:focus ~ &": {
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.primary.main,
      },
      "input:active ~ &": {
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.primary.dark,
      },
    },
    checkedIcon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderColor: error
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
      borderWidth: size === "small" ? "6px" : "8px",
      "input:hover ~ &": {
        borderColor: theme.palette.primary.light,
      },
      "input:focus ~ &": {
        borderColor: theme.palette.primary.light,
      },
      "input:active ~ &": {
        borderColor: theme.palette.primary.dark,
      },
    },
  }));

type StyledRadioProps = RadioProps & {
  error?: boolean;
};

const StyledRadio: FC<StyledRadioProps> = ({ size, error, ...rest }) => {
  const { classes } = useStyles({ size });
  const { classes: iconClasses, cx } = useIconStyles({ size, error });

  return (
    <Radio
      classes={classes}
      color="default"
      checkedIcon={
        <span className={cx(iconClasses.icon, iconClasses.checkedIcon)} />
      }
      icon={<span className={iconClasses.icon} />}
      {...rest}
    />
  );
};

export { StyledRadio };
