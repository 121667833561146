import React, { useRef, useState } from "react";
import { t } from "i18next";
import { tss } from "tss-react/mui";

const useStyles = tss.create(() => ({
  root: {
    width: "100%",
  },
  errorWrapper: {
    width: "100%",
    backgroundImage: `url("/images/error/videoError.jpg")`,
    filter: "grayscale(.5)",
    backgroundPosition: "center",
  },
  errorRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  errorText: {
    textAlign: "center",
  },
}));

type NavigatorWithConnection = typeof navigator & {
  connection: { effectiveType: "slow-2g" | "2g" | "3g" | "4g" };
};

const isNavigatorWithConnection = (
  param: Navigator
): param is NavigatorWithConnection => param && "connection" in param;

const CardMediaVideo: React.FC<{
  videoSrc: string;
  className?: string;
}> = ({ videoSrc, className }) => {
  const { classes, cx } = useStyles();
  const [error, setError] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  let connectionEffectiveType = "4g";

  if (isNavigatorWithConnection(navigator)) {
    const { connection } = navigator;
    connectionEffectiveType = connection.effectiveType;
  }
  const isSlowConnection =
    connectionEffectiveType === "slow-2g" || connectionEffectiveType === "2g";

  if (error) {
    return (
      <div className={cx(className, classes.errorWrapper)}>
        <div className={classes.errorRoot}>
          <span className={classes.errorText}>{t("error.videoError")}</span>
        </div>
      </div>
    );
  }

  return (
    <video
      className={cx(className, classes.root)}
      src={videoSrc}
      autoPlay={isSlowConnection ? undefined : true}
      loop
      muted
      preload="metadata"
      ref={videoRef}
      onError={() => {
        setError(true);
      }}
    />
  );
};

export default CardMediaVideo;
