import React, { FC } from "react";
import { Skeleton } from "@mui/material";
import { tss } from "tss-react/mui";

const useStyles = tss.create(() => ({
  skeletonTop: {
    width: "50%",
    marginBottom: "20px",
  },
  skeleton: {
    width: "50%",
  },
}));

const StyledSkeletonCategoriesAccordion: FC = () => {
  const { classes } = useStyles();

  return (
    <>
      <Skeleton animation="wave" className={classes.skeletonTop} />
      <Skeleton animation="wave" className={classes.skeleton} />
      <Skeleton
        animation="wave"
        className={classes.skeleton}
        style={{ marginLeft: "20px" }}
      />
      <Skeleton
        animation="wave"
        className={classes.skeleton}
        style={{ marginLeft: "40px" }}
      />
      <Skeleton
        animation="wave"
        className={classes.skeleton}
        style={{ marginLeft: "40px" }}
      />
      <Skeleton
        animation="wave"
        className={classes.skeleton}
        style={{ marginLeft: "20px" }}
      />
      <Skeleton animation="wave" className={classes.skeleton} />
      <Skeleton animation="wave" className={classes.skeleton} />
    </>
  );
};

export { StyledSkeletonCategoriesAccordion };
