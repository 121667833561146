import { createReducer } from "@reduxjs/toolkit";
import initStates from "./initStates";
import { CATEGORYVIEW_TOP_REQUEST_ACTION } from "_redux/actions/catalog";

const catalogReducer = createReducer(initStates.catalog, (builder) => {
  builder.addCase(CATEGORYVIEW_TOP_REQUEST_ACTION, (state, action) => {
    const { payload } = action;
    const categoryTop = payload?.data?.catalogGroupView || [];
    Object.assign(state, { categoryTop });
  });
});
export default catalogReducer;
