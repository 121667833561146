const getEnvironmentType = (hostname?: string) => {
  if (hostname) {
    const regexProduction = /(.*)(.servicebund.com)/g;
    const matchesProduction = regexProduction.exec(hostname);

    if (matchesProduction && matchesProduction.length === 3) {
      const regexEnvironment = /(.*)-(.*)-(.*)(.servicebund.com)/g;
      const matchesEnvironment = regexEnvironment.exec(hostname);

      if (
        !matchesEnvironment ||
        (matchesEnvironment.length > 2 && matchesEnvironment[2] === "prod")
      ) {
        return "prod";
      }
      if (
        matchesEnvironment &&
        matchesEnvironment.length > 2 &&
        matchesEnvironment[2] === "qa"
      ) {
        return "qa";
      }
      if (
        matchesEnvironment &&
        matchesEnvironment.length > 2 &&
        matchesEnvironment[2] === "test"
      ) {
        return "test";
      }
    }
  }

  return "development";
};

export type EnvironmentType = ReturnType<typeof getEnvironmentType>;

export default getEnvironmentType;
