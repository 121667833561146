/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, Method, AxiosPromise } from "axios";
import { executeRequest } from "_foundation/axios/axiosConfig";
import { getSite } from "_foundation/hooks/useSite";

const categoriesServiceExt = {
  getCategoryViewTop(parameters: any, url?: string): AxiosPromise<any> {
    const param = parameters;

    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.searchContext || "";
    }

    const domain = url || siteContext;
    const path = "/store/{storeId}/categoryview/@top";
    let requestUrl = domain + path;
    const method: Method = "GET";
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    const queryParameters = new URLSearchParams();
    const body = {};

    if (param.storeId === undefined && site !== null) {
      param.storeId = site.storeID;
    }

    if (parameters.storeId === undefined) {
      throw new Error(`Request '${path}' missing required parameter storeId`);
    }

    requestUrl = requestUrl.replace("{storeId}", parameters.storeId);

    if (param.depthAndLimit !== undefined) {
      const name = "depthAndLimit";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.id !== undefined) {
      const name = "id";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.identifier !== undefined) {
      const name = "identifier";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.parentCategoryId !== undefined) {
      const name = "parentCategoryId";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.storeId !== undefined) {
      const name = "storeId";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.$queryParameters) {
      Object.keys(param.$queryParameters).forEach((parameterName) => {
        const parameter = param.$queryParameters[parameterName];
        queryParameters.set(parameterName, parameter);
      });
    }

    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      headers: header,
      data: body,
      url: requestUrl,
      ...parameters,
    };
    return executeRequest(requestOptions);
  },
};

export default categoriesServiceExt;
