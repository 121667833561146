import React from "react";
import { Link } from "react-router-dom";
import {
  Element,
  DOMNode,
  domToReact,
  HTMLReactParserOptions,
  attributesToProps,
} from "html-react-parser";
import { Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";

type ReplaceFunctionType = HTMLReactParserOptions["replace"];

const mapLink: ReplaceFunctionType = (domNode) => {
  const node = domNode as Element;
  const { attribs, children } = node;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { href, ...attributes } = attributesToProps(attribs);

  // Absolute Link
  if (/^[a-zA-Z][a-zA-Z\d+\-.]*:/.test(attribs.href)) {
    return (
      <a href={attribs.href} {...attributes} target="_blank" rel="noreferrer">
        {domToReact(children as DOMNode[])}
      </a>
    );
  }

  // Relative Link
  return (
    <Link to={attribs.href} {...attributes}>
      {domToReact(children as DOMNode[])}
    </Link>
  );
};

const mapNodeToReactComponent: ReplaceFunctionType = (domNode, index) => {
  // "instanceof Element" type guard doesn't work here
  // so check for the required attribute instead ...
  if (domNode.type !== "tag" || !("name" in domNode)) {
    return undefined;
  }
  const node = domNode as Element;

  if (node.type === "tag" && node.name === "a") {
    return mapLink(node, index);
  }

  return undefined;
};

const mapNodeToMUIComponent: ReplaceFunctionType = (domNode, index) => {
  // "instanceof Element" type guard doesn't work here
  // so check for the required attribute instead ...
  if (domNode.type !== "tag" || !("name" in domNode)) {
    return undefined;
  }
  const node = domNode as Element;
  const children = node.children as DOMNode[];

  if (["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(node.name) !== -1) {
    const attributes = attributesToProps(node.attribs);
    return (
      <Typography variant={node.name as Variant} {...attributes}>
        {domToReact(children, { replace: mapNodeToMUIComponent })}
      </Typography>
    );
  }
  if (node.name === "p") {
    const attributes = attributesToProps(node.attribs);
    return (
      <Typography variant="body1" component={node.name} {...attributes}>
        {domToReact(children, { replace: mapNodeToMUIComponent })}
      </Typography>
    );
  }

  return mapNodeToReactComponent(domNode, index);
};

export { mapNodeToReactComponent, mapNodeToMUIComponent };
