import React, { FC } from "react";

import { Box } from "@mui/material";
import { StyledButton } from "components/StyledUI";
import { ReactComponent as ListViewIcon } from "assets/icons/navigation-menu.svg";
import { LISTVIEW_COOKIE_TYPE } from "./ListViewConstants";

interface ListViewButtonProps {
  listViewMode: string;
  setListViewMode: (string) => void;
}

const ListViewButton: FC<ListViewButtonProps> = ({
  listViewMode,
  setListViewMode,
}) => (
  <Box mr={0}>
    {listViewMode === LISTVIEW_COOKIE_TYPE.LIST ? (
      <StyledButton startIcon={<ListViewIcon />} />
    ) : (
      <StyledButton
        variant="outlined"
        startIcon={<ListViewIcon />}
        onClick={() => setListViewMode(LISTVIEW_COOKIE_TYPE.LIST)}
      />
    )}
  </Box>
);

export default ListViewButton;
