// Standard libraries
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { StyledLink } from "components/StyledUI";

import { ROUTES } from "constants/routes";
import { Typography, Box } from "@mui/material";

interface SpellCheckSuggestionsProps {
  suggestions: string[];
  searchTerm?: string | null;
  marginBottom?: number | undefined;
  onClick?: () => void;
}

const SpellCheckSuggestions: FC<SpellCheckSuggestionsProps> = ({
  suggestions,
  searchTerm,
  marginBottom,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography>{t("search.spellCheckHint", { searchTerm })}</Typography>

      {suggestions?.length > 0 && (
        <Box display="flex" mt={2} mb={marginBottom}>
          <Typography>{t("search.didYouMean")}</Typography>

          {suggestions.map((item) => (
            <StyledLink
              style={{ marginLeft: "16px" }}
              to={`${ROUTES.PRODUCT_DETAIL}${ROUTES.SEARCH}?searchTerm=${item}`}
              onClick={onClick}>
              {item}
            </StyledLink>
          ))}
        </Box>
      )}
    </>
  );
};

export default SpellCheckSuggestions;
