import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import { FETCH_OFFERS_SUCCESS_ACTION } from "_redux/actions/user";
import {
  loginStatusSelector,
  userInitStatusSelector,
} from "_redux/selectors/user";
import offerService from "_foundationExt/apis/transaction/offer.service";
import { Offer } from "types/Offer";
import { useSite } from "_foundationExt/hooks";

const useFetchOffers = () => {
  const { currentSite } = useSite();
  const userInitStatus = useAppSelector(userInitStatusSelector);
  const userLoggedIn = useAppSelector(loginStatusSelector);
  const dispatch = useAppDispatch();

  const [offers, setOffers] = useState<Offer[]>();

  useEffect(() => {
    if (currentSite?.storeID && userInitStatus) {
      const fetchOffers = async () => {
        const offerResponse = await offerService.getOffers();
        const data = offerResponse.data.offers as Offer[] | undefined;

        setOffers(data);
        dispatch(FETCH_OFFERS_SUCCESS_ACTION(data));
      };

      fetchOffers();
    }
  }, [currentSite?.storeID, userInitStatus, userLoggedIn, dispatch]);

  return offers;
};

export default useFetchOffers;
