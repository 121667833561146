import React, { FC, useCallback, useMemo } from "react";
import { useAppDispatch } from "_redux/hooks";
import {
  RESET_PRODUCTLIST_FILTER,
  UPDATE_PRODUCTLIST_FILTER,
} from "_redux/actions/user";
import { StyledButton, StyledFilterTag } from "components/StyledUI";
import { Filter } from "types/Search";
import Grid from "@mui/material/Unstable_Grid2";
import { ReactComponent as BinIcon } from "assets/icons/bin-1.svg";
import { CustomerProductlist } from "types/CustomerProductlist";
import { useNavigate, useLocation } from "react-router-dom";
import { QUERY_STRING_PARAM_PAGE } from "_foundationExt/hooks/usePagination";

interface FilterSelectedOptionRowProps {
  filterList: Filter[];
  selectedProductlistFilter: CustomerProductlist[];
}

const FilterSelectedOptionRow: FC<FilterSelectedOptionRowProps> = ({
  filterList,
  selectedProductlistFilter,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = useMemo(
    () =>
      new URLSearchParams(
        location.search ? location.search.substring(1) : undefined
      ),
    [location.search]
  );

  const hashParams = useMemo(
    () =>
      new URLSearchParams(
        location.hash ? location.hash.substring(1) : undefined
      ),
    [location.hash]
  );

  const selectedOptionData = useMemo(() => {
    if (!hashParams.size) {
      return [];
    }

    return filterList
      .flatMap((filter) => {
        const filterValue = hashParams.getAll(filter.value) || [];
        if (filterValue.length) {
          const filterOptions = filterValue.map((value) => {
            const filterEntries = filter.entry.find(
              (item) => item.label === value
            );
            return {
              filterName: filter.name,
              optionName: filterEntries?.label || "",
              optionValue: filter.value,
            };
          });
          return filterOptions;
        }
        return [];
      })
      .filter(Boolean);
  }, [filterList, hashParams]);

  const removeAllParamFromUrl = useCallback(() => {
    const tmpSearchParams = new URLSearchParams(searchParams);
    tmpSearchParams.delete(QUERY_STRING_PARAM_PAGE);

    dispatch(RESET_PRODUCTLIST_FILTER());

    navigate({
      pathname: location.pathname,
      search: `?${tmpSearchParams.toString()}`,
      hash: "", // removes all filter parameter
    });
  }, [dispatch, location.pathname, navigate, searchParams]);

  const removeParamFromUrl = useCallback(
    (key, value) => {
      const tmpHashParams = new URLSearchParams(hashParams);
      const oldHashValues = tmpHashParams.getAll(key);
      const remainingHashValues = oldHashValues.filter(
        (oldValue) => oldValue !== value
      );
      tmpHashParams.delete(key);
      if (remainingHashValues.length) {
        remainingHashValues.forEach((remainingValue) =>
          tmpHashParams.append(key, remainingValue)
        );
      }

      const tmpSearchParams = new URLSearchParams(searchParams);
      tmpSearchParams.delete(QUERY_STRING_PARAM_PAGE);

      navigate({
        pathname: location.pathname,
        search: `?${tmpSearchParams.toString()}`,
        hash: `#${tmpHashParams.toString()}`,
      });
    },
    [hashParams, location.pathname, navigate, searchParams]
  );

  return (
    <Grid container spacing={1}>
      {selectedOptionData.map((selectedOptionItem) => (
        <Grid key={selectedOptionItem.optionValue}>
          <StyledFilterTag
            label={`${selectedOptionItem.filterName} ${selectedOptionItem.optionName}`}
            onClick={() =>
              removeParamFromUrl(
                selectedOptionItem.optionValue,
                selectedOptionItem.optionName
              )
            }
          />
        </Grid>
      ))}

      {selectedProductlistFilter.map((selectedList) => (
        <Grid key={`selectedProductlistFilter_${selectedList.id}`}>
          <StyledFilterTag
            label={selectedList.name}
            onClick={() => {
              const updatedFilterList = selectedProductlistFilter.filter(
                (list) => list.id !== selectedList.id
              );
              if (updatedFilterList.length > 0) {
                dispatch(UPDATE_PRODUCTLIST_FILTER(updatedFilterList));
              } else {
                dispatch(RESET_PRODUCTLIST_FILTER());
              }
            }}
          />
        </Grid>
      ))}

      <Grid>
        <StyledButton
          color="secondary"
          size="small"
          startIcon={<BinIcon />}
          onClick={removeAllParamFromUrl}
        />
      </Grid>
    </Grid>
  );
};

export default FilterSelectedOptionRow;
