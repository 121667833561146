import { ROUTES } from "constants/routes";
import { BreadcrumbData } from "./StyledBreadcrumb";

const maxStringLength = 30;

interface determineBreadcrumbDataReturn {
  link: string;
  value: string;
  label: string;
}

function determineBreadcrumbData(
  breadcrumbItem: BreadcrumbData,
  matchesXsViewport: boolean,
  listLength: number,
  index: number
): determineBreadcrumbDataReturn | null {
  if (!breadcrumbItem.label) {
    return null;
  }

  // the breadcrumb items before the penultimate on xs viewport should not be visible
  if (matchesXsViewport && listLength > 1 && index < listLength - 2) {
    return null;
  }

  // penultimate breadcrumb item on xs viewport should have the label "..."
  if (matchesXsViewport && listLength > 1 && index === listLength - 2) {
    return {
      link:
        breadcrumbItem.link || `${ROUTES.PRODUCT_LIST}/${breadcrumbItem.value}`,
      value: breadcrumbItem.value,
      label: "...",
    };
  }

  return {
    link:
      breadcrumbItem.link || `${ROUTES.PRODUCT_LIST}/${breadcrumbItem.value}`,
    value: breadcrumbItem.value,
    label: breadcrumbItem.label.substring(0, maxStringLength),
  };
}

export default determineBreadcrumbData;
