import React, { FC, PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import { ReactComponent as DownIcon } from "assets/icons/arrow-down-1.svg";
import { StyledButton } from "components/StyledUI";
import { tss } from "tss-react/mui";

interface StyledAccordionProps {
  headline: React.ReactNode;
  datePicker?: React.ReactNode;
  datePickerCaption?: React.ReactNode;
  hideDatePicker?: boolean;
  dark?: boolean;
  thick?: boolean;
  small?: boolean;
  large?: boolean;
  expanded?: boolean;
  disabled?: boolean;
  noBorder?: boolean;
  additionalHeadline?: string;
}

const useStyles = tss.create(({ theme }) => ({
  accordion: {
    boxShadow: "none",
    backgroundColor: "transparent",
    "&:before": {
      height: "0px",
    },
    "&.lightBorder": {
      borderTop: `1px dashed ${theme.palette.grey[300]}`,
    },
    "&.darkBorder": {
      borderTop: `1px dashed ${theme.palette.grey[800]}`,
    },
    "&.dark": {
      color: theme.palette.common.white,
    },
    /* fixed minHeight and margin to prevent jumping headline */
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: `${theme.spacing(6)} !important`,
    },
    "&.MuiAccordion-root.Mui-expanded": {
      marginTop: "0px",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: `${theme.spacing(1.5)} 0 !important`,
    },
    "& .MuiAccordionSummary-expandIcon": {
      transition: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "unset",
      opacity: 1,
      "& .MuiIconButton-label": {
        visibility: "hidden",
      },
    },
    "& .MuiAccordionSummary-content": {
      display: "inline-grid",
    },
  },
  padding: {
    padding: "0",
  },
  datePickerCaptionStyle: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      flexBasis: "100%",
    },
  },
  datePickerStyle: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(-1),
    },
    [theme.breakpoints.down("sm")]: {
      flexBasis: "100%",
    },
  },
  headline: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  thick: {
    padding: "6px 0",
  },
}));

const StyledAccordion: FC<PropsWithChildren<StyledAccordionProps>> = ({
  headline,
  dark,
  thick,
  hideDatePicker,
  datePicker,
  datePickerCaption,
  small,
  large,
  expanded = false,
  children,
  disabled = false,
  noBorder,
  additionalHeadline,
}) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const [open, setOpen] = useState(expanded);

  const handleChange = () => setOpen((currentState) => !currentState);

  let headingLevel: "h2" | "h3" | "h4" = "h3";
  if (small) headingLevel = "h4";
  if (large) headingLevel = "h2";

  const variant = dark ? "dark" : "light";

  let border = dark ? "darkBorder" : "lightBorder";
  if (noBorder) border = "";

  return (
    <Accordion
      className={cx(classes.accordion, variant, border, {
        [classes.thick]: thick,
      })}
      expanded={open}
      disabled={disabled}
      onChange={handleChange}>
      <AccordionSummary
        className={classes.padding}
        expandIcon={
          <StyledButton
            dark={variant === "dark"}
            startIcon={<DownIcon />}
            size="small"
            color={variant === "dark" ? "primary" : "inherit"}
            tooltip={
              open
                ? t("iconTooltips.minimizeIcon")
                : t("iconTooltips.maximizeIcon")
            }
            useflexstyle
          />
        }>
        {datePicker && !hideDatePicker ? (
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Typography variant={headingLevel}>{headline}</Typography>
            <Box className={classes.datePickerCaptionStyle}>
              {datePickerCaption}
            </Box>
            <Box
              className={classes.datePickerStyle}
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}>
              {datePicker}
            </Box>
          </Box>
        ) : (
          <Typography variant={headingLevel} className={classes.headline}>
            {headline}
            {!open && additionalHeadline ? ` (${additionalHeadline})` : null}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails className={classes.padding}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export { StyledAccordion };
