import { TOP_CATEGORIES_DEPTH_LIMIT } from "configs/catalog";
import { call, put } from "redux-saga/effects";
import categoriesServiceExt from "_foundationExt/apis/search/categories.service";
import {
  CATEGORYVIEW_TOP_ERROR_ACTION,
  CATEGORYVIEW_TOP_REQUEST_ACTION,
} from "_redux/actions/catalog";

export function* loadTopCategories(action: any) {
  try {
    const payload = action.payload;
    const storeId = payload.storeId ? payload.storeId : payload.storeID;

    const parameters = {
      storeId,
      depthAndLimit: TOP_CATEGORIES_DEPTH_LIMIT,
    };
    const response = yield call(categoriesServiceExt.getCategoryViewTop, {
      ...parameters,
    });
    yield put(CATEGORYVIEW_TOP_REQUEST_ACTION(response));
  } catch (error) {
    yield put(CATEGORYVIEW_TOP_ERROR_ACTION(error));
  }
}
