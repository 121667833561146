import React, { FC } from "react";

import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow-down-1.svg";

interface StyledProps {
  dark?: boolean;
}

const EFoodTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "dark",
})<StyledProps>(({ theme, dark }) => ({
  "& label": {
    visibility: "hidden",
  },
  "& .MuiInputBase-root": {
    minWidth: 64,
    marginTop: "0 !important",
    borderRadius: 4,
    borderWidth: "2px",
    borderStyle: "solid",
    color: dark ? theme.palette.grey[50] : undefined,
    borderColor: dark ? theme.palette.grey[800] : theme.palette.grey[50],
    backgroundColor: dark ? theme.palette.grey[800] : theme.palette.grey[50],
    transition: theme.transitions.create(["border-color", "background-color"]),
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
    "& svg": {
      color: dark ? theme.palette.grey[50] : undefined,
      pointerEvents: "none",
    },
    "&.Mui-focused": {
      borderColor: dark
        ? theme.palette.primary.main
        : theme.palette.primary.light,
      backgroundColor: dark ? "transparent" : theme.palette.common.white,
    },
  },
  "& .MuiInput-underline": {
    "&:before": {
      content: "none",
    },
    "&:after": {
      content: "none",
    },
  },
  "& .MuiInputBase-input": {
    padding: "8px 46px 8px 14px !important",
    height: "auto",
    lineHeight: "24px",
    "&::placeholder": {
      color: theme.palette.grey[700],
      opacity: 1,
    },
  },
  "& .MuiInputBase-inputSizeSmall": {
    padding: "6px 32px 6px 6px !important",
    fontSize: 14,
    lineHeight: "20px",
  },
  "& .MuiInputBase-sizeSmall": {
    "& svg.MuiSelect-icon": {
      top: "calc(50% - 8px)",
      right: 6,
      height: 16,
      width: 16,
      pointerEvents: "none",
    },
  },
  "& .MuiFormHelperText-root": {
    position: "absolute",
    top: "calc(100% + 12px)",
    left: 0,
    margin: 0,
    padding: "11px 8px",
    width: "100%",
    backgroundColor: theme.palette.common.white,
    opacity: 0,
    visibility: "hidden",
    lineHeight: "14px",
    fontSize: 12,
    transition: theme.transitions.create(["top", "opacity"]),
    boxShadow: "0px 4px 32px rgba(32,35,28,0.15)",
    borderRadius: theme.shape.borderRadius,
    color: `${theme.palette.grey.A200} !important`,
    zIndex: theme.zIndex.extension.formSelect,

    "&.Mui-focused": {
      opacity: 1,
      visibility: "visible",
      top: "calc(100% + 8px)",
    },
  },
  "& .MuiSelect-icon": {
    top: "calc(50% - 10px)",
    right: 14,
    width: 20,
    height: 20,
    color: theme.palette.grey[800],
  },
  "& .MuiSelect-iconOpen": {
    transform: "rotateZ(180deg)",
  },
  "& .MuiMenu-paper": {
    marginTop: theme.spacing(1),
    boxShadow: "0px 4px 32px rgba(32,35,28,0.15)",
  },
  "& .MuiPopover-root": {
    "& .MuiPaper-root": {
      backgroundColor: dark ? theme.palette.grey.A100 : undefined,
    },
    "& .MuiMenuItem-root": {
      color: dark ? theme.palette.grey["50"] : undefined,
      "&.Mui-selected": {
        backgroundColor: dark
          ? `${theme.palette.grey["800"]} !important`
          : undefined,
      },
      "&:hover": {
        backgroundColor: dark ? theme.palette.grey["800"] : undefined,
      },
    },
    "& .MuiListSubheader-root": {
      color: dark ? theme.palette.grey["50"] : undefined,
      backgroundColor: dark ? theme.palette.grey.A100 : undefined,
    },
  },
}));

type StyledFormSelectProps = TextFieldProps & {
  dark?: boolean;
};

const StyledFormSelect: FC<StyledFormSelectProps> = ({
  InputProps,
  SelectProps,
  dark,
  children,
  ...rest
}) => {
  const inputLapelProps = {
    shrink: true,
  };
  const selectProps: Partial<typeof SelectProps> = {
    displayEmpty: true,
    IconComponent: ArrowIcon,
    MenuProps: {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    },
    ...SelectProps,
  };

  return (
    <EFoodTextField
      select
      variant="standard"
      InputLabelProps={inputLapelProps}
      InputProps={InputProps}
      SelectProps={selectProps}
      dark={dark}
      {...rest}>
      {children}
    </EFoodTextField>
  );
};

export default StyledFormSelect;
