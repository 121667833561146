import React, { FC, useEffect, useMemo, useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import { useAppDispatch } from "_redux/hooks";

import { StyledDivider, StyledLinkButton } from "components/StyledUI";
import { getCookie } from "tools/cookieUtils";
import { CustomerProductlist } from "types/CustomerProductlist";
import SpellCheckSuggestions from "components/searchSpellCheckSuggestions";
import { useTranslation } from "react-i18next";
import { ProductListItem } from "components/productListItem";
import { ProductGalleryItem } from "components/productGallery/productGalleryItem";
import ESpot from "components/widgets/espot/ESpot";
import { TRIGGER_MARKETING_ACTION } from "_redux/actions/marketingEvent";
import { StyledSkeletonTable } from "components/StyledUI/StyledSkeleton";
import { marketingConstants } from "@hcl-commerce-store-sdk/utils";
import { useLocation } from "react-router-dom";
import Hidden from "components/Hidden";
import { useOnlineStatus } from "_foundationExt/hooks";
import { tss } from "tss-react/mui";
import {
  useCookieState,
  type Options as UseCookieStateOptions,
} from "_foundationExt/hooks/useCookieState";
import ProductListGroup from "./ProductListGroup";
import { Filter, SearchResult } from "../../types/Search";
import PaginationSection from "./PaginationSection";
import PageSizeSelector from "./PageSizeSelector";
import CategoriesAccordionWrapper from "./CategoriesAccordionWrapper";
import { FilterSection, FilterButton } from "./filter";
import { GalleryViewButton, ListViewButton } from "./listViewSwitch";
import ProductListTopGroup from "./ProductListTopGroup";
import SortReorderStocklistMenu from "./SortReorderStocklistMenu";
import useHeadline from "./useHeadline";
import {
  LISTVIEW_COOKIE_TYPE,
  PRODUCT_VIEW_MODE_GALLERY,
} from "./listViewSwitch/ListViewConstants";
import { type LoadingState } from "./useProductList";

const useStyles = tss.create(({ theme }) => ({
  buttonAlignLeft: {
    "& > button": {
      textAlign: "left",
    },
  },
  top: {
    marginTop: theme.spacing(0),
  },
  switchButtons: {
    "& > .MuiBox-root": {
      marginRight: 0,
      display: "inline-block",
      float: "left",
    },
    "& > .MuiBox-root:nth-of-type(1) > button": {
      borderRadius: "4px 0 0 4px",
    },
    "& > .MuiBox-root:nth-of-type(2) > button": {
      borderRadius: "0 4px 4px 0",
    },
  },
  eMSLeftSideBanner: {
    marginTop: theme.spacing(3),
    "@media (min-width:450px)": {
      maxWidth: "324px",
    },
  },
  pagination: {
    display: "flex",
    flex: 1,
    placeContent: "flex-end",
  },
}));

interface ProductListProps {
  searchResult: SearchResult;
  categoryId?: string;
  loadingState?: LoadingState;
  pageSize: string;
  setPageSize: (pageSize: string) => void;
  page: number;
  setPage: (page: number) => void;
  searchTerm?: string | null;
  headline?: string | null;
  selectedCustomerProductList?: CustomerProductlist | null;
  refetch?: () => void;
  filterList: Filter[];
  setStorageSorting?: React.Dispatch<React.SetStateAction<string>>;
  disableNoteSearch?: () => void;
  disableProductGroups?: boolean;
  isAccountListView?: boolean;
  disableSearchOrderset?: () => void;
  selectedProductlistFilter: CustomerProductlist[];
  editMode?: boolean;
}

const useCookieStateOptions: UseCookieStateOptions = {
  encode: {
    sameSite: "lax",
  },
};

const ProductList: FC<ProductListProps> = ({
  searchResult,
  categoryId,
  loadingState,
  pageSize,
  setPageSize,
  page,
  setPage,
  searchTerm,
  selectedCustomerProductList,
  headline,
  refetch,
  filterList,
  setStorageSorting,
  disableNoteSearch,
  disableProductGroups,
  isAccountListView,
  disableSearchOrderset,
  selectedProductlistFilter,
  editMode,
}) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const location = useLocation();
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const { isOffline } = useOnlineStatus();
  const getProductListViewCookieValue = (): LISTVIEW_COOKIE_TYPE => {
    if (getCookie(PRODUCT_VIEW_MODE_GALLERY) === LISTVIEW_COOKIE_TYPE.GALLERY) {
      return LISTVIEW_COOKIE_TYPE.GALLERY;
    }
    return LISTVIEW_COOKIE_TYPE.LIST;
  };

  const getGalleryActive = () =>
    !isAccountListView &&
    getProductListViewCookieValue() != null &&
    getProductListViewCookieValue() === LISTVIEW_COOKIE_TYPE.GALLERY;

  const [listViewMode, setListViewMode] = useCookieState(
    PRODUCT_VIEW_MODE_GALLERY,
    LISTVIEW_COOKIE_TYPE.LIST,
    useCookieStateOptions
  );

  const [showFilterSection, setShowFilterSection] = useState(
    selectedProductlistFilter.length > 0
  );
  const [showEspot, setShowEspot] = useState(true);
  const [lastCategoryId, setLastCategoryId] = useState<string>("");
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { searchOrderset } = searchResult;
  let searchTermForHeadline2 = searchTerm || null;
  if (!searchOrderset) {
    searchTermForHeadline2 = searchResult.spellCheckSuggestions?.length
      ? searchResult.spellCheckSuggestions[0]
      : null;
  }

  const headline2 = useHeadline(
    searchTermForHeadline2,
    null,
    null,
    null,
    searchOrderset,
    searchResult.totalNumberOfProducts,
    page,
    pageSize
  );

  const matchedFilter = useMemo(() => {
    const urlParam = new URLSearchParams(location.hash.substring(1));
    return filterList.filter((item) => !!urlParam.get(item.value));
  }, [filterList, location.hash]);

  useEffect(() => {
    if (matchedFilter.length && !showFilterSection) {
      setShowFilterSection(true);
    }

    if (categoryId && categoryId !== lastCategoryId) {
      const mktParam = {
        categoryId,
        DM_ReqCmd: marketingConstants.CATEGORY_DISPLAY,
      };

      dispatch(TRIGGER_MARKETING_ACTION(mktParam));

      setLastCategoryId(categoryId);
    }
  }, [matchedFilter, showFilterSection, categoryId, dispatch, lastCategoryId]);

  const productListViewCookieValue = getProductListViewCookieValue();
  if (listViewMode !== productListViewCookieValue) {
    setListViewMode(productListViewCookieValue);
  }

  return (
    <>
      <Grid container>
        <Hidden condition={isOffline}>
          <Grid xs={12} lg={3}>
            <CategoriesAccordionWrapper
              categoriesLookup={searchResult.categoriesLookup}
              searchTerm={searchTerm}
              isAccountListView={isAccountListView}
              loadingState={loadingState}
            />
            <Hidden condition={lgDown}>
              <ESpot
                emsName="Category_Left_Sidebar_EMS"
                categoryId={categoryId}
                passShowEspot={setShowEspot}
                sidebar
              />
            </Hidden>
          </Grid>
        </Hidden>
        {loadingState === "loading" ? (
          <Grid xs={12} lg={isOffline ? 12 : 9}>
            <StyledSkeletonTable />
          </Grid>
        ) : (
          <Grid
            xs={12}
            lg={
              !isOffline && (searchResult.categoriesLookup?.size || showEspot)
                ? 9
                : 12
            }>
            <Hidden condition={isOffline}>
              <ESpot
                emsName="Category_Top_EMS"
                categoryId={categoryId}
                className={classes.top}
                passShowEspot={(pass) => setShowEspot(!pass)}
              />
            </Hidden>
            {headline && (
              <Box mb={2}>
                <Typography variant="h4">{headline}</Typography>
              </Box>
            )}
            {searchResult.categoryNote && (
              <Box mb={2}>
                <Typography variant="body1" className={classes.buttonAlignLeft}>
                  <Hidden condition={lgDown}>
                    {t("search.categoryNote", {
                      categoryName: searchResult.categoryNote,
                    })}{" "}
                  </Hidden>
                  <StyledLinkButton onClick={disableNoteSearch}>
                    {t("search.disableNoteSearch")}
                  </StyledLinkButton>
                </Typography>
              </Box>
            )}
            {searchOrderset && (
              <Box mb={2}>
                <Typography variant="body1">
                  {t("search.ordersetSearch")}{" "}
                  <StyledLinkButton onClick={disableSearchOrderset}>
                    {t("search.disableNoteSearch")}
                  </StyledLinkButton>
                </Typography>
              </Box>
            )}
            {!searchResult.originalNumberOfProducts &&
              searchResult.spellCheckSuggestions?.length && (
                <>
                  <SpellCheckSuggestions
                    suggestions={searchResult.spellCheckSuggestions}
                    searchTerm={searchTerm}
                    marginBottom={2}
                  />
                  <Box mb={2}>
                    <Typography variant="h4">{headline2}</Typography>
                  </Box>
                </>
              )}
            <Grid container gap={2} justifyContent="space-between">
              <Grid container wrap="nowrap" xs={8} sm={5} lg={4}>
                <Hidden condition={isOffline}>
                  <FilterButton
                    setShowFilterSection={setShowFilterSection}
                    showFilterSection={showFilterSection}
                    filterList={filterList}
                    selectedProductlistFilter={selectedProductlistFilter}
                  />
                </Hidden>
                {!isAccountListView && (
                  <Box className={classes.switchButtons}>
                    <ListViewButton
                      listViewMode={listViewMode}
                      setListViewMode={setListViewMode}
                    />
                    <GalleryViewButton
                      listViewMode={listViewMode}
                      setListViewMode={setListViewMode}
                    />
                  </Box>
                )}
              </Grid>

              <Grid
                container
                xs={12}
                sm={12}
                lg={8}
                className={classes.pagination}
                // Check if pagination is shown. If so spacing of 2 should be apply.
                // Otherwise spacing of 0 should apply because only the PageSizeSelector is visible.
                spacing={searchResult.totalNumberOfProducts ? 2 : 0}>
                <Grid>
                  <PaginationSection
                    pageSize={pageSize}
                    totalAmountOfProducts={searchResult.totalNumberOfProducts}
                    page={page}
                    setPage={setPage}
                  />
                </Grid>

                {!!searchResult.totalNumberOfProducts &&
                  isAccountListView &&
                  selectedCustomerProductList?.type === "XSL" && (
                    <Grid>
                      <SortReorderStocklistMenu
                        selectedCustomerProductList={
                          selectedCustomerProductList
                        }
                        setStorageSorting={setStorageSorting}
                        refetch={refetch}
                      />
                    </Grid>
                  )}
                <Grid>
                  <PageSizeSelector
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Hidden condition={!showFilterSection || isOffline}>
              <FilterSection
                filterList={filterList}
                selectedProductlistFilter={selectedProductlistFilter}
                isAccountListView={isAccountListView || false}
              />
            </Hidden>
            <>
              {!isAccountListView &&
                searchResult.listProducts &&
                searchResult.listProducts.length > 0 && (
                  <ProductListTopGroup
                    products={searchResult.listProducts}
                    refetch={refetch}
                    selectedCustomerProductList={selectedCustomerProductList}
                    isAccountListView={isAccountListView}
                    title={t("productListing.listProducts")}
                    isDark
                    isGalleryView={getGalleryActive()}
                    editMode={editMode}
                  />
                )}
              {!isAccountListView &&
                searchResult.topProducts &&
                searchResult.topProducts.length > 0 && (
                  <ProductListTopGroup
                    products={searchResult.topProducts}
                    refetch={refetch}
                    selectedCustomerProductList={selectedCustomerProductList}
                    isAccountListView={isAccountListView}
                    title={t("productListing.topProducts")}
                    isDark={
                      !(
                        searchResult.listProducts &&
                        searchResult.listProducts.length > 0
                      )
                    }
                    isGalleryView={getGalleryActive()}
                    editMode={editMode}
                  />
                )}
              {disableProductGroups ? (
                <>
                  <StyledDivider text="&nbsp;" />
                  {!getGalleryActive() &&
                    searchResult.products.map((product) => (
                      <ProductListItem
                        key={product.uniqueID}
                        product={product}
                        refetch={refetch}
                        selectedCustomerProductList={
                          selectedCustomerProductList
                        }
                        isAccountListView={isAccountListView}
                        editMode={editMode}
                      />
                    ))}
                  {getGalleryActive() && (
                    <Box mb={5}>
                      <Grid container spacing={3}>
                        <>
                          {searchResult.products.map((product) => (
                            <ProductGalleryItem
                              key={product.uniqueID}
                              product={product}
                              refetch={refetch}
                            />
                          ))}
                        </>
                      </Grid>
                    </Box>
                  )}
                </>
              ) : (
                <ProductListGroup
                  products={searchResult.products}
                  refetch={refetch}
                  selectedCustomerProductList={selectedCustomerProductList}
                  isAccountListView={isAccountListView}
                  isGalleryView={getGalleryActive()}
                  editMode={editMode}
                />
              )}
            </>
          </Grid>
        )}
      </Grid>

      {loadingState !== "loading" && (
        <>
          <Grid container justifyContent="flex-end">
            <PaginationSection
              pageSize={pageSize}
              totalAmountOfProducts={searchResult.totalNumberOfProducts}
              page={page}
              setPage={setPage}
            />
          </Grid>
          <Hidden condition={isOffline || lgUp}>
            <ESpot
              className={classes.eMSLeftSideBanner}
              emsName="Category_Left_Sidebar_EMS"
              categoryId={categoryId}
              passShowEspot={setShowEspot}
              sidebarBottom
            />
          </Hidden>

          <Hidden condition={isOffline}>
            <ESpot emsName="Category_Bottom_EMS" categoryId={categoryId} />
          </Hidden>
        </>
      )}
    </>
  );
};

export default ProductList;
