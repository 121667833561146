import React, { FC } from "react";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  root: {
    margin: "0 8px",
    padding: "6px 8px",
    fontSize: 14,
    lineHeight: "20px",
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    letterSpacing: ".5px",
    "&:hover": {
      backgroundColor: `${theme.palette.grey[50]}`,
      cursor: "pointer",
    },
  },
  selected: {
    backgroundColor: `${theme.palette.grey[50]} !important`,
  },
}));

const StyledFormSelectItem: FC<MenuItemProps> = ({ children, ...rest }) => {
  const { classes } = useStyles();

  return (
    <MenuItem classes={classes} {...rest}>
      {children}
    </MenuItem>
  );
};

export default StyledFormSelectItem;
