export const PERSONALIZATION_ID = "personalizationID";
export const INITIATED_FROM_STORAGE = "initiatedFromStorage";
export const TRACKING_CHOICE = "trackingChoice";
export const PRICE_CHANGES = "priceChanges";

export const DEFAULT_TRACKING_CHOICE = "1";
export const OPTIONS_TRACKING_CHOICE = ["0", "1"];

export const NEWSLETTER_VALIDATION_CODE = "newsletterValidationCode";

export const DEFAULT_GENDER_OPTION = "Unspecified";
export const GENDER_OPTIONS = ["Unspecified", "Female", "Male"];
export const GENDER_OPTIONS_ADMIN = ["M", "F", "N"];
