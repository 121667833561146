import React, { FC } from "react";
import { useAppSelector } from "_redux/hooks";
import { userContactPersonState } from "_redux/selectors/user";
import { useTranslation } from "react-i18next";
import { Box, Typography, Avatar } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { StyledLink } from "components/StyledUI";
import GridCardContent from "pages/home/GridCartContent";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    padding: theme.spacing(4),
    overflow: "hidden",
    "& img": {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  },
  tel: {
    textDecoration: "none",
  },
}));

const initials = (name: string) =>
  name
    .split(" ")
    .map((n) => n[0])
    .join("");

const ContactPerson: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const contactPerson = useAppSelector(userContactPersonState);

  return contactPerson && contactPerson.name ? (
    <GridCardContent
      content={
        <Grid container style={{ height: "202px" }}>
          <Grid xs={12}>
            <Box mb={2}>
              <Typography variant="h2">
                {t("myAccount.contactPerson")}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Grid container alignItems="center">
              <Grid container xs={4}>
                <Avatar
                  className={classes.avatar}
                  src={contactPerson.avatarUrl || undefined}>
                  {initials(contactPerson.name)}
                </Avatar>
              </Grid>
              <Grid xs={8}>
                <Typography variant="h4">{contactPerson.name}</Typography>
                {contactPerson.businessTitle && (
                  <Typography variant="body2">
                    {contactPerson.businessTitle}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          {contactPerson.phone && (
            <Grid xs={12}>
              <Box mb={1} mt={2}>
                <Typography variant="h2">
                  <StyledLink
                    to={`tel:${contactPerson.phone}`}
                    className={classes.tel}>
                    <Box fontWeight="fontWeightRegular">
                      {contactPerson.phone}
                    </Box>
                  </StyledLink>
                </Typography>
              </Box>
            </Grid>
          )}
          {contactPerson.email && (
            <Grid xs={12}>
              <Box fontSize={14}>
                <StyledLink to={`mailto:${contactPerson.email}`}>
                  {contactPerson.email}
                </StyledLink>
              </Box>
            </Grid>
          )}
        </Grid>
      }
      key="contactPerson"
    />
  ) : null;
};

export default React.memo(ContactPerson);
