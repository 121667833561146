import { isAxiosError } from "axios";
import { Resource } from "pwa/offline";
import { CUSTOM_HEADER } from "pwa/offline/common/constants";
import { OfflineAwareRequest } from "pwa/offline/common/types";

export const isOfflineAwareRequest = (
  request: Request,
  ...expectedResources: Resource[]
) => {
  const resource = request.headers.get(CUSTOM_HEADER.X_EFOOD_REQUEST_RESOURCE);
  const originMethod = request.headers.get(
    CUSTOM_HEADER.X_EFOOD_REQUEST_ORIGIN_METHOD
  );

  if (
    resource &&
    originMethod &&
    (expectedResources.length === 0 ||
      expectedResources.some(
        (expectedResource) => expectedResource === resource
      ))
  ) {
    return {
      resource,
      originMethod,
    } as OfflineAwareRequest<Resource, string>;
  }

  return false;
};

/* eslint-disable indent */
export const isOfflineAwareRequestReturnType = <
  R extends Resource,
  M extends string
>(
  candidate: any // eslint-disable-line @typescript-eslint/no-explicit-any
): candidate is OfflineAwareRequest<R, M> =>
  !!candidate && "resource" in candidate && "originMethod" in candidate;
/* eslint-enable indent */

export const isNetworkError = (candidate: unknown): candidate is TypeError =>
  candidate instanceof TypeError ||
  (isAxiosError(candidate) && candidate.code === "ERR_NETWORK");
