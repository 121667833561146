import { CustomerProductList } from "pwa/db/types";
import { executeRequest } from "_foundationExt/axios/axiosConfig";
import DEFAULT_SYNC_HEADERS from "../common/headers";
import { FetchSyncDataFunction } from "../common/types";
import productListsSyncTimestamp from "./productListsSyncTimestamp";
import saveCustomerProductLists from "./saveCustomerProductLists";

interface ResponseData {
  customerProductlists: Array<CustomerProductList>;
  timestamp: unknown;
}

const doSyncCustomerProductLists: FetchSyncDataFunction = async ({
  storeId,
  transactionContext,
  signal,
  onDownloadProgress,
  paginated,
  force = false,
}) => {
  try {
    const lastSync = force ? 0 : (await productListsSyncTimestamp.get()) ?? 0;
    const {
      data: { customerProductlists, timestamp },
    }: { data: ResponseData } = await executeRequest({
      method: "GET",
      url: `${transactionContext}/store/${storeId}/efood/customer_productlist/@sync`,
      headers: DEFAULT_SYNC_HEADERS,
      params: {
        responseFormat: "json",
        lastSync,
      },
      signal,
      onDownloadProgress,
    });
    await saveCustomerProductLists(
      customerProductlists,
      paginated ? undefined : timestamp
    );

    return Promise.resolve({
      recordSetTotal: customerProductlists?.length ?? 0,
      recordSetStartNumber: 0,
      recordSetCount: customerProductlists?.length ?? 0,
      timestamp,
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

export default doSyncCustomerProductLists;
