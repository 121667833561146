import React, { FC, useCallback, useEffect, useState } from "react";

import {
  Alert,
  AlertColor,
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  Typography,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";

import { ReactComponent as DownIcon } from "assets/icons/arrow-down-1.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check-circle-1.svg";
import { ReactComponent as InfoIcon } from "assets/icons/messages-bubble-information.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/messages-bubble-warning.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

import { tss } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "_redux/hooks";
import {
  RESET_SYNC_MANUALLY_ACTION,
  UPDATE_SYNC_RUNNING_ACTION,
} from "_redux/actions/site";
import ShowSyncState from "./ShowSyncState";
import useSyncCatalogData from "../common/useSyncCatalogData";
import { SyncState } from "../common/types";

const useStyles = tss.create(({ theme }) => ({
  root: {
    marginBottom: theme.spacing(2),
    minHeight: theme.spacing(6),
    padding: theme.spacing(0),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(0.5),
    // standard colors
    "&.MuiAlert-standardError .MuiAlert-icon": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.background.paper,
    },
    "&.MuiAlert-standardWarning .MuiAlert-icon": {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.background.paper,
    },
    "&.MuiAlert-standardInfo .MuiAlert-icon": {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.background.paper,
    },
    "&.MuiAlert-standardSuccess .MuiAlert-icon": {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.background.paper,
    },
    // filled colors MuiAlert-filledSuccess
    "&.MuiAlert-filledError": {
      backgroundColor: theme.palette.error.main,
    },
    "&.MuiAlert-filledWarning": {
      backgroundColor: theme.palette.warning.main,
    },
    "&.MuiAlert-filledInfo": {
      backgroundColor: theme.palette.info.main,
    },
    "&.MuiAlert-filledSuccess": {
      backgroundColor: theme.palette.success.main,
    },
    "& .MuiAlert-icon": {
      padding: theme.spacing(1.5),
      borderTopLeftRadius: theme.spacing(0.5),
      borderBottomLeftRadius: theme.spacing(0.5),
      alignItems: "center",
      "& svg": {
        height: theme.spacing(3),
        width: theme.spacing(3),
        pointerEvents: "none",
      },
    },
    "& .MuiAlert-message": {
      padding: 0,
    },
    "& .MuiAlert-action": {
      alignItems: "center",
      marginRight: theme.spacing(2),
    },
  },
  closeButton: {
    "&.text-button": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    "&.icon-button": {
      display: "none",
      "& svg": {
        cursor: "pointer",
        width: theme.spacing(2),
        height: theme.spacing(2),
        pointerEvents: "none",
      },
      [theme.breakpoints.down("sm")]: {
        display: "inline-flex",
      },
    },
  },
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  backgroundColor: "transparent",
  border: "none",
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<DownIcon />} {...props} />
))(({ theme }) => ({
  backgroundColor: "transparent",
  flexDirection: "row",
  padding: 0,
  "& .MuiAccordionSummary-expandIconWrapper": {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginLeft: theme.spacing(1),
    transition: "none",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0),
  },
}));

type CloseButtonProps = ButtonProps & IconButtonProps;
const CloseButton: FC<CloseButtonProps> = ({
  children,
  className,
  ...rest
}) => {
  const { cx } = useStyles();
  return (
    <>
      <Button
        aria-label="close"
        className={cx(className, "text-button")}
        {...rest}>
        {children}
      </Button>
      <IconButton
        aria-label="close"
        className={cx(className, "icon-button")}
        {...rest}>
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </>
  );
};

type InnerSyncNotificationProps = {
  syncStates: {
    categories: SyncState;
    customerProductLists: SyncState;
    products: SyncState;
    prices: SyncState;
  };
  error?: boolean;
  finished?: boolean;
  cancelable?: boolean;
  finishedSyncs?: number;
  allSyncs?: number;
  onClose?: () => void;
};

const InnerSyncNotification: FC<InnerSyncNotificationProps> = ({
  error,
  finished,
  cancelable,
  finishedSyncs,
  allSyncs,
  onClose,
  syncStates,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  let severity: AlertColor = "info";
  let titleKey = "offline.sync.state.runningSummary";
  let actionKey = "offline.sync.action.abort";
  if (error) {
    severity = "warning";
    titleKey = "offline.sync.state.errorSummary";
    actionKey = "offline.sync.action.close";
  } else if (finished) {
    severity = "success";
    titleKey = "offline.sync.state.successSummary";
    actionKey = "offline.sync.action.close";
  }

  return (
    <Alert
      className={classes.root}
      severity={severity}
      action={
        error || finished || cancelable ? (
          <CloseButton
            className={classes.closeButton}
            color="inherit"
            size="small"
            onClick={onClose}>
            {t(actionKey)}
          </CloseButton>
        ) : undefined
      }
      iconMapping={{
        success: <CheckIcon />,
        info: <InfoIcon />,
        warning: <ErrorIcon />,
        error: <ErrorIcon />,
      }}>
      <Accordion>
        <AccordionSummary>
          <Typography>
            {t(titleKey, {
              finishedSyncs,
              allSyncs,
            })}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ShowSyncState
            syncState={syncStates.categories}
            labelKey="offline.sync.data.category"
          />
          <ShowSyncState
            syncState={syncStates.customerProductLists}
            labelKey="offline.sync.data.customerList"
          />
          <ShowSyncState
            syncState={syncStates.products}
            labelKey="offline.sync.data.product"
          />
          <ShowSyncState
            syncState={syncStates.prices}
            labelKey="offline.sync.data.price"
          />
        </AccordionDetails>
      </Accordion>
    </Alert>
  );
};

export type SyncNotificationProps = {
  triggerSync: boolean;
  manuallyTriggered?: boolean;
  force?: boolean;
  cancelable?: boolean;
  closeOnSuccess?: boolean;
  onClose?: () => void;
};

const SyncNotification: FC<SyncNotificationProps> = ({
  triggerSync,
  manuallyTriggered,
  force,
  cancelable,
  closeOnSuccess,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const {
    categoriesSyncState,
    customerProductListsSyncState,
    productsSyncState,
    pricesSyncState,
    error,
    loading,
    finished,
    startSync,
    stopSync,
    resetSync,
  } = useSyncCatalogData({ force });

  const allSyncs = [
    categoriesSyncState,
    customerProductListsSyncState,
    productsSyncState,
    pricesSyncState,
  ];

  useEffect(() => {
    if (manuallyTriggered) {
      resetSync();
    }
  }, [manuallyTriggered, resetSync]);

  const finishedSyncs = allSyncs.filter(
    (s) => s.recordSetTotal != null && s.recordSetTotal === s.recordSetCount
  ).length;

  const onCloseHandler = useCallback(() => {
    setOpen(false);
    dispatch(UPDATE_SYNC_RUNNING_ACTION(false));
    dispatch(RESET_SYNC_MANUALLY_ACTION());
    stopSync();
    if (typeof onClose === "function") {
      onClose();
    }
  }, [stopSync, onClose, dispatch]);

  useEffect(() => {
    if (triggerSync && !error && !loading && !finished) {
      setOpen(true);
      dispatch(UPDATE_SYNC_RUNNING_ACTION(true));
      startSync();
    }
  }, [triggerSync, error, loading, finished, startSync, dispatch]);

  useEffect(() => {
    if (closeOnSuccess && finished && !error && !loading) {
      onCloseHandler();
    }
  }, [closeOnSuccess, error, finished, loading, onCloseHandler]);

  if (!open) {
    return null;
  }

  return (
    <InnerSyncNotification
      syncStates={{
        categories: categoriesSyncState,
        customerProductLists: customerProductListsSyncState,
        prices: pricesSyncState,
        products: productsSyncState,
      }}
      error={error != null}
      finished={finished}
      cancelable={cancelable}
      finishedSyncs={finishedSyncs}
      allSyncs={allSyncs.length}
      onClose={onCloseHandler}
    />
  );
};

export default SyncNotification;
