/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import { useAppSelector } from "_redux/hooks";

import {
  selectSortedCustomerProductLists,
  userInitStatusSelector,
} from "_redux/selectors/user";

import { useSite } from "_foundationExt/hooks/useSite";

import { sortProductLists } from "components/helpers/sortProductLists";
import { CustomerProductlist } from "types/CustomerProductlist";

const useProductOptions = () => {
  const { currentSite } = useSite();
  const initSelector = useAppSelector(userInitStatusSelector);
  const customerProductLists = useAppSelector(selectSortedCustomerProductLists);

  const [lists, setLists] = useState<{
    orderset?: CustomerProductlist;
    labellist?: CustomerProductlist;
    shoppinglist: CustomerProductlist[];
    customerProductLists: CustomerProductlist[];
  }>({
    orderset: undefined,
    labellist: undefined,
    shoppinglist: [],
    customerProductLists: [],
  });

  useEffect(() => {
    if (customerProductLists && initSelector && currentSite != null) {
      const orderset = customerProductLists.find((item) => item.type === "XOS");

      const labellist = customerProductLists.find(
        (item) => item.type === "XLL"
      );

      const shoppinglist = sortProductLists(
        customerProductLists.filter(
          (item) => item.type !== "XLL" && item.type !== "XOS"
        )
      );

      const checkbox: string[] = [];

      for (const customerProductListsItem of customerProductLists) {
        checkbox.push(customerProductListsItem.id);
      }

      setLists({
        orderset,
        labellist,
        shoppinglist,
        customerProductLists,
      });
    }
  }, [customerProductLists, initSelector, currentSite]);

  return lists;
};

export default useProductOptions;
