import { useCallback } from "react";
import isInstanaActive from "tools/isInstanaActive";
import useFetchSyncData, {
  UseFetchSyncDataFunctionProps,
  UseFetchSyncDataFunctionReturnType,
} from "../common/useFetchSyncData";
import clearProductsData from "./clearProductsData";

import doSyncProducts from "./doSyncProducts";
import productsSyncTimestamp from "./productsSyncTimestamp";

const useSyncProducts: (
  props: Omit<UseFetchSyncDataFunctionProps, "fetchSyncData" | "cleanup">
) => UseFetchSyncDataFunctionReturnType = ({
  callback: externalCallback,
  paginated,
  ...rest
}) => {
  const callback = useCallback(
    (timestamp: unknown) => {
      if (paginated) {
        productsSyncTimestamp.put(timestamp);

        if (isInstanaActive()) {
          ineum("reportEvent", "sync products setTimestamp", {
            timestamp: Date.now(),
            meta: {
              paginated: true,
              productsSyncTimestamp: timestamp as string | number | boolean,
            },
          });
        }
      }
      if (externalCallback) {
        externalCallback(timestamp);
      }
    },
    [externalCallback, paginated]
  );

  return useFetchSyncData({
    fetchSyncData: doSyncProducts,
    cleanup: clearProductsData,
    callback,
    paginated,
    ...rest,
  });
};

export default useSyncProducts;
