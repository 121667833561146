import { createAction } from "@reduxjs/toolkit";
import { CloseDialogPayload, ShowDialogPayload } from "components/dialog/types";
import * as ACTIONTYPES from "../action-types/site";

export const INIT_SITE_SUCCESS_ACTION = createAction<
  any,
  typeof ACTIONTYPES.INIT_SITE_SUCCESS
>(ACTIONTYPES.INIT_SITE_SUCCESS);

export const SCROLL_TO_TOP_ACTION = createAction<
  any,
  typeof ACTIONTYPES.SCROLL_TO_TOP
>(ACTIONTYPES.SCROLL_TO_TOP);

export const SHOW_DIALOG_ACTION = createAction<
  ShowDialogPayload,
  typeof ACTIONTYPES.SHOW_DIALOG
>(ACTIONTYPES.SHOW_DIALOG);

export const CLOSE_DIALOG_ACTION = createAction<
  CloseDialogPayload,
  typeof ACTIONTYPES.CLOSE_DIALOG
>(ACTIONTYPES.CLOSE_DIALOG);

export const UPDATE_ONLINE_STATUS_ACTION = createAction<"online" | "offline">(
  ACTIONTYPES.UPDATE_ONLINE_STATUS
);

export const UPDATE_FORCE_STANDALONE_MODE_ACTION = createAction<boolean>(
  ACTIONTYPES.UPDATE_FORCE_STANDALONE_MODE
);

export const TRIGGER_SYNC_MANUALLY_ACTION = createAction(
  ACTIONTYPES.TRIGGER_SYNC_MANUALLY
);

export const RESET_SYNC_MANUALLY_ACTION = createAction(
  ACTIONTYPES.RESET_SYNC_MANUALLY
);

export const UPDATE_SYNC_RUNNING_ACTION = createAction<boolean>(
  ACTIONTYPES.UPDATE_SYNC_RUNNING
);
