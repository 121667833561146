import {
  type Attribute,
  type BaseProduct,
  isAttributeMap,
} from "types/Product";

const resolveEan = (product: BaseProduct) => {
  if (isAttributeMap(product.attributes)) {
    return Object.values(product.attributes)
      .flat<Array<Attribute>>()
      .filter((a) => a.searchable && a.identifier === "EAN")
      .flatMap((a) => a.values)
      .flatMap((av) => av.value);
  }
  if (Array.isArray(product.attributes)) {
    return (product.attributes as Array<Attribute>)
      .filter((a) => a.searchable && a.identifier === "EAN")
      .flatMap((a) => a.values)
      .flatMap((av) => av.value);
  }
  return [];
};

export default resolveEan;
