import React, { FC } from "react";

import {
  StyledCard,
  StyledCardActions,
  StyledCardContent,
} from "components/StyledUI";
import { GridSize } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Breakpoint } from "@mui/material/styles";

import { tss } from "tss-react/mui";

interface GridCardContentProps {
  content: React.ReactElement;
  action?: React.ReactElement;
}

const useStyles = tss.create(() => ({
  content: {
    paddingBottom: "0px !important",
  },
}));

const GridCardContent: FC<
  Partial<Record<Breakpoint, boolean | GridSize>> & GridCardContentProps
> = ({ content, action, ...rest }) => {
  const { classes } = useStyles();
  return (
    <Grid {...rest}>
      <StyledCard>
        {content && (
          <StyledCardContent className={classes.content}>
            {content}
          </StyledCardContent>
        )}
        {action && <StyledCardActions>{action}</StyledCardActions>}
      </StyledCard>
    </Grid>
  );
};

export default GridCardContent;
