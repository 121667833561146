import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import isInstanaActive from "tools/isInstanaActive";
import createRootReducer from "./reducers/index";
import rootSaga from "./sagas/index";
import initStates from "./reducers/initStates";
import getEnvironmentType from "tools/getEnvironmentType";

const getHostname = () => {
  if (typeof document === "undefined") {
    return undefined;
  }

  return window.location.hostname;
};

const sagaMiddleware = createSagaMiddleware({
  onError: (error, errorInfo) => {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);

    if (isInstanaActive()) {
      ineum("reportError", error, {
        componentStack: errorInfo.sagaStack,
      });
    }
  },
});

const rootReducer = createRootReducer();

const store = configureStore({
  reducer: rootReducer,
  devTools:
    getEnvironmentType(getHostname()) !== "prod"
      ? {
          trace: true,
          traceLimit: 25,
        }
      : false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      immutableCheck: true,
      serializableCheck: false,
    }).prepend(sagaMiddleware),
  preloadedState: initStates,
});

sagaMiddleware.run(rootSaga);

// set page on pageload
if (isInstanaActive()) {
  ineum("page", window.location.pathname);
}

export type AppStore = typeof store;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
