/* tslint:disable */
/* eslint-disable */
/**
 * EFood Storefront API
 * This is the EFood Storefront API specification.  Operations are grouped by tags.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { ResetPasswordRequest } from '../../../../com/telekom-mms/efood/api/model';
/**
 * PersonPasswordApi - axios parameter creator
 * @export
 */
export const PersonPasswordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Reset password.
         * @param {string} action The action to execute. Currently only supports \&#39;changePassword\&#39;
         * @param {string} langId The action to execute.
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (action: string, langId: string, resetPasswordRequest?: ResetPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'action' is not null or undefined
            assertParamExists('resetPassword', 'action', action)
            // verify required parameter 'langId' is not null or undefined
            assertParamExists('resetPassword', 'langId', langId)
            const localVarPath = `/site/efood/person/resetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonPasswordApi - functional programming interface
 * @export
 */
export const PersonPasswordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonPasswordApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Reset password.
         * @param {string} action The action to execute. Currently only supports \&#39;changePassword\&#39;
         * @param {string} langId The action to execute.
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(action: string, langId: string, resetPasswordRequest?: ResetPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(action, langId, resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PersonPasswordApi - factory interface
 * @export
 */
export const PersonPasswordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonPasswordApiFp(configuration)
    return {
        /**
         * 
         * @summary Reset password.
         * @param {string} action The action to execute. Currently only supports \&#39;changePassword\&#39;
         * @param {string} langId The action to execute.
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(action: string, langId: string, resetPasswordRequest?: ResetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.resetPassword(action, langId, resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonPasswordApi - object-oriented interface
 * @export
 * @class PersonPasswordApi
 * @extends {BaseAPI}
 */
export class PersonPasswordApi extends BaseAPI {
    /**
     * 
     * @summary Reset password.
     * @param {string} action The action to execute. Currently only supports \&#39;changePassword\&#39;
     * @param {string} langId The action to execute.
     * @param {ResetPasswordRequest} [resetPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonPasswordApi
     */
    public resetPassword(action: string, langId: string, resetPasswordRequest?: ResetPasswordRequest, options?: any) {
        return PersonPasswordApiFp(this.configuration).resetPassword(action, langId, resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
