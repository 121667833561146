export interface Pagination {
  pageNumber: number;
  pageSizePrices: number;
  pageSizeProducts: number;
}

export type SyncRequest = {
  storeId: string;
  transactionContext: string;
  signal: AbortSignal;
  onDownloadProgress: (progressEvent: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  paginated?: Pagination;
  force?: boolean;
  syncTimestamp?: unknown;
};

export interface SyncState {
  loading: boolean;
  progress?: number;
  recordSetTotal?: number;
  recordSetCount?: number;
  recordSetStartNumber?: number;
  error?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface SyncStateAction {
  type:
    | "SYNC_START"
    | "SYNC_PROGRESS_UPDATE"
    | "SYNC_SUCCESS"
    | "SYNC_ERROR"
    | "SYNC_RESET";
  payload?: Omit<SyncState, "loading">;
}

export const INITIAL_SYNC_STATE: SyncState = {
  loading: false,
};

export type FetchSyncDataFunction = (request: SyncRequest) => Promise<{
  recordSetTotal: number;
  recordSetCount: number;
  recordSetStartNumber: number;
  timestamp?: unknown;
}>;
