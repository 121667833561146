import {
  CustomerProductlist,
  CUSTOMER_PRODUCTLIST_TYPE_CONTRACT,
  CUSTOMER_PRODUCTLIST_TYPE_LABELLIST,
  CUSTOMER_PRODUCTLIST_TYPE_ORDERSET,
  CUSTOMER_PRODUCTLIST_TYPE_REQUISITION_LIST,
  CUSTOMER_PRODUCTLIST_TYPE_STORAGE_LIST,
  CUSTOMER_PRODUCTLIST_TYPE_OFFER,
} from "types/CustomerProductlist";

const PRODUCT_LIST_ORDER = [
  CUSTOMER_PRODUCTLIST_TYPE_ORDERSET,
  CUSTOMER_PRODUCTLIST_TYPE_CONTRACT,
  CUSTOMER_PRODUCTLIST_TYPE_LABELLIST,
  CUSTOMER_PRODUCTLIST_TYPE_REQUISITION_LIST,
  CUSTOMER_PRODUCTLIST_TYPE_STORAGE_LIST,
  CUSTOMER_PRODUCTLIST_TYPE_OFFER,
] as const;

// TODO use custom hook as components/accountList/useSortProductLists instead
export const sortProductLists = (productLists: CustomerProductlist[]) =>
  productLists.sort((a, b) => {
    if (
      a.type === b.type ||
      (a.type === CUSTOMER_PRODUCTLIST_TYPE_REQUISITION_LIST &&
        b.type === CUSTOMER_PRODUCTLIST_TYPE_STORAGE_LIST) ||
      (a.type === CUSTOMER_PRODUCTLIST_TYPE_STORAGE_LIST &&
        b.type === CUSTOMER_PRODUCTLIST_TYPE_REQUISITION_LIST)
    ) {
      return a.name?.localeCompare(b.name);
    }
    return (
      PRODUCT_LIST_ORDER.indexOf(a.type) - PRODUCT_LIST_ORDER.indexOf(b.type)
    );
  });
