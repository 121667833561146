import { useCallback, useEffect, useMemo, useState } from "react";

import productServiceExt from "_foundationExt/apis/search/product.service";
import { useOrderset } from "_redux/hooks/useOrderset";
import useUserSelectedProductlistIdSelector from "_redux/hooks/userSelectedProductlistId";
import { useSite } from "_foundationExt/hooks/useSite";
import { CustomerProductlist } from "types/CustomerProductlist";
import { PROFILE_NAME } from "constants/search";
import { usePrevious } from "_foundationExt/hooks";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "_redux/hooks";
import { userStockDeliveryDateDate } from "_redux/selectors/user";
import { CategoriesLookupEntry, SearchResult } from "../../types/Search";
import { useEFoodConfig } from "../../_foundationExt/hooks/useEFoodConfig";
import {
  determineCategoriesLookup,
  determineFilterData,
  determineSubCategories,
} from "./utils";
import type { LoadingState } from "./useProductList";

const createPartNumberQuery = (partNumberList) => {
  let newSearchTerm = "";

  for (let i = 0; i < partNumberList.length; i += 1) {
    if (i === 0) {
      newSearchTerm = `${partNumberList[i]}`;
    } else {
      newSearchTerm += ` OR ${partNumberList[i]}`;
    }
  }

  return newSearchTerm;
};

const convertToShowProductsUrl = (searchTerm, partNumbers) => {
  let newSearchTerm = searchTerm;
  const partNumberList = partNumbers.split(",");

  if (partNumberList.length > 0) {
    newSearchTerm = createPartNumberQuery(partNumberList);
  }

  newSearchTerm = encodeURIComponent(newSearchTerm);

  return newSearchTerm;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useSearch = (
  searchTerm: string,
  partNumbers: string,
  pageSize: string,
  page: number,
  categoryId: string | null,
  excludeNoteSearch: boolean,
  searchInOrderset: boolean,
  selectedProductlistFilter?: CustomerProductlist[],
  customerProductListId?: string | null,
  storageSorting?: string | null
) => {
  const { currentSite } = useSite();
  const location = useLocation();
  const eFoodConfig = useEFoodConfig();
  const searchIn = eFoodConfig?.searchIn;
  const orderset = useOrderset();
  const selectedProductListIdFromSession =
    useUserSelectedProductlistIdSelector();
  const deliveryDate = useAppSelector(userStockDeliveryDateDate);

  const previousSearchTerm = usePrevious(searchTerm);

  const [loadingState, setLoadingState] = useState<LoadingState>("initial");
  const [reloadToggle, setReloadToggle] = useState(false);

  const refetch = useCallback(() => {
    setReloadToggle((oldValue) => !oldValue);
  }, []);

  const [searchResult, setSearchResult] = useState<SearchResult>({
    searchTerm: null,
    originalNumberOfProducts: 0,
    totalNumberOfProducts: 0,
    products: [],
    topProducts: [],
    categoryFacet: { categories: [] },
    filterFacets: [],
    productList: null,
    spellCheckSuggestions: null,
    categoryNote: null,
    searchOrderset: false,
    breadCrumbTrail: null,
    categoriesLookup: new Map<string, CategoriesLookupEntry>(),
  });

  const handleSearchResult = useCallback(
    (
      topProducts,
      response,
      originalNumberOfProducts,
      productList,
      spellcheck,
      categoryNote,
      searchOrderset,
      breadCrumbTrail
    ) => {
      const totalNumberOfProducts = response.data.recordSetTotal;
      const products = response.data.catalogEntryView;
      const subCategoriesData = determineSubCategories(response);
      const filterList = determineFilterData(response);
      const categoriesLookup = determineCategoriesLookup(response, categoryId);

      setSearchResult({
        searchTerm,
        originalNumberOfProducts,
        totalNumberOfProducts,
        products,
        topProducts,
        categoryFacet: { categories: subCategoriesData },
        filterFacets: filterList,
        productList,
        spellCheckSuggestions: spellcheck,
        categoryNote,
        searchOrderset,
        breadCrumbTrail,
        categoriesLookup,
      });
    },
    [categoryId, searchTerm]
  );

  const handleSearchResultCategorized = useCallback(
    (
      response,
      originalNumberOfProducts,
      productList,
      spellcheck,
      searchOrderset,
      breadCrumbTrail
    ) => {
      const totalCount = response.data?.recordSetTotal || 0;
      const totalNumberOfProducts = totalCount;
      const products = response.data.sectionCatalog?.catalogEntryView || [];
      const topProducts = response.data.sectionTop?.catalogEntryView || [];
      const listProducts = response.data.sectionLists?.catalogEntryView || [];
      const subCategoriesData = determineSubCategories(response);
      const filterList = determineFilterData(response);
      const categoryNote = response.data?.sectionCatalog?.categoryName
        ? response.data.sectionCatalog.categoryName
        : null;
      const categoriesLookup = determineCategoriesLookup(response, categoryId);

      setSearchResult({
        searchTerm,
        originalNumberOfProducts,
        totalNumberOfProducts,
        products,
        topProducts,
        listProducts,
        categoryFacet: { categories: subCategoriesData },
        filterFacets: filterList,
        productList,
        spellCheckSuggestions: spellcheck,
        categoryNote,
        searchOrderset,
        breadCrumbTrail,
        categoriesLookup,
      });
    },
    [categoryId, searchTerm]
  );

  const selectedFilterOptions = useMemo(() => {
    const urlParam = new URLSearchParams(location.hash.substring(1));
    const result: string[] = [];
    if (urlParam?.size) {
      urlParam.forEach((value, key) => {
        result.push(encodeURIComponent(`${key}:"${value}"`));
      });
    }
    return result;
  }, [location.hash]);

  useEffect(() => {
    if (currentSite?.storeID) {
      setLoadingState("loading");

      const urlEncodedTerm = partNumbers
        ? convertToShowProductsUrl(searchTerm, partNumbers)
        : encodeURIComponent(searchTerm);

      let profileName = "";

      if (partNumbers) {
        profileName = PROFILE_NAME.FIND_PRODUCTS_BY_PARTNUMBERS;
      } else if (
        searchTerm === "*" &&
        customerProductListId &&
        customerProductListId !== "0"
      ) {
        profileName = PROFILE_NAME.FIND_PRODUCTS_BY_SEARCH_TERM;
      }

      let orderBy = eFoodConfig.internTxt ? "2" : "1";

      if (storageSorting && storageSorting.startsWith("ascending")) {
        profileName = PROFILE_NAME.FIND_PRODUCT_LIST_PRODUCTS_BY_SEARCH_TERM;
        orderBy = eFoodConfig.internTxt ? "4" : "3";
      } else if (storageSorting && storageSorting.startsWith("descending")) {
        profileName = PROFILE_NAME.FIND_PRODUCT_LIST_PRODUCTS_BY_SEARCH_TERM;
        orderBy = eFoodConfig.internTxt ? "6" : "5";
      }

      const parameters = {
        storeId: currentSite.storeID,
        term: urlEncodedTerm,
        profileName,
        $queryParameters: {
          orderBy,
          pageSize,
          pageNumber: page,
          excludeNoteSearch,
        } as {
          orderBy: string;
          pageSize: string;
          pageNumber: number;
          excludeNoteSearch: boolean;
          productList?: string;
          categoryId?: string;
          facet?: string[];
          deliveryDate?: Date;
        },
      };

      if (deliveryDate) {
        parameters.$queryParameters.deliveryDate = deliveryDate;
      }

      let productListToUse: string | null = null;
      let searchOrderset = false;
      if (
        (!customerProductListId || customerProductListId === "0") &&
        selectedProductListIdFromSession &&
        selectedProductListIdFromSession !== "0"
      ) {
        productListToUse = selectedProductListIdFromSession;
      }

      if (
        !productListToUse &&
        searchTerm !== "*" &&
        searchTerm !== previousSearchTerm
      ) {
        if (searchIn === "orderset" && orderset?.id) {
          productListToUse = orderset.id;
          searchOrderset = true;
        }
      }
      if (
        !productListToUse &&
        customerProductListId &&
        customerProductListId !== "0"
      ) {
        productListToUse = customerProductListId;
      }

      if (productListToUse) {
        parameters.$queryParameters.productList = productListToUse;
        parameters.$queryParameters.excludeNoteSearch = true;
      }

      if (categoryId) {
        parameters.$queryParameters.categoryId = categoryId;
      }

      if (selectedProductlistFilter?.length) {
        const ids = selectedProductlistFilter.map((list) => list.id).join(",");
        parameters.$queryParameters.productList = ids;
      }

      if (selectedFilterOptions.length) {
        parameters.$queryParameters.facet = selectedFilterOptions;
      }

      const executeSearchByTerm = (): void => {
        productServiceExt
          .bySearchTermCategorized(false, {
            ...parameters,
            $queryParameters: {
              ...parameters.$queryParameters,
            },
          })
          .then((response) => {
            const originalNumberOfProducts = response.data?.recordSetTotal;
            const spellcheck = response.data?.sectionCatalog?.metaData
              ?.spellcheck
              ? response.data?.sectionCatalog?.metaData?.spellcheck
              : null;

            if (originalNumberOfProducts === 0 && spellcheck) {
              parameters.term = encodeURIComponent(spellcheck[0]);
              parameters.$queryParameters.excludeNoteSearch = true;

              productServiceExt
                .bySearchTermCategorized(false, {
                  ...parameters,
                  $queryParameters: {
                    ...parameters.$queryParameters,
                  },
                })
                .then((response2) => {
                  handleSearchResultCategorized(
                    response2,
                    originalNumberOfProducts,
                    null,
                    spellcheck,
                    searchOrderset,
                    response.data.sectionCatalog.breadCrumbTrailEntryView ||
                      null
                  );
                });
            } else {
              handleSearchResultCategorized(
                response,
                originalNumberOfProducts,
                null,
                spellcheck,
                searchOrderset,
                response.data.sectionCatalog.breadCrumbTrailEntryView || null
              );
            }
          })
          .catch(() => {
            setLoadingState("error");
          })
          .finally(() => {
            setLoadingState("completed");
          });
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const executeSearch = (pagingShift: number, topProducts: any): void => {
        productServiceExt
          .bySearchTerm({
            ...parameters,
            $queryParameters: {
              ...parameters.$queryParameters,
              pagingShift,
            },
          })
          .then((response2) => {
            const originalNumberOfProducts = response2.data.recordSetTotal;
            const spellcheck = response2.data?.metaData?.spellcheck
              ? response2.data?.metaData?.spellcheck
              : null;
            const categoryNote = response2.data.categoryName
              ? response2.data.categoryName
              : null;

            if (
              !productListToUse &&
              originalNumberOfProducts === 0 &&
              spellcheck
            ) {
              parameters.term = encodeURIComponent(spellcheck[0]);
              parameters.$queryParameters.excludeNoteSearch = true;

              productServiceExt
                .bySearchTerm({
                  ...parameters,
                  $queryParameters: {
                    ...parameters.$queryParameters,
                    pagingShift,
                  },
                })
                .then((response3) => {
                  handleSearchResult(
                    topProducts,
                    response3,
                    originalNumberOfProducts,
                    null,
                    spellcheck,
                    categoryNote,
                    searchOrderset,
                    response3.data.breadCrumbTrailEntryView || null
                  );
                });
            } else if (originalNumberOfProducts === 0 && searchOrderset) {
              delete parameters.$queryParameters.productList;
              parameters.$queryParameters.excludeNoteSearch = true;

              productServiceExt
                .bySearchTerm({
                  ...parameters,
                  $queryParameters: {
                    ...parameters.$queryParameters,
                    pagingShift,
                  },
                })
                .then((response3) => {
                  handleSearchResult(
                    topProducts,
                    response3,
                    originalNumberOfProducts,
                    null,
                    spellcheck,
                    categoryNote,
                    searchOrderset,
                    response3.data.breadCrumbTrailEntryView || null
                  );
                });
            } else {
              handleSearchResult(
                topProducts,
                response2,
                originalNumberOfProducts,
                productListToUse,
                spellcheck,
                categoryNote,
                searchOrderset,
                response2.data.breadCrumbTrailEntryView || null
              );
            }
          })
          .catch(() => {
            setLoadingState("error");
          })
          .finally(() => {
            setLoadingState("completed");
          });
      };

      if (profileName === "" && searchTerm !== "*") {
        executeSearchByTerm();
      } else {
        executeSearch(0, []);
      }
    }
  }, [
    currentSite?.storeID,
    eFoodConfig.internTxt,
    searchIn,
    orderset?.id,
    searchTerm,
    page,
    pageSize,
    categoryId,
    customerProductListId,
    selectedProductListIdFromSession,
    storageSorting,
    excludeNoteSearch,
    searchInOrderset,
    handleSearchResult,
    handleSearchResultCategorized,
    previousSearchTerm,
    selectedFilterOptions,
    selectedProductlistFilter,
    partNumbers,
    reloadToggle,
    deliveryDate,
  ]);

  return useMemo(
    () => ({
      error: loadingState === "error",
      loadingState,
      refetch,
      searchResult,
    }),
    [loadingState, refetch, searchResult]
  );
};

export { useSearch };
