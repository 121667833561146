import React, { FC } from "react";

import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  root: {
    padding: 0,
    border: "none",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    fontFamily: "NunitoSans,sans-serif",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.5px",
    textDecorationLine: "underline",
    textUnderlineOffset: "4px",
    textDecorationColor: theme.palette.grey[600],
    cursor: "pointer",
    "&:hover": {
      textDecorationColor: theme.palette.primary.light,
      textDecorationLine: "underline",
    },
    "&:active": {
      textDecorationColor: theme.palette.primary.dark,
      textDecorationLine: "underline",
    },
  },
}));

const StyledLinkButton: FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (
  props
) => {
  const { classes } = useStyles();

  return <button type="button" className={classes.root} {...props} />;
};

export { StyledLinkButton };
