import { eFoodDB } from "pwa/db/efood.db";
import { EFoodPrice } from "types/Product";
import { executeRequest } from "_foundationExt/axios/axiosConfig";
import isInstanaActive from "tools/isInstanaActive";
import DEFAULT_SYNC_HEADERS from "../common/headers";
import { FetchSyncDataFunction, Pagination } from "../common/types";
import pricesSyncTimestamp from "./pricesSyncTimestamp";
import saveProductPrices from "./saveProductPrices";

export type SyncPricesResponsePrice = {
  id: number | string;
  prices: EFoodPrice[];
};

export type SyncPricesResponseData = {
  prices: Array<SyncPricesResponsePrice>;
};

const getTotalProductCount = async (lastSync: number) =>
  eFoodDB.products.where("lastUpdate").aboveOrEqual(lastSync).count();
const getProductIdsOfPage = async (lastSync: number, paginated: Pagination) =>
  eFoodDB.products
    .where("lastUpdate")
    .aboveOrEqual(lastSync)
    .offset((paginated.pageNumber - 1) * paginated.pageSizePrices)
    .limit(paginated.pageSizePrices)
    .primaryKeys();
const getAllProductIds = async () =>
  eFoodDB.products.toCollection().primaryKeys();

const isNotUndefined = <T>(c?: T): c is T => c != null;

const doSyncPrices: FetchSyncDataFunction = async ({
  storeId,
  transactionContext,
  signal,
  onDownloadProgress,
  paginated,
  force = false,
}) => {
  try {
    const lastSync = force ? 0 : (await pricesSyncTimestamp.get()) ?? 0;
    const totalProductCount = await getTotalProductCount(lastSync);
    const productIds = paginated
      ? await getProductIdsOfPage(lastSync, paginated)
      : await getAllProductIds();

    if (!productIds.length) {
      return Promise.resolve({
        recordSetTotal: 0,
        recordSetStartNumber: 0,
        recordSetCount: 0,
        timestamp: Date.now(),
      });
    }

    const productsOfPage = await eFoodDB.products.bulkGet(productIds);
    const productsWithoutPriceIds = productsOfPage
      .filter(isNotUndefined)
      .filter((p) => p.prices == null || p.prices.length === 0)
      .map((p) => p.uniqueID);

    if (!productsWithoutPriceIds.length) {
      return Promise.resolve({
        recordSetTotal: totalProductCount,
        recordSetStartNumber: paginated
          ? (paginated.pageNumber - 1) * paginated.pageSizePrices
          : 0,
        recordSetCount: productIds.length ?? 0,
        timestamp: Date.now(),
      });
    }

    const params: Record<string, number | string> = {
      responseFormat: "json",
      lastSync,
    };
    if (paginated) {
      params.pageNumber = paginated.pageNumber;
      params.pageSize = paginated.pageSizePrices;
    }

    if (isInstanaActive()) {
      ineum("reportEvent", "sync prices", {
        timestamp: Date.now(),
        meta: {
          pageNumber: paginated?.pageNumber ?? -1,
          pricesSyncTimestamp: lastSync,
          productIdsLength: productsWithoutPriceIds.length,
          totalProductCount,
        },
      });
    }

    const timestamp = Date.now(); // FIXME use server time not client
    const {
      data: { prices },
    }: { data: SyncPricesResponseData } = await executeRequest({
      method: "POST",
      url: `${transactionContext}/store/${storeId}/efood/price`,
      headers: DEFAULT_SYNC_HEADERS,
      params,
      data: {
        id: productIds.join(";"),
      },
      signal,
      onDownloadProgress,
    });

    await saveProductPrices(prices, paginated ? undefined : timestamp);

    return Promise.resolve({
      recordSetTotal: totalProductCount,
      recordSetStartNumber: paginated
        ? (paginated.pageNumber - 1) * paginated.pageSizePrices
        : 0,
      recordSetCount: prices?.length ?? productsWithoutPriceIds.length ?? 0,
      timestamp,
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

export default doSyncPrices;
