/**
 * generic cookie functions
 */

import { Cookie } from "types/Cookie";

export function getCookie(cname: string) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
}

function getCookies(): Cookie[] {
  return document.cookie.split(";").reduce((cookies: Cookie[], cookie) => {
    const [name, value] = cookie.split("=").map((c) => c.trim());
    cookies.push({ name, value });
    return cookies;
  }, []);
}

export function getCookiesWithPrefix(cookiePrefix: string): Cookie[] {
  return getCookies().filter((cookie) => cookie.name.startsWith(cookiePrefix));
}

export function setCookie(cname: string, cvalue: string, exdays: number): void {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

export function deleteCookie(cookie: Cookie): void {
  document.cookie = `${cookie.name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
}
