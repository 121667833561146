import React, { FC } from "react";

import { Box } from "@mui/material";
import { StyledButton } from "components/StyledUI";
import { ReactComponent as GalleryViewIcon } from "assets/icons/layout-module-1.svg";
import { LISTVIEW_COOKIE_TYPE } from "./ListViewConstants";

interface GalleryViewButtonProps {
  listViewMode: string;
  setListViewMode: (string) => void;
}

const GalleryViewButton: FC<GalleryViewButtonProps> = ({
  listViewMode,
  setListViewMode,
}) => (
  <Box mr={{ xs: 1, sm: 2 }}>
    {listViewMode === LISTVIEW_COOKIE_TYPE.GALLERY ? (
      <StyledButton startIcon={<GalleryViewIcon />} />
    ) : (
      <StyledButton
        variant="outlined"
        startIcon={<GalleryViewIcon />}
        onClick={() => setListViewMode(LISTVIEW_COOKIE_TYPE.GALLERY)}
      />
    )}
  </Box>
);

export default GalleryViewButton;
