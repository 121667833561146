import React, { FC } from "react";

import { ContentRecommendation } from "components/widgets/content-recommendation";
import { useAppSelector } from "_redux/hooks";
import { loginStatusSelector } from "_redux/selectors/user";
import { ContentRecommendationProps } from "components/widgets/content-recommendation/ContentRecommendation";
import { tss } from "tss-react/mui";

const eSpotConfig: ContentRecommendationProps = {
  cid: "header",
  page: {
    name: "headerLogo",
  },
  eSpot: {
    eSpotName: "HeaderStoreLogo_Content",
  },
  options: {
    cacheAsset: true,
  },
};

const useStyles = tss.create(({ theme }) => ({
  headerLogo: {
    height: "100%",
    background: "white",
    borderBottomRightRadius: "20px",
    paddingRight: theme.spacing(1),

    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(2),
    },

    "& div": {
      height: "100%",
    },

    "& .imageWrapper": {
      display: "flex",
      alignItems: "center",
    },

    "& img": {
      objectFit: "contain",
      maxWidth: "100%",
      [theme.breakpoints.only("xs")]: {
        height: "80%",
      },
    },
  },
}));

const HeaderLogo: FC = () => {
  const { classes } = useStyles();
  const userLoggedIn = useAppSelector(loginStatusSelector);

  return (
    <div className={classes.headerLogo}>
      <ContentRecommendation
        key={userLoggedIn ? "forceLoggedInLogoLoad" : "forceLoggedOutLogoLoad"}
        {...eSpotConfig}
      />
    </div>
  );
};

export default HeaderLogo;
