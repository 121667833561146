export const SERVICE_WORKER_VERSION = "v1";
export const CACHE_PREFIX = "efood";
export const SERVB_IMAGE_HOST = "cloud.servicebund.de";
export const EFOOD_SERVB_PROXY_IMAGE_HOST = "cloudproxy.servicebund.com";
export const EFOOD_SERVB_PROXY_IMAGE_HOST_QA =
  "cloudproxy-qa-v2.servicebund.com";
export const EFOOD_SERVB_PROXY_IMAGE_HOST_TEST =
  "cloudproxy-test-v2.servicebund.com";
export const CACHE_IMAGE_MARKER_NAME = "sb_c";
export const CACHE_IMAGE_MARKER_VALUE = "true";
