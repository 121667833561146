/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from "react";
import { Box, IconButton } from "@mui/material";
import { IconButtonProps } from "@mui/material/IconButton";

import { StyledIcon } from "components/StyledUI";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  box: {
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.common.white,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    transition: theme.transitions.create(["background"]),
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "&:active": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  label: {
    padding: "10px 8px 8px",
    lineHeight: "18px",
    fontSize: 13,
    letterSpacing: "0.5px",
  },
  button: {
    padding: 10,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  disabled: {
    backgroundColor: theme.palette.grey[600],
    pointerEvents: "none",
    "& .MuiButtonBase-root": {
      color: theme.palette.common.white,
    },
  },
}));

interface StyledFilterTagProps extends IconButtonProps {
  label: string;
}

const StyledFilterTag: FC<StyledFilterTagProps> = (props) => {
  const { classes, cx } = useStyles();
  const { label, disabled, ...rest } = props;

  return (
    <Box className={cx(classes.box, { [classes.disabled]: disabled })}>
      <div className={classes.label}>{label}</div>
      <IconButton
        className={classes.button}
        color="inherit"
        disableRipple
        disabled={disabled}
        disableFocusRipple
        {...rest}
        size="large">
        <StyledIcon size="12">
          <CloseIcon />
        </StyledIcon>
      </IconButton>
    </Box>
  );
};

export { StyledFilterTag };
