// Core libraries
import { CommerceEnvironment } from "constants/common";
import { PERMANENT_STORE_DAYS } from "configs/common";
import { StoreLookupApi } from "@efood-commerce-sdk/typescript-axios-transaction-efood";
import { site as SiteContext } from "_foundationExt/constants/site";
import {
  StoreApi,
  StoreStore,
} from "@hcl-commerce-store-sdk/typescript-axios-transaction";
import { AppDispatch } from "_redux/store";
import { isCanceledError } from "_foundationExt/axios/axiosConfig";
import {
  WC_PREVIEW_TOKEN,
  NEW_PREVIEW_SESSION,
  LANGID,
  LOCALE,
  SHOW_API_FLOW,
} from "../../../_foundation/constants/common";
import {
  EFoodConfiguration,
  SiteInfo,
} from "../../../_redux/reducers/reducerStateInterface";
import {
  sessionStorageUtil,
  localStorageUtil,
  storageSessionHandler,
  windowRegistryHandler,
  storageStoreIdHandler,
} from "../../../_foundation/utils/storageUtil";
// Redux
import { INIT_SITE_SUCCESS_ACTION } from "../../../_redux/actions/site";

declare global {
  interface Window {
    HCL_STORE_ID?: string;
  }
}

const storeLookUpApiInstance = new StoreLookupApi(
  undefined,
  SiteContext.transactionContext
);

const storeApiInstance = new StoreApi(
  undefined,
  SiteContext.transactionContext
);
export interface SiteInfoArgs {
  storeName?: string;
  searchContext: string;
  transactionContext?: string;
  storeID?: string;
  [extraPros: string]: unknown;
}

export type ExtendedOnlineStoreDataType = StoreStore & {
  config: EFoodConfiguration;
};

export class SiteInfoService {
  private static mySiteInfo: SiteInfoService = new SiteInfoService();

  private readonly B2B = "B2B";

  private readonly BMH = "BMH";

  private siteInfo: SiteInfo | null = null;

  public static getSiteInfo(): SiteInfoService {
    return SiteInfoService.mySiteInfo;
  }

  public getSiteValue(): SiteInfo | null {
    return this.siteInfo;
  }

  public setSite(s: SiteInfoArgs, dispatch: AppDispatch, signal?: AbortSignal) {
    if (!this.siteInfo) {
      this.initializeSite(s, signal).then((site) => {
        if (site) {
          this.siteInfo = site;
          dispatch(INIT_SITE_SUCCESS_ACTION(site));
        }
      });
    }
  }

  private static initStorage(site: SiteInfo) {
    sessionStorageUtil.setStoreName(site.storeName);
    localStorageUtil.setStoreName(site.storeName);
    storageStoreIdHandler.setStoreId(site.storeID);
    windowRegistryHandler.registerWindow();
    window.addEventListener("unload", () => {
      windowRegistryHandler.unRegisterWindow();
    });
    window.addEventListener("contextmenu", () => {
      storageStoreIdHandler.verifyActiveStoreId();
    });
    // preview token
    const storeviewURL = new URL(window.location.href);
    const wcPreviewToken = {};
    const previewtoken = storeviewURL.searchParams.get(WC_PREVIEW_TOKEN);
    if (previewtoken != null) {
      wcPreviewToken[WC_PREVIEW_TOKEN] = previewtoken;
      storageSessionHandler.savePreviewToken(wcPreviewToken);
      const newPreviewSession =
        storeviewURL.searchParams.get(NEW_PREVIEW_SESSION);
      if (newPreviewSession === "true") {
        storageSessionHandler.removeCurrentUser();
      }
    }

    const urlParamsLangId = storeviewURL.searchParams.get(LANGID);
    const urlParamsLocale = storeviewURL.searchParams.get(LOCALE);
    const urlParamsShowAPIFlow = storeviewURL.searchParams.get(SHOW_API_FLOW);

    if (urlParamsLangId != null) {
      // check if it is part supported language.
      if (site.supportedLanguages.includes(urlParamsLangId)) {
        localStorageUtil.set(
          LOCALE,
          CommerceEnvironment.languageMap[urlParamsLangId],
          30
        );
      } else {
        // eslint-disable-next-line no-console
        console.warn(
          `${urlParamsLangId} is not supported language of store ${site.storeName}`
        );
      }
    } else if (urlParamsLocale != null) {
      //
      const langId = CommerceEnvironment.reverseLanguageMap[urlParamsLocale];
      if (site.supportedLanguages.includes(langId)) {
        localStorageUtil.set(LOCALE, urlParamsLocale, PERMANENT_STORE_DAYS);
      } else {
        // eslint-disable-next-line no-console
        console.warn(
          `${urlParamsLocale} is not supported language of store ${site.storeName}`
        );
      }
    } else {
      // verify if locale is one of supported language, remove it if is not supported.
      const locale = localStorageUtil.get(LOCALE);
      const langId = CommerceEnvironment.reverseLanguageMap[locale];
      if (!site.supportedLanguages.includes(langId)) {
        localStorageUtil.remove(LOCALE);
      }
    }

    if (urlParamsShowAPIFlow != null) {
      localStorageUtil.set(
        SHOW_API_FLOW,
        urlParamsShowAPIFlow,
        PERMANENT_STORE_DAYS
      );
    }
  }

  private assign(
    target: SiteInfo,
    onlineStoreData: ExtendedOnlineStoreDataType
  ) {
    const { config: efood, resultList } = onlineStoreData;
    const { storeID } = target;
    const source = resultList?.[0] ?? {};
    const { relatedStores = [] } = source;

    // -4 is catalog-asset-store
    const caStore = relatedStores.find(
      ({ relationshipType, relatedStoreId }) =>
        relationshipType === "-4" && relatedStoreId !== storeID
    );
    // -11 is storefront-asset-store
    const saStore = relatedStores.find(
      ({ relationshipType, relatedStoreId }) =>
        relationshipType === "-11" && relatedStoreId !== storeID
    );

    Object.assign(source, { efood });
    Object.assign(target, {
      storeName: source.identifier,
      catalogStoreID: caStore?.relatedStoreId,
      saStoreID: saStore?.relatedStoreId,
      catalogID: source.defaultCatalog?.[0]?.catalogIdentifier?.uniqueID,
      defaultCurrencyID: source.supportedCurrencies?.defaultCurrency,
      defaultLanguageID: source.supportedLanguages?.defaultLanguageId,
      storeType: source.storeType,
      isB2B: source.storeType === this.B2B || source.storeType === this.BMH,
      storeCfg: source,
      supportedLanguages: source.supportedLanguages?.supportedLanguages ?? [],
      supportedCurrencies:
        source.supportedCurrencies?.supportedCurrencies ?? [],
    });
  }

  private async initializeSite(s: SiteInfoArgs, signal?: AbortSignal) {
    const site = { ...s } as SiteInfo;

    let storeId =
      typeof window.HCL_STORE_ID === "undefined"
        ? undefined
        : window.HCL_STORE_ID;
    if (!storeId) {
      storeId = storageStoreIdHandler.getStoreId4Initialization() ?? undefined;
    }

    try {
      if (!storeId) {
        const storeData = await SiteInfoService.getStoreData(signal);
        storeId =
          storeData.storeId != null ? storeData.storeId.toString() : undefined;
      }

      if (storeId) {
        site.storeID = storeId;
        const onlineStoreData = await SiteInfoService.getOnlineStoreData(
          storeId,
          signal
        );
        this.assign(site, onlineStoreData);
      }

      SiteInfoService.initStorage(site);
    } catch (error) {
      if (isCanceledError(error)) {
        return undefined;
      }
      throw error;
    }

    return site;
  }

  private static getStoreData(signal?: AbortSignal) {
    const hostname = SiteInfoService.determineHostname();

    return storeLookUpApiInstance
      .lookupStoreBy(hostname, { signal })
      .then((r) => r.data);
  }

  private static getOnlineStoreData(storeId: string, signal?: AbortSignal) {
    return storeApiInstance
      .storeFindByQueryOnlineStore(storeId, undefined, {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
        signal,
      })
      .then((r) => r.data as ExtendedOnlineStoreDataType);
  }

  /**
   * urls > shop-qa-v2.localhost
   * replace > sgk-qa-v2.servicebund.com
   * with > shop-qa-v2.servicebund.com
   */
  private static rewriteStoreSubdomain(url: string) {
    // get url
    const { hostname } = window.location;
    // detect subdomain
    if (hostname.includes(".")) {
      let store = hostname.split(".")[0];
      if (store && store.length > 0) {
        if (
          process.env.REACT_APP_SERVER_REQUEST_STAGE === "QA" &&
          !store.includes("-qa-v2")
        ) {
          store += "-qa-v2";
        }
        if (
          process.env.REACT_APP_SERVER_REQUEST_STAGE === "TEST" &&
          !store.includes("-test-v2")
        ) {
          store += "-test-v2";
        }

        // replace subdomain
        const redirect = url.split(".");
        redirect[0] = store;
        return redirect.join(".");
      }
    }
    return url;
  }

  private static determineHostname() {
    if (
      process.env.REACT_APP_MOCK === undefined &&
      process.env.REACT_APP_ENVIRONMENT === "development"
    ) {
      if (
        process.env.REACT_APP_SERVER_REQUEST_STAGE === "QA" &&
        process.env.REACT_APP_BACKEND_BASE_URL_QA_ENVIRONMENT !== undefined
      ) {
        return SiteInfoService.rewriteStoreSubdomain(
          process.env.REACT_APP_BACKEND_BASE_URL_QA_ENVIRONMENT
        );
      }
      if (
        process.env.REACT_APP_SERVER_REQUEST_STAGE === "PROD" &&
        process.env.REACT_APP_BACKEND_BASE_URL_PROD_ENVIRONMENT !== undefined
      ) {
        return SiteInfoService.rewriteStoreSubdomain(
          process.env.REACT_APP_BACKEND_BASE_URL_PROD_ENVIRONMENT
        );
      }
      if (
        process.env.REACT_APP_SERVER_REQUEST_STAGE !== "local" &&
        process.env.REACT_APP_BACKEND_BASE_URL_TEST_ENVIRONMENT !== undefined
      ) {
        return SiteInfoService.rewriteStoreSubdomain(
          process.env.REACT_APP_BACKEND_BASE_URL_TEST_ENVIRONMENT
        );
      }
    }

    return window.location.hostname;
  }
}
