/* eslint-disable indent */
import React, { FC, useMemo, useState } from "react";
import { Collapse } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

import { useAppSelector } from "_redux/hooks";
import { useLoggedIn } from "_foundationExt/hooks/useLoggedIn";
import {
  customerOffers,
  userStockDeliveryDateDate,
} from "_redux/selectors/user";

import { StyledButton, StyledIcon } from "components/StyledUI";
import { ReactComponent as NewIcon } from "assets/nutritionCharacteristicsIcons/kennzeichen-new.svg";
import { ReactComponent as SbBrandIcon } from "assets/attributeIcons/icon-merkmal-eigenmarke.svg";
import { ReactComponent as SaisonalIcon } from "assets/attributeIcons/icon-merkmal-saisonal.svg";
import { ReactComponent as OfferIcon } from "assets/attributeIcons/icon-merkmal-angebot.svg";
import { ReactComponent as BestellartikelIcon } from "assets/attributeIcons/icon-merkmal-bestellartikel.svg";
import { ReactComponent as ContractIcon } from "assets/attributeIcons/icon-kennzeichen-kontrakt.svg";
import { ReactComponent as DropShipmentIcon } from "assets/attributeIcons/icon-merkmal-direktfrisch.svg";
import { ReactComponent as OrderSetIcon } from "assets/attributeIcons/icon-orderset.svg";
import { ReactComponent as DownIcon } from "assets/icons/arrow-down-1.svg";
import { ReactComponent as UpIcon } from "assets/icons/arrow-up-1.svg";

import { Offer } from "types/Offer";
import {
  Attribute,
  AttributeMap,
  isAttributeMap,
  isNutritionCharacteristic,
  NutritionCharacteristic,
} from "types/Product";
import {
  ARTICLEFLAG_ATTRIBUTE_IDENTIFIER,
  ARTICLEORDERTIME_ATTRIBUTE_IDENTIFIER,
  characteristicsWithIcon,
  CONTRACT_IDENTIFIER,
  NEW_ATTRIBUTE_IDENTIFIER,
  OPTAINARTICLE_ATTRIBUTE_IDENTIFIER,
  ORDERSET_IDENTIFIER,
  PRODUCTOFFERS_ATTRIBUTE_IDENTIFIER,
  SBBRAND_ATTRIBUTE_IDENTIFIER,
  SEASONARTICLE_ATTRIBUTE_IDENTIFIER,
} from "constants/catalog";
import { useEFoodConfig } from "_foundationExt/hooks";
import { EFoodConfiguration } from "_redux/reducers";
import { CustomerProductlist } from "types/CustomerProductlist";
import { tss } from "tss-react/mui";
import useProductOptions from "../productOptions/useProductOptions";

const useStyles = tss
  .withParams<{ smallVariant?: boolean }>()
  .create(({ theme, smallVariant }) => ({
    iconsWrapper: {
      lineHeight: 0,

      "&>div": {
        padding: smallVariant ? theme.spacing(0.25) : theme.spacing(0.5),
      },
    },
    iconsGalleryWrapper: {
      lineHeight: 0,
      width: "36px",
      height: "auto",
      flexDirection: "column-reverse",

      "&>div": {
        padding: "0 0 5px 0",
      },
      "& .grid-item svg": {
        width: "36px",
        height: "36px",
      },
    },
    galleryCollapse: {
      height: "auto",
    },
    icon: {
      margin: "4px 0px 12px 4px",
      height: "36px",
    },
  }));

interface Icon {
  image;
  identifier: string;
  tooltip?: string;
}

const hideImage = (e: HTMLImageElement) => {
  e.style.display = "none";
};

const determineIcon = (nutritionCharacteristic: NutritionCharacteristic) => {
  if (nutritionCharacteristic.identifier === NEW_ATTRIBUTE_IDENTIFIER) {
    return <NewIcon />;
  }

  if (nutritionCharacteristic.identifier === SBBRAND_ATTRIBUTE_IDENTIFIER) {
    return <SbBrandIcon />;
  }

  if (
    nutritionCharacteristic.identifier === SEASONARTICLE_ATTRIBUTE_IDENTIFIER
  ) {
    return <SaisonalIcon />;
  }

  if (nutritionCharacteristic.identifier === ARTICLEFLAG_ATTRIBUTE_IDENTIFIER) {
    if (
      nutritionCharacteristic.values.some(
        (value) => value.image1 === "icons/icon_direktfrisch.png"
      )
    ) {
      return <DropShipmentIcon />;
    }
    if (nutritionCharacteristic.values[0]?.image1 !== undefined) {
      return nutritionCharacteristic.values[0].image1;
    }
  }
  return null;
};

const determineTooltip = (
  nutritionCharacteristic: NutritionCharacteristic,
  t: TFunction
) => {
  if (nutritionCharacteristic.identifier === SBBRAND_ATTRIBUTE_IDENTIFIER) {
    return t("productDetail.iconTooltip.sBBrand");
  }

  if (
    nutritionCharacteristic.identifier === SEASONARTICLE_ATTRIBUTE_IDENTIFIER
  ) {
    return t("productDetail.iconTooltip.seasonArticle");
  }

  if (nutritionCharacteristic.identifier === ARTICLEFLAG_ATTRIBUTE_IDENTIFIER) {
    return t("productDetail.iconTooltip.dropShipmentItem");
  }

  return "";
};

const getIcons = (
  nutritionCharacteristics: NutritionCharacteristic[],
  t: TFunction
): Icon[] => {
  const Icons = nutritionCharacteristics.flatMap(
    (nutritionCharacteristic): Icon[] => {
      const icon = determineIcon(nutritionCharacteristic);
      const tooltip = determineTooltip(nutritionCharacteristic, t);
      return icon
        ? [
            {
              image: icon,
              identifier: nutritionCharacteristic.identifier,
              tooltip,
            },
          ]
        : [];
    }
  );

  return Icons;
};

const optainArticleIconVisibleForDeliveryDateAndArticlOrdertime = (
  currentDeliveryDate,
  articleOordertimeAttribute?: NutritionCharacteristic
) => {
  let isOptainArticleIconVisible = true;

  if (currentDeliveryDate) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const deliveryDate = new Date(currentDeliveryDate);
    deliveryDate.setHours(0, 0, 0, 0);

    if (articleOordertimeAttribute !== undefined) {
      if (deliveryDate.getTime() === tomorrow.getTime()) {
        const articleOrdertimeForProduct =
          articleOordertimeAttribute.values[0]?.value;

        if (
          articleOrdertimeForProduct &&
          articleOrdertimeForProduct.indexOf(":") !== -1
        ) {
          const now = new Date();
          const ordertime = articleOrdertimeForProduct.split(":");
          const articleOrdertime = new Date(now);
          articleOrdertime.setHours(
            Number(ordertime[0]),
            Number(ordertime[1]),
            0,
            0
          );

          if (articleOrdertime.getTime() > now.getTime()) {
            isOptainArticleIconVisible = false;
          }
        }
      } else {
        isOptainArticleIconVisible = false;
      }
    }
  }

  return isOptainArticleIconVisible;
};

const getOptainArticleIcon = (
  nutritionCharacteristics: NutritionCharacteristic[],
  storeConfiguration: EFoodConfiguration,
  loggedIn: boolean,
  currentDeliveryDate,
  t: TFunction
): Icon[] => {
  const optainarticleAttribute = nutritionCharacteristics.find(
    (nutritionCharacteristic) =>
      nutritionCharacteristic.identifier === OPTAINARTICLE_ATTRIBUTE_IDENTIFIER
  );

  if (loggedIn || storeConfiguration.optainArticleGuest) {
    if (optainarticleAttribute) {
      let showOptainarticleIcon = true;

      if (storeConfiguration.optainarticleCheck) {
        const articleOrdertimeAttribute = nutritionCharacteristics.find(
          (nutritionCharacteristic) =>
            nutritionCharacteristic.identifier ===
            ARTICLEORDERTIME_ATTRIBUTE_IDENTIFIER
        );

        showOptainarticleIcon =
          optainArticleIconVisibleForDeliveryDateAndArticlOrdertime(
            currentDeliveryDate,
            articleOrdertimeAttribute
          );
      }

      if (showOptainarticleIcon) {
        return [
          {
            image: <BestellartikelIcon />,
            identifier: optainarticleAttribute.identifier,
            tooltip: t("productDetail.iconTooltip.optainArticle"),
          },
        ];
      }
    }
  }

  return [];
};

const productInOfferOfCustomer = (
  offerNumberForProduct: string,
  offers: Offer[]
) => {
  let isProductInOfferOfCustomer = false;

  if (offers) {
    offers.forEach((offer) => {
      if (offer.offerNumber.toString() === offerNumberForProduct) {
        isProductInOfferOfCustomer = true;
      }
    });
  }

  return isProductInOfferOfCustomer;
};

const getOfferIcon = (
  nutritionCharacteristics: NutritionCharacteristic[],
  offers: Offer[],
  t: TFunction
): Icon[] => {
  const offerAttribute = nutritionCharacteristics.find(
    (nutritionCharacteristic) =>
      nutritionCharacteristic.identifier === PRODUCTOFFERS_ATTRIBUTE_IDENTIFIER
  );

  if (offerAttribute) {
    for (let i = 0; i < offerAttribute.values.length; i++) {
      const offerNumberForProduct = offerAttribute.values[i]?.value;
      if (productInOfferOfCustomer(offerNumberForProduct, offers)) {
        return [
          {
            image: <OfferIcon />,
            identifier: offerAttribute.identifier,
            tooltip: t("productDetail.iconTooltip.offer"),
          },
        ];
      }
    }
  }

  return [];
};

const getOrderSetIcon = (
  t: TFunction,
  customerProductlists: string[],
  orderSetId?: string
): Icon[] => {
  const isOrderSetItem = customerProductlists.some(
    (customerProductlistElem) => customerProductlistElem === orderSetId
  );

  if (isOrderSetItem) {
    return [
      {
        image: <OrderSetIcon />,
        identifier: ORDERSET_IDENTIFIER,
        tooltip: t("productDetail.iconTooltip.orderSetArticle"),
      },
    ];
  }
  return [];
};

const getContractIcon = (
  inContract: boolean | null,
  storeConfiguration: EFoodConfiguration,
  t: TFunction
): Icon[] =>
  inContract && storeConfiguration.contracts
    ? [
        {
          image: <ContractIcon />,
          identifier: CONTRACT_IDENTIFIER,
          tooltip: t("productDetail.iconTooltip.contractArticle"),
        },
      ]
    : [];

const renderIcons = (
  icons: Icon[] | null,
  open: boolean,
  setOpen,
  iconStyle: string,
  smallVariant?: boolean
) => {
  let wrapCounter = -1;
  const divider = smallVariant ? 2 : 4;

  const renderedIcons = icons?.map((icon) => {
    wrapCounter += 1;

    return !smallVariant || wrapCounter < 4 ? (
      <React.Fragment key={icon.identifier}>
        {wrapCounter > 0 && wrapCounter % divider === 0 && (
          <>
            {!smallVariant && (
              <StyledButton
                className={iconStyle}
                startIcon={open ? <UpIcon /> : <DownIcon />}
                size="small"
                color="inherit"
                onClick={() => setOpen(!open)}
              />
            )}
            <div style={{ flexBasis: "100%", padding: 0 }} />
          </>
        )}
        {icon.image !== null && (
          <Grid className="grid-item">
            <StyledIcon
              size={smallVariant ? "20" : "44"}
              tooltip={icon.tooltip || ""}
              delay={100}>
              <>
                {typeof icon.image === "string" && (
                  <img
                    src={icon.image}
                    alt={icon.tooltip}
                    width={smallVariant ? "20px" : "44px"}
                    height={smallVariant ? "20px" : "44px"}
                    onError={(event) => hideImage(event.currentTarget)}
                  />
                )}
                {typeof icon.image === "object" && icon.image}
              </>
            </StyledIcon>
          </Grid>
        )}
      </React.Fragment>
    ) : null;
  });

  return renderedIcons;
};

interface ProductAttributesGridProps {
  smallVariant?: boolean;
  icons: Icon[];
  isGalleryView?: boolean;
}

const ProductAttributesGrid: FC<ProductAttributesGridProps> = ({
  smallVariant,
  icons,
  isGalleryView,
}) => {
  const { classes } = useStyles({ smallVariant });

  const [open, setOpen] = useState(false);

  if (!icons.length) {
    return null;
  }

  const collapsedSize = isGalleryView ? 36 : 52;
  const collapseClassName = isGalleryView ? classes.galleryCollapse : "";
  const gridClassName = isGalleryView
    ? classes.iconsGalleryWrapper
    : classes.iconsWrapper;

  return (
    <Collapse
      in={open}
      collapsedSize={collapsedSize}
      className={collapseClassName}>
      <Grid container className={gridClassName}>
        {renderIcons(icons, open, setOpen, classes.icon, smallVariant)}
      </Grid>
    </Collapse>
  );
};

interface ProductAttributesProps {
  attributes: AttributeMap | Attribute[] | NutritionCharacteristic[] | null;
  inContract: boolean | null;
  smallVariant?: boolean;
  isGalleryView?: boolean;
  customerProductlists?: string[];
}

const getIconList = (
  nutritionCharacteristics: NutritionCharacteristic[] | null,
  inContract: boolean | null,
  t: TFunction,
  loggedIn: boolean,
  efoodConfig: EFoodConfiguration,
  offers: Offer[],
  stockDeliveryDateDate?: Date,
  orderset?: CustomerProductlist,
  customerProductlists?: string[]
) => {
  let icons: Icon[] = [...getContractIcon(inContract, efoodConfig, t)];

  if (customerProductlists && orderset?.id != null) {
    icons = [
      ...icons,
      ...getOrderSetIcon(t, customerProductlists, orderset.id),
    ];
  }

  if (nutritionCharacteristics) {
    icons = [
      ...icons,
      ...getIcons(nutritionCharacteristics, t),
      ...getOfferIcon(nutritionCharacteristics, offers, t),
      ...getOptainArticleIcon(
        nutritionCharacteristics,
        efoodConfig,
        loggedIn,
        stockDeliveryDateDate,
        t
      ),
    ];
  }

  icons.sort((icon1, icon2) => {
    const sortIndexForIcon1 = characteristicsWithIcon.indexOf(icon1.identifier);
    const sortIndexForIcon2 = characteristicsWithIcon.indexOf(icon2.identifier);

    return sortIndexForIcon1 > sortIndexForIcon2 ? 1 : -1;
  });

  return icons;
};

const getGalleryIconList = (
  nutritionCharacteristics: NutritionCharacteristic[] | null,
  t: TFunction,
  loggedIn: boolean,
  efoodConfig: EFoodConfiguration,
  offers: Offer[],
  stockDeliveryDateDate?: Date
) => {
  let galleryProductTileIcons: Icon[] = [];

  if (nutritionCharacteristics) {
    galleryProductTileIcons = [
      ...galleryProductTileIcons,
      ...getOfferIcon(nutritionCharacteristics, offers, t),
      ...getOptainArticleIcon(
        nutritionCharacteristics,
        efoodConfig,
        loggedIn,
        stockDeliveryDateDate,
        t
      ),
    ];
  }

  galleryProductTileIcons.sort((icon1, icon2) => {
    const sortIndexForIcon1 = characteristicsWithIcon.indexOf(icon1.identifier);
    const sortIndexForIcon2 = characteristicsWithIcon.indexOf(icon2.identifier);

    return sortIndexForIcon1 > sortIndexForIcon2 ? 1 : -1;
  });

  return galleryProductTileIcons;
};

const ProductAttributes: FC<ProductAttributesProps> = ({
  attributes,
  inContract,
  smallVariant,
  isGalleryView,
  customerProductlists,
}) => {
  const { t } = useTranslation();
  const { loggedIn } = useLoggedIn();
  const storeConfiguration = useEFoodConfig();
  const stockDeliveryDateDate = useAppSelector(userStockDeliveryDateDate);
  const offers = useAppSelector(customerOffers);
  const { orderset } = useProductOptions();

  let nutritionCharacteristics: NutritionCharacteristic[] | null = null;
  if (isAttributeMap(attributes)) {
    nutritionCharacteristics = Object.values(attributes)
      .flat()
      .filter(isNutritionCharacteristic);
  } else if (Array.isArray(attributes)) {
    nutritionCharacteristics = attributes.filter(isNutritionCharacteristic);
  }

  const icons: Icon[] = useMemo(
    () =>
      isGalleryView
        ? getGalleryIconList(
            nutritionCharacteristics,
            t,
            loggedIn,
            storeConfiguration,
            offers,
            stockDeliveryDateDate
          )
        : getIconList(
            nutritionCharacteristics,
            inContract,
            t,
            loggedIn,
            storeConfiguration,
            offers,
            stockDeliveryDateDate,
            orderset,
            customerProductlists
          ),
    [
      customerProductlists,
      inContract,
      isGalleryView,
      loggedIn,
      nutritionCharacteristics,
      offers,
      orderset,
      stockDeliveryDateDate,
      storeConfiguration,
      t,
    ]
  );

  return (
    <ProductAttributesGrid
      icons={icons}
      smallVariant={smallVariant}
      isGalleryView={isGalleryView}
    />
  );
};

export default ProductAttributes;
