import { useEffect, useState } from "react";
import { useSite } from "_foundation/hooks/useSite";
import productServiceExt from "_foundationExt/apis/search/product.service";
import cartServiceExt from "_foundationExt/apis/transaction/cart.service";
import { Product } from "types/Product";
import { Reward, RewardOption } from "types/Order";

const useUpdateFreeProductSelection = (orderId: string, rewards: Reward[]) => {
  const updateFreeProductSelection = (
    selectedFreeProducts: Map<string, string[]>
  ) => {
    const updateRewardChoices: Promise<boolean>[] = [];
    rewards.forEach((reward) =>
      reward.rewardOptions.forEach((option) => {
        const quantities = selectedFreeProducts
          .get(option.rewardOptionId)
          ?.map((selectedFreeProduct) => {
            const foundElement =
              reward.rewardSpecification.giftSetSpecification.giftItem.find(
                (giftItem) => selectedFreeProduct === giftItem.uniqueID
              );
            return foundElement?.quantity.value;
          });

        const parameters = {
          body: {
            orderId,
            rewardOptionId: option.rewardOptionId,
            catEntryId: selectedFreeProducts.get(option.rewardOptionId),
            quantity: quantities,
          },
        };

        updateRewardChoices.push(
          cartServiceExt
            .updateRewardChoice(parameters)
            .then(() => true)
            .catch(() => false)
        );
      })
    );

    return Promise.all(updateRewardChoices);
  };

  return { updateFreeProductSelection };
};

const fetchProductsFor = async (
  storeId: string,
  productIds
): Promise<Product[]> => {
  const parameters = {
    storeId,
    $queryParameters: {
      id: productIds,
    },
    profileName: "EFood_findProductByIds_Recommendation",
  };

  const products = await productServiceExt
    .findByIds(parameters)
    .then((response) => {
      if (
        response.status === 200 &&
        response.data?.catalogEntryView?.length > 0
      ) {
        return response.data.catalogEntryView;
      }

      return [];
    });

  return products;
};

const getProductIdsFrom = (rewards) => {
  const productIds: string[] = [];
  rewards.forEach((reward) =>
    reward.rewardSpecification.giftSetSpecification.giftItem.forEach(
      (giftItem) => {
        const productId = giftItem.uniqueID;
        if (!productIds.includes(productId)) {
          productIds.push(productId);
        }
      }
    )
  );

  return productIds;
};

const constructProductMapFrom = (rewards, products) => {
  const productMap = new Map<string, Product[]>();
  rewards.forEach((reward) => {
    const giftProducts: Product[] = [];
    reward.rewardSpecification.giftSetSpecification.giftItem.forEach(
      (giftItem) => {
        const productId = giftItem.uniqueID;
        products.forEach((product) => {
          if (productId === product.uniqueID) {
            giftProducts.push(product);
          }
        });
      }
    );
    reward.rewardOptions.forEach((rewardOption) => {
      productMap.set(rewardOption.rewardOptionId, giftProducts);
    });
  });

  return productMap;
};

const useFetchProducts = (
  rewards: Reward[]
): { freeProducts: Map<string, Product[]> } => {
  const { currentSite } = useSite();
  const [freeProducts, setFreeProducts] = useState<Map<string, Product[]>>(
    new Map<string, Product[]>(null)
  );

  useEffect(() => {
    if (currentSite?.storeID) {
      const productIds: string[] = getProductIdsFrom(rewards);
      fetchProductsFor(currentSite.storeID, productIds).then((products) => {
        const productMap = constructProductMapFrom(rewards, products);

        setFreeProducts(productMap);
      });
    }
  }, [currentSite?.storeID, rewards]);

  return {
    freeProducts,
  };
};

const getProductsFromRewards = async (
  rewards: Reward[],
  storeId: string
): Promise<Map<string, Product[]>> => {
  const productIds: string[] = getProductIdsFrom(rewards);
  return fetchProductsFor(storeId, productIds).then((products) =>
    constructProductMapFrom(rewards, products)
  );
};

const getCombinedRewardOptions = (reward: Reward): RewardOption[][] => {
  const { rewardOptions } = reward;
  let combinedRewardOptions;

  if (reward.numberOfGiftOptions === 1) {
    combinedRewardOptions = [rewardOptions];
  } else {
    combinedRewardOptions = [];
    reward.rewardOptions.forEach((rewardOption) => {
      combinedRewardOptions.push([rewardOption]);
    });
  }

  return combinedRewardOptions;
};

const getNumberOfSelectedProducts = (
  reward: Reward,
  selectedFreeProducts?: string[]
): number => {
  let numberOfSelectedProducts = 0;
  if (selectedFreeProducts && selectedFreeProducts.length > 0) {
    if (reward.numberOfGiftOptions === 1) {
      numberOfSelectedProducts = reward.rewardOptions.length;
    } else {
      numberOfSelectedProducts = selectedFreeProducts.length;
    }
  }

  return numberOfSelectedProducts;
};

export {
  getProductsFromRewards,
  useFetchProducts,
  useUpdateFreeProductSelection,
  getCombinedRewardOptions,
  getNumberOfSelectedProducts,
};
