import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useAppSelector } from "_redux/hooks";
import { StyledFormInput } from "components/StyledUI";
import customerProductlistService from "_foundationExt/apis/transaction/customerProductlist.service";
import { siteSelector } from "_redux/selectors/site";
import { CustomerProductlist } from "types/CustomerProductlist";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

interface EditProductStandardQuantityProps {
  setStandardQuantity: React.Dispatch<React.SetStateAction<number | undefined>>;
  standardQuantity?: number;
  productListItemId?: string;
  selectedCustomerProductList?: CustomerProductlist | null;
}

const EditProductStandardQuantity: FC<EditProductStandardQuantityProps> = ({
  setStandardQuantity,
  standardQuantity,
  productListItemId,
  selectedCustomerProductList,
}) => {
  const site = useAppSelector(siteSelector);
  const { t } = useTranslation();
  const abortController = useRef<AbortController>();

  const [quantity, setQuantity] = useState(
    standardQuantity ? standardQuantity.toString() : "0"
  );

  const concreteMutateServerQuantity = useCallback(
    (changedQuantity: string, signal?: AbortSignal) => {
      if (site?.storeID) {
        const newQuantity = changedQuantity === "" ? "0" : changedQuantity;

        setStandardQuantity(Number(newQuantity));

        customerProductlistService
          .updateStandardQuantityList({
            storeId: site.storeID,
            customerProductListId: selectedCustomerProductList?.id,
            customerProductListItemId: productListItemId,
            body: {
              quantity: newQuantity,
            },
            signal,
          })
          .catch(() => {});
      }
    },
    [
      setStandardQuantity,
      productListItemId,
      selectedCustomerProductList?.id,
      site?.storeID,
    ]
  );

  const debouncedMutateServerQuantity = useDebouncedCallback(
    concreteMutateServerQuantity,
    350
  );

  const mutateServerQuantity = useCallback(
    (newQuantity: string) => {
      abortController.current?.abort();
      abortController.current = new AbortController();
      debouncedMutateServerQuantity(
        newQuantity,
        abortController.current.signal
      );
    },
    [debouncedMutateServerQuantity]
  );

  // When the component goes to be unmounted, we will force update the server state.
  useEffect(
    () => () => {
      debouncedMutateServerQuantity.flush();
    },
    [debouncedMutateServerQuantity]
  );

  const handleInputChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = useCallback(
    (event) => {
      const { value } = event.target;
      setQuantity(value);
      mutateServerQuantity(value);
    },
    [mutateServerQuantity]
  );

  const handleInputBlur = useCallback(() => {
    if (quantity === "") {
      setQuantity("0");
    }
  }, [quantity]);

  return (
    <>
      <Typography
        sx={{
          float: "right",
          marginRight: "10px",
          marginTop: "5px",
        }}>
        {t("myAccount.listManagement.inputStandardLable")}
      </Typography>
      <StyledFormInput
        size="small"
        sx={{ width: "50px", float: "right" }}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        value={quantity}
        type="number"
      />
    </>
  );
};

export default EditProductStandardQuantity;
