import { executeRequest } from "_foundationExt/axios/axiosConfig";
import DEFAULT_SYNC_HEADERS from "../common/headers";
import { FetchSyncDataFunction } from "../common/types";
import categoriesSyncTimestamp from "./categoriesSyncTimestamp";
import saveCategories from "./saveCategories";

const doSyncCategories: FetchSyncDataFunction = async ({
  storeId,
  transactionContext,
  signal,
  onDownloadProgress,
  paginated,
  force = false,
}) => {
  try {
    const lastSync = force ? 0 : (await categoriesSyncTimestamp.get()) ?? 0;
    const {
      data: {
        recordSetTotal,
        recordSetStartNumber,
        timestamp,
        catalogGroupView,
      },
    } = await executeRequest({
      method: "GET",
      url: `${transactionContext}/store/${storeId}/efood/categoryview/@sync`,
      headers: DEFAULT_SYNC_HEADERS,
      params: {
        responseFormat: "json",
        lastSync,
      },
      signal,
      onDownloadProgress,
    });
    await saveCategories(catalogGroupView, paginated ? undefined : timestamp);

    return Promise.resolve({
      recordSetTotal: recordSetTotal
        ? Number(recordSetTotal)
        : catalogGroupView?.length ?? 0,
      recordSetStartNumber: recordSetStartNumber
        ? Number(recordSetStartNumber)
        : 0,
      recordSetCount: recordSetTotal
        ? Number(recordSetTotal)
        : catalogGroupView?.length ?? 0,
      timestamp,
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

export default doSyncCategories;
