/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, Method, AxiosPromise } from "axios";
import { executeRequest } from "_foundation/axios/axiosConfig";
import { getSite } from "_foundation/hooks/useSite";

const pageServiceExt = {
  byNames(parameters: any, url?: string): AxiosPromise<any> {
    const param = parameters || {};

    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.transactionContext || "";
    }
    const domain = url || siteContext;
    const path = "/store/{storeId}/page";
    let requestUrl = domain + path;
    const method: Method = "GET";
    const body = {};
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    const queryParameters = new URLSearchParams();

    if (param.storeId === undefined && site !== null) {
      param.storeId = site.storeID;
    }
    requestUrl = requestUrl.replace("{storeId}", param.storeId);

    if (param.storeId === undefined) {
      throw new Error(
        "Request '/store/{storeId}/page' missing required parameter storeId"
      );
    }

    queryParameters.set("q", "byNames");

    if (param.name === undefined) {
      throw new Error(
        "Request '/store/{storeId}/page' missing required parameter name"
      );
    }

    if (param.name !== undefined) {
      const name = "name";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.profileName !== undefined) {
      const name = "profileName";
      const parameter = param[name];
      delete param[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (param.$queryParameters) {
      Object.keys(param.$queryParameters).forEach((parameterName) => {
        const parameter = param.$queryParameters[parameterName];
        queryParameters.set(parameterName, parameter);
      });
    }

    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      headers: header,
      data: body,
      url: requestUrl,
      ...parameters,
    };

    return executeRequest(requestOptions);
  },
};

export default pageServiceExt;
