// Standard libraries
import React, { FC } from "react";
import HTMLReactParser from "html-react-parser";

import { StyledLink } from "components/StyledUI";

import { ROUTES } from "constants/routes";

interface SuggestLink {
  label: string;
  term: string;
  onClick: () => void;
}

const KeywordSuggestionLink: FC<SuggestLink> = ({ label, term, onClick }) => {
  const searchUri = `${ROUTES.PRODUCT_DETAIL}${ROUTES.SEARCH}?searchTerm=${label}`;

  const highlightLabel = () => label.replace(term, `<strong>${term}</strong>`);

  const linkLabel = HTMLReactParser(highlightLabel());

  return (
    <div>
      <StyledLink to={searchUri} onClick={onClick}>
        {linkLabel}
      </StyledLink>
    </div>
  );
};

export default KeywordSuggestionLink;
