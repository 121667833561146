// react
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
// redux
import { useAppSelector } from "_redux/hooks";
import {
  isFetchingSelector,
  orderIdSelector,
  selectHasBaskets,
} from "_redux/selectors/order";

import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  StyledDivider,
  StyledProgress,
  StyledProtectable,
} from "components/StyledUI";
// components
import CartBaskets from "components/cart/CartList/CartBaskets";
import { tss } from "tss-react/mui";
import CartPriceSummary from "./CartPriceSummary";
import ShippingAddress from "./ShippingAddress";
import CartPromotionCode from "./CartPromotionCode";
import {
  ClearCartButton,
  ContinueShoppingButton,
  OrderNowButton,
  SaveInListButton,
} from "../CartButtons";
import CartFreeProducts from "../CartFreeProduct/CartFreeProducts";

const useStyles = tss.create(({ theme }) => ({
  container1: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "224px 201px 290px auto 50px 187px",
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "auto",
    },
  },
  container2: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "auto auto",
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "auto",
    },
  },
  item1: {
    [theme.breakpoints.down("lg")]: {
      gridRow: "1",
      gridColumn: "1",
    },
    [theme.breakpoints.down("sm")]: {
      gridRow: "1",
      marginRight: "60px",
    },
  },
  item2: {
    [theme.breakpoints.down("lg")]: {
      gridRow: "1",
      gridColumn: "1",
      marginLeft: "228px",
    },
    [theme.breakpoints.down("md")]: {
      gridRow: "3",
      marginLeft: "0",
    },
  },
  item4: {
    [theme.breakpoints.down("lg")]: {
      gridRow: "1",
      gridColumn: "1",
      marginRight: "191px",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: "0",
    },
    gridColumn: "5",
    textAlign: "right",
  },
  item5: {
    [theme.breakpoints.down("lg")]: {
      gridRow: "1",
      gridColumn: "1",
    },
    [theme.breakpoints.down("sm")]: {
      gridRow: "2",
    },
    gridColumn: "6",
    textAlign: "right",
  },
  item6: {
    gridColumn: "1",
    [theme.breakpoints.down("sm")]: {
      gridRow: "1",
    },
  },
  item7: {
    textAlign: "right",
    gridColumn: "2",
    [theme.breakpoints.down("sm")]: {
      gridColumn: "1",
      gridRow: "2",
    },
  },
  button: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const CartActions: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const isFetching = useAppSelector(isFetchingSelector);
  const orderId = useAppSelector(orderIdSelector);
  const hasBaskets = useAppSelector(selectHasBaskets);

  if (!orderId && isFetching) {
    return (
      <Grid container justifyContent="center">
        <StyledProgress />
      </Grid>
    );
  }

  if (!orderId || !hasBaskets) {
    return <Typography variant="h2">{t("cart.empty")}</Typography>;
  }

  return (
    <>
      <Box mb={1}>
        <div className={classes.container1}>
          <div className={classes.item1}>
            <ContinueShoppingButton />
          </div>

          <div className={classes.item2}>
            <SaveInListButton />
          </div>

          <div className={classes.item4}>
            <ClearCartButton />
          </div>

          <div className={classes.item5}>
            <OrderNowButton />
          </div>
        </div>
      </Box>

      <CartBaskets orderId={orderId} />

      <StyledDivider light pt={0} mt="-1px" />

      <Grid container>
        <Grid xs={12} sm={8}>
          <CartPromotionCode orderId={orderId} />

          <Box mt={2}>
            <CartFreeProducts orderId={orderId} />
          </Box>
        </Grid>

        <StyledProtectable permission="show.price">
          <CartPriceSummary />
        </StyledProtectable>
      </Grid>

      <StyledDivider light />

      <ShippingAddress />

      <StyledDivider light />

      <Box mb={2}>
        <div className={classes.container2}>
          <div className={classes.item6}>
            <ContinueShoppingButton />
          </div>

          <div className={classes.item7}>
            <OrderNowButton />
          </div>
        </div>
      </Box>
    </>
  );
};

export default CartActions;
