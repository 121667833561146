export const PRODUCT_IMAGE_HOST =
  "https://stwebshopbilder01prod001.blob.core.windows.net";
export const CENTRAL_SERVISA_ID = "199";

// eslint-disable-next-line no-shadow
export enum ARTICLE_TYPE {
  STOCK = "STOCK",
  OPTAIN = "OPTAIN",
  DROP_SHIP = "DROP_SHIP",
}
