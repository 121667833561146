import React, { FC } from "react";

import type { OrderItemError } from "_redux/reducers";
import type { Availability } from "types/Order";

import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { formatShortDate } from "tools/stringUtils";

import { TFunction } from "i18next";
import { getBaseToolTipStyle } from "theme/components";
import roundQuantity from "tools/round-quantity";
import QuantityHint from "./QuantityHint";
import OrderItemErrorHint from "./OrderItemErrorHint";

const getAvailabilityMessage = (t: TFunction, availability?: Availability) => {
  if (!availability) {
    return null;
  }

  const { replenishmentDate } = availability;
  if (replenishmentDate) {
    return t("cart.deliveryFromReplenishmentDate", {
      replenishmentDate: formatShortDate(replenishmentDate),
    });
  }

  return availability.message;
};

const Root = styled("div", {
  name: "AdditionalQuantityInformation",
  slot: "Root",
})(({ theme }) => ({
  textAlign: "right",
  color: theme.palette.text.primary,
  lineHeight: "16px",
  fontSize: "12px",
  marginTop: theme.spacing(0.5),
}));

const Tooltip = styled("div", {
  name: "AdditionalQuantityInformation",
  slot: "Tooltip",
})(({ theme }) => ({
  ...getBaseToolTipStyle(theme),
  display: "inline-block",
}));

const AdditionalQuantityInformation: FC<{
  additionalQuantityInformationClass?: string;
  availability?: Availability;
  isBcScan?: boolean;
  isFreeGift?: boolean;
  minAmountHint?: string;
  showCartHint?: boolean;
  orderItemError?: OrderItemError;
}> = ({
  additionalQuantityInformationClass,
  availability,
  isBcScan,
  isFreeGift,
  minAmountHint,
  showCartHint,
  orderItemError,
}) => {
  const { t } = useTranslation();

  const availabilityMessage = getAvailabilityMessage(t, availability);

  let localMinAmountHint = minAmountHint;
  let displayError: OrderItemError | undefined;

  // special handling of "min quantity not reached" backend error -> display as hint, not as error
  if (
    orderItemError?.errorKey === "_ERR_MINQUANTITY_NOTREACHED" &&
    orderItemError.minQuantityHint
  ) {
    localMinAmountHint = t("cart.minAmountHint", {
      quantity: roundQuantity(
        Number(orderItemError.minQuantityHint.quantity),
        3
      ),
      shortDesc: orderItemError.minQuantityHint.shortDesc,
    });
  } else {
    displayError = orderItemError;
  }

  if (!(availability || displayError || localMinAmountHint || isFreeGift)) {
    return null;
  }

  return (
    <Root className={additionalQuantityInformationClass}>
      {!isBcScan && !!availabilityMessage && (
        <QuantityHint message={availabilityMessage} />
      )}
      <OrderItemErrorHint orderItemError={displayError} />
      {!isBcScan && !!localMinAmountHint && (
        <Tooltip
          className={
            additionalQuantityInformationClass
              ? `${additionalQuantityInformationClass}_tooltip`
              : undefined
          }>
          {localMinAmountHint}
        </Tooltip>
      )}
      {!isBcScan && isFreeGift && (
        <QuantityHint message={t("cart.freeGiftHint")} />
      )}
      {showCartHint && <QuantityHint message={t("barcodeScanner.cartHint")} />}
    </Root>
  );
};

export default AdditionalQuantityInformation;
