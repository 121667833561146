/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { StyledTooltip } from "components/StyledUI";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  root: {
    color: theme.palette.common.white,
    padding: "11px 16px 9px",
    minWidth: 16,
    whiteSpace: "nowrap",
    transition: theme.transitions.create([
      "color",
      "background",
      "borderColor",
      "border",
    ]),
  },
  contained: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[800],
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    "&:active": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    "&[disabled]": {
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.common.white,
    },
    "&.dark": {
      backgroundColor: theme.palette.grey.A100,
      "&[disabled] svg": {
        color: theme.palette.grey[700],
      },
    },
  },
  containedPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:active": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  containedSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
    "&:focus": {
      backgroundColor: theme.palette.secondary.main,
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  outlinedPrimary: {
    padding: "9px 14px 7px",
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      border: `2px solid ${theme.palette.primary.light}`,
      color: theme.palette.common.white,
    },
    "&:focus": {
      border: `2px solid ${theme.palette.primary.light}`,
    },
    "&:active": {
      backgroundColor: theme.palette.primary.dark,
      border: `2px solid ${theme.palette.primary.dark}`,
      color: theme.palette.common.white,
    },
    "&[disabled]": {
      color: theme.palette.grey[600],
      border: `2px solid ${theme.palette.grey[600]}`,
    },
  },
  textPrimary: {
    color: theme.palette.grey.A200,
    padding: "10px 12px",
    fontWeight: 400,
    width: "44px",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    "&:active": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
  },
  textSizeSmall: {
    padding: "8px !important",
    width: "36px",
  },
  label: {
    whiteSpace: "nowrap",
  },
  sizeSmall: {
    padding: `8px 18px`,
    fontSize: 12,
    lineHeight: "20px",
  },
  sizeLarge: {
    padding: theme.spacing(1.5),
  },
  outlinedSizeSmall: {
    padding: "6px 16px !important",
  },
  outlinedSizeLarge: {
    padding: "10px !important",
  },
  startIcon: {
    marginRight: 0,
  },
  iconSizeSmall: {
    "&.MuiButton-startIcon": {
      marginLeft: "-10px",
      marginRight: "-10px",
    },
    "& svg": {
      width: 20,
      height: 20,
      pointerEvents: "none",
    },
  },
  iconSizeMedium: {
    marginTop: "-1px",
    marginBottom: "1px",
    "&.MuiButton-startIcon": {
      marginLeft: "-6px",
      marginRight: "-6px",
    },
    "& svg": {
      width: 24,
      height: 24,
      pointerEvents: "none",
    },
  },
  iconSizeLarge: {
    "&.MuiButton-startIcon": {
      marginLeft: 0,
    },
    "& svg": {
      width: 32,
      height: 32,
      pointerEvents: "none",
    },
  },
  fullWidth: {
    "&+&": {
      marginTop: theme.spacing(2),
    },
  },
}));

interface Props {
  dark?: boolean;
  component?: any;
  to?: string;
  disabled?: boolean;
  tooltip?: string;
  disableFocusListener?: boolean;
  useflexstyle?: boolean;
}

type StyledButtonProps = Props & ButtonProps;

const StyledButton: FC<StyledButtonProps> = (props) => {
  const {
    color,
    dark,
    variant,
    disabled,
    tooltip,
    disableFocusListener,
    useflexstyle,
    ...rest
  } = props;
  const { classes } = useStyles();

  if (tooltip) {
    return (
      <StyledTooltip
        title={tooltip}
        enterDelay={2500}
        enterNextDelay={2500}
        useflexstyle={useflexstyle}
        disableFocusListener={disableFocusListener || false}>
        <Button
          className={dark ? "dark" : ""}
          classes={classes}
          color={color || "primary"}
          variant={variant || "contained"}
          disableElevation
          disabled={disabled}
          {...rest}
        />
      </StyledTooltip>
    );
  }

  return (
    <Button
      className={dark ? "dark" : ""}
      classes={classes}
      color={color || "primary"}
      variant={variant || "contained"}
      disableElevation
      disabled={disabled}
      {...rest}
    />
  );
};

export { StyledButton };
