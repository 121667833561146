import productServiceExt from "_foundationExt/apis/search/product.service";
import type { Product } from "types/Product";

const fetchProductByIds = async (
  productIds: string[],
  storeId: string,
  setError: (error: boolean) => void
): Promise<Product[]> => {
  if (!productIds.length) {
    return [];
  }
  const productResponse = await productServiceExt.findByIds({
    storeId,

    $queryParameters: {
      id: productIds,
    },
  });

  if (productResponse.status !== 200) {
    setError(true);
  }

  return productResponse.data?.catalogEntryView ?? [];
};

export default fetchProductByIds;
