import React, { useEffect } from "react";
import { useAppDispatch } from "_redux/hooks";
import { UPDATE_ONLINE_STATUS_ACTION } from "_redux/actions/site";

const SimpleOnlineStatusChecker: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // initial check
    if (navigator.onLine) {
      dispatch(UPDATE_ONLINE_STATUS_ACTION("online"));
    } else {
      dispatch(UPDATE_ONLINE_STATUS_ACTION("offline"));
    }

    // recurring check
    const controller = new AbortController();
    const { signal } = controller;
    window.addEventListener(
      "offline",
      () => {
        dispatch(UPDATE_ONLINE_STATUS_ACTION("offline"));
      },
      {
        signal,
      }
    );

    window.addEventListener(
      "online",
      () => {
        dispatch(UPDATE_ONLINE_STATUS_ACTION("online"));
      },
      {
        signal,
      }
    );
  }, [dispatch]);

  return null;
};

export default SimpleOnlineStatusChecker;
