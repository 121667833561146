import React, { FC, PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ReactComponent as DownIcon } from "assets/icons/arrow-down-1.svg";
import { StyledButton } from "components/StyledUI";
import { tss } from "tss-react/mui";
import StyledCategoriesLink from "../StyledCategoriesLink";

interface StyledCategoriesAccordionProps {
  headline: React.ReactNode;
  expanded?: boolean;
  to: string;
  bold: boolean;
  underline: boolean;
}

const useStyles = tss.create(({ theme }) => ({
  accordion: {
    boxShadow: "none",
    backgroundColor: "transparent",
    "&:before": {
      height: "0px",
    },
    /* fixed minHeight and margin to prevent jumping headline */
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: `${theme.spacing(6)} !important`,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: `${theme.spacing(1.5)} 0 !important`,
    },
    "& .MuiAccordionSummary-expandIcon": {
      transition: "none",
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  padding: {
    paddingTop: "0",
    paddingLeft: "0",
    paddingRight: "0",
  },
}));

const StyledCategoriesAccordion: FC<
  PropsWithChildren<StyledCategoriesAccordionProps>
> = ({ headline, expanded = false, children, to, bold, underline }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [open, setOpen] = useState(expanded);

  const handleChange = () => setOpen((currentState) => !currentState);

  return (
    <Accordion
      className={classes.accordion}
      expanded={open}
      onChange={handleChange}>
      <AccordionSummary
        className={classes.padding}
        expandIcon={
          <StyledButton
            style={{ scale: "0.7" }}
            startIcon={<DownIcon />}
            size="small"
            color="inherit"
            tooltip={
              open
                ? t("iconTooltips.minimizeIcon")
                : t("iconTooltips.maximizeIcon")
            }
            useflexstyle
          />
        }>
        <StyledCategoriesLink
          bold={bold}
          underline={underline}
          to={to}
          onClick={(event) => event.stopPropagation()}>
          {headline}
        </StyledCategoriesLink>
      </AccordionSummary>
      <AccordionDetails className={classes.padding}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export { StyledCategoriesAccordion };
