import { eFoodDB } from "pwa/db/efood.db";

export const toValidNumber = (timestamp: unknown) => {
  const timestampAsNumber = Number(timestamp);
  return Number.isNaN(timestampAsNumber) ? undefined : timestampAsNumber;
};

export default class SyncTimestamp {
  private key: string;

  constructor(key: string) {
    this.key = key;
  }

  public put = async (timestamp: unknown) => {
    // check for null and undefined via double equal check
    if (timestamp == null) {
      return undefined;
    }

    const timestampAsNumber = toValidNumber(timestamp);
    if (timestampAsNumber) {
      return eFoodDB.syncTimestamps.put({
        name: this.key,
        value: timestampAsNumber,
      });
    }

    return undefined;
  };

  public get = async () => {
    const timestamp = await eFoodDB.syncTimestamps.get(this.key);
    return timestamp?.value;
  };

  public clear = () => eFoodDB.syncTimestamps.delete(this.key);
}
