/* eslint-disable camelcase */
import { BaseProduct, Product } from "./Product";

export interface Validation {
  message: string;
  type: string;
}

export interface Approval {
  basketIdentifier: BasketIdentifier;
  approvalMessage: string;
  approvalStatus: "Approved" | "Info" | "Warning" | "Denial";
}

export interface ShipInfos {
  address1: string;
  address2: string;
  address3: string;
  city: string;
  email: string;
  firstname: string;
  lastname: string;
  zipcode: string;
}

export interface SubBaskets {
  [index: string]: SubBasket;
}

export interface SubBasket {
  articleType: string;
  orderitems: OrderItem[];
  total: number;
  totalProduct: number;
  totalAdjustment: number;
  totalShipping: number;
}

export interface BasketIdentifier {
  type: string;
  dropShipType: string | null;
  deliveryDate: DeliveryDate | null;
}

export interface Basket {
  identifier: BasketIdentifier;
  earliestDeliveryDate: DeliveryDate | null;
  comment: string | null;
  validation: Validation | null;
  subBaskets: SubBasket[];
}

export interface DeliveryDate {
  date: Date;
  validUntil?: Date;
}

export interface Availability {
  pending: boolean;
  withOrderSplit: boolean;
  replenishmentDate: string | null;
  message: string | null;
}

export interface OrderItem {
  total: number;
  quantity: number;
  price: number;
  orderitemId: string;
  availability: Availability;
  adjustment: number;
  article: Product;
  createDate: string;
  lastUpdate: string;
  isFreeGift: boolean;
}

export const isOrderItem = (
  item?: OrderItem | BaseProduct
): item is OrderItem =>
  item != null && "orderitemId" in item && "article" in item;

export interface OrderHistory {
  entries: OrderHistoryDetails[];
  size: number;
}

export interface OrderHistoryDetails {
  orderType: number;
  notepadId: string;
  orderAmount: number;
  orderHistoryId: number;
  orderNumber: string;
  orderer: string;
  positions: number;
  deliveryDate: string;
  orderDate: string;
  orderState: number;
}

export interface BasketPrediction {
  type: string;
  customerNumber: string;
  cluster: {
    [index: string]: number;
  };
  individual: {
    [index: string]: number;
  };
}

export interface PromotionCode {
  id: string;
  code: string;
  active: boolean;
  qualified: boolean;
  conditions: string | null;
  name: string;
}

export interface Reward {
  code: string;
  title: string;
  numberOfGiftOptions: number;
  rewardSpecification: RewardSpecification;
  rewardOptions: RewardOption[];
}
export interface RewardOption {
  rewardOptionId: string;
  rewardChoice: RewardChoice;
}

export interface RewardSpecification {
  giftSetSpecification: GiftSetSpecification;
}

export interface GiftSetSpecification {
  giftItem: GiftItem[];
  maximumQuantity: GiftMaximumQuantity;
}

export interface GiftMaximumQuantity {
  uom: string;
  value: number;
}
export interface GiftItem {
  quantity: RewardQuantity;
  uniqueID: string;
}

export interface RewardQuantity {
  uom: string;
  value: number;
}

export interface RewardChoice {
  giftSet: GiftSet;
}

export interface GiftSet {
  giftItem: GiftItem[];
}
