import { OfferApi } from "@efood-commerce-sdk/typescript-axios-transaction-efood";
import { getSite } from "_foundation/hooks/useSite";
import { site } from "_foundationExt/constants/site";

const offerApiInstance = new OfferApi(undefined, site.transactionContext);

const EMPTY_RESPONSE = { data: { offers: [] } };

const offerService = {
  async getOffers() {
    const siteInfo = getSite();
    if (!siteInfo?.storeID) {
      return EMPTY_RESPONSE;
    }

    return offerApiInstance.getOffers(siteInfo.storeID);
  },
};

export default offerService;
