import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Hidden, Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { UnstyledLink, StyledPriceTableSmall } from "components/StyledUI";

import ProductAttributes from "components/productAttributes/ProductAttributes";
import Quantity from "components/quantity/Quantity";
import ProductImage from "components/productImage";

import { truncate } from "tools/stringUtils";
import { ROUTES } from "constants/routes";
import { Product } from "types/Product";
import { isEqual } from "lodash-es";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  root: {
    overflowX: "hidden",
    marginBottom: theme.spacing(1.5),
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  row: {
    "&:hover": {
      background: theme.palette.grey[50],
    },
  },
}));

interface ProductSearchItemProps {
  product: Product;
  onClick: () => void;
}

const ProductSearchItem: FC<ProductSearchItemProps> = ({
  product,
  onClick,
}) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const articleNumber = product.eFoodPartnumber;
  const discount = product.discountPrices?.[0] != null;

  return (
    <Grid
      className={cx(classes.root, classes.row)}
      id={`search_row_${product.partNumber}`}
      container
      wrap="nowrap"
      spacing={3}>
      <Hidden mdDown>
        <Grid sx={{ minWidth: discount ? "100px" : "92px" }}>
          <ProductImage
            product={product}
            size="68"
            badgeText={discount && t("cart.promotionCode")}
          />
        </Grid>
      </Hidden>
      <Grid container sx={{ width: "100%" }} margin={0}>
        <Grid xs={8} padding={0}>
          <UnstyledLink
            to={`${ROUTES.PRODUCT_DETAIL}/${product.eFoodPartnumber}`}
            onClick={onClick}>
            <Typography variant="body2">
              <b>{truncate(product.name, 50)}</b>
            </Typography>
            <Typography variant="subtitle1">
              {t("product.partnumber")} {articleNumber || ""}
            </Typography>
          </UnstyledLink>
        </Grid>
        <Grid xs={4} padding={0}>
          <StyledPriceTableSmall
            matrix={product.matrix}
            prices={product.prices}
            discountPrices={product.discountPrices}
          />
        </Grid>
        <Grid xs={12} padding={0}>
          <Box mt={0}>
            <Grid container alignItems="center">
              <Grid container xs={3}>
                <ProductAttributes
                  attributes={product.attributes}
                  inContract={product.inContract}
                  smallVariant
                  customerProductlists={product.customerProductlists || []}
                />
              </Grid>
              <Grid xs={9}>
                <Quantity data={product} type="search" />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(ProductSearchItem, isEqual);
