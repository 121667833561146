import { getSite } from "_foundation/hooks/useSite";
import {
  DeliveryDateApi,
  TourdataApi,
} from "@efood-commerce-sdk/typescript-axios-transaction-efood";
import { site as constantsSite } from "_foundationExt/constants/site";
import ensureObject from "tools/ensureObject";

const deliveryDateApiInstance = new DeliveryDateApi(
  undefined,
  constantsSite.transactionContext
);

const tourDataApiInstance = new TourdataApi(
  undefined,
  constantsSite.transactionContext
);

const deliveryDateService = {
  /**
   * loads all possible delivery dates
   * @param parameters {storeId: string}
   */
  getTourData(parameters: Record<string, unknown>) {
    const site = getSite();
    const { storeId = site?.storeID, ...options } = ensureObject(parameters);

    return tourDataApiInstance.getTourdata(storeId, options);
  },
  /**
   * load current deliverydate
   * @param url
   */
  getCurrentDeliveryDate() {
    const siteInfo = getSite();
    if (!siteInfo) {
      return Promise.reject();
    }

    return deliveryDateApiInstance.getCurrentDeliveryDate(siteInfo?.storeID);
  },

  /**
   * load current deliverydate
   * @param parameters {storeId: string, dropShipType?: string}
   */
  getDeliveryDates(parameters: Record<string, unknown>) {
    const site = getSite();
    const { storeId = site?.storeID, ...options } = ensureObject(parameters);

    return deliveryDateApiInstance.getDeliveryDates(storeId, options);
  },

  /**
   * set current deliverydate
   * @param parameters {storeId: string, dropShipType?: string, validUntil?: string, date: string}
   */
  setDeliveryDate(parameters: Record<string, unknown>) {
    const site = getSite();
    const {
      storeId = site?.storeID,
      body,
      ...options
    } = ensureObject(parameters);

    return deliveryDateApiInstance.setCurrentDeliveryDate(
      storeId,
      body,
      options
    );
  },
};
export default deliveryDateService;
