import React, { FC, useCallback, useLayoutEffect, useState } from "react";

import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "components/StyledUI";
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import { showDialogSelector } from "_redux/selectors/site";
import { CLOSE_DIALOG_ACTION } from "_redux/actions/site";
import { tss } from "tss-react/mui";
import * as contents from "./contents";
import * as titles from "./titles";

const useStyles = tss.create(({ theme }) => ({
  dialogRoot: {
    "& .MuiPaper-root": {
      maxWidth: "600px",
    },
  },
  dialogContentRoot: {
    padding: theme.spacing(3, 4),
  },
}));

const Dialog: FC = () => {
  const { classes } = useStyles();
  const dialogList = useAppSelector(showDialogSelector);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const dialog = dialogList?.[0] ?? {};
  const { type, params, closeable = true } = dialog;

  useLayoutEffect(() => {
    if (type) setOpen(true);
    else setOpen(false);
  }, [type]);

  const handlePopperClose = useCallback(() => {
    if (type) {
      dispatch(CLOSE_DIALOG_ACTION({ type }));
    }
  }, [dispatch, type]);

  const DialogContent = contents[type];
  if (!DialogContent) {
    return null;
  }
  const Title = titles[`${type}Title`];

  return (
    <StyledDialog
      className={classes.dialogRoot}
      open={open}
      onClose={closeable ? handlePopperClose : undefined}
      disableEscapeKeyDown={!closeable}>
      {Title ? (
        <StyledDialogTitle
          id={`dialog-${type}`}
          onClose={closeable ? handlePopperClose : undefined}>
          <Title close={handlePopperClose} params={params} />
        </StyledDialogTitle>
      ) : null}
      <StyledDialogContent className={classes.dialogContentRoot}>
        <DialogContent close={handlePopperClose} params={params} />
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default Dialog;
