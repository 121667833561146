import React, { FC } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ESpotData } from "types/Marketing";
import HTMLReactParser from "html-react-parser";
import { tss } from "tss-react/mui";

type Props = {
  data: ESpotData | undefined;
};

const useStyles = tss.create(({ theme }) => ({
  green: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    height: "334px",
    width: "100%",
    position: "relative",
    "& img": {
      position: "absolute",
      right: theme.spacing(2),
      bottom: theme.spacing(2),
      maxWidth: "80px",
      maxHeight: "80px",
    },
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      fontFamily: "ShadowsIntoLight",
      fontWeight: "normal",
      fontSize: "32px",
      marginTop: "0",
    },
  },
}));

const ContentCard: FC<Props> = ({ data }) => {
  const { classes } = useStyles();
  if (!data) {
    return null;
  }

  return (
    <>
      {data.contents &&
        data.contents.map((content) => (
          <Grid key={`espot_${data.id}_content_${content.id}`}>
            <Box className={classes.green}>
              {content.marketingText &&
                HTMLReactParser(content.marketingText || "")}
              {content.attachment && content.attachment.url && (
                <img src={content.attachment.url} alt="" />
              )}
            </Box>
          </Grid>
        ))}
    </>
  );
};

export default ContentCard;
