//Standard libraries
import { createReducer } from "@reduxjs/toolkit";
//Redux

import initStates from "./initStates";
import { API_CALL_ACTION } from "_redux/actions/api";

/**
 * API reducer
 * handles states used by api related components for development use
 * @param state State object managed by api reducer
 * @param action The dispatched action
 */
const apiReducer = createReducer(initStates.api, (builder) => {
  builder.addCase(API_CALL_ACTION, (state, action) => {
    state.apiFlowList.push(action.payload);
  });
});

export default apiReducer;
