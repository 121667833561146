import { takeLatest, takeLeading } from "redux-saga/effects";
import {
  RESET_ERROR_ACTION,
  WATCH_AXIOS_ERROR_ACTION,
  VALIDATION_ERROR_ACTION,
} from "_redux/actions/error";
import * as WORKERS from "../workers/error";

export function* watchSaga() {
  yield takeLatest(RESET_ERROR_ACTION, WORKERS.resetError);
  yield takeLeading(WATCH_AXIOS_ERROR_ACTION, WORKERS.handleAxiosErrors);
  yield takeLeading(VALIDATION_ERROR_ACTION, WORKERS.handleValidationError);
}
