import React, { FC, PropsWithChildren } from "react";
import type { ZIndexWithExtension } from "theme/zIndexExtension";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  root: {
    position: "fixed",
    display: "inline-block",
    zIndex: (theme.zIndex as ZIndexWithExtension).extension.scrollUpButton,
    bottom: theme.spacing(2),
    maxWidth: "1440px",
    width: "100%",
    transform: "translate(-50%, 0px)",
    left: "50%",
    textAlign: "right",
    paddingRight: "16px",
    pointerEvents: "none",
  },
}));

const StyledScrollUpWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default StyledScrollUpWrapper;
