export const maintenanceData = [
  {
    id: "10151",
    identifier: "nfs",
    email: "info@nordischer-fs.de",
    phone: "+49 (0) 38207 7 42 - 0",
    subdomain: "nfs",
    www: "https://nfs.servicebund.de",
  },
  {
    id: "10651",
    identifier: "vnk",
    email: "verkauf@nussbaumer.de",
    phone: "+49 (0) 9367 98 40 - 0",
    subdomain: "nussbaumer",
    www: "https://www.nussbaumer.de",
  },
  {
    id: "10652",
    identifier: "w21",
    email: "service@windmann-sb.de",
    phone: "+49 (0) 5732 96 88 - 0",
    subdomain: "windmann",
    www: "https://www.windmann.servicebund.de",
  },
  {
    id: "11151",
    identifier: "osn",
    email: "info.nuernberg@omega-sorg.de",
    phone: "+49 (0) 9122 63 05 - 0",
    subdomain: "omega-nuernberg",
    www: "https://www.omega-sorg.de",
  },
  {
    id: "11152",
    identifier: "wbt",
    email: "bestellung@bast-servicebund.de",
    phone: "+49 (0) 4861 96 00 - 0",
    subdomain: "bast",
    www: "https://www.bast-servicebund.de/",
  },
  {
    id: "11651",
    identifier: "r04",
    email: "info@sb-recker-gardelegen.de",
    phone: "+49 (0) 3907 77 61 - 0",
    subdomain: "recker-gardelegen",
    www: "https://www.sb-recker-gardelegen.de",
  },
  {
    id: "12151",
    identifier: "zin",
    email: "info@feinkost-zink.de",
    phone: "+49 (0) 7223 98 62 - 0",
    subdomain: "zink",
    www: "https://feinkost-zink.servicebund.de",
  },
  {
    id: "18651",
    identifier: "jws",
    email: "verkauf@fruechte-jork.de",
    phone: "+49 (0) 07562 97 44 - 0",
    subdomain: "jork",
    www: "https://www.fruechte-jork.de",
  },
  {
    id: "12651",
    identifier: "fbb",
    email: "bestellung@flach-rheinmain.de",
    phone: "+49 (0) 6152 8 06 32 - 0",
    subdomain: "flach-buettelborn",
    www: "https://www.flach-sb.de",
  },
  {
    id: "12652",
    identifier: "fri",
    email: "bestellung@flach-sb.de",
    phone: "+49 (0) 6691 9 49 49 - 0",
    subdomain: "flach",
    www: "https://www.flach-sb.de",
  },
  {
    id: "13151",
    identifier: "jmm",
    email: "info@mettler-servicebund.com",
    phone: "+49 (0) 6533 93 85 - 0",
    subdomain: "mettler",
    www: "https://www.mettler-servicebund.com",
  },
  {
    id: "13152",
    identifier: "rfs",
    email: "verkauf@rittnerfoodservice.de",
    phone: "+49 (0) 89 13 07 46 - 0",
    subdomain: "rittner",
    www: "https://www.rittnerfoodservice.servicebund.de",
  },
  {
    id: "13153",
    identifier: "oss",
    email: "info.stuttgart@omega-sorg.de",
    phone: "+49 (0) 711 40 10 - 3000",
    subdomain: "omega-stuttgart",
    www: "https://www.omega-sorg.de",
  },
  {
    id: "13154",
    identifier: "fbr",
    email: "bestell@bauer-feinkost.de",
    phone: "+49 (0) 9563 72 28 - 88",
    subdomain: "bauer",
    www: "https://www.bauer-feinkost.de",
  },
  {
    id: "13155",
    identifier: "osw",
    email: "info.waldheim@omega-sorg.de",
    phone: "+49 (0) 34327 9 59 - 4000",
    subdomain: "omega-waldheim",
    www: "https://www.omega-sorg.de",
  },
  {
    id: "13156",
    identifier: "sbn",
    email: null,
    phone: null,
    subdomain: "national",
    www: "https://www.servicebund-national.de",
  },
  {
    id: "13651",
    identifier: "xtr",
    email: "bestell@troiber.de",
    phone: "+49 (0) 8545 17 - 0",
    subdomain: "troiber",
    www: "https://www.troiber.de",
  },
  {
    id: "13652",
    identifier: "sgk",
    email: "service@schwalli.de",
    phone: "+49 (0) 5631 50 07 - 0",
    subdomain: "schwalli",
    www: "https://www.schwalli.de",
  },
  {
    id: "14151",
    identifier: "hgs",
    email: "verkauf@hambrock.de",
    phone: "+49 (0) 2536 34 30 - 333",
    subdomain: "hambrock",
    www: "https://www.hambrock.de",
  },
  {
    id: "18151",
    identifier: "suh",
    email: "info@schwarz-hansen.de",
    phone: "+49 (0) 40 7 81 21 - 0",
    subdomain: "schwarz-hansen",
    www: "https://schwarz-hansen.servicebund.de",
  },
  {
    id: "17151",
    identifier: "sus",
    email: "info@steidingerschmidt.de",
    phone: "+49 (0) 7641 96 23 - 0",
    subdomain: "steidingerschmidt",
    www: "https://www.steidingerschmidt.de",
  },
  {
    id: "14651",
    identifier: "osa",
    email: "info.essingen@omega-sorg.de",
    phone: "+49 (0) 7361 94 70 - 1000",
    subdomain: "omega-essingen",
    www: "https://www.omega-sorg.de",
  },
  {
    id: "15151",
    identifier: "ald",
    email: "verkauf@gastromaster-pf.de",
    phone: "+49 (0) 7231 95 56 - 0",
    subdomain: "aldinger",
    www: "https://www.gastromaster-pf.de",
  },
  {
    id: "17651",
    identifier: "grf",
    email: "info@regiergmbh.de",
    phone: "+49 (0) 4681 – 718 und – 719",
    subdomain: "regier",
    www: "https://www.regier.servicebund.de",
  },
  {
    id: "15651",
    identifier: "sbw",
    email: "service@rauchhaupt-servicebund.de",
    phone: "+49 (0) 3425 89 55 - 0",
    subdomain: "rauchhaupt",
    www: "https://www.rauchhaupt-servicebund.de",
  },
  {
    id: "16151",
    identifier: "aun",
    name: "Albrecht+Neiss GmbH",
    email: "auftrag@albrecht-neiss.de",
    phone: "+49 (0) 33 42 24 9 - 6",
    subdomain: "albrecht-neiss",
    www: "https://albrecht-neiss.servicebund.de",
  },
  {
    id: "16152",
    identifier: "r01",
    email: "bestellung@recker-sb.de",
    phone: "+49 (0) 5446 99 30 - 0",
    subdomain: "recker",
    www: "https://www.recker-servicebund.de",
  },
  {
    id: "16652",
    identifier: "bro",
    email: "bestellung@bierbichler.bayern",
    phone: "+49 (0) 8031 7 21 - 21",
    subdomain: "bierbichler",
    www: "https://bierbichler.servicebund.de",
  },
  {
    id: "19651",
    identifier: "boy",
    email: "info@konrad-boysen.de",
    phone: "+49 (0) 4651 8 27 - 00",
    subdomain: "boysen",
    www: "https://boysen.servicebund.de",
  },
  {
    id: "0",
    identifier: "www",
    email: "ecommerce@servicebund.de",
    phone: null,
    subdomain: "www",
    www: "https://www.servicebund.de",
  },
] as const;

export type MaintenanceDataType = (typeof maintenanceData)[number];
