import { ServiceWorkerContext } from "pwa/serviceWorker/context";
import { FETCHING_CART_ACTION } from "_redux/actions/order";
import { OrderReducerState } from "_redux/reducers";
import {
  createOrderitemFrom,
  handleItemModifySuccess,
} from "_redux/reducers/order";
import store from "_redux/store";

import { OrderItem } from "types/Order";
import cartService from "_foundation/apis/transaction/cart.service";
import { HttpStatusCode, isAxiosError } from "axios";
import { ServerError } from "types/Error";
import isInstanaActive from "tools/isInstanaActive";
import { CUSTOM_HEADER, isProvisionalOrderItemId } from "../common/constants";
import { eFoodDB, EFoodDB } from "../../db/efood.db";
import { OfflineOrderItem, OfflineOrderItemLogEntry } from "../../db/types";
import {
  AddOrderItemLogEntry,
  GetCartResponse,
  UpdateOrderItemLogEntry,
} from "./types";

const isOrderItemAlreadyCompleted = (responseData: unknown) => {
  const { errors } = (responseData ?? {}) as { errors?: ServerError[] };
  if (!errors || !errors.length) {
    return false;
  }
  return errors.some(
    (e) =>
      e.errorCode === "_ERR_USER_AUTHORITY" &&
      e.errorParameters ===
        "com.ibm.commerce.orderitems.commands.OrderItemUpdateCmd"
  );
};

const compareOfflineOrderItemLogEntryByPK = (
  a: OfflineOrderItemLogEntry,
  b: OfflineOrderItemLogEntry
) => {
  if (a.pk == null) {
    if (b.pk == null) {
      return 0;
    }
    return -1;
  }
  if (b.pk == null) {
    return 1;
  }
  return a.pk - b.pk;
};

class OfflineCartHandler {
  db: EFoodDB;

  isSyncing: boolean;

  constructor(db: EFoodDB) {
    this.db = db;
    this.isSyncing = false;
  }

  public async addOrderItemLogEntry(orderItemLogEntry: AddOrderItemLogEntry) {
    const now = Date.now();
    return this.db.orderItemLog.put({
      ...orderItemLogEntry,
      createDate: now,
      updateDate: now,
    });
  }

  public async updateOrderItemLogEntry(
    orderItemLogEntry: UpdateOrderItemLogEntry,
    merge = true
  ) {
    const { pk } = orderItemLogEntry ?? {};
    if (!pk) {
      return Promise.reject(new Error(`Missing required parameter pk.`));
    }
    const logEntryToUpdate = await this.db.orderItemLog.get(pk);
    if (!logEntryToUpdate) {
      return Promise.reject(
        new Error(
          `Could not update order item log entry. An order item log entry with pk ${pk} was not found.`
        )
      );
    }
    const updatedOrderItemLogEntry: OfflineOrderItemLogEntry = {
      ...logEntryToUpdate,
      ...orderItemLogEntry,
      updateDate: Date.now(),
    };

    await this.db.orderItemLog.put(updatedOrderItemLogEntry);

    if (merge && updatedOrderItemLogEntry.orderItemId) {
      await Promise.all([
        this.removeObsoleteNotSyncedOrderItemLogEntries(
          updatedOrderItemLogEntry.orderItemId
        ),
        this.mergeSyncedOrderItemLogEntries(
          updatedOrderItemLogEntry.orderItemId
        ),
      ]);
    }

    return Promise.resolve();
  }

  public async removeOrderItemLogEntriesByOrderItemId(orderItemId: string) {
    return this.db.orderItemLog
      .where("orderItemId")
      .equals(orderItemId)
      .delete();
  }

  /**
   * Merge order item log entries for a given orderItemId. Only synced log entries will be merged.
   *
   * If an "update" or "delete" log entry is encountered, without the prior processing of an "add" or "merged" log entry
   * the log entry will be removed, without merging.
   *
   * @param orderItemIdToMerge the order item id for which the log entries should be merged
   * @returns Promise<undefined> if nothing could be merged or a Promise containing the merged state of the log entries.
   */
  public async mergeSyncedOrderItemLogEntries(
    orderItemIdToMerge: string
  ): Promise<OfflineOrderItemLogEntry | undefined> {
    const orderItemsHistory =
      await this.getOrderItemLogEntriesByOrderItemId(orderItemIdToMerge);

    if (!orderItemsHistory.length) {
      // nothing to do
      return undefined;
    }

    // since we used the orderItemId index to search entries, the db result will be sorted by orderItemId
    // so we need to sort it again to the primary key, as the primary key is autoincremented we will get a sorting after time
    orderItemsHistory.sort(compareOfflineOrderItemLogEntryByPK);

    const pKsToRemove: Array<number> = [];
    const oldestPK = orderItemsHistory[0].pk!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    const mergeResult = orderItemsHistory.reduce(
      (result, entry) => {
        const {
          pk,
          orderItemId,
          partNumber,
          quantity,
          rounded,
          type,
          isSynced,
          createDate,
          updateDate,
        } = entry;

        if (!pk || !isSynced) {
          // without pk, we are not able to do anything
          return result;
        }

        pKsToRemove.push(pk);

        switch (type) {
          case "add": {
            const newResult: OfflineOrderItemLogEntry = {
              orderItemId,
              partNumber,
              quantity,
              rounded,
              isSynced,
              createDate,
              updateDate,
              type: isSynced ? "merged" : "add",
            };

            return newResult;
          }
          case "update": {
            const newResult: OfflineOrderItemLogEntry = {
              ...result,
              orderItemId,
              partNumber: partNumber || result?.partNumber,
              quantity,
              rounded,
              isSynced,
              createDate: result?.createDate ? result.createDate : createDate,
              updateDate,
              type: isSynced ? "merged" : "update",
            };
            return newResult;
          }
          case "delete": {
            const newResult: OfflineOrderItemLogEntry = {
              ...result,
              orderItemId,
              quantity: 0,
              isSynced,
              createDate: result?.createDate ? result.createDate : createDate,
              updateDate,
              type: isSynced ? "merged" : "delete",
            };
            return newResult;
          }
          case "merged": {
            return entry;
          }
          default:
            return result;
        }
      },
      undefined as OfflineOrderItemLogEntry | undefined
    );

    if (pKsToRemove.length > 0 && mergeResult) {
      const result = { ...mergeResult, pk: oldestPK };
      await this.db.transaction("rw", this.db.orderItemLog, async () => {
        await this.db.orderItemLog.bulkDelete(pKsToRemove);
        if (mergeResult.quantity) {
          await this.db.orderItemLog.put(result);
        }
      });

      return result;
    }

    return undefined;
  }

  /**
   * Searches for not synced order item log entries whose update timestamp is older than the update timestamp of the
   * newest synced order item log entry.
   *
   * @param orderItemIds the order item ids for which obsolete log entries should be searched
   * @returns Promise<Array<number>> the primary keys of log entries which are obsolete
   */
  public async getObsoleteNotSyncedOrderItemLogEntries(
    orderItemIds: string[]
  ): Promise<Array<number>> {
    const orderItemsHistories = await Promise.all(
      orderItemIds.map((id) => this.getOrderItemLogEntriesByOrderItemId(id))
    );

    return orderItemsHistories
      .map((orderItemsHistory) => {
        const pksToRemove: number[] = [];

        if (!orderItemsHistory.length) {
          return pksToRemove;
        }

        const splittedOrderItemsHistory = orderItemsHistory.reduce(
          (
            splittedResult: {
              synced: Array<OfflineOrderItemLogEntry>;
              notSynced: Array<OfflineOrderItemLogEntry>;
            },
            logEntry
          ) => {
            if (logEntry.isSynced) {
              splittedResult.synced.push(logEntry);
            } else {
              splittedResult.notSynced.push(logEntry);
            }
            return splittedResult;
          },
          { synced: [], notSynced: [] }
        );

        if (
          !splittedOrderItemsHistory.synced.length ||
          !splittedOrderItemsHistory.notSynced.length
        ) {
          return pksToRemove;
        }

        splittedOrderItemsHistory.synced.sort(
          (a, b) => (b.updateDate ?? 0) - (a.updateDate ?? 0)
        );
        const newestSyncedUpdateTimestamp =
          splittedOrderItemsHistory.synced[0].updateDate ?? 0;

        return splittedOrderItemsHistory.notSynced
          .filter(
            (e) => e.pk && (e.updateDate ?? 0) < newestSyncedUpdateTimestamp
          )
          .map((e) => e.pk!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
      })
      .flat();
  }

  protected async getOrderItemIds(orderItemIds?: string | string[]) {
    if (!orderItemIds) {
      return this.getOrderItemIdsFromDB();
    }
    if (Array.isArray(orderItemIds)) {
      return orderItemIds;
    }
    return [orderItemIds];
  }

  /**
   * Removes not synced order item log entries whose update timestamp is older than the update timestamp of the
   * newest synced order item log entry.
   *
   *
   * @param orderItemId the order item id for which obsolete log entries should be removed
   * @returns Promise<Array<number>> the primary keys of log entries which where deleted
   */
  public async removeObsoleteNotSyncedOrderItemLogEntries(
    orderItemId?: string | string[]
  ): Promise<Array<number>> {
    const orderItemIds = await this.getOrderItemIds(orderItemId);
    const pksToRemove: number[] =
      await this.getObsoleteNotSyncedOrderItemLogEntries(orderItemIds);

    if (pksToRemove.length) {
      await this.db.orderItemLog.bulkDelete(pksToRemove);
    }

    return pksToRemove;
  }

  public async getDeletedProvisionalOrderItemLogEntriesPKs(
    optionalOrderItemIds?: string | string[]
  ) {
    const orderItemIds = await this.getOrderItemIds(optionalOrderItemIds);
    const provisionalOrderItemLogEntries = await Promise.all(
      orderItemIds
        .filter(isProvisionalOrderItemId)
        .map((orderItemId) =>
          this.getOrderItemLogEntriesByOrderItemId(orderItemId)
        )
    );

    return provisionalOrderItemLogEntries.reduce((result, current) => {
      if (current.length) {
        current.sort(compareOfflineOrderItemLogEntryByPK);
        const lastEntry = current[current.length - 1];
        if (lastEntry && lastEntry.type === "delete") {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          result.push(...current.map((e) => e.pk!));
        }
      }
      return result;
    }, [] as number[]);
  }

  /**
   * removes obsolete and deleted provisional order item log entries
   *
   * @param optionalOrderItemIds optional orderItemIds which should be checked, if not provided all entries present in the db will be checked
   * @returns the PK of the log entries removed
   */
  public async removeObsoleteOrderItemLogEntries(
    optionalOrderItemIds?: string | string[]
  ): Promise<Array<number>> {
    const orderItemIds = await this.getOrderItemIds(optionalOrderItemIds);

    const obsoleteNotSyncedOrderItemPKs =
      await this.getObsoleteNotSyncedOrderItemLogEntries(orderItemIds);
    const deletedProvisionalOrderItemLogEntryPKs =
      await this.getDeletedProvisionalOrderItemLogEntriesPKs(orderItemIds);

    const pksToRemove: number[] = [
      ...new Set(
        obsoleteNotSyncedOrderItemPKs.concat(
          deletedProvisionalOrderItemLogEntryPKs
        )
      ),
    ];

    if (pksToRemove.length) {
      await this.db.orderItemLog.bulkDelete(pksToRemove);
    }

    return pksToRemove;
  }

  public async clear() {
    return this.db.orderItemLog.clear();
  }

  public async getCart() {
    const { currentDeliveryDate } = ServiceWorkerContext.current.user;

    const cart: OrderReducerState = {
      id: "-1",
      baskets: [],
      numItems: 0,
      shipInfos: null,
      total: 0,
      totalProduct: 0,
      totalAdjustment: 0,
      totalShipping: 0,
      promotionCode: [],
      rewards: [],
    };

    const createOfflineOrderItemPromises: Array<
      Promise<OrderItem | undefined>
    > = [];
    const offlineOrderItemsMap = await this.getCurrentOrderItems();
    offlineOrderItemsMap.forEach((offlineOrderItem) => {
      createOfflineOrderItemPromises.push(
        this.createOfflineOrderItem(offlineOrderItem)
      );
    });
    const orderItems = (
      await Promise.all(createOfflineOrderItemPromises)
    ).filter(Boolean) as Array<OrderItem>;

    orderItems.forEach((orderItem) => {
      const {
        orderitemId: orderItemId,
        article,
        availability,
        quantity,
      } = orderItem;
      handleItemModifySuccess({
        cart,
        orderItemId,
        article,
        availability,
        quantity,
        stockDeliveryDate: { date: currentDeliveryDate },
      });
    });

    return cart;
  }

  public async syncGetCartResponse(response: GetCartResponse) {
    if (!response?.baskets?.length) {
      return;
    }

    const responseOrderItems = new Map<string, OfflineOrderItem>();
    response.baskets.forEach((basket) => {
      basket.subBaskets.forEach((subBasket) => {
        subBasket.orderitems.forEach((subBasketOrderItem) => {
          const {
            orderitemId,
            quantity,
            article: { partNumber },
            lastUpdate,
          } = subBasketOrderItem;
          responseOrderItems.set(orderitemId, {
            pk: 0,
            orderItemId: orderitemId,
            quantity,
            partNumber,
            isSynced: true,
            updateDate: new Date(lastUpdate).getTime(),
            type: "add",
          });
        });
      });
    });

    if (!responseOrderItems.size) {
      return;
    }

    const offlineOrderItems = await this.getCurrentOrderItems(false);

    responseOrderItems.forEach((responseOrderItem, orderItemId) => {
      const offlineOrderItem = offlineOrderItems.get(orderItemId);
      if (offlineOrderItem) {
        offlineOrderItems.delete(orderItemId);
        if (responseOrderItem.updateDate > offlineOrderItem.updateDate) {
          // change offline order item quantity
          this.addOrderItemLogEntry({
            type: "update",
            orderItemId,
            quantity: Number(responseOrderItem.quantity),
            rounded: false,
            isSynced: true,
          });
        }
      } else {
        // add log entry
        this.addOrderItemLogEntry({
          type: "add",
          orderItemId,
          partNumber: responseOrderItem.partNumber,
          quantity: Number(responseOrderItem.quantity),
          rounded: false,
          isSynced: true,
        });
      }
    });

    // remove remaining offlineOrderItems, when they're synced
    await Promise.all(
      [...offlineOrderItems.values()]
        .filter((e) => e.isSynced)
        .map((e) => this.removeOrderItemLogEntriesByOrderItemId(e.orderItemId))
    );

    const orderItemIds = await this.getOrderItemIdsFromDB();
    if (orderItemIds?.length) {
      await Promise.all([
        this.removeObsoleteNotSyncedOrderItemLogEntries(orderItemIds),
        ...orderItemIds.map((id) => this.mergeSyncedOrderItemLogEntries(id)),
      ]);
    }
  }

  public async isCartSyncNeeded() {
    try {
      const offlineOrderItems = await this.getCurrentOrderItems(false);
      return [...offlineOrderItems].some(
        ([_key, offlineOrderItem]) =>
          !offlineOrderItem.isSynced &&
          (offlineOrderItem.type !== "delete" ||
            !isProvisionalOrderItemId(offlineOrderItem.orderItemId))
      );
    } catch (e) {
      if (e instanceof Error && isInstanaActive()) {
        ineum("reportError", e, {
          componentStack: e.stack,
          meta: {
            reason: "error while checking if offline cart sync is needed",
          },
        });
      }
      return false;
    }
  }

  /**
   * removes remaining provisional order item log entries, after a cart sync was completed
   *
   * @returns the PK of the log entries removed
   */
  public async removeProvisionalOrderItemLogEntries(orderItemIds: string[]) {
    const pkToRemovePerOrderItemId = await Promise.all(
      orderItemIds
        .filter(isProvisionalOrderItemId)
        .map((orderItemId) =>
          this.db.orderItemLog
            .where("orderItemId")
            .equals(orderItemId)
            .primaryKeys()
        )
    );

    const pksToRemove = pkToRemovePerOrderItemId.flatMap((pks) => pks);

    if (pksToRemove.length) {
      await this.db.orderItemLog.bulkDelete(pksToRemove);
    }

    return pksToRemove;
  }

  public async syncCart() {
    if (this.isSyncing) {
      return;
    }
    this.isSyncing = true;
    try {
      const offlineOrderItems = await this.getCurrentOrderItems(false);

      const syncPromises: Promise<number | void>[] = [];

      offlineOrderItems.forEach((offlineOrderItem) => {
        const { type, isSynced } = offlineOrderItem;
        if (isSynced) {
          // do nothing
          return;
        }

        switch (type) {
          case "merged": // fall-through intended
          case "add":
            syncPromises.push(
              this.syncOfflineOrderItemTypeAdd(offlineOrderItem)
            );
            break;
          case "update":
            syncPromises.push(
              this.syncOfflineOrderItemTypeUpdate(offlineOrderItem)
            );
            break;
          case "delete":
            if (!isProvisionalOrderItemId(offlineOrderItem.orderItemId)) {
              syncPromises.push(
                this.syncOfflineOrderItemTypeDelete(offlineOrderItem)
              );
            }
            break;
          default: // do nothing
        }
      });
      const orderItemIdsHandled = Array.from(offlineOrderItems.keys());

      await this.removeProvisionalOrderItemLogEntries(orderItemIdsHandled);
      await this.removeObsoleteOrderItemLogEntries();

      // refresh cart from server if sync is complete
      if (syncPromises.length) {
        await Promise.allSettled(syncPromises).catch();
        store.dispatch(FETCHING_CART_ACTION({}));
      }
    } catch (e) {
      if (e instanceof Error && isInstanaActive()) {
        ineum("reportError", e, {
          componentStack: e.stack,
          meta: {
            reason: "error while syncing offline cart data",
          },
        });
      }
    }
    this.isSyncing = false;
  }

  protected getOrderItemLogEntriesByOrderItemId(orderItemId: string) {
    return this.db.orderItemLog
      .where("orderItemId")
      .equals(orderItemId)
      .toArray();
  }

  protected getOrderItemIdsFromDB() {
    return this.db.orderItemLog
      .orderBy("orderItemId")
      .uniqueKeys((keys) => keys.map((key) => String(key)));
  }

  public async getCurrentOrderItems(removeDeletedEntries = true) {
    const orderItemsHistory = await this.db.orderItemLog
      .orderBy("pk")
      .toArray();
    const orderItems = orderItemsHistory.reduce((map, logEntry) => {
      const {
        pk,
        orderItemId,
        partNumber,
        quantity,
        rounded,
        type,
        isSynced,
        updateDate,
      } = logEntry;
      if (!orderItemId) {
        return map;
      }

      const offlineOrderItem = map.get(orderItemId);
      switch (type) {
        case "merged":
        // fallthrough intended
        case "add": {
          if (partNumber == null || quantity == null || rounded == null) {
            // Incomplete order item log entry for type "add" discovered. Ignoring it.
            break;
          }
          map.set(orderItemId, {
            pk: pk!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            orderItemId,
            partNumber,
            quantity,
            isSynced,
            updateDate: updateDate ?? 0,
            type,
          });
          break;
        }
        case "update": {
          if (
            quantity == null ||
            (!offlineOrderItem?.partNumber && !partNumber)
          ) {
            // Incomplete order item log entry for type "update" discovered. Ignoring it.
            break;
          }
          map.set(orderItemId, {
            pk: pk!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            orderItemId,
            partNumber: partNumber ?? offlineOrderItem?.partNumber ?? "",
            quantity,
            isSynced,
            updateDate: updateDate ?? offlineOrderItem?.updateDate ?? 0,
            type: offlineOrderItem?.isSynced
              ? type
              : offlineOrderItem?.type ?? type,
          });
          break;
        }
        case "delete": {
          if (!offlineOrderItem) {
            // Delete order item log entry discovered but missing log entries for the given orderItemId. Ignoring "delete" event.
            break;
          }
          if (
            removeDeletedEntries ||
            (!offlineOrderItem?.partNumber && !partNumber)
          ) {
            map.delete(orderItemId);
            break;
          }

          map.set(orderItemId, {
            pk: pk!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            orderItemId,
            partNumber: partNumber ?? offlineOrderItem?.partNumber ?? "",
            quantity: 0,
            isSynced,
            updateDate: updateDate ?? offlineOrderItem?.updateDate ?? 0,
            type,
          });

          break;
        }
        default:
          throw new Error("unknown order item log entry type discovered");
      }

      return map;
    }, new Map<string, OfflineOrderItem>());

    return orderItems;
  }

  protected async createOfflineOrderItem({
    orderItemId,
    partNumber,
    quantity,
  }: OfflineOrderItem): Promise<OrderItem | undefined> {
    const product = await this.db.products
      .where("partNumber")
      .equals(partNumber)
      .first();

    if (product) {
      return createOrderitemFrom(
        orderItemId,
        quantity,
        {
          ...product,
          attributes: Object.values(product.attributes).flat(),
          partNumber,
        },
        {
          message: null,
          pending: false,
          replenishmentDate: null,
          withOrderSplit: false,
        }
      );
    }

    return undefined;
  }

  protected async syncOfflineOrderItemTypeAdd(
    offlineOrderItem: OfflineOrderItem
  ) {
    const { pk, partNumber, quantity } = offlineOrderItem;

    // call server
    const parameters = {
      body: {
        orderItem: [
          {
            quantity: quantity.toString(),
            partNumber,
          },
        ],
      },
      headers: {
        [CUSTOM_HEADER.X_EFOOD_OFFLINE_SYNC_REQUEST]: "true",
      },
    };

    // call server
    const response = await cartService.addOrderItem(parameters);

    if (response.status !== 200) {
      return undefined;
    }

    // update order item log entry
    const {
      orderItemId,
      quantity: updatedQuantity,
      rounded,
    } = response.data?.orderItem?.[0] ?? {};

    return this.updateOrderItemLogEntry({
      pk,
      type: "add",
      isSynced: true,
      orderItemId,
      quantity: updatedQuantity,
      rounded,
    });
  }

  protected async syncOfflineOrderItemTypeUpdate(
    offlineOrderItem: OfflineOrderItem
  ) {
    const { pk, orderItemId, partNumber, quantity } = offlineOrderItem;
    // call server
    const parameters = {
      body: {
        orderItem: [
          {
            quantity: quantity.toString(),
            orderItemId,
            partNumber,
          },
        ],
      },
      headers: {
        [CUSTOM_HEADER.X_EFOOD_OFFLINE_SYNC_REQUEST]: "true",
      },
    };

    try {
      // call server
      const response = await cartService.updateOrderItem(parameters);

      if (response.status !== 200) {
        return undefined;
      }

      // update order item log entry
      const {
        orderItemId: responseOrderItemId,
        quantity: responseQuantity,
        rounded,
      } = response.data?.orderItem?.[0] ?? {};

      return this.updateOrderItemLogEntry({
        pk,
        type: "update",
        isSynced: true,
        orderItemId: responseOrderItemId ?? "",
        quantity: Number(responseQuantity),
        rounded,
      });
    } catch (e) {
      if (
        isAxiosError(e) &&
        e.response?.status === HttpStatusCode.BadRequest &&
        isOrderItemAlreadyCompleted(e.response.data)
      ) {
        return this.removeOrderItemLogEntriesByOrderItemId(orderItemId);
      }
      throw e;
    }
  }

  protected async syncOfflineOrderItemTypeDelete(
    offlineOrderItem: OfflineOrderItem
  ) {
    const { pk, orderItemId } = offlineOrderItem;

    const parameters = {
      body: {
        orderItemId,
      },
      headers: {
        [CUSTOM_HEADER.X_EFOOD_OFFLINE_SYNC_REQUEST]: "true",
      },
    };

    try {
      // call server
      const response = await cartService.deleteOrderItem(parameters);

      if (response.status !== 200) {
        return undefined;
      }

      // update orderitemlogentry
      return this.updateOrderItemLogEntry({
        pk,
        type: "delete",
        isSynced: true,
      });
    } catch (e) {
      if (
        isAxiosError(e) &&
        e.response?.status === HttpStatusCode.BadRequest &&
        isOrderItemAlreadyCompleted(e.response.data)
      ) {
        return this.removeOrderItemLogEntriesByOrderItemId(orderItemId);
      }
      throw e;
    }
  }
}

const defaultOfflineCartHandler = new OfflineCartHandler(eFoodDB);
export default defaultOfflineCartHandler;
