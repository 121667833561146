import { ARTICLE_TYPE } from "constants/product";
import { DiscountEntry } from "@efood-commerce-sdk/typescript-axios-transaction-efood";
import { isObject } from "lodash-es";
import { ProductListType } from "./CustomerProductlist";

export interface EFoodPrice {
  price: number;
  currencySymbol: string;
  matrixShortDescription: string;
}

export const isEFoodPrice = (candidate: unknown): candidate is EFoodPrice =>
  candidate != null &&
  typeof candidate === "object" &&
  "price" in candidate &&
  "currencySymbol" in candidate &&
  "matrixShortDescription" in candidate;

export interface UserData {
  field3?: string;
  categoryName?: string;
  field4?: string;
  vke?: string;
  vketext?: string;
}

export type Identifier =
  | "NODECLADDITIVE"
  | "NODECLALLERGENE"
  | "GLUTENFREE"
  | "LACTOSEFREE"
  | "VEGETARIAN"
  | "NEW"
  | "WEIGHTARTICLE"
  | "SEASONARTICLE"
  | "SBBRAND"
  | "OPTAINARTICLE"
  | "ARTICLEFLAG"
  | "EAN";

export interface AttributeMap {
  Informationen?: Attribute[];
  Inhalte?: Attribute[];
  Nährwerte?: Attribute[];
  Produktdaten?: Attribute[];
  "-"?: Attribute[];
}

export const isAttributeMap = (candidate: unknown): candidate is AttributeMap =>
  isObject(candidate) &&
  ("Informationen" in candidate ||
    "Inhalte" in candidate ||
    "Nährwerte" in candidate ||
    "Produktdaten" in candidate ||
    "-" in candidate);

export interface Attribute {
  comparable: boolean;
  displayable: boolean;
  facetable: boolean;
  identifier: Identifier;
  name: string;
  searchable: boolean;
  sequence: string;
  storeDisplay: boolean;
  uniqueID: string;
  usage: string;
  values: AttributeValue[];
}

export interface AttributeValue {
  identifier: string;
  image1: string;
  image1path: string;
  sequence: string;
  uniqueID: string;
  unitID: string;
  unitOfMeasure: string;
  value: string;
}

export interface Matrix {
  position: string;
  longDesc: string;
  shortDesc: string;
  convFactor: string;
  normType: string;
  handlingCode: string;
}

export interface BaseAttributes {
  values: AttributeValue[];
  name: string;
}

export interface NutritionCharacteristic extends BaseAttributes {
  identifier:
    | "NODECLADDITIVE"
    | "NODECLALLERGENE"
    | "GLUTENFREE"
    | "LACTOSEFREE"
    | "VEGETARIAN"
    | "VEGAN"
    | "NEW"
    | "WEIGHTARTICLE"
    | "SEASONARTICLE"
    | "SBBRAND"
    | "OPTAINARTICLE"
    | "ARTICLEFLAG"
    | "ARTICLEORDERTIME"
    | "FOODFORMVALUES"
    | "PRODUCTOFFERS"
    | "BIO";
}

export interface ContentHints extends BaseAttributes {
  identifier: "HINT";
}

const NutritionCharacteristicIdentifiers = [
  "NODECLADDITIVE",
  "NODECLALLERGENE",
  "GLUTENFREE",
  "LACTOSEFREE",
  "VEGETARIAN",
  "VEGAN",
  "NEW",
  "WEIGHTARTICLE",
  "SEASONARTICLE",
  "SBBRAND",
  "OPTAINARTICLE",
  "ARTICLEFLAG",
  "ARTICLEORDERTIME",
  "FOODFORMVALUES",
  "PRODUCTOFFERS",
  "BIO",
];

export const isNutritionCharacteristic = (
  candidate: unknown
): candidate is NutritionCharacteristic =>
  isObject(candidate) &&
  "identifier" in candidate &&
  NutritionCharacteristicIdentifiers.some(
    (identifier) => identifier === candidate.identifier
  );

export interface BaseProduct {
  buyable: string;
  name: string;
  shortDescription?: string;
  longDescription?: string;
  partNumber: string;
  eFoodPartnumber: string;
  uniqueID: string;
  UserData: UserData[];
  inContract: boolean;
  matrix: Matrix[];
  prices?: EFoodPrice[];
  discountPrices?: DiscountEntry[];
  location?: string;
  productListItemId?: string;
  customerProductlists?: string[];
  standardQuantity?: number;
  articleType: ARTICLE_TYPE;
  minAmount: number;
  parentCatalogGroupID: string | string[];
  parentCatgroupsPath?: string[];
  attributes: Attribute[] | AttributeMap;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: PropertyKey]: any;
}

export const isBaseProduct = (candidate: unknown): candidate is BaseProduct =>
  isObject(candidate) && "matrix" in candidate && "attributes" in candidate;

export interface Product extends BaseProduct {
  attributes: Attribute[];
}

export const isProduct = (candidate: unknown): candidate is Product =>
  isBaseProduct(candidate) && !isAttributeMap(candidate.attributes);

export interface ProductDetail extends BaseProduct {
  attributes: AttributeMap;
  nutritionCharacteristics: NutritionCharacteristic[] | null;
  contentHints?: ContentHints[];
  sequence?: string;
  categorySequence?: string;
  manufacturer?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isProductDetail = (candidate: any): candidate is ProductDetail =>
  isBaseProduct(candidate) && isAttributeMap(candidate.attributes);

export interface ProductList {
  id: string;
  name: string;
  productListItemId: string;
  description;
  type: ProductListType;
}
