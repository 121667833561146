import Setting from "pwa/db/Setting";

const offlineSyncEnabled = new Setting(
  "offline-sync-enabled",
  false,
  (value) => value === "true",
  (value) => String(value)
);

export default offlineSyncEnabled;
