import React, { PropsWithChildren } from "react";
import { StyledProgress } from "components/StyledUI";

import Grid from "@mui/material/Unstable_Grid2";
import { useLoggedIn } from "_foundationExt/hooks/useLoggedIn";
import { ROUTES } from "constants/routes";
import Restricted from "./Restricted";

interface Props {
  redirectTo?: string;
}

/**
 * Usage:
 *  <Route path={ROUTES.RECIPES} element={
 *      <RequireLoggedIn>
 *          <Recipes />
 *      </RequireLoggedIn>
 *  } />
 * redirect to site when not loggedin
 */
const RequireLoggedIn = ({
  children,
  redirectTo = ROUTES.LOGON,
}: PropsWithChildren<Props>) => {
  const { loggedIn, loading } = useLoggedIn();

  if (loading) {
    return (
      <Grid container justifyContent="center">
        <StyledProgress />
      </Grid>
    );
  }

  return (
    <Restricted restricted={!loggedIn} redirectTo={redirectTo}>
      {children}
    </Restricted>
  );
};

export { RequireLoggedIn };
