import React, { FC } from "react";
import { Typography } from "@mui/material";

import { EFoodPrice, Matrix } from "types/Product";
import { formatCurrency } from "tools/stringUtils";

import { DiscountEntry } from "@efood-commerce-sdk/typescript-axios-transaction-efood";
import { StyledProtectable } from "../StyledProtectable";

const mergeData = (matrix: Matrix[], prices?: EFoodPrice[]) => {
  const matrixEntry = matrix.find((item) => item.handlingCode === "1");
  const priceEntry = prices?.find(
    (item) => item.matrixShortDescription === matrixEntry?.shortDesc
  );

  return {
    ...matrixEntry,
    ...priceEntry,
  };
};

interface StyledPriceTableSmallProps {
  matrix: Matrix[];
  prices?: EFoodPrice[];
  shortDesc?: boolean;
  discountPrices?: DiscountEntry[];
}

const StyledPriceTableSmall: FC<StyledPriceTableSmallProps> = ({
  matrix,
  prices,
  discountPrices,
  shortDesc = false,
}) => {
  const priceMatrix = mergeData(matrix, prices);

  const discountPrice = discountPrices?.[0] ?? undefined;

  return (
    <Typography variant="body2" align="right">
      {shortDesc ? priceMatrix.shortDesc : priceMatrix.longDesc}
      <StyledProtectable permission="show.price">
        <br />

        {discountPrice != null ? (
          <>
            <s>{formatCurrency(priceMatrix.price)}</s>
            <br />
            <b>{formatCurrency(discountPrice.discountPrice)}</b>
          </>
        ) : (
          <b>{formatCurrency(priceMatrix.price)}</b>
        )}
      </StyledProtectable>
    </Typography>
  );
};

export default StyledPriceTableSmall;
