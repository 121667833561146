import { ActivityDate, ContentTracking, NVPProperties } from "types/Marketing";

const getContentTracking = (
  activity: ActivityDate,
  nvp: NVPProperties
): ContentTracking => {
  const result: ContentTracking = {};

  if (activity && (activity.activityIdentifierName || activity.contentName)) {
    result.content = true;
    result.name = activity.activityIdentifierName;
    result.piece = activity.contentName;
  }

  if (nvp) {
    Object.keys(nvp).map((item) => {
      if (item.includes("name")) {
        result.content = true;
        result.name = nvp[item];
      } else if (item.includes("piece")) {
        result.content = true;
        result.piece = nvp[item];
      } else if (item.includes("target")) {
        result.content = true;
        result.target = nvp[item];
      } else if (item.includes("content")) {
        result.content = Boolean(nvp[item]);
      }
      return null;
    });
  }

  return result;
};

export default getContentTracking;
