import productServiceExt from "_foundationExt/apis/search/product.service";
import { Product } from "types/Product";

const fetchProductByPartNumbers = async (
  partNumbers: string[],
  storeId: string,
  setError: (error: boolean) => void
): Promise<Product[]> => {
  if (!partNumbers.length) {
    return [];
  }
  const productResponse = await productServiceExt.byPartNumbers({
    storeId,
    partNumbers,
  });

  if (productResponse.status !== 200) {
    setError(true);
  }

  const products = productResponse.data?.catalogEntryView ?? [];
  // prefix all part numbers with '0' to have same format as returned .eFoodPartnumber
  const prefixedPartNumbers = partNumbers.map((partNumber) =>
    partNumber.toString().padStart(6, "0")
  );
  // sort products to reflect order of given partNumbers
  const sortedProducts = products.sort(
    (a, b) =>
      prefixedPartNumbers.indexOf(a.eFoodPartnumber) -
      prefixedPartNumbers.indexOf(b.eFoodPartnumber)
  );

  return sortedProducts;
};

export default fetchProductByPartNumbers;
