import {
  CACHE_IMAGE_MARKER_NAME,
  CACHE_IMAGE_MARKER_VALUE,
  EFOOD_SERVB_PROXY_IMAGE_HOST,
  EFOOD_SERVB_PROXY_IMAGE_HOST_QA,
  EFOOD_SERVB_PROXY_IMAGE_HOST_TEST,
  SERVB_IMAGE_HOST,
} from "pwa/serviceWorker/constants";
import { EnvironmentType } from "./getEnvironmentType";

const getReplacementHost = (envType: EnvironmentType) => {
  switch (envType) {
    case "prod":
      return EFOOD_SERVB_PROXY_IMAGE_HOST;
    case "qa":
      return EFOOD_SERVB_PROXY_IMAGE_HOST_QA;
    case "test":
      return EFOOD_SERVB_PROXY_IMAGE_HOST_TEST;
    case "development":
      return EFOOD_SERVB_PROXY_IMAGE_HOST_QA;
    default: {
      // throw typescript error, if new environment type is defined
      const exhaustiveCheck: undefined = envType;
      return exhaustiveCheck;
    }
  }
};

const cacheLuckyCloudImage = (
  envType: EnvironmentType,
  url: URL | string,
  cache = false
) => {
  const tmp = url instanceof URL ? url : new URL(url, document.baseURI);

  if (cache) {
    tmp.searchParams.append(CACHE_IMAGE_MARKER_NAME, CACHE_IMAGE_MARKER_VALUE);
    if (tmp.hostname === SERVB_IMAGE_HOST) {
      const replacementHost = getReplacementHost(envType);
      if (replacementHost) {
        tmp.hostname = replacementHost;
      }
    }
  }

  return tmp;
};

export default cacheLuckyCloudImage;
