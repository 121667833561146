import React, { FC, ReactElement, useEffect, useMemo } from "react";
import { GridProps } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tss } from "tss-react/mui";
import { useFetchESpotDataByFindByName } from "./utils";
import ProductRecommendation from "./widgets/ProductRecommendation";
import PredictiveRecommendation from "./widgets/PredictiveRecommendation";
import ContentRecommendation from "./widgets/ContentRecommendation";
import ProductProximities from "./widgets/ProductProximities";
import CategoryRecommendation from "./widgets/CategoryRecommendation";

export interface ESpotParams {
  emsName: string;

  categoryId?: string;
  productId?: string;
  eFoodPartnumber?: string;
  useCatalogEntryId?: boolean;
  useCatalogGroupId?: boolean;

  className?: string;
  contentClassName?: string;
  sidebar?: boolean;
  sidebarBottom?: boolean;

  passShowEspot?: (showEspot: boolean) => void;
}

const useStyles = tss.create(({ theme }) => ({
  wrapper: {
    margin: theme.spacing(2, 0, 3, 0),
  },
  root: {
    marginBottom: theme.spacing(1),
    position: "relative",
  },
}));

type Props = ESpotParams & GridProps;

type ContentToRender = { key: string; content: ReactElement };
const isContentToRender = (
  content?: ContentToRender
): content is ContentToRender => content != null;

const ESpot: FC<Props> = ({
  emsName,
  categoryId,
  productId,
  eFoodPartnumber,
  useCatalogEntryId = true,
  useCatalogGroupId = true,

  className,
  sidebar = false,
  sidebarBottom = false,

  passShowEspot,
  ...rest
}) => {
  const { classes, cx } = useStyles();

  const { data, error, loading, found } = useFetchESpotDataByFindByName({
    emsName,
    categoryId,
    productId,
    useCatalogEntryId,
    useCatalogGroupId,
  });

  useEffect(() => {
    if (passShowEspot && !found && !loading) {
      passShowEspot(false);
    }
  }, [found, loading, passShowEspot]);

  const espotContent = useMemo(() => {
    if (!data) {
      return [];
    }
    return data
      .map((item, index) => {
        const isContentRecommendation =
          item.contents &&
          item.contents.length > 0 &&
          ((item.type.includes("MarketingContent") && item.type.length === 1) ||
            (item.type.includes("MarketingContent") &&
              item.type.includes("DM_NVP") &&
              item.type.length === 2));

        const isProductRecommendation =
          item.products &&
          item.products.length > 0 &&
          item.type.includes("CatalogEntryId");

        const isPredictiveRecommendation =
          item.type.includes("PredictivBasket");

        const isCategoryRecommendation = item.type.includes("CatalogGroupId");

        const isProductProximities = item.type.includes("ProductProximities");

        const key = `espotContent_${emsName}_${index}`;

        if (isContentRecommendation) {
          return {
            key,
            content: <ContentRecommendation data={item} sidebar={sidebar} />,
          };
        }

        if (isProductRecommendation) {
          return {
            key,
            content: (
              <ProductRecommendation
                data={item}
                sidebar={sidebar}
                sidebarBottom={sidebarBottom}
              />
            ),
          };
        }

        if (isCategoryRecommendation) {
          return {
            key,
            content: <CategoryRecommendation data={item} sidebar={sidebar} />,
          };
        }

        if (isPredictiveRecommendation) {
          return {
            key,
            content: (
              <PredictiveRecommendation
                data={item}
                emsName={emsName}
                productId={productId}
                sidebar={sidebar}
                categoryId={categoryId}
              />
            ),
          };
        }

        if (isProductProximities) {
          return {
            key,
            content: (
              <ProductProximities
                data={item}
                eFoodPartnumber={eFoodPartnumber}
                sidebar={sidebar}
              />
            ),
          };
        }

        return undefined;
      })
      .filter(isContentToRender);
  }, [
    data,
    categoryId,
    eFoodPartnumber,
    emsName,
    productId,
    sidebar,
    sidebarBottom,
  ]);

  if (loading || error || !data?.length || !found) {
    return null;
  }

  return (
    <Grid container className={cx(classes.wrapper, className)}>
      {espotContent?.length
        ? espotContent.map(({ key, content }) => (
            <Grid
              key={key}
              container
              xs={12}
              className={classes.root}
              {...rest}>
              {content}
            </Grid>
          ))
        : null}
    </Grid>
  );
};

export default React.memo(ESpot);
