import React, { FC, PropsWithChildren } from "react";

import { tss } from "tss-react/mui";

const useStyles = tss.withParams<Props>().create(({ size }) => ({
  root: {
    margin: "0 auto",
    maxWidth: size === "medium" ? "1224px" : "808px",
  },
}));

type Props = {
  size: "medium" | "small";
};

const StyledContainer: FC<PropsWithChildren<Props>> = ({ size, children }) => {
  const { classes } = useStyles({ size });

  return <div className={classes.root}>{children}</div>;
};

export { StyledContainer };
