import { eFoodDB } from "pwa/db/efood.db";

type FromString<ValueType> = (value: string) => ValueType;
type ToString<ValueType> = (value: ValueType) => string;

export default class Setting<ValueType> {
  private key: string;

  private errorValue: ValueType;

  private fromString: FromString<ValueType>;

  private toString: ToString<ValueType>;

  constructor(
    key: string,
    errorValue: ValueType,
    fromString: FromString<ValueType>,
    toString: ToString<ValueType>
  ) {
    this.key = key;
    this.errorValue = errorValue;
    this.fromString = fromString;
    this.toString = toString;
  }

  public put = async (value?: ValueType) => {
    // check for null and undefined via double equal check
    if (value == null) {
      return undefined;
    }

    return eFoodDB.settings.put({
      name: this.key,
      value: this.toString(value),
    });
  };

  public get = async () => {
    try {
      const setting = await eFoodDB.settings.get(this.key);
      if (setting?.value == null) {
        return undefined;
      }
      return this.fromString(setting.value);
    } catch {
      return this.errorValue;
    }
  };

  public clear = () => eFoodDB.settings.delete(this.key);
}
