export const SUCCESS_MSG_PREFIX = "success-message.";
export const ORDER_ID = "orderid";
export const HYPHEN = "-";
export const SLOTID = "id";
export const SELLER_STORAGE_KEY = "seller";

export const CommerceEnvironment = {
  productSkeleton: {
    id: "",
    type: "Product",
    productInternal: "",
    productDesc: {},
    eSpotInternal: {},
    eSpotDescInternal: {},
  },
  suggestionSkeleton: {
    arrIndex: "",
    id: "",
    type: "Suggestion",
    name: "",
    url: {},
  },
  errorParamsSkeleton: {
    zero: "",
    one: "",
    two: "",
  },
  defaultLang: "de_DE",
  languageMap: {
    "-1": "en_US",
    "-3": "de_DE",
  } as const,
  reverseLanguageMap: {
    en_US: "-1",
    en: "-1",
    de_DE: "-3",
    de: "-3",
  } as const,
  reverseLanguageMapForDateFns: {
    en_US: "en-US",
    de_DE: "de",
  } as const,
  dxLanguageMap: {
    "-1": "en",
    "-3": "de",
  } as const,
};
