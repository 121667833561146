/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
// Standard libraries
import React, { FC, useCallback } from "react";
import HTMLReactParser, { domToReact } from "html-react-parser";
import { Link } from "react-router-dom";
// Foundation libraries
import { commonUtil } from "@hcl-commerce-store-sdk/utils";
import { useAbortControllers } from "_foundationExt/hooks";
import { useAppDispatch } from "_redux/hooks";
import { ComIbmCommerceRestMarketingHandlerESpotDataHandlerESpotContainerMarketingSpotDataContainer } from "@hcl-commerce-store-sdk/typescript-axios-transaction";
import { EnvironmentType } from "tools/getEnvironmentType";
import isExternalUrl from "tools/isExternalUrl";
import cacheLuckyCloudImage from "tools/cacheLuckyCloudImage";
import { useEnvironmentContext } from "components/helpers/environment-context";
import { useSite } from "../../../_foundation/hooks/useSite";
import eSpotService from "../../../_foundation/apis/transaction/eSpot.service";
// Redux
import { ADD_ITEM_ACTION } from "../../../_redux/actions/order";
import { CLICK_EVENT_TRIGGERED_ACTION } from "../../../_redux/actions/marketingEvent";

import { mapNodeToReactComponent } from "./mapNodeToReactComponent";
import { createFindByNameParameters } from "../espot/utils/useFetchESpotDataByFindByName";

interface ContentRecommendationOptions {
  cacheAsset?: boolean;
}

export interface ContentRecommendationProps {
  cid?: string;
  eSpot: {
    eSpotName?: string;
    type?: string;
  };
  page: {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    externalContext?: any;
  };
  options?: ContentRecommendationOptions;
}

interface Template {
  id?: string | null;
  template?: ReturnType<typeof domToReact> | null;
}

interface Content {
  title?: string;
  templates: Template[];
}

type PerformClickFunction = (
  event: React.MouseEvent<HTMLElement, MouseEvent>,
  { eSpotRoot, eSpotDesc }
) => void;

const getAssetSrc = (
  envType: EnvironmentType,
  attachmentAssetPath?: string,
  options: ContentRecommendationOptions = {}
) => {
  if (!attachmentAssetPath) {
    return "";
  }

  try {
    const { isExternal, url } = isExternalUrl(attachmentAssetPath);
    const { cacheAsset = false } = options;
    if (isExternal && url) {
      // external url
      return cacheLuckyCloudImage(envType, url, cacheAsset).toString();
    }

    return cacheLuckyCloudImage(
      envType,
      attachmentAssetPath.startsWith("/")
        ? attachmentAssetPath
        : `/${attachmentAssetPath}`,
      cacheAsset
    ).toString();
  } catch {
    return "";
  }
};

const processMarketingSpotData = (
  eSpotRoot: ComIbmCommerceRestMarketingHandlerESpotDataHandlerESpotContainerMarketingSpotDataContainer,
  performClick: PerformClickFunction,
  env: EnvironmentType,
  options?: ContentRecommendationOptions
): Template[] => {
  const templates: Template[] = [];
  const eSpotData = eSpotRoot.baseMarketingSpotActivityData || [];
  eSpotData.forEach((eSpotDesc) => {
    // Define the component using Component decorator.
    const currentTemplate: Template = {};
    const desc = eSpotDesc.marketingContentDescription;
    const assetSrc = getAssetSrc(
      env,
      eSpotDesc?.attachmentAsset?.[0]?.attachmentAssetPath,
      options
    );
    const assetName =
      eSpotDesc?.attachmentDescription?.[0]?.attachmentName || "";

    if (desc?.[0]?.marketingText?.length) {
      // each template text suppose to only have one <a> tag
      currentTemplate.template = HTMLReactParser(desc[0].marketingText.trim(), {
        replace: mapNodeToReactComponent,
      });
    } else if (eSpotDesc.contentUrl) {
      currentTemplate.template = (
        <Link
          to={eSpotDesc.contentUrl}
          onClick={(event) => performClick(event, { eSpotRoot, eSpotDesc })}>
          <div className="imageWrapper">
            <img alt={assetName} src={assetSrc} crossOrigin="anonymous" />
          </div>
        </Link>
      );
    } else {
      currentTemplate.template = (
        <div className="imageWrapper">
          <img alt={assetName} src={assetSrc} crossOrigin="anonymous" />
        </div>
      );
    }
    currentTemplate.id = eSpotDesc.contentId;
    templates.push(currentTemplate);
  });

  return templates;
};

const ContentRecommendation: FC<ContentRecommendationProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  cid,
  eSpot,
  page,
  options,
}) => {
  const ESPOT_TYPE_PAGE_PREFIX = "page-prefix";
  const ESPOT_TYPE_PAGE_SUFFIX = "page-suffix";

  const { eSpotName, type } = eSpot;

  const dispatch = useAppDispatch();
  const abortControllers = useAbortControllers();
  const { envType } = useEnvironmentContext();
  const { currentSite } = useSite();
  const [content, setContent] = React.useState<Content>();

  const performClick = useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      { eSpotRoot, eSpotDesc }
    ) => {
      dispatch(CLICK_EVENT_TRIGGERED_ACTION({ eSpotRoot, eSpotDesc }));
      const linkAction = commonUtil.parseContentUrl(eSpotDesc.contentUrl || "");
      if (linkAction?.action) {
        event.preventDefault();
        switch (linkAction.action) {
          case "addToCart": {
            const { signal } = abortControllers.create();
            const payload = {
              quantity: [1],
              partnumber: linkAction.partnumber,
              signal,
            };
            dispatch(ADD_ITEM_ACTION(payload));
            break;
          }
          case "addToWishlist":
            // do nothing
            break;
          default:
            // do nothing
            break;
        }
      }
    },
    [abortControllers, dispatch]
  );

  const initESpot = useCallback(
    (
      pageName: string,
      storeId: string,
      catalogId: string,
      signal: AbortSignal
    ) => {
      let eSName = eSpotName;
      if (type === ESPOT_TYPE_PAGE_SUFFIX) {
        eSName += pageName; // pagename
      } else if (type === ESPOT_TYPE_PAGE_PREFIX) {
        eSName = pageName + eSName;
      }
      const parameters = createFindByNameParameters({
        emsName: eSName ?? "",
        storeId,
        catalogId,
        signal,
      });
      eSpotService
        .findByName(parameters)
        .then((res) => {
          const eSpotRoot = res.data.MarketingSpotData[0];
          const title =
            (eSpotRoot?.marketingSpotDataTitle?.[0]
              ?.marketingContentDescription?.[0]?.marketingText as string) ||
            undefined;
          const templates = processMarketingSpotData(
            eSpotRoot,
            performClick,
            envType,
            options
          );
          setContent({ templates, title });
        })
        .catch((/* error */) => {
          // console.error(error);
        });
    },
    [eSpotName, type, performClick, envType, options]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    if (currentSite?.storeID && currentSite.catalogID && page) {
      const pageName = page.externalContext?.identifier || page.name;
      initESpot(
        pageName,
        currentSite.storeID,
        currentSite.catalogID,
        abortController.signal
      );
    }

    return () => {
      abortController.abort();
    };
  }, [page, currentSite?.storeID, currentSite?.catalogID, initESpot]);

  return (
    <>
      {content && (
        <div>
          {content.title && <h2>{HTMLReactParser(content.title)}</h2>}
          {content.templates.map((t: Template) => (
            <React.Fragment key={t.id}>{t.template}</React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};

export default ContentRecommendation;
