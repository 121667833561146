// react
import React, { FC, useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
// redux
import { useAppSelector } from "_redux/hooks";
import { storeListSelector } from "_redux/selectors/site";
// mui
import { tss } from "tss-react/mui";
import { Typography, Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  StyledButton,
  StyledFormInput,
  StyledNotification,
} from "components/StyledUI";
// components
import externalService from "_foundationExt/apis/transaction/external.service";
import { ROUTES } from "constants/routes";
// types
import { StoreSearchResult } from "types/Store";
import { ServerError } from "types/Error";
// assets
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { isAxiosError } from "axios";
import getDisplayName from "tools/getDisplayName";
import { useAbortControllers } from "_foundationExt/hooks";

interface Store {
  registrationtype: "NONE" | "ALL" | "NEW";
  servisaID: string;
  host: string;
}

const useStyles = tss.create(({ theme }) => ({
  content: {
    maxWidth: "370px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "310px",
      minWidth: "75vw",
    },
  },
  spacing: {
    marginBottom: theme.spacing(2),
  },
  unstyled: {
    textDecoration: "none !important",
    color: "inherit",
  },
}));

interface ZipCodeProps {
  setView: (view: number) => void;
}

const PostalCodeView: FC<ZipCodeProps> = ({ setView }) => {
  const widgetName = getDisplayName(PostalCodeView);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const abortControllers = useAbortControllers();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ServerError | null>(null);

  const storeListSelect: Store[] = useAppSelector(storeListSelector);
  const [searchResult, setSearchResult] = useState<StoreSearchResult[]>();
  const [storeList, setStoreList] = useState<Store[]>([]);
  const [postalCode, setPostalCode] = useState("");
  const [inValidPostalCode, setInValidPostalCode] = useState(true);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    if (storeListSelect?.length && !storeList.length) {
      setStoreList(
        storeListSelect.map((item) => {
          let url = item.host + ROUTES.HOME;
          if (item.registrationtype === "ALL") {
            url = item.host + ROUTES.REGISTER_TYPE;
          } else if (item.registrationtype === "NEW") {
            url = item.host + ROUTES.REGISTER_NEW_CUSTOMER;
          }
          return {
            registrationtype: item.registrationtype,
            servisaID: item.servisaID,
            host: url,
          };
        })
      );
    }
  }, [storeListSelect, storeList.length]);

  const handlePlzChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const plz = e.target.value;
    setPostalCode(plz);
    setInValidPostalCode(plz.match(/\b\d{5}\b/g) === null);
  };

  const triggerPlzSearch = async () => {
    setLoading(true);
    try {
      const { signal } = abortControllers.create();
      const response = await externalService.searchStoreByPlz(postalCode, {
        widget: widgetName,
        signal,
      });
      setSearchResult(response.data);
      setNoResults(!response.data?.length);
    } catch (e) {
      if (isAxiosError(e)) {
        setError(e.response?.data.error?.[0]);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <StyledButton
        className="close"
        onClick={() => setView(1)}
        startIcon={<CloseIcon />}
        size="small"
        color="inherit"
        tooltip={t("iconTooltips.closeIcon")}
      />
      <Grid container className={classes.content}>
        <Grid xs={12}>
          <Box mb={3} mr={2}>
            <Typography variant="h1" component="p">
              {t("registration.findPartner")}
            </Typography>
          </Box>

          {!!error && (
            <Box mb={3}>
              <StyledNotification severity="error">
                {error.errorMessage}
              </StyledNotification>
            </Box>
          )}
        </Grid>

        <Grid xs={8}>
          <Box mr={1}>
            <StyledFormInput
              className={classes.spacing}
              id="plzsearch"
              name="plzsearch"
              label={t("registration.searchPlz")}
              placeholder={t("registration.searchPlz")}
              fullWidth
              error={noResults}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  triggerPlzSearch();
                }
              }}
              onChange={handlePlzChange}
              autoFocus
            />
          </Box>
        </Grid>
        <Grid xs={4}>
          <StyledButton
            fullWidth
            onClick={triggerPlzSearch}
            disabled={inValidPostalCode || loading}>
            {t("search.search")}
          </StyledButton>
        </Grid>
        {searchResult?.map((result) => {
          const store = storeList.find(
            (item) => item.servisaID === result.identifier
          );

          return (
            <Grid xs={12}>
              <Box mt={1} mb={1}>
                <Box mb={1}>
                  <Typography variant="body2">
                    <b>{result.name}</b>
                    <br />
                    {result.address.streetAddress}
                    <br />
                    {result.address.postalCode} {result.address.addressLocality}
                    <br />
                    {t("registration.phoneShort")}: {result.telephone}
                    <br />
                    {t("registration.email")}.: {result.email} <br />
                  </Typography>
                </Box>
                <Link
                  href={`//${!store ? result.url : store.host}`}
                  className={classes.unstyled}>
                  <StyledButton
                    fullWidth
                    className={classes.unstyled}
                    size="small"
                    variant="outlined">
                    {!store ? t("action.register") : t("action.toSite")}
                  </StyledButton>
                </Link>
              </Box>
            </Grid>
          );
        })}
        {noResults && (
          <Grid xs={12}>
            <Box mt={1}>
              <Typography variant="body2">
                {t("registration.searchNoResult")}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default PostalCodeView;
