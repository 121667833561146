import Axios, { isAxiosError } from "axios";
import { ROUTES } from "constants/routes";
import { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

const useAxiosMaintenanceInterceptor = () => {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const responseInterceptor = Axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          isAxiosError(error) &&
          (error.status === 503 || error.response?.status === 503)
        ) {
          navigate(ROUTES.MAINTENANCE, { replace: true });
          return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );

    // Return cleanup function to remove interceptors if necessary
    return () => {
      Axios.interceptors.response.eject(responseInterceptor);
    };
  }, [navigate]);
};

export default useAxiosMaintenanceInterceptor;
