/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, Method, AxiosPromise } from "axios";
import { executeRequest } from "_foundation/axios/axiosConfig";
import { getSite } from "_foundation/hooks/useSite";

const designService = {
  byPageId(parameters: any, url?: string): AxiosPromise<any> {
    const param = parameters || {};

    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.transactionContext || "";
    }

    const domain = url || siteContext;
    const path = "/store/{storeId}/page_design";
    let requestUrl = domain + path;
    const method: Method = "GET";
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    const queryParameters = new URLSearchParams();
    const body = {};

    if (param.storeId === undefined && site !== null) {
      param.storeId = site.storeID;
    }
    requestUrl = requestUrl.replace("{storeId}", param.storeId);

    if (param.storeId === undefined) {
      throw new Error(
        "Request '/store/{storeId}/page_design' missing required parameter storeId"
      );
    }

    queryParameters.set("q", "byObjectIdentifier");
    queryParameters.set("deviceClass", "Any");
    queryParameters.set("pageGroup", "Content");

    if (param.objectIdentifier === undefined) {
      throw new Error(
        "Request '/store/{storeId}/page_design' missing required parameter objectIdentifier"
      );
    }

    queryParameters.set("objectIdentifier", param.objectIdentifier);

    if (param.$queryParameters) {
      Object.keys(param.$queryParameters).forEach((parameterName) => {
        const parameter = param.$queryParameters[parameterName];
        queryParameters.set(parameterName, parameter);
      });
    }

    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      headers: header,
      data: body,
      url: requestUrl,
      ...parameters,
    };

    return executeRequest(requestOptions);
  },
};
export default designService;
