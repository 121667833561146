import React, { FC, useEffect, useState, useMemo, ReactNode } from "react";

import { useAppSelector } from "_redux/hooks";
import { loginStatusSelector } from "_redux/selectors/user";
import { makeSelectTopCategoryByChildCategoryId } from "_redux/selectors/catalog";
import { tss } from "tss-react/mui";
import { UnstyledLink, StyledTooltip, StyledBadge } from "components/StyledUI";
import { ROUTES } from "constants/routes";
import { BaseProduct } from "types/Product";
import { PRODUCT_IMAGE_HOST, CENTRAL_SERVISA_ID } from "constants/product";
import ConditionalWrapper from "components/helpers/ConditionalWrapper";
import { isMobileSafari, isSafari } from "react-device-detect";
import { useEFoodConfig } from "_foundationExt/hooks";

const imageExistsCache = new Map<string, Promise<boolean>>();

const existsImage = (imageUrl?: string | null) => {
  if (!imageUrl) {
    return Promise.resolve(false);
  }

  const cachedResult = imageExistsCache.get(imageUrl);
  if (cachedResult) {
    return cachedResult;
  }

  const resultPromise = new Promise<boolean>((resolve) => {
    const img = new Image();
    img.referrerPolicy = "same-origin";
    img.addEventListener("load", () => {
      resolve(true);
    });
    img.addEventListener("error", () => {
      resolve(false);
    });
    img.addEventListener("abort", () => {
      imageExistsCache.delete(imageUrl);
      resolve(false);
    });
    img.src = imageUrl;
  });
  imageExistsCache.set(imageUrl, resultPromise);

  return resultPromise;
};

const useStyles = tss
  .withParams<{
    centered: boolean;
    dummySize?: string;
    imageMaxSize?: number;
    size?: string;
  }>()
  .create(({ centered, dummySize, imageMaxSize, size }) => ({
    image: {
      margin: centered ? "0 auto" : "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "start",
      height: `${size}px`,
      width: `${size}px`,
      borderRadius: size && parseInt(size, 10) > 68 ? 8 : 4,
      "& img": {
        maxHeight: imageMaxSize ? `${imageMaxSize}px` : "100%",
        maxWidth: "100%",
        textIndent: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
    },
    minWidth: {
      minWidth: 300,
    },
    tooltipImage: {
      margin: 0,
      "& img": {
        maxHeight: "250px",
        maxWidth: "300px",
      },
    },
    setImageSize: {
      "& img": {
        height: dummySize ? `${dummySize}px` : `${size}px`,
        width: dummySize ? `${dummySize}px` : `${size}px`,
      },
    },
  }));

interface ProductImageProps {
  product?: BaseProduct;
  forceImageMinWidth?: boolean;
  size?: string;
  dummySize?: string;
  imageMaxSize?: number;
  centered?: boolean;
  enableTooltip?: boolean;
  isLink?: boolean;
  badgeText?: string | ReactNode;
  className?: string;
}

const ProductImage: FC<ProductImageProps> = ({
  product,
  size,
  imageMaxSize,
  forceImageMinWidth = false,
  centered = false,
  enableTooltip = false,
  isLink = true,
  badgeText,
  className,
  dummySize,
}) => {
  const { classes, cx } = useStyles({
    size,
    imageMaxSize,
    centered,
    dummySize,
  });

  const efood = useEFoodConfig();
  const loggedIn = useAppSelector(loginStatusSelector);
  const enableLink = loggedIn
    ? efood.productdetailCustomer || false
    : efood.productdetailGuest || false;

  const selectTopCategoryByChildCategoryId = useMemo(
    () => makeSelectTopCategoryByChildCategoryId(product?.parentCatalogGroupID),
    [product?.parentCatalogGroupID]
  );

  const topCategory = useAppSelector(selectTopCategoryByChildCategoryId);

  const [imageSrc, setImageSrc] = useState<string | null>();

  useEffect(() => {
    if (!product) {
      return () => {
        // do nothing
      };
    }

    // avoid warning "Can't perform a React state update on an unmounted component.""
    let isMounted = true;
    const resolveImageSrc = async () => {
      const shopSrc = `${PRODUCT_IMAGE_HOST}/${efood.servisaID}/${product.eFoodPartnumber}.jpg`;
      if (isMounted && (await existsImage(shopSrc))) {
        setImageSrc(shopSrc);
        return;
      }

      const fallbackSrc = `${PRODUCT_IMAGE_HOST}/${CENTRAL_SERVISA_ID}/${product.eFoodPartnumber}.jpg`;
      if (isMounted && (await existsImage(fallbackSrc))) {
        setImageSrc(fallbackSrc);
        return;
      }

      const dummySrc = topCategory?.fullImage || null;
      if (isMounted && dummySrc != null && (await existsImage(dummySrc))) {
        setImageSrc(dummySrc);
      }
    };

    resolveImageSrc();
    return () => {
      isMounted = false;
    };
  }, [product, efood, topCategory?.fullImage]);

  if (!product) {
    return null;
  }

  return (
    <StyledBadge
      badgeContent={badgeText}
      color="primary"
      invisible={!badgeText}
      large>
      <ConditionalWrapper
        condition={enableTooltip && imageSrc != null}
        renderWrapper={(children) => (
          <StyledTooltip
            placement="right"
            enterDelay={1000}
            autoWidth
            title={
              <figure className={cx(classes.tooltipImage, className)}>
                <img src={imageSrc ?? undefined} alt={product.name} />
              </figure>
            }>
            <>{children}</>
          </StyledTooltip>
        )}>
        <ConditionalWrapper
          condition={isLink && enableLink}
          renderWrapper={(children) => (
            <UnstyledLink
              to={`${ROUTES.PRODUCT_DETAIL}/${product.eFoodPartnumber}`}>
              {children}
            </UnstyledLink>
          )}>
          {!imageSrc ? (
            <div className={classes.image} />
          ) : (
            <figure
              className={cx(
                classes.image,
                {
                  [classes.minWidth]: !imageSrc && forceImageMinWidth,
                  [classes.setImageSize]:
                    !imageSrc && (isSafari || isMobileSafari),
                },
                className
              )}>
              <img src={imageSrc} alt={product.name} />
            </figure>
          )}
        </ConditionalWrapper>
      </ConditionalWrapper>
    </StyledBadge>
  );
};

export default ProductImage;
