// react
import React, { useState, FC, useLayoutEffect, MouseEventHandler } from "react";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// redux
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import { RESET_ERROR_ACTION } from "_redux/actions/error";
import { LOGIN_REQUESTED_ACTION } from "_redux/actions/user";
import {
  authenticationErrorSelector,
  genericErrorSelector,
  passwordExpiredErrorSelector,
} from "_redux/selectors/error";
import { sessionStorageUtil } from "_foundationExt/utils/storageUtil";
// mui
import {
  Typography,
  InputAdornment,
  IconButton,
  Box,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import {
  StyledButton,
  StyledPopper,
  StyledIcon,
  StyledLink,
  StyledFormInput,
  StyledNotification,
  StyledProgress,
  StyledTooltip,
  StyledFormCheckbox,
} from "components/StyledUI";
// components
import { Controller, useForm } from "react-hook-form";

import { ROUTES } from "constants/routes";
import {
  FINDER_EXCEPTION_ERROR_KEY,
  PERSON_HAS_NO_ROLES_IN_STORE,
} from "constants/errors";

import { ReactComponent as ProfileIcon } from "assets/icons/people-man-8.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as VisibleIcon } from "assets/icons/view-1.svg";
import { ReactComponent as UnvisibleIcon } from "assets/icons/view-off.svg";
import { mapRefToInputRef } from "tools/convertUtils";
import getDisplayName from "tools/getDisplayName";
import {
  useAbortControllers,
  useEFoodConfig,
  useIsAutoLoginAllowed,
} from "_foundationExt/hooks";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  button: {
    backgroundColor: "transparent !important",
    padding: "4px 10px",
    fontSize: 14,
    textTransform: "none",
    letterSpacing: 0.5,
    "& svg": {
      marginRight: theme.spacing(2),
      pointerEvents: "none",
    },
    "&:focus": {
      color: theme.palette.primary.dark,
    },
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
  content: {
    maxWidth: "370px",
    maxHeight: "600px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "310px",
    },
  },
  spacing: {
    marginBottom: theme.spacing(2),
  },
}));

const formDefaultValues = {
  logonId: "",
  logonPassword: "",
  rememberMe: false,
};

const LogonBox: FC = () => {
  const widgetName = getDisplayName(LogonBox);
  const { classes } = useStyles();
  const theme = useTheme();
  const breakpointUpMd = useMediaQuery(theme.breakpoints.up("md"));

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { registrationtype } = useEFoodConfig();
  const isAutoLoginAllowed = useIsAutoLoginAllowed();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [loading, setLoading] = useState(false);
  const [cookieError, setCookieError] = useState<boolean>(false);
  const abortControllers = useAbortControllers();

  const {
    register,
    handleSubmit,
    trigger,
    // subscribe to dirtyFields is needed to be able to use keepDirtyValues = true during form rest
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { dirtyFields, errors, isDirty, isValid, touchedFields },
    reset,
    control,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: { ...formDefaultValues, rememberMe: isAutoLoginAllowed },
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const authenticationError = useAppSelector(authenticationErrorSelector);
  const passwordExpiredError = useAppSelector(passwordExpiredErrorSelector);
  const genericError = useAppSelector(genericErrorSelector);
  const error = authenticationError || genericError;
  const isUserRegisteredInDifferentStore =
    authenticationError?.errorKey === PERSON_HAS_NO_ROLES_IN_STORE;

  useLayoutEffect(() => {
    reset(
      {
        ...formDefaultValues,
        rememberMe: isAutoLoginAllowed,
      },
      { keepDirtyValues: true }
    );
  }, [reset, isAutoLoginAllowed]);

  useLayoutEffect(() => {
    if (loading && error) {
      setLoading(false);
    }
  }, [loading, error]);

  useLayoutEffect(() => {
    if (
      genericError?.errorKey === FINDER_EXCEPTION_ERROR_KEY &&
      genericError.errorParameters === "javax.persistence.NoResultException"
    ) {
      setCookieError(true);
    }
  }, [genericError, t]);

  const handlePopperClose = () => {
    // abort possible requests when component gets closed
    abortControllers.abortAll();
    setLoading(false);
    setCookieError(false);
    // reset form values to defaults
    reset();
    setAnchorEl(undefined);
  };

  const handlePopperToggle: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (anchorEl == null) {
      setAnchorEl(event.currentTarget);
    } else {
      handlePopperClose();
    }
  };

  const onSubmit = handleSubmit((controlledForm) => {
    dispatch(RESET_ERROR_ACTION());
    setLoading(true);
    setCookieError(false);

    const { signal } = abortControllers.create();

    const requestPayload = {
      body: {
        ...controlledForm,
      },
      query: { rememberMe: controlledForm.rememberMe },
      widget: widgetName,
      signal,
    };

    dispatch(
      LOGIN_REQUESTED_ACTION({
        requestPayload,
        navigate,
        pathname,
      })
    );
  });

  const tooltipViewIcon = t("iconTooltips.viewIcon");

  if (passwordExpiredError) {
    if (pathname !== ROUTES.EXPIRED_PASSWORD) {
      const form = getValues();
      sessionStorageUtil.set("u", form.logonId);
      sessionStorageUtil.set("p", form.logonPassword);
      return <Navigate to={ROUTES.EXPIRED_PASSWORD} replace />;
    }
    return null;
  }

  return (
    <>
      <StyledButton
        className={classes.button}
        aria-describedby="login-flyout"
        onClick={handlePopperToggle}>
        <StyledIcon size="40">
          <ProfileIcon />
        </StyledIcon>
        {t("header.logon")}
      </StyledButton>

      <StyledPopper
        id="login-flyout"
        zIndex={theme.zIndex.extension.loginFlyoutPopper}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handlePopperClose}
        placement="bottom-end">
        <StyledButton
          className="close"
          onClick={handlePopperClose}
          startIcon={<CloseIcon />}
          size="small"
          color="inherit"
          tooltip={t("iconTooltips.closeIcon")}
        />

        <Grid container className={classes.content}>
          <Grid xs={12}>
            <Box mb={3}>
              <Typography variant={breakpointUpMd ? "h2" : "h3"} component="p">
                {t("header.login")}
              </Typography>
            </Box>
          </Grid>

          {!!error && (
            <>
              {isUserRegisteredInDifferentStore && error.errorParameters ? (
                <Box mb={3}>
                  <StyledNotification severity="info">
                    {t("logon.wrongShop")}{" "}
                    <StyledLink to={error.errorParameters}>
                      {t("logon.wrongShopLink")}
                    </StyledLink>
                  </StyledNotification>
                </Box>
              ) : (
                <Box mb={3}>
                  <StyledNotification severity="error">
                    {cookieError
                      ? t("logon.cookieProblem")
                      : error.errorMessage}
                  </StyledNotification>
                </Box>
              )}
            </>
          )}

          <form
            name="LoginForm"
            id="LoginForm"
            onSubmit={onSubmit}
            autoComplete="off">
            <StyledFormInput
              className={classes.spacing}
              id="logonId"
              label={t("header.username")}
              placeholder={`${t("header.username")}*`}
              fullWidth
              autoFocus
              {...mapRefToInputRef(
                register("logonId", {
                  required: `${t("form.error.required")}`,
                  onChange: (e) => {
                    setValue("logonId", e.target.value);
                    return touchedFields.logonId && trigger(["logonId"]);
                  },
                  onBlur: () => trigger(["logonId"]),
                })
              )}
              error={!!errors.logonId}
              helperText={errors?.logonId?.message?.toString()}
            />

            <StyledFormInput
              className={classes.spacing}
              id="logonPassword"
              label={t("header.password")}
              placeholder={`${t("header.password")}*`}
              type={showPassword ? "text" : "password"}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <StyledTooltip
                      title={tooltipViewIcon}
                      enterDelay={2500}
                      enterNextDelay={2500}>
                      <IconButton
                        tabIndex={-1}
                        edge="end"
                        style={showPassword ? { marginTop: "-2px" } : {}}
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        size="large">
                        {showPassword ? <UnvisibleIcon /> : <VisibleIcon />}
                      </IconButton>
                    </StyledTooltip>
                  </InputAdornment>
                ),
              }}
              {...mapRefToInputRef(
                register("logonPassword", {
                  required: `${t("form.error.required")}`,
                  onChange: (e) => {
                    setValue("logonPassword", e.target.value);
                    return (
                      touchedFields.logonPassword && trigger(["logonPassword"])
                    );
                  },
                  onBlur: () => trigger(["logonPassword"]),
                })
              )}
              error={!!errors.logonPassword}
              helperText={errors.logonPassword && errors.logonPassword.message}
            />

            <Box mb={0.5} textAlign="right">
              <StyledLink
                to={ROUTES.FORGOT_PASSWORD}
                onClick={handlePopperClose}
                small>
                {t("header.lostPassword")}
              </StyledLink>
            </Box>

            {isAutoLoginAllowed && (
              <Box mb={2}>
                <Controller
                  name="rememberMe"
                  control={control}
                  render={({ field: { value, ref, ...rest } }) => (
                    <StyledFormCheckbox
                      checkboxProps={{
                        checked: value,
                        value,
                        inputRef: ref,
                        ...rest,
                      }}
                      label={t("header.rememberMe")}
                    />
                  )}
                />
              </Box>
            )}

            <StyledButton
              fullWidth
              type="submit"
              disabled={loading || (touchedFields && !isDirty) || !isValid}>
              {loading ? (
                <StyledProgress size={24} color="inherit" />
              ) : (
                t("header.logon")
              )}
            </StyledButton>

            {registrationtype === "ALL" && (
              <StyledButton
                fullWidth
                variant="outlined"
                onClick={handlePopperClose}
                component={Link}
                to={ROUTES.REGISTER_TYPE}>
                {t("header.register")}
              </StyledButton>
            )}

            {registrationtype === "NEW" && (
              <StyledButton
                fullWidth
                variant="outlined"
                onClick={handlePopperClose}
                component={Link}
                to={ROUTES.REGISTER_NEW_CUSTOMER}>
                {t("header.register")}
              </StyledButton>
            )}
          </form>
        </Grid>
      </StyledPopper>
    </>
  );
};

export default LogonBox;
