import React, { FC, useMemo } from "react";
import Box from "@mui/material/Box";
import { useAppSelector } from "_redux/hooks";

import { loginStatusSelector } from "_redux/selectors/user";

import { formatCurrency } from "tools/stringUtils";
import { EFoodPrice, Matrix } from "types/Product";

import { tss } from "tss-react/mui";
import { DiscountEntry } from "@efood-commerce-sdk/typescript-axios-transaction-efood";
import { StyledProtectable } from "../StyledProtectable";

const useStyles = tss.create(({ theme }) => ({
  root: {
    fontSize: theme.typography.body2.fontSize,
  },
  highlight: {
    fontWeight: "bold",
    "& s": {
      fontWeight: "normal",
    },
  },
  fullWidth: {
    width: "100%",
  },
  noPaddingTop: {
    paddingTop: "0px",
    verticalAlign: "top",
  },
  noPaddingLeft: {
    paddingLeft: "0px",
  },
  wordBreak: {
    wordBreak: "break-word",
  },
  itemLeft: {
    paddingLeft: "8px",
    paddingRight: "0px",
  },
  itemRight: {
    paddingLeft: "0px",
    paddingRight: "16px",
  },
}));

/**
 * Merges the matrix and prices data via a lookup from the prices data into matrix data.
 * This only happens if the user is logged in.
 * If the user isn't logged in only the matrix data will be used.
 */
const mergeData = (
  matrix: Matrix[],
  loggedIn: boolean,
  prices?: EFoodPrice[]
) => {
  // User is logged in and therefore the prices data is available
  if (prices && loggedIn) {
    return prices
      .map((priceData) => {
        const correspondingMatrixData = matrix.find(
          (matrixData) =>
            matrixData.shortDesc === priceData.matrixShortDescription
        );

        if (!correspondingMatrixData) {
          return null;
        }

        return {
          ...priceData,
          longDesc: correspondingMatrixData.longDesc,
          handlingCode: correspondingMatrixData.handlingCode,
          position: 0,
        };
      })
      .filter(Boolean) as MergedData[];
  }

  // User is not logged in. There is only the matrix data
  const unsortedMatrix = matrix
    .map((matrixData) => {
      if (
        matrixData.handlingCode === "1" ||
        Math.abs(parseInt(matrixData.handlingCode, 10)) === 2
      ) {
        return {
          longDesc: matrixData.longDesc,
          handlingCode: Math.abs(
            parseInt(matrixData.handlingCode, 10)
          ).toString(),
          matrixShortDescription: matrixData.shortDesc,
          currencySymbol: "",
          position: parseInt(matrixData.position, 10),
        };
      }

      return null;
    })
    .filter(Boolean) as MergedData[];

  // Item with handlingCode 1 will be sorted to the first position in the array
  const sortedMatrix = unsortedMatrix.sort(
    (a, b) => parseInt(a.handlingCode, 10) - parseInt(b.handlingCode, 10)
  );

  // If there are more than one item with handling code 2.
  // Then only the item with the lowest position value should be shown and the item with handling code 1.
  if (sortedMatrix.length > 2) {
    return sortedMatrix
      .sort((a, b) => {
        if (a.handlingCode === "2" && b.handlingCode === "2") {
          return a.position - b.position;
        }

        return 0;
      })
      .slice(0, 2);
  }

  return sortedMatrix;
};

interface MergedData {
  longDesc: string;
  handlingCode: string;
  price?: number;
  currencySymbol: string;
  matrixShortDescription: string;
  position: number;
  discountPrice?: number;
}

interface StyledPriceTableProps {
  matrix: Matrix[];
  prices?: EFoodPrice[];
  discountPrices?: DiscountEntry[];
  fullWidth?: boolean;
  noPaddingTop?: boolean;
  noPaddingLeft?: boolean;
  paddingX?: boolean;
}

const StyledPriceTable: FC<StyledPriceTableProps> = ({
  matrix,
  discountPrices,
  prices,
  fullWidth,
  noPaddingTop,
  noPaddingLeft,
  paddingX,
}) => {
  const { classes, cx } = useStyles();
  const loggedIn = useAppSelector(loginStatusSelector);
  const discountPrice = discountPrices?.[0]?.discountPrice ?? undefined;

  const mergedData = useMemo(() => {
    const newMergedData = mergeData(matrix, loggedIn, prices);
    if (discountPrice == null || !newMergedData.length) {
      return newMergedData;
    }

    // find handligCode 1, calculate discount
    const standardVke = newMergedData.find((d) => d.handlingCode === "1");
    if (standardVke == null || standardVke.price == null) {
      return newMergedData;
    }

    standardVke.discountPrice = discountPrice;
    if (newMergedData.length === 1) {
      return newMergedData;
    }

    // apply discount in percent to other elements
    const discount = discountPrice / standardVke.price;
    newMergedData
      .filter((d) => d.handlingCode !== "1")
      .filter((d) => d.price != null)
      .forEach((d) => {
        // eslint-disable-next-line no-param-reassign
        d.discountPrice = Math.round(d.price! * discount * 100) / 100;
      });

    return newMergedData;
  }, [loggedIn, matrix, prices, discountPrice]);

  if (!mergedData) return null;

  return (
    <table className={cx({ [classes.fullWidth]: fullWidth })}>
      <tbody>
        {mergedData.map((dataItem) => {
          const highlight = dataItem.handlingCode === "1";

          return (
            <tr className={classes.root} key={dataItem.matrixShortDescription}>
              <td
                className={cx({
                  [classes.highlight]: highlight,
                  [classes.noPaddingTop]: noPaddingTop,
                  [classes.noPaddingLeft]: noPaddingLeft,
                  [classes.wordBreak]: true,
                  [classes.itemLeft]: paddingX,
                })}>
                {dataItem?.longDesc}
              </td>
              {dataItem.price != null && (
                <td
                  className={cx({
                    [classes.highlight]: highlight,
                    [classes.noPaddingTop]: noPaddingTop,
                    [classes.noPaddingLeft]: noPaddingLeft,
                    [classes.itemRight]: paddingX,
                  })}>
                  <Box ml={2} textAlign="right">
                    <StyledProtectable permission="show.price">
                      {dataItem.discountPrice != null ? (
                        <>
                          <s>{formatCurrency(dataItem.price)}</s>
                          <br />
                          <>{formatCurrency(dataItem.discountPrice)}</>
                        </>
                      ) : (
                        formatCurrency(dataItem.price)
                      )}
                    </StyledProtectable>
                  </Box>
                </td>
              )}
              {/* <td>{dataItem.matrixShortDescription}</td> */}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default StyledPriceTable;
