import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { ContentProps } from "components/dialog/types";
import type { SyncCartDataParams } from "./SyncCartData";

const SyncCartDataTitle: FC<ContentProps<SyncCartDataParams>> = () => {
  const { t } = useTranslation();

  return (
    <Typography variant="h3">{t("offline.sync.headline.cart")}</Typography>
  );
};

export default SyncCartDataTitle;
