/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from "react-i18next";

import { useSite } from "_foundation/hooks/useSite";
import { RESET_ERROR_ACTION } from "_redux/actions/error";
import { genericErrorSelector } from "_redux/selectors/error";
import { useAppDispatch, useAppSelector } from "_redux/hooks";

const useErrorMessage = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { currentSite } = useSite();
  const error: any = useAppSelector(genericErrorSelector);

  const errorKeys: string[] = [];
  let errorKey = error.errorKey || "";
  const { errorCode } = error;
  let { errorParameters } = error;
  let errorParameterKey: string | undefined;

  if (typeof errorParameters === "string") {
    errorParameters = errorParameters.split(",");
  }
  if (!errorParameters) {
    errorParameters = [];
  }

  if (errorCode && errorCode !== errorKey) {
    errorKey = `${errorKey}_${errorCode}`;
  }

  if (errorParameters.length > 0) {
    // user first item in parameters as part of key
    errorParameterKey = `${errorKey}_${errorParameters[0]}`;
  }
  if (currentSite?.isB2B) {
    // get b2b key
    const errorKeyB2B = `error-message.B2B.${errorKey}`;
    if (errorParameterKey) {
      const errorParameterKeyB2B = `error-message.B2B.${errorParameterKey}`;
      if (i18n.exists(errorParameterKeyB2B)) {
        errorKeys.push(errorParameterKeyB2B);
      }
    }
    if (i18n.exists(errorKeyB2B)) {
      errorKeys.push(errorKeyB2B);
    }
  }
  if (errorParameterKey) {
    const eerrorParameterKey = `error-message.${errorParameterKey}`;
    if (i18n.exists(eerrorParameterKey)) {
      errorKeys.push(eerrorParameterKey);
    }
  }
  if (i18n.exists(`error-message.${errorKey}`)) {
    errorKeys.push(`error-message.${errorKey}`);
  }

  let errorMessage = error.errorMessage || error.message;
  if (errorKeys.length > 0) {
    errorMessage = t(errorKeys, { ...errorParameters });
  }

  const handleClose = () => {
    dispatch(RESET_ERROR_ACTION());
  };

  return { handleClose, errorMessage };
};

export { useErrorMessage };
