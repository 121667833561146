import { BreakpointsOptions } from "@mui/material/styles";

const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 640,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};

export { breakpoints };
