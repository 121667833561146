/* tslint:disable */
/* eslint-disable */
/**
 * EFood Storefront API
 * This is the EFood Storefront API specification.  Operations are grouped by tags.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { OrderConfirmationResponseData } from '../../../../com/telekom-mms/efood/api/model';
/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves order information for orderId.
         * @param {string} storeId The store identifier
         * @param {string} orderId The order identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByOrderId: async (storeId: string, orderId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('findByOrderId', 'storeId', storeId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('findByOrderId', 'orderId', orderId)
            const localVarPath = `/store/{storeId}/efood/order/findByOrderId/{orderId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"orderId"}}`, String(orderId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves order information for orderId.
         * @param {string} storeId The store identifier
         * @param {string} orderId The order identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByOrderId(storeId: string, orderId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderConfirmationResponseData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByOrderId(storeId, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves order information for orderId.
         * @param {string} storeId The store identifier
         * @param {string} orderId The order identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByOrderId(storeId: string, orderId: string, options?: any): AxiosPromise<OrderConfirmationResponseData> {
            return localVarFp.findByOrderId(storeId, orderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves order information for orderId.
     * @param {string} storeId The store identifier
     * @param {string} orderId The order identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public findByOrderId(storeId: string, orderId: string, options?: any) {
        return OrderApiFp(this.configuration).findByOrderId(storeId, orderId, options).then((request) => request(this.axios, this.basePath));
    }
}
