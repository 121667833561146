/* eslint-disable react/jsx-props-no-spreading */

import React, { FC } from "react";
import { DialogActionsProps } from "@mui/material/";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  root: {
    margin: theme.spacing(3),
  },
}));

const StyledDialogActions: FC<DialogActionsProps> = (props) => {
  const { classes } = useStyles();
  const { children } = props;

  return <div className={classes.root}>{children}</div>;
};

export default StyledDialogActions;
