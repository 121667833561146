import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "_redux/hooks";
import { useMatomo, SessionUtils, typeList } from "components/matomo";
import { userPersonalizationIdSelector } from "_redux/selectors/user";

const reverseMapping = (o) =>
  Object.keys(o).reduce(
    (r, k) => Object.assign(r, { [o[k]]: (r[o[k]] || []).concat(k) }),
    {}
  );

const useTrackPredictionOnCart = () => {
  const persinalizationID = useAppSelector(userPersonalizationIdSelector);
  const inCart = SessionUtils.getSessionMap("inCart");
  const matomo = useMatomo();
  const { t } = useTranslation();

  const [execute, setExecute] = useState(false);
  const trackPrediction = useCallback(() => setExecute(true), []);

  useEffect(() => {
    if (execute && matomo && persinalizationID) {
      if (inCart) {
        const cart = reverseMapping(inCart);

        Object.keys(cart).forEach((key) => {
          const shown = SessionUtils.getSession(key);
          const shownLenght = shown.length || 0;
          const buy = cart[key];
          const buyLength = buy.length || 0;

          const percent = (100 / shownLenght) * buyLength;
          const rounded = Math.ceil(percent / 10) * 10;

          matomo.trackEvent({
            category: `${t(`prediction.event.type.${key}`)}: ${t(
              "prediction.event.category"
            )}`,
            action: `${t(`prediction.event.type.${key}`)}: ${t(
              "prediction.event.action.buy"
            )}`,
            name: `${t(
              `prediction.event.percent.${
                Number.isFinite(rounded) ? rounded : 0
              }`
            )}`,
          });

          SessionUtils.deleteSession(key);
        });

        SessionUtils.deleteSession("inCart");
      }

      typeList.forEach((key) => {
        const shown = SessionUtils.getSession(key);

        if (shown.length > 0) {
          matomo.trackEvent({
            category: `${t(`prediction.event.type.${key}`)}: ${t(
              "prediction.event.category"
            )}`,
            action: `${t(`prediction.event.type.${key}`)}: ${t(
              "prediction.event.action.buy"
            )}`,
            name: `${t(`prediction.event.percent.${0}`)}`,
          });
          SessionUtils.deleteSession(key);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, persinalizationID]);

  return useMemo(() => ({ trackPrediction }), [trackPrediction]);
};

export default useTrackPredictionOnCart;
