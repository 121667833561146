import { useRef } from "react";

const usePrevious = <T>(
  value: T,
  isEqualFunc?: (nextValue: T, currentValue: T) => boolean
) => {
  const ref = useRef({
    value,
    prev: undefined,
  } as {
    value: T;
    prev?: T;
  });

  const current = ref.current.value;

  if (isEqualFunc ? !isEqualFunc(value, current) : value !== current) {
    ref.current = {
      value,
      prev: current,
    };
  }

  return ref.current.prev;
};

export { usePrevious };
