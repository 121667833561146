import React, { FC } from "react";
import { Skeleton } from "@mui/material";
import { tss } from "tss-react/mui";

const useStyles = tss.create(() => ({
  skeletonWrapper: {
    display: "flex",
    gap: "20px",
    flexDirection: "column",
  },
  skeletonInnerWrapper: {
    display: "flex",
  },
  skeletonLeft: {
    width: "70%",
    margin: "0 20px",
  },
  skeletonRight: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingLeft: "20%",
    alignItems: "flex-end",
  },
}));

const StyledSkeletonTable: FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.skeletonWrapper}>
      <div className={classes.skeletonInnerWrapper}>
        <div>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={80}
            height={80}
          />
        </div>
        <div className={classes.skeletonLeft}>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" width="50%" />
        </div>
        <div className={classes.skeletonRight}>
          <Skeleton animation="wave" width="100%" />
          <Skeleton animation="wave" width="25%" />
          <Skeleton animation="wave" width="70%" />
        </div>
      </div>
      <div className={classes.skeletonInnerWrapper}>
        <div>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={80}
            height={80}
          />
        </div>
        <div className={classes.skeletonLeft}>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" width="50%" />
        </div>
        <div className={classes.skeletonRight}>
          <Skeleton animation="wave" width="100%" />
          <Skeleton animation="wave" width="25%" />
          <Skeleton animation="wave" width="70%" />
        </div>
      </div>
      <div className={classes.skeletonInnerWrapper}>
        <div>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={80}
            height={80}
          />
        </div>
        <div className={classes.skeletonLeft}>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" width="50%" />
        </div>
        <div className={classes.skeletonRight}>
          <Skeleton animation="wave" width="100%" />
          <Skeleton animation="wave" width="25%" />
          <Skeleton animation="wave" width="70%" />
        </div>
      </div>
    </div>
  );
};

export { StyledSkeletonTable };
