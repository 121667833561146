import React from "react";
import usePWADisplayMode from "pwa/displayMode/usePWADisplayMode";
import SimpleOnlineStatusChecker from "./SimpleOnlineStatusChecker";
import DefaultOnlineStatusChecker from "./DefaultOnlineStatusChecker";

const OnlineStatusChecker: React.FC<{ cache?: RequestCache }> = ({ cache }) => {
  const { isBrowser } = usePWADisplayMode();

  if (isBrowser) {
    return <SimpleOnlineStatusChecker />;
  }
  return <DefaultOnlineStatusChecker cache={cache} />;
};

export default OnlineStatusChecker;
