import React, { PropsWithChildren, useCallback } from "react";
import Popper, { type PopperProps } from "@mui/material/Popper";
import { ClickAwayListener } from "@mui/material";
import { tss } from "tss-react/mui";

const useStyles = tss
  .withParams<{ zIndex?: number }>()
  .create(({ theme, zIndex }) => ({
    popper: {
      zIndex,
      marginTop: `${theme.spacing(2)} !important`,
      boxShadow: "0px 8px 48px rgba(32,35,28,0.2)",
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.background.default,
      position: "fixed",
      "& .close": {
        position: "absolute",
        top: theme.spacing(3),
        right: theme.spacing(3),
      },
    },
    spacing: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(4)}`,
    },
  }));

type StyledPopperProps = PopperProps &
  PropsWithChildren & {
    zIndex?: number;
    onClose?: () => void;
  };

const StyledPopper = React.forwardRef<HTMLDivElement, StyledPopperProps>(
  (props, ref) => {
    const {
      children,
      zIndex,
      className,
      placement,
      onClose,
      popperOptions,
      ...restProps
    } = props;
    const { classes, cx } = useStyles({ zIndex });

    const onClickAwayAction = useCallback(
      (event) => {
        // https://github.com/mui/material-ui/issues/25578
        // The click event couldn't find its target, ignore
        if (event.target.nodeName === "BODY" && event.type === "click") {
          return;
        }
        if (onClose) {
          onClose();
        }
      },
      [onClose]
    );

    if (!props.open) {
      return null;
    }

    return (
      <ClickAwayListener onClickAway={onClickAwayAction}>
        <Popper
          ref={ref}
          placement={placement || "bottom"}
          className={cx(classes.popper, className)}
          popperOptions={{
            strategy: "fixed",
            ...popperOptions,
          }}
          {...restProps}>
          <div className={classes.spacing}>{children}</div>
        </Popper>
      </ClickAwayListener>
    );
  }
);

export { StyledPopper };
