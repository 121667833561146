import {
  BooleanCameraCapability,
  Html5QrcodeScannerStrings,
  OnTorchActionFailureCallback,
} from "@efood-commerce-sdk/html5-qrcode";

/** Controller class for handling torch / flash. */
export class BcTorchController {
  private readonly torchCapability: BooleanCameraCapability;

  private readonly onTorchActionFailureCallback:
    | OnTorchActionFailureCallback
    | undefined;

  // Mutable states.
  private isTorchOn = false;

  constructor(
    torchCapability: BooleanCameraCapability,
    onTorchActionFailureCallback: OnTorchActionFailureCallback | undefined
  ) {
    this.torchCapability = torchCapability;
    this.onTorchActionFailureCallback = onTorchActionFailureCallback;
  }

  /** Returns {@code true} if torch is enabled. */
  public isTorchEnabled(): boolean {
    return this.isTorchOn;
  }

  /**
   * Flips the state of the torch.
   *
   * <p> Turns torch On if current state is Off and vice-versa.
   * <p> Modifies the UI state accordingly.
   *
   * @returns Promise that finishes when the async action is done.
   */
  public async flipState(): Promise<void> {
    const isTorchOnExpected = !this.isTorchOn;
    try {
      await this.torchCapability.apply(isTorchOnExpected);
      const torchCapabilityValue = this.torchCapability.value();
      let torchCapability = false;
      if (torchCapabilityValue != null) {
        torchCapability = torchCapabilityValue;
      }
      this.updateUiBasedOnLatestSettings(torchCapability, isTorchOnExpected);
    } catch (error) {
      this.propagateFailure(isTorchOnExpected, error);
    }
  }

  private updateUiBasedOnLatestSettings(
    isTorchOn: boolean,
    isTorchOnExpected: boolean
  ) {
    if (isTorchOn === isTorchOnExpected) {
      this.isTorchOn = isTorchOnExpected;
    } else {
      // Torch didn't get set as expected.
      // Show warning.
      this.propagateFailure(isTorchOnExpected);
    }
  }

  private propagateFailure(isTorchOnExpected: boolean, error?: unknown) {
    let errorMessage = isTorchOnExpected
      ? Html5QrcodeScannerStrings.torchOnFailedMessage()
      : Html5QrcodeScannerStrings.torchOffFailedMessage();
    if (error) {
      errorMessage += `; Error = ${error}`;
    }
    if (this.onTorchActionFailureCallback) {
      this.onTorchActionFailureCallback(errorMessage);
    }
  }

  /**
   * Resets the state.
   *
   * <p>Note: Doesn't turn off the torch implicitly.
   */
  public reset() {
    this.isTorchOn = false;
  }
}
