import { createAction } from "@reduxjs/toolkit";
import * as ACTIONTYPES from "../action-types/order";

export const ADD_ITEM_ACTION = createAction<any, string>(
  ACTIONTYPES.ITEM_ADD_REQUESTED
);

export const REMOVE_ITEM_ACTION = createAction<any, string>(
  ACTIONTYPES.ITEM_REMOVE_REQUESTED
);

export const UPDATE_ITEM_ACTION = createAction<any, string>(
  ACTIONTYPES.ITEM_UPDATE_REQUESTED
);

export const MODIFY_ITEM_ACTION = createAction<any, string>(
  ACTIONTYPES.ITEM_MODIFY_REQUESTED
);

export const ITEM_MODIFY_WARNING_ACTION = createAction<
  { payload: any; response: any },
  string
>(ACTIONTYPES.ITEM_MODIFY_WARNING);

export const ITEM_MODIFY_ERROR_ACTION = createAction<
  { error: any; article: any; partNumber: any },
  string
>(ACTIONTYPES.ITEM_MODIFY_ERROR);

export const CHECK_AVAILABILITY_ACTION = createAction<any, string>(
  ACTIONTYPES.ITEM_CHECK_AVAILABILITY_REQUESTED
);

export const FETCHING_CART_ACTION = createAction<any, string>(
  ACTIONTYPES.CART_FETCHING_REQUESTED
);

export const CART_PRECHECKOUT_ERROR_ACTION = createAction<any, string>(
  ACTIONTYPES.CART_PRECHECKOUT_ERROR
);

export const CART_CHECKOUT_ERROR_ACTION = createAction<any, string>(
  ACTIONTYPES.CART_CHECKOUT_ERROR
);

export const SET_ORDER_COMMENT_ACTION = createAction<any, string>(
  ACTIONTYPES.SET_ORDER_COMMENT_REQUESTED
);

export const RESET_CART_ACTION = createAction(ACTIONTYPES.CART_RESET_REQUESTED);
