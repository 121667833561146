/* eslint-disable indent */
// react
import React, { FC } from "react";
// redux
import { useAppDispatch } from "_redux/hooks";
import * as orderActions from "_redux/actions/order";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import { StyledButton } from "components/StyledUI";
// assets
import { ReactComponent as DeleteIcon } from "assets/icons/bin-1.svg";
import { tss } from "tss-react/mui";

const useStyles = tss.create(() => ({
  container: {
    textAlign: "right",
  },
}));

interface RemoveOrderItemProps {
  orderItemId: string;
}

const RemoveOrderItem: FC<RemoveOrderItemProps> = ({ orderItemId }) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();

  const onClickDelete = () => {
    const payload = {
      body: {
        orderItemId,
      },
    };
    dispatch(orderActions.REMOVE_ITEM_ACTION(payload));
  };

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid xs>
          <StyledButton
            color="secondary"
            startIcon={<DeleteIcon />}
            size="small"
            onClick={onClickDelete}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default RemoveOrderItem;
