import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import store from "_redux/store";
import { EnvironmentContextProvider } from "components/helpers/environment-context";
import { handleInstallPrompt, registerWithWorkbox } from "pwa";
import App from "App";
import MaterialUiThemeProvider from "./theme/ThemeProvider";
import { StyledProgress } from "./components/StyledUI";
import "./i18n";

const container = document.getElementById("sbec")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MaterialUiThemeProvider>
        <EnvironmentContextProvider>
          <Suspense fallback={<StyledProgress />}>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </Suspense>
        </EnvironmentContextProvider>
      </MaterialUiThemeProvider>
    </Provider>
  </React.StrictMode>
);

// ServiceWorker
registerWithWorkbox({ store });
handleInstallPrompt();
