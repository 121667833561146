// react
import React, { FC, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
// redux
import { useAppDispatch } from "_redux/hooks";
import * as orderActions from "_redux/actions/order";
// mui
import { Box, Typography } from "@mui/material";
import {
  StyledButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "components/StyledUI";
// components
import { ReactComponent as BinIcon } from "assets/icons/bin-1.svg";
import SvgIcon from "@mui/material/SvgIcon";
import cartServiceExt from "_foundationExt/apis/transaction/cart.service";
import { RESET_ERROR_ACTION } from "_redux/actions/error";
import { useOnlineStatus } from "_foundationExt/hooks/useOnlineStatus";
import { useSite } from "_foundationExt/hooks";

const CartRemoveButton: FC = () => {
  const { currentSite } = useSite();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { isOffline } = useOnlineStatus();

  const showDialog = () => setOpen(true);

  const closeDialog = () => setOpen(false);

  const removeAllProductsInCart = useCallback(() => {
    if (currentSite?.storeID) {
      cartServiceExt.emptyCart({ storeId: currentSite.storeID }).then(() => {
        dispatch(RESET_ERROR_ACTION());
        dispatch(orderActions.FETCHING_CART_ACTION({}));
        closeDialog();
      });
    }
  }, [currentSite?.storeID, dispatch]);

  return (
    <>
      <StyledButton
        startIcon={<SvgIcon component={BinIcon} title={t("cart.deleteCart")} />}
        color="secondary"
        onClick={showDialog}
        disabled={isOffline}
      />
      <StyledDialog open={open} onClose={closeDialog}>
        <StyledDialogTitle id="dialog-title" onClose={closeDialog}>
          <Box style={{ maxWidth: "275px" }}>
            <Typography variant="h2" component="p">
              {t("cart.deleteCartHint")}
            </Typography>
          </Box>
        </StyledDialogTitle>

        <StyledDialogContent>
          <Box mt={2}>
            <StyledButton fullWidth onClick={removeAllProductsInCart}>
              {t("action.delete")}
            </StyledButton>
          </Box>
          <Box mt={2}>
            <StyledButton fullWidth variant="outlined" onClick={closeDialog}>
              {t("action.abort")}
            </StyledButton>
          </Box>
        </StyledDialogContent>
      </StyledDialog>
    </>
  );
};

export default CartRemoveButton;
