import React, { FC } from "react";

import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  waveDivider: {
    backgroundColor: theme.palette.grey.A200,
    fill: theme.palette.grey.A100,
    height: "48px",
  },
  svg: {
    width: "100%",
  },
}));

const WaveDivider: FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.waveDivider}>
      <svg
        version="1.2"
        xmlns="http://www.w3.org/2000/svg"
        overflow="visible"
        preserveAspectRatio="none"
        viewBox="0 0 1440 48"
        width="1440"
        height="48"
        className={classes.svg}>
        <g transform="translate(0, 0)">
          <defs>
            <path
              id="path-161165170429738"
              d="M4 339.90010263427996 C4 339.90010263427996 1444.0111420612811 339.9500513171399 1444.0111420612811 339.9500513171399 C1444.0111420612811 339.9500513171399 1444.0111420612811 316.3492986657545 1444.0111420612811 316.3492986657545 C1444.0111420612811 316.3492986657545 1373 277.73554567225494 1231 298.3178241532674 C1089 318.90010263427985 905.6785050707609 328.0396823910626 743 302.90010263427985 C580.3214949292391 277.76052287749707 452.9637883008359 340.41977420458426 291 310.90010263427985 C129.03621169916408 281.3804310639751 4.1860465116279055 302.746835443038 4.1860465116279055 302.746835443038 C4.1860465116279055 302.746835443038 4 339.90010263427996 4 339.90010263427996 Z"
              vectorEffect="non-scaling-stroke"
            />
          </defs>
          <g transform="translate(-4, -291.9500513171399)">
            <path
              d="M4 339.90010263427996 C4 339.90010263427996 1444.0111420612811 339.9500513171399 1444.0111420612811 339.9500513171399 C1444.0111420612811 339.9500513171399 1444.0111420612811 316.3492986657545 1444.0111420612811 316.3492986657545 C1444.0111420612811 316.3492986657545 1373 277.73554567225494 1231 298.3178241532674 C1089 318.90010263427985 905.6785050707609 328.0396823910626 743 302.90010263427985 C580.3214949292391 277.76052287749707 452.9637883008359 340.41977420458426 291 310.90010263427985 C129.03621169916408 281.3804310639751 4.1860465116279055 302.746835443038 4.1860465116279055 302.746835443038 C4.1860465116279055 302.746835443038 4 339.90010263427996 4 339.90010263427996 Z"
              vectorEffect="non-scaling-stroke"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default WaveDivider;
