import React from "react";
import { StyledNotification } from "components/StyledUI";
import { useOnlineStatus } from "_foundationExt/hooks/useOnlineStatus";
import { useTranslation } from "react-i18next";
import { tss } from "tss-react/mui";
import { useLiveQuery } from "dexie-react-hooks";
import { eFoodDB } from "pwa/db/efood.db";

const useStyles = tss.create(({ theme }) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}));

const OnlineStatusInformation: React.FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { isLoading, isOnline } = useOnlineStatus();
  const productsCount = useLiveQuery(() => eFoodDB.products.count(), [], 0);

  if (isLoading || isOnline) {
    return null;
  }

  const msgKey = !productsCount
    ? "offline.status.message_noData"
    : "offline.status.message";

  return (
    <StyledNotification severity="info" className={classes.root}>
      {t(msgKey)}
    </StyledNotification>
  );
};

export default OnlineStatusInformation;
