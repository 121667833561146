import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationDe from "locales/de/translation.json";

const DEFAULT_LANGUAGE = "de-DE";
const DEFAULT_NAMESPACE = "translation";

i18n.use(initReactI18next).init({
  load: "languageOnly",
  lng: DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  ns: [DEFAULT_NAMESPACE],
  defaultNS: DEFAULT_NAMESPACE,
  resources: {
    [DEFAULT_LANGUAGE]: {
      [DEFAULT_NAMESPACE]: translationDe,
    },
  },
  debug: false,
  interpolation: {
    /* 
        This setting is needed so that translation parameters
        (second param of the t function) do not get escaped
        Otherwise for example "&" would be escaped to "&amp;" 
       */
    escapeValue: false,
  },
});

export default i18n;
