import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import type { ContentProps } from "../types";
import type { DeliveryDateExpiredParams } from "../contents/DeliveryDateExpired";

const DeliveryDateExpiredTitle: FC<
  ContentProps<DeliveryDateExpiredParams>
> = () => {
  const { t } = useTranslation();

  return (
    <Typography variant="h3">
      {t("cart.dialog.deliveryDateExpired.headline")}
    </Typography>
  );
};

export default DeliveryDateExpiredTitle;
