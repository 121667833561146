import { CatalogGroupView } from "_redux/reducers";

const getCatalogGroupViewFromTopCategoriesById = (
  categoryViewTop: CatalogGroupView[] | undefined,
  catalogGroupViewId: string
): CatalogGroupView | null => {
  let result: CatalogGroupView | null = null;

  let stack: CatalogGroupView[] = [];
  let currentCatalogGroupView;
  let index;

  if (categoryViewTop && categoryViewTop.length > 0) {
    categoryViewTop.forEach((root) => {
      stack.push(root);

      while (stack.length > 0 && result === null) {
        currentCatalogGroupView = stack.pop();
        if (currentCatalogGroupView.uniqueID === catalogGroupViewId) {
          result = currentCatalogGroupView;
          stack = [];
          break;
        }
        if (
          currentCatalogGroupView.catalogGroupView &&
          currentCatalogGroupView.catalogGroupView.length
        ) {
          for (
            index = 0;
            index < currentCatalogGroupView.catalogGroupView.length;
            index += 1
          ) {
            stack.push(currentCatalogGroupView.catalogGroupView[index]);
          }
        }
      }
    });
  }

  return result;
};

export default getCatalogGroupViewFromTopCategoriesById;
