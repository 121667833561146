import { RootState, AppStore } from "_redux/store";
import { Workbox } from "workbox-window";
import { cloneDeep, isObject } from "lodash-es";
import { UPDATE_CONTEXT } from "./messageTypes";

export const getNextFallbackDeliveryDate = (optionalDate?: Date) => {
  const date = optionalDate ?? new Date();
  date.setHours(0, 0, 0, 0);
  const dayInWeek = date.getDay() || 7;
  date.setDate(date.getDate() + (dayInWeek > 4 ? 8 - dayInWeek : 1));
  return date;
};

const DEFAULT_PATHNAME_CONTEXT = {
  search: "/search/resources",
  transaction: "/wcs/resources",
  legacy: "/webapp/wcs/stores/servlet",
};
export type ContextPathnameType = typeof DEFAULT_PATHNAME_CONTEXT;

const DEFAULT_USER_CONTEXT = {
  currentDeliveryDate: getNextFallbackDeliveryDate(),
};
export type ContextUserType = typeof DEFAULT_USER_CONTEXT;

export const DEFAULT_CONTEXT = {
  pathname: DEFAULT_PATHNAME_CONTEXT,
  user: DEFAULT_USER_CONTEXT,
};
export type Context = typeof DEFAULT_CONTEXT;

export const isContext = (candidate: unknown): candidate is Context =>
  isObject(candidate) && "pathname" in candidate && "user" in candidate;

const handlePathname = (state: RootState, pathname: ContextPathnameType) => {
  const currentSite = state.site?.currentSite;
  if (!currentSite) {
    return;
  }

  const { searchContext, transactionContext, legacyContext } = currentSite;
  if (searchContext != null && searchContext !== pathname.search) {
    // eslint-disable-next-line no-param-reassign
    pathname.search = searchContext;
  }
  if (
    transactionContext != null &&
    transactionContext !== pathname.transaction
  ) {
    // eslint-disable-next-line no-param-reassign
    pathname.transaction = transactionContext;
  }
  if (legacyContext != null && legacyContext !== pathname.legacy) {
    // eslint-disable-next-line no-param-reassign
    pathname.legacy = legacyContext;
  }
};

const handleUser = (state: RootState, user: ContextUserType) => {
  const currentDeliveryDate = state.user?.currentDeliveryDate;
  if (!currentDeliveryDate) {
    return;
  }

  const { date } = currentDeliveryDate;
  if (date !== user.currentDeliveryDate) {
    // eslint-disable-next-line no-param-reassign
    user.currentDeliveryDate = date;
  }
};

const workBoxHolder: { wb?: Workbox } = {};

export async function messageSW(data: Record<string, unknown>) {
  return workBoxHolder.wb?.messageSW(data);
}

export const syncReduxStoreWithSW = (store: AppStore, wb: Workbox) => {
  workBoxHolder.wb = wb;
  const SyncContext: { current: Context } = {
    current: cloneDeep(DEFAULT_CONTEXT),
  };

  store.subscribe(() => {
    let syncNeeded = false;
    /* eslint-disable indent */
    const createSyncNeededProxyHandler = <T extends object>(
      _: T
    ): ProxyHandler<T> => ({
      set(...args) {
        syncNeeded = true;
        return Reflect.set(...args);
      },
    });
    /* eslint-enable indent */
    const context: Context = cloneDeep(SyncContext.current);
    const state = store.getState();

    handlePathname(
      state,
      new Proxy(
        context.pathname,
        createSyncNeededProxyHandler(context.pathname)
      )
    );
    handleUser(
      state,
      new Proxy(context.user, createSyncNeededProxyHandler(context.user))
    );

    if (syncNeeded) {
      SyncContext.current = context;
      wb.messageSW({
        type: UPDATE_CONTEXT,
        payload: context,
      });
    }
  });
};

export const ServiceWorkerContext: { current: Context } = {
  current: cloneDeep(DEFAULT_CONTEXT),
};
