import { Theme, ThemeOptions } from "@mui/material/styles";

import NunitoSansRegular from "../assets/fonts/NunitoSans/NunitoSans-Regular.ttf";
import NunitoSansBold from "../assets/fonts/NunitoSans/NunitoSans-Bold.ttf";
import ShadowsIntoLightRegular from "../assets/fonts/ShadowsIntoLight/ShadowsIntoLight-Regular.ttf";

const fontFace = [
  {
    fontFamily: "NunitoSans",
    src: `url('${NunitoSansRegular}') format('truetype')`,
  },
  {
    fontFamily: "NunitoSans",
    fontWeight: "bold",
    src: `url('${NunitoSansBold}') format('truetype')`,
  },
  {
    fontFamily: "ShadowsIntoLight",
    src: `url('${ShadowsIntoLightRegular}') format('truetype')`,
  },
];

export const getBaseToolTipStyle = (theme: Omit<Theme, "components">) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: "0px 4px 32px rgba(32,35,28,0.15)",
  color: theme.palette.text.primary,
  fontSize: 12,
  lineHeight: "14px",
  padding: "11px 8px",
});

const components: NonNullable<ThemeOptions["components"]> = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiCssBaseline: {
    styleOverrides: (theme) => ({
      "@font-face": fontFace,
      html: {
        height: "100%",
        backgroundColor: "#292c25",
        WebkitFontSmoothing: "antialiased",
        MozOsxFontSmoothing: "grayscale",
      },
      body: {
        height: "100%",
        margin: "0 auto",
        fontSize: "1rem",
        lineHeight: "1.55",
        backgroundColor: theme.palette.grey.A100,
      },
      // Make footer stick to the bottom
      "#sbec": {
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        maxWidth: "1440px",
        margin: "0 auto",
      },
      main: {
        backgroundColor: "#ffffff",
        padding: "24px 24px 40px",
        flex: 1,
      },
    }),
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme }) => ({
        ...getBaseToolTipStyle(theme),
      }),
    },
  },
  MuiUseMediaQuery: {
    defaultProps: {
      noSsr: true,
    },
  },
};

export { components };
