import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import type { ContentProps } from "components/dialog/types";
import type { AllowSyncDialogParams } from "./AllowSyncDialog";

const AllowSyncDialogTitle: FC<ContentProps<AllowSyncDialogParams>> = () => {
  const { t } = useTranslation();

  return (
    <Typography variant="h3">
      {t("offline.allowSyncDialog.headline")}
    </Typography>
  );
};

export default AllowSyncDialogTitle;
