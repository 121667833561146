/* tslint:disable */
/* eslint-disable */
/**
 * EFood Storefront API
 * This is the EFood Storefront API specification.  Operations are grouped by tags.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { GetAvailability200Response } from '../../../../com/telekom-mms/efood/api/model';
/**
 * AvailabilityApi - axios parameter creator
 * @export
 */
export const AvailabilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get availability information for orderitems.
         * @param {string} storeId The store identifier
         * @param {Array<string>} orderItemId The orderitem ids.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailability: async (storeId: string, orderItemId: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getAvailability', 'storeId', storeId)
            // verify required parameter 'orderItemId' is not null or undefined
            assertParamExists('getAvailability', 'orderItemId', orderItemId)
            const localVarPath = `/store/{storeId}/efood/availability`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderItemId) {
                localVarQueryParameter['orderItemId'] = orderItemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AvailabilityApi - functional programming interface
 * @export
 */
export const AvailabilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AvailabilityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get availability information for orderitems.
         * @param {string} storeId The store identifier
         * @param {Array<string>} orderItemId The orderitem ids.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailability(storeId: string, orderItemId: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAvailability200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailability(storeId, orderItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AvailabilityApi - factory interface
 * @export
 */
export const AvailabilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AvailabilityApiFp(configuration)
    return {
        /**
         * 
         * @summary Get availability information for orderitems.
         * @param {string} storeId The store identifier
         * @param {Array<string>} orderItemId The orderitem ids.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailability(storeId: string, orderItemId: Array<string>, options?: any): AxiosPromise<GetAvailability200Response> {
            return localVarFp.getAvailability(storeId, orderItemId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AvailabilityApi - object-oriented interface
 * @export
 * @class AvailabilityApi
 * @extends {BaseAPI}
 */
export class AvailabilityApi extends BaseAPI {
    /**
     * 
     * @summary Get availability information for orderitems.
     * @param {string} storeId The store identifier
     * @param {Array<string>} orderItemId The orderitem ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvailabilityApi
     */
    public getAvailability(storeId: string, orderItemId: Array<string>, options?: any) {
        return AvailabilityApiFp(this.configuration).getAvailability(storeId, orderItemId, options).then((request) => request(this.axios, this.basePath));
    }
}
