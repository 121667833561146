/* tslint:disable */
/* eslint-disable */
/**
 * EFood Storefront API
 * This is the EFood Storefront API specification.  Operations are grouped by tags.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { AddCustomerProductListToCartResponse } from '../../../../com/telekom-mms/efood/api/model';
// @ts-ignore
import { AddProductToListRequest } from '../../../../com/telekom-mms/efood/api/model';
// @ts-ignore
import { CreateProductList201Response } from '../../../../com/telekom-mms/efood/api/model';
// @ts-ignore
import { CreateProductListRequest } from '../../../../com/telekom-mms/efood/api/model';
// @ts-ignore
import { CustomerProductListsResponse } from '../../../../com/telekom-mms/efood/api/model';
// @ts-ignore
import { UpdateProductListRequest } from '../../../../com/telekom-mms/efood/api/model';
// @ts-ignore
import { UpdateStandardQuantityRequest } from '../../../../com/telekom-mms/efood/api/model';
/**
 * CustomerProductListApi - axios parameter creator
 * @export
 */
export const CustomerProductListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds the productlists specified by customerProductlistId to the current cart.
         * @param {string} storeId The store identifier
         * @param {BigInt} customerProductlistId The customer productlist identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductListToCart: async (storeId: string, customerProductlistId: BigInt, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('addProductListToCart', 'storeId', storeId)
            // verify required parameter 'customerProductlistId' is not null or undefined
            assertParamExists('addProductListToCart', 'customerProductlistId', customerProductlistId)
            const localVarPath = `/store/{storeId}/efood/customer_productlist/add_to_cart/{customerProductlistId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"customerProductlistId"}}`, String(customerProductlistId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds an item to a customer productlist specified by the customerProductlistId and productId.
         * @param {string} storeId The store identifier
         * @param {BigInt} customerProductlistId The customer productlist identifier.
         * @param {AddProductToListRequest} [addProductToListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductToList: async (storeId: string, customerProductlistId: BigInt, addProductToListRequest?: AddProductToListRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('addProductToList', 'storeId', storeId)
            // verify required parameter 'customerProductlistId' is not null or undefined
            assertParamExists('addProductToList', 'customerProductlistId', customerProductlistId)
            const localVarPath = `/store/{storeId}/efood/customer_productlist/{customerProductlistId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"customerProductlistId"}}`, String(customerProductlistId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addProductToListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new customer productlist specified by name, type and description.
         * @param {string} storeId The store identifier
         * @param {CreateProductListRequest} [createProductListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductList: async (storeId: string, createProductListRequest?: CreateProductListRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('createProductList', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/efood/customer_productlist`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an item from a customer productlist specified by the customerProductlistId and productId.
         * @param {string} storeId The store identifier
         * @param {BigInt} customerProductlistId The customer productlist identifier.
         * @param {BigInt} productId The product identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductFromList: async (storeId: string, customerProductlistId: BigInt, productId: BigInt, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('deleteProductFromList', 'storeId', storeId)
            // verify required parameter 'customerProductlistId' is not null or undefined
            assertParamExists('deleteProductFromList', 'customerProductlistId', customerProductlistId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteProductFromList', 'productId', productId)
            const localVarPath = `/store/{storeId}/efood/customer_productlist/{customerProductlistId}/{productId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"customerProductlistId"}}`, String(customerProductlistId))
                .replace(`{${"productId"}}`, String(productId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a customer productlist specified by the customerProductlistId
         * @param {string} storeId The store identifier
         * @param {BigInt} customerProductlistId The customer productlist identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductList: async (storeId: string, customerProductlistId: BigInt, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('deleteProductList', 'storeId', storeId)
            // verify required parameter 'customerProductlistId' is not null or undefined
            assertParamExists('deleteProductList', 'customerProductlistId', customerProductlistId)
            const localVarPath = `/store/{storeId}/efood/customer_productlist/{customerProductlistId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"customerProductlistId"}}`, String(customerProductlistId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all plain productlists specified by the active user and the storeId.
         * @param {string} storeId The store identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomerProductLists: async (storeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getAllCustomerProductLists', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/efood/customer_productlist/@self`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all plain productlists which contain a product with the specified productId.
         * @param {string} storeId The store identifier
         * @param {string} productId The product identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListsByProduct: async (storeId: string, productId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getListsByProduct', 'storeId', storeId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getListsByProduct', 'productId', productId)
            const localVarPath = `/store/{storeId}/efood/customer_productlist/byProductId`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a customer productlist specified by the customerProductlistId.
         * @param {string} storeId The store identifier
         * @param {BigInt} customerProductlistId The customer productlist identifier.
         * @param {UpdateProductListRequest} [updateProductListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductList: async (storeId: string, customerProductlistId: BigInt, updateProductListRequest?: UpdateProductListRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('updateProductList', 'storeId', storeId)
            // verify required parameter 'customerProductlistId' is not null or undefined
            assertParamExists('updateProductList', 'customerProductlistId', customerProductlistId)
            const localVarPath = `/store/{storeId}/efood/customer_productlist/{customerProductlistId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"customerProductlistId"}}`, String(customerProductlistId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds standard quantity to customer product list item.
         * @param {string} storeId The store identifier
         * @param {BigInt} customerProductlistId The customer productlist identifier.
         * @param {BigInt} customerProductlistItemId The customer productlist item identifier.
         * @param {UpdateStandardQuantityRequest} [updateStandardQuantityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStandardQuantity: async (storeId: string, customerProductlistId: BigInt, customerProductlistItemId: BigInt, updateStandardQuantityRequest?: UpdateStandardQuantityRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('updateStandardQuantity', 'storeId', storeId)
            // verify required parameter 'customerProductlistId' is not null or undefined
            assertParamExists('updateStandardQuantity', 'customerProductlistId', customerProductlistId)
            // verify required parameter 'customerProductlistItemId' is not null or undefined
            assertParamExists('updateStandardQuantity', 'customerProductlistItemId', customerProductlistItemId)
            const localVarPath = `/store/{storeId}/efood/customer_productlist/{customerProductlistId}/{customerProductlistItemId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"customerProductlistId"}}`, String(customerProductlistId))
                .replace(`{${"customerProductlistItemId"}}`, String(customerProductlistItemId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStandardQuantityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerProductListApi - functional programming interface
 * @export
 */
export const CustomerProductListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerProductListApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds the productlists specified by customerProductlistId to the current cart.
         * @param {string} storeId The store identifier
         * @param {BigInt} customerProductlistId The customer productlist identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProductListToCart(storeId: string, customerProductlistId: BigInt, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddCustomerProductListToCartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProductListToCart(storeId, customerProductlistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds an item to a customer productlist specified by the customerProductlistId and productId.
         * @param {string} storeId The store identifier
         * @param {BigInt} customerProductlistId The customer productlist identifier.
         * @param {AddProductToListRequest} [addProductToListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProductToList(storeId: string, customerProductlistId: BigInt, addProductToListRequest?: AddProductToListRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProductToList(storeId, customerProductlistId, addProductToListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new customer productlist specified by name, type and description.
         * @param {string} storeId The store identifier
         * @param {CreateProductListRequest} [createProductListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProductList(storeId: string, createProductListRequest?: CreateProductListRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateProductList201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProductList(storeId, createProductListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes an item from a customer productlist specified by the customerProductlistId and productId.
         * @param {string} storeId The store identifier
         * @param {BigInt} customerProductlistId The customer productlist identifier.
         * @param {BigInt} productId The product identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductFromList(storeId: string, customerProductlistId: BigInt, productId: BigInt, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductFromList(storeId, customerProductlistId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a customer productlist specified by the customerProductlistId
         * @param {string} storeId The store identifier
         * @param {BigInt} customerProductlistId The customer productlist identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductList(storeId: string, customerProductlistId: BigInt, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductList(storeId, customerProductlistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all plain productlists specified by the active user and the storeId.
         * @param {string} storeId The store identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCustomerProductLists(storeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerProductListsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCustomerProductLists(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all plain productlists which contain a product with the specified productId.
         * @param {string} storeId The store identifier
         * @param {string} productId The product identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListsByProduct(storeId: string, productId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerProductListsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListsByProduct(storeId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a customer productlist specified by the customerProductlistId.
         * @param {string} storeId The store identifier
         * @param {BigInt} customerProductlistId The customer productlist identifier.
         * @param {UpdateProductListRequest} [updateProductListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProductList(storeId: string, customerProductlistId: BigInt, updateProductListRequest?: UpdateProductListRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProductList(storeId, customerProductlistId, updateProductListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds standard quantity to customer product list item.
         * @param {string} storeId The store identifier
         * @param {BigInt} customerProductlistId The customer productlist identifier.
         * @param {BigInt} customerProductlistItemId The customer productlist item identifier.
         * @param {UpdateStandardQuantityRequest} [updateStandardQuantityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStandardQuantity(storeId: string, customerProductlistId: BigInt, customerProductlistItemId: BigInt, updateStandardQuantityRequest?: UpdateStandardQuantityRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStandardQuantity(storeId, customerProductlistId, customerProductlistItemId, updateStandardQuantityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerProductListApi - factory interface
 * @export
 */
export const CustomerProductListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerProductListApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds the productlists specified by customerProductlistId to the current cart.
         * @param {string} storeId The store identifier
         * @param {BigInt} customerProductlistId The customer productlist identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductListToCart(storeId: string, customerProductlistId: BigInt, options?: any): AxiosPromise<AddCustomerProductListToCartResponse> {
            return localVarFp.addProductListToCart(storeId, customerProductlistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds an item to a customer productlist specified by the customerProductlistId and productId.
         * @param {string} storeId The store identifier
         * @param {BigInt} customerProductlistId The customer productlist identifier.
         * @param {AddProductToListRequest} [addProductToListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductToList(storeId: string, customerProductlistId: BigInt, addProductToListRequest?: AddProductToListRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addProductToList(storeId, customerProductlistId, addProductToListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new customer productlist specified by name, type and description.
         * @param {string} storeId The store identifier
         * @param {CreateProductListRequest} [createProductListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductList(storeId: string, createProductListRequest?: CreateProductListRequest, options?: any): AxiosPromise<CreateProductList201Response> {
            return localVarFp.createProductList(storeId, createProductListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an item from a customer productlist specified by the customerProductlistId and productId.
         * @param {string} storeId The store identifier
         * @param {BigInt} customerProductlistId The customer productlist identifier.
         * @param {BigInt} productId The product identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductFromList(storeId: string, customerProductlistId: BigInt, productId: BigInt, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProductFromList(storeId, customerProductlistId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a customer productlist specified by the customerProductlistId
         * @param {string} storeId The store identifier
         * @param {BigInt} customerProductlistId The customer productlist identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductList(storeId: string, customerProductlistId: BigInt, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProductList(storeId, customerProductlistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all plain productlists specified by the active user and the storeId.
         * @param {string} storeId The store identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomerProductLists(storeId: string, options?: any): AxiosPromise<CustomerProductListsResponse> {
            return localVarFp.getAllCustomerProductLists(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all plain productlists which contain a product with the specified productId.
         * @param {string} storeId The store identifier
         * @param {string} productId The product identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListsByProduct(storeId: string, productId: string, options?: any): AxiosPromise<CustomerProductListsResponse> {
            return localVarFp.getListsByProduct(storeId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a customer productlist specified by the customerProductlistId.
         * @param {string} storeId The store identifier
         * @param {BigInt} customerProductlistId The customer productlist identifier.
         * @param {UpdateProductListRequest} [updateProductListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductList(storeId: string, customerProductlistId: BigInt, updateProductListRequest?: UpdateProductListRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateProductList(storeId, customerProductlistId, updateProductListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds standard quantity to customer product list item.
         * @param {string} storeId The store identifier
         * @param {BigInt} customerProductlistId The customer productlist identifier.
         * @param {BigInt} customerProductlistItemId The customer productlist item identifier.
         * @param {UpdateStandardQuantityRequest} [updateStandardQuantityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStandardQuantity(storeId: string, customerProductlistId: BigInt, customerProductlistItemId: BigInt, updateStandardQuantityRequest?: UpdateStandardQuantityRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateStandardQuantity(storeId, customerProductlistId, customerProductlistItemId, updateStandardQuantityRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerProductListApi - object-oriented interface
 * @export
 * @class CustomerProductListApi
 * @extends {BaseAPI}
 */
export class CustomerProductListApi extends BaseAPI {
    /**
     * 
     * @summary Adds the productlists specified by customerProductlistId to the current cart.
     * @param {string} storeId The store identifier
     * @param {BigInt} customerProductlistId The customer productlist identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductListApi
     */
    public addProductListToCart(storeId: string, customerProductlistId: BigInt, options?: any) {
        return CustomerProductListApiFp(this.configuration).addProductListToCart(storeId, customerProductlistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds an item to a customer productlist specified by the customerProductlistId and productId.
     * @param {string} storeId The store identifier
     * @param {BigInt} customerProductlistId The customer productlist identifier.
     * @param {AddProductToListRequest} [addProductToListRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductListApi
     */
    public addProductToList(storeId: string, customerProductlistId: BigInt, addProductToListRequest?: AddProductToListRequest, options?: any) {
        return CustomerProductListApiFp(this.configuration).addProductToList(storeId, customerProductlistId, addProductToListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new customer productlist specified by name, type and description.
     * @param {string} storeId The store identifier
     * @param {CreateProductListRequest} [createProductListRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductListApi
     */
    public createProductList(storeId: string, createProductListRequest?: CreateProductListRequest, options?: any) {
        return CustomerProductListApiFp(this.configuration).createProductList(storeId, createProductListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes an item from a customer productlist specified by the customerProductlistId and productId.
     * @param {string} storeId The store identifier
     * @param {BigInt} customerProductlistId The customer productlist identifier.
     * @param {BigInt} productId The product identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductListApi
     */
    public deleteProductFromList(storeId: string, customerProductlistId: BigInt, productId: BigInt, options?: any) {
        return CustomerProductListApiFp(this.configuration).deleteProductFromList(storeId, customerProductlistId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a customer productlist specified by the customerProductlistId
     * @param {string} storeId The store identifier
     * @param {BigInt} customerProductlistId The customer productlist identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductListApi
     */
    public deleteProductList(storeId: string, customerProductlistId: BigInt, options?: any) {
        return CustomerProductListApiFp(this.configuration).deleteProductList(storeId, customerProductlistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all plain productlists specified by the active user and the storeId.
     * @param {string} storeId The store identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductListApi
     */
    public getAllCustomerProductLists(storeId: string, options?: any) {
        return CustomerProductListApiFp(this.configuration).getAllCustomerProductLists(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all plain productlists which contain a product with the specified productId.
     * @param {string} storeId The store identifier
     * @param {string} productId The product identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductListApi
     */
    public getListsByProduct(storeId: string, productId: string, options?: any) {
        return CustomerProductListApiFp(this.configuration).getListsByProduct(storeId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a customer productlist specified by the customerProductlistId.
     * @param {string} storeId The store identifier
     * @param {BigInt} customerProductlistId The customer productlist identifier.
     * @param {UpdateProductListRequest} [updateProductListRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductListApi
     */
    public updateProductList(storeId: string, customerProductlistId: BigInt, updateProductListRequest?: UpdateProductListRequest, options?: any) {
        return CustomerProductListApiFp(this.configuration).updateProductList(storeId, customerProductlistId, updateProductListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds standard quantity to customer product list item.
     * @param {string} storeId The store identifier
     * @param {BigInt} customerProductlistId The customer productlist identifier.
     * @param {BigInt} customerProductlistItemId The customer productlist item identifier.
     * @param {UpdateStandardQuantityRequest} [updateStandardQuantityRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductListApi
     */
    public updateStandardQuantity(storeId: string, customerProductlistId: BigInt, customerProductlistItemId: BigInt, updateStandardQuantityRequest?: UpdateStandardQuantityRequest, options?: any) {
        return CustomerProductListApiFp(this.configuration).updateStandardQuantity(storeId, customerProductlistId, customerProductlistItemId, updateStandardQuantityRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
