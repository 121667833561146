/* tslint:disable */
/* eslint-disable */
/**
 * EFood Storefront API
 * This is the EFood Storefront API specification.  Operations are grouped by tags.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { AddOrderHistoryToCartResponse } from '../../../../com/telekom-mms/efood/api/model';
// @ts-ignore
import { GetOrderHistoryItem200Response } from '../../../../com/telekom-mms/efood/api/model';
// @ts-ignore
import { GetOrderHistoryList200Response } from '../../../../com/telekom-mms/efood/api/model';
// @ts-ignore
import { OrderHistoryEntryDetails } from '../../../../com/telekom-mms/efood/api/model';
/**
 * OrderHistoryApi - axios parameter creator
 * @export
 */
export const OrderHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds the order specified by orderHistoryId to the current cart.
         * @param {string} storeId The store identifier
         * @param {BigInt} orderHistoryId The order history identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrderHistoryToCart: async (storeId: string, orderHistoryId: BigInt, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('addOrderHistoryToCart', 'storeId', storeId)
            // verify required parameter 'orderHistoryId' is not null or undefined
            assertParamExists('addOrderHistoryToCart', 'orderHistoryId', orderHistoryId)
            const localVarPath = `/store/{storeId}/efood/order_history/add_to_cart/{orderHistoryId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"orderHistoryId"}}`, String(orderHistoryId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method to retrieve the details of an order history entry.
         * @param {string} storeId The store identifier
         * @param {BigInt} orderHistoryId The id of the order history entry.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistoryEntryDetails: async (storeId: string, orderHistoryId: BigInt, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getOrderHistoryEntryDetails', 'storeId', storeId)
            // verify required parameter 'orderHistoryId' is not null or undefined
            assertParamExists('getOrderHistoryEntryDetails', 'orderHistoryId', orderHistoryId)
            const localVarPath = `/store/{storeId}/efood/order_history/details/{orderHistoryId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"orderHistoryId"}}`, String(orderHistoryId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method to retrieve the order history details for a specified item.
         * @param {string} storeId The store identifier
         * @param {string} partNumber The partnumber of the item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistoryItem: async (storeId: string, partNumber: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getOrderHistoryItem', 'storeId', storeId)
            // verify required parameter 'partNumber' is not null or undefined
            assertParamExists('getOrderHistoryItem', 'partNumber', partNumber)
            const localVarPath = `/store/{storeId}/efood/order_history/item/{partNumber}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"partNumber"}}`, String(partNumber));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method to retrieve the order history list.
         * @param {string} storeId The store identifier
         * @param {BigInt} memberId The id of the member (e.g. organization id).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistoryList: async (storeId: string, memberId: BigInt, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getOrderHistoryList', 'storeId', storeId)
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('getOrderHistoryList', 'memberId', memberId)
            const localVarPath = `/store/{storeId}/efood/order_history/{memberId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"memberId"}}`, String(memberId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderHistoryApi - functional programming interface
 * @export
 */
export const OrderHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds the order specified by orderHistoryId to the current cart.
         * @param {string} storeId The store identifier
         * @param {BigInt} orderHistoryId The order history identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrderHistoryToCart(storeId: string, orderHistoryId: BigInt, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrderHistoryToCartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrderHistoryToCart(storeId, orderHistoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method to retrieve the details of an order history entry.
         * @param {string} storeId The store identifier
         * @param {BigInt} orderHistoryId The id of the order history entry.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderHistoryEntryDetails(storeId: string, orderHistoryId: BigInt, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderHistoryEntryDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderHistoryEntryDetails(storeId, orderHistoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method to retrieve the order history details for a specified item.
         * @param {string} storeId The store identifier
         * @param {string} partNumber The partnumber of the item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderHistoryItem(storeId: string, partNumber: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrderHistoryItem200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderHistoryItem(storeId, partNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method to retrieve the order history list.
         * @param {string} storeId The store identifier
         * @param {BigInt} memberId The id of the member (e.g. organization id).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderHistoryList(storeId: string, memberId: BigInt, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrderHistoryList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderHistoryList(storeId, memberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderHistoryApi - factory interface
 * @export
 */
export const OrderHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderHistoryApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds the order specified by orderHistoryId to the current cart.
         * @param {string} storeId The store identifier
         * @param {BigInt} orderHistoryId The order history identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrderHistoryToCart(storeId: string, orderHistoryId: BigInt, options?: any): AxiosPromise<AddOrderHistoryToCartResponse> {
            return localVarFp.addOrderHistoryToCart(storeId, orderHistoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method to retrieve the details of an order history entry.
         * @param {string} storeId The store identifier
         * @param {BigInt} orderHistoryId The id of the order history entry.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistoryEntryDetails(storeId: string, orderHistoryId: BigInt, options?: any): AxiosPromise<OrderHistoryEntryDetails> {
            return localVarFp.getOrderHistoryEntryDetails(storeId, orderHistoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method to retrieve the order history details for a specified item.
         * @param {string} storeId The store identifier
         * @param {string} partNumber The partnumber of the item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistoryItem(storeId: string, partNumber: string, options?: any): AxiosPromise<GetOrderHistoryItem200Response> {
            return localVarFp.getOrderHistoryItem(storeId, partNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method to retrieve the order history list.
         * @param {string} storeId The store identifier
         * @param {BigInt} memberId The id of the member (e.g. organization id).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistoryList(storeId: string, memberId: BigInt, options?: any): AxiosPromise<GetOrderHistoryList200Response> {
            return localVarFp.getOrderHistoryList(storeId, memberId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderHistoryApi - object-oriented interface
 * @export
 * @class OrderHistoryApi
 * @extends {BaseAPI}
 */
export class OrderHistoryApi extends BaseAPI {
    /**
     * 
     * @summary Adds the order specified by orderHistoryId to the current cart.
     * @param {string} storeId The store identifier
     * @param {BigInt} orderHistoryId The order history identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderHistoryApi
     */
    public addOrderHistoryToCart(storeId: string, orderHistoryId: BigInt, options?: any) {
        return OrderHistoryApiFp(this.configuration).addOrderHistoryToCart(storeId, orderHistoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method to retrieve the details of an order history entry.
     * @param {string} storeId The store identifier
     * @param {BigInt} orderHistoryId The id of the order history entry.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderHistoryApi
     */
    public getOrderHistoryEntryDetails(storeId: string, orderHistoryId: BigInt, options?: any) {
        return OrderHistoryApiFp(this.configuration).getOrderHistoryEntryDetails(storeId, orderHistoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method to retrieve the order history details for a specified item.
     * @param {string} storeId The store identifier
     * @param {string} partNumber The partnumber of the item.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderHistoryApi
     */
    public getOrderHistoryItem(storeId: string, partNumber: string, options?: any) {
        return OrderHistoryApiFp(this.configuration).getOrderHistoryItem(storeId, partNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method to retrieve the order history list.
     * @param {string} storeId The store identifier
     * @param {BigInt} memberId The id of the member (e.g. organization id).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderHistoryApi
     */
    public getOrderHistoryList(storeId: string, memberId: BigInt, options?: any) {
        return OrderHistoryApiFp(this.configuration).getOrderHistoryList(storeId, memberId, options).then((request) => request(this.axios, this.basePath));
    }
}
