import React, { FC } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import StyledPagination from "components/StyledUI/StyledPagination";

interface PaginationSectionProps {
  pageSize: string;
  page: number;
  setPage: (page: number) => void;
  totalAmountOfProducts: number | null;
}

const calculatePaginationSize = (
  totalAmountOfProducts: number,
  pageSize: string
) => Math.ceil(totalAmountOfProducts / parseInt(pageSize, 10));

const PaginationSection: FC<PaginationSectionProps> = ({
  pageSize,
  page,
  setPage,
  totalAmountOfProducts,
}) => {
  const theme = useTheme();
  const matchesXsViewport = useMediaQuery(theme.breakpoints.only("xs"), {
    noSsr: true,
  });

  if (!totalAmountOfProducts) {
    return null;
  }

  const paginationSize = calculatePaginationSize(
    totalAmountOfProducts,
    pageSize
  );

  // Current page is out of scope of the pagination
  // For example pagination has 1-3 pages but the current page is 4
  if (page > paginationSize) {
    setPage(1);
  }

  return (
    <StyledPagination
      smallVariant={matchesXsViewport}
      currentPage={page}
      count={paginationSize}
      handleChange={(newPage) => setPage(newPage)}
      siblingCount={0}
    />
  );
};

export default PaginationSection;
