import { useMemo } from "react";
import { useAppSelector } from "_redux/hooks";
import {
  loginStatusSelector,
  userInitStatusSelector,
} from "_redux/selectors/user";

interface loggedInResult {
  loggedIn: boolean;
  loading: boolean;
}

const useLoggedIn = (): loggedInResult => {
  const loggedIn = useAppSelector(loginStatusSelector);
  const initSelector = useAppSelector(userInitStatusSelector);

  return useMemo(() => {
    if (initSelector) {
      return { loggedIn, loading: false };
    }

    return { loggedIn, loading: true };
  }, [loggedIn, initSelector]);
};

export { useLoggedIn };
