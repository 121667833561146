import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react";
import getEnvironmentType, { EnvironmentType } from "tools/getEnvironmentType";

export const EnvironmentContext = createContext<{ envType: EnvironmentType }>({
  envType: "prod",
} as const);

export const EnvironmentContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const hostname = window?.location.hostname;
  const contextValue = useMemo(
    () => ({ envType: getEnvironmentType(hostname) }) as const,
    [hostname]
  );

  return (
    <EnvironmentContext.Provider value={contextValue}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export const useEnvironmentContext = () => useContext(EnvironmentContext);
