import React, { FC } from "react";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
  color?: "primary" | "secondary" | "inherit";
  size?: number | string;
}

const StyledProgress: FC<Props> = ({ color = "primary", size = 40 }) => (
  <CircularProgress color={color} size={size} />
);

export default StyledProgress;
