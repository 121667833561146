import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { StyledDivider } from "components/StyledUI";
import { DeliveryDateBox } from "components/widgets/deliveryDateBox";
import { tss } from "tss-react/mui";
import type { ContentProps } from "../types";

const useStyles = tss.create(() => ({
  lable: {
    display: "inline-block",
    margin: "10px 4px 0 0",
  },
}));

export type DeliveryDateExpiredParams = {
  time?: string;
};

const DeliveryDateExpired: FC<ContentProps<DeliveryDateExpiredParams>> = ({
  close,
  params,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <>
      <Typography variant="body2">
        {t("cart.dialog.deliveryDateExpired.body", {
          time: params?.time ? `(${params?.time} Uhr)` : "",
        })}
      </Typography>
      <StyledDivider light />
      <Typography variant="body1" className={classes.lable}>
        {t(`cart.deliveryOn`)}:
      </Typography>
      <DeliveryDateBox callback={close} />
    </>
  );
};

export default DeliveryDateExpired;
