import React, { FC } from "react";
import { Box } from "@mui/material";
import { StyledNotification } from "components/StyledUI";
import { useTranslation } from "react-i18next";

export type ErrorTypes = "MAX_SELECTION_ERROR" | "FETCH_ERROR";

interface CartFreeProductErrorProps {
  errorType?: ErrorTypes;
}

const CartFreeProductError: FC<CartFreeProductErrorProps> = ({ errorType }) => {
  const { t } = useTranslation();

  const getErrorMessage = () => {
    if (errorType === "MAX_SELECTION_ERROR") {
      return t("cart.freeProduct.maxSelectionError");
    }

    if (errorType === "FETCH_ERROR") {
      return t("cart.freeProduct.fetchError");
    }

    return null;
  };

  return (
    <>
      {errorType && (
        <Box mb={2}>
          <StyledNotification severity="error" close>
            {getErrorMessage()}
          </StyledNotification>
        </Box>
      )}
    </>
  );
};

export default CartFreeProductError;
