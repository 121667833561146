// react
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

// components
import { StyledButton } from "components/StyledUI";
import { useNavigate } from "react-router-dom";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  button: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const ContinueShoppingButton: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <StyledButton
      variant="outlined"
      className={classes.button}
      onClick={() => navigate(-1)}>
      {t("cart.continueShopping")}
    </StyledButton>
  );
};

export default ContinueShoppingButton;
