import React, { FC } from "react";

import CardContent, { CardContentProps } from "@mui/material/CardContent";

import { tss } from "tss-react/mui";

const useStyles = tss.create(() => ({
  root: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
}));

const StyledCardContent: FC<CardContentProps> = ({ children, ...rest }) => {
  const { classes } = useStyles();

  return (
    <CardContent classes={classes} {...rest}>
      {children}
    </CardContent>
  );
};

export { StyledCardContent };
