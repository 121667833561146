import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import type { ContentProps } from "../types";
import type { MessageParams } from "../contents/Message";

const Message: FC<ContentProps<MessageParams>> = ({ params }) => {
  const { t } = useTranslation();
  const { titleKey = "" } = params ?? {};

  if (!titleKey) {
    return null;
  }

  return <Typography variant="h3">{t(titleKey)}</Typography>;
};

export default Message;
