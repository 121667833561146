import { createReducer, AnyAction } from "@reduxjs/toolkit";
import initStates from "./initStates";
import {
  CLOSE_DIALOG_ACTION,
  INIT_SITE_SUCCESS_ACTION,
  RESET_SYNC_MANUALLY_ACTION,
  SCROLL_TO_TOP_ACTION,
  SHOW_DIALOG_ACTION,
  TRIGGER_SYNC_MANUALLY_ACTION,
  UPDATE_FORCE_STANDALONE_MODE_ACTION,
  UPDATE_ONLINE_STATUS_ACTION,
  UPDATE_SYNC_RUNNING_ACTION,
} from "_redux/actions/site";
import { UPDATE_FORCE_STANDALONE_MODE } from "_redux/action-types/site";
import { SiteReducerState } from ".";

const siteReducer = createReducer(initStates.site, (builder) => {
  builder.addCase(
    INIT_SITE_SUCCESS_ACTION,
    (state: SiteReducerState | any, action: AnyAction) => {
      Object.assign(state, { currentSite: { ...action.payload } });
    }
  );
  builder.addCase(
    SCROLL_TO_TOP_ACTION,
    (state: SiteReducerState | any, action: AnyAction) => {
      Object.assign(state, { scrollToTop: action.payload });
    }
  );
  builder.addCase(SHOW_DIALOG_ACTION, (state, action) => {
    const { payload } = action;
    // avoid opening same dialog more than once
    if (state.showDialog.findIndex((d) => d.type === payload.type) === -1) {
      state.showDialog.push(action.payload);
    }
  });
  builder.addCase(CLOSE_DIALOG_ACTION, (state, action) => {
    const { payload } = action;
    const dialogIndex = state.showDialog.findIndex(
      (e) => e.type === payload.type
    );
    if (dialogIndex > -1) {
      state.showDialog.splice(dialogIndex, 1);
    }
  });
  builder.addCase(UPDATE_ONLINE_STATUS_ACTION, (state, action) => {
    const { onlineStatus } = state;
    const { payload } = action;
    if (
      payload === "online" &&
      (onlineStatus.status !== "online" || onlineStatus.changed)
    ) {
      state.onlineStatus = {
        status: "online",
        changed: onlineStatus.status !== "online",
      };
    } else if (
      payload === "offline" &&
      (onlineStatus.status !== "offline" || onlineStatus.changed)
    ) {
      state.onlineStatus = {
        status: "offline",
        changed: onlineStatus.status !== "offline",
      };
    }
  });
  builder.addCase(UPDATE_FORCE_STANDALONE_MODE_ACTION, (state, action) => {
    const { payload } = action;
    state.forceStandaloneMode = payload;
  });
  builder.addCase(TRIGGER_SYNC_MANUALLY_ACTION, (state, action) => {
    state.syncManuallyTriggered = true;
  });
  builder.addCase(RESET_SYNC_MANUALLY_ACTION, (state, action) => {
    state.syncManuallyTriggered = false;
  });
  builder.addCase(UPDATE_SYNC_RUNNING_ACTION, (state, action) => {
    const { payload } = action;
    state.syncRunning = payload;
  });
});

export default siteReducer;
