// react
import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

// components
import { OffersContainer, OfferSearchContainer } from "components/offers";
import ESpot from "components/widgets/espot/ESpot";
import { useParams } from "react-router-dom";

const Offers: FC = () => {
  const params = useParams();
  const { t } = useTranslation();
  const offerNumber = params.offerNumber
    ? parseInt(params.offerNumber, 10)
    : null;

  return (
    <>
      <Helmet>
        <title>{t("offers.title")}</title>
      </Helmet>

      {!offerNumber ? (
        <>
          <OffersContainer />
          <ESpot emsName="Offer_Bottom_EMS" />
        </>
      ) : (
        <OfferSearchContainer offerNumber={offerNumber} />
      )}
    </>
  );
};

export default Offers;
