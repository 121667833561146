/* eslint-disable @typescript-eslint/no-explicit-any */
import HTMLReactParser, { domToReact } from "html-react-parser";
import { mapNodeToMUIComponent } from "components/widgets/content-recommendation/mapNodeToReactComponent";
import { ComIbmCommerceRestMarketingHandlerESpotDataHandlerESpotContainerMarketingSpotDataContainer } from "@hcl-commerce-store-sdk/typescript-axios-transaction";

export type EmsGridItemType = "one-third" | "one-half" | "full" | undefined;

export default interface EmsGridItem {
  gridItemType: EmsGridItemType;
  content?: ReturnType<typeof domToReact>;
  contentId?: string;
  imageSrc?: string;
  imageAlt?: string;
  iconSrc?: string;
  iconAlt?: string;
  url?: string;
}

export const mapMarketingSpotDataToEmsGridItems = (
  eSpotRoot: ComIbmCommerceRestMarketingHandlerESpotDataHandlerESpotContainerMarketingSpotDataContainer
): EmsGridItem[] => {
  const eSpotData =
    (eSpotRoot.baseMarketingSpotActivityData as Array<Record<string, any>>) ||
    [];
  const items: EmsGridItem[] = eSpotData
    .filter(
      (eSpotDesc) =>
        eSpotDesc.marketingContentDescription &&
        eSpotDesc.marketingContentDescription[0]?.marketingText?.length > 0
    )
    .map((eSpotDesc) => {
      let cardType: EmsGridItemType;
      if (eSpotDesc.contentName.endsWith("_3")) {
        cardType = "full";
      } else if (eSpotDesc.contentName.endsWith("_2")) {
        cardType = "one-half";
      } else {
        cardType = "one-third";
      }

      // create result object
      const gridItem: EmsGridItem = {
        gridItemType: cardType,
        contentId: eSpotDesc.contentId,
        url: eSpotDesc.contentUrl,
      };

      // background image
      if (eSpotDesc.attachmentAsset && eSpotDesc.attachmentAsset[0]) {
        gridItem.imageSrc = eSpotDesc.attachmentAsset[0].attachmentAssetPath;
        gridItem.imageAlt = eSpotDesc.attachmentAsset[0].attachmentName;
      }

      // find content for item
      const { marketingText } = eSpotDesc.marketingContentDescription[0];
      const parsedDocument = new DOMParser().parseFromString(
        marketingText,
        "text/html"
      );
      // first find all images and use the first image as icon
      // than remove all images
      const parsedImages = parsedDocument.querySelectorAll("img");
      if (parsedImages.length) {
        gridItem.iconSrc = parsedImages[0].src || undefined;
        gridItem.iconAlt = parsedImages[0].alt || undefined;

        // remove all images from document
        parsedImages.forEach((img) => {
          const imgParent = img?.parentNode;
          if (imgParent) {
            if (imgParent.parentNode && imgParent.childElementCount === 1) {
              imgParent.parentNode.removeChild(imgParent);
            } else {
              imgParent.removeChild(img);
            }
          }
        });
      }
      // finally add the remaining html fragment as content to the item
      gridItem.content = HTMLReactParser(parsedDocument.body.innerHTML, {
        replace: mapNodeToMUIComponent,
      });

      return gridItem;
    });

  return items;
};
