/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, Method, AxiosPromise } from "axios";
import { executeRequest } from "_foundation/axios/axiosConfig";
import { getSite } from "_foundation/hooks/useSite";
import { CustomerProductListApi } from "@efood-commerce-sdk/typescript-axios-transaction-efood";
import { site as constantsSite } from "_foundationExt/constants/site";
import ensureObject from "tools/ensureObject";

const customerProductListApiInstance = new CustomerProductListApi(
  undefined,
  constantsSite.transactionContext
);

const customerProductlistService = {
  /**
   * Get all plain productlists specified by the active user and the storeId
   * @param parameters {storeId: string}
   */
  getSelf(parameters: any) {
    const site = getSite();
    const { storeId = site?.storeID, ...options } = ensureObject(parameters);

    return customerProductListApiInstance.getAllCustomerProductLists(
      storeId,
      options
    );
  },

  /**
   * Get all plain productlists which contain a product with the specified prodictId
   * @param parameters {storeId: string, productId: string}
   */
  getListsByProductId(parameters: any) {
    const site = getSite();
    const {
      storeId = site?.storeID,
      productId,
      ...options
    } = ensureObject(parameters);

    return customerProductListApiInstance.getListsByProduct(
      storeId,
      productId,
      options
    );
  },

  /**
   * Adds a productlist to the cart with the specified customerProductListId
   * @param parameters {customerProductListId: string}
   */
  addProductlistToBasket(parameters: any) {
    const site = getSite();
    const {
      storeId = site?.storeID,
      customerProductListId,
      ...options
    } = ensureObject(parameters);

    return customerProductListApiInstance.addProductListToCart(
      storeId,
      customerProductListId,
      options
    );
  },

  /**
   * Creates a new customer productlist specified by name, type and description
   * Available types: XRL | shoppinglist, XSL | stocklist, XLL |  labellist
   * @param parameters {storeId: string, body:{name: string, description: string, type: string, addProductsFromCart: boolean}}
   */
  createList(parameters: any) {
    const site = getSite();
    const {
      storeId = site?.storeID,
      body,
      ...options
    } = ensureObject(parameters);

    return customerProductListApiInstance.createProductList(
      storeId,
      body,
      options
    );
  },

  /**
   * Adds an item to a customer productlist specified by the customerProductlistId and productId.
   * @param parameters {storeId: string,customerProductListId: string, body:{productId: string}}
   */
  addProduct(parameters: any) {
    const site = getSite();
    const {
      storeId = site?.storeID,
      customerProductListId,
      body,
      ...options
    } = ensureObject(parameters);

    return customerProductListApiInstance.addProductToList(
      storeId,
      customerProductListId,
      body,
      options
    );
  },

  /**
   * Updates a customer productlist specified by the customerProductlistId
   * @param parameters {storeId: string,customerProductListId: string, body:{name: string, description: string}}
   */
  updateList(parameters: any) {
    const site = getSite();
    const {
      storeId = site?.storeID,
      customerProductListId,
      body,
      ...options
    } = ensureObject(parameters);

    return customerProductListApiInstance.updateProductList(
      storeId,
      customerProductListId,
      body,
      options
    );
  },

  /**
   * Deletes a customer productlist specified by the customerProductlistId
   * @param parameters {storeId: string,customerProductListId: string}
   */
  deleteList(parameters: any) {
    const site = getSite();
    const {
      storeId = site?.storeID,
      customerProductListId,
      ...options
    } = ensureObject(parameters);

    return customerProductListApiInstance.deleteProductList(
      storeId,
      customerProductListId,
      options
    );
  },

  /**
   * Deletes an item from a customer productlist specified by the customerProductlistId and productId
   * @param parameters {storeId: string,customerProductListId: string, productId: string}
   */
  deleteProduct(parameters: any) {
    const site = getSite();
    const {
      storeId = site?.storeID,
      customerProductListId,
      productId,
      ...options
    } = ensureObject(parameters);

    return customerProductListApiInstance.deleteProductFromList(
      storeId,
      customerProductListId,
      productId,
      options
    );
  },

  /**
   * Updates the standard quantity of a product specified by the customerProductListId and customerProductListItemId
   * @param parameters {storeId: string, customerProductListId: string, customerProductListItemId: string, body:{quantity: number}}
   */
  updateStandardQuantityList(parameters: any) {
    const site = getSite();
    const {
      storeId = site?.storeID,
      customerProductListId,
      customerProductListItemId,
      body,
      ...options
    } = ensureObject(parameters);

    return customerProductListApiInstance.updateStandardQuantity(
      storeId,
      customerProductListId,
      customerProductListItemId,
      body,
      options
    );
  },

  /**
   * Saves a stocklist location for a given stocklist item.
   * @param parameters {storeId: string,stockListItemId: string, body:{location: string}}
   * @param url
   */
  saveStorageLocation(parameters: any, url?: string): AxiosPromise<any> {
    const param = parameters;
    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.transactionContext || "";
    }
    const domain = url || siteContext;
    const path = "/store/{storeId}/efood/stocklist/{stockListItemId}";
    let requestUrl = domain + path;
    const method: Method = "PUT";
    let body = {};
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    const queryParameters = new URLSearchParams();

    if (param.storeId === undefined && site !== null) {
      param.storeId = site.storeID;
    }

    if (param.storeId === undefined) {
      throw new Error(`Request '${path}' missing required parameter storeId`);
    }

    requestUrl = requestUrl.replace("{storeId}", param.storeId);

    if (param.stockListItemId === undefined) {
      throw new Error(
        `Request '${path}' missing required parameter stockListItemId`
      );
    }

    requestUrl = requestUrl.replace("{stockListItemId}", param.stockListItemId);

    if (param.body !== undefined) {
      body = parameters.body;
    }

    if (param.body.location === undefined) {
      throw new Error(`Request '${path}' missing required parameter location`);
    }

    if (param.$queryParameters) {
      Object.keys(param.$queryParameters).forEach((parameterName) => {
        const parameter = param.$queryParameters[parameterName];
        queryParameters.set(parameterName, parameter);
      });
    }

    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      headers: header,
      data: body,
      url: requestUrl,
      ...parameters,
    };

    return executeRequest(requestOptions);
  },
  reorganizeStockList(parameters: any, url?: string): AxiosPromise<any> {
    const param = parameters;
    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.transactionContext || "";
    }
    const domain = url || siteContext;
    // const path = "/store/{storeId}/efood/stocklist/{stockListItemId}";
    const path = "/store/{storeId}/efood/stocklist/{stocklistId}/reorganize";
    let requestUrl = domain + path;
    const method: Method = "POST";
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    const queryParameters = new URLSearchParams();

    if (param.storeId === undefined && site !== null) {
      param.storeId = site.storeID;
    }

    if (param.storeId === undefined) {
      throw new Error(`Request '${path}' missing required parameter storeId`);
    }

    requestUrl = requestUrl.replace("{storeId}", param.storeId);

    if (param.stocklistId === undefined) {
      throw new Error(
        `Request '${path}' missing required parameter stocklistId`
      );
    }

    requestUrl = requestUrl.replace("{stocklistId}", param.stocklistId);

    if (param.$queryParameters) {
      Object.keys(param.$queryParameters).forEach((parameterName) => {
        const parameter = param.$queryParameters[parameterName];
        queryParameters.set(parameterName, parameter);
      });
    }

    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      headers: header,
      url: requestUrl,
    };

    return executeRequest(requestOptions);
  },
};
export default customerProductlistService;
