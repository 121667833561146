import { combineReducers } from "redux";
import apiReducer from "./api";
import error from "./error";
import site from "./site";
import user from "./user";
import order from "./order";
import catalog from "./catalog";

import {
  ErrorReducerState,
  UserReducerState,
  OrderReducerState,
  SiteReducerState,
  CatalogReducerState,
  ApiReducerState,
} from "./reducerStateInterface";

export * from "./reducerStateInterface";
export interface RootReducerState {
  api: ApiReducerState;
  user: UserReducerState;
  order: OrderReducerState;
  error: ErrorReducerState;
  site: SiteReducerState;
  catalog: CatalogReducerState;
}

const reducers = {
  api: apiReducer,
  user,
  order,
  error,
  site,
  catalog,
};

const createRootReducer = () =>
  combineReducers({
    ...reducers,
  });

export default createRootReducer;
