import React from "react";
import { GridProps, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ROUTES } from "constants/routes";
import { UnstyledLink } from "components/StyledUI";
import CategoryImage from "components/categoryImage";
import { CatalogGroupView } from "_redux/reducers";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  productImage: {
    margin: "0 auto",
  },
  productTitle: {
    marginBottom: theme.spacing(1),
    fontFamily: "ShadowsIntoLight, ",
    minHeight: "60px",
  },
  productPriceContainer: {
    marginBottom: theme.spacing(2),
    fontSize: "14px",
  },
  productPrice: {
    float: "right",
  },
  ellipsis: {
    marginTop: theme.spacing(2),
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
}));

interface CatalogGroupCardProps {
  currentCatalogGroupView: CatalogGroupView;
}

const CatalogGroupCard: React.FC<CatalogGroupCardProps & GridProps> = ({
  currentCatalogGroupView,
  ...rest
}) => {
  const { classes } = useStyles();

  return (
    currentCatalogGroupView && (
      <Grid container {...rest}>
        <Grid xs={12} className={classes.productTitle}>
          <CategoryImage
            catalogGroupView={currentCatalogGroupView}
            size="180"
            centered
          />
          <UnstyledLink
            to={`${ROUTES.PRODUCT_LIST}/${currentCatalogGroupView?.uniqueID}`}>
            <Typography
              variant="h5"
              component="h3"
              className={classes.ellipsis}>
              {currentCatalogGroupView?.name}
            </Typography>
          </UnstyledLink>
        </Grid>
      </Grid>
    )
  );
};

export default React.memo(CatalogGroupCard);
