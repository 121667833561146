import React, { FC, useEffect, useState } from "react";
import { getType } from "components/matomo";
import { PredictionList, PredictionSlider } from "components/prediction";
import { useAppSelector } from "_redux/hooks";
import {
  basketPredictionSelector,
  selectUserActiveOrgId,
} from "_redux/selectors/user";
import { ESpotData } from "types/Marketing";

type Props = {
  data: ESpotData | undefined;
  emsName: string;
  productId?: string;
  sidebar?: boolean;
  categoryId?: string;
};

const PredictiveRecommendation: FC<Props> = ({
  data,
  emsName,
  productId,
  sidebar,
  categoryId,
}) => {
  const activeOrgId = useAppSelector(selectUserActiveOrgId);
  const prediction = useAppSelector(basketPredictionSelector);
  const [lastActiveOrgId, setLastActiveOrgId] = useState<string>();
  const [reloadPredictiveBasket, setReloadPredictiveBasket] = useState(false);

  useEffect(() => {
    if (lastActiveOrgId !== activeOrgId) {
      setLastActiveOrgId(activeOrgId);
      setReloadPredictiveBasket(true);
    } else {
      setReloadPredictiveBasket(false);
    }
  }, [activeOrgId, lastActiveOrgId]);

  if (!data) {
    return null;
  }

  if (emsName === "Offer_Bottom_EMS" && prediction)
    return <PredictionList reloadPredictiveBasket={reloadPredictiveBasket} />;

  return (
    <PredictionSlider
      data={data}
      cluster={!!productId}
      trackType={getType(emsName)}
      sidebar={sidebar}
      categoryId={categoryId}
    />
  );
};

export default PredictiveRecommendation;
