import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import OrderConfirmation from "components/cart/OrderConfirmation";
import { useTranslation } from "react-i18next";

const OrderConfirmationPage: FC = () => {
  const { orderId } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("cart.confirmation.headline")}</title>
      </Helmet>

      {orderId ? <OrderConfirmation orderId={orderId} /> : null}
    </>
  );
};

export default OrderConfirmationPage;
