import { PaletteOptions } from "@mui/material/styles";

const palette: PaletteOptions = {
  primary: {
    light: "#7FC72E",
    main: "#76b82a",
    dark: "#6CA827",
    contrastText: "#fff",
  },
  secondary: {
    light: "#F21713",
    main: "#e41513",
    dark: "#D41411",
  },
  error: {
    main: "#e41513",
  },
  warning: {
    main: "#FFA200",
  },
  success: {
    main: "#76b82a",
  },
  info: {
    main: "#2196f3",
  },
  grey: {
    50: "#f2f3f1", // color-tertiary-200
    100: "#f2f2f2",
    200: "#e4f1d4",
    300: "#e0e3de",
    400: "#e0e0e0",
    500: "#dadada",
    600: "#babcb9", // color-tertiary-300
    700: "#80837c", // color-tertiary-400
    800: "#545850", // color-tertiary-500
    900: "#40443c",
    A100: "#292c25", // color-tertiary-700
    A200: "#20231c", // color-tertiary-900
    A400: "#0a0a0a",
    A700: "#000000",
  },
  text: {
    primary: "#20231c",
    secondary: "#0a0a0a",
    disabled: "#121212",
  },
  background: {
    paper: "#fff",
    default: "#fff",
  },
};

export { palette };
