import React, { FC, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import isInstanaActive from "tools/isInstanaActive";

const InstanaPageEvent: FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (isInstanaActive()) {
      // set page on history change event
      ineum("page", pathname);
    }
  }, [pathname]);

  return <Outlet />;
};

export default InstanaPageEvent;
