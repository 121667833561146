import React, { FC, useMemo } from "react";
import { get } from "lodash-es";

import { StyledDivider } from "components/StyledUI";
import { CustomerProductlist } from "types/CustomerProductlist";
import { Grid, Box } from "@mui/material";
import i18n from "i18next";
import { tss } from "tss-react/mui";
import { ProductListItem } from "../productListItem";
import { ProductGalleryItem } from "../productGallery/productGalleryItem";
import { Product } from "../../types/Product";

const useStyles = tss.create(({ theme }) => ({
  wrapper: {
    margin: theme.spacing(-1.5),
    overflow: "hidden",
  },
}));

interface GroupedProducts {
  [key: string]: Product[];
}

interface ProductListGroupProps {
  products: Product[];
  refetch?: () => void;
  selectedCustomerProductList?: CustomerProductlist | null;
  isAccountListView?: boolean;
  isGalleryView?: boolean;
  editMode?: boolean;
}

function addProductToGroup(product: Product, groupedProducts: GroupedProducts) {
  const rank = get(product, "rank");
  const customerProductlists = get(product, "customerProductlists", []);

  const categoryName =
    rank !== undefined && rank === "-1.0" && customerProductlists.length <= 0
      ? i18n.t("productCatalog.productGroup.additionalAssortment")
      : (get(product, "UserData[0].categoryName") as string | undefined);
  if (!categoryName) {
    return;
  }

  const productGroup = groupedProducts[categoryName];

  if (!productGroup) {
    // eslint-disable-next-line no-param-reassign
    groupedProducts[categoryName] = [product];
  } else {
    productGroup.push(product);
  }
}

function groupProducts(products: Product[]) {
  const groupedProducts: GroupedProducts = {};

  products.forEach((product) => {
    addProductToGroup(product, groupedProducts);
  });

  return groupedProducts;
}

const ProductListGroup: FC<ProductListGroupProps> = ({
  products,
  refetch,
  selectedCustomerProductList,
  isAccountListView,
  isGalleryView,
  editMode,
}) => {
  const { classes } = useStyles();
  const groupedProducts = useMemo(() => groupProducts(products), [products]);

  return (
    <>
      {Object.keys(groupedProducts).map((key) => (
        <div key={key}>
          <StyledDivider text={key} />

          {!isGalleryView &&
            groupedProducts[key].map((product) => (
              <ProductListItem
                key={product.uniqueID}
                product={product}
                refetch={refetch}
                selectedCustomerProductList={selectedCustomerProductList}
                isAccountListView={isAccountListView}
                editMode={editMode}
              />
            ))}
          {isGalleryView && (
            <Box mb={3}>
              <Grid container spacing={3} className={classes.wrapper}>
                <>
                  {groupedProducts[key].map((product) => (
                    <ProductGalleryItem
                      key={product.uniqueID}
                      product={product}
                      refetch={refetch}
                    />
                  ))}
                </>
              </Grid>
            </Box>
          )}
        </div>
      ))}
    </>
  );
};

export default ProductListGroup;
