import React, { FC, useState, useEffect, useLayoutEffect } from "react";

import { Helmet } from "react-helmet-async";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "_redux/hooks";
import {
  loginStatusSelector,
  userNewsletterValidationCodeSelector,
} from "_redux/selectors/user";
import {
  sessionErrorSelector,
  sessionErrors,
  authenticationErrorSelector,
  passwordExpiredErrorSelector,
  genericErrorSelector,
} from "_redux/selectors/error";
import * as userAction from "_redux/actions/user";

import { useSite } from "_foundation/hooks/useSite";
import { sessionStorageUtil } from "_foundationExt/utils/storageUtil";

import { Controller, useForm } from "react-hook-form";

import {
  FINDER_EXCEPTION_ERROR_KEY,
  PERSON_HAS_NO_ROLES_IN_STORE,
} from "constants/errors";

import {
  Typography,
  Box,
  InputAdornment,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";

import {
  StyledNotification,
  StyledFormInput,
  StyledButton,
  StyledLink,
  StyledDivider,
  StyledContainer,
  StyledProgress,
  StyledTooltip,
  StyledFormCheckbox,
} from "components/StyledUI";

import { ROUTES } from "constants/routes";

import { ReactComponent as VisibleIcon } from "assets/icons/view-1.svg";
import { ReactComponent as UnvisibleIcon } from "assets/icons/view-off.svg";
import { mapRefToInputRef } from "tools/convertUtils";
import { RESET_ERROR_ACTION } from "_redux/actions/error";
import { TFunction } from "i18next";
import { ErrorReducerState } from "_redux/reducers";
import getDisplayName from "tools/getDisplayName";
import {
  useAbortControllers,
  useIsAutoLoginAllowed,
} from "_foundationExt/hooks";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  actions: {
    flexDirection: "row-reverse",
    "& .grid-item:first-of-type": {
      textAlign: "right",
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(2),
      },
    },
  },
  spacing: {
    marginBottom: theme.spacing(2),
  },
}));

const translateError = (t: TFunction, error?: ErrorReducerState) => {
  if (!error) {
    return undefined;
  }
  const { errorCode, errorKey, errorParameters, errorMessage } = error;

  if (
    (errorCode && sessionErrors.includes(errorCode)) ||
    (errorKey && sessionErrors.includes(errorKey))
  ) {
    return t("logon.sessionTimeout");
  }
  if (
    errorKey === FINDER_EXCEPTION_ERROR_KEY &&
    errorParameters === "javax.persistence.NoResultException"
  ) {
    return t("logon.cookieProblem");
  }

  return errorMessage;
};

const formDefaultValues = {
  logonId: "",
  logonPassword: "",
  rememberMe: false,
};

const Logon: FC = () => {
  const widgetName = getDisplayName(Logon);
  const { classes } = useStyles();
  const theme = useTheme();
  const breakpointDownXs = useMediaQuery(theme.breakpoints.down("sm"));

  const { currentSite } = useSite();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const redirectPath = queryParams.get("redirectPath") || "";
  const krypto = queryParams.get("krypto");

  const abortControllers = useAbortControllers();

  const dispatch = useAppDispatch();

  const loggedIn = useAppSelector(loginStatusSelector);
  const isAutoLoginAllowed = useIsAutoLoginAllowed();
  const authenticationError = useAppSelector(authenticationErrorSelector);
  const passwordExpiredError = useAppSelector(passwordExpiredErrorSelector);
  const sessionError = useAppSelector(sessionErrorSelector);
  const genericError = useAppSelector(genericErrorSelector);
  const newsletterValidationCode = useAppSelector(
    userNewsletterValidationCodeSelector
  );

  const error = authenticationError || sessionError || genericError;
  const isUserRegisteredInDifferentStore =
    authenticationError?.errorKey === PERSON_HAS_NO_ROLES_IN_STORE;

  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    if (loading && error) {
      setLoading(false);
    }
  }, [loading, error]);

  const {
    register,
    handleSubmit,
    // subscribe to dirtyFields is needed to be able to use keepDirtyValues = true during form rest
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { dirtyFields, errors, isDirty, isValid, touchedFields },
    control,
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: { ...formDefaultValues, rememberMe: isAutoLoginAllowed },
  });
  const [showPassword, setShowPassword] = useState(false);

  useLayoutEffect(() => {
    reset(
      {
        ...formDefaultValues,
        rememberMe: isAutoLoginAllowed,
      },
      { keepDirtyValues: true }
    );
  }, [reset, isAutoLoginAllowed]);

  const onSubmit = handleSubmit((controlledForm) => {
    setLoading(true);
    dispatch(RESET_ERROR_ACTION());
    const { signal } = abortControllers.create();
    const requestPayload = {
      body: {
        ...controlledForm,
      },
      query: { rememberMe: controlledForm.rememberMe },
      widget: widgetName,
      signal,
    };
    dispatch(
      userAction.LOGIN_REQUESTED_ACTION({
        requestPayload,
        navigate,
        newsletterValidationCode,
      })
    );
  });

  useEffect(() => {
    if (currentSite != null && krypto) {
      setLoading(true);
      dispatch(RESET_ERROR_ACTION());
      const { signal } = abortControllers.create();
      const requestPayload = {
        body: {
          krypto,
        },
        widget: widgetName,
        signal,
      };
      dispatch(
        userAction.REDIRECTED_LOGIN_REQUESTED_ACTION({
          requestPayload,
          navigate,
          newsletterValidationCode,
        })
      );
    }
  }, [
    krypto,
    dispatch,
    currentSite,
    newsletterValidationCode,
    navigate,
    abortControllers,
    widgetName,
  ]);

  const tooltipViewIcon = t("iconTooltips.viewIcon");

  if (passwordExpiredError) {
    const form = getValues();
    sessionStorageUtil.set("u", form.logonId);
    sessionStorageUtil.set("p", form.logonPassword);
    return <Navigate to={ROUTES.EXPIRED_PASSWORD} replace />;
  }

  if (loggedIn) {
    if (newsletterValidationCode) {
      return (
        <Navigate
          to={`${ROUTES.NEWSLETTER_ACTIVATE}/${newsletterValidationCode}`}
          replace
        />
      );
    }
    if (redirectPath) {
      return <Navigate to={redirectPath} replace />;
    }
    return <Navigate to={ROUTES.HOME_LOGGEDIN} replace />;
  }

  if (krypto && !error) {
    return (
      <>
        <Helmet>
          <title>{t("logon.title")}</title>
        </Helmet>

        <StyledContainer size="small">
          <Box mb={3}>
            <Typography variant="h1">{t("logon.title")}</Typography>
          </Box>

          <Box mb={3}>
            <StyledNotification severity="info">
              {t("logon.redirected.message")}
            </StyledNotification>
          </Box>

          <StyledProgress />
        </StyledContainer>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t("logon.title")}</title>
      </Helmet>

      <StyledContainer size="small">
        <Box mb={3}>
          <Typography variant="h1">{t("logon.title")}</Typography>
        </Box>

        {error && error.errorMessage && (
          <>
            {isUserRegisteredInDifferentStore && error.errorParameters ? (
              <Box mb={3}>
                <StyledNotification severity="info">
                  {t("logon.wrongShop")}{" "}
                  <StyledLink to={error.errorParameters}>
                    {t("logon.wrongShopLink")}
                  </StyledLink>
                </StyledNotification>
              </Box>
            ) : (
              <Box mb={3}>
                <StyledNotification severity="error">
                  {translateError(t, error)}
                </StyledNotification>
              </Box>
            )}
          </>
        )}

        <Typography paragraph align="right">
          * {t("form.mandatory")}
        </Typography>

        <form
          name="LogonForm"
          id="LogonForm"
          noValidate
          onSubmit={onSubmit}
          autoComplete="off">
          <StyledFormInput
            className={classes.spacing}
            id="logonId"
            label={t("logon.logonId")}
            placeholder={`${t("logon.logonId")}*`}
            fullWidth
            autoFocus
            {...mapRefToInputRef(
              register("logonId", {
                required: t("form.error.required"),
              })
            )}
            error={!!errors.logonId}
            helperText={errors?.logonId?.message?.toString()}
          />

          <StyledFormInput
            id="logonPassword"
            label={t("logon.password")}
            placeholder={`${t("logon.password")}*`}
            type={showPassword ? "text" : "password"}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <StyledTooltip
                    title={tooltipViewIcon}
                    enterDelay={2500}
                    enterNextDelay={2500}>
                    <IconButton
                      tabIndex={-1}
                      edge="end"
                      style={showPassword ? { marginTop: "-2px" } : {}}
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      size="large">
                      {showPassword ? <UnvisibleIcon /> : <VisibleIcon />}
                    </IconButton>
                  </StyledTooltip>
                </InputAdornment>
              ),
            }}
            {...mapRefToInputRef(
              register("logonPassword", {
                required: `${t("form.error.required")}`,
              })
            )}
            error={!!errors.logonPassword}
            helperText={errors.logonPassword && errors.logonPassword.message}
          />

          {isAutoLoginAllowed && (
            <Controller
              name="rememberMe"
              control={control}
              render={({ field: { value, ref, ...rest } }) => (
                <StyledFormCheckbox
                  checkboxProps={{
                    checked: value,
                    value,
                    inputRef: ref,
                    ...rest,
                  }}
                  label={t("logon.rememberMe")}
                />
              )}
            />
          )}

          <StyledDivider light py={3} />

          <Grid container className={classes.actions}>
            <Grid xs={12} sm={6} className="grid-item">
              <StyledButton
                style={{ minWidth: 200 }}
                fullWidth={breakpointDownXs}
                type="submit"
                disabled={loading || (touchedFields && !isDirty) || !isValid}>
                {loading ? (
                  <StyledProgress size={24} color="inherit" />
                ) : (
                  t("logon.logon")
                )}
              </StyledButton>
            </Grid>
            <Grid xs={12} sm={6} className="grid-item">
              <StyledLink to={ROUTES.FORGOT_PASSWORD} small>
                {t("logon.lostPassword")}
              </StyledLink>
            </Grid>
          </Grid>
        </form>
      </StyledContainer>
    </>
  );
};

export default Logon;
