import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "_redux/hooks";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  StyledButton,
  StyledConsentAccordion,
  StyledDialog,
  StyledDialogTitle,
  StyledDialogContent,
  StyledLink,
} from "components/StyledUI";
import { ROUTES } from "constants/routes";
import { SET_TRACKING_CHOICE_ACTION } from "_redux/actions/user";
import { getCookie, setCookie } from "tools/cookieUtils";
import { useBreakpoints } from "_foundationExt/hooks";
import {
  DEFAULT_TRACKING_CHOICE,
  OPTIONS_TRACKING_CHOICE,
} from "constants/user";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  link: {
    zIndex: 1,
  },
  spacing: {
    marginBottom: theme.spacing(1),
  },
  dialog: {
    "& .MuiDialogContent-root": {
      padding: theme.spacing(0, 3, 1),
    },
  },
  button: {
    "& .MuiButton-label": {
      whiteSpace: "unset",
    },
  },
}));

const CookieConsentModal: FC = () => {
  const cookieChoiceValue = getCookie("cookieChoice");
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [showDialog, setShowDialog] = useState<boolean>(true);
  const { sm } = useBreakpoints();

  /**
   * maxAge 31536000 seconds = 365 days
   */
  const saveDialog = (cookieChoice: string) => {
    const cookieToSave = OPTIONS_TRACKING_CHOICE.includes(cookieChoice)
      ? cookieChoice
      : DEFAULT_TRACKING_CHOICE;
    dispatch(SET_TRACKING_CHOICE_ACTION(cookieToSave));
    setCookie("cookieChoice", cookieToSave, 365);
    setShowDialog(false);
  };

  return (
    <>
      {!cookieChoiceValue && (
        <StyledDialog open={showDialog} className={classes.dialog}>
          <StyledDialogTitle id="dialog-title">
            {t("cookieConsent.dialogTitle")}
          </StyledDialogTitle>

          <StyledDialogContent>
            <Typography variant="body2">
              {t("cookieConsent.bodyText")}
            </Typography>

            <Box mt={2}>
              <StyledButton
                fullWidth
                className={classes.button}
                onClick={() => saveDialog("1")}
                size={sm ? "medium" : "small"}>
                {t("cookieConsent.acceptAll")}
              </StyledButton>
              <StyledButton
                variant="outlined"
                fullWidth
                className={classes.button}
                size={sm ? "medium" : "small"}
                onClick={() => saveDialog("0")}>
                {t("cookieConsent.acceptOnlyNecessary")}
              </StyledButton>
            </Box>

            <Box mt={2}>
              <Typography variant="body2">
                {t("cookieConsent.acceptOnlyNecessaryHint")}
              </Typography>
            </Box>

            <Box mt={2}>
              <Grid container spacing={2} alignItems="flex-end" direction="row">
                <Grid className={classes.link}>
                  <StyledLink to={ROUTES.PRIVACY} small>
                    {t("cookieConsent.privacyLinkLabel")}
                  </StyledLink>
                </Grid>

                <Grid className={classes.link}>
                  <StyledLink to={ROUTES.ABOUT} small>
                    {t("cookieConsent.legalNoticeLabel")}
                  </StyledLink>
                </Grid>

                <Grid>
                  <StyledConsentAccordion
                    small
                    headline={t("cookieConsent.moreInfoLabel")}>
                    <Box>
                      <Typography variant="h6" className={classes.spacing}>
                        {t("cookieConsent.moreInfoContent1Title")}
                      </Typography>
                      <Typography variant="body2" className={classes.spacing}>
                        {t("cookieConsent.moreInfoContent1")}
                      </Typography>

                      <Typography variant="h6" className={classes.spacing}>
                        {t("cookieConsent.moreInfoContent2Title")}
                      </Typography>
                      <Typography variant="body2" className={classes.spacing}>
                        {t("cookieConsent.moreInfoContent2")}
                      </Typography>

                      <Typography variant="h6" className={classes.spacing}>
                        {t("cookieConsent.moreInfoContent3Title")}
                      </Typography>
                      <Typography variant="body2" className={classes.spacing}>
                        {t("cookieConsent.moreInfoContent3")}
                      </Typography>
                    </Box>
                  </StyledConsentAccordion>
                </Grid>
              </Grid>
            </Box>
          </StyledDialogContent>
        </StyledDialog>
      )}
    </>
  );
};
export default CookieConsentModal;
