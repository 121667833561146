/* eslint-disable indent */
// react
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ROUTES } from "constants/routes";
// mui
import {
  StyledDivider,
  StyledKeyVisual,
  StyledButton,
  StyledProgress,
} from "components/StyledUI";
import { Typography, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

// _foundation
import { useSite } from "_foundationExt/hooks/useSite";
import orderServiceExt from "_foundationExt/apis/transaction/order.service";
import { OrderConfirmationResponseData } from "@efood-commerce-sdk/typescript-axios-transaction-efood";
// component
import introImage from "assets/images/my-account-intro.png";
import { format } from "date-fns";
import { de } from "date-fns/locale/de";
import ESpot from "components/widgets/espot/ESpot";
import { useNavigate } from "react-router-dom";
import { useAbortControllers } from "_foundationExt/hooks";
import useUserName from "pages/home/useUserName";
import { tss } from "tss-react/mui";
import { isCanceledError } from "_foundationExt/axios/axiosConfig";

interface OrderConfirmationProps {
  orderId: string;
}

const useStyles = tss.create(({ theme }) => ({
  button: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  mr: {
    marginRight: "10%",
  },
  mb: {
    marginBottom: theme.spacing(3),
  },
}));

const OrderConfirmation: FC<OrderConfirmationProps> = ({ orderId }) => {
  const { t } = useTranslation();
  const { currentSite } = useSite();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const abortControllers = useAbortControllers();
  const [loading, setLoading] = React.useState(true);

  const formatDateLong = (date: string) => {
    const result = format(new Date(date), "EEEE, 'den' dd. MMMM yyyy", {
      locale: de,
    });
    return result;
  };

  const { firstName, lastName } = useUserName();
  const [details, setDetails] = useState<OrderConfirmationResponseData>();

  useEffect(() => {
    if (currentSite?.storeID != null) {
      const { signal } = abortControllers.create();
      const fetchData = async () => {
        try {
          const response = await orderServiceExt.getOrderConfirmation({
            storeId: currentSite?.storeID,
            orderId,
            signal,
          });

          setDetails(response.data);
        } catch (error) {
          if (!isCanceledError(error)) {
            throw error;
          }
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [orderId, currentSite?.storeID, abortControllers]);

  if (loading) {
    return (
      <Grid container justifyContent="center">
        <StyledProgress />
      </Grid>
    );
  }

  if (!details?.baskets?.length) {
    return (
      <>
        <ESpot emsName="basket_catentry_recommendation_checkout_2" />
        <Grid container>
          <Grid xs={12}>
            <StyledKeyVisual
              useBackground
              imageSrc={introImage}
              imageAlt="cutting herbs with a knife">
              <Typography>{t("cart.confirmation.notfound")}</Typography>
            </StyledKeyVisual>
          </Grid>
        </Grid>
        <ESpot emsName="basket_catentry_recommendation_checkout_2_bottom" />
      </>
    );
  }

  const shippingAddress = details.shippingDetails ?? {};
  const billingAddress = details.billingDetails ?? shippingAddress;

  return (
    <>
      <ESpot emsName="basket_catentry_recommendation_checkout_2" />

      <Grid container>
        <Grid xs={12}>
          <StyledKeyVisual
            useBackground
            imageSrc={introImage}
            imageAlt="cutting herbs with a knife">
            <Typography>
              {t("cart.confirmation.image")}
              <br />
              {`${firstName} ${lastName}!`}
            </Typography>
          </StyledKeyVisual>
        </Grid>
        <Grid xs={12}>
          <Box mb={2}>
            <Typography variant="h1">
              {t("cart.confirmation.headline")}
            </Typography>
          </Box>
        </Grid>
        <Grid container xs={12}>
          <Grid container xs={12} sm={12} md={6} className={classes.mb}>
            <Grid xs={12}>
              <Typography variant="h3">
                {t("cart.confirmation.info")}
              </Typography>
            </Grid>
            {details.baskets.map((basket) => (
              <Grid container xs={12} key={basket.orderNumber}>
                {basket.name && (
                  <Grid xs={12}>
                    <Box mt={1}>
                      <Typography>{basket.name}</Typography>
                    </Box>
                  </Grid>
                )}
                <Grid xs={6} sm={6} md={5}>
                  <Typography>{t("cart.orderNumber")}:</Typography>
                  <Typography>{t("cart.deliveryOn")}:</Typography>
                </Grid>
                <Grid xs={6} sm={6} md={7}>
                  <Typography>{basket.orderNumber}</Typography>
                  <Typography>{formatDateLong(basket.shippingDate)}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid container xs={12} sm={12} md={6}>
            <Grid xs={6}>
              <Grid xs={12}>
                <Box mb={1}>
                  <Typography variant="h3">
                    {t("cart.confirmation.shippingAddress")}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} className={classes.mr}>
                <Typography>{shippingAddress.address1}</Typography>
                <Typography>{shippingAddress.address2}</Typography>
                <Typography>{shippingAddress.address3}</Typography>
                <Typography>
                  {`${shippingAddress.zipcode} ${shippingAddress.city}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={6}>
              <Grid xs={12}>
                <Box mb={1}>
                  <Typography variant="h3">
                    {t("cart.confirmation.billingAddress")}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12}>
                <Typography>{billingAddress.address1}</Typography>
                <Typography>{billingAddress.address2}</Typography>
                <Typography>{billingAddress.address3}</Typography>
                <Typography>
                  {`${billingAddress.zipcode} ${billingAddress.city}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Box mt={2}>
            <Typography>{t("cart.confirmation.conditions")}</Typography>
          </Box>
        </Grid>
        <Grid xs={12}>
          <StyledDivider light />
        </Grid>
        <Grid xs={12}>
          <StyledButton
            variant="outlined"
            className={classes.button}
            onClick={() => navigate(ROUTES.ACCOUNT_REPORTS)}>
            {t("cart.myhistory")}
          </StyledButton>
          <StyledButton
            style={{ float: "right" }}
            className={classes.button}
            onClick={() => navigate(ROUTES.HOME)}>
            {t("home.toHome")}
          </StyledButton>
        </Grid>
      </Grid>

      <ESpot emsName="basket_catentry_recommendation_checkout_2_bottom" />
    </>
  );
};

export default OrderConfirmation;
