/* eslint-disable indent */
import React, { FC, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

import { CategoriesLookupEntry, CategoriesLookupMap } from "types/Search";

import { useAppSelector } from "_redux/hooks";
import {
  categoryTopSelector,
  makeSelectTopCategoryByChildCategoryId,
} from "_redux/selectors/catalog";
import { StyledAccordion, StyledLink } from "components/StyledUI";
import { useLocation, useParams } from "react-router-dom";
import { CatalogGroupView, RootReducerState } from "_redux/reducers";
import { StyledSkeletonCategoriesAccordion } from "components/StyledUI/StyledSkeleton";
import { tss } from "tss-react/mui";
import CategoriesAccordion from "./CategoriesAccordion";
import { buildAllCategoriesLink } from "./utils";
import { type LoadingState } from "./useProductList";

interface CategoriesAccordionWrapperProps {
  searchTerm?: string | null;
  isAccountListView?: boolean;
  categoriesLookup: CategoriesLookupMap;
  loadingState?: LoadingState;
}

export interface CategoryTree {
  name: string;
  categoryId: string;
  subCategories: CategoryTree[];
  bold: boolean;
  underline: boolean;
  expand: boolean;
}

let selectedCategory = "";

const createCategoryWithMetadata = (
  isBold: boolean,
  isCategoryDisplay: boolean,
  category: CatalogGroupView,
  metaData?: CategoriesLookupEntry
): CategoryTree | undefined => {
  if (metaData) {
    return {
      ...metaData,
      name: category.name,
      categoryId: category.uniqueID,
      subCategories: [],
      bold: isBold || metaData.bold,
    };
  }
  if (isCategoryDisplay) {
    return {
      name: category.name,
      categoryId: category.uniqueID,
      underline: false,
      subCategories: [],
      expand: false,
      bold: isBold,
    };
  }

  return undefined;
};

const filterCatalogGroupView = (
  catalogGroupView: CatalogGroupView | CatalogGroupView[],
  categoriesLookup: CategoriesLookupMap,
  isCategoryDisplay: boolean,
  isBold = false
) => {
  const catalogGroupViewArray = Array.isArray(catalogGroupView)
    ? catalogGroupView
    : [catalogGroupView];

  return catalogGroupViewArray.reduce((accumulator, currentCatalogGroup) => {
    const metaData = categoriesLookup.get(currentCatalogGroup.uniqueID);
    const categoryWithMetadata = createCategoryWithMetadata(
      isBold,
      isCategoryDisplay,
      currentCatalogGroup,
      metaData
    );

    if (categoryWithMetadata) {
      if (currentCatalogGroup.catalogGroupView?.length) {
        categoryWithMetadata.subCategories = filterCatalogGroupView(
          currentCatalogGroup.catalogGroupView,
          categoriesLookup,
          isCategoryDisplay,
          metaData?.underline || isBold
        );
      }
      accumulator.push(categoryWithMetadata);
    }

    if (categoryWithMetadata?.underline) {
      selectedCategory = currentCatalogGroup.name;
    }

    return accumulator;
  }, [] as CategoryTree[]);
};

const useStyles = tss.create(({ theme }) => ({
  wrapper: {
    background: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius * 2,
    padding: `${theme.spacing(0.25)} ${theme.spacing(3)}`,
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },

    "& > .MuiAccordion-root > .MuiAccordionSummary-root": {
      height: "48px",
    },
  },
  categoriesWrapper: {
    width: "100%",
  },
  styleLink: {
    margin: "8px 0",
  },
}));

const CategoriesAccordionWrapper: FC<CategoriesAccordionWrapperProps> = ({
  searchTerm,
  isAccountListView,
  categoriesLookup,
  loadingState,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const matchesXsAndSmViewport = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const location = useLocation();

  const { categoryId } = useParams();
  const isCategoryDisplay = !!categoryId;

  const queryString = new URLSearchParams(location.search);
  const categoryIdFromQueryParam = queryString.get("categoryId");

  const selectTopCategoryByChildCategoryId = useMemo(
    () => makeSelectTopCategoryByChildCategoryId(categoryId),
    [categoryId]
  );

  const categorySelector: (
    state: RootReducerState
  ) => CatalogGroupView | CatalogGroupView[] | undefined = isCategoryDisplay
    ? selectTopCategoryByChildCategoryId
    : categoryTopSelector;
  const categories = useAppSelector(categorySelector);

  const categoryTree = useMemo(() => {
    selectedCategory = "";
    return filterCatalogGroupView(
      categories ?? [],
      categoriesLookup,
      isCategoryDisplay,
      !isCategoryDisplay && !categoryIdFromQueryParam
    );
  }, [
    categories,
    categoriesLookup,
    categoryIdFromQueryParam,
    isCategoryDisplay,
  ]);

  if (loadingState === "loading")
    return (
      <div className={classes.wrapper}>
        <StyledSkeletonCategoriesAccordion />
      </div>
    );

  if (!categories || !categoriesLookup.size) return null;
  return (
    <div className={classes.wrapper}>
      <StyledAccordion
        headline={t("productListing.category")}
        additionalHeadline={selectedCategory}
        expanded={!matchesXsAndSmViewport}
        noBorder
        large>
        <div className={classes.categoriesWrapper}>
          {categoryId || categoryIdFromQueryParam ? (
            <StyledLink
              className={classes.styleLink}
              to={buildAllCategoriesLink(location, categoryId, searchTerm)}
              navigation>
              {t("productListing.allCategories")}
            </StyledLink>
          ) : null}
          <CategoriesAccordion
            categories={categoryTree}
            isAccountListView={isAccountListView}
            searchTerm={searchTerm}
          />
        </div>
      </StyledAccordion>
    </div>
  );
};

export default CategoriesAccordionWrapper;
