import React, { FC } from "react";

import Dialog, { DialogProps } from "@mui/material/Dialog";
import { hexToRgba } from "tools/convertUtils";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  root: {
    "& .MuiBackdrop-root": {
      backgroundColor: hexToRgba(theme.palette.grey.A200, 0.2),
    },
  },
  paper: {
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: "none",
    maxWidth: "480px",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
}));

const StyledDialog: FC<DialogProps> = (props: DialogProps) => {
  const { classes } = useStyles();

  return <Dialog classes={classes} {...props} />;
};

export default StyledDialog;
