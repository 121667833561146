import React, {
  FC,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  StyledDialog,
  StyledDialogContent,
  StyledProgress,
} from "components/StyledUI";
import { QuickSearchStateParam } from "components/header/search/QuickSearch";
import { t } from "i18next";
import { useWindowSize } from "usehooks-ts";
import { tss } from "tss-react/mui";
import BcScanner, {
  type BCSannerProps,
  type CameraViewDimension,
} from "./BcScanner";
import BcScannerHelpDialog from "./BcScannerHelpDialog";
import BcProductSearchItem from "./BcProductSearchItem";

interface Props {
  closeDialog: () => void;
  showScannerDialog: boolean;
  bcScannerResult: (string) => void;
  params: QuickSearchStateParam;
  handlePopoverClick: () => void;
}

type WindowSize = ReturnType<typeof useWindowSize>;

// height of Header (124px) + ResultDialog (170px) + place to bottom (10px)
// is used to dynamical set the Height of the CameraView window
const sizeOfOtherComponents = 115 + 170 + 40;

const useStyles = tss
  .withParams<{ windowSize: WindowSize }>()
  .create(({ theme, windowSize }) => ({
    dialog: {
      "& .MuiPaper-root": {
        width: "100%",
        height: "auto",
        maxHeight: "none",
        maxWidth: "639px",
        backgroundColor: "#000000",
        borderRadius: "0",
        top: "115px",
        position: "absolute",
      },
      "& .MuiDialogContent-root": {
        padding: theme.spacing(0, 0, 0),
      },
      "& .MuiBackdrop-root": {
        backdropFilter: "blur(4px)",
      },
    },
    productResultBox: {
      backgroundColor: "#ffffff",
      display: "inline-box",
      padding: "25px 20px",
    },
    searchProgress: {
      width: "100%",
      backgroundColor: "#ffffff",
      display: "inline-box",
      padding: "25px 20px",
      height: "170px",
      "& .MuiGrid-container:hover": {
        background: "#ffffff !important",
      },
    },
    cameraView: {
      height: windowSize.height - sizeOfOtherComponents,
      "& video": {
        height: windowSize.height - sizeOfOtherComponents,
      },
    },
  }));

const BcScannerDialog: FC<Props> = ({
  closeDialog,
  showScannerDialog,
  bcScannerResult,
  params,
  handlePopoverClick,
}) => {
  const [showHelpDialog, setShowHelpDialog] = useState(false);
  const [showResultDialog, setShowResultDialog] = useState(false);
  const [multipleScanCounter, setMultipleScanCounter] = useState(0);
  const windowSize = useWindowSize();
  const blockMultipleScannerSearch = useRef(false);
  const prevEFoodPartnumber = useRef("");

  const { classes } = useStyles({ windowSize });

  const [cameraViewDimensions, setCameraViewDimensions] =
    useState<CameraViewDimension>({
      width: 0,
      height: 0,
    });

  const handleClose = useCallback(() => {
    setShowResultDialog(false);
    setShowHelpDialog(false);
    closeDialog();
  }, [closeDialog]);

  const handleProductClick = () => {
    handlePopoverClick();
    handleClose();
  };

  const handleShowHelpDialog = useCallback(() => {
    setShowHelpDialog(true);
  }, []);

  const showResult: BCSannerProps["onResult"] = useCallback(
    (res) => {
      setShowResultDialog(true);

      if (!blockMultipleScannerSearch.current) {
        blockMultipleScannerSearch.current = true;

        setTimeout(() => {
          blockMultipleScannerSearch.current = false;
        }, 3000);

        const eFoodPartnumber = res;

        if (prevEFoodPartnumber.current !== eFoodPartnumber) {
          bcScannerResult(res);
          prevEFoodPartnumber.current = eFoodPartnumber;
          setMultipleScanCounter(0);
        } else {
          setMultipleScanCounter((oldCounter) => oldCounter + 1);
        }
      }
    },
    [bcScannerResult]
  );

  useLayoutEffect(() => {
    const { height, width } = windowSize;
    const calculatedHeight = height - sizeOfOtherComponents;
    setCameraViewDimensions({
      height: calculatedHeight,
      width,
    });
  }, [windowSize]);

  return (
    <>
      <BcScannerHelpDialog
        closeDialog={() => setShowHelpDialog(false)}
        showDialog={showHelpDialog}
      />
      <StyledDialog
        open={showScannerDialog}
        onClose={handleClose}
        className={classes.dialog}>
        <div>
          <StyledDialogContent>
            <Box className={classes.cameraView}>
              <BcScanner
                onResult={showResult}
                cameraViewDimension={cameraViewDimensions}
                onClose={handleClose}
                showHelpDialog={handleShowHelpDialog}
              />
            </Box>
            {showResultDialog && (
              <Box className={classes.searchProgress}>
                {params.loading && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <Grid>
                      <StyledProgress />
                    </Grid>
                  </Grid>
                )}
                {params.error && (
                  <Box mb={3} display="flex">
                    <Typography>{t("search.error.generalError")}</Typography>
                  </Box>
                )}
                {!params.loading && params.recordTotal === 0 && (
                  <Box mb={3} display="flex">
                    <Typography>{t("search.productNotFound")}</Typography>
                  </Box>
                )}
                {params.recordTotal > 0 && (
                  <Box mr={2}>
                    <BcProductSearchItem
                      key={`BcSearchItem_${params.products[0].uniqueID}`}
                      product={params.products[0]}
                      onClick={handleProductClick}
                      multipleScanCounter={multipleScanCounter}
                    />
                  </Box>
                )}
              </Box>
            )}
          </StyledDialogContent>
        </div>
      </StyledDialog>
    </>
  );
};

export default BcScannerDialog;
