import { eFoodDB } from "pwa/db/efood.db";

import isInstanaActive from "tools/isInstanaActive";
import handleProductDetails from "./handleProductDetails";
import productsSyncTimestamp from "./productsSyncTimestamp";

const saveProductDetails = async ({
  paginated,
  timestamp,
  products,
}: {
  paginated: boolean;
  timestamp: unknown;
  products?: unknown[];
}) => {
  if (products?.length) {
    const { productsWithIndices, deletedPartNumbers } =
      await handleProductDetails(products, timestamp);

    if (deletedPartNumbers.length) {
      await eFoodDB.products
        .where("partNumber")
        .anyOf(deletedPartNumbers)
        .delete();
    }

    await eFoodDB.products.bulkPut(productsWithIndices);
  }

  if (!paginated) {
    await productsSyncTimestamp.put(timestamp);

    if (isInstanaActive()) {
      ineum("reportEvent", "sync products setTimestamp", {
        timestamp: Date.now(),
        meta: {
          paginated: false,
          productsSyncTimestamp: timestamp as string | number | boolean,
        },
      });
    }
  }
};

export default saveProductDetails;
