import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ReactComponent as LeftIcon } from "assets/icons/arrow-left-1.svg";
import { ReactComponent as RightIcon } from "assets/icons/arrow-right-1.svg";
import { StyledButton } from "components/StyledUI";
import { TrackProduct, TrackShowType } from "components/matomo";
import { ESpotData } from "types/Marketing";
import { tss } from "tss-react/mui";
import ProductCard from "./ProductCard";
import ContentCard from "./ContentCard";
import TitleCard from "./TitleCard";

const useStyles = tss.create(({ theme }) => ({
  productSlide: {
    paddingTop: theme.spacing(2),

    "& > div": {
      borderRight: `1px dashed ${theme.palette.grey[300]}`,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    "&:last-of-type > div": {
      borderRight: 0,
    },
  },
  arrows: {
    position: "absolute",
    zIndex: 1,
    "&.MuiButton-root:focus": {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[800],
    },
    "&.MuiButton-root:hover": {
      backgroundColor: theme.palette.primary.light,
      color: "#fff",
    },
  },
  left: {
    top: "12px",
    right: "56px",
  },
  right: {
    top: "12px",
    right: "12px",
  },
  leftSidebar: {
    top: "18px",
    right: "85px",
  },
  rigthSidebar: {
    top: "18px",
    right: "41px",
  },
  sidebar: {
    border: `2px solid ${theme.palette.grey[50]}`,
    borderRadius: theme.shape.borderRadius * 2,
    [theme.breakpoints.up("lg")]: {
      maxWidth: `calc(100% - ${theme.spacing(3)})`,
      marginRight: theme.spacing(3),
    },
    "& figure": {
      maxWidth: "100%",
    },
    "& > div > div": {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(3),
    },
  },
  title: {
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
  },
  titleScrollButtonSpacing: {
    marginRight: theme.spacing(14), // buttons should not overlap title
  },
}));

interface ProductSliderProps {
  id: string;
  data: ESpotData;
  trackType?: TrackShowType;
  headline?: string;
  sidebar?: boolean;
  sidebarBottom?: boolean;
}

const ProductSlider: React.FC<ProductSliderProps> = ({
  id,
  data,
  trackType,
  headline,
  sidebar,
  sidebarBottom,
}) => {
  const [index, setIndex] = React.useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const { classes, cx } = useStyles();
  const [cols, setCols] = useState(4);

  const { products } = data;
  const calculateCols = useCallback(() => {
    const newWidth = ref.current?.clientWidth ?? 0;

    let newCols: number;
    if (newWidth > 1000) newCols = 4;
    else if (newWidth > 750) newCols = 3;
    else if (newWidth > 500) newCols = 2;
    else newCols = 1;

    setCols(newCols);
  }, []);

  useLayoutEffect(() => {
    if (ref.current?.clientWidth) {
      setCols(Math.floor(ref.current.clientWidth / 290));
    }
    window.addEventListener("resize", calculateCols);
    return () => {
      window.removeEventListener("resize", calculateCols);
    };
  }, [calculateCols]);

  const forward = useCallback(() => {
    setIndex((oldIndex) => (oldIndex < products.length - 1 ? oldIndex + 1 : 0));
  }, [products.length]);
  const backward = useCallback(() => {
    setIndex((oldIndex) => (oldIndex > 0 ? oldIndex - 1 : products.length - 1));
  }, [products.length]);

  const displayedProducts: React.ReactElement[] = [];

  const calcIndex = (i: number) => {
    let ci = index + i;
    if (ci > products.length - 1) ci -= products.length;
    return ci;
  };

  const calcRows = () => {
    let r = cols;
    if (r < 1) r = 1;
    return r;
  };

  const green = () => {
    if (
      data.contents &&
      data.contents.length > 0 &&
      data.type.includes("MarketingContent") &&
      cols > 1
    )
      return 1;
    return 0;
  };

  for (let i = 0; i < calcRows() - green() && i < products.length; i++) {
    displayedProducts.push(
      <Grid
        xs
        key={`espot_${id}_product_${products[calcIndex(i)].uniqueID}`}
        style={
          products.length < calcRows()
            ? { maxWidth: `${100 / calcRows()}%` }
            : {}
        }
        className={classes.productSlide}>
        <TrackProduct
          trackType={trackType}
          productId={products[calcIndex(i)].eFoodPartnumber}>
          <ProductCard product={products[calcIndex(i)]} trackType={trackType} />
        </TrackProduct>
      </Grid>
    );
  }

  const needsScrollButtons =
    products && products.length > 1 && products.length > calcRows() - green();

  return (
    <Grid
      container={cols !== 1}
      spacing={2}
      xs
      ref={ref}
      className={cx({
        [classes.sidebar]: (sidebar || sidebarBottom) && cols === 1,
      })}
      data-track-content={data.contentTracking?.content || undefined}
      data-content-name={data.contentTracking?.name || undefined}
      data-content-piece={data.type.join(",") || undefined}>
      {((data.titles && data.titles.length > 0) || headline) && (
        <TitleCard
          headline={headline}
          data={data}
          className={cx(
            { [classes.titleScrollButtonSpacing]: needsScrollButtons },
            classes.title
          )}
        />
      )}

      {needsScrollButtons && (
        <>
          <StyledButton
            startIcon={<LeftIcon />}
            size="small"
            color="inherit"
            className={cx(
              { [classes.leftSidebar]: sidebar, [classes.left]: !sidebar },
              classes.arrows
            )}
            onClick={backward}
          />
          <StyledButton
            startIcon={<RightIcon />}
            size="small"
            color="inherit"
            className={cx(
              { [classes.rigthSidebar]: sidebar, [classes.right]: !sidebar },
              classes.arrows
            )}
            onClick={forward}
          />
        </>
      )}
      {green() > 0 && (
        <Box style={{ width: `${100 / calcRows()}%` }}>
          <ContentCard data={data} />
        </Box>
      )}
      {displayedProducts && displayedProducts.map((product) => product)}
    </Grid>
  );
};

export default ProductSlider;
