import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { Approval, Basket, SubBasket } from "types/Order";
import { ARTICLE_TYPE } from "constants/product";
import { BASKET_TYPE } from "constants/order";
import { useEFoodConfig } from "_foundationExt/hooks";
import {
  StyledAccordion,
  StyledDivider,
  StyledNotification,
  StyledProtectable,
} from "components/StyledUI";
import {
  DeliveryDateBox,
  DeliveryDateBoxTPD,
} from "components/widgets/deliveryDateBox";
import { isEqual } from "lodash-es";
import {
  getDeliveryDateDate,
  isEqual as isDateEqual,
} from "tools/delivery-date-helper";
import { tss } from "tss-react/mui";
import { useAppSelector } from "_redux/hooks";
import { userStockDeliveryDateDate } from "_redux/selectors/user";
import OrderComment from "./OrderComment";
import CartSubBasketItem from "./CartSubBasketItem";
import CartSubBasketPriceSummary from "./CartSubBasketPriceSummary";

const useStyles = tss.create(({ theme }) => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(1),
    },
    "& > .grid-item": {
      display: "flex",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
      },
      "& > .MuiBox-root": {
        alignSelf: "center",
      },
    },
    "& > .Mui-expanded": {
      marginTop: "0px",
    },
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  warning: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
    "& .MuiAlert-standardWarning": {
      minHeight: "44px",
    },
    "&  .MuiAlert-icon": {
      padding: "10px 12px",
    },
  },
}));

interface Props {
  orderId: string;
  basket: Basket;
  approval: Approval | null;
}

const hideDatePicker = (
  basketType: string,
  basket: Basket,
  subBasket: SubBasket
) =>
  BASKET_TYPE.STOCK_OPTAIN === basketType &&
  ARTICLE_TYPE.OPTAIN === subBasket.articleType &&
  basket.subBaskets.some((item) => item.articleType === ARTICLE_TYPE.STOCK);

const CartSubBaskets: FC<Props> = ({ orderId, basket, approval }) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const { dropShipping } = useEFoodConfig();
  const stockDeliveryDateDate = useAppSelector(userStockDeliveryDateDate);
  const { type: basketType, dropShipType, deliveryDate } = basket.identifier;
  const deliveryDateDate = getDeliveryDateDate(deliveryDate);

  const dropShipConfig = useMemo(
    () => dropShipping.find((dropShip) => dropShip.type === dropShipType),
    [dropShipping, dropShipType]
  );

  const dropShipTitle = dropShipConfig?.title?.trim();

  return (
    <>
      {basket.subBaskets.map((subBasket, index) => {
        if (!subBasket.orderitems?.length) {
          return null;
        }

        const rootKey = `${basketType}_${dropShipType}_${
          deliveryDateDate?.getTime() ?? ""
        }_${subBasket.articleType}`;

        const { orderitems, ...subBasketWithoutOrderItems } = subBasket;

        return (
          <Box className={classes.root} key={rootKey}>
            {index === 0 && (
              <Grid className="grid-item">
                {BASKET_TYPE.OPTAIN === basketType && basket.validation ? (
                  <Box
                    className={classes.warning}
                    key={`validation_${rootKey}`}>
                    <StyledNotification
                      severity={
                        basket.validation.type === "DENIAL"
                          ? "error"
                          : "warning"
                      }>
                      {t("cart.invalidOptainBasket")}
                    </StyledNotification>
                  </Box>
                ) : null}
                {approval ? (
                  <Box className={classes.warning} key={`approval_${rootKey}`}>
                    <StyledNotification severity="warning">
                      {approval.approvalMessage}
                    </StyledNotification>
                  </Box>
                ) : null}
              </Grid>
            )}

            <StyledAccordion
              headline={
                dropShipTitle ||
                t(`cart.articleType.${subBasket.articleType.toLowerCase()}`)
              }
              hideDatePicker={hideDatePicker(basketType, basket, subBasket)}
              datePickerCaption={
                <Typography variant="body1">
                  {`${
                    BASKET_TYPE.OPTAIN === basketType
                      ? t("cart.deliveryFrom")
                      : t("cart.deliveryOn")
                  }:`}
                </Typography>
              }
              datePicker={
                dropShipType != null && dropShipConfig != null ? (
                  <DeliveryDateBoxTPD
                    dropShipConfig={dropShipConfig}
                    orderId={orderId}
                    articleType={basketType}
                    deliveryDate={deliveryDate ? deliveryDate.date : null}
                    dropShipType={dropShipType}
                  />
                ) : (
                  <DeliveryDateBox
                    orderId={orderId}
                    basket={
                      BASKET_TYPE.STOCK_OPTAIN === basketType &&
                      (basket.earliestDeliveryDate == null ||
                        isDateEqual(
                          basket.earliestDeliveryDate.date,
                          stockDeliveryDateDate
                        ))
                        ? null
                        : basket
                    }
                  />
                )
              }
              noBorder
              expanded>
              <Grid
                container
                xs
                key={`orderitems_${rootKey}`}
                className="grid-item">
                <Grid xs={12} className="grid-item">
                  <StyledDivider light pb={0} pt={0} />
                </Grid>
                <Grid xs={12} className={cx("grid-item", classes.mt2)}>
                  {orderitems.map((orderItem) => (
                    <CartSubBasketItem
                      key={`orderitem_${orderItem.orderitemId}`}
                      orderItem={orderItem}
                    />
                  ))}
                </Grid>
                <Grid xs={12} className="grid-item">
                  <StyledDivider light />
                </Grid>
                <Grid container xs={12} className="grid-item">
                  <Grid xs={4} sm={6} md={8} className="grid-item">
                    <OrderComment
                      required={
                        dropShipConfig ? dropShipConfig.commentRequired : false
                      }
                      text={
                        dropShipConfig ? dropShipConfig.name : t("cart.comment")
                      }
                      basket={basket}
                    />
                  </Grid>
                  <Grid container xs className="grid-item">
                    <StyledProtectable permission="show.price">
                      <CartSubBasketPriceSummary
                        dropShipConfig={dropShipConfig}
                        {...subBasketWithoutOrderItems}
                      />
                    </StyledProtectable>
                  </Grid>
                  <Grid xs={12} className="grid-item">
                    <StyledDivider light pb={0} />
                  </Grid>
                </Grid>
              </Grid>
            </StyledAccordion>
          </Box>
        );
      })}
    </>
  );
};

export default React.memo(CartSubBaskets, isEqual);
