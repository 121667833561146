// react
import React, { FC } from "react";
// redux
import { useAppSelector } from "_redux/hooks";
import {
  orderApprovalsSelector,
  selectAllBaskets,
} from "_redux/selectors/order";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import { isEqual } from "lodash-es";
// type
import { BASKET_TYPE } from "constants/order";
// components
import { getDeliveryDateDate } from "tools/delivery-date-helper";
import CartSubBaskets from "./CartSubBaskets";

const CartBaskets: FC<{ orderId: string }> = ({ orderId }) => {
  const approvals = useAppSelector(orderApprovalsSelector, isEqual);
  const baskets = useAppSelector(selectAllBaskets, isEqual);

  return (
    <Grid container xs={12} className="grid-item">
      {baskets.map((basket) => {
        const {
          identifier: { type, dropShipType, deliveryDate },
        } = basket;
        const deliveryDateDate = getDeliveryDateDate(deliveryDate);
        const basketApproval =
          approvals.find((approval) => {
            const { basketIdentifier } = approval;

            if (
              basketIdentifier.type !== BASKET_TYPE.OPTAIN ||
              !deliveryDateDate
            ) {
              return false;
            }

            const approvalDeliveryDateDate = getDeliveryDateDate(
              basketIdentifier.deliveryDate
            );

            return (
              approvalDeliveryDateDate &&
              approvalDeliveryDateDate.getTime() === deliveryDateDate.getTime()
            );
          }) ?? null;

        return (
          <CartSubBaskets
            key={`${type}_${dropShipType}_${
              deliveryDateDate ? deliveryDateDate.getTime() : 0
            }`}
            orderId={orderId}
            basket={basket}
            approval={basketApproval}
          />
        );
      })}
    </Grid>
  );
};

export default CartBaskets;
