import { takeLatest } from "redux-saga/effects";
import { INIT_SITE_SUCCESS } from "_redux/action-types/site";
import { FETCH_CONTRACTS } from "_redux/action-types/user";
import * as WORKERS from "../workers/catalog";

export function* watchSaga() {
  yield takeLatest(
    [INIT_SITE_SUCCESS, FETCH_CONTRACTS],
    WORKERS.loadTopCategories
  );
}
