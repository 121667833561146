import React, { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { StyledButton } from "components/StyledUI";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    margin: 0,
    padding: theme.spacing(3),
    "& .MuiTypography-root": {
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        fontSize: "25px",
      },
    },
  },
}));

interface StyledDialogTitleProps {
  id: string;
  onClose?: () => void;
}

const StyledDialogTitle: FC<PropsWithChildren<StyledDialogTitleProps>> = ({
  children,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <DialogTitle classes={classes} {...rest}>
      <Typography variant="h1" component="div">
        {children}
      </Typography>

      {onClose ? (
        <StyledButton
          startIcon={<CloseIcon />}
          aria-label="close"
          size="small"
          color="inherit"
          onClick={onClose}
          tooltip={t("iconTooltips.closeIcon")}
        />
      ) : null}
    </DialogTitle>
  );
};

export default React.memo(StyledDialogTitle);
