import { call, put, select } from "redux-saga/effects";
import { PERSONALIZATION_ID } from "constants/user";
import { WC_PREVIEW_TOKEN } from "_foundation/constants/common";
import loginIdentity from "_foundation/apis/transaction/loginIdentity.service";
import {
  localStorageUtil,
  storageSessionHandler,
} from "_foundation/utils/storageUtil";
import personService from "_foundation/apis/transaction/person.service";
import personServiceExt from "_foundationExt/apis/transaction/person.service";
import deliveryDateService from "_foundationExt/apis/transaction/deliverydate.service";
import offerService from "_foundationExt/apis/transaction/offer.service";
import contractService from "_foundationExt/apis/transaction/contract.service";
import * as ACTIONS from "_redux/action-types/user";
import {
  REGISTRATION_SUCCESS_ACTION,
  LOGOUT_SUCCESS_ACTION,
  INIT_USER_FROM_STORAGE_SUCCESS_ACTION,
  FETCH_USER_DETAILS_SUCCESS_ACTION,
  SESSION_ERROR_LOGIN_ERROR_ACTION,
  FETCH_CONTRACTS_SUCCESS_ACTION,
  FETCH_DELIVERY_DATES_SUCCESS_ACTION,
  FETCH_DELIVERY_DATE_SUCCESS_ACTION,
  FETCH_CUSTOMER_PRODUCTLISTS_ACTION,
  FETCH_OFFERS_SUCCESS_ACTION,
  FETCH_EFOOD_CONTRACTS_SUCCESS_ACTION,
  FETCH_ENTITLED_ORGANIZATIONS_ACTION,
  FETCH_ORDER_HISTORY_ACTION,
  LOGIN_SUCCESS_ACTION,
  loginErrorAction,
  RENEW_LOGIN_REQUESTED_ACTION,
} from "_redux/actions/user";
import { efoodSelector, siteSelector } from "_redux/selectors/site";
import customerProductlistService from "_foundationExt/apis/transaction/customerProductlist.service";
import organizationService from "_foundationExt/apis/transaction/organization.service";
import orderHistoryService from "_foundationExt/apis/transaction/report.service";
import {
  customerConfigSelector,
  selectUserActiveOrgId,
  userDetailsSelector,
  selectUserEntitledOrganizations,
  selectUserIsCentralPurchaser,
  userLastUpdatedSelector,
  selectUserOrgId,
} from "_redux/selectors/user";
import * as cookieUtils from "tools/cookieUtils";
import predictivBasketService from "_foundationExt/apis/transaction/predictivBasket.service";
import { ROUTES } from "constants/routes";
import { isAxiosError } from "axios";
import {
  EFoodConfiguration,
  ErrorReducerState,
  UserReducerState,
} from "_redux/reducers";
import loginIdentityService from "_foundationExt/apis/transaction/loginIdentity.service";
import { CLOSE_DIALOG_ACTION, SHOW_DIALOG_ACTION } from "_redux/actions/site";
import { RESET_ERROR_ACTION } from "_redux/actions/error";

import { fromResponseDeliveryDates } from "tools/delivery-date-helper";
import { isAuthError, isCanceledError } from "_foundationExt/axios/axiosConfig";
import { isNetworkError } from "pwa/serviceWorker/routes/common";

const COOKIE_SANDER_AUTH_PREFIX = "sander_auth_";

const preProcessLoginError = (error: any): ErrorReducerState => {
  if (isAxiosError(error) && error.response?.data?.errors?.[0]) {
    return {
      ...error.response.data.errors[0],
      loginError: true,
    };
  }

  return {
    errorMessage: error.toLocaleString(),
    loginError: true,
  };
};

export function* fetchDetail(action: any) {
  const {
    payload: {
      requestPayload: payload,
      navigate,
      newsletterValidationCode,
      pathname,
    },
  } = action;

  // delete AbortSignal in Payload, as during login we can navigate to a different route,
  // which will cause the AbortSignal to be canceled,
  // but these requests have to be done after a successful login
  // and this generator is only called after a successful login
  if (payload.signal) {
    delete payload.signal;
  }

  const person = yield call(personService.findPersonBySelf, {});
  yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(person.data));

  let activeOrganizationId = person.data.activeOrganizationId;
  const organizations = yield call(
    organizationService.selfEntiledOrgs,
    payload
  );
  yield put(FETCH_ENTITLED_ORGANIZATIONS_ACTION(organizations.data));

  if (payload.centralPurchaser) {
    activeOrganizationId =
      organizations.data.entitledOrganizations[0]?.organizationId;
    const switchOrganizationToPayload = {
      organizationId: activeOrganizationId,
    };

    yield call(
      organizationService.switchOrganizationTo,
      switchOrganizationToPayload
    );
  } else {
    if (!activeOrganizationId) {
      activeOrganizationId = person.data.organizationId;
    }
  }

  yield* loadCustomerData({
    ...action,
    payload: { ...payload, activeOrganizationId },
  });

  if (newsletterValidationCode) {
    navigate(`${ROUTES.NEWSLETTER_ACTIVATE}/${newsletterValidationCode}`);
  } else if (pathname === ROUTES.HOME || pathname === ROUTES.LOGON) {
    navigate(ROUTES.HOME_LOGGEDIN);
  }
}

function* loginAndFetchDetail(action: any) {
  const {
    payload: {
      requestPayload: { body, ...rest },
    },
  } = action;
  const payload = {
    ...rest,
    body: { ...body, rememberMe: body.rememberMe ? "true" : "false" },
  };
  const response = yield call(loginIdentity.login, payload);
  const user = response.data;
  if (payload?.query?.rememberMe && user) {
    // add rememberMe true to user state
    user.rememberMe = payload.query.rememberMe;
  }
  yield put(LOGIN_SUCCESS_ACTION(user));

  yield* fetchDetail(action);
}

function* relogonAndFetchDetail(action: any) {
  const {
    payload: {
      requestPayload: { body, query, ...rest },
      ...payloadRest
    },
  } = action;
  const payload = {
    ...rest,
    body: { ...body, rememberMe: body.rememberMe ? "true" : "false" },
  };
  let user: any = undefined;

  try {
    const response = yield call(loginIdentityService.renew, {
      ...payload,
    });
    user = response.data;
  } catch (error) {
    // ignore network error, as this mean we're offline
    if (!isNetworkError(error) || !query?.oldUser) {
      throw error;
    }

    // use old user information, especially the refreshToken to fake a successful relogin
    // needed in the case the user closes the shop and reopen it, while he or the backend is offline
    user = query.oldUser;
  }

  if (query?.rememberMe && user) {
    // add rememberMe true to user state
    user.rememberMe = query.rememberMe;
  }
  yield put(LOGIN_SUCCESS_ACTION(user));
  yield put(CLOSE_DIALOG_ACTION({ type: "SessionRenewal" }));

  yield* fetchDetail({
    ...action,
    payload: {
      requestPayload: {
        body,
        ...rest,
      },
      ...payloadRest,
    },
  });
}

function* redirectedLoginAndFetchDetail(action: any) {
  const {
    payload: { requestPayload },
  } = action;
  const payload = {
    ...requestPayload,
    rememberMe: requestPayload.rememberMe ? "true" : "false",
  };
  const response = yield call(loginIdentityService.redirectedLogin, payload);
  const user = response.data;
  if (payload?.query?.rememberMe && user) {
    // add rememberMe true to user state
    user.rememberMe = payload.query.rememberMe;
  }
  yield put(LOGIN_SUCCESS_ACTION(user));

  yield* fetchDetail(action);
}

export function* login(action: any) {
  try {
    yield* loginAndFetchDetail(action);
  } catch (error) {
    if (isCanceledError(error) || isAuthError(error)) {
      return;
    }
    yield put(loginErrorAction(preProcessLoginError(error)));
  }
}

export function* redirectedLogin(action: any) {
  try {
    yield* redirectedLoginAndFetchDetail(action);
  } catch (error) {
    if (isCanceledError(error) || isAuthError(error)) {
      return;
    }
    yield put(loginErrorAction(preProcessLoginError(error)));
  }
}

export function* renewLogin(
  action: ReturnType<typeof RENEW_LOGIN_REQUESTED_ACTION>
) {
  const {
    payload: { resolve, reject },
  } = action;
  storageSessionHandler.removeCurrentUser();
  try {
    yield put(
      SHOW_DIALOG_ACTION({
        type: "SessionRenewal",
        closeable: false,
      })
    );
    yield* relogonAndFetchDetail(action);
    if (resolve) {
      resolve();
    }
  } catch (error) {
    if (reject) {
      reject(error);
    }
    if (isCanceledError(error) || isAuthError(error)) {
      return;
    }
    yield put(RESET_ERROR_ACTION());
    yield put(LOGOUT_SUCCESS_ACTION());
    yield put(loginErrorAction(preProcessLoginError(error)));
  } finally {
    yield put(CLOSE_DIALOG_ACTION({ type: "SessionRenewal" }));
  }
}

export function* FetchUserDetails(action: any) {
  const { payload } = action;
  const response1 = yield call(personService.findPersonBySelf, {});
  yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(response1.data));
}

export function* FetchDeliveryDate(action: any) {
  const { payload } = action;
  const response2: Awaited<
    ReturnType<typeof deliveryDateService.getDeliveryDates>
  > = yield call(deliveryDateService.getDeliveryDates, payload);
  yield put(
    FETCH_DELIVERY_DATES_SUCCESS_ACTION(
      fromResponseDeliveryDates(response2.data.dates)
    )
  );
}

// reload() is only called on changing the organisation (ZEK)
// so FetchDetails must only called once on initial reload
// if it called on later reload again, the request of prediction basket (in loadCustomerData)
// begins after the rerendering of the prediction basket components
export function* reload(action: any) {
  try {
    const { payload = {} } = action;
    const { navigate, navigateTo, initReload } = payload;

    const isCentralPurchaser = yield select(selectUserIsCentralPurchaser);

    if (initReload) {
      yield* FetchUserDetails(action);
    }
    yield* FetchDeliveryDate(action);

    const params = {
      isCentralPurchaserOnOrgChange: isCentralPurchaser,
    };
    const newAction = {
      payload: { ...payload, ...params },
      type: action.type,
    };
    yield* loadCustomerData(newAction);

    if (!!navigate && navigateTo != null) {
      navigate(navigateTo);
    }
  } catch (error) {
    if (isCanceledError(error) || isAuthError(error)) {
      return;
    }
    console.warn(error);
  }
}

function* initEntitledOrganization(action: any) {
  const entitledOrganizations = yield select(selectUserEntitledOrganizations);
  const newActiveOrgId = entitledOrganizations?.length
    ? entitledOrganizations[0]?.organizationId
    : undefined;

  if (newActiveOrgId != null) {
    const switchOrganizationToPayload = {
      organizationId: newActiveOrgId,
    };

    const response = yield call(
      organizationService.switchOrganizationTo,
      switchOrganizationToPayload
    );
    const payload = {
      storeId: action.payload.storeId,
      organizationId: newActiveOrgId,
      initReload: true,
    };

    yield put({
      type: ACTIONS.RELOAD_CUSTOMER_DATA,
      response,
      payload: { ...payload },
    });
  }
}

export function* loadCustomerData(action: any) {
  try {
    const { payload } = action;

    const userOrganizationId = yield select(selectUserOrgId);
    const activeOrganizationId = yield select(selectUserActiveOrgId);
    const isCentralPurchaser = yield select(selectUserIsCentralPurchaser);
    const customerConfiguration = yield select(customerConfigSelector);

    // if a ZEK (centralPurchaser) inital login to the store, it is sometimes possible that the
    // current customer is not correctly set (customerConfiguration = {} or activeOrganization is set to ZEK organisation) -
    // possible reasons are the async requests -
    // so we set the active organization to the first entitled organization
    //
    if (
      isCentralPurchaser &&
      (Object.keys(customerConfiguration).length == 0 ||
        userOrganizationId === activeOrganizationId)
    ) {
      yield* initEntitledOrganization(action);
      return;
    }

    if (customerConfiguration.showBasketPredictions) {
      yield* getPredictivBasket(action);
    }

    const contract = yield call(contractService.getContracts);
    yield put(FETCH_CONTRACTS_SUCCESS_ACTION(contract.data.contracts));
    const delivery: Awaited<
      ReturnType<typeof deliveryDateService.getDeliveryDates>
    > = yield call(deliveryDateService.getDeliveryDates, payload);
    yield put(
      FETCH_DELIVERY_DATES_SUCCESS_ACTION(
        fromResponseDeliveryDates(delivery.data.dates)
      )
    );
    yield* getCurrentDeliveryDate({ payload });

    yield* fetchProductLists({ payload });

    try {
      const offer = yield call(offerService.getOffers);
      yield put(FETCH_OFFERS_SUCCESS_ACTION(offer.data.offers));
    } catch (error) {
      yield put({ type: ACTIONS.FETCH_OFFERS_ERROR, error });
    }

    const storeConfiguration: EFoodConfiguration = yield select(efoodSelector);
    if (storeConfiguration.contracts) {
      const eFoodContract = yield call(contractService.getEFoodContracts);

      // filter assignedProductLists if disponent
      const eFoodContracts =
        payload.assignedProductLists && payload.assignedProductLists.length > 0
          ? eFoodContract.data.contracts.filter((contract) =>
              payload.assignedProductLists.includes(contract.id)
            )
          : eFoodContract.data.contracts;
      yield put(FETCH_EFOOD_CONTRACTS_SUCCESS_ACTION(eFoodContracts));
    }

    try {
      const siteConfiguration = yield select(siteSelector);
      if (siteConfiguration && siteConfiguration.storeCfg.efood.sander) {
        const userDetails = yield select(userDetailsSelector);

        // clear previous set sander auth cookie(s)
        const sanderAuthCookies = cookieUtils.getCookiesWithPrefix(
          COOKIE_SANDER_AUTH_PREFIX
        );
        sanderAuthCookies.forEach((sanderAuthCookie) =>
          cookieUtils.deleteCookie(sanderAuthCookie)
        );

        if (userDetails && userDetails.config?.sander) {
          cookieUtils.setCookie(
            COOKIE_SANDER_AUTH_PREFIX + payload.userId,
            payload.WCTrustedToken,
            1
          );
          cookieUtils.setCookie(
            "sander_active",
            siteConfiguration.defaultLanguageID +
              "," +
              siteConfiguration.storeCfg.storeId,
            1
          );
        }
      }
    } catch (error) {
      console.log(error);
    }

    yield* fetchOrderHistory(action);
  } catch (error) {
    console.warn(error);
  }
}

export function* fetchOrderHistory(action: any) {
  try {
    const { payload } = action;
    const organizationId = payload.activeOrganizationId
      ? payload.activeOrganizationId
      : payload.organizationId;
    const parameters = {
      organizationId,
      storeId: payload.storeId,
    };
    const history = yield call(orderHistoryService.getOrderHistory, {
      ...parameters,
    });
    yield put(FETCH_ORDER_HISTORY_ACTION(history.data));
  } catch (error) {
    if (isCanceledError(error) || isAuthError(error)) {
      return;
    }
    yield put({ type: ACTIONS.FETCH_ORDER_HISTORY_ERROR, error });
  }
}

export function* sessionErrorReLogin(action: any) {
  try {
    const { payload } = action;
    const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount();
    if (currentUser?.rememberMe) {
      payload.query
        ? (payload.query.rememberMe = currentUser.rememberMe)
        : (payload.query = { rememberMe: currentUser.rememberMe });
    }
    storageSessionHandler.removeCurrentUser();
    yield* loginAndFetchDetail(action);
  } catch (error: any) {
    if (isCanceledError(error) || isAuthError(error)) {
      return;
    }
    const message = error?.response?.data?.errors?.[0];
    if (message) {
      yield put(SESSION_ERROR_LOGIN_ERROR_ACTION(message));
    }
  }
}

export function* logout(action: any) {
  const { payload = {} } = action;
  const { navigate, ...remainingPayload } = payload;
  try {
    yield call(loginIdentity.logout, remainingPayload);
  } catch (error) {
    if (isCanceledError(error) || isAuthError(error)) {
      return;
    }
    yield put({ type: ACTIONS.LOGOUT_ERROR, error });
  }
  // clear user token, even if the logout failed to avoid infinite loop
  yield put(LOGOUT_SUCCESS_ACTION(remainingPayload));
  if (navigate) {
    navigate(ROUTES.HOME);
  }
}

export function* registration(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(personServiceExt.registerPerson, payload);

    const registrationPayload = response.data;
    if (payload?.query?.rememberMe) {
      registrationPayload.rememberMe = payload.query.rememberMe;
    }
    yield put(REGISTRATION_SUCCESS_ACTION(registrationPayload));
    // User will not be logged in
    // const response2 = yield call(personService.findPersonBySelf, {});
    // yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(response2.data));
  } catch (error) {
    if (isCanceledError(error) || isAuthError(error)) {
      return;
    }
    yield put({ type: ACTIONS.REGISTRATION_ERROR, error });
  }
}

export function* initStateFromStorage(action: any) {
  try {
    let currentUser =
      storageSessionHandler.getCurrentUserAndLoadAccount() as Partial<UserReducerState>;
    if (currentUser === null) {
      //
      // if we have both previewtoken and newPreviewSession, the current user is removed in inistates.ts
      // then we should get new personalizationID from preview session
      const previewToken = storageSessionHandler.getPreviewToken();
      if (!previewToken || !previewToken[WC_PREVIEW_TOKEN]) {
        const personalizationID = localStorageUtil.get(PERSONALIZATION_ID);
        if (personalizationID !== null) {
          currentUser = { personalizationID };
        }
      }
    }
    if (
      currentUser?.userLoggedIn &&
      !currentUser?.WCToken &&
      !currentUser?.refreshToken
    ) {
      yield put(LOGOUT_SUCCESS_ACTION());
      currentUser =
        storageSessionHandler.getCurrentUserAndLoadAccount() as Partial<UserReducerState>;
    }
    const { userLoggedIn, WCToken, refreshToken } = currentUser ?? {};
    yield put(INIT_USER_FROM_STORAGE_SUCCESS_ACTION(currentUser));
    if (!WCToken && refreshToken) {
      yield put(
        RENEW_LOGIN_REQUESTED_ACTION({
          requestPayload: {
            body: {
              refreshToken,
              rememberMe: true,
            },
            query: {
              rememberMe: true,
              oldUser: currentUser,
            },
          },
        })
      );
    }
    if (WCToken) {
      const response2 = yield call(personService.findPersonBySelf, {
        ...action.payload,
      });
      const loginPayload2 = response2.data;
      yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(loginPayload2));
    }
  } catch {}
}

export function* updateStateFromStorage(action: any) {
  try {
    const currentUser =
      storageSessionHandler.getCurrentUserAndLoadAccount() as UserReducerState;
    if (currentUser && currentUser.forUserId) {
      return;
    }
    const userLastUpdated = yield select(userLastUpdatedSelector);
    if (
      currentUser &&
      currentUser.lastUpdated &&
      (!userLastUpdated || userLastUpdated < currentUser.lastUpdated)
    ) {
      yield put(INIT_USER_FROM_STORAGE_SUCCESS_ACTION(currentUser));
      if (currentUser.isGuest) {
        // yield put(GUEST_LOGIN_SUCCESS_ACTION(null));
      } else {
        yield put(LOGIN_SUCCESS_ACTION(null));
      }
    }
  } catch (e) {
    console.warn(e);
  }
}

export function* updateDeliveryDate(action: any) {
  try {
    const { payload } = action;
    const { storeId, date, orderId, basketIdentifier } = payload;
    const body = {
      storeId,
      body: {
        date,
        orderId,
        basketIdentifier,
      },
    };
    const response = yield call(deliveryDateService.setDeliveryDate, body);
    yield put({
      type: ACTIONS.UPDATE_DELIVERY_DATE_SUCCESS,
      response,
      payload,
    });
  } catch (error) {
    if (isCanceledError(error) || isAuthError(error)) {
      return;
    }
    yield put({ type: ACTIONS.UPDATE_DELIVERY_DATE_ERROR, error });
  }
}

export function* fetchDeliveryDates(action: any) {
  try {
    const { payload } = action;
    const response: Awaited<
      ReturnType<typeof deliveryDateService.getDeliveryDates>
    > = yield call(deliveryDateService.getDeliveryDates, payload);
    yield put(
      FETCH_DELIVERY_DATES_SUCCESS_ACTION(
        fromResponseDeliveryDates(response.data.dates)
      )
    );
  } catch (error) {
    if (isCanceledError(error) || isAuthError(error)) {
      return;
    }
    yield put({ type: ACTIONS.FETCH_DELIVERY_DATES_ERROR, error });
  }
}

export function* getCurrentDeliveryDate(action: any) {
  try {
    const payload = action.payload;
    if (payload.dropShipType === undefined) {
      const response = yield call(deliveryDateService.getCurrentDeliveryDate);
      yield put(FETCH_DELIVERY_DATE_SUCCESS_ACTION(response.data));
    }
  } catch (error) {
    if (isCanceledError(error) || isAuthError(error)) {
      return;
    }
    yield put({ type: ACTIONS.FETCH_DELIVERY_DATE_ERROR, error });
  }
}

export function* fetchProductLists(action: any) {
  const payload = action.payload;
  const response = yield call(customerProductlistService.getSelf, payload);
  if (response.data.productlists)
    yield put(FETCH_CUSTOMER_PRODUCTLISTS_ACTION(response.data.productlists));
  else yield put({ type: ACTIONS.FETCH_CUSTOMER_PRODUCTLISTS_ERROR, response });
}

export function* getPredictivBasket(action: any) {
  try {
    const { payload } = action;
    const storeConfiguration: EFoodConfiguration = yield select(efoodSelector);
    const { predictivBasketCount } = storeConfiguration;

    const params = {
      predictivBasketCount: predictivBasketCount,
      updateClusterPrediction: action.type === "LOGIN_SUCCESS",
    };

    const response = yield call(
      predictivBasketService.getPredictionForCustomer
    );

    const response1 = yield call(personService.findPersonBySelf, {});
    yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(response1.data));

    yield put({
      type: ACTIONS.PREDICTIVE_BASKET_FOUND,
      response,
      payload: { ...payload, ...params },
    });
  } catch (error) {
    if (isCanceledError(error) || isAuthError(error)) {
      return;
    }
    yield put({ type: ACTIONS.PREDICTIVE_BASKET_NOT_FOUND, error });
  }
}
