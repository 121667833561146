import { has } from "lodash-es";
import { OfflineAwareResponse } from "pwa/offline";
import { ROUTES } from "constants/routes";

import { EXCLUDED_FILTER_FACETS } from "configs/catalog";
import {
  Category,
  Filter,
  CategoriesLookupMap,
  CategoriesLookupEntry,
} from "../../types/Search";

const determineSubCategories = (response: OfflineAwareResponse): Category[] => {
  const facetView = response.data?.facetView;

  if (facetView == null) {
    return [];
  }

  const subCategoriesData = facetView.find(
    (entry) => entry.value === "parentCatgroup_id_search"
  );

  if (
    subCategoriesData &&
    subCategoriesData.entry &&
    subCategoriesData.extendedData
  ) {
    const { displayLevel } = subCategoriesData.extendedData;

    const subCategories = subCategoriesData.entry.filter(
      (subCategory) => subCategory.extendedData.level === displayLevel
    );

    return subCategories;
  }

  return [];
};

const determineFilterData = (response: OfflineAwareResponse): Filter[] => {
  const facetView = response.data?.facetView;

  if (facetView == null) {
    return [];
  }

  const listOfFilters: Filter[] = facetView.filter(
    (entry) => !EXCLUDED_FILTER_FACETS.includes(entry.value)
  );

  return listOfFilters;
};

const determineCategoriesLookup = (
  response: OfflineAwareResponse,
  categoryId: string | null
): CategoriesLookupMap => {
  const facetView = response.data?.facetView;

  const categoriesLookupMap: CategoriesLookupMap = new Map<
    string,
    CategoriesLookupEntry
  >();
  if (facetView !== undefined) {
    const subCategoriesData = facetView.find(
      (entry) => entry.value === "parentCatgroup_id_search"
    );
    if (has(subCategoriesData, "entry")) {
      subCategoriesData.entry.forEach((entry) => {
        categoriesLookupMap.set(entry.value, {
          bold: false,
          underline: false,
          expand: false,
        });
      });
    }
  }
  response.data?.breadCrumbTrailEntryView?.forEach((entry) => {
    categoriesLookupMap.set(entry.value, {
      bold: true,
      underline: categoryId === entry.value,
      expand: true,
    });
  });
  return categoriesLookupMap;
};

interface LocationParams {
  pathname: string;
  search: string;
}

const buildLinkRoute = (
  location: LocationParams,
  categoryId: string,
  searchTerm?: string | null,
  isAccountListView?: boolean
) => {
  const queryString = new URLSearchParams(location.search);
  const productListId = queryString.get("productListId");
  const isOffersView = location.pathname.includes("offers");

  let link = "";
  let isCategoryDisplay = false;
  if (isOffersView) {
    link = `${window.location.pathname}`;
  } else if (isAccountListView && productListId) {
    link = `${ROUTES.ACCOUNT_LISTS}`;
  } else if (searchTerm && productListId) {
    link = `${ROUTES.PRODUCT_DETAIL}${ROUTES.SEARCH}`;
  } else if (searchTerm) {
    link = `${ROUTES.PRODUCT_DETAIL}${ROUTES.SEARCH}`;
  } else if (productListId) {
    link = `${ROUTES.PRODUCT_DETAIL}${ROUTES.SEARCH}`;
  } else {
    link = `${ROUTES.PRODUCT_LIST}/${categoryId}`;
    isCategoryDisplay = true;
  }

  const linkSearchParams = new URLSearchParams();
  if (searchTerm && !isAccountListView && !isOffersView) {
    linkSearchParams.append("searchTerm", searchTerm);
  }
  if (productListId) {
    linkSearchParams.append("productListId", productListId);
  }
  if (categoryId && !isCategoryDisplay) {
    linkSearchParams.append("categoryId", categoryId);
  }

  const linkQueryString = linkSearchParams.toString();
  if (linkQueryString) {
    link = `${link}?${linkQueryString}`;
  }
  if (window.location.hash.length) {
    link = `${link}${window.location.hash}`;
  }

  return link;
};

const buildAllCategoriesLink = (
  location: LocationParams,
  categoryId?: string,
  searchTerm?: string | null
) => {
  const queryString = new URLSearchParams(location.search);
  const productListId = queryString.get("productListId");

  let link = "";

  if (categoryId) {
    link = `${ROUTES.PRODUCT_CATALOG}`;
  } else if (productListId) {
    link = `${ROUTES.ACCOUNT_LISTS}?productListId=${productListId}`;
  } else if (searchTerm) {
    link = `${ROUTES.PRODUCT_DETAIL}${ROUTES.SEARCH}?searchTerm=${searchTerm}`;
  } else {
    link = `${ROUTES.PRODUCT_CATALOG}`;
  }

  link = `${link}${
    window.location.hash.length > 1 ? window.location.hash : ""
  }`;

  return link;
};

export {
  determineSubCategories,
  determineFilterData,
  determineCategoriesLookup,
  buildLinkRoute,
  buildAllCategoriesLink,
};
