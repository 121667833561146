import React from "react";
import { useLocation } from "react-router-dom";
import { useSite } from "_foundationExt/hooks/useSite";
import { ROUTES } from "constants/routes";
import { useAppSelector } from "_redux/hooks";
import {
  orderSelector,
  selectAllOrderItemsSorted,
} from "_redux/selectors/order";
import { useMatomo, ContentParams } from "components/matomo";
import roundQuantity from "tools/round-quantity";

const Content: React.FC<ContentParams> = ({ tracker, pathname }) => {
  const { currentSite } = useSite();
  const orderitems = useAppSelector(selectAllOrderItemsSorted);
  const order = useAppSelector(orderSelector);

  const trackAction = (currentPathname) => {
    setTimeout(() => {
      if (currentPathname.includes(ROUTES.CART)) {
        tracker.clearEcommerceCart();
        if (orderitems) {
          orderitems.map((item) =>
            tracker.addEcommerceItem({
              sku: item.article?.partNumber || "n/a",
              productName: item.article?.name,
              productPrice: item.price,
              productQuantity: roundQuantity(item.quantity, 2),
            })
          );
        }
        tracker.trackEcommerceCartUpdate(order.total);
      } else if (!currentPathname.includes(ROUTES.SEARCH)) {
        tracker.trackPageView();
      }
    }, 500);
  };

  React.useEffect(() => {
    if (currentSite) {
      trackAction(pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSite, pathname]);

  return null;
};

const TrackRoute: React.FC = () => {
  const location = useLocation();
  const matomo = useMatomo();
  const [pathname, setPathname] = React.useState(location.pathname);

  React.useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname]);

  if (!matomo || !pathname) {
    return null;
  }
  return <Content tracker={matomo} pathname={pathname} />;
};

export default TrackRoute;
