import React, { FC, useState } from "react";
import { Box, Typography } from "@mui/material";
import ESpot from "components/widgets/espot/ESpot";
import { useLocation } from "react-router-dom";
import { StyledDivider } from "components/StyledUI";

const ESpotShowcase: FC = () => {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const productId = queryString.get("productId") || "";
  const categoryId = queryString.get("categoryId") || "1";

  return (
    <div style={{ width: queryString.get("width") || "100%" }}>
      <p>
        <Typography variant="h1">ESpot Showcase Page</Typography>
        <Box style={{ cursor: "pointer", display: "inline" }}>
          <Typography variant="body2">
            {"url params > ?categoryId=xxx&productId=xxx&width=100%"}
          </Typography>
        </Box>
      </p>

      <Box onClick={() => setOpen1(!open1)} style={{ cursor: "pointer" }}>
        <StyledDivider text="alles aufklappen" />
      </Box>

      <details open={open1}>
        <summary>basket_catentry_recommendation_checkout_1</summary>
        <p>
          <ESpot emsName="basket_catentry_recommendation_checkout_1" />
        </p>
      </details>

      <details open={open1}>
        <summary>basket_catentry_recommendation_checkout_1_bottom</summary>
        <p>
          <ESpot emsName="basket_catentry_recommendation_checkout_1_bottom" />
        </p>
      </details>

      <details open={open1}>
        <summary>basket_catentry_recommendation_checkout_2</summary>
        <p>
          <ESpot emsName="basket_catentry_recommendation_checkout_2" />
        </p>
      </details>

      <details open={open1}>
        <summary>basket_catentry_recommendation_checkout_2_bottom</summary>
        <p>
          <ESpot emsName="basket_catentry_recommendation_checkout_2_bottom" />
        </p>
      </details>

      <details open={open1}>
        <summary>Product_Bottom_EMS</summary>
        <p>
          <ESpot
            emsName="Product_Bottom_EMS"
            productId={productId}
            categoryId={categoryId}
          />
        </p>
      </details>

      <details open={open1}>
        <summary>Category_Top_EMS </summary>
        <p>
          <ESpot emsName="Category_Top_EMS" categoryId={categoryId} />
        </p>
      </details>

      <details open={open1}>
        <summary>Category_Left_Sidebar_EMS </summary>
        <p>
          <ESpot emsName="Category_Left_Sidebar_EMS" categoryId={categoryId} />
        </p>
      </details>

      <details open={open1}>
        <summary>Category_Bottom_EMS </summary>
        <p>
          <ESpot emsName="Category_Bottom_EMS" categoryId={categoryId} />
        </p>
      </details>

      <details open={open1}>
        <summary>Offer_Bottom_EMS</summary>
        <p>
          <ESpot emsName="Offer_Bottom_EMS" />
        </p>
      </details>

      <Box onClick={() => setOpen2(!open2)} style={{ cursor: "pointer" }}>
        <StyledDivider text="alles aufklappen" />
      </Box>

      <details open={open2}>
        <summary>Predictiv Basket Slider</summary>
        <p>
          <ESpot emsName="ESpotShowcasePredictivBasketSlider" />
        </p>
      </details>

      <details open={open2}>
        <summary>Predictiv Basket Slider with Title</summary>
        <p>
          <ESpot emsName="ESpotShowcasePredictivBasketSliderTitle" />
        </p>
      </details>

      <details open={open2}>
        <summary>Predictiv Basket Slider with Content</summary>
        <p>
          <ESpot emsName="ESpotShowcasePredictivBasketSliderContent" />
        </p>
      </details>

      <details open={open2}>
        <summary>Product Slider</summary>
        <p>
          <ESpot emsName="ESpotShowcaseProductSlider" />
        </p>
      </details>

      <details open={open2}>
        <summary>Product Slider with Title</summary>
        <p>
          <ESpot emsName="ESpotShowcaseProductSliderTitle" />
        </p>
      </details>

      <details open={open2}>
        <summary>Product Slider with Content</summary>
        <p>
          <ESpot emsName="ESpotShowcaseProductSliderContent" />
        </p>
      </details>

      <details open={open2}>
        <summary>Content</summary>
        <p>
          <ESpot emsName="ESpotShowcaseContent" />
        </p>
      </details>

      <details open={open2}>
        <summary>Content with Title</summary>
        <p>
          <ESpot emsName="ESpotShowcaseTitleContent" />
        </p>
      </details>

      <details open={open2}>
        <summary>Content Left Side</summary>
        <p>
          <ESpot emsName="ESpotShowcaseContentLeftSide" />
        </p>
      </details>

      <details open={open2}>
        <summary>Content Left Side with HTML</summary>
        <p>
          <ESpot emsName="ESpotShowcaseContentLeftSideHtml" />
        </p>
      </details>
    </div>
  );
};

export default ESpotShowcase;
