/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, Method, AxiosPromise } from "axios";
import { executeRequest } from "_foundation/axios/axiosConfig";
import { getSite } from "_foundation/hooks/useSite";
import loginIdentityService from "_foundation/apis/transaction/loginIdentity.service";
import { site as constantsSite } from "_foundationExt/constants/site";
import { LoginIdentityRenewApi } from "@efood-commerce-sdk/typescript-axios-transaction-efood";
import ensureObject from "tools/ensureObject";

const loginIdentityRenewApiInstance = new LoginIdentityRenewApi(
  undefined,
  constantsSite.transactionContext
);

const loginIdentityServiceExt = {
  redirectingLogin(
    iParameters: any,
    headers?: any,
    url?: string
  ): AxiosPromise<any> {
    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.transactionContext || "";
    }
    const domain = url || siteContext;
    const path = "/site/efood/loginidentity/@redirect";
    const requestUrl = domain + path;
    const method: Method = "POST";
    const form: any = {};
    let body = {};
    let header: Headers;
    const queryParameters = new URLSearchParams();
    const formParams = new URLSearchParams();
    if (typeof headers === "undefined" || headers === null) {
      header = new Headers();
    } else {
      header = new Headers(headers);
    }
    const parameters = iParameters || {};
    [
      "application/json",
      "application/xml",
      "application/xhtml+xml",
      "application/atom+xml",
    ].forEach((value) => header.append("Accept", value));

    if (parameters.generateLTPAToken !== undefined) {
      const name = "generateLTPAToken";
      const parameter = parameters[name];
      delete parameters[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (parameters.responseFormat !== undefined) {
      const name = "responseFormat";
      const parameter = parameters[name];
      delete parameters[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (parameters.body !== undefined) {
      body = parameters.body;
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach((parameterName) => {
        const parameter = parameters.$queryParameters[parameterName];
        queryParameters.set(parameterName, parameter);
      });
    }

    if (!header.get("Content-Type")) {
      header.append("Content-Type", "application/json; charset=utf-8");
    }

    const accept = header.get("Accept");
    if (accept !== null && accept.indexOf("application/json") > -1) {
      header.set("Accept", "application/json");
    }

    const formKeys = Object.keys(form);
    if (
      header.get("content-type") === "multipart/form-data" &&
      formKeys.length > 0
    ) {
      const formData = new FormData();
      formKeys.forEach((key) => {
        if (form[key].name !== undefined) {
          formData.append(key, form[key], form[key].name);
        } else {
          formData.append(key, form[key]);
        }
      });
      body = formData;
    } else if (formKeys.length > 0) {
      header.set("content-type", "application/x-www-form-urlencoded");
      formKeys.forEach((key) => {
        formParams.append(key, form[key]);
      });

      formParams.sort();
      body = formParams;
    }
    const headerObject: any = {};
    Array.from(header.entries()).forEach((headerPair) => {
      const [key, value] = headerPair;
      headerObject[key] = value;
    });
    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      headers: headerObject,
      data: body,
      url: requestUrl,
      ...parameters,
    };

    return executeRequest(requestOptions);
  },

  redirectedLogin(
    iParameters: any,
    headers?: any,
    url?: string
  ): AxiosPromise<any> {
    const site = getSite();
    let siteContext = "";
    if (site) {
      siteContext = site.transactionContext || "";
    }
    const domain = url || siteContext;
    const path = "/store/{storeId}/loginidentity/@redirected";
    let requestUrl = domain + path;
    const method: Method = "POST";
    const form: any = {};
    let body = {};
    let header: Headers;
    const queryParameters = new URLSearchParams();
    const formParams = new URLSearchParams();
    if (typeof headers === "undefined" || headers === null) {
      header = new Headers();
    } else {
      header = new Headers(headers);
    }
    const parameters = iParameters || {};
    [
      "application/json",
      "application/xml",
      "application/xhtml+xml",
      "application/atom+xml",
    ].forEach((value) => header.append("Accept", value));

    if (parameters.storeId === undefined && site !== null) {
      parameters.storeId = site.storeID;
    }
    if (parameters.storeId === undefined) {
      throw new Error(
        "Request '/store/{storeId}/loginidentity' missing required parameter storeId"
      );
    }
    requestUrl = requestUrl.replace("{storeId}", parameters.storeId);

    if (parameters.generateLTPAToken !== undefined) {
      const name = "generateLTPAToken";
      const parameter = parameters[name];
      delete parameters[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (parameters.responseFormat !== undefined) {
      const name = "responseFormat";
      const parameter = parameters[name];
      delete parameters[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (parameters.body !== undefined) {
      body = parameters.body;
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach((parameterName) => {
        const parameter = parameters.$queryParameters[parameterName];
        queryParameters.set(parameterName, parameter);
      });
    }

    if (!header.get("Content-Type")) {
      header.append("Content-Type", "application/json; charset=utf-8");
    }

    const accept = header.get("Accept");
    if (accept !== null && accept.indexOf("application/json") > -1) {
      header.set("Accept", "application/json");
    }

    const formKeys = Object.keys(form);
    if (
      header.get("content-type") === "multipart/form-data" &&
      formKeys.length > 0
    ) {
      const formData = new FormData();
      formKeys.forEach((key) => {
        if (form[key].name !== undefined) {
          formData.append(key, form[key], form[key].name);
        } else {
          formData.append(key, form[key]);
        }
      });
      body = formData;
    } else if (formKeys.length > 0) {
      header.set("content-type", "application/x-www-form-urlencoded");
      formKeys.forEach((key) => {
        formParams.append(key, form[key]);
      });

      formParams.sort();
      body = formParams;
    }
    const headerObject: any = {};
    Array.from(header.entries()).forEach((headerPair) => {
      const [key, value] = headerPair;
      headerObject[key] = value;
    });
    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      headers: headerObject,
      data: body,
      url: requestUrl,
      ...parameters,
    };

    return executeRequest(requestOptions);
  },

  renew(options: {
    body: { refreshToken: string };
    [key: string]: any;
  }): AxiosPromise<any> {
    const site = getSite();
    const {
      storeId = site?.storeID,
      body,
      ...additionalOptions
    } = ensureObject(options);

    return loginIdentityRenewApiInstance.validateAndLoginWithRefreshToken(
      storeId,
      body,
      additionalOptions
    );
  },
};

export default { ...loginIdentityService, ...loginIdentityServiceExt };
