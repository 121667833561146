/**
 * options for items per page
 *
 * bigger values increase loading time
 */
export const pageSizeOptions = ["16", "32", "64"];

/**
 * fallback page size
 *
 * default 30
 */
export const defaultPageSize = pageSizeOptions[1];
