// react
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ROUTES } from "constants/routes";
// redux
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import {
  userCustomerNumberSelector,
  userConfigSelector,
  selectCentralPurchaser,
} from "_redux/selectors/user";
import { LOGOUT_REQUESTED_ACTION } from "_redux/actions/user";
// import * as orderActions from "redux/actions/order";
import * as userAction from "_redux/actions/user";
// mui
import { Typography, Box, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import {
  StyledIcon,
  StyledButton,
  StyledLink,
  UnstyledLink,
  StyledFormLabel,
  StyledFormSelect,
  StyledFormSelectItem,
  StyledPopover,
} from "components/StyledUI";
// components
import { useSite } from "_foundationExt/hooks/useSite";
import { ReactComponent as ProfileIcon } from "assets/icons/people-man-8.svg";
import { ReactComponent as PersonIcon } from "assets/icons/single-neutral.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
// types
import organizationService from "_foundationExt/apis/transaction/organization.service";
import { useOrderset } from "_redux/hooks/useOrderset";
import useUserName from "pages/home/useUserName";
import { tss } from "tss-react/mui";
import usePWADisplayMode from "pwa/displayMode/usePWADisplayMode";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = tss.create(({ theme }) => ({
  button: {
    backgroundColor: "transparent !important",
    padding: "4px 8px",
    fontSize: 14,
    textTransform: "none",
    letterSpacing: 0.5,
    "&:focus": {
      color: theme.palette.primary.dark,
    },
    "&:hover": {
      color: theme.palette.primary.light,
    },

    [theme.breakpoints.up("sm")]: {
      padding: "4px 12px",
    },
  },
  content: {
    maxWidth: "370px",
    maxHeight: "600px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "310px",
    },
  },
  spacing: {
    marginBottom: theme.spacing(2),
  },
  link: {
    "&:hover $icon": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  icon: {
    padding: 14,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    marginRight: theme.spacing(2),
    transition: theme.transitions.create(["background-color"]),
  },
  greeting: {
    fontFamily: "ShadowsIntoLight",
    fontSize: 28,
    lineHeight: "32px",
    letterSpacing: "-0.5px",
  },
  headerPopover: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const Account: FC = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const breakpointUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { currentSite } = useSite();
  const orderset = useOrderset();
  const { isPWA } = usePWADisplayMode();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    isCentralPurchaser,
    entitledOrganizations,
    activeOrganizationId,
    organizationId,
  } = useAppSelector(selectCentralPurchaser);
  const { customerAdmin } = useAppSelector(userConfigSelector);
  const { firstName, lastName } = useUserName();
  const customerNumber = useAppSelector(userCustomerNumberSelector);

  const [currentOrganizationId, setCurrentOrganizationId] = useState("");

  const setOrganizationToSession = useCallback(
    (id: string) =>
      organizationService
        .switchOrganizationTo({
          organizationId: id,
          changeSelectedUserForZek: "true",
        })
        .then(() => {
          const payload: ReloadCustomerDataPayload = {
            storeId: currentSite?.storeID,
            organizationId: id,
          };

          // Check if user is on orderhistory details, if add navigate
          const isOnOrderHistoryDetailsPage = location.pathname.includes(
            ROUTES.ACCOUNT_ORDERHISTORY_DETAILS
          );
          if (isOnOrderHistoryDetailsPage) {
            payload.navigate = navigate;
            payload.navigateTo = ROUTES.ACCOUNT_REPORTS;
          }

          dispatch(userAction.RELOAD_CUSTOMER_DATA_ACTION(payload));
        }),
    [dispatch, currentSite?.storeID, navigate, location.pathname]
  );

  const handleCustomerChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    setCurrentOrganizationId(event.target.value);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopperClose = () => {
    setAnchorEl(null);
  };

  const handlePopperToggle: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    if (anchorEl == null) {
      setAnchorEl(event.currentTarget);
    } else {
      handlePopperClose();
    }
  };

  const handleLogout = () => {
    dispatch(LOGOUT_REQUESTED_ACTION({ navigate }));
    handlePopperClose();
  };

  useEffect(() => {
    if (
      currentSite &&
      isCentralPurchaser &&
      entitledOrganizations.length &&
      activeOrganizationId &&
      organizationId
    ) {
      if (!currentOrganizationId) {
        if (activeOrganizationId === organizationId) {
          setCurrentOrganizationId(entitledOrganizations[0].organizationId);
        } else {
          setCurrentOrganizationId(activeOrganizationId);
        }
      } else if (currentOrganizationId !== activeOrganizationId) {
        setOrganizationToSession(currentOrganizationId);
      }
    }
  }, [
    currentSite,
    isCentralPurchaser,
    entitledOrganizations,
    activeOrganizationId,
    organizationId,
    currentOrganizationId,
    setOrganizationToSession,
  ]);

  return (
    <>
      <StyledButton
        id="profile-button"
        className={classes.button}
        aria-describedby="profile-flyout"
        onClick={handlePopperToggle}>
        <StyledIcon size="40" tooltip={t("iconTooltips.myAccountIcon")}>
          <ProfileIcon />
        </StyledIcon>
      </StyledButton>

      <StyledPopover
        id="profile-flyout"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handlePopperClose}
        transitionDuration={0}
        padding="24px"
        disableRestoreFocus>
        <Grid container className={classes.content}>
          <Grid xs={12}>
            <Box className={classes.headerPopover} mb={2}>
              <Typography variant={breakpointUpMd ? "h2" : "h3"} component="p">
                {t("header.myAccount.myAccount")}
              </Typography>
              <StyledButton
                className="close"
                onClick={handlePopperClose}
                startIcon={<CloseIcon />}
                size="small"
                color="inherit"
              />
            </Box>
          </Grid>

          <Grid xs={12}>
            <UnstyledLink
              className={classes.link}
              to={ROUTES.ACCOUNT}
              onClick={handlePopperClose}>
              <Box mb={2}>
                <Grid container wrap="nowrap" alignItems="center">
                  <Grid className={classes.icon}>
                    <StyledIcon size="36" color={theme.palette.common.white}>
                      <PersonIcon />
                    </StyledIcon>
                  </Grid>
                  <Grid className={classes.greeting}>
                    {t("header.myAccount.greeting", { firstName, lastName })}
                  </Grid>
                </Grid>
              </Box>
            </UnstyledLink>
            {!isCentralPurchaser && (
              <Box mb={1}>
                <Typography
                  variant="subtitle1"
                  component="p"
                  style={{ fontSize: "14px" }}>
                  {t("header.myAccount.customerNumber", { customerNumber })}
                </Typography>
              </Box>
            )}
          </Grid>

          {isCentralPurchaser && (
            <Grid xs={12}>
              <Box mb={2}>
                <StyledFormLabel>
                  {t("header.myAccount.yourCurrentCustomer")}:
                </StyledFormLabel>

                <StyledFormSelect
                  id="current-customer"
                  label={t("header.myAccount.yourCurrentCustomer")}
                  value={currentOrganizationId}
                  name="organizationId"
                  fullWidth
                  onChange={handleCustomerChange}>
                  {entitledOrganizations != null &&
                    entitledOrganizations.map((org) => (
                      <StyledFormSelectItem
                        key={org.organizationId}
                        value={org.organizationId}>
                        {org.customerNumber} - {org.customerName}
                      </StyledFormSelectItem>
                    ))}
                </StyledFormSelect>
              </Box>
            </Grid>
          )}

          <Grid xs={12}>
            <Box mb={1}>
              <StyledLink
                small
                to={ROUTES.ACCOUNT_PROFILE}
                onClick={handlePopperClose}>
                {t("header.myAccount.profileInformation")}
              </StyledLink>
            </Box>
            <Box mb={1}>
              <StyledLink
                small
                to={`${ROUTES.ACCOUNT_LISTS}?productListId=${orderset?.id}`}
                onClick={handlePopperClose}>
                {t("header.myAccount.listManagement")}
              </StyledLink>
            </Box>
            <Box mb={1}>
              <StyledLink
                small
                to={ROUTES.ACCOUNT_REPORTS}
                onClick={handlePopperClose}>
                {t("header.myAccount.reports")}
              </StyledLink>
            </Box>
            {customerAdmin && (
              <Box mb={1}>
                <StyledLink
                  small
                  to={ROUTES.ACCOUNT_USERS}
                  onClick={handlePopperClose}>
                  {t("header.myAccount.usersAdministration")}
                </StyledLink>
              </Box>
            )}
            {isPWA && (
              <Box mb={1}>
                <StyledLink
                  small
                  to={ROUTES.ACCOUNT_PWA_SETTINGS}
                  onClick={handlePopperClose}>
                  {t("offline.settings.headline")}
                </StyledLink>
              </Box>
            )}
          </Grid>

          <Grid xs={12}>
            <Box mt={2}>
              <StyledButton color="secondary" fullWidth onClick={handleLogout}>
                {t("header.myAccount.logout")}
              </StyledButton>
            </Box>
          </Grid>
        </Grid>
      </StyledPopover>
    </>
  );
};

interface ReloadCustomerDataPayload {
  storeId?: string;
  organizationId: string;
  navigate?: (path: string) => void;
  navigateTo?: string;
}

export default Account;
