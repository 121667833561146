import { get, groupBy } from "lodash-es";
import type {
  BaseMarketingSpotActivityData,
  BaseMarketingSpotDataType,
  ESpotContent,
  ESpotData,
  MarketingSpotData,
  MarketingSpotDataTitle,
} from "types/Marketing";
import fetchProductByIds from "./fetchProductByIds";
import getCatalogEntryIdsFromMarketingSpotData from "./getCatalogEntryIdsFromMarketingSpotData";
import getCatalogGroupIdsFromMarketingSpotData from "./getCatalogGroupIdsFromMarketingSpotData";
import mapMarketingContent from "./mapMarketingContent";
import getMarketingSpotNVPData from "./getMarketingSpotNVPData";
import getActivityData from "./getActivityData";
import getContentTracking from "./getContentTracking";

export type MapMarketingSpotDataReturnType = ESpotData[] | undefined;

const mapMarketingSpotData = async (
  marketingSpotData: MarketingSpotData,
  storeId,
  defaultLanguageId,
  setError: (error: boolean) => void
): Promise<MapMarketingSpotDataReturnType> => {
  // group data by activityId
  const marketingSpotDataGroup = groupBy(
    marketingSpotData.baseMarketingSpotActivityData,
    "activityIdentifierID"
  );
  const marketingSpotTitleGroup = groupBy(
    marketingSpotData.marketingSpotDataTitle,
    "marketingSpotDataTitleActivityID"
  );

  const dataPromise = Object.keys(marketingSpotDataGroup).map(
    async (activityId) => {
      // get current activityId
      const groupMarketingSpotDataItem = marketingSpotDataGroup[
        activityId
      ] as BaseMarketingSpotActivityData;
      const groupMarketingSpotTitleItem = marketingSpotTitleGroup[
        activityId
      ] as MarketingSpotDataTitle;

      // get prio
      const prio = Number(
        get(groupMarketingSpotDataItem, "[0].activityPriority", "-1")
      );

      // merge group with marketingSpotData
      const groupMarketingSpotData = {
        ...marketingSpotData,
        baseMarketingSpotActivityData: groupMarketingSpotDataItem,
        marketingSpotDataTitle: groupMarketingSpotTitleItem,
      } as MarketingSpotData;

      const currentLanguageId = defaultLanguageId;

      // get type
      const type: BaseMarketingSpotDataType[] = [
        ...new Set(
          (groupMarketingSpotData.baseMarketingSpotActivityData
            ?.map((e) => e.baseMarketingSpotDataType)
            .filter(Boolean) as BaseMarketingSpotDataType[]) || []
        ),
      ];

      const catalogEntryIdsInResponse = getCatalogEntryIdsFromMarketingSpotData(
        groupMarketingSpotData
      );

      const catalogGroupIds = getCatalogGroupIdsFromMarketingSpotData(
        groupMarketingSpotData
      );

      const nvp = getMarketingSpotNVPData(groupMarketingSpotData);
      const activity = getActivityData(groupMarketingSpotData);
      const contentTracking = getContentTracking(activity, nvp);

      // get id from espot
      const id = groupMarketingSpotData.marketingSpotIdentifier;

      // load titles
      const titles: ESpotContent[] =
        (groupMarketingSpotData.marketingSpotDataTitle
          ?.map((e) => mapMarketingContent(currentLanguageId, e))
          .filter(Boolean) as ESpotContent[]) || [];

      // load content
      const contents: ESpotContent[] =
        (groupMarketingSpotData.baseMarketingSpotActivityData
          ?.filter(
            (e) => e && e.baseMarketingSpotDataType === "MarketingContent"
          )
          .map((e) => mapMarketingContent(currentLanguageId, e))
          .filter(Boolean) as ESpotContent[]) || [];

      // load products
      const products = await fetchProductByIds(
        catalogEntryIdsInResponse,
        storeId,
        setError
      );

      return {
        id,
        prio,
        type,
        contents,
        products,
        titles,
        catalogGroupIds,
        activityId,
        contentTracking,
      };
    }
  );

  const data = await Promise.all(dataPromise);

  return data;
};

export default mapMarketingSpotData;
