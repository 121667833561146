import { useMemo } from "react";
import { BreadcrumbData } from "components/StyledUI/StyledBreadcrumb/StyledBreadcrumb";
import { useTranslation } from "react-i18next";
import { ROUTES } from "constants/routes";
import { BreadCrumbTrail } from "types/Search";
import { Offer } from "types/Offer";

const useBreadcrumb = (
  searchTerm: string | null,
  partNumbers: string | null,
  overrideHeadline: string | null,
  offer: Offer | null,
  breadCrumbTrail: BreadCrumbTrail[] | null
) => {
  const { t } = useTranslation();

  const result: BreadcrumbData[] | null = useMemo(() => {
    // TODO: addd productlist breadcrumb
    if (partNumbers && !overrideHeadline) {
      return [];
    }

    if (partNumbers && overrideHeadline) {
      return [{ label: `${overrideHeadline}`, value: "" }];
    }

    /* eslint-disable indent */
    if (offer) {
      const trail =
        breadCrumbTrail != null
          ? breadCrumbTrail.map((item) => ({
              label: item.label,
              value: "",
              link: `${ROUTES.OFFERS}/${offer.offerNumber}?categoryId=${item.value}`,
            }))
          : null;

      return trail
        ? [
            { label: `${t("sites.offers")}`, value: "", link: ROUTES.OFFERS },
            {
              label: `${offer.title}`,
              value: "",
              link: `${ROUTES.OFFERS}/${offer.offerNumber}`,
            },
          ].concat(trail)
        : [
            { label: `${t("sites.offers")}`, value: "", link: ROUTES.OFFERS },
            { label: `${offer.title}`, value: "" },
          ];
    }
    /* eslint-enable indent */

    if (searchTerm)
      return [{ label: `${t("search.search")}: ${searchTerm}`, value: "" }];

    return null;
  }, [breadCrumbTrail, offer, overrideHeadline, partNumbers, searchTerm, t]);

  return result;
};

export { useBreadcrumb };
