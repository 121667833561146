import React, { FC } from "react";
import { FormGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledFilterDropdown } from "components/StyledUI";
import { CustomerProductlist } from "types/CustomerProductlist";
import FilterOptionProductlist from "./FilterOptionProductlist";

interface FilterDropdownProductListsProps {
  selectedProductlistFilter: CustomerProductlist[];
  customerProductLists: CustomerProductlist[];
}

const FilterDropdownProductLists: FC<FilterDropdownProductListsProps> = ({
  selectedProductlistFilter,
  customerProductLists,
}) => {
  const { t } = useTranslation();

  return (
    <StyledFilterDropdown buttonLabel={t("productListing.myLists")}>
      {customerProductLists?.map((productlist) => (
        <FilterOptionProductlist
          key={`filterOption_${productlist.id}`}
          filterEntry={productlist}
          selectedProductlistFilter={selectedProductlistFilter}
        />
      ))}
      <FormGroup />
    </StyledFilterDropdown>
  );
};

export default FilterDropdownProductLists;
