/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { UseFormRegisterReturn } from "react-hook-form";

export function hexToRgba(hexString: string, a: number): string {
  let hex = hexString;
  if (hex.charAt(0) === "#") hex = hex.substring(1, hex.length);
  return `rgba(
    ${parseInt(hex.substring(0, 2), 16)}, 
    ${parseInt(hex.substring(2, 4), 16)}, 
    ${parseInt(hex.substring(4, 6), 16)}, 
    ${a})`;
}

const toCamel = (s) =>
  s.replace(/([-_][a-z])/gi, ($1) =>
    $1.toUpperCase().replace("-", "").replace("_", "")
  );

const isArray = (a) => Array.isArray(a);

const isObject = (o) =>
  o === Object(o) && !isArray(o) && typeof o !== "function";

export const keysToCamel = (o: any): any => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  }
  if (isArray(o)) {
    return o.map((i) => keysToCamel(i));
  }

  return o;
};

export const mapRefToInputRef = (
  useFormRegisterReturn: UseFormRegisterReturn
) => {
  const { ref, ...rest } = useFormRegisterReturn;
  return {
    inputRef: ref,
    ...rest,
  };
};
