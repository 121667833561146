import { sessionStorageUtil } from "_foundationExt/utils/storageUtil";

// check if we're starting with a scheme or the same scheme should be used
export const getHostWithScheme = (host?: string | null) =>
  host?.lastIndexOf("http:", 0) === 0 ||
  host?.lastIndexOf("https:", 0) === 0 ||
  host?.lastIndexOf("//", 0) === 0
    ? `${host}`
    : `https://${host}`;

/**
 * session storage PBE stores all partnumbers that added on predictive basket slider or list
 */

export const getSession = (key: string): string[] => {
  const partnumbers = sessionStorageUtil.get(key);
  if (partnumbers) return partnumbers.split(",");
  return [];
};

export const setSession = (key: string, partnumber: string): void => {
  const partnumbers = getSession(key);
  if (partnumbers.includes(partnumber)) return;
  partnumbers.push(partnumber);
  sessionStorageUtil.set(key, partnumbers.join(","));
};

export const removeSession = (key: string, partnumber: string): void => {
  let partnumbers = getSession(key);
  partnumbers = partnumbers.filter((p) => p !== partnumber);
  sessionStorageUtil.set(key, partnumbers.join(","));
};

export const countSession = (key: string): number => {
  const partnumbers = getSession(key);
  return partnumbers.length;
};

export const deleteSession = (key: string): void => {
  sessionStorageUtil.remove(key);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getSessionMap = (key: string): any => {
  const map = sessionStorageUtil.get(key);
  if (map) {
    return JSON.parse(map);
  }
  return {};
};

export const setSessionMap = (
  key: string,
  val1: string,
  val2: string
): void => {
  const map = getSessionMap(key);
  if (map) {
    map[val1] = val2;
    sessionStorageUtil.set(key, JSON.stringify(map));
  } else {
    map[val1] = val2;
    sessionStorageUtil.set(key, JSON.stringify(map));
  }
};

export const removeSessionMap = (key: string, val1: string): void => {
  const map = getSessionMap(key);
  if (map) {
    delete map[val1];
    sessionStorageUtil.set(key, JSON.stringify(map));
  }
};
