import React, { FC } from "react";

import { StyledAccordion } from "components/StyledUI";
import { CatalogGroupView } from "_redux/reducers";
import SideBarLink from "./SideBarLink";

interface SideBar2ndLevelProps {
  memorizedCloseSideBar: () => void;
  category2ndLevel: CatalogGroupView;
  navigationLevels: number;
}

const stopEventPropagation = (event) => event.stopPropagation();

const SideBar2ndLevel: FC<SideBar2ndLevelProps> = ({
  memorizedCloseSideBar,
  category2ndLevel,
  navigationLevels,
}) => {
  const childCategoryAccordion = (
    <StyledAccordion
      headline={
        <SideBarLink
          memorizedCloseSideBar={memorizedCloseSideBar}
          uniqueID={category2ndLevel.uniqueID}
          small={false}
          onClick={stopEventPropagation}
          onFocus={stopEventPropagation}>
          {category2ndLevel.name}
        </SideBarLink>
      }
      dark
      disabled={!category2ndLevel.catalogGroupView}
      small>
      <div>
        {category2ndLevel.catalogGroupView &&
          category2ndLevel.catalogGroupView.map((childChildCategory) => (
            <div key={childChildCategory.uniqueID}>
              <SideBarLink
                memorizedCloseSideBar={memorizedCloseSideBar}
                uniqueID={childChildCategory.uniqueID}>
                {childChildCategory.name}
              </SideBarLink>
            </div>
          ))}
      </div>
    </StyledAccordion>
  );

  const childCategoryLink = (
    <SideBarLink
      memorizedCloseSideBar={memorizedCloseSideBar}
      uniqueID={category2ndLevel.uniqueID}>
      {childCategoryAccordion}
    </SideBarLink>
  );

  if (navigationLevels === 2) {
    return (
      <SideBarLink
        memorizedCloseSideBar={memorizedCloseSideBar}
        uniqueID={category2ndLevel.uniqueID}>
        {category2ndLevel.name}
      </SideBarLink>
    );
  }

  return category2ndLevel.catalogGroupView
    ? childCategoryAccordion
    : childCategoryLink;
};

export default SideBar2ndLevel;
