import React, { FC } from "react";

import { CheckboxProps } from "@mui/material/Checkbox";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";

import { tss } from "tss-react/mui";
import { StyledCheckbox } from "./StyledCheckbox";

const useStyles = tss
  .withParams<{ noMarginTop?: boolean; size?: string }>()
  .create(({ theme, noMarginTop, size }) => ({
    root: {
      marginTop: size === "small" ? undefined : theme.spacing(2),
      marginLeft: 0,
      alignItems: "flex-start",
    },
    label: {
      marginTop: noMarginTop ? undefined : theme.spacing(0.5),
    },
  }));

type StyledFormCheckboxProps = Omit<
  FormControlLabelProps,
  "control" | "label"
> & {
  className?: string;
  size?: string;
  error?: boolean;
  checkboxProps: CheckboxProps;
  label?: React.ReactNode;
  noMarginTop?: boolean;
};

const StyledFormCheckbox: FC<StyledFormCheckboxProps> = ({
  className,
  size,
  error,
  checkboxProps,
  label,
  noMarginTop,
}) => {
  const { classes, cx } = useStyles({ noMarginTop, size });
  const { inputRef } = checkboxProps;

  return (
    <FormControlLabel
      classes={classes}
      className={cx(className)}
      label={label}
      control={
        <StyledCheckbox
          error={error}
          size={size === "small" ? "small" : "medium"}
          {...checkboxProps}
          inputRef={inputRef}
          inputProps={{
            name: checkboxProps.name,
          }}
        />
      }
    />
  );
};

export { StyledFormCheckbox };
