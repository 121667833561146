import fleischWildGefluegel from "assets/images/commodity-groups/Warengruppe-Fleisch-Wild-Gefluegel.jpg";
import fischSeafood from "assets/images/commodity-groups/Warengruppe-Fisch-Seafood.jpg";
import obstGemuese from "assets/images/commodity-groups/Warengruppe-Obst-Gemuese.jpg";
import wurstSchicken from "assets/images/commodity-groups/Warengruppe-Wurst-Schinken.jpg";
import feinkostFetteOele from "assets/images/commodity-groups/Warengruppe-Feinkost-Fette-Oele.jpg";
import moPro from "assets/images/commodity-groups/Warengruppe-Mopro.jpg";
import backwaren from "assets/images/commodity-groups/Warengruppe-Backwaren.jpg";
import convenience from "assets/images/commodity-groups/Warengruppe-Convenience.jpg";
import eis from "assets/images/commodity-groups/Warengruppe-Eis.jpg";
import sonstiges from "assets/images/commodity-groups/Warengruppe-Sonstiges.jpg";
import naehrmittelGewuerzeSaucen from "assets/images/commodity-groups/Warengruppe-Naehrmittel-Gewuerze-Saucen.jpg";
import konserven from "assets/images/commodity-groups/Warengruppe-Konserven.jpg";
import kaffeeTeeKakaoSuesswaren from "assets/images/commodity-groups/Warengruppe-Tee-Kaffee-Kakao.jpg";
import getraenkeWeinSpirituosen from "assets/images/commodity-groups/Warengruppe-Getraenke-Wein-Spirituosen.jpg";
import noneFood from "assets/images/commodity-groups/Warengruppe-Non-Food.jpg";

export const categoryImages = [
  {
    identifier: "10",
    image: fleischWildGefluegel,
  },
  {
    identifier: "20",
    image: fischSeafood,
  },
  {
    identifier: "30",
    image: obstGemuese,
  },
  {
    identifier: "40",
    image: wurstSchicken,
  },
  {
    identifier: "50",
    image: feinkostFetteOele,
  },
  {
    identifier: "60",
    image: moPro,
  },
  {
    identifier: "70",
    image: backwaren,
  },
  {
    identifier: "80",
    image: convenience,
  },
  {
    identifier: "90",
    image: eis,
  },
  {
    identifier: "95",
    image: sonstiges,
  },
  {
    identifier: "99",
    image: naehrmittelGewuerzeSaucen,
  },
  {
    identifier: "110",
    image: konserven,
  },
  {
    identifier: "120",
    image: kaffeeTeeKakaoSuesswaren,
  },
  {
    identifier: "130",
    image: getraenkeWeinSpirituosen,
  },
  {
    identifier: "140",
    image: noneFood,
  },
];

export const ARTICLEFLAG_ATTRIBUTE_IDENTIFIER = "ARTICLEFLAG";
export const ARTICLEORDERTIME_ATTRIBUTE_IDENTIFIER = "ARTICLEORDERTIME";
export const CONTRACT_IDENTIFIER = "_CONTRACT_";
export const ORDERSET_IDENTIFIER = "_ORDERSETPRODUCT_";
export const NEW_ATTRIBUTE_IDENTIFIER = "NEW";
export const PRODUCTOFFERS_ATTRIBUTE_IDENTIFIER = "PRODUCTOFFERS";
export const OPTAINARTICLE_ATTRIBUTE_IDENTIFIER = "OPTAINARTICLE";
export const SBBRAND_ATTRIBUTE_IDENTIFIER = "SBBRAND";
export const SEASONARTICLE_ATTRIBUTE_IDENTIFIER = "SEASONARTICLE";

export const CONTENT_HINT = "HINT";

export const characteristicsWithIcon = [
  OPTAINARTICLE_ATTRIBUTE_IDENTIFIER,
  CONTRACT_IDENTIFIER,
  ORDERSET_IDENTIFIER,
  PRODUCTOFFERS_ATTRIBUTE_IDENTIFIER,
  NEW_ATTRIBUTE_IDENTIFIER,
  SBBRAND_ATTRIBUTE_IDENTIFIER,
  SEASONARTICLE_ATTRIBUTE_IDENTIFIER,
  ARTICLEFLAG_ATTRIBUTE_IDENTIFIER,
];

export const GGA_ATTRIBUTE_IDENTIFIER = "tbd";
export const GU_ATTRIBUTE_IDENTIFIER = "tbd";
export const GST_ATTRIBUTE_IDENTIFIER = "tbd";
export const NODECLALLERGENE_ATTRIBUTE_IDENTIFIER = "NODECLALLERGENE";
export const NODECLADDITIVE_ATTRIBUTE_IDENTIFIER = "NODECLADDITIVE";
export const BIO_ATTRIBUTE_IDENTIFIER = "BIO";
export const MSC_ATTRIBUTE_IDENTIFIER = "MSC";
export const ASC_ATTRIBUTE_IDENTIFIER = "ASC";
export const FAIRTRADE_ATTRIBUTE_IDENTIFIER = "tbd";
export const WITHOUTGENTECHNIC_ATTRIBUTE_IDENTIFIER = "tbd";
export const FOODFORMVALUES_ATTRIBUTE_IDENTIFIER = "FOODFORMVALUES";
export const LACTOSEFREE_ATTRIBUTE_IDENTIFIER = "LACTOSEFREE";
export const GLUTENFREE_ATTRIBUTE_IDENTIFIER = "GLUTENFREE";
export const CLIMATICNEUTRAL_ATTRIBUTE_IDENTIFIER = "tbd";

export const nutritionCharacteristicsWithIcon = [
  // GGA_ATTRIBUTE_IDENTIFIER,
  // GU_ATTRIBUTE_IDENTIFIER,
  // GST_ATTRIBUTE_IDENTIFIER,
  NODECLALLERGENE_ATTRIBUTE_IDENTIFIER,
  NODECLADDITIVE_ATTRIBUTE_IDENTIFIER,
  BIO_ATTRIBUTE_IDENTIFIER,
  MSC_ATTRIBUTE_IDENTIFIER,
  ASC_ATTRIBUTE_IDENTIFIER,
  // FAIRTRADE_ATTRIBUTE_IDENTIFIER,
  // WITHOUTGENTECHNIC_ATTRIBUTE_IDENTIFIER,
  FOODFORMVALUES_ATTRIBUTE_IDENTIFIER,
  LACTOSEFREE_ATTRIBUTE_IDENTIFIER,
  GLUTENFREE_ATTRIBUTE_IDENTIFIER,
  // CLIMATICNEUTRAL_ATTRIBUTE_IDENTIFIER,
];

export const FOODFORMVALUES_ATTRIBUTE_IDENTIFIER_NUTRITION_CHARACTERISTICS_WITH_ICON_INDEX =
  nutritionCharacteristicsWithIcon.indexOf(FOODFORMVALUES_ATTRIBUTE_IDENTIFIER);

export const PROTECTEDGEOGRAPHICALINDICATION_VALUE =
  "Geschützte Geografische Angabe (g.g.A)";
export const PROTECTEDDESIGNATIONOFORIGIN_VALUE =
  "Geschützte Ursprungsbezeichnung (g.U.)";
export const GUARANTEEDTRADITIONALSPECIALITY_VALUE =
  "Garantiert traditionelle Spezialität (g.t.S.)";
export const WITHOUTGENETICTECHNOLOGY_VALUE = "Ohne Gentechnik";
export const FAIRTRADE_VALUE = "Fairtrade";

export const CONTENT_HINTS = [
  PROTECTEDGEOGRAPHICALINDICATION_VALUE,
  PROTECTEDDESIGNATIONOFORIGIN_VALUE,
  GUARANTEEDTRADITIONALSPECIALITY_VALUE,
  WITHOUTGENETICTECHNOLOGY_VALUE,
  FAIRTRADE_VALUE,
];

export const VEGAN_FOODFORMVALUE_IDENTIFIER = "Vegan";
export const VEGETARIAN_FOODFORMVALUE_IDENTIFIER = "Vegetarisch";
export const HALAL_FOODFORMVALUE_IDENTIFIER = "Halal";

export const foodFormValuesWithIcon = [
  FOODFORMVALUES_ATTRIBUTE_IDENTIFIER + VEGAN_FOODFORMVALUE_IDENTIFIER,
  FOODFORMVALUES_ATTRIBUTE_IDENTIFIER + VEGETARIAN_FOODFORMVALUE_IDENTIFIER,
  FOODFORMVALUES_ATTRIBUTE_IDENTIFIER + HALAL_FOODFORMVALUE_IDENTIFIER,
];

export const HIDE_ATTRIBUTES_WITH_NULL_VALUE = ["NETWEIGHT2"];
export const ATTRIBUTES_NULL_VALUE = ["0", "0,0"];
