export const ORDER_CONFIGS = {
  calculationUsage: "-1,-2,-3,-4,-5,-6,-7",
  calculateOrder: "1",
  inventoryValidation: true,
  allocate: "***",
  backOrder: "***",
  remerge: "***",
  check: "*n",
  sortOrderItemBy: "orderItemID",
};

export const MINICART_CONFIGS = {
  maxItemsToShow: 3,
};

export const PAYMENT_CONFIGS = {
  maxNumPayment: 3,
};

export const MINICART_ORDERITEMS_LIMIT = 20;
