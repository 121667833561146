/* tslint:disable */
/* eslint-disable */
/**
 * EFood Storefront API
 * This is the EFood Storefront API specification.  Operations are grouped by tags.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { GetProductProximities200Response } from '../../../../com/telekom-mms/efood/api/model';
/**
 * ProductProximitiesApi - axios parameter creator
 * @export
 */
export const ProductProximitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get product proximity information for article.
         * @param {string} storeId The store identifier
         * @param {string} articleId The article id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductProximities: async (storeId: string, articleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getProductProximities', 'storeId', storeId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('getProductProximities', 'articleId', articleId)
            const localVarPath = `/store/{storeId}/efood/productproximities/@self`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (articleId !== undefined) {
                localVarQueryParameter['articleId'] = articleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductProximitiesApi - functional programming interface
 * @export
 */
export const ProductProximitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductProximitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get product proximity information for article.
         * @param {string} storeId The store identifier
         * @param {string} articleId The article id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductProximities(storeId: string, articleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductProximities200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductProximities(storeId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductProximitiesApi - factory interface
 * @export
 */
export const ProductProximitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductProximitiesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get product proximity information for article.
         * @param {string} storeId The store identifier
         * @param {string} articleId The article id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductProximities(storeId: string, articleId: string, options?: any): AxiosPromise<GetProductProximities200Response> {
            return localVarFp.getProductProximities(storeId, articleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductProximitiesApi - object-oriented interface
 * @export
 * @class ProductProximitiesApi
 * @extends {BaseAPI}
 */
export class ProductProximitiesApi extends BaseAPI {
    /**
     * 
     * @summary Get product proximity information for article.
     * @param {string} storeId The store identifier
     * @param {string} articleId The article id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductProximitiesApi
     */
    public getProductProximities(storeId: string, articleId: string, options?: any) {
        return ProductProximitiesApiFp(this.configuration).getProductProximities(storeId, articleId, options).then((request) => request(this.axios, this.basePath));
    }
}
