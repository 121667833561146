import { createReducer, AnyAction } from "@reduxjs/toolkit";
import {
  localStorageUtil,
  storageSessionHandler,
} from "_foundationExt/utils/storageUtil";
import {
  PERSONALIZATION_ID,
  INITIATED_FROM_STORAGE,
  TRACKING_CHOICE,
  PRICE_CHANGES,
  OPTIONS_TRACKING_CHOICE,
  NEWSLETTER_VALIDATION_CODE,
} from "constants/user";

import initStates from "./initStates";
import { OrderItemWithOrderSplit } from "./reducerStateInterface";
import {
  LOGIN_SUCCESS_ACTION,
  LOGOUT_SUCCESS_ACTION,
  loginErrorAction,
  REGISTRATION_SUCCESS_ACTION,
  registrationErrorAction,
  NEWSLETTER_SUCCESS_ACTION,
  NEWSLETTER_ERROR_ACTION,
  INIT_USER_FROM_STORAGE_SUCCESS_ACTION,
  FETCH_USER_DETAILS_SUCCESS_ACTION,
  FETCH_CONTRACTS_SUCCESS_ACTION,
  FETCH_DELIVERY_DATES_SUCCESS_ACTION,
  FETCH_DELIVERY_DATE_SUCCESS_ACTION,
  FETCH_CUSTOMER_PRODUCTLISTS_ACTION,
  FETCH_ENTITLED_ORGANIZATIONS_ACTION,
  FETCH_OFFERS_SUCCESS_ACTION,
  FETCH_EFOOD_CONTRACTS_SUCCESS_ACTION,
  FETCH_ORDER_HISTORY_ACTION,
  PUT_SELECTED_PRODUCTLIST,
  UPDATE_PRODUCTLIST_FILTER,
  SET_TRACKING_CHOICE_ACTION,
  SET_STORAGE_LOCATION_CHANGED,
  ACCEPT_PRICE_CHANGES_ACTION,
  UPDATE_DELIVERY_DATE_SUCCESS_ACTION,
  ORDER_SPLIT_HINT_VIEWED_ACTION,
  RESET_PRODUCTLIST_FILTER,
} from "_redux/actions/user";
import { PRODUCT_VIEW_MODE_GALLERY } from "components/productList/listViewSwitch/ListViewConstants";

import * as cookieUtils from "tools/cookieUtils";
import {
  CART_GET_SUCCESS,
  ITEM_MODIFY_SUCCESS,
} from "_redux/action-types/order";
import {
  PREDICTIVE_BASKET_FOUND,
  REWARDS_HANDLED,
  SET_ORDER_SPLIT_ITEMS,
} from "_redux/action-types/user";
import { PERMANENT_STORE_DAYS } from "configs/common";

const clearUserState = (userState: any) => {
  for (var variableKey in userState) {
    if (
      variableKey !== PERSONALIZATION_ID &&
      variableKey !== INITIATED_FROM_STORAGE &&
      variableKey !== NEWSLETTER_VALIDATION_CODE &&
      variableKey !== TRACKING_CHOICE &&
      userState.hasOwnProperty(variableKey)
    ) {
      delete userState[variableKey];
    }
  }
};

const clearProductViewModeCookie = () => {
  const productViewModeCookies = cookieUtils.getCookiesWithPrefix(
    PRODUCT_VIEW_MODE_GALLERY
  );
  productViewModeCookies.forEach((productViewModeCookie) =>
    cookieUtils.deleteCookie(productViewModeCookie)
  );
};

const setOrderSplitItems = (
  state,
  orderItemsWithOrderSplit: OrderItemWithOrderSplit[]
) => {
  orderItemsWithOrderSplit.forEach((orderItemWithOrderSplit) => {
    if (
      !state.orderItemsWithOrderSplit.some(
        (existingOrderItemWithOrderSplit) =>
          existingOrderItemWithOrderSplit.orderItemId ==
          orderItemWithOrderSplit.orderItemId
      )
    ) {
      state.orderItemsWithOrderSplit = [
        ...state.orderItemsWithOrderSplit,
        ...orderItemsWithOrderSplit,
      ];
    }
  });
  storageSessionHandler.saveCurrentUser(state);
};

/**
 * User reducer
 * handles states used by user related components
 * @param state State object managed by user reducer
 * @param action The dispatched action
 */
const userReducer = createReducer(initStates.user, (builder) => {
  builder.addCase(LOGIN_SUCCESS_ACTION, (state, action: AnyAction) => {
    Object.assign(state, action.payload, {
      userLoggedIn: true,
      isGuest: false,
    });
    storageSessionHandler.saveCurrentUser(state);
    //set personalizationID to localStorage
    const { personalizationID } = action.payload;
    localStorageUtil.set(
      PERSONALIZATION_ID,
      personalizationID,
      PERMANENT_STORE_DAYS
    );
  });

  builder.addCase(
    INIT_USER_FROM_STORAGE_SUCCESS_ACTION,
    (state, action: AnyAction) => {
      clearUserState(state);
      const cookieChoice = cookieUtils.getCookie("cookieChoice") ?? "1";
      const validCookieValue = OPTIONS_TRACKING_CHOICE.includes(cookieChoice);
      if (action.payload != null) {
        Object.assign(state, action.payload, {
          [INITIATED_FROM_STORAGE]: true,
          [TRACKING_CHOICE]: validCookieValue ? cookieChoice : "1",
        });
      } else {
        Object.assign(state, {
          [INITIATED_FROM_STORAGE]: true,
          [TRACKING_CHOICE]: validCookieValue ? cookieChoice : "1",
        });
      }
    }
  );

  builder.addCase(loginErrorAction, (state, _action) => {
    Object.assign(state, { userLoggedIn: false, isGuest: false });
  });

  builder.addCase(LOGOUT_SUCCESS_ACTION, (state, _action) => {
    state.userLoggedIn = false;
    clearUserState(state);
    storageSessionHandler.removeCurrentUser();
    clearProductViewModeCookie();
  });

  builder.addCase(REGISTRATION_SUCCESS_ACTION, (state, action: AnyAction) => {
    Object.assign(state, action.payload);
    state.userRegistration = true;
  });

  builder.addCase(registrationErrorAction, (state, _action) => {
    state.userRegistration = false;
    state.isGuest = true;
    return state;
  });

  builder.addCase(NEWSLETTER_SUCCESS_ACTION, (state, _action) => {
    delete state[NEWSLETTER_VALIDATION_CODE];
  });

  builder.addCase(NEWSLETTER_ERROR_ACTION, (state, action: AnyAction) => {
    state.newsletterValidationCode = action.payload;
  });

  builder.addCase(
    FETCH_USER_DETAILS_SUCCESS_ACTION,
    (state, action: AnyAction) => {
      Object.assign(state, { details: action.payload });
      storageSessionHandler.saveCurrentUser(state);
    }
  );

  builder.addCase(FETCH_ORDER_HISTORY_ACTION, (state, action: AnyAction) => {
    Object.assign(state, { orderHistory: action.payload });
    storageSessionHandler.saveCurrentUser(state);
  });

  builder.addCase(
    FETCH_CONTRACTS_SUCCESS_ACTION,
    (state, action: AnyAction) => {
      Object.assign(state, { contracts: action.payload });
      storageSessionHandler.saveCurrentUser(state);
    }
  );

  builder.addCase(FETCH_DELIVERY_DATES_SUCCESS_ACTION, (state, action) => {
    state.deliveryDates = action.payload;
    storageSessionHandler.saveCurrentUser(state);
  });
  builder.addCase(FETCH_DELIVERY_DATE_SUCCESS_ACTION, (state, action) => {
    state.stockDeliveryDate = action.payload;
    storageSessionHandler.saveCurrentUser(state);
  });
  builder.addCase(
    FETCH_CUSTOMER_PRODUCTLISTS_ACTION,
    (state, action: AnyAction) => {
      Object.assign(state, { customerProductLists: action.payload });
      storageSessionHandler.saveCurrentUser(state);
    }
  );
  builder.addCase(
    FETCH_ENTITLED_ORGANIZATIONS_ACTION,
    (state, action: AnyAction) => {
      Object.assign(state, { accountCheck: action.payload.accountCheck });
      Object.assign(state, {
        entitledOrganizations: action.payload.entitledOrganizations,
      });
      storageSessionHandler.saveCurrentUser(state);
    }
  );

  builder.addCase(FETCH_OFFERS_SUCCESS_ACTION, (state, action: AnyAction) => {
    Object.assign(state, { offers: action.payload });
    storageSessionHandler.saveCurrentUser(state);
  });

  builder.addCase(
    FETCH_EFOOD_CONTRACTS_SUCCESS_ACTION,
    (state, action: AnyAction) => {
      Object.assign(state, { efoodContracts: action.payload });
      storageSessionHandler.saveCurrentUser(state);
    }
  );

  builder.addCase(PUT_SELECTED_PRODUCTLIST, (state, action: AnyAction) => {
    Object.assign(state, { selectedProductListId: action.payload });
    storageSessionHandler.saveCurrentUser(state);
  });

  builder.addCase(UPDATE_PRODUCTLIST_FILTER, (state, action: AnyAction) => {
    Object.assign(state, { selectedProductListFilter: action.payload });
    storageSessionHandler.saveCurrentUser(state);
  });

  builder.addCase(RESET_PRODUCTLIST_FILTER, (state) => {
    if (state.selectedProductListFilter?.length > 0) {
      Object.assign(state, { selectedProductListFilter: [] });
      storageSessionHandler.saveCurrentUser(state);
    }
  });

  builder.addCase(SET_TRACKING_CHOICE_ACTION, (state, action) => {
    state.trackingChoice = action.payload;
    storageSessionHandler.saveCurrentUser(state);
  });

  builder.addCase(ACCEPT_PRICE_CHANGES_ACTION, (state, _action) => {
    delete state[PRICE_CHANGES];
    storageSessionHandler.saveCurrentUser(state);
  });
  builder.addCase(
    UPDATE_DELIVERY_DATE_SUCCESS_ACTION,
    (state, action: AnyAction) => {
      const { response } = action;
      if (response) {
        const { data } = response;
        if (data) {
          const { priceChanges } = data;
          if (priceChanges) {
            state[PRICE_CHANGES] = priceChanges;
            storageSessionHandler.saveCurrentUser(state);
          }
        }
      }
    }
  );
  builder.addCase(CART_GET_SUCCESS, (state, action: AnyAction) => {
    const response = action.response;
    if (response) {
      const orderRewardOptionIds: string[] = response.rewards
        ? response.rewards.flatMap((reward) =>
            reward.rewardOptions.map(
              (rewardOption) => rewardOption.rewardOptionId
            )
          )
        : [];
      state.rewardOptionIdsHandled = state.rewardOptionIdsHandled
        ? state.rewardOptionIdsHandled.filter((rewardOptionIdHandled) => {
            return orderRewardOptionIds.includes(rewardOptionIdHandled);
          })
        : [];
      state.orderItemsWithOrderSplit = [];
      storageSessionHandler.saveCurrentUser(state);
    }
  });
  builder.addCase(REWARDS_HANDLED, (state, action: AnyAction) => {
    state.rewardOptionIdsHandled = [
      ...state.rewardOptionIdsHandled,
      ...action.payload,
    ];
    storageSessionHandler.saveCurrentUser(state);
  });
  builder.addCase(SET_ORDER_SPLIT_ITEMS, (state, action: AnyAction) => {
    setOrderSplitItems(state, action.payload);
  });
  builder.addCase(ITEM_MODIFY_SUCCESS, (state, action: AnyAction) => {
    const orderItem = action.orderItem;
    if (orderItem && orderItem.availability.withOrderSplit) {
      setOrderSplitItems(state, [orderItem]);
    }
  });
  builder.addCase(ORDER_SPLIT_HINT_VIEWED_ACTION, (state, _action) => {
    state.orderItemsWithOrderSplit = [];
    storageSessionHandler.saveCurrentUser(state);
  });

  builder.addCase(SET_STORAGE_LOCATION_CHANGED, (state, _action) => {
    state.lastLocationChange = Date.now();
  });

  builder.addCase(PREDICTIVE_BASKET_FOUND, (state, action: AnyAction) => {
    const isCentralPurchaserOnOrgChange =
      action.payload.isCentralPurchaserOnOrgChange;
    if (action.response.data) {
      const { data } = action.response;

      state.prediction = data;
      storageSessionHandler.saveCurrentUser(state);

      const { cluster, individual, customerNumber } = data;
      const storageKey = "PB-" + customerNumber;

      try {
        if (cluster) {
          const unsortedMap = new Map<string, number>(Object.entries(cluster));
          const unsortedArray = [...unsortedMap];
          let sortedArray = unsortedArray.sort(
            ([keyA, valueA], [keyB, valueB]) => {
              const a = valueA as number;
              const b = valueB as number;
              return b - a;
            }
          );

          const valuesFromLocalStorage = localStorageUtil.get(storageKey);
          if (valuesFromLocalStorage) {
            let reset = false;
            const { predictivBasketCount } = action.payload;
            const individualKeys = Object.keys(individual);
            let updateItems = Math.round(
              (individualKeys.length / 100) * predictivBasketCount
            );
            const storedValuesMap = new Map(
              Object.entries(valuesFromLocalStorage)
            );
            sortedArray.forEach(([key, value], index) => {
              const storedValue = (storedValuesMap.get(key) as number) || 0;
              sortedArray[index] = [key, storedValue];
            });
            sortedArray = sortedArray
              .sort(([keyA, valueA], [keyB, valueB]) => {
                const a = valueA as number;
                const b = valueB as number;
                return b - a;
              })
              .map(([key, value]) => {
                if (updateItems > 0) {
                  let newValue = ((value as number) * 1000 - 1000) / 1000;
                  updateItems--;
                  if (newValue < -1) reset = true;
                  return [key, newValue];
                }

                return [key, value];
              });

            if (reset) {
              sortedArray = sortedArray.map(([key, value]) => {
                let newValue = ((value as number) * 1000 + 1000) / 1000;
                return [key, newValue];
              });
            }

            localStorageUtil.set(storageKey, Object.fromEntries(sortedArray));
          } else {
            localStorageUtil.set(storageKey, Object.fromEntries(sortedArray));
          }
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      if (isCentralPurchaserOnOrgChange) {
        state.prediction = undefined;
        storageSessionHandler.saveCurrentUser(state);
      }
    }
  });
});

export default userReducer;
