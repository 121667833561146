import React, { FC, PropsWithChildren } from "react";

interface Props {
  condition: boolean;
  renderWrapper: (children: React.ReactNode) => React.ReactElement;
}

const ConditionalWrapper: FC<PropsWithChildren<Props>> = ({
  condition,
  renderWrapper,
  children,
}) => (condition ? renderWrapper(<>{children}</>) : <>{children}</>);

export default ConditionalWrapper;
