import React from "react";
import { useMatomo } from "components/matomo";

interface Params {
  searchTerm: string;
  searchResult: number;
  searchCategory: string;
}

const TrackSearch: React.FC<Params> = ({
  searchTerm,
  searchResult,
  searchCategory,
}) => {
  const matomoInstance = useMatomo();

  React.useEffect(() => {
    if (matomoInstance && searchTerm) {
      matomoInstance.trackSiteSearch({
        keyword: searchTerm,
        documentTitle: document.title,
        href: window.location.href,
        category: searchCategory,
        count: searchResult,
      });
    }
  }, [matomoInstance, searchTerm, searchCategory, searchResult]);

  return null;
};

export default TrackSearch;
