import React, { FC } from "react";

import { Box } from "@mui/material";
import { StyledButton } from "components/StyledUI";
import { ReactComponent as FilterIcon } from "assets/icons/streamline-icon-setting-slider.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { Filter } from "types/Search";
import { CustomerProductlist } from "types/CustomerProductlist";

interface FilterButtonProps {
  showFilterSection: boolean;
  setShowFilterSection: React.Dispatch<React.SetStateAction<boolean>>;
  filterList: Filter[];
  selectedProductlistFilter: CustomerProductlist[];
}

const FilterButton: FC<FilterButtonProps> = ({
  showFilterSection,
  setShowFilterSection,
  filterList,
  selectedProductlistFilter,
}) => {
  if (!filterList?.length && !selectedProductlistFilter?.length) {
    return null;
  }

  return (
    <Box mr={{ xs: 1, sm: 2 }}>
      {showFilterSection ? (
        <StyledButton
          startIcon={<CloseIcon />}
          onClick={() => setShowFilterSection(false)}
        />
      ) : (
        <StyledButton
          variant="outlined"
          startIcon={<FilterIcon />}
          onClick={() => setShowFilterSection(true)}
        />
      )}
    </Box>
  );
};

export default FilterButton;
