export const URL_PARAMS = {
  PRODUCT_ID: "id",
  CONTRACT_ID: "contractId",
  EXCLUDE_NOTE_SEARCH: "excludeNoteSearch",
  ORDER_BY: "orderBy",
  PAGE_NUMBER: "pageNumber",
  PAGE_SIZE: "pageSize",
  PAGING_SHIFT: "pagingShift",
  PROFILE_NAME: "profileName",
  PRODUCT_LIST: "productList",
  SEARCH_SOURCE: "searchSource",
  PART_NUMBERS: "partNumbers",
  SEARCH_TERM: "searchTerm",
} as const;

export const CUSTOM_HEADER = {
  X_EFOOD_REQUEST_RESOURCE: "x-efood-request-resource",
  X_EFOOD_REQUEST_ORIGIN_METHOD: "x-efood-request-origin-method",
  X_EFOOD_OFFLINE_RESPONSE_TYPE: "x-efood-offline-response-type",
  X_EFOOD_OFFLINE_SYNC_REQUEST: "x-efood-offline-sync-request",
} as const;

export enum Resource {
  Availability = "availability",
  Cart = "cart",
  ProductView = "productview",
}

export const PROVISIONAL_ORDER_ITEM_ID_PREFIX = "oiid_";

export const isProvisionalOrderItemId = (id?: string) =>
  !!id && id.startsWith(PROVISIONAL_ORDER_ITEM_ID_PREFIX);
