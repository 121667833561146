import React, { useMemo } from "react";

import { useBreakpoints } from "_foundationExt/hooks/useBreakpoints";
import { GridProps, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Quantity from "components/quantity/Quantity";
import ProductImage from "components/productImage";
import ProductAttributes from "components/productAttributes/ProductAttributes";
import { EFoodPrice, Matrix, Product } from "types/Product";
import { formatCurrency } from "tools/stringUtils";
import { StyledProtectable, UnstyledLink } from "components/StyledUI";
import { TrackShowType } from "components/matomo";
import ProductOptions from "components/productOptions";
import ConditionalWrapper from "components/helpers/ConditionalWrapper";
import { ROUTES } from "constants/routes";
import { useAppSelector } from "_redux/hooks";
import { loginStatusSelector } from "_redux/selectors/user";
import { useEFoodConfig } from "_foundationExt/hooks";
import { tss } from "tss-react/mui";
import type { ZIndexWithExtension } from "theme/zIndexExtension";

const mergeData = (matrix: Matrix[], prices?: EFoodPrice[]) => {
  const matrixEntry = matrix.find((item) => item.handlingCode === "1");
  const priceEntry = prices?.find(
    (item) => item.matrixShortDescription === matrixEntry?.shortDesc
  );

  return {
    ...matrixEntry,
    ...priceEntry,
  };
};

const useStyles = tss.create(({ theme }) => ({
  tileContainer: {
    position: "relative",
  },
  productImage: {
    maxWidth: "180px",
    "& > img": {
      margin: "auto 0",
    },
  },
  opWrapper: {
    position: "relative",
  },
  productImageWrapper: {
    margin: "10px auto",
    borderRadius: "8px;",
    width: "180px",
    height: "180px",
    maxWidth: "180px",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    "@media (max-width:1280px)": {
      width: "151px",
      height: "151px",
    },
  },
  productOptions: {
    position: "absolute",
    right: "0px",
    bottom: "0px",
    borderRadius: "6px",
    backgroundColor: "#fff",
    zIndex: (theme.zIndex as ZIndexWithExtension).extension.productOptions,
  },
  productAttributes: {
    position: "absolute",
    right: "0px",
    bottom: "36px",
    zIndex: (theme.zIndex as ZIndexWithExtension).extension.productOptions,
  },
  productTitle: {
    marginBottom: "22px",
    fontFamily: "NunitoSans",
    minHeight: "48px",
    textAlign: "left",
    "@media (max-width:1280px)": {
      marginBottom: "0px",
    },
  },
  productPriceContainer: {
    marginBottom: theme.spacing(1),
    fontSize: "14px",
    textAlign: "left",
  },
  productPrice: {
    float: "right",
  },
  ellipsis: {
    marginTop: "8px",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  tileQuantityField: {
    "& .availabilityHint": {
      textAlign: "left",
      position: "relative",
    },
    "& .availabilityHint .availabilityHint_tooltip": {
      textAlign: "right",
      position: "absolute",
      right: 0,
    },
  },
}));

interface ProductTileProps {
  product: Product;
  trackType?: TrackShowType;
  refetch?: () => void;
}

const ProductTile: React.FC<ProductTileProps & GridProps> = ({
  product,
  trackType,
  refetch,
  ...rest
}) => {
  const { classes, cx } = useStyles();
  const efood = useEFoodConfig();
  const loggedIn = useAppSelector(loginStatusSelector);
  const enableLink = loggedIn
    ? efood.productdetailCustomer || false
    : efood.productdetailGuest || false;
  const { xs, sm, md, lg } = useBreakpoints();
  const getImageSize = (): string => {
    if (xs || sm || md) return "151";
    if (lg) return "180";
    return "180";
  };

  const priceMatrix = useMemo(
    () => mergeData(product.matrix, product.prices),
    [product.matrix, product.prices]
  );

  return (
    <Grid container {...rest} className={classes.tileContainer}>
      <Grid xs={12} className={classes.opWrapper}>
        <div className={classes.productAttributes}>
          <ProductAttributes
            attributes={product.attributes}
            inContract={product.inContract}
            smallVariant
            isGalleryView
          />
        </div>
        <div className={cx("productOptions", classes.productOptions)}>
          <ProductOptions
            productId={product.uniqueID}
            customerProductlists={product.customerProductlists}
            refetch={refetch}
            inContract={product.inContract}
          />
        </div>
        <Box className={classes.productImageWrapper}>
          <ProductImage
            product={product}
            size={getImageSize()}
            centered
            className={classes.productImage}
          />
        </Box>
      </Grid>
      <Grid xs={12} className={classes.productTitle}>
        <ConditionalWrapper
          condition={enableLink}
          renderWrapper={(children) => (
            <UnstyledLink
              to={`${ROUTES.PRODUCT_DETAIL}/${product.eFoodPartnumber}`}>
              {children}
            </UnstyledLink>
          )}>
          <Typography variant="h5" component="h3" className={classes.ellipsis}>
            {product.name}
          </Typography>
        </ConditionalWrapper>
      </Grid>
      <Grid xs={12} className={classes.productPriceContainer}>
        {priceMatrix.longDesc}
        {lg && (
          <div className={classes.productPrice}>
            <StyledProtectable permission="show.price">
              <b>{formatCurrency(priceMatrix.price)}</b>
            </StyledProtectable>
          </div>
        )}
      </Grid>
      {product.buyable && (
        <Grid xs={12} className={classes.tileQuantityField}>
          <Quantity
            data={product}
            type="espot"
            trackType={trackType}
            additionalQuantityInformationClass="availabilityHint"
            galleryView
            formattedPrice={formatCurrency(priceMatrix.price)}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(ProductTile);
