import React, { FC } from "react";

import StyledScrollUp from "./StyledScrollUp";
import StyledScrollUpWrapper from "./StyledScrollUpWrapper";

const StyledScrollUpContainer: FC = () => (
  <StyledScrollUpWrapper>
    <StyledScrollUp />
  </StyledScrollUpWrapper>
);

export default StyledScrollUpContainer;
