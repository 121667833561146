import { INITIAL_SYNC_STATE, SyncState, SyncStateAction } from "./types";

const syncStateReducer = (
  state: SyncState,
  action: SyncStateAction
): SyncState => {
  const { payload, type } = action;

  switch (type) {
    case "SYNC_START": {
      return {
        loading: true,
        progress: 0,
        recordSetTotal: undefined,
        recordSetCount: undefined,
        recordSetStartNumber: undefined,
        error: undefined,
      };
    }
    case "SYNC_PROGRESS_UPDATE": {
      return {
        ...state,
        progress: payload?.progress ?? 0,
        recordSetTotal: payload?.recordSetTotal ?? state.recordSetTotal,
        recordSetCount: payload?.recordSetCount ?? state.recordSetCount,
        recordSetStartNumber:
          payload?.recordSetStartNumber ?? state.recordSetStartNumber,
      };
    }
    case "SYNC_SUCCESS": {
      return {
        ...state,
        loading: false,
        progress: 0,
        recordSetTotal: payload?.recordSetTotal ?? 0,
        recordSetCount: payload?.recordSetCount ?? 0,
        recordSetStartNumber: payload?.recordSetStartNumber ?? 0,
      };
    }
    case "SYNC_ERROR": {
      return {
        ...state,
        loading: false,
        error: payload?.error,
      };
    }
    case "SYNC_RESET": {
      return INITIAL_SYNC_STATE;
    }
    default:
      return state;
  }
};

export default syncStateReducer;
