import { ActivityDate, MarketingSpotData } from "types/Marketing";

const getActivityData = (
  marketingSpotData?: MarketingSpotData
): ActivityDate => {
  const result: ActivityDate = {};
  if (marketingSpotData?.baseMarketingSpotActivityData) {
    marketingSpotData.baseMarketingSpotActivityData.forEach(
      (baseMarketingspotData) => {
        if (baseMarketingspotData.activityIdentifierName) {
          result.activityIdentifierName =
            baseMarketingspotData.activityIdentifierName;
        }
        if (baseMarketingspotData.contentName) {
          result.contentName = baseMarketingspotData.contentName;
        }
        if (baseMarketingspotData.properties) {
          const filterKey = baseMarketingspotData.properties.filter(
            (e) => e.baseMarketingKey === "mktActivityDescription"
          );
          if (filterKey && filterKey.length > 0) {
            result.activityDescription = filterKey[0].baseMarketingValue;
          }
        }
      }
    );
  }
  return result;
};

export default getActivityData;
