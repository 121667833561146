import React, { FC } from "react";
import { Grid, Typography } from "@mui/material";
import {
  StyledDialog,
  StyledDialogTitle,
  StyledDialogContent,
  StyledProgress,
  StyledNotification,
} from "components/StyledUI";

interface ProgressDialogProps {
  closeDialog: () => void;
  showDialog: boolean;
  message: string;
  closable?: boolean;
  errorMessage?: string;
}

const ProgressDialog: FC<ProgressDialogProps> = ({
  closeDialog,
  showDialog,
  message,
  closable,
  errorMessage,
}) => {
  const handleCloseClick = () => {
    closeDialog();
  };

  if (closable === false) {
    return (
      <StyledDialog open={showDialog}>
        <StyledDialogTitle id="dialog-title">
          <Typography variant="h2">{message}</Typography>
        </StyledDialogTitle>

        <StyledDialogContent>
          <Grid container justifyContent="center">
            <StyledProgress />
          </Grid>
        </StyledDialogContent>
      </StyledDialog>
    );
  }

  return (
    <StyledDialog open={showDialog} onClose={handleCloseClick}>
      <StyledDialogTitle id="dialog-title" onClose={handleCloseClick}>
        <Typography variant="h2">{message}</Typography>
      </StyledDialogTitle>

      <StyledDialogContent>
        <Grid container justifyContent="center">
          {errorMessage ? (
            <StyledNotification severity="error">
              {errorMessage}
            </StyledNotification>
          ) : (
            <StyledProgress />
          )}
        </Grid>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default ProgressDialog;
