import React, { FC, PropsWithChildren } from "react";
import { useSite } from "_foundationExt/hooks/useSite";
import { useInView } from "react-intersection-observer";
import { TrackShowType, SessionUtils, useMatomo } from "components/matomo";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "_redux/hooks";
import { userPersonalizationIdSelector } from "_redux/selectors/user";

interface Params {
  productId: string;
  trackType?: TrackShowType;
}

const TrackProduct: FC<PropsWithChildren<Params>> = ({
  children,
  productId,
  trackType,
}) => {
  const { currentSite } = useSite();
  const { t } = useTranslation();
  const persinalizationID = useAppSelector(userPersonalizationIdSelector);
  const matomoInstance = useMatomo();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
    trackVisibility: true,
    delay: 100,
  });

  React.useEffect(() => {
    if (currentSite && inView && productId && trackType && persinalizationID) {
      const shownProducts = trackType ? SessionUtils.getSession("show") : [];
      if (matomoInstance && trackType && !shownProducts.includes(trackType)) {
        // when product is in viewport, track pb espot shown
        const event = {
          category: `${t(
            `prediction.event.type.${trackType}: ${t(
              "prediction.event.category"
            )}`
          )}`,
          action: `${t(`prediction.event.type.${trackType}`)}: ${t(
            "prediction.event.action.show"
          )}`,
          name: persinalizationID,
        };
        matomoInstance.trackEvent(event);
        SessionUtils.setSession("show", trackType);
      }
      // save product in viewport in session
      SessionUtils.setSession(trackType, productId);
    }
  }, [
    currentSite,
    inView,
    productId,
    trackType,
    matomoInstance,
    persinalizationID,
    t,
  ]);

  return <div ref={ref}>{children}</div>;
};

export default TrackProduct;
