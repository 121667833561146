//Add item to cart
export const ITEM_ADD_REQUESTED = "ITEM_ADD_REQUESTED";
export const ITEM_ADD_SUCCESS = "ITEM_ADD_SUCCESS";
export const ITEM_ADD_ERROR = "ITEM_ADD_ERROR";

//Remove item to cart
export const ITEM_REMOVE_REQUESTED = "ITEM_REMOVE_REQUESTED";
export const ITEM_REMOVE_SUCCESS = "ITEM_REMOVE_SUCCESS";
export const ITEM_REMOVE_ERROR = "ITEM_REMOVE_ERROR";

//Update item in cart
export const ITEM_UPDATE_REQUESTED = "ITEM_UPDATE_REQUESTED";
export const ITEM_UPDATE_SUCCESS = "ITEM_UPDATE_SUCCESS";
export const ITEM_UPDATE_ERROR = "ITEM_UPDATE_ERROR";

//Put item in cart
export const ITEM_MODIFY_REQUESTED = "ITEM_MODIFY_REQUESTED";
export const ITEM_MODIFY_SUCCESS = "ITEM_MODIFY_SUCCESS";
export const ITEM_MODIFY_WARNING = "ITEM_MODIFY_WARNING";
export const ITEM_MODIFY_ERROR = "ITEM_MODIFY_ERROR";
export const ITEM_CHECK_AVAILABILITY_REQUESTED =
  "ITEM_CHECK_AVAILABILITY_REQUESTED";
export const ITEM_CHECK_AVAILABILITY_SUCCESS =
  "ITEM_CHECK_AVAILABILITY_SUCCESS";

//Get current user's cart
export const CART_FETCHING_REQUESTED = "CART_FETCHING_REQUESTED";
export const CART_GET_SUCCESS = "CART_GET_SUCCESS";
export const CART_GET_ERROR = "CART_GET_ERROR";
export const CART_CHECK_APPROVAL_SUCCESS = "CART_CHECK_APPROVAL_SUCCESS";
export const CART_PRECHECKOUT_ERROR = "CART_PRECHECKOUT_ERROR";
export const CART_CHECKOUT_ERROR = "CART_CHECKOUT_ERROR";

//Reset cart states
export const CART_RESET_REQUESTED = "CART_RESET_REQUESTED";
export const CART_RESET_SUCCESS = "CART_RESET_SUCCESS";
export const CART_RESET_ERROR = "CART_RESET_ERROR";

// Order comments
export const SET_ORDER_COMMENT_REQUESTED = "SET_ORDER_COMMENT_REQUESTED";
export const SET_ORDER_COMMENT_SUCCESS = "SET_ORDER_COMMENT_SUCCESS";
export const SET_ORDER_COMMENT_ERROR = "SET_ORDER_COMMENT_ERROR";
