import { useMemo } from "react";
import { type Theme, useMediaQuery } from "@mui/material";

interface BreakpointsResponse {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  rows: number;
}

const useBreakpoints = (): BreakpointsResponse => {
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.up("xs"));
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const lg = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  return useMemo(() => {
    let rows = 1;
    if (xs && sm && !md && !lg) rows = 2;
    if (xs && sm && md && !lg) rows = 3;
    if (xs && sm && md && lg) rows = 4;
    return { xs, sm, md, lg, rows };
  }, [xs, sm, md, lg]);
};

export { useBreakpoints };
