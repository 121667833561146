import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  orderTotalAdjustmentSelector,
  orderTotalProductSelector,
  orderTotalSelector,
  orderTotalShippingSelector,
} from "_redux/selectors/order";

const useOrderPriceSelector = () => {
  const total = useSelector(orderTotalSelector);
  const totalAdjustment = useSelector(orderTotalAdjustmentSelector);
  const totalProduct = useSelector(orderTotalProductSelector);
  const totalShipping = useSelector(orderTotalShippingSelector);

  return useMemo(
    () => ({
      total,
      totalAdjustment,
      totalProduct,
      totalShipping,
    }),
    [total, totalAdjustment, totalProduct, totalShipping]
  );
};

export default useOrderPriceSelector;
