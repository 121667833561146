import React, {
  FC,
  createContext,
  useContext,
  PropsWithChildren,
  useMemo,
} from "react";
import MatomoTracker from "@jonkoops/matomo-tracker";
import { trackingSelector } from "_redux/selectors/site";
import { trackingChoiceSelector } from "_redux/selectors/user";
import { matomoConfig } from "components/matomo/config";
import { useAppSelector } from "_redux/hooks";
import { getHostWithScheme } from "./utils";

export const MatomoContext = createContext<MatomoTracker | undefined>(
  undefined
);

export const useTrackingConfig = () => useAppSelector(trackingSelector);

export const useMatomo = () => {
  const matomoTracker = useContext(MatomoContext);

  return matomoTracker;
};

const MatomoProvider: FC<PropsWithChildren> = ({ children }) => {
  const trackingConfig = useTrackingConfig();
  const trackingChoice = useAppSelector(trackingChoiceSelector);
  const {
    matomo: { host, siteId },
  } = trackingConfig;

  const trackerInstance = useMemo(() => {
    if (
      host &&
      siteId != null &&
      trackingChoice != null &&
      Number(trackingChoice) >= 0
    ) {
      const urlBase = getHostWithScheme(host);

      const customParams = {
        siteId,
        urlBase,
      };

      return new MatomoTracker({
        ...matomoConfig,
        ...customParams,
      });
    }
    return undefined;
  }, [host, siteId, trackingChoice]);

  return (
    <MatomoContext.Provider value={trackerInstance}>
      {children}
    </MatomoContext.Provider>
  );
};

export default MatomoProvider;
