import { createAction } from "@reduxjs/toolkit";
import * as ACTIONTYPES from "../action-types/error";
import { ErrorReducerState } from "../reducers/reducerStateInterface";
import { NavigateFunction } from "react-router-dom";

export const CANCEL_SESSION_ERROR_ACTION = createAction<{
  navigate?: NavigateFunction;
}>(ACTIONTYPES.CANCEL_SESSION_ERROR);

export const HANDLE_SESSION_ERROR_ACTION = createAction<
  ErrorReducerState,
  string
>(ACTIONTYPES.HANDLE_SESSION_ERROR);

export const RESET_ERROR_ACTION = createAction(ACTIONTYPES.RESET_ERROR);

export const RESET_ERROR_SUCCESS_ACTION = createAction<
  ErrorReducerState | any,
  string
>(ACTIONTYPES.RESET_SESSION_ERROR_SUCCESS);

export const RESET_SESSION_POPUP_LOGON_ERROR_ACTION = createAction(
  ACTIONTYPES.RESET_SESSION_POPUP_LOGON_ERROR
);

export const VALIDATION_ERROR_ACTION = createAction<ErrorReducerState, string>(
  ACTIONTYPES.VALIDATION_ERROR
);

export const WATCH_AXIOS_ERROR_ACTION = createAction<any, string>(
  ACTIONTYPES.WATCH_AXIOS_ERROR
);
