import React from "react";
import Popover, { type PopoverProps } from "@mui/material/Popover";

import { tss } from "tss-react/mui";

const useStyles = tss
  .withParams<Pick<Props, "fullwidth" | "marginTop" | "padding" | "dark">>()
  .create(({ theme, fullwidth, marginTop, padding, dark }) => ({
    popover: {
      maxWidth: fullwidth ? "432px" : "",
      marginTop: marginTop || "16px",
      padding: padding || "8px",
      boxShadow: "0px 4px 32px rgba(32,35,28,0.15)",
      borderRadius: "8px",
      backgroundColor: dark
        ? theme.palette.grey.A100
        : theme.palette.common.white,
    },
  }));

type Props = {
  dark?: boolean;
  disableAutoFocus?: boolean;
  disableEnforceFocus?: boolean;
  fullwidth?: boolean;
  marginTop?: string;
  transitionDuration?: number;
  padding?: string;
} & PopoverProps;

const anchorOriginDefault = {
  vertical: "bottom",
  horizontal: "center",
} as const;

const transformOriginDefault = {
  vertical: "top",
  horizontal: "center",
} as const;

const StyledPopover = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    dark,
    fullwidth,
    marginTop,
    transitionDuration,
    padding,
    anchorOrigin,
    transformOrigin,
    ...rest
  } = props;
  const { classes } = useStyles({ dark, fullwidth, marginTop, padding });

  return (
    <Popover
      transitionDuration={transitionDuration}
      disableScrollLock
      classes={{ paper: classes.popover }}
      ref={ref}
      anchorOrigin={anchorOrigin || anchorOriginDefault}
      transformOrigin={transformOrigin || transformOriginDefault}
      {...rest}>
      {children}
    </Popover>
  );
});

export { StyledPopover };
