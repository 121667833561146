import React, { FC, useMemo } from "react";

import { StyledDivider } from "components/StyledUI";
import { styled } from "@mui/material";
import type { Filter } from "types/Search";
import type { CustomerProductlist } from "types/CustomerProductlist";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "_redux/hooks";
import { selectCustomerProductListsWithContractsSorted } from "_redux/selectors/user";
import FilterSelectedOptionRow from "./FilterSelectedOptionRow";
import FilterDropdownSimple from "./FilterDropdownSimple";
import FilterDropdowsWithSearch from "./FilterDropdowsWithSearch";
import FilterDropdownProductLists from "./FilterDropdownProductlists";

interface FilterSectionProps {
  filterList: Filter[];
  selectedProductlistFilter: CustomerProductlist[];
  isAccountListView: boolean;
}

const FilterWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: theme.spacing(1),
}));

const FilterSection: FC<FilterSectionProps> = ({
  filterList,
  selectedProductlistFilter,
  isAccountListView,
}) => {
  const location = useLocation();
  const customerProductLists = useAppSelector(
    selectCustomerProductListsWithContractsSorted
  );

  const sortedFilterList = useMemo(() => {
    const result = [...filterList];
    result.forEach((f) =>
      f.entry.sort((a, b) => a.label.localeCompare(b.label))
    );
    return result;
  }, [filterList]);

  const hashParams = useMemo(
    () =>
      new URLSearchParams(
        location.hash ? location.hash.substring(1) : undefined
      ),
    [location.hash]
  );

  const matchedFilter = useMemo(
    () => sortedFilterList.filter((item) => !!hashParams.get(item.value)),
    [hashParams, sortedFilterList]
  );

  return (
    <>
      <StyledDivider light />

      <FilterWrapper>
        {!isAccountListView && customerProductLists.length > 0 ? (
          <FilterDropdownProductLists
            selectedProductlistFilter={selectedProductlistFilter}
            customerProductLists={customerProductLists}
          />
        ) : undefined}

        {sortedFilterList.length > 0 &&
          sortedFilterList.map((filter) =>
            filter.entry.length >= 11 ? (
              <FilterDropdowsWithSearch key={filter.value} filter={filter} />
            ) : (
              <FilterDropdownSimple key={filter.value} filter={filter} />
            )
          )}
      </FilterWrapper>

      {(matchedFilter.length > 0 || selectedProductlistFilter?.length > 0) && (
        <>
          <StyledDivider light />

          <FilterSelectedOptionRow
            filterList={filterList}
            selectedProductlistFilter={selectedProductlistFilter}
          />
        </>
      )}
    </>
  );
};

export default FilterSection;
