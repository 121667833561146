import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import usePagination from "@mui/material/usePagination";

import { StyledButton } from "components/StyledUI";
import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left-1.svg";
import { ReactComponent as ArrowRightIcon } from "assets/icons/arrow-right-1.svg";
import { isNil } from "lodash-es";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  ul: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
  },
  li: {
    margin: "0 4px",

    "&:first-of-type": {
      marginLeft: 0,
    },

    "&:last-of-type": {
      marginRight: 0,
    },
  },
  disabledButton: {
    color: `${theme.palette.text.primary} !important`,
  },
}));

interface StyledPaginationProps {
  currentPage: number;
  count: number;
  handleChange: (newCurrentPage: number) => void;
  smallVariant?: boolean;
  siblingCount?: number;
}

const StyledPagination: FC<StyledPaginationProps> = ({
  currentPage,
  count,
  handleChange,
  smallVariant = false,
  siblingCount = 1,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { items } = usePagination({
    count,
    siblingCount,
    boundaryCount: 1,
    page: currentPage,
  });

  return (
    <nav>
      <ul className={classes.ul}>
        {items.map(({ page, type, selected, disabled }, index) => {
          let children: React.ReactElement | null = null;

          if (
            smallVariant &&
            (type === "page" ||
              type === "start-ellipsis" ||
              type === "end-ellipsis")
          ) {
            return null;
          }

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = (
              <StyledButton
                className={classes.disabledButton}
                disabled
                variant="text">
                ...
              </StyledButton>
            );
          } else if (type === "page" && page) {
            children = (
              <StyledButton
                color="primary"
                variant={selected ? "contained" : "text"}
                onClick={() => handleChange(page)}>
                {page}
              </StyledButton>
            );
          } else if (type === "previous" && !isNil(page)) {
            children = (
              <StyledButton
                startIcon={<ArrowLeftIcon />}
                onClick={() => {
                  handleChange(page);
                }}
                color="inherit"
                disabled={disabled}
                tooltip={t("iconTooltips.previousPageIcon")}
              />
            );
          } else if (type === "next" && !isNil(page)) {
            children = (
              <StyledButton
                startIcon={<ArrowRightIcon />}
                onClick={() => {
                  handleChange(page);
                }}
                color="inherit"
                disabled={disabled}
                tooltip={t("iconTooltips.nextPageIcon")}
              />
            );
          }

          return (
            // eslint-disable-next-line react/no-array-index-key
            <li className={classes.li} key={index}>
              {children}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export { StyledPagination };
