import { eFoodDB } from "pwa/db/efood.db";
import { type ProductDetailWithIndices } from "pwa/db/types";
import { tokenizeString } from "pwa/offline/common/tokenizeString";
import {
  type Attribute,
  type ProductDetail,
  isAttributeMap,
  isProductDetail,
} from "types/Product";
import isInstanaActive from "tools/isInstanaActive";
import { isObject } from "lodash-es";
import { toValidNumber } from "../common/SyncTimestamp";
import resolveEan from "./resolve-ean";

const ADDITIONAL_SEARCHABLE_ATTRIBUTES_IDENTIFIER = new Set([
  "MANUCATURER",
  "ITEMGROUP",
  "SUPPLIERPARTNUMBER",
  "SELLINGDESCRIPTION",
  "TAGS",
]);

const createProductDetailWithIndices = async (
  product: ProductDetail,
  lastUpdate: unknown
) => {
  const result = product as ProductDetailWithIndices;
  let oldProduct: ProductDetailWithIndices | undefined;

  try {
    if (result.uniqueID) {
      oldProduct = await eFoodDB.products.get(result.uniqueID);
    } else if (result.partNumber) {
      oldProduct = await eFoodDB.products
        .where("partNumber")
        .equals(result.partNumber)
        .first();
    }
  } catch (error) {
    if (isInstanaActive()) {
      const isError = error instanceof Error;
      ineum(
        "reportEvent",
        "error while loading old product during product saving",
        {
          error: isError ? error : undefined,
          componentStack: isError ? error.stack : undefined,
          timestamp: Date.now(),
          meta: {
            productId: result.uniqueID,
            partNumber: result.partNumber,
          },
        }
      );
    }
  }

  result.nameTokens = tokenizeString(product.name);
  result.shortDescTokens = tokenizeString(product.shortDescription);

  if (isAttributeMap(product.attributes)) {
    result.attrTokens = tokenizeString(
      Object.values(product.attributes)
        .flat<Array<Attribute>>()
        .filter(
          (a) =>
            a.identifier !== "EAN" &&
            (a.searchable ||
              ADDITIONAL_SEARCHABLE_ATTRIBUTES_IDENTIFIER.has(a.identifier))
        )
        .flatMap((a) => a.values)
        .flatMap((av) => av.value)
        .join(" ")
    );
  } else if (Array.isArray(product.attributes)) {
    result.attrTokens = tokenizeString(
      (product.attributes as Array<Attribute>)
        .filter(
          (a) =>
            a.identifier !== "EAN" &&
            (a.searchable ||
              ADDITIONAL_SEARCHABLE_ATTRIBUTES_IDENTIFIER.has(a.identifier))
        )
        .flatMap((a) => a.values)
        .flatMap((av) => av.value)
        .join(" ")
    );
  }

  result.ean = resolveEan(product);
  if (result.prices == null || result.prices.length === 0) {
    if (oldProduct?.prices?.length) {
      result.prices = oldProduct.prices;
    } else {
      result.prices = [];
    }
  }
  result.lastUpdate = toValidNumber(lastUpdate);

  return result;
};

const isDeleteEntry = (
  candidate: unknown
): candidate is { partNumber: string; delete: boolean } =>
  isObject(candidate) && "partNumber" in candidate && "delete" in candidate;

const isPromiseFulfilled = <T>(
  p: PromiseSettledResult<T>
): p is PromiseFulfilledResult<T> => p.status === "fulfilled";

const handleProductDetails = async (
  products: unknown[],
  timestamp: unknown
) => {
  const deletedPartNumbers = [] as string[];
  const productWithIndicesPromises: Array<Promise<ProductDetailWithIndices>> =
    [];

  products.forEach((product) => {
    if (isDeleteEntry(product) && product.delete) {
      deletedPartNumbers.push(product.partNumber);
    } else if (isProductDetail(product)) {
      productWithIndicesPromises.push(
        createProductDetailWithIndices(product, timestamp)
      );
    }
  });

  const settledPromises = await Promise.allSettled(productWithIndicesPromises);
  const productsWithIndices = settledPromises
    .filter(isPromiseFulfilled)
    .map((p) => p.value);

  return {
    productsWithIndices,
    deletedPartNumbers,
  };
};

export default handleProductDetails;
