import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));

export type AllowSyncDialogParams = Record<string, unknown>;

const AllowSyncDialogContent: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="subtitle2" component="p">
        {t("offline.allowSyncDialog.description")}
      </Typography>
      <Typography
        className={classes.marginTop}
        variant="subtitle1"
        component="p">
        {t("offline.allowSyncDialog.hint")}
      </Typography>
    </>
  );
};

export default AllowSyncDialogContent;
