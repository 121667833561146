import { useCallback, useEffect, useMemo, useState } from "react";
import isInstanaActive from "tools/isInstanaActive";
import { useAppSelector } from "_redux/hooks";
import {
  loginStatusSelector,
  wcTokenSelector,
  wcTrustedTokenSelector,
} from "_redux/selectors/user";
import useSyncCategories from "../categories/useSyncCategories";
import lastRunSyncTimestamp from "../lastRun/lastRunSyncTimestamp";
import useSyncPrices from "../prices/useSyncPrices";
import useSyncCustomerProductLists from "../productLists/useSyncCustomerProductLists";
import useSyncProducts from "../products/useSyncProducts";

type SyncCatalogDataProps = {
  force?: boolean;
};

const useSyncCatalogData = ({ force }: SyncCatalogDataProps) => {
  const [syncStartDate, setSyncStartDate] = useState(0);
  const [syncCategories, setSyncCategories] = useState(false);
  const [syncProductLists, setSyncProductLists] = useState(false);
  const [syncProducts, setSyncProducts] = useState(false);
  const [syncPrices, setSyncPrices] = useState(false);
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const userLoggedIn = useAppSelector(loginStatusSelector);
  const wcToken = useAppSelector(wcTokenSelector);
  const wcTrustedToken = useAppSelector(wcTrustedTokenSelector);

  const canSync = userLoggedIn && wcToken != null && wcTrustedToken != null;

  const categoriesSyncCallback = useCallback(() => {
    setSyncCategories(false);
    setSyncProductLists(true);
  }, []);
  const {
    syncState: categoriesSyncState,
    resetSyncState: resetCategoriesSyncState,
  } = useSyncCategories({
    callback: categoriesSyncCallback,
    run: syncCategories,
    force,
  });

  const productListsSyncCallback = useCallback(() => {
    setSyncProductLists(false);
    setSyncProducts(true);
  }, []);
  const {
    syncState: customerProductListsSyncState,
    resetSyncState: resetProductListsSyncState,
  } = useSyncCustomerProductLists({
    callback: productListsSyncCallback,
    run: syncProductLists,
    force,
  });

  const productsSyncCallback = useCallback(() => {
    setSyncProducts(false);
    setSyncPrices(true);
  }, []);
  const {
    syncState: productsSyncState,
    resetSyncState: resetProductsSyncState,
  } = useSyncProducts({
    callback: productsSyncCallback,
    paginated: true,
    run: syncProducts,
    force,
  });

  const pricesSyncCallback = useCallback(async () => {
    await lastRunSyncTimestamp.put(syncStartDate);
    setSyncPrices(false);
    setLoading(false);
    setFinished(true);

    if (isInstanaActive()) {
      ineum("reportEvent", "sync setLastRunSyncTimestamp", {
        timestamp: Date.now(),
        duration: Date.now() - syncStartDate,
        meta: {
          syncStartDate,
        },
      });
    }
  }, [syncStartDate]);
  const { syncState: pricesSyncState, resetSyncState: resetPricesSyncState } =
    useSyncPrices({
      callback: pricesSyncCallback,
      paginated: true,
      run: syncPrices,
      force,
    });

  const startSync = useCallback(() => {
    const reportSyncStartAndStartSync = async () => {
      const startDate = Date.now();

      if (isInstanaActive()) {
        const lastRunTimestamp = await lastRunSyncTimestamp.get();
        ineum("reportEvent", "sync start", {
          timestamp: startDate,
          meta: {
            lastRunTimestamp: lastRunTimestamp ?? 0,
            page: window.location.pathname,
          },
        });
      }

      setSyncStartDate(startDate);
      setLoading(true);
      // trigger start of sync
      setSyncCategories(true);
    };

    reportSyncStartAndStartSync();
  }, []);

  const stopSync = useCallback(() => {
    setSyncStartDate(0);
    setLoading(false);
    setFinished(true);

    setSyncCategories(false);
    setSyncProducts(false);
    setSyncProductLists(false);
    setSyncPrices(false);
  }, []);

  const resetSync = useCallback(() => {
    setSyncStartDate(0);
    setLoading(false);
    setFinished(false);

    setSyncCategories(false);
    setSyncProductLists(false);
    setSyncProducts(false);
    setSyncPrices(false);

    resetCategoriesSyncState();
    resetProductListsSyncState();
    resetProductsSyncState();
    resetPricesSyncState();
  }, [
    resetCategoriesSyncState,
    resetProductListsSyncState,
    resetProductsSyncState,
    resetPricesSyncState,
  ]);

  const error =
    productsSyncState.error ||
    categoriesSyncState.error ||
    customerProductListsSyncState.error ||
    pricesSyncState.error;

  useEffect(() => {
    if (error || (!canSync && loading)) {
      stopSync();
    }
  }, [error, canSync, loading, stopSync]);

  useEffect(
    () => () => {
      if (syncStartDate && loading && isInstanaActive()) {
        ineum("reportEvent", "sync close", {
          timestamp: Date.now(),
          duration: Date.now() - syncStartDate,
          meta: {
            syncStartDate,
          },
        });
      }
    },
    [syncStartDate, loading]
  );

  return useMemo(
    () => ({
      categoriesSyncState,
      customerProductListsSyncState,
      productsSyncState,
      pricesSyncState,
      startSync,
      stopSync,
      resetSync,
      error,
      loading,
      finished,
    }),
    [
      categoriesSyncState,
      customerProductListsSyncState,
      productsSyncState,
      pricesSyncState,
      startSync,
      stopSync,
      resetSync,
      error,
      loading,
      finished,
    ]
  );
};

export default useSyncCatalogData;
