import { eFoodDB } from "pwa/db/efood.db";
import { ProductDetailWithIndices } from "pwa/db/types";
import { EFoodPrice } from "types/Product";
import isInstanaActive from "tools/isInstanaActive";
import { isEqual } from "lodash-es";
import { SyncPricesResponsePrice } from "./doSyncPrices";
import pricesSyncTimestamp from "./pricesSyncTimestamp";

const eFoodPriceCompare = (a: EFoodPrice, b: EFoodPrice) => {
  const matrixDescriptionA = a?.matrixShortDescription;
  const matrixDescriptionB = b?.matrixShortDescription;

  if (matrixDescriptionA == null || matrixDescriptionB == null) {
    if (matrixDescriptionA === matrixDescriptionB) {
      return 0;
    }

    return matrixDescriptionA == null ? -1 : 1;
  }

  return matrixDescriptionA.localeCompare(matrixDescriptionB);
};

const saveProductPrices = async (
  prices?: Array<SyncPricesResponsePrice>,
  timestamp?: unknown
) => {
  if (prices?.length) {
    const responsePrices = prices.reduce((map, price) => {
      const productId = typeof price.id === "string" ? price.id : `${price.id}`;
      map.set(productId, price.prices);
      return map;
    }, new Map<string, EFoodPrice[]>());
    const productIds = Array.from(responsePrices.keys());

    await eFoodDB.transaction("rw", eFoodDB.products, async () => {
      const dbProducts = (await eFoodDB.products.bulkGet(productIds)).filter(
        Boolean
      ) as Array<ProductDetailWithIndices>;
      const changedProducts: ProductDetailWithIndices[] = [];
      dbProducts.forEach((dbProduct) => {
        const newPrices = responsePrices.get(dbProduct.uniqueID);
        const oldPrices = dbProduct.prices;

        if (oldPrices) {
          if (!newPrices) {
            return;
          }

          oldPrices.sort(eFoodPriceCompare);
          newPrices.sort(eFoodPriceCompare);

          if (isEqual(oldPrices, newPrices)) {
            return;
          }

          Object.assign(dbProduct, { prices: newPrices });
          changedProducts.push(dbProduct);
        } else {
          Object.assign(dbProduct, { prices: newPrices });
          changedProducts.push(dbProduct);
        }
      });

      await eFoodDB.products.bulkPut(changedProducts);
    });
  }

  if (timestamp != null) {
    await pricesSyncTimestamp.put(timestamp);

    if (isInstanaActive()) {
      ineum("reportEvent", "sync prices setTimestamp", {
        timestamp: Date.now(),
        meta: {
          paginated: false,
          pricesLength: prices?.length ?? -1,
          pricesSyncTimestamp: timestamp as string | number | boolean,
        },
      });
    }
  }
};

export default saveProductPrices;
