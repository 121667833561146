import React, { FC, PropsWithChildren } from "react";
import { useAppSelector } from "_redux/hooks";

import { loginStatusSelector } from "_redux/selectors/user";

interface StyledShowProps {
  loggedIn?: boolean;
  loggedOut?: boolean;
}

const StyledShow: FC<PropsWithChildren<StyledShowProps>> = ({
  loggedIn,
  loggedOut,
  children,
}) => {
  const loggedInStatus = useAppSelector(loginStatusSelector);

  if (loggedOut && !loggedInStatus && children) {
    return <>{children}</>;
  }

  if (loggedIn && loggedInStatus && children) {
    return <>{children}</>;
  }

  return null;
};

export default StyledShow;
