// Standard libraries
import { StyledDatePicker } from "components/StyledUI";
import React, { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "_redux/hooks";
// Redux
import {
  userDeliveryDates,
  userStockDeliveryDateDate,
} from "_redux/selectors/user";
import { useSite } from "_foundationExt/hooks/useSite";
import * as userActions from "_redux/actions/user";
// Components
import { format } from "date-fns";
import { Basket, DeliveryDate } from "types/Order";
import { isEqual } from "lodash-es";

interface DeliveryDateBoxParams {
  orderId?: string | null;
  basket?: Basket | null;
  variant?: "icon" | "text" | undefined;
  callback?: () => void;
}

const DATE_PATTERN = "yyyy-MM-dd";

const DeliveryDateBox: FC<DeliveryDateBoxParams> = ({
  orderId = null,
  basket = null,
  variant = undefined,
  callback,
}) => {
  const dispatch = useAppDispatch();
  const stockDeliveryDateDate = useAppSelector(userStockDeliveryDateDate);
  const deliveryDates = useAppSelector(userDeliveryDates);
  const { currentSite } = useSite();
  const basketIdentifier = basket?.identifier;
  const basketDeliveryDate = basketIdentifier?.deliveryDate;
  const earliestDeliveryDate = basket?.earliestDeliveryDate;
  const selectedDate = basketDeliveryDate
    ? basketDeliveryDate.date
    : stockDeliveryDateDate;

  const setDeliveryDate = useCallback(
    (date: Date | null) => {
      const payload = {
        storeId: currentSite?.storeID,
        date,
        orderId: orderId || null,
        basketIdentifier: basketIdentifier || null,
        refreshAvailabilities: true,
      };
      dispatch(userActions.UPDATE_DELIVERY_DATE_REQUEST_ACTION(payload));
    },
    [basketIdentifier, orderId, currentSite?.storeID, dispatch]
  );

  const validDeliveryDateForBasket = useCallback(
    (deliveryDate: DeliveryDate, formattedDate: string) => {
      const deliveryDateDate = new Date(deliveryDate.date);
      const earliestDeliveryDateDate = earliestDeliveryDate
        ? new Date(earliestDeliveryDate.date)
        : null;
      const formattedDeliveryDate = format(deliveryDateDate, DATE_PATTERN);

      return (
        formattedDeliveryDate === formattedDate &&
        (!earliestDeliveryDateDate ||
          deliveryDateDate.getTime() >= earliestDeliveryDateDate.getTime())
      );
    },
    [earliestDeliveryDate]
  );

  const filterDeliveryDates = useCallback(
    (date: Date) => {
      const formattedDate = format(date, DATE_PATTERN);
      if (deliveryDates.length) {
        const exists = deliveryDates.filter((deliveryDate) =>
          validDeliveryDateForBasket(deliveryDate, formattedDate)
        );
        if (exists.length) {
          return false;
        }
      }
      return true;
    },
    [deliveryDates, validDeliveryDateForBasket]
  );

  return (
    <StyledDatePicker
      shouldDisableDate={filterDeliveryDates}
      variant={variant}
      onAccept={setDeliveryDate}
      selectedDate={selectedDate}
      key={`deliverydate-${basketIdentifier?.type}-${basketDeliveryDate?.date}`}
      callback={callback}
    />
  );
};

export default React.memo(DeliveryDateBox, isEqual);
