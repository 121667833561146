import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import ProductImage from "components/productImage";
import { StyledFormCheckbox } from "components/StyledUI";
import { Product } from "types/Product";
import { formatCurrency } from "tools/stringUtils";
import { Reward, RewardOption } from "types/Order";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  itemWrapper: {
    padding: theme.spacing(2),
    border: `2px solid ${theme.palette.grey[100]}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
}));

interface CartFreeProductDialogItemProps {
  reward: Reward;
  rewardOptions: RewardOption[];
  freeProduct: Product;
  selectedFreeProducts: string[];
  changeSelection: (
    reward: Reward,
    rewardOptions: RewardOption[],
    freeProductUniqueID: string,
    changeStatus: "CHECKED" | "UNCHECKED"
  ) => void;
}

const CartFreeProductDialogItem: FC<CartFreeProductDialogItemProps> = ({
  reward,
  rewardOptions,
  freeProduct,
  selectedFreeProducts,
  changeSelection,
}) => {
  const { classes } = useStyles();
  const handleFreeGiftChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      changeSelection(reward, rewardOptions, freeProduct.uniqueID, "CHECKED");
    } else {
      changeSelection(reward, rewardOptions, freeProduct.uniqueID, "UNCHECKED");
    }
  };

  return (
    <div className={classes.itemWrapper}>
      <Grid container key={freeProduct.uniqueID}>
        <Grid container xs={1} alignItems="center" justifyContent="center">
          <StyledFormCheckbox
            size="small"
            checkboxProps={{
              checked: selectedFreeProducts?.includes(freeProduct.uniqueID),
              name: "checkbox-small",
              onChange: handleFreeGiftChange,
            }}
          />
        </Grid>
        <Grid container xs={3} justifyContent="center" alignItems="center">
          <ProductImage
            isLink={false}
            product={freeProduct}
            size="68"
            imageMaxSize={68}
          />
        </Grid>
        <Grid xs={8}>
          <Box ml={1}>
            <Typography variant="h3">{freeProduct.name}</Typography>
            <Grid container>
              <Grid>
                {freeProduct.prices && freeProduct.prices[0].price
                  ? formatCurrency(freeProduct.prices[0].price)
                  : null}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default CartFreeProductDialogItem;
