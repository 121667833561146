import React, { FC } from "react";

import ListSubheader, { ListSubheaderProps } from "@mui/material/ListSubheader";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  root: {
    paddingTop: 6,
    paddingBottom: 6,
    fontSize: 14,
    lineHeight: "24px",
    color: theme.palette.text.primary,
    fontWeight: 700,
    pointerEvents: "none",
  },
}));

const StyledFormSelectSubheader: FC<ListSubheaderProps> = ({
  children,
  ...rest
}) => {
  const { classes } = useStyles();

  return (
    <ListSubheader classes={classes} {...rest}>
      {children}
    </ListSubheader>
  );
};

export default StyledFormSelectSubheader;
