// Standard libraries
import React, { FC, PropsWithChildren } from "react";

import { StyledPopover, StyledIcon } from "components/StyledUI";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow-down-1.svg";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  button: {
    padding: 0,
    fontFamily: "NunitoSans,sans-serif",
    display: "inline-flex",
    alignItems: "center",
    fontSize: "1rem",
    lineHeight: 1.5,
    color: theme.palette.background.default,
    backgroundColor: "transparent",
    border: "none",
    letterSpacing: "0.5px",
    textDecorationLine: "underline",
    textUnderlineOffset: "4px",
    textDecorationColor: theme.palette.grey[800],
    cursor: "pointer",
    "&:hover": {
      textDecorationColor: theme.palette.primary.light,
      textDecorationLine: "underline",
    },
    "&:active": {
      textDecorationColor: theme.palette.primary.dark,
      textDecorationLine: "underline",
    },
    "& svg": {
      marginLeft: "8px",
      pointerEvents: "none",
    },
  },
  popover: {
    maxWidth: "432px",
    "& a": {
      width: "100%",
      padding: "6px 8px",
      borderRadius: theme.shape.borderRadius,
      textDecoration: "none",
      "&:hover": {
        backgroundColor: theme.palette.grey[800],
        textDecoration: "none",
      },
    },
  },
}));

interface Props {
  label: string;
}

const ActionBarDropdown: FC<PropsWithChildren<Props>> = ({
  label,
  children,
  ...rest
}) => {
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "navigation-dropdown" : undefined;

  return (
    <>
      <button
        aria-describedby={id}
        className={classes.button}
        type="button"
        onClick={handleClick}>
        {label}
        <StyledIcon>
          <ArrowIcon
            style={open ? { transform: "rotateZ(180deg)" } : undefined}
          />
        </StyledIcon>
      </button>

      <StyledPopover
        className={classes.popover}
        dark
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClick={handleClose}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        {...rest}>
        {children}
      </StyledPopover>
    </>
  );
};

export default React.memo(ActionBarDropdown);
