import { createAction } from "@reduxjs/toolkit";
import {
  CLICK_EVENT_TRIGGERED,
  TRIGGER_MARKETING,
} from "../action-types/marketingEvent";

export const CLICK_EVENT_TRIGGERED_ACTION = createAction<any, string>(
  CLICK_EVENT_TRIGGERED
);

export const TRIGGER_MARKETING_ACTION = createAction<any, string>(
  TRIGGER_MARKETING
);
