import React, { FC } from "react";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  placeholder: {
    color: theme.palette.grey["700"],
    opacity: 1,
  },
}));

const StyledFormSelectPlaceholder: FC<{ text?: string }> = ({ text }) => {
  const { classes } = useStyles();
  return <div className={classes.placeholder}>{text}</div>;
};

export default StyledFormSelectPlaceholder;
