import React, { FC, PropsWithChildren } from "react";
import { useAppSelector } from "_redux/hooks";

import {
  customerConfigSelector,
  disponentSelector,
  loginStatusSelector,
  userConfigSelector,
} from "_redux/selectors/user";

interface StyledProtectableProps {
  permission?: string;
}

const StyledProtectable: FC<PropsWithChildren<StyledProtectableProps>> = ({
  permission,
  children,
}) => {
  const loggedIn = useAppSelector(loginStatusSelector);
  const disponent = useAppSelector(disponentSelector);
  const customerConfig = useAppSelector(customerConfigSelector);
  const userConfig = useAppSelector(userConfigSelector);

  if (loggedIn) {
    let showContent = true;

    if (permission === "show.price") {
      if (disponent) {
        showContent = customerConfig.showPrice && userConfig.showPrice;
      } else {
        showContent = customerConfig.showPrice;
      }
    } else {
      // all other (legacy) permissions evaluate currenlty to false for disponent
      showContent = !disponent;
    }

    if (showContent) {
      return <>{children}</>;
    }
  }

  return null;
};

export default StyledProtectable;
