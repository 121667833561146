import React, { FC } from "react";
import { ESpotData } from "types/Marketing";
import CatalogGroupSlider from "./CatalogGroupSlider";

type Props = {
  data: ESpotData | undefined;
  sidebar?: boolean;
};

const CategoryRecommendation: FC<Props> = ({ data, sidebar }) => {
  if (!data || !data.catalogGroupIds || !data.catalogGroupIds.length) {
    return null;
  }

  return <CatalogGroupSlider data={data} id={data.id} sidebar={sidebar} />;
};

export default CategoryRecommendation;
