import { useMemo } from "react";
import { useAppSelector } from "_redux/hooks";
import { userDetailsSelector } from "_redux/selectors/user";

const useUserName = () => {
  const userDetails = useAppSelector(userDetailsSelector);

  const userName = useMemo(
    () => ({
      firstName: userDetails?.firstName ?? "",
      lastName: userDetails?.lastName ?? "",
    }),
    [userDetails]
  );

  return userName;
};

export default useUserName;
