import React, { useMemo, type FC } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Box, Typography, styled } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Link } from "react-router-dom";
import { MaintenanceDataType, maintenanceData } from "constants/maintenance";
import { StyledButton, StyledLink } from "components/StyledUI";
import { ROUTES } from "constants/routes";

const Banner = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "8px",
  overflow: "hidden",
  height: "160px",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundImage: "url(images/pause.jpg)",
  [theme.breakpoints.up("sm")]: {
    height: "180px",
  },
  [theme.breakpoints.up("md")]: {
    height: "240px",
  },
  [theme.breakpoints.up("lg")]: {
    height: "267px",
  },
}));

const Overlay = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: 24,
  transform: "translateY(-50%)",
  margin: "auto",
  whiteSpace: "pre-line",
  color: theme.palette.common.white,
  [theme.breakpoints.up("sm")]: {
    left: 118,
  },
  [theme.breakpoints.up("md")]: {
    left: 64,
  },
  [theme.breakpoints.up("lg")]: {
    left: 118,
  },
  "& p": {
    fontFamily: "ShadowsIntoLight",
    fontSize: 32,
    lineHeight: "40px",
    letterSpacing: "-0.5px",
    [theme.breakpoints.up("sm")]: {
      fontSize: 40,
      lineHeight: "48px",
    },
  },
}));

const TileWrapper = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const Tile = styled(Grid)(({ theme }) => ({
  minHeight: theme.spacing(42),
  [theme.breakpoints.down("sm")]: {
    minHeight: `calc(100vw - ${theme.spacing(8)})`,
  },
}));

const TileContent = styled("div")(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: theme.spacing(1),
  height: "100%",
  padding: theme.spacing(2, 3),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  "& *:first-child": {
    marginTop: theme.spacing(11),
    [theme.breakpoints.only("xs")]: {
      marginTop: theme.spacing(6),
    },
  },
  "& *:last-child": {
    marginBottom: theme.spacing(11),
    overflowWrap: "anywhere",
    [theme.breakpoints.only("xs")]: {
      marginBottom: theme.spacing(6),
    },
  },
}));

type VariantType = Extract<
  keyof MaintenanceDataType,
  "email" | "phone" | "www"
>;

const determinePrefix = (variant: VariantType) => {
  switch (variant) {
    case "www":
      return "";
    case "email":
      return "mailto:";
    case "phone":
      return "tel:";
    default: {
      // Type guard
      const exhaustiveCheck: never = variant;
      return exhaustiveCheck;
    }
  }
};

const PrintData: FC<{
  storeData?: MaintenanceDataType;
  variant: VariantType;
}> = ({ storeData, variant }) => {
  const data = storeData?.[variant];
  const toPrefix = determinePrefix(variant);

  if (data) {
    return (
      <Typography
        variant="h3"
        component={StyledLink}
        to={`${toPrefix}${data}`}
        navigation
        whiteText>
        {data}
      </Typography>
    );
  }

  return (
    <Typography variant="h3" component="p">
      -
    </Typography>
  );
};

const Maintenance: FC = () => {
  const { t } = useTranslation();

  const storeData = useMemo(() => {
    let data: MaintenanceDataType | undefined;

    const storeId = new URLSearchParams(window.location.search).get("storeId");
    if (storeId != null) {
      data = maintenanceData.find((d) => d.id === storeId);
    }
    if (data != null) {
      return data;
    }

    let identifier: string | undefined;
    const { hostname } = window.location;
    if (hostname.indexOf("servicebund.com") >= 0) {
      [identifier] = hostname.split(".");
    }
    if (
      identifier != null &&
      (identifier.endsWith("qa-v2") || identifier.endsWith("test-v2"))
    ) {
      [identifier] = identifier.split("-");
    }
    data = maintenanceData.find((d) => d.identifier === identifier);
    if (data != null) {
      return data;
    }

    return maintenanceData.find((d) => d.id === "0");
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("error.maintenance.title")}</title>
        <meta
          httpEquiv="Cache-Control"
          content="no-cache, no-store, must-revalidate"
        />
        <meta httpEquiv="Pragma" content="no-cache" />
        <meta httpEquiv="Expires" content="0" />
      </Helmet>
      <Banner>
        <Overlay>
          <Typography>{t("error.maintenance.overlay.title")}</Typography>
          <Typography variant="h3">
            {t("error.maintenance.overlay.subtitle")}
          </Typography>
        </Overlay>
      </Banner>
      <TileWrapper container rowSpacing={2} columnSpacing={3}>
        <Tile xs={12} sm={6} md={4}>
          <TileContent>
            <Typography variant="h2" component="p">
              {t("error.maintenance.tile.email")}
            </Typography>
            <PrintData storeData={storeData} variant="email" />
          </TileContent>
        </Tile>
        <Tile xs={12} sm={6} md={4}>
          <TileContent>
            <Typography variant="h2" component="p">
              {t("error.maintenance.tile.phone")}
            </Typography>
            <PrintData storeData={storeData} variant="phone" />
          </TileContent>
        </Tile>
        <Tile xs={12} sm={6} md={4}>
          <TileContent>
            <Typography variant="h2" component="p">
              {t("error.maintenance.tile.web")}
            </Typography>
            <PrintData storeData={storeData} variant="www" />
          </TileContent>
        </Tile>
      </TileWrapper>
      <Box marginTop={3}>
        <StyledButton component={Link} to={ROUTES.HOME} variant="outlined">
          {t("home.toHome")}
        </StyledButton>
      </Box>
    </>
  );
};

export default Maintenance;
