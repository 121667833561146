import React, { useEffect, useState } from "react";
import { useTrackingConfig } from "components/matomo";
import { MatomoConfig } from "_redux/reducers";
import { getHostWithScheme } from "./utils";

const ExecuteMatomoTagManager = (
  { siteId, containerId, host }: MatomoConfig,
  dataLayerName = "_mtm"
) => {
  const hostWithScheme = getHostWithScheme(host);
  // remove the last slash if present
  const scriptSrcBase =
    hostWithScheme.lastIndexOf("/") === hostWithScheme.length - 1
      ? hostWithScheme.slice(0, -1)
      : hostWithScheme;

  const dataLayer = window[dataLayerName] || [];
  if (!window[dataLayerName]) {
    window[dataLayerName] = dataLayer;
  }
  dataLayer.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
  dataLayer.push({ siteId });

  const script = document.createElement("script");
  script.async = true;
  script.src = `${scriptSrcBase}/js/container_${containerId}.js`;
  document.head.appendChild(script);

  return script;
};

const MatomoTagManager: React.FC = () => {
  const trackingConfig = useTrackingConfig();
  const [script, setScript] = useState<HTMLScriptElement>();
  const {
    matomo: { host, siteId, containerId },
  } = trackingConfig;

  useEffect(() => {
    if (host && siteId != null && containerId && !script) {
      setScript(() =>
        ExecuteMatomoTagManager({
          host,
          siteId,
          containerId,
        })
      );
    }

    return () => {
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, [host, siteId, containerId, script]);

  return null;
};

export default MatomoTagManager;
