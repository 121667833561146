import React, { FC } from "react";

import CartPromotionCodeButton, {
  PromotionCode,
} from "./CartPromotionCodeButton";

interface CartPromotionCodeButtonProps {
  promotionCodeData: PromotionCode[] | null;
  deletePromotion: (promotionCode: string) => Promise<void>;
}

const CartPromotionCodeButtons: FC<CartPromotionCodeButtonProps> = ({
  promotionCodeData,
  deletePromotion,
}) => {
  if (!promotionCodeData) {
    return null;
  }

  return (
    <>
      {promotionCodeData.map((promotionCode) => (
        <CartPromotionCodeButton
          promotionCode={promotionCode}
          deletePromotion={deletePromotion}
          key={promotionCode.codeName}
        />
      ))}
    </>
  );
};

export default CartPromotionCodeButtons;
