import { useLayoutEffect, useMemo, useState } from "react";
import { useAppSelector } from "_redux/hooks";
import { selectForceStandaloneMode } from "_redux/selectors/site";

export enum PWADisplayMode {
  TWA = "twa",
  STANDALONE = "standalone",
  BROWSER = "browser",
}

const MEDIA_QUERY = "(display-mode: standalone)";

export const standaloneMediaQuery = window.matchMedia(MEDIA_QUERY);

const usePWADisplayMode = () => {
  const [mediaQueryEvent, setMediaQueryEvent] = useState<MediaQueryListEvent>(
    () => {
      const { matches, media } = standaloneMediaQuery;
      return new MediaQueryListEvent("change", {
        matches,
        media,
      });
    }
  );
  const forceStandaloneMode = useAppSelector(selectForceStandaloneMode);

  useLayoutEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const query = standaloneMediaQuery;
    query.addEventListener("change", setMediaQueryEvent, { signal });

    return () => controller.abort();
  }, []);

  const pwaDisplayMode = useMemo(() => {
    const isStandalone = mediaQueryEvent.matches;
    // TODO use 'android-app://<twa.package.name>' instead
    if (document.referrer.startsWith("android-app://")) {
      return { displayMode: PWADisplayMode.TWA, isPWA: true, isBrowser: false };
    }
    if (
      ("standalone" in navigator && navigator.standalone) ||
      isStandalone ||
      forceStandaloneMode
    ) {
      return {
        displayMode: PWADisplayMode.STANDALONE,
        isPWA: true,
        isBrowser: false,
      };
    }
    return {
      displayMode: PWADisplayMode.BROWSER,
      isPWA: false,
      isBrowser: true,
    };
  }, [mediaQueryEvent, forceStandaloneMode]);

  return pwaDisplayMode;
};

export default usePWADisplayMode;
