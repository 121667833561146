import React, { FC } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import HTMLReactParser from "html-react-parser";
import { ESpotData } from "types/Marketing";
import { tss } from "tss-react/mui";

type Props = {
  data: ESpotData | undefined;
  headline?: string;
  className?: string; // additional css class
};

const useStyles = tss.create(({ theme }) => ({
  headline: {
    fontFamily: "ShadowsIntoLight, Arial",
    fontWeight: 100,
    fontSize: "1.5rem",
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
}));

const TitleCard: FC<Props> = ({ data, headline, className }) => {
  const { classes, cx } = useStyles();
  if (!data) {
    return null;
  }

  return (
    <Grid xs={12}>
      <Typography variant="h3" className={cx(classes.headline, className)}>
        {!headline &&
          data.titles.map((title) =>
            HTMLReactParser(title.marketingText || "")
          )}
        {headline && HTMLReactParser(headline || "")}
      </Typography>
    </Grid>
  );
};

export default TitleCard;
