import React, { useMemo } from "react";
import { GridProps, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Quantity from "components/quantity/Quantity";
import ProductImage from "components/productImage";
import { EFoodPrice, Matrix, Product } from "types/Product";
import { formatCurrency } from "tools/stringUtils";
import { StyledProtectable } from "components/StyledUI";
import { TrackShowType } from "components/matomo";
import { tss } from "tss-react/mui";

const mergeData = (matrix: Matrix[], prices?: EFoodPrice[]) => {
  const matrixEntry = matrix.find((item) => item.handlingCode === "1");
  const priceEntry = prices?.find(
    (item) => item.matrixShortDescription === matrixEntry?.shortDesc
  );

  return {
    ...matrixEntry,
    ...priceEntry,
  };
};

const useStyles = tss.create(({ theme }) => ({
  productImage: {
    margin: "0 auto",
  },
  productTitle: {
    marginBottom: theme.spacing(1),
    fontFamily: "ShadowsIntoLight, ",
    minHeight: "60px",
  },
  productPriceContainer: {
    marginBottom: theme.spacing(2),
    fontSize: "14px",
  },
  productPrice: {
    float: "right",
  },
  ellipsis: {
    marginTop: theme.spacing(2),
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
}));

interface ProductCardProps {
  product: Product;
  trackType?: TrackShowType;
}

const ProductCard: React.FC<ProductCardProps & GridProps> = ({
  product,
  trackType,
  ...rest
}) => {
  const { classes } = useStyles();
  const priceMatrix = useMemo(
    () => mergeData(product.matrix, product.prices),
    [product.matrix, product.prices]
  );

  return (
    <Grid container {...rest}>
      <Grid xs={12}>
        <ProductImage product={product} size="180" centered />
      </Grid>
      <Grid xs={12} className={classes.productTitle}>
        <Typography variant="h5" component="h3" className={classes.ellipsis}>
          {product.name}
        </Typography>
      </Grid>
      <Grid xs={12} className={classes.productPriceContainer}>
        {priceMatrix.longDesc}
        <div className={classes.productPrice}>
          <StyledProtectable permission="show.price">
            <b>{formatCurrency(priceMatrix.price)}</b>
          </StyledProtectable>
        </div>
      </Grid>
      {product.buyable && (
        <Grid xs={12}>
          <Quantity data={product} type="espot" trackType={trackType} />
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(ProductCard);
