// react
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
// mui
import { Typography, Hidden } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import {
  StyledPriceTable,
  StyledPriceTableSmall,
  StyledProtectable,
  UnstyledLink,
} from "components/StyledUI";
// types
import { OrderItem } from "types/Order";
// const
import { ROUTES } from "constants/routes";
// components
import Quantity from "components/quantity/Quantity";
import ProductImage from "components/productImage";
import ProductOptions from "components/productOptions";

import { formatCurrency } from "tools/stringUtils";
import { isEqual } from "lodash-es";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  root: {
    margin: theme.spacing(1, 0),
  },
}));

interface CartSubBasketItemProps {
  orderItem: OrderItem;
}

const CartSubBasketItem: FC<CartSubBasketItemProps> = ({ orderItem }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const buyable = orderItem?.article?.buyable === "true" || false;

  let badgeText: string | undefined;

  if (orderItem.isFreeGift) {
    badgeText = t("cart.freeProduct.free");
  } else if (orderItem.adjustment) {
    badgeText = t("cart.promotionCode");
  }
  const product = {
    options: (
      <ProductOptions
        productId={orderItem.article?.uniqueID}
        customerProductlists={orderItem.article?.customerProductlists}
      />
    ),
    image: (
      <ProductImage
        product={orderItem.article}
        size="68"
        badgeText={badgeText}
      />
    ),
    name: (
      <UnstyledLink
        to={`${ROUTES.PRODUCT_DETAIL}/${orderItem.article?.eFoodPartnumber}`}>
        <Typography variant="h5">
          {orderItem.article
            ? orderItem.article.name
            : t("product.notAvailable")}
        </Typography>
        <Typography variant="subtitle1">
          {t("product.partnumber")} {orderItem.article?.eFoodPartnumber}
        </Typography>
      </UnstyledLink>
    ),
    matrix: (
      <>
        {orderItem.article && (
          <StyledPriceTable
            fullWidth
            noPaddingTop
            prices={buyable ? orderItem.article?.prices : undefined}
            matrix={orderItem.article?.matrix}
          />
        )}
      </>
    ),
    matrixSmall: (
      <>
        {orderItem.article && (
          <StyledPriceTableSmall
            prices={orderItem.article?.prices}
            matrix={orderItem.article?.matrix}
          />
        )}
      </>
    ),
    quantity: <Quantity data={orderItem} type="cart" />,
    price: (
      <>
        {buyable && (
          <StyledProtectable permission="show.price">
            <Typography align="right" variant="body2">
              {orderItem.adjustment !== 0 ? (
                <>
                  <s>{formatCurrency(orderItem.total)}</s>
                  <br />
                  <b>
                    {formatCurrency(orderItem.total + orderItem.adjustment)}
                  </b>
                </>
              ) : (
                <b>{formatCurrency(orderItem.total)}</b>
              )}
            </Typography>
          </StyledProtectable>
        )}
      </>
    ),
  };

  return (
    <Grid
      container
      xs={12}
      className={classes.root}
      style={{ minHeight: orderItem.article ? "unset" : "68px" }}>
      <Hidden mdUp smDown>
        <Grid xs={2}>{product.image}</Grid>
      </Hidden>
      <Grid xs={12} sm={10} md={12} container>
        <Grid container xs={12}>
          <Hidden mdDown>
            <Grid container md={2}>
              <Grid xs={1} sm={1} md={3}>
                {product.options}
              </Grid>
              <Grid container justifyContent="center" xs={1} sm={1} md={9}>
                {product.image}
              </Grid>
            </Grid>
          </Hidden>
          <Grid xs={8} md={4}>
            {product.name}
          </Grid>
          <Hidden mdUp>
            <Grid xs={4}>{product.matrixSmall}</Grid>
          </Hidden>
          <Hidden mdDown>
            <>
              <Grid xs={2} sm={2} md={2}>
                {product.matrix}
              </Grid>
              <Grid xs={3} sm={3} md={3}>
                {product.quantity}
              </Grid>
              <Grid xs={1} sm={1} md={1}>
                {product.price}
              </Grid>
            </>
          </Hidden>
        </Grid>
        <Hidden mdUp>
          <Grid container xs={12}>
            <Grid xs={2}>{product.options}</Grid>
            <Grid xs={10}>
              {product.quantity}
              {product.price}
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default React.memo(CartSubBasketItem, isEqual);
