/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, Method, AxiosPromise } from "axios";
import { executeRequest } from "_foundation/axios/axiosConfig";

const externalService = {
  /**
   * Servicebund API to get stores with information for postalcode
   * @param postalCode
   * @returns
   */
  searchStoreByPlz(
    postalCode: string,
    options?: { widget?: string; signal?: AbortSignal }
  ): AxiosPromise<any> {
    const domain = "https://www.servicebund.de";
    const path = "/webshop-api/affiliate/listforpostalcode";
    const requestUrl = domain + path;
    const method: Method = "GET";

    const queryParameters = new URLSearchParams();

    if (postalCode === undefined) {
      throw new Error(
        `Request '${path}' missing required parameter postalCode`
      );
    }

    queryParameters.set("postalCode", postalCode);

    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method,
      url: requestUrl,
      ...(options ?? {}),
    };

    return executeRequest(requestOptions);
  },
};
export default externalService;
