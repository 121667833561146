import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { UnstyledLink, StyledPriceTableSmall } from "components/StyledUI";

import Quantity from "components/quantity/Quantity";

import { truncate } from "tools/stringUtils";
import { ROUTES } from "constants/routes";
import { Product } from "types/Product";
import ProductOptions from "components/productOptions";
import { useAppSelector } from "_redux/hooks";
import { makeSelectOrderItemByPartNumberAndFreeGift } from "_redux/selectors/order";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  root: {
    marginBottom: theme.spacing(1.5),
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  row: {
    "&:hover": {
      background: theme.palette.grey[50],
    },
  },
  bcSearchItemBox: {
    padding: "12px 0 0 12px !important",
  },
}));

interface BcProductSearchItemProps {
  product: Product;
  onClick: () => void;
  multipleScanCounter?: number;
}

const BcProductSearchItem: FC<BcProductSearchItemProps> = ({
  product,
  onClick,
  multipleScanCounter,
}) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const articleNumber = product.eFoodPartnumber;

  const [showCartHint, setShowCartHint] = useState(false);
  const [addProductToCart, setAddProductToCart] = useState(false);

  const selectOrderItemByPartNumberAndFreeGift = useMemo(
    () => makeSelectOrderItemByPartNumberAndFreeGift(product.partNumber, false),
    [product.partNumber]
  );

  const orderItem = useAppSelector(selectOrderItemByPartNumberAndFreeGift);

  useEffect(() => {
    if (orderItem && orderItem.quantity > 0) {
      if (orderItem.quantity > 1) {
        setShowCartHint(multipleScanCounter === 0);
      }
      setAddProductToCart(true);
    }

    if (!orderItem) {
      setAddProductToCart(true);
    }
  }, [orderItem, multipleScanCounter]);

  return (
    <Grid
      className={cx(classes.root, classes.row)}
      id={`search_row_${product.partNumber}`}
      container
      wrap="nowrap"
      spacing={3}>
      <Grid container className={classes.bcSearchItemBox}>
        <Grid xs={8}>
          <UnstyledLink
            to={`${ROUTES.PRODUCT_DETAIL}/${product.eFoodPartnumber}`}
            onClick={onClick}>
            <Typography variant="body2">
              <b>{truncate(product.name, 50)}</b>
            </Typography>
            <Typography variant="subtitle1">
              {t("product.partnumber")} {articleNumber || ""}
            </Typography>
          </UnstyledLink>
        </Grid>
        <Grid xs={4}>
          <StyledPriceTableSmall
            matrix={product.matrix}
            prices={product.prices}
          />
        </Grid>
        <Grid xs={12}>
          <Box mt={1}>
            <Grid container>
              <Grid container xs={3}>
                <ProductOptions
                  productId={product.uniqueID}
                  customerProductlists={product.customerProductlists}
                  inContract={product.inContract}
                />
              </Grid>
              <Grid xs={9}>
                <Quantity
                  data={product}
                  type="search"
                  showCartHint={showCartHint}
                  addProductToCart={addProductToCart}
                  multipleScanCounter={multipleScanCounter}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BcProductSearchItem;
