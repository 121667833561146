import React, { FC } from "react";

import { ContentRecommendation } from "components/widgets/content-recommendation";
import { tss } from "tss-react/mui";
import type { ZIndexWithExtension } from "theme/zIndexExtension";

const headerConfig = {
  page: {
    name: "header",
  },
  espotFooter: {
    eSpotName: "headerNotification",
  },
};

const useStyles = tss.create(({ theme }) => ({
  wrapper: {
    zIndex: (theme.zIndex as ZIndexWithExtension).extension.headerMarketingSpot,
    background: theme.palette.grey.A100,
  },
}));

const HeaderMarketingSpot: FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <ContentRecommendation
        cid="header"
        eSpot={headerConfig.espotFooter}
        page={headerConfig.page}
      />
    </div>
  );
};

export default React.memo(HeaderMarketingSpot);
