import {
  eFoodConfigDefaultState,
  trackingDefaultState,
} from "_redux/reducers/initStates";
import { RootReducerState } from "../reducers";

export const siteSelector = (state: RootReducerState) => state.site.currentSite;

export const efoodSelector = (state: RootReducerState) =>
  state.site.currentSite?.storeCfg.efood ?? eFoodConfigDefaultState;

export const storeListSelector = (state: RootReducerState) =>
  state.site.currentSite?.storeCfg.efood.storeList || [];

export const trackingSelector = (state: RootReducerState) =>
  state.site.currentSite?.storeCfg.efood.tracking || trackingDefaultState;

export const scrollToTopSelector = (state: RootReducerState) =>
  state.site.scrollToTop;

export const showDialogSelector = (state: RootReducerState) =>
  state.site.showDialog;

export const onlineStatusSelector = (state: RootReducerState) =>
  state.site.onlineStatus;

export const selectForceStandaloneMode = (state: RootReducerState) =>
  state.site.forceStandaloneMode;

export const selectSyncManuallyTriggered = (state: RootReducerState) =>
  state.site.syncManuallyTriggered;

export const selectSyncRunning = (state: RootReducerState) =>
  state.site.syncRunning;
