import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import {
  StyledCard,
  StyledKeyVisual,
  StyledCardMedia,
} from "components/StyledUI";

import { CatalogGroupView } from "_redux/reducers/reducerStateInterface";

import { ROUTES } from "constants/routes";

import introImage from "assets/images/my-account-intro.png";
import { useSite } from "_foundation/hooks/useSite";

import { useAppSelector } from "_redux/hooks";
import { categoryTopSelector } from "_redux/selectors/catalog";
import { categoryImages } from "constants/catalog";
import { tss } from "tss-react/mui";

const useStyles = tss.create(() => ({
  root: {
    height: "144px",
  },
}));

const ProductCatalog: FC = () => {
  const { classes } = useStyles();

  const { t } = useTranslation();

  const { currentSite } = useSite();
  const [categories, setCategories] = useState<Array<CatalogGroupView>>();
  const categoryViewTop = useAppSelector(categoryTopSelector);

  const getCategoryImage = (identifier) =>
    categoryImages.find((image) => image.identifier === identifier)?.image;

  useEffect(() => {
    if (currentSite && categoryViewTop) {
      setCategories(categoryViewTop);
    }
  }, [currentSite, categoryViewTop]);

  return (
    <>
      <Helmet>
        <title>{t("productCatalog.productCatalog")}</title>
      </Helmet>

      <StyledKeyVisual
        useBackground
        imageSrc={introImage}
        imageAlt="cutting herbs with a knife">
        <Typography>{t("productCatalog.productCatalog")}</Typography>
      </StyledKeyVisual>

      <Grid container spacing={3}>
        {categories &&
          categories.map((category) => (
            <Grid key={category.identifier} item xs={12} sm={6} md={4} lg={3}>
              <StyledCard
                to={`${ROUTES.PRODUCT_LIST}/${category.uniqueID}`}
                withImage>
                <StyledCardMedia
                  className={classes.root}
                  image={
                    category.thumbnail || getCategoryImage(category.identifier)
                  }
                  title={category.name}
                />

                <Typography variant="h3">{category.name}</Typography>
              </StyledCard>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default ProductCatalog;
