/* tslint:disable */
/* eslint-disable */
/**
 * EFood Storefront API
 * This is the EFood Storefront API specification.  Operations are grouped by tags.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { SaveLocationRequest } from '../../../../com/telekom-mms/efood/api/model';
/**
 * StockListApi - axios parameter creator
 * @export
 */
export const StockListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Reorganizes stock list by generating stock list places for all items in stock list by current order.
         * @param {string} storeId The store identifier
         * @param {BigInt} stockListId The customer productlist identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorganizeStockList: async (storeId: string, stockListId: BigInt, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('reorganizeStockList', 'storeId', storeId)
            // verify required parameter 'stockListId' is not null or undefined
            assertParamExists('reorganizeStockList', 'stockListId', stockListId)
            const localVarPath = `/store/{storeId}/efood/stocklist/{stockListId}/reorganize`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"stockListId"}}`, String(stockListId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves a stocklist location for a given stocklist item.
         * @param {string} storeId The store identifier
         * @param {BigInt} stockListItemId The id of the stocklist item
         * @param {SaveLocationRequest} [saveLocationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLocation: async (storeId: string, stockListItemId: BigInt, saveLocationRequest?: SaveLocationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('saveLocation', 'storeId', storeId)
            // verify required parameter 'stockListItemId' is not null or undefined
            assertParamExists('saveLocation', 'stockListItemId', stockListItemId)
            const localVarPath = `/store/{storeId}/efood/stocklist/{stockListItemId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"stockListItemId"}}`, String(stockListItemId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveLocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockListApi - functional programming interface
 * @export
 */
export const StockListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StockListApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Reorganizes stock list by generating stock list places for all items in stock list by current order.
         * @param {string} storeId The store identifier
         * @param {BigInt} stockListId The customer productlist identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reorganizeStockList(storeId: string, stockListId: BigInt, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reorganizeStockList(storeId, stockListId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves a stocklist location for a given stocklist item.
         * @param {string} storeId The store identifier
         * @param {BigInt} stockListItemId The id of the stocklist item
         * @param {SaveLocationRequest} [saveLocationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveLocation(storeId: string, stockListItemId: BigInt, saveLocationRequest?: SaveLocationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveLocation(storeId, stockListItemId, saveLocationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StockListApi - factory interface
 * @export
 */
export const StockListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StockListApiFp(configuration)
    return {
        /**
         * 
         * @summary Reorganizes stock list by generating stock list places for all items in stock list by current order.
         * @param {string} storeId The store identifier
         * @param {BigInt} stockListId The customer productlist identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorganizeStockList(storeId: string, stockListId: BigInt, options?: any): AxiosPromise<void> {
            return localVarFp.reorganizeStockList(storeId, stockListId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves a stocklist location for a given stocklist item.
         * @param {string} storeId The store identifier
         * @param {BigInt} stockListItemId The id of the stocklist item
         * @param {SaveLocationRequest} [saveLocationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLocation(storeId: string, stockListItemId: BigInt, saveLocationRequest?: SaveLocationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.saveLocation(storeId, stockListItemId, saveLocationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StockListApi - object-oriented interface
 * @export
 * @class StockListApi
 * @extends {BaseAPI}
 */
export class StockListApi extends BaseAPI {
    /**
     * 
     * @summary Reorganizes stock list by generating stock list places for all items in stock list by current order.
     * @param {string} storeId The store identifier
     * @param {BigInt} stockListId The customer productlist identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockListApi
     */
    public reorganizeStockList(storeId: string, stockListId: BigInt, options?: any) {
        return StockListApiFp(this.configuration).reorganizeStockList(storeId, stockListId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves a stocklist location for a given stocklist item.
     * @param {string} storeId The store identifier
     * @param {BigInt} stockListItemId The id of the stocklist item
     * @param {SaveLocationRequest} [saveLocationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockListApi
     */
    public saveLocation(storeId: string, stockListItemId: BigInt, saveLocationRequest?: SaveLocationRequest, options?: any) {
        return StockListApiFp(this.configuration).saveLocation(storeId, stockListItemId, saveLocationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
